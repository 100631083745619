import * as c from '../../constants'

// eslint-disable-next-line import/prefer-default-export
export function sortCodeValidate(formId) {
  return {
    type: c.SORTCODE_VALIDATE,
    payload: {
      formId,
    },
  }
}
