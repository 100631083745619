import { connect } from 'react-redux'
import ActivityLogSingleView from '../../components/ActivityLogSingleView'
import { isAndroid } from '../../utils/getPlatform'
import { saveeReceipt, showReceipt } from './selectors'
import { modalShow } from '../../store/ui'

const mapStateToProps = (state, ownProps) => ({
  onPrintClick: () => window.print(),
  isLoading: state.activityLog.single.isFetching,
  saveReceiptClick: () => saveeReceipt(state),
  showReceipt: showReceipt(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  saveReceiptModal() {
    if (isAndroid()) {
      dispatch(modalShow('eReceipt'))
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLogSingleView)
