/* eslint-disable no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'
import s from './styles.css'

import TableHead from '../TableHead'
import TableBody from '../TableBody'
import Virtualized from '../TableBody/virtualized'
import { TableHeadColsProps } from '../TableHead/types'

type TableProps = {
  headerCols: TableHeadColsProps[]
  virtualized?: boolean
  rows: any[]
  itemHeight?: number
}

const Table = ({ virtualized, itemHeight = 45, rows, headerCols, ...props }: TableProps) => {
  const tenth = Math.ceil(rows.length / 10)
  const itemBuffer = tenth > 30 ? 30 : tenth
  return (
    <div className={s.root}>
      <table>
        <TableHead {...props} cols={headerCols} />

        {virtualized ? (
          rows.length > 0 ? (
            <Virtualized {...props} items={rows} itemHeight={itemHeight} itemBuffer={itemBuffer} />
          ) : null
        ) : (
          <TableBody rows={rows} {...props} />
        )}
      </table>
    </div>
  )
}

export default Table
