import { connect } from 'react-redux'
import AlertsView from '../../components/AlertsView'
import { getMaskedContacts, doesUserHaveDetails } from './selectors'

export const mapStateToProps = (state, ownProps) => ({
  contacts: getMaskedContacts(state),
  showAlerts: doesUserHaveDetails(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(AlertsView)
