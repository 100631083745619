/* eslint-disable */
// @ts-nocheck
// todo - eslint and typescript
export const getPartyId = (id, code, state?: any) => {
  let partyId
  if (code === 'SI' || code === 'DD') {
    partyId = (state) => state.paymentsRecurring[code].items[id].partyId.value
    return partyId
  }
  partyId = (state) => state.paymentsFuture.list.items[id].customerId
  return partyId
}
