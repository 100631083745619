import merge from 'lodash/merge'
import union from 'lodash/union'
import sortBy from 'lodash/sortBy'
import * as c from '../../constants'
import groupAccounts from '../../selectors/groupAccounts'

export interface IAccountsState {
  isFetching: boolean
  isSaving: boolean
  showSavedMessage: boolean
  items: any
  itemsOrder: any[]
  realToMasked: undefined
  lastUpdated: number
  knownMappings: any[]
  knownMappingsErrors: any
}

const initialState: IAccountsState = {
  isFetching: false,
  isSaving: false,
  showSavedMessage: false,
  items: {},
  itemsOrder: [],
  realToMasked: undefined,
  lastUpdated: 0,
  knownMappings: [],
  knownMappingsErrors: {},
}

export default function accountsReducer(state = initialState, action): IAccountsState {
  const p = action.payload
  switch (action.type) {
    case c.ACCOUNTS_REALTOMASKED_UPDATE:
      return {
        ...state,
        realToMasked: p.realToMasked,
      }
    case c.ACCOUNTS_FETCH:
      return {
        ...state,
        isFetching: true,
      }
    case c.ACCOUNTS_UPDATE:
      return {
        ...state,
        items: merge({}, state.items, p.items),
        isFetching: false,
        lastUpdated: Date.now(),
        itemsOrder: p.itemsOrder,
      }
    case c.ACCOUNT_DETAILS_UPDATE:
      return {
        ...state,
        items: {
          ...state.items,
          [p.id]: {
            ...state.items[p.id],
            ...p.details,
          },
        },
      }
    case c.ACCOUNTS_MAPPING_ADD: {
      const newItems = {}

      const ids = p.ids || []

      Object.keys(state.items).forEach((key) => {
        newItems[key] = {
          ...state.items[key],
          [p.propertyName]: ids.indexOf(key) > -1,
        }
      })

      const newErrors = { ...state.knownMappingsErrors }
      if (p.error) newErrors[p.mappingName] = p.error

      return {
        ...state,
        knownMappings: union(state.knownMappings, [p.mappingName]),
        knownMappingsErrors: newErrors,
        items: newItems,
      }
    }
    case c.ACCOUNTS_ORDER_CHANGE: {
      const { items, itemsOrder } = state
      const selectedAccount = items[p.accountId]
      const direction = p.direction === 'up' ? -1 : 1

      const groupedAccounts = groupAccounts(items)
      const selectedGroupAll = groupedAccounts.find((g) => g.id === selectedAccount.group).items
      const selectedGroup = p.entity
        ? selectedGroupAll.filter((val) => val.customerId === p.entity)
        : selectedGroupAll

      const orderedSelectedGroup = sortBy(selectedGroup, ['order'])

      const selectedAccountIndexInGroup = orderedSelectedGroup.findIndex(
        (account) => account.id === p.accountId
      )

      const siblingIndex = selectedAccountIndexInGroup + direction

      const siblingAccount = orderedSelectedGroup[siblingIndex]

      const newOrder = [...itemsOrder]
      const selectedAccountIndexInOrder = itemsOrder.findIndex((val) => val === p.accountId)

      const newItems = { ...items }

      if (siblingIndex !== -1 && !(siblingIndex >= orderedSelectedGroup.length)) {
        // Update order
        const siblingAccountIndexInOrder = itemsOrder.findIndex((val) => val === siblingAccount.id)
        newOrder[selectedAccountIndexInOrder] = siblingAccount.id
        newOrder[siblingAccountIndexInOrder] = p.accountId
        // Update items
        newItems[p.accountId] = {
          ...selectedAccount,
          order: siblingAccount.order,
        }
        newItems[siblingAccount.id] = {
          ...siblingAccount,
          order: selectedAccount.order,
        }
      }

      return {
        ...state,
        items: newItems,
        itemsOrder: newOrder,
      }
    }
    case c.ACCOUNTS_ORDER_SUBMIT:
      return {
        ...state,
        isSaving: true,
      }
    case c.ACCOUNTS_ORDER_SUBMIT_FINISHED:
      return {
        ...state,
        isSaving: false,
        showSavedMessage: p.showSavedMessage,
      }
    default:
      return state
  }
}
