/* eslint-disable */

import { uid } from 'uid'
import goOutMultiLogin from './index'
import * as c from '../../constants'
import { isArray } from 'lodash'

const TYPE_BACK_FORWARD = 'back_forward'
const TYPE_TYPE_NAVIGATE_SAFARI = 0

function generateTabOrScreenId() {
  return `${uid()}${uid()}-${uid()}-${uid()}-${uid()}-${uid()}${uid()}${uid()}`
}

function storageHandler(e) {
  if (e.key === c.tabOrScreenId) {
    if (e.oldValue !== e.newValue) {
      goOutMultiLogin.goOutIfSecondLoginDetected('https://www.weatherbys.bank/sessionexpired')
    }
  }
}

function isDuplicateTab() {
  try {
    const navigation: any = window.performance.getEntriesByType('navigation')
    if (navigation && isArray(navigation) && navigation.length > 0 && navigation[0].type) {
      return navigation[0].type === TYPE_BACK_FORWARD
    }

    // Backward compatibility : mainly for SAFARI
    // warning! window.performance.navigation is deprecated
    // https://developer.mozilla.org/en-US/docs/Web/API/Performance/navigation

    if (window.performance.navigation) {
      return window.performance.navigation.type === TYPE_TYPE_NAVIGATE_SAFARI
    }
  } catch (e) {
    return false
  }
}

export function registerTabOrScreenOnStorage() {
  if (typeof Storage !== 'undefined') {
    if (isDuplicateTab() || sessionStorage[c.tabOrScreenId] == null) {
      sessionStorage[c.tabOrScreenId] = generateTabOrScreenId()
    }
    const id = sessionStorage[c.tabOrScreenId]
    // todo: Safari 14.1 issues
    // https://bugs.webkit.org/show_bug.cgi?id=225344
    window.addEventListener('storage', storageHandler, false)
    localStorage[c.tabOrScreenId] = id
    goOutMultiLogin.updateSessionLocalStorageId(id)
  }
}
