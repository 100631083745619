import { connect } from 'react-redux'

import SecondaryNav from '../../components/Header/SecondaryNav'
import userHasUnreadMail from '../../selectors/userHasUnreadMail'
import { mailBulletinsFetch } from '../MailLayout/actions'
import { getBulletins } from './selectors'
import { panelToggle, tooltipToggle } from '../../store/ui'
import { isAndroidOriOS } from '../../utils/getPlatform'

const mapStateToProps = (state, ownProps) => ({
  hasUnreadMail: userHasUnreadMail(state),
  hasUnreadBulletins: userHasUnreadMail(state, 'bulletins'),
  bulletins: getBulletins(state),
  bulletinsAreLoading: getBulletins(state) === undefined,
  bulletinsAreVisible: isAndroidOriOS()
    ? state.ui.tooltip === 'bulletins'
    : state.ui.panel === 'bulletins',
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onBulletinIconClick: (e) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(mailBulletinsFetch())
    if (isAndroidOriOS()) {
      dispatch(tooltipToggle('bulletins'))
    } else {
      dispatch(panelToggle('bulletins'))
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(SecondaryNav)
