import { connect } from 'react-redux'
import { getCount } from './selectors'
import AuthorisationsBox from '../../components/AuthorisationsBox'

const mapStateToProps = (state, ownProps) => {
  const count = getCount(state)
  const isVisible = count !== undefined && count > 0

  return {
    count,
    isVisible,
  }
}

export default connect(mapStateToProps)(AuthorisationsBox)
