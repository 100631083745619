import React, { ReactNode } from 'react'
import Header from '../Header'
import Footer from '../../containers/Footer'
import Wrapper from '../Wrapper'
import s from './styles.css'
import lock from '../../assets/svg/lock.svg'

type LoggedOutLayoutProps = {
  children: ReactNode
  route: any
  subheader?: string
  authError?: boolean
  isLoading?: boolean
}

const LoggedOutLayout = ({
  children,
  route,
  subheader,
  authError,
  isLoading,
}: LoggedOutLayoutProps) => {
  const backgroundClass = route.hasBackgroundImage ? s.hasBg : undefined
  return (
    <div className={`${s.root} ${backgroundClass}`}>
      <Header loggedOut />
      <Wrapper>
        <div className={s.inner}>
          <div className={s.lock}>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img src={lock} aria-hidden />
          </div>
          <h1>Online Banking</h1>
          {subheader && <h2 className='subheader'>{subheader}</h2>}
          <div className={s.row}>
            <div className={s.col}>{children}</div>
            {!authError && !isLoading && (
              <div className={s.col}>
                <h2>Need help?</h2>
                <p>
                  For any enquiries regarding logging in or registering for online banking, please
                  speak to your Private Bank Team or Racing Bank Team in the first instance or
                  contact us on the following:
                </p>
                <p>
                  Private Bank Team: <a href='tel:+44 (0) 1933 543 600'>+44 (0) 1933 543 600</a>
                </p>
                <p>
                  Racing Bank Team: <a href='tel:+44 (0) 1933 543 543 '>+44 (0) 1933 543 543</a>
                </p>
                <p>
                  Email: <a href='mailto:bank@weatherbys.bank'>bank@weatherbys.bank</a>
                </p>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </Wrapper>
    </div>
  )
}

export default LoggedOutLayout
