import get from 'lodash/get'
import moment from 'moment'

export default (
  data: {
    data: {
      czItems: {
        transactionDate: string
      }
    }
  },
  state
) => {
  const txnData = get(data, 'data.czItems')
  let date: any = {}
  let endDate = ''
  let startDate = ''
  if (txnData.length > 0) {
    endDate = moment(txnData[0].transactionDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
    startDate = moment(endDate, 'YYYY-MM-DD').subtract(1, 'month').format('YYYY-MM-DD')
    date = {
      endDate,
      startDate,
    }
  }

  return date
}
