import includes from 'lodash/includes'
import get from 'lodash/get'
import formatMoney from '../formatMoney'
import formatSortCode from '../formatSortCode'
import { branchCodeToSortCode } from '../lookup/branchCodes'
import { decodeHTML } from '../../utils/decodeHTML'

const typeToGroup = {
  CA: 'current',
  RA: 'racing',
  TD: 'deposit',
  IA: 'deposit',
  NT: 'deposit',
  LN: 'lending',
  MO: 'lending',
}

export interface IAccountsSummaryRaw {
  dictionaryArray: {
    nameValuePairDTOArray: {
      name: string
      value: string
    }[]
  }
  id: {
    displayValue: string
    value: string
  }
  partyId: {
    displayValue: string
    value: string
  }
  displayName: string
  status: string
  type: string
  currencyCode: string
  branchCode: string | number
  productDTO: {
    description: string
    productClass: string
    productGroup: string
    productId: string
    currencySpecificParameterDTO: {
      minimumBalanceAmount: {
        amount: string | number
      }
    }
    demandDepositProductFacilitiesDTO: {
      hasChequeBookFacility: boolean
      hasOverDraftFacility: boolean
      hasPassbookFacility: boolean
      hasATMFacility: boolean
    }
  }
  openingDate: string
  partyName: string
  holdingPattern: string
  module: string
  ddaAccountType: string
  availableBalance: {
    currency: string
    amount: string | number
  }
  currentBalance: {
    currency: string
    amount: string | number
  }
  noOfDebitCards: number
  equivalentAvailableBalance: {
    currency: string
    amount: string | number
  }
  nomineeRegistered: boolean
}

export interface IAccountsSummary {
  entities: {
    [key: string]: {
      displayId: string
      id: string | boolean // todo - needs to refactor for it to be only string
      title: string
    }
  }
  items: {
    [key: string]: {
      accountId: string
      accountNumber: string
      accountType: string
      availableBalance: string
      availableBalancePure: number
      balance: string
      balancePure: number
      branchCode: string
      canTransferGlobal: boolean
      canTransferLocal: boolean
      currency: string // 'GBP'
      customerId: string
      group: string
      isInternational: boolean
      order: number
      originalType?: string
      partyId: string
      productType: string
      sortCode?: string
      statementsDisabled?: boolean
      title: string
      transferOutDisabled?: boolean
      type: string
      typeTitle: string
    }
  }
}

/**
 * Tidies deeply nested and poorly named accounts data from API into something
 * easier to deal with!
 * @param  {Object} data - raw data from API
 * @return {Object} data - tidied data
 */

export default (
  data: {
    data: {
      accounts: IAccountsSummaryRaw
    }
  },
  state
): IAccountsSummary => {
  // Need to check this again - as it may be an array
  const response = data.data
  const tidyData: any = {}
  const currenciesForTransferGlobal = {}
  let currenciesForTransferLocal = {}
  const takenOrderNumbers = []
  const tempAccountsWithoutOrder = []

  // Before anything else, update the users preferred name if they have one
  let udf = get(response, 'accounts', [])
  if (!(udf instanceof Array)) udf = [udf]
  const nameField = udf.find((field) => field.accountNickName !== undefined)
  if (nameField !== undefined) {
    tidyData.preferredName = nameField
  }
  // Also need to get all the correct entity names
  let entities = get(response, 'accounts', [])
  if (!(entities instanceof Array)) entities = [entities]
  tidyData.entities = entities.reduce((acc, entity) => {
    acc[String(entity.partyId.value)] = {
      id: String(entity.partyId.value),
      title: decodeHTML(entity.partyName),
      displayId: String(entity.partyId.displayValue),
    }
    return acc
  }, {})

  let customers = get(response, 'accounts', [])

  if (!(customers instanceof Array)) {
    customers = [customers]
  }

  tidyData.items = {}

  // Count how many currencies there are for accounts that can transfer
  customers.forEach((customer) => {
    const curr = customer.currencyCode
    currenciesForTransferLocal = {}
    if (currenciesForTransferGlobal[curr]) {
      currenciesForTransferGlobal[curr] += 1
    } else {
      currenciesForTransferGlobal[curr] = 1
    }
    if (currenciesForTransferLocal[curr]) {
      currenciesForTransferLocal[curr] += 1
    } else {
      currenciesForTransferLocal[curr] = 1
    }
  })
  customers.forEach((customer) => {
    const curr = customer.currencyCode

    const customValues = customer.dictionaryArray[0].nameValuePairDTOArray
    const type = customValues.filter((item) => item.name === 'SubGroup')[0].value
    const accountType = customer.type
    const productType = customer.productDTO.productId
    let typeTitle
    let statementsDisabled
    let transferOutDisabled

    const currCountGlobal = currenciesForTransferGlobal[curr]
    const currCountLocal = currenciesForTransferLocal[curr]
    const canTransferGlobal = currCountGlobal && currCountGlobal > 1
    const canTransferLocal = currCountLocal && currCountLocal >= 1
    const group = typeToGroup[type]
    let sortCode = formatSortCode(branchCodeToSortCode[customer.branchCode])
    let balance = customer.currentBalance
      ? formatMoney(customer.currentBalance.amount, curr, true)
      : ''
    let availableBalance = customer.availableBalance
      ? formatMoney(customer.availableBalance.amount, curr, true)
      : ''
    const isInternational = curr !== 'GBP'

    switch (type) {
      case 'CA':
        typeTitle = 'Private Bank Current Account'
        break
      case 'RA':
        typeTitle = 'Racing Bank Account'
        break
      case 'TD':
        typeTitle = 'Fixed Rate Bond'
        sortCode = undefined
        balance = `${formatMoney(customer.principalAmount.amount, curr)} CR`
        availableBalance = `${formatMoney(customer.availableBalance.amount, curr)} CR`
        break
      case 'IA':
        typeTitle = 'Instant Access'
        break
      case 'NT':
        typeTitle = 'Notice Account'
        transferOutDisabled = true
        break
      case 'LN':
        statementsDisabled = true
        sortCode = undefined
        typeTitle = 'Loan'
        balance = `-${formatMoney(
          customer.outstandingLoanDetailsDTO.principalBalance.amount,
          curr
        )} DR`
        availableBalance = `-${formatMoney(
          customer.outstandingLoanDetailsDTO.outstandingAmount.amount,
          curr
        )} DR`
        break
      case 'MO':
        statementsDisabled = true
        sortCode = undefined
        typeTitle = 'Mortgage'
        balance = `-${formatMoney(
          customer.outstandingLoanDetailsDTO.principalBalance.amount,
          curr
        )} DR`
        availableBalance = `-${formatMoney(
          customer.outstandingLoanDetailsDTO.outstandingAmount.amount,
          curr
        )} DR`
        break
      default:
    }
    const orderFieldName =
      'com.ofss.digx.cz.app.account.dto.nickname.CZAccountNicknameDTO.sequenceNumber'
    const orderFields = customValues.filter((item) => item.name === orderFieldName)
    const orderField = orderFields.length !== 0 ? orderFields[0].value : ''
    let avBalance

    if (customer.availableBalance !== undefined) {
      avBalance = parseFloat(customer.availableBalance.amount)
    } else {
      avBalance = ''
    }
    const nickName = customer.accountNickname
    const { displayName } = customer
    const { partyName } = customer
    const accountData = {
      customerId: String(customer.partyId.value),
      accountNumber: String(customer.id.displayValue),
      accountId: String(customer.id.value),
      partyId: String(customer.partyId.displayValue),
      currency: customer.currencyCode,
      originalType: customer.productDTO.name,
      branchCode: customer.branchCode,
      sortCode,
      type,
      accountType,
      productType,
      typeTitle,
      group,
      isInternational,
      canTransferGlobal,
      canTransferLocal,
      statementsDisabled,
      balance,
      transferOutDisabled,
      balancePure:
        customer.currentBalance !== undefined ? parseFloat(customer.currentBalance.amount) : '',
      availableBalance,
      availableBalancePure: avBalance,
      // eslint-disable-next-line no-nested-ternary
      title: nickName
        ? decodeHTML(String(nickName))
        : displayName
        ? decodeHTML(customer.displayName)
        : partyName,
    }

    if (orderField && String(orderField) !== '0') {
      if (includes(takenOrderNumbers, orderField)) {
        tempAccountsWithoutOrder.push(accountData)
      }
      takenOrderNumbers.push(String(orderField))
      tidyData.items[String(customer.id.displayValue)] = {
        ...accountData,
        order: Number(orderField),
      }
    } else {
      tempAccountsWithoutOrder.push(accountData)
    }
  })
  const highestNumInSeq = takenOrderNumbers.reduce((prev, next) => Math.max(prev, next), 0)

  tempAccountsWithoutOrder.forEach((val, index) => {
    const orderNumber = highestNumInSeq + index + 1
    tidyData.items[String(val.accountNumber)] = {
      ...val,
      order: orderNumber,
    }
  })
  return tidyData
}
