import React from 'react'
import { Field } from 'redux-form'
import { Dispatch } from 'redux'
import SingleField from '../SingleField'
import SortCode from '../SortCode'
import s from './styles.css'
import SwitchWithLabels from '../SwitchWithLabels'
import { accountNo as accountNoNormaliser } from '../../../utils/forms/normalizers'
import {
  accountNo as accountNoValidator,
  payeeName as payeeNameValidator,
  sortCode as sortCodeValidator,
  reference as referenceValidator,
} from '../../../utils/forms/validators'
import { modalShow } from '../../../store/ui'
import Modal from '../../../containers/Modal'
import COPLargeInformation from '../../../assets/icons/COPLargeInformation'

type PayeeFieldsProps = {
  onSortCodeChange: () => void
  bankName?: string
  accountType: string
  referenceChecked: boolean
  dispatch: Dispatch
  error?: any
}

const PayeeFields = ({
  onSortCodeChange,
  bankName,
  accountType,
  referenceChecked,
  dispatch,
  error,
}: PayeeFieldsProps) => (
  <div>
    {/* Account type switcher */}
    <Field
      name='accountType'
      label='Account type'
      component={SwitchWithLabels}
      options={['Personal', 'Business']}
      initialValues='Personal'
      iconString='copInformation'
      onIconClick={() => {
        dispatch(modalShow('payeeFieldsAccountType'))
      }}
    />

    {/* Payee name */}
    <Field
      name='name'
      label={accountType === 'Personal' ? 'Full name on account' : 'Full business name'}
      placeholder={accountType === 'Personal' ? 'Add full name' : 'Add business name'}
      type='text'
      component={SingleField}
      props={{ autoFocus: true }}
      validate={payeeNameValidator}
      validationMessages={{
        maxPayeeNameAN: 'Must be 35 alphanumeric characters or less',
        required: 'What is the name of the payee?',
        onlyPermittedChars: 'Allowed characters: A-Z 0-9 $ @ _ #',
      }}
      iconString='copInformation'
      onIconClick={() => {
        dispatch(modalShow(`payeeFieldsName${accountType}`))
      }}
    />

    {/* Payee account number */}
    <Field
      name='accountNumber'
      label='Account number'
      placeholder='Add 8 digit account number'
      type='text'
      component={SingleField}
      normalize={accountNoNormaliser}
      validate={accountNoValidator}
      validateType='onBlur'
      validationMessages={{
        required: 'Please enter your sort code',
        onlyLength8: 'The account number should be 8 digits',
      }}
    />

    {/* Payee sort code */}
    <div className={s.row}>
      <Field
        name='sortCode'
        label='Sort code'
        component={SingleField}
        InputComponent={SortCode}
        validate={sortCodeValidator}
        onChange={onSortCodeChange} // if there is an error, avoiding this request as it's already been validated
        validateType='onBlur'
        validationMessages={{
          required: 'What is the sort code',
          onlyLength6: 'Sort code should look like this: 12 - 34 - 56',
          badSortCode: 'Sort code is not recognised, please check',
          noFastPayments: 'This bank does not currently accept faster payments',
        }}
      />
      {bankName && <p className={s.bankName}>{bankName}</p>}
    </div>

    {/*
      Here are a list of outstanding actions that need to be completed/answered before we can ship this monday.
      TODO: What validations need to be applied to these fields? (API Limits/Restrictions?)
    */}

    {/* Payee reference */}
    <Field
      name='reference'
      label='Reference (optional)'
      type='text'
      component={SingleField}
      canToggleField
      toggleFieldStatus={referenceChecked}
      validate={referenceChecked && referenceValidator}
      iconString='copInformation'
      onIconClick={() => {
        dispatch(modalShow('payeeFieldsReference'))
      }}
    />

    {/* Payee description */}
    <Field
      name='payeeDescription'
      label='Payee description (optional)'
      type='text'
      component={SingleField}
      iconString='copInformation'
      onIconClick={() => {
        dispatch(modalShow('payeeFieldsPayeeDescription'))
      }}
    />

    <Modal id='payeeFieldsAccountType' size='medium' noScroll className={s.modalComponent}>
      <div className={s.modal}>
        <div className={s.modalIcon}>
          <COPLargeInformation className={s.modalSVG} />
        </div>
        <div className={s.modalContent}>
          <div className={s.modalHeader}>
            <h1>Account type</h1>
          </div>
          <div className={s.modalText}>
            <p>Please select if the payee account is a Personal or Business Account.</p>
            <p>If you are unsure, please check with the payee.</p>
          </div>
        </div>
      </div>
    </Modal>
    <Modal id='payeeFieldsNamePersonal' size='medium' noScroll className={s.modalComponent}>
      <div className={s.modal}>
        <div className={s.modalIcon}>
          <COPLargeInformation className={s.modalSVG} />
        </div>
        <div className={s.modalContent}>
          <div className={s.modalHeader}>
            <h1>Full name on account</h1>
          </div>
          <div className={s.modalText}>
            <p>Please enter the full name of the account you are trying to pay.</p>
            <p>If you are unsure, please check with the payee.</p>
          </div>
        </div>
      </div>
    </Modal>
    <Modal id='payeeFieldsNameBusiness' size='medium' noScroll className={s.modalComponent}>
      <div className={s.modal}>
        <div className={s.modalIcon}>
          <COPLargeInformation className={s.modalSVG} />
        </div>
        <div className={s.modalContent}>
          <div className={s.modalHeader}>
            <h1>Full business name</h1>
          </div>
          <div className={s.modalText}>
            <p>Please enter the full business name you are trying to pay.</p>
            <p>If you are unsure, please check with the payee.</p>
          </div>
        </div>
      </div>
    </Modal>
    <Modal id='payeeFieldsReference' size='medium' noScroll className={s.modalComponent}>
      <div className={s.modal}>
        <div className={s.modalIcon}>
          <COPLargeInformation className={s.modalSVG} />
        </div>
        <div className={s.modalContent}>
          <div className={s.modalHeader}>
            <h1>Reference</h1>
          </div>
          <div className={s.modalText}>
            <p>
              Enter the unique code or identifier provided by the payee to ensure your payment is
              accurately processed.
            </p>
            <p>
              If you will use a different reference each time or do not need one at all, please
              leave this field blank.
            </p>
            <p>If you are unsure, please check with the payee.</p>
          </div>
        </div>
      </div>
    </Modal>
    <Modal id='payeeFieldsPayeeDescription' size='medium' noScroll className={s.modalComponent}>
      <div className={s.modal}>
        <div className={s.modalIcon}>
          <COPLargeInformation className={s.modalSVG} />
        </div>
        <div className={s.modalContent}>
          <div className={s.modalHeader}>
            <h1>Payee description</h1>
          </div>
          <div className={s.modalText}>
            <p>Add a nickname to help you identify this account in your payees list.</p>
          </div>
        </div>
      </div>
    </Modal>
  </div>
)

export default PayeeFields
