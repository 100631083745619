/* eslint-disable prefer-destructuring */
import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects'
import request from '../../utils/request'
import humanError from '../../utils/humanError'
import { decodeHTML } from '../../utils/decodeHTML'
import * as c from '../../constants'
import * as a from './actions'
import { modalErrorShow } from '../../store/ui'
import { flowSuccess } from '../../store/flow'
import { formError } from '../../sagas/formErrors/actions'
import { getBulletinIds } from './selectors'
// import GA from '../../utils/GA'

export function* fetchItems(action) {
  const p = action.payload
  try {
    const response1 = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    let response: any = {} // todo-ts: was ''
    if (p.folderName === 'bulletins') {
      response = yield call(request, {
        id: c.requestBulletins,
        url: 'v1/mailbox/mailers',
        nonce: response1,
      })
    } else if (p.folderName === 'outbox') {
      response = yield call(request, {
        id: c.requestMail,
        url: 'v1/mailbox/mails?deleteStatus=false&msgFlag=F',
        nonce: response1,
      })
    } else {
      response = yield call(request, {
        id: c.requestMail,
        url: 'v1/mailbox/mails?deleteStatus=false&msgFlag=T',
        nonce: response1,
      })
    }
    yield put(a.mailListUpdate(p.folderName, response.items))
  } catch (error) {
    const message = yield call(humanError, error)
    yield put(modalErrorShow(message))
  }
}

export function* fetchDetail(action) {
  const p = action.payload
  let id
  let mapId
  try {
    const nonce = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    let data: any = {} // todo-ts: was ''
    if (p.folderName === 'bulletins') {
      id = p.id.split('-')[0]
      mapId = p.id.split('-')[1]
      data = yield call(request, {
        id: c.requestBulletinDetail,
        url: `v1/mailbox/mailers/${id}`,
        nonce,
        mailId: p.id,
      })
      data.detail.body = decodeHTML(data.detail.body)
    } else if (p.folderName === 'outbox') {
      data = yield call(request, {
        id: c.requestMailDetail,
        url: `v1/mailbox/mails/${p.id}`,
        nonce,
        mailId: p.id,
      })
    } else {
      data = yield call(request, {
        id: c.requestMailDetail,
        url: `v1/mailbox/mails/${p.id}`,
        nonce,
        mailId: p.id,
      })
    }
    yield put(a.mailDetailUpdate(p.id, p.folderName, data.detail))
    if (p.folderName === 'inbox') {
      const nonceUpdate = yield call(request, {
        id: c.requestNonce,
        url: 'v1/session/nonce',
      })
      yield call(request, {
        id: c.requestUpdateMailStatus,
        url: `v1/mailbox/mails/${p.id}`,
        nonce: nonceUpdate,
        payload: {
          id: p.id,
        },
      })
    }
    if (p.folderName === 'bulletins') {
      const nonceUpdate = yield call(request, {
        id: c.requestNonce,
        url: 'v1/session/nonce',
      })
      yield call(request, {
        id: c.requestUpdateBulletinsStatus,
        url: `v1/mailbox/mailers/${mapId}`,
        nonce: nonceUpdate,
        payload: {
          id,
          mapId,
        },
      })
    }
  } catch (error) {
    const message = yield call(humanError, error)
    yield put(modalErrorShow(message))
  }
}

export function* handleSend(action) {
  const p = action.payload
  let url = 'v1/mailbox/mails'
  let partyId
  let subject
  let subjectId
  let messageBody
  let messageId
  let mailItem: any = {} // todo-ts: was ''
  let reply = false
  let parentDetails = {}
  if (p.customerId) {
    partyId = p.customerId.value
    subject = p.subjectCode.label
    subjectId = p.subjectCode.value
    messageBody = p.body
  } else if (p.replyingTo) {
    messageId = p.replyingTo
    mailItem = yield select((state: any) => state.mail.inbox.items[messageId])
    messageBody = p.body
    partyId = mailItem.party
    subjectId = mailItem.subjectId
    url = `${url}/${mailItem.Id}/replyMail`
    subject = `Re: ${p.customSubject}`
    reply = true
    parentDetails = {
      messageBody: mailItem.body,
      subject: mailItem.subject,
    }
  }
  try {
    const nonce = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    yield call(request, {
      id: c.requestMailSend,
      url,
      nonce,
      partyId,
      payload: {
        messageBody,
        messageType: 'M',
        subjectId,
        subject,
        party: partyId,
        messageId,
        reply,
        parentDetails,
      },
    })
    yield put(flowSuccess())
    // #GA4-PROVISIONALLY-DISABLED
    // GA.event({
    //   category: 'Form',
    //   action: 'Submit',
    //   label: `Secure Message - ${p.subjectCode.label}`,
    // })
  } catch (error) {
    console.error(error)
    yield put(formError(a.send.FAILURE, error))
  }
}

export function* fetchBulletins(action) {
  const ids = yield select(getBulletinIds)

  for (let i = 0; i < ids.length; i += 1) {
    yield put(a.mailDetailFetchRequest(ids[i], 'bulletins'))
  }
}

export function* watchMail() {
  yield takeEvery(c.MAIL_LIST_FETCH_REQUEST, fetchItems)
  yield takeLatest(a.send.REQUEST, handleSend)
  yield takeEvery(c.MAIL_DETAIL_FETCH_REQUEST, fetchDetail)
  yield takeLatest(c.MAIL_BULLETINS_FETCH, fetchBulletins)
}
