import React from 'react'
import ModalLink from '../../containers/ModalLink'
import Modal from '../../containers/Modal'
import Icon from '../Icon'
import SubmitCancel from '../Forms/SubmitCancel'
import s from './styles.css'

type FooterModalProps = {
  id: string
  link: string
  message: string
}

const FooterModal = ({ id, link, message }: FooterModalProps) => (
  <Modal id={id} size='small' title='External Website'>
    <p>{message}</p>
    <SubmitCancel
      submitText='Proceed'
      onSubmitClick={() => window.open(link, '_blank')}
      submitClosesModal
      cancelClosesModal
    />
  </Modal>
)

type FooterProps = {
  linkMessage: string
  version: string
}

export const Footer = ({ linkMessage, version }: FooterProps) => (
  <footer className={s.root}>
    <div className={s.inner}>
      <p className={s.name}>Weatherbys Bank</p>

      <ul className={s.links}>
        <li>
          <ModalLink id='footerTerms'>Terms and Conditions</ModalLink>
        </li>
        <li>
          <ModalLink id='footerPrivacy'>Privacy Policy</ModalLink>
        </li>
        <li>
          <ModalLink id='footerContact'>Contact</ModalLink>
        </li>
        <li>
          <span className={s.version}>v{version}</span>
        </li>
      </ul>

      <FooterModal
        id='footerTerms'
        message={linkMessage}
        link='https://www.weatherbys.bank/terms-and-conditions'
      />
      <FooterModal
        id='footerPrivacy'
        message={linkMessage}
        link='https://www.weatherbys.bank/privacy-policy'
      />
      <FooterModal
        id='footerContact'
        message={linkMessage}
        link='https://www.weatherbys.bank/contact'
      />
      <FooterModal id='modalVersion' message={linkMessage} link='https://www.weatherbys.bank/' />

      <div className={s.icon}>
        <Icon icon='weatherbys' />
      </div>
    </div>
  </footer>
)

export default Footer
