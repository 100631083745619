import omit from 'lodash/omit'
import * as c from '../../constants'
import formatMoney from '../../utils/formatMoney'

export interface ListState {
  items?: {
    details?: any
    pureAmount?: any
    currency?: any
    amount?: any
  }
  isFetching?: boolean
  errorMessage?: boolean
  errorCode?: string
}

const defaultState: ListState = {
  items: {},
  isFetching: true,
  errorMessage: false,
}

export default (listName = '') =>
  (state = defaultState, action): ListState => {
    const { name } = action
    const p = action.payload
    if (name !== listName) return state

    switch (action.type) {
      case c.LIST_UPDATE_ALL: {
        return {
          ...state,
          items: p.items,
          isFetching: false,
          errorMessage: false,
        }
      }
      case c.LIST_ITEM_UPDATE: {
        return {
          ...state,
          items: {
            ...state.items,
            [p.id]: {
              ...state.items[p.id],
              ...p.details,
            },
          },
        }
      }
      case c.LIST_MERGE_ITEMS: {
        return {
          ...state,
          items: {
            ...state.items,
            ...p.items,
          },
        }
      }
      case c.LIST_CLEAR_ITEMS: {
        return {
          ...state,
          items: {},
        }
      }
      // used exclusevily for bulk items
      case c.LIST_MERGE_ITEM_DETAILS: {
        return {
          ...state,
          items: {
            ...state.items,
            details: [...state.items.details, ...p.items.details],
            pureAmount: state.items.pureAmount + p.items.pureAmount,
            amount: formatMoney(state.items.pureAmount + p.items.pureAmount, state.items.currency),
          },
        }
      }
      case c.LIST_ITEM_DELETE: {
        return {
          ...state,
          items: omit(state.items, [p.id]),
        }
      }
      case c.LIST_FETCH_START: {
        return {
          ...state,
          isFetching: true,
          errorMessage: false,
        }
      }
      case c.LIST_FETCH_FAIL: {
        return {
          ...state,
          isFetching: false,
          items: {},
          errorMessage: p.error,
          errorCode: typeof p.error === 'object' ? p.error.code : '',
        }
      }
      case c.LIST_FETCH_STOP: {
        return {
          ...state,
          isFetching: false,
        }
      }
      default:
        return state
    }
  }
