import { connect } from 'react-redux'
import TransferMoneySuccess from '../../components/TransferMoneySuccess'
import generatePaymentDetails from '../../utils/generatePaymentDetails'
import { saveeReceipt, showRecurringWarning } from './selectors'
import * as a from '../../store/flow'
import { modalShow } from '../../store/ui'
import { isAndroid } from '../../utils/getPlatform'

const mapStateToProps = (state, ownProps) => {
  const d = ownProps.data
  const details = generatePaymentDetails(d)

  // On recurring payments, we show a warning if the selected date is today
  // as the first payment from recurring payment will always be tomorrow
  const recurringWarnings = showRecurringWarning(d)
  // Warnings that come back from the server are for things like when the
  // scheduled date is set to a weekend or bank holiday
  const apiWarnings = d.warnings

  const warnings = []
  if (recurringWarnings)
    warnings.push('Your first payment will be tomorrow (or the next working day)')
  if (apiWarnings) warnings.push(apiWarnings)
  return {
    details,
    warnings,
    needsAuthoriser: d.needsAuthoriser,
    timeOutError: d.timeOutError,
    referenceNumber: d.referenceNumber,
    type: d.type,
    onReceiptClick: () => saveeReceipt(details, state),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onReceiptModal: () => {
    if (isAndroid()) {
      dispatch(modalShow('eReceipt'))
    }
  },
  onPrintClick: () => {
    window.print()
  },
  onMakeAnotherClick: () => dispatch(a.flowReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(TransferMoneySuccess)
