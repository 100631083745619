import React from 'react'
import Breadcrumbs from '../Breadcrumbs'
import ActionHeader from '../ActionHeader'
import InnerContainer from '../InnerContainer'
import StickyTable from '../StickyTable'
import FilterPanel from '../../containers/FilterPanel'
import SuperDatePicker from '../../containers/SuperDatePicker'
import SuperDatePickerField from '../../containers/SuperDatePickerField'
import SlideyOrModal from '../../containers/SlideyOrModal'
import s from './styles.css'

type PaymentsBulkListViewProps = {
  onDateClick?: () => void
  startDate?: string // todofixed - was object
  endDate?: string // todofixed - was object
  dateRangeText?: string
  datePickerShowing?: boolean
  onDateSubmit?: () => void
  isFetching?: boolean
  rows: any[] // todo - was added as StickyTable requires it
  headerCols: any[] // todo - was added as StickyTable requires it
}

const PaymentsBulkListView = (props: PaymentsBulkListViewProps) => (
  <div>
    <Breadcrumbs
      items={[
        {
          to: '/payments',
          text: 'Payments',
        },
        {
          to: '/payments/bulk',
          text: 'Bulk payments',
        },
        {
          to: '/payments/bulk/all',
          text: 'Past uploads',
        },
      ]}
    />
    <InnerContainer>
      <ActionHeader title='Past uploads' />
    </InnerContainer>
    {/* todo - simpleResultsState not sure what this is used for */}
    {/* <StickyTable {...props} simpleResultsState> */}
    <StickyTable {...props}>
      <FilterPanel>
        <SuperDatePickerField id='paymentsBulkFiles' />
        <SlideyOrModal id='datePicker'>
          <SuperDatePicker id='paymentsBulkFiles' onSubmit={props.onDateSubmit} />
        </SlideyOrModal>
      </FilterPanel>
    </StickyTable>
    {!props.isFetching && (
      <div className={s.bottomMessage}>
        <p>To view other dates please select a different date range.</p>
      </div>
    )}
  </div>
)

export default PaymentsBulkListView
