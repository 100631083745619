/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
import React from 'react'
import s from './MainError.css'

type MainErrorProps = {
  errors: {
    other?: any[]
    misc?: any[]
    main?: any
  }
}

const MainError = ({ errors: { other, misc, main } }: MainErrorProps) => (
  <div className={s.root}>
    <legend className={s.title}>Error Log</legend>
    {other.map((error) => error && error.map((desc) => <p className={s.otherError}>{desc}</p>))}
    {misc.map((error) => (
      <p className={s.otherError}>{error && error.error && error.error._errordesc}</p>
    ))}
    {main.length > 0 && <p>Records:</p>}
    <ol className={s.allErrors}>
      {main.map((error, idx) => (
        <li key={`${error}-${idx}`}>
          <span className={s.subDescription}>
            {error ? 'The following errors are present in this record:' : 'No errors found.'}
          </span>
          <ol className={s.recordErrors}>
            {error && error.map((desc, eIdx) => <li key={`${desc}-${eIdx}`}>{desc}</li>)}
          </ol>
        </li>
      ))}
    </ol>
  </div>
)

export default MainError
