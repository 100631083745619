import React from 'react'
import s from './styles.css'
import NavItem from '../../../containers/NavItem'

const PrimaryNav = () => (
  <nav className={s.root}>
    <ul>
      <li>
        <NavItem icon='home' navId='accounts'>
          <span>My Accounts</span>
        </NavItem>
      </li>
      <li>
        <NavItem icon='payments' navId='payments'>
          <span>
            Payments<span className={s.longText}>{'\u00A0'}& Transfers</span>
          </span>
        </NavItem>
      </li>
      {/* <li>
        <NavItem glyph={weatherbysIcon} navId='services'>
          <span>More Services</span>
        </NavItem>
      </li> */}
    </ul>
  </nav>
)

export default PrimaryNav
