import React from 'react'
import InnerContainer from '../InnerContainer'
import DataTable from '../../containers/DataTable'
import StopModal from './StopModal'
import Stripey from '../Stripey'
import s from './styles.css'

type PaymentsRecurringSectionProps = {
  title: string
  showStripey?: boolean
  handleSubmit: () => void // todo - was added StopModal requires it
  paymentsRecurringStop: () => void // todo - was added StopModal requires it
  type: string // todo - was added StopModal requires it
  typeName: string // todo - was added StopModal requires it
}

export const PaymentsRecurringSection = (props: PaymentsRecurringSectionProps) => (
  <div className={s.root}>
    <InnerContainer>
      <h2>{props.title}</h2>
    </InnerContainer>
    <DataTable {...props} simpleResultsState />
    <StopModal {...props} />
    {props.showStripey && <Stripey className={s.recurringStripey} />}
  </div>
)

export default PaymentsRecurringSection
