import { connect } from 'react-redux'
import { change } from 'redux-form'
import get from 'lodash/get'
import PaymentMakeForm from '../../components/PaymentMakeForm'
import {
  getFromAccount,
  getToAccount,
  getHasReference,
  getPaymentMakeDetails,
  getExistingPayees,
  getToId,
} from '../PaymentMake/selectors'
import { paymentMakeVerify, paymentMakeVerifySubmit } from '../PaymentMake/actions'
import { modalShow } from '../../store/ui'

const mapStateToProps = (state) => {
  const newPayee = getPaymentMakeDetails(state).isNewPayee
  const toAccount = getToAccount(state)

  // TODO: Do we want this behaviour to be as follows:
  //   where lastPaymentReference is not undefined (previously used?) we can default to payee reference
  //   or we can always default to payee reference.
  return {
    currency: getFromAccount(state).currency,
    lastReference: toAccount.lastPaymentReference,
    initialValues: {
      when: 'now',
      frequencyCode: {
        value: '50',
      },
      repeatUntil: {
        value: 'forever',
      },
      isRegular: get(state, 'routing.locationBeforeTransitions.query.isRegular'),
      reference: newPayee ? toAccount.reference : toAccount.payeeReference,
    },
    isSubmitDisabled: state.ui.panel === 'changeAccount',
    hasReference: getHasReference(state),
    copState: state.cop,
    verificationReport: getExistingPayees(state)[getToId(state)]?.verificationReport,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  paymentVerify: paymentMakeVerify,
  onClickLastReference: (ref) => {
    dispatch(change('paymentMakeVerify', 'reference', ref))
  },
  checkReference: (e, hasRef) => {
    e.preventDefault()
    if (hasRef) {
      dispatch(paymentMakeVerifySubmit)
    } else {
      dispatch(modalShow('paymentNoReference'))
    }
  },
  onSubmitButtonClick: () => {
    dispatch(paymentMakeVerifySubmit)
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMakeForm)
