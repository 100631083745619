import React from 'react'
import Breadcrumbs from '../Breadcrumbs'
import BulletPoints from '../BulletPoints'
import ContactContainer from '../ContactContainer'
import InnerFullWidthContainer from '../InnerFullWidthContainer'
import ServicesHeader from '../ServicesHeader'
import s from '../ServicesView/styles.css'

const addPadding = true
const listStyle = true

export const ServicesView = (/* { mappingError } */) => (
  <div>
    <Breadcrumbs
      items={[
        {
          to: '/services',
          text: 'Additional services',
        },
        {
          to: '/services/mortgageloans',
          text: 'Mortgages & loans',
        },
      ]}
    />
    <ServicesHeader
      title='Common sense lending'
      secondaryTitle='Tailored, flexible lending solutions'
    />
    <InnerFullWidthContainer addPadding={addPadding}>
      <div className={`${s.textArea} ${s.extraTopMargin}`}>
        <p>
          We always take a holistic view of every client’s financial situation so we can make swift,
          sensible decisions about mortgages and loans, whether it’s for:
        </p>
      </div>
      <BulletPoints
        listStyle={listStyle}
        items={[
          {
            bulletPoint: 'owner-occupied property',
          },
          {
            bulletPoint: 'buy-to-let property',
          },
          {
            bulletPoint: 'bridging finance',
          },
        ]}
      />
      <div className={`${s.textArea}`}>
        <p>
          Our Private Banking team’s personal attention to detail and clear communication keeps the
          process as stress-free as possible from start to finish. They can also work directly with
          third parties such as your surveyor and lawyer.
        </p>
      </div>
      <div className={`${s.textArea}`}>
        <p className={s.italicFont}>
          We treat all mortgage and loan applicants equally, regardless of your residency status or
          whether you hold investments with us. We also consider applications from borrowers over 70
          and can take into account other assets that you hold.
        </p>
      </div>
      <ContactContainer
        title='Find out more'
        infoText='To find out more about our lending solutions, please contact the Private Bank team'
        items={[
          {
            linkTitle: '+44 (0) 20 7292 9029',
            to: '+44 (0) 20 7292 9029',
            telNumber: true,
          },
          // {
          //   linkTitle: 'Send Secure Message',
          //   to: '/secure-mail/new'
          // }
          {
            linkTitle: 'Send Secure Message',
            to: '/secure-mail/enquiry#mortgageloans',
          },
        ]}
      />
    </InnerFullWidthContainer>
  </div>
)

export default ServicesView
