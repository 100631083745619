import React from 'react'
import { Link } from 'react-router'
import s from './primary.css'

const Primary = () => (
  <div className={`${s.root} ${s.darkSection}`}>
    <h2 className={s.heading}>MORTGAGES & LOANS</h2>
    <p>
      At Weatherbys, we take a personal approach to lending, creating tailored, flexible mortgages
      and bespoke lending solutions to meet your needs
    </p>
    <div className={s.buttons}>
      <Link to='/services/mortgageloans' className={s.button}>
        Find out more
      </Link>
    </div>
  </div>
)

export default Primary
