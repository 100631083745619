import { dontDuplicate } from '../utils/routerBugWorkaround'

export default (store) => ({
  path: '/accounts/statements(/:id)',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const Statements = require('../containers/Statements').default
        const { uStatementsFetchRequest } = require('../containers/Statements/actions')

        dontDuplicate(() => {
          store.dispatch(uStatementsFetchRequest(nextState.params.id))
        })

        cb(null, Statements)
      },
      'statements'
    )
  },
})
