import accountMappingHelper from './accountMappingHelper'
/**
 * Tidies deeply nested and poorly named data from API into something
 * easier to deal with!
 * @param  {Object} data - raw data from API
 * @param {Object} state - current state of application
 * @return {Object} Accounts - Accounts Ids that are mapped
 */
export default (data, state) =>
  accountMappingHelper(data, state, 'prepareregisteralertresponsedto.custaccounts')
