/* eslint-disable no-shadow */
// eslint-disable-next-line import/prefer-default-export
export enum MaxHeightEnum {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum ChangeCredentialsSteps {
  welcome = 'welcome',
  terms = 'terms',
  pass = 'pass',
  passChanged = 'passChanged',
  pin = 'pin',
  success = 'success',
}

export enum ManageCredentialsType {
  password = 'password',
}

export enum PasswordPolicyEnum {
  CorporatePolicy = 'CorporatePolicy',
}

export enum DIGXErrorCodes {
  DIGX_WW_017 = 'DIGX_WW_017', // We recieve this code when the cutoff period is enabled.
}

export type SupportedCurrenciesType = keyof typeof SupportedCurrencies

export enum SupportedCurrencies {
  '£' = 'GBP',
  '$' = 'USD',
  '€' = 'EUR',
}
