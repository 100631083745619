import { connect } from 'react-redux'
import PendingTransactionsTable from '../../components/PendingTransactionsTable'
import { showPendingTransactionsFunc, hidePendingTransactionsFunc } from './actions'

const mapStateToProps = (state, ownProps) => ({
  showPendingTransactions: state.pendingTransactions.showPendingTransactions,
  accounts: state.accounts,
  rows: ownProps.rows,
  filters: ownProps.filters,
})

const mapDispatchToProps = (dispatch) => ({
  hidePendingTransactionsFunc() {
    dispatch(hidePendingTransactionsFunc())
  },
  showPendingTransactionsFunc() {
    dispatch(showPendingTransactionsFunc())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PendingTransactionsTable)
