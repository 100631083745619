/* eslint-disable array-callback-return */
import values from 'lodash/values'
import get from 'lodash/get'
import X2JS from 'x2js'
import moment from 'moment'
import getVisibleAccounts from '../../selectors/getVisibleAccounts'
import getAccount from '../../selectors/getAccount'
import niceTableData from '../../utils/niceTableData'
import getURL from '../../utils/getURL'
import { saveeReceiptPDF } from '../../utils/savePDF'
import saveFile from '../../utils/saveFile'

export const getBulkAccounts = (state) =>
  values(getVisibleAccounts(state)).filter((item: any) => item.canMakeBulkPayment)
export const getBulkFiles = (state, listName, limit) =>
  values(state.paymentsBulk[listName].items)
    .slice(0, limit)
    .map((item) => ({
      ...item,
      fromName: state.accounts.items[item.fromId]
        ? state.accounts.items[item.fromId].title
        : undefined,
      statusAndDate: `${item.status} - ${item.date}`,
      paymentInfo: item.amount ? `${item.transactionCount} payments: ${item.amount}` : undefined,
    }))

// todo-ts: limit undefined
export const getBulkFilesTableProps = (state, listName, limit = undefined) => {
  const tableFormat = {
    mapRowIdTo: 'id',
    cols: [
      {
        header: 'File name',
        mapTextTo: 'fileName',
        perc: 30,
        wrap: true,
      },
      {
        header: 'Status',
        mapTextTo: 'status',
        mapExtraTo: 'date',
        perc: 20,
      },
      {
        header: 'From',
        mapTextTo: 'fromName',
        perc: 35,
        wrap: true,
      },
      {
        header: 'No.',
        mapTextTo: 'transactionCount',
        perc: 5,
      },
      {
        header: 'Amount',
        mapTextTo: 'amount',
        perc: 10,
      },
    ],
    // Check getBulkFiles to see extra properties added
    card: {
      mapTitleTo: 'fileName',
      mapTextTo: 'fromName',
      mapExtraTo: 'statusAndDate',
      mapText2To: 'paymentInfo',
    },
  }

  const items = getBulkFiles(state, listName, limit)
  const niceData = niceTableData(items, tableFormat)
  const { isFetching } = state.paymentsBulk[listName]

  return {
    rows: !isFetching ? niceData.body : [],
    headerCols: niceData.header,
    failedToFetch: state.paymentsBulk[listName].errorMessage,
    isFetching,
    cardForSmall: true,
    rowLink: (row) => `/payments/bulk/file/${row.id || row.card.id}`,
  }
}

export const getBulkItemStats = (items) => {
  if (items.status === 'ERROR') return { message: 'File error' }
  const details = get(items, 'details', [])
  // Turn the items in to Accepted: 14, Rejected: 28, Processing: 104
  const stats = details.reduce(
    (acc, item) => {
      if (
        item.statusCode === 'VERIFIED' ||
        item.statusCode === 'UPLOADED' ||
        item.statusCode === 'APPROVED' ||
        item.statusCode === 'PROCESSING_IN_PROGRESS'
      ) {
        // Processing
        acc.processing += 1
      } else if (item.statusCode === 'PROCESSED' || item.statusCode === 'COMPLETED') {
        // Accepted
        acc.accepted += 1
      }
      return acc
    },
    { accepted: 0, rejected: items.recordsRejected, processing: 0 }
  )
  // Get the most common status
  stats.message = Object.keys(stats).reduce((a, b) => (stats[a] > stats[b] ? a : b))
  if (stats.message === 'accepted') stats.message = 'Successful'
  if (stats.message === 'rejected') stats.message = 'Rejected'
  if (stats.message === 'processing') stats.message = 'Under process'
  return stats
}

export const getBulkId = (state) => {
  const { bulkId } = state.user
  return bulkId
}

export const getFromAccount = (state, accId) => {
  const acc = state.accounts.items[accId]
  return acc
}

export const hasTransactionRange = (state, customerId, amount) => {
  const minUserPaymentLimitRange = state.user.userBulkPaymentLimit.txnRange.minTransaction
  const maxUserPaymentLimitRange = state.user.userBulkPaymentLimit.txnRange.maxTransaction
  const minPartyPaymentLimitRange =
    state.user.partyBulkPaymentLimit[customerId].txnRange.minTransaction
  const maxPartyPaymentLimitRange =
    state.user.partyBulkPaymentLimit[customerId].txnRange.maxTransaction
  let minTransactionRange = minUserPaymentLimitRange
  let maxTransactionRange = maxUserPaymentLimitRange
  if (minUserPaymentLimitRange < minPartyPaymentLimitRange) {
    minTransactionRange = minPartyPaymentLimitRange
  }
  if (maxUserPaymentLimitRange > maxPartyPaymentLimitRange) {
    maxTransactionRange = maxPartyPaymentLimitRange
  }
  if (minTransactionRange > amount.toFixed(2)) {
    return 'amountTooSmall'
  }
  if (maxTransactionRange < amount.toFixed(2) || amount.toFixed(2) > 100000000) {
    return 'amountTooBig'
  }
  return ''
}
export const hasDailyLimitLeft = (state, customerId, amount) => {
  const userPaymentLimit = state.user.userBulkPaymentLimit.maxAmount
  const partyPaymentLimit = state.user.partyBulkPaymentLimit[customerId].maxAmount
  let transactionDailyLimit = partyPaymentLimit
  if (userPaymentLimit < partyPaymentLimit) {
    transactionDailyLimit = userPaymentLimit
  }
  return transactionDailyLimit - amount.toFixed(2) >= 0
}
export const hasDailyTransactionCountLeft = (state, customerId) => {
  const userPaymentCountsLeft = state.user.userBulkPaymentLimit.maxCount
  const partyPaymentCountsLeft = state.user.partyBulkPaymentLimit[customerId].maxCount
  let transactionCountLeft = partyPaymentCountsLeft
  if (userPaymentCountsLeft < partyPaymentCountsLeft) {
    transactionCountLeft = userPaymentCountsLeft
  }
  return transactionCountLeft > 0
}

export const getBulkFileName = (state) =>
  get(state, 'paymentsBulk.item.items.downloadFile.file.name', '')

export const getBulkItemProps = (state) => {
  const { items } = state.paymentsBulk.item
  const { path } = getURL(state)

  const stats = getBulkItemStats(items)

  return {
    ...items,
    reference: path.last,
    name: getBulkFileName(state),
    stats,
    fromAccount: getAccount(state, items.accountId),
  }
}

export const getErrorLogJson = (state) => {
  const xml = state.paymentsBulk.item.items.errorLog
  const x = new X2JS()
  const json = x.xml2js(xml)
  const rawRecords = get(json, 'faml.records', [])
  let rawErrors = get(json, 'faml.errorlist', [])
  if (!(rawErrors instanceof Array)) rawErrors = [rawErrors]

  const getErrors = (arr) =>
    // eslint-disable-next-line consistent-return
    arr.map((record) => {
      if (record.recorditem.error) {
        let { failure } = record.recorditem.error
        if (!failure.length) failure = [failure]
        // eslint-disable-next-line no-underscore-dangle
        const errArray = failure.map((fail) => fail._errmsg)
        return errArray
      }
    })

  const records = [...rawRecords.slice(0, rawRecords.length - 2)]
  const otherRecords = rawRecords.slice(rawRecords.length - 2)

  const output = {
    // Main is for errors relating to the
    // rows in the uploaded file
    main: records.length > 0 ? getErrors(records) : [],
    // Other is for CONSOL and CONTROL which
    // show account balance errors and things like that
    other: otherRecords.length > 0 ? getErrors(otherRecords) : [],
    // Misc is for other potential failures on the serverside
    // like if the account number doesnt exist (via a ui glitch or something)
    misc: rawErrors,
  }

  return output
}

export const onDownloadClick = (state) => {
  const data = get(state, 'paymentsBulk.item.items.downloadFile.data')
  const name = getBulkFileName(state)
  const idxOfDot = name.lastIndexOf('.')
  const ext = idxOfDot !== -1 ? name.slice(idxOfDot) : null
  saveFile(data, `${name}`, ext || '')
}

export const saveeReceipt = (d) => {
  const pdfConfig = {
    title: 'Bulk Payment',
    rows: [
      { label: 'From Account', value: d.title },
      { label: '', value: d.accountNumber },
      { label: '', value: '' },
      { label: 'Date', value: d.date },
      { label: 'Number of payments', value: d.payments.toString() },
      { label: 'Total amount', value: d.amount },
      { label: '', value: '' },
      { label: '', value: '' },
      {
        label: '',
        value: 'To track the progress of your bulk upload and the individual payments',
      },
      {
        label: '',
        value: ' included in this submission, please log on to Online Banking',
      },
      {
        label: '',
        value: ' and select ‘Bulk Payments’ in the ‘Payments & transfers’ section.',
      },
    ],
  }
  saveeReceiptPDF(pdfConfig)
}

export const hasFunds = (state, accountId, amount) => {
  const balance = state.accounts.items[accountId].availableBalancePure
  return balance - amount.toFixed(2) >= 0
}

export const show3pmMessage = () => {
  // Moment is one hour behind (native JS dates are too)
  const threePM = moment().hour(9).minute(0).second(0).millisecond(0)
  return moment().isAfter(threePM)
}

export const showWeekendWarning = (state) => {
  const timeDay = moment().format('dddd')
  const timeHour = parseInt(moment().format('HH')) // todo-ts: converted to int
  const sendMessage = timeDay === 'Saturday' && timeHour > 14 && timeHour < 24
  /*
  FLEX-3068 - This should always return false, as this condition will never occur.
  Bulk payments made during the cutoff period will always be processed on the next working day, and we already display the value date in the payment information box.
  The message is no longer needed.
  It's a temporary fix; proper refactoring will be done later. */
  return false
}
