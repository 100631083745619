import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import s from './styles.css'

const setButtonLink = (tel, email, ext, to, title) => {
  if (tel) {
    return (
      <a className={s.link} href={`tel:${to}`}>
        {title}
      </a>
    )
  }
  if (email) {
    return (
      <a className={s.link} href={`mailto:${to}`}>
        {title}
      </a>
    )
  }
  if (ext) {
    return (
      <a className={s.link} target='_blank' rel='noreferrer' href={`https://${to}`}>
        {title}
      </a>
    )
  }
  return (
    <Link className={s.link} to={to}>
      {title}
    </Link>
  )
}

type ContactContainerProps = {
  title: string
  infoText: string
  items: {
    linkTitle: string
    to: string
    telNumber?: boolean
    email?: boolean
    external?: boolean
  }[]
}

const ContactContainer = ({ title, infoText, items }: ContactContainerProps) => (
  <div className={s.root}>
    <h2>{title}</h2>
    <div className={s.linkContainer}>
      {/* <p>{infoText}</p> */}
      <ul className={s.linkList}>
        {items.map(
          ({ telNumber = false, email = false, external = false, linkTitle, to }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index}>{setButtonLink(telNumber, email, external, to, linkTitle)}</li>
          )
        )}
      </ul>
    </div>
  </div>
)

export default ContactContainer
