import { takeEvery, put, call, select, delay } from 'redux-saga/effects'
import { paymentMakeReset } from '../../containers/PaymentMake/actions'
import getCurrentPath from '../../selectors/getCurrentPath'
import history from '../../history'
import * as c from '../../constants'

export function* handleEntityChange() {
  const path = yield select(getCurrentPath)
  const parts = path.split('/')

  if (
    (parts[0] === 'accounts' && parts[1] === 'transactions') ||
    (parts[0] === 'accounts' && parts[1] === 'services') ||
    (parts[0] === 'accounts' && parts[1] === 'statements') ||
    parts[0] === 'secure-mail' ||
    (parts[0] === 'settings' && parts[1] !== 'authorisations')
  ) {
    yield call(history.replace, '/entity-redirect')
    yield delay(3000)
    yield call(history.replace, '/accounts')
  } else if (parts[0] === 'payments' && parts[1] === 'make') {
    yield put(paymentMakeReset())
    // todo-ts: will not reach?
    // eslint-disable-next-line no-dupe-else-if
  } else if (parts[0] === 'accounts' && parts[1] === 'statements') {
    yield call(history.replace, '/accounts/statements')
  }
}

export function* watchEntities() {
  yield takeEvery(c.ENTITIES_CHANGE_ACTIVE, handleEntityChange)
}
