import React from 'react'
import DataTable from '../../containers/DataTable'
import s from './notesStyles.css'

// todo - any
const Notes = (props: any) => (
  <div className={s.notes}>
    <h3 className={s.header}>Audit details</h3>
    <DataTable {...props} simpleResultsState />
  </div>
)

export default Notes
