import * as React from 'react'

function SvgDirectDebit(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 700 700'
      width='1em'
      height='1em'
      {...props}
    >
      <path d='M366.8 286.1v262.2s-152-27.4-152-131.7c0-83.1 112.6-119.7 152-130.5z' />
      <path d='M366.8 281.2c-91.4-6.6-298.6 36.2-298.6 130 0 61.1 109.7 150.5 288.4 152.8 7 0 210-7.8 206.9-225.7-2.6-183-139.6-215-183.4-220.6v-11.2c174.7 11.1 312 116.1 312 243.5 0 134.8-153.6 244.5-342.5 244.5S7.9 484.8 7.9 350s152.8-244.5 341.7-244.5c5.8 0 11.5.1 17.2.3v175.4z' />
    </svg>
  )
}

export default SvgDirectDebit
