import * as React from 'react'

function COPTickIcon(props) {
  return (
    <svg
      {...props}
      width='15'
      height='15'
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='7.5' cy='7.5' r='7.5' fill='#41BEAE' />
      <line x1='3.70711' y1='7.29289' x2='6.70711' y2='10.2929' stroke='white' stroke-width='2' />
      <line x1='6.29289' y1='9.29289' x2='11.2929' y2='4.29289' stroke='white' stroke-width='2' />
    </svg>
  )
}

export default COPTickIcon
