/* eslint-disable react/style-prop-object */
import React from 'react'
import s from './styles.css'

import Button from '../Button'

const MiniPanel = () => (
  <div className={s.root}>
    <p>View your recently paid and next due standing orders and direct debits</p>
    <div className={s.buttons}>
      <div className={s.col}>
        <Button text='View' style='ghost-dark' />
      </div>
      <div className={s.col}>
        <Button text='Set Up New' style='dark' />
      </div>
    </div>
  </div>
)

export default MiniPanel
