import React from 'react'
import s from './styles.css'
import Button from '../Button'

type ActionHeaderProps = {
  title: string
  actionText?: string
  to?: string
  wrap?: boolean
  headerSize?: string
  onClick?: () => void
  buttonStyle?: string
}

const ActionHeader = ({
  title,
  actionText,
  to,
  headerSize = '1',
  onClick,
  buttonStyle,
  wrap,
}: ActionHeaderProps) => {
  const HeaderEl: any = `h${headerSize}` // todo - any
  const rootClass = wrap ? `${s.root} ${s.wrap}` : s.root
  return (
    <div className={rootClass}>
      <HeaderEl className={s.header}>{title}</HeaderEl>
      {actionText && (
        <Button
          text={actionText}
          to={to}
          className={s.action}
          onClick={onClick}
          style={buttonStyle}
        />
      )}
    </div>
  )
}

export default ActionHeader
