export default (store) => ({
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const MarketTrackingPortfolios = require('../containers/MarketTrackingPortfolios').default
        cb(null, MarketTrackingPortfolios)
      },
      'mtportfolios'
    )
  },
})
