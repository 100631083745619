export default (store) => ({
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const ServicesInvestmentWealth = require('../containers/ServicesInvestmentWealth').default
        cb(null, ServicesInvestmentWealth)
      },
      'investmentwealth'
    )
  },
})
