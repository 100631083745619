/* eslint-disable no-nested-ternary */
import React from 'react'
import InnerContainer from '../InnerContainer'
import Button from '../Button'
import Card from '../Card'
import s from './styles.css'
import ResultsInfo from '../ResultsInfo'
import NothingToShow from '../NothingToShow'

type AccountCardsProps = {
  accounts: {
    id: string
    title: string
    sortCode: string
    accountNumber: string
    balance: number
    availableBalance: number
  }[]
  onShowAllClick?: () => void
  showAllIsVisible?: boolean
  accountsAsLinks?: boolean
  accountsAreLoading?: boolean
  title?: string
  onItemClick?: (value: string) => void
  noAccountsWarning?: string
}

const AccountCards = ({
  accounts,
  onShowAllClick,
  showAllIsVisible,
  accountsAsLinks,
  accountsAreLoading,
  onItemClick,
  title = 'From which account?',
  noAccountsWarning = 'You have no accounts that can make payments.',
}: AccountCardsProps) => (
  <InnerContainer>
    {accountsAreLoading ? (
      <ResultsInfo state={{ state: 'loading' }} />
    ) : accounts.length === 0 ? (
      <NothingToShow title={noAccountsWarning} />
    ) : (
      <div>
        <h2>{title}</h2>
        <ul className={s.accounts}>
          {accounts.map((account) => {
            const onClickHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
              e.preventDefault()
              onItemClick(account.id)
            }
            return (
              <Card
                key={account.id}
                to={accountsAsLinks ? `/payments/make/${account.id}` : null}
                title={account.title}
                details={`${account.sortCode} ${account.accountNumber}`}
                text={`${account.balance}`}
                extra={`Available balance: ${account.availableBalance}`}
                onClick={onItemClick && onClickHandler}
              />
            )
          })}
        </ul>
        {showAllIsVisible && (
          <div className={s.showAll}>
            <Button text='Show All' onClick={onShowAllClick} />
          </div>
        )}
      </div>
    )}
  </InnerContainer>
)

export default AccountCards
