export const decodeHTML = (html) => {
  const txt: any = document.createElement('div')
  txt.innerHTML = html
  return txt.textContent || txt.innerText || txt.value || ''
}
export const decodeWaf = (html) => {
  const txt = document.createElement('div')
  txt.innerHTML = html
  let errorCode = txt.getElementsByTagName('p')[0].textContent
  errorCode = errorCode.replace(/^(.{5})(.{4})(.{4})(.{4})(.*)$/, '$1 $2 $3 $4 $5')
  return errorCode
}
export const decodeBlockedText = (html) => {
  const txt = document.createElement('div')
  txt.innerHTML = html
  return txt.getElementsByTagName('b')[0].textContent
}
