import { connect } from 'react-redux'

import AvailableBalanceInfoModal from '../../components/AvailableBalanceInfoModal'
import {
  showAvailableBalanceInfoModalFunction,
  hideAvailableBalanceInfoModalFunction,
} from './actions'

const mapStateToProps = (state, ownProps) => ({
  showAvailableBalanceInfoModal: state.availableBalanceInfoModal.showAvailableBalanceInfoModal,
  availableBalanceInfoModal: ownProps.availableBalance,
})

const mapDispatchToProps = (dispatch) => ({
  showAvailableBalanceInfoModalFunction() {
    dispatch(showAvailableBalanceInfoModalFunction())
  },
  hideAvailableBalanceInfoModalFunction() {
    dispatch(hideAvailableBalanceInfoModalFunction())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(AvailableBalanceInfoModal)
