import React from 'react'
import s from './styles.css'
import ReactSelect from '../Forms/ReactSelect'

type SelectEntityProps = {
  onChange: () => void
  value: string | boolean
  options: {
    value: string | boolean
    label: string
  }[]
  isDisabled?: boolean
}

export const SelectEntity = ({ onChange, options, value, isDisabled }: SelectEntityProps) => (
  <form className={s.root}>
    <label>Select an entity</label>
    <ReactSelect
      name='select-entity'
      value={value}
      options={options}
      onChange={onChange}
      disabled={isDisabled}
    />
  </form>
)

export default SelectEntity
