import { connect } from 'react-redux'
import Accounts from '../../components/AccountsView'
import groupAccounts from '../../selectors/groupAccounts'
import getVisibleAccounts from '../../selectors/getVisibleAccounts'
import getUserPermittedFunctions from '../../selectors/getUserPermittedFunctions'

const mapStateToProps = (state) => ({
  isLoadingEmpty: state.accounts.isFetching && Object.keys(state.accounts.items).length === 0,
  title: state.entities.activeEntityTitle,
  items: groupAccounts(getVisibleAccounts(state)),
  mappingList: getUserPermittedFunctions(state),
})

export default connect(mapStateToProps)(Accounts)
