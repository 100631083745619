export default (store) => ({
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const ServicesInternational = require('../containers/ServicesInternational').default
        cb(null, ServicesInternational)
      },
      'international'
    )
  },
})
