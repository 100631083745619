/* eslint-disable */
// @ts-nocheck
// todo - eslint and typescript
declare global {
  interface Window {
    autoLogout: any
  }
}
import { AUTOLOGOUT_IDLE_TIME } from '../../constants'
export const userAutoLogoutCheck = () => {
  window.autoLogout = true // setting this flag to true as we want this function to be called only on-load

  const minutes = true // change to false if you'd rather use seconds

  const interval = minutes ? 60000 : 1000

  const IDLE_TIMEOUT = AUTOLOGOUT_IDLE_TIME

  let idleCounter = 0
  const events = ['mousemove', 'touchmove', 'scroll', 'click', 'keypress']

  events.forEach(function (e) {
    document.addEventListener(e, function () {
      idleCounter = 0
    })
  })

  window.setInterval(function () {
    if (++idleCounter >= IDLE_TIMEOUT) {
      window.location.reload() // reloading the page here
    }
  }, interval)
}
