import { call, put, takeLatest, select, all } from 'redux-saga/effects'
import moment from 'moment'
import request from '../../utils/request'
import saveFile from '../../utils/saveFile'
import humanError from '../../utils/humanError'
import { checkIfGot, getUnfetchedAccountIds } from './selectors'
import * as c from '../../constants'
import * as a from './actions'
import { modalErrorShow } from '../../store/ui'

export function* fetchItems(action) {
  const p = action.payload
  const statementType = ['Statement', 'LOAN-PRENOTICE', 'CCALOAN-STATEMENT', 'CASA-Statement']
  try {
    if (!p.id) {
      const ids = yield select(getUnfetchedAccountIds)

      yield put(a.statementsFetchAllStart())

      yield all(
        ids.map((id) =>
          call(fetchItems, {
            payload: { id },
          })
        )
      )

      yield put(a.statementsFetchAllStop())
    } else {
      const hasGot = yield select(checkIfGot, p.id)
      if (!hasGot) {
        const account = yield select((state: any) => state.accounts.items[p.id])
        yield put(a.rStatementsFetchRequest()) // todo-ts : did remove the p.id param, as it was not used
        const partyId = account.customerId
        let items = []
        const baseUrl = `cz/v1/accounts/${account.accountId}/statements?statementType`
        const dateRange = `fromDate=2015-01-01&toDate=${moment().format('YYYY-MM-DD')}`
        // If its a lending account, we have 3 different
        // statement types to request
        if (account.group === 'lending') {
          for (let i = 0; i < 4; i += 1) {
            const nonce = yield call(request, {
              id: c.requestNonce,
              url: 'v1/session/nonce',
            })
            const data = yield call(request, {
              id: c.requestStatements,
              nonce,
              partyId,
              url: `${baseUrl}=${statementType[i]}&${dateRange}`,
            })
            items = items.concat(data)
          }
        } else {
          const nonce = yield call(request, {
            id: c.requestNonce,
            url: 'v1/session/nonce',
          })
          items = yield call(request, {
            id: c.requestStatements,
            nonce,
            partyId,
            url: `${baseUrl}=${statementType[3]}&${dateRange}`,
          })
        }
        items = items.map((item) => ({
          ...item,
          accountId: p.id,
        }))
        yield put(a.statementsFetchSuccess(items, p.id))
      } else {
        yield put(a.statementsFetchStop())
      }
    }
  } catch (error) {
    const message = yield call(humanError, error)
    yield put(a.statementsFetchFail(message))
  }
}

export function* downloadDoc(action) {
  const p = action.payload
  try {
    const statements = yield select((state: any) => state.statements.items)
    const statement = statements.filter((item) => item.id === p.id)
    const account = yield select((state: any) => state.accounts.items[statement[0].accountId])
    const partyId = account.customerId
    const nonce = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    const data = yield call(request, {
      id: c.requestStatementDownload,
      nonce,
      partyId,
      url: `cz/v1/accounts/${account.accountId}/statements/${statement[0].documentId}`,
    })
    yield call(saveFile, data.content, data.filename, 'pdf')
    yield put(a.statementDownloadSuccess())
  } catch (error) {
    const message = yield call(humanError, error)
    yield put(modalErrorShow(message))
  }
}

export function* watchStatements() {
  yield takeLatest(c.U_STATEMENTS_FETCH_REQUEST, fetchItems)
  yield takeLatest(c.STATEMENT_DOWNLOAD_REQUEST, downloadDoc)
}
