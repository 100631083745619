import React from 'react'
import { reduxForm, InjectedFormProps } from 'redux-form'
import TransactionPinFormHelper from '../../formHelpers/TransactionPinFormHelper'
import NewTransactionPinFormHelper from '../../formHelpers/NewTransactionPinFormHelper'
import FieldsetLegend from '../../Forms/FieldsetLegend'
import Button from '../../Button'
import BottomError from '../../Forms/BottomError'

type PinStepProps = {
  handleSubmit?: () => void // todo - was mandatory- but ChangeCredentialsViewn do not pass
  onSubmit: () => void
  invalid?: boolean
  submitting?: boolean
  error?: string
  isFirstTime?: boolean
}

const PinStep = ({
  handleSubmit,
  onSubmit,
  error,
  invalid,
  submitting,
  isFirstTime,
}: InjectedFormProps<any> & PinStepProps) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <fieldset className='narrow'>
      <FieldsetLegend
        title={isFirstTime ? 'Choose a Transaction PIN' : 'Change your Transaction PIN'}
        info={isFirstTime && 'You will use it to confirm payments to new payees.'}
      />
      <TransactionPinFormHelper
        name='oldPass'
        label='Temporary PIN'
        subLabel='This will have been sent to you via text/email'
        hideErrors
      />
      <NewTransactionPinFormHelper />
    </fieldset>
    <fieldset className='narrow'>
      <BottomError message={error} />
      <Button disabled={invalid} submit text='Next' loading={submitting} />
    </fieldset>
  </form>
)

const PinStepReduxForm = reduxForm<any, PinStepProps>({
  form: 'forceChangePin',
})(PinStep)

export default PinStepReduxForm
