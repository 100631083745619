import { connect } from 'react-redux'
import PaymentsFuture from '../../components/PaymentsFuture'
import niceTableData from '../../utils/niceTableData'
import { getSelectedPayment } from './selectors'
import filterSortPayments from '../../selectors/filterSortPayments'
import { flowUpdateData } from '../../store/flow'
import { modalShow } from '../../store/ui'
import { paymentStop } from './actions'

const tableFormat = {
  mapRowIdTo: 'id',
  cols: [
    {
      header: 'From',
      mapTextTo: 'fromAccountTitle',
      wrap: true,
      perc: 30,
    },
    {
      header: 'Payee',
      mapTextTo: 'recipientName',
      perc: 30,
      wrap: true,
      mapExtraTo: 'reference',
    },
    {
      header: 'Amount',
      mapTextTo: 'amount',
    },
    {
      header: 'Payment due',
      mapTextTo: 'date',
    },
    {
      header: 'Actions',
      hasActions: true,
    },
  ],
  card: {
    mapTitleTo: 'fromAccountTitle',
    mapTextTo: 'recipientName',
    mapText2To: 'amount',
    mapDetailsTo: 'date',
    mapExtraTo: 'reference',
  },
}

const mapStateToProps = (state, ownProps) => {
  const items = filterSortPayments(state, state.paymentsFuture.list.items)
  const niceData = niceTableData(items, tableFormat)

  return {
    cardForSmall: true,
    rows: niceData.body,
    headerCols: niceData.header,
    virtualized: true,
    actions: [
      {
        text: 'Cancel',
        id: 'stop',
      },
    ],
    isFetching: state.paymentsFuture.list.isFetching,
    failedToFetch: state.paymentsFuture.list.errorMessage,
    errorCode: state.paymentsFuture.list.errorCode,
    selectedPayment: getSelectedPayment(state),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onActionClick: (actionId, rowId) => {
    if (actionId === 'stop') {
      dispatch(flowUpdateData(rowId))
      dispatch(modalShow('paymentsFutureStop'))
    }
  },
  paymentStop,
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsFuture)
