import React from 'react'
import Breadcrumbs from '../Breadcrumbs'
import InnerContainer from '../InnerContainer'
import ActionHeader from '../ActionHeader'
import ActivityLogTypeList from '../../containers/ActivityLogTypeList'
import { typeCodesByCodeNewNames } from '../../utils/lookup/typeCodes'
import s from './style.css'

type ActivityLogTypeViewProps = {
  params: { type: string }
}

const ActivityLogTypeView = (props: ActivityLogTypeViewProps) => {
  const { type } = props.params
  return (
    <div>
      <Breadcrumbs
        items={[
          {
            to: '/settings',
            text: 'Details & settings',
          },
          {
            to: '/settings/activity-log',
            text: 'Activity log',
          },
        ]}
      />
      <InnerContainer>
        <ActionHeader title='Activity log' />
        <h2 className={s.subheader}>{typeCodesByCodeNewNames[type]}</h2>
        <ActivityLogTypeList {...props} />
      </InnerContainer>
    </div>
  )
}

export default ActivityLogTypeView
