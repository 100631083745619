import moment from 'moment'
import { decodeHTML } from '../../utils/decodeHTML'
import tidyNonce from './tidyNonce'

/**
 * Tidies deeply nested and poorly named accounts data from API into something
 * easier to deal with!
 * @param  {Object} data - raw data from API
 * @return {Object} data - tidied data
 */
export default (data) => {
  const response = data.data
  const nonce = tidyNonce(data)
  const user = response.userProfile
  const userExtraData = user.dictionaryArray[0].nameValuePairDTOArray

  let customers = user.partyId

  const entities = user.accessibleEntities
  const session = {
    id: nonce,
  }
  let isFirstTime = false

  if (!(customers instanceof Array)) {
    customers = [customers]
  }

  const honorific = userExtraData.filter((item) => item.name === 'title')
  const preferredName = userExtraData.filter((item) => item.name === 'preferredName')

  const phoneNumber = userExtraData.filter((item) => item.name === 'mobileNumber')
  const lastLogin =
    (user.preLastLoggedInDateTime && moment(user.preLastLoggedInDateTime, 'YYYY-MM-DDThh:mm:ss')) ||
    ''
  const tidyUser = {
    id: user.userName,
    mainPartyId: user.partyId.value,
    firstName: user.firstName,
    lastName: user.lastName,
    preferredName: preferredName[0].value,
    honorific: honorific[0].value,
    fullName: `${user.firstName} ${user.lastName}`,
    lastLogin: lastLogin !== '' ? lastLogin : undefined,
    email: user.emailId.displayValue,
    phoneNo: decodeHTML(phoneNumber[0].value),
  }

  isFirstTime = !response.firstLoginFlowDone

  return {
    user: tidyUser,
    session,
    isFirstTime,
    entities,
  }
}
