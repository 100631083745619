import { dontDuplicate } from '../utils/routerBugWorkaround'

export default (store) => ({
  path: '/accounts/transactions/:id',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const Account = require('../containers/Account').default
        const { accountInit } = require('../containers/Accounts/actions')

        dontDuplicate(() => {
          store.dispatch(accountInit(nextState.params.id))
        })

        cb(null, Account)
      },
      'account'
    )
  },
})
