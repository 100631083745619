import { connect } from 'react-redux'
import packageJson from '../../../package.json'
import Footer from '../../components/Footer'

const mapStateToProps = (state) => ({
  version: packageJson.version,
  linkMessage: state.session.id
    ? 'This will open in a new tab on your browser. ' +
      'Please log-off from Online Banking if you have completed everything you need to do.'
    : 'This will open in a new tab on your browser',
})

export default connect(mapStateToProps)(Footer)
