import { connect } from 'react-redux'

import ChangeCredentialsView from '../../components/ChangeCredentialsView'
import { forceChangePass, forceChangePin, changeCredentialsNextStep, acceptTerms } from './actions'
import {
  getVisibleSteps,
  getCurrentStep,
  getSuccessMessage,
  getIsFirstTime,
  getUserReady,
  getIsLoading,
  getAuthError,
} from './selectors'

const mapStateToProps = (state, ownProps) => {
  const currentStep = getCurrentStep(state)
  const steps = getVisibleSteps(state)
  const currentStepNum = steps.indexOf(currentStep)
  const ready = getUserReady(state)
  const isLoading = getIsLoading(state)
  const authError = getAuthError(state)
  return {
    currentStep,
    currentStepNum,
    steps,
    ready,
    successMessage: getSuccessMessage(state),
    isFirstTime: getIsFirstTime(state),
    isLoading,
    authError,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  forceChangePassSubmit: forceChangePass,
  forceChangePinSubmit: forceChangePin,
  acceptTermsSubmit: acceptTerms,
  goNextStep: () => dispatch(changeCredentialsNextStep()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangeCredentialsView)
