import React from 'react'
import DataTable from '../../containers/DataTable'

import s from './styles.css'

const PaymentsBulkItemList = (props) => (
  <div>
    <h3 className={s.root}>Payment details</h3>
    <DataTable {...props} />
  </div>
)

export default PaymentsBulkItemList
