import { dontDuplicate } from '../utils/routerBugWorkaround'
import { isLocalStorageSessionActiveBasic } from '../utils/redirectChecks'

export default (store) => ({
  path: 'make(/:fromAccountId)',
  onEnter: (nextState, replace) => {
    isLocalStorageSessionActiveBasic(store.getState(), store.dispatch, replace)
  },
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const PaymentMake = require('../containers/PaymentMake').default
        const { paymentMakeInit } = require('../containers/PaymentMake/actions')
        dontDuplicate(() => {
          store.dispatch(
            paymentMakeInit(
              nextState.params.fromAccountId,
              nextState.location.query.toAccountId,
              nextState.location.query.isNewPayee
            )
          )
        })

        cb(null, PaymentMake)
      },
      'payments-make'
    )
  },
})
