import get from 'lodash/get'
import moment from 'moment'
import filterSearch from '../../selectors/filterSearch'
import getAccount from '../../selectors/getAccount'
import getResultsState from '../../selectors/getResultsState'
import { getCurrencySymbol } from '../../utils/formatMoney'
import { saveTransactionsPDF } from '../../utils/savePDF'

export const filterDebitCreditFlag = (items, debitCreditFlag) => {
  if (debitCreditFlag === undefined) {
    return items
  }

  return items.filter((item) => {
    if (item.debitCreditFlag === debitCreditFlag) {
      return true
    }
    return false
  })
}

export const filterDates = (items, fromDate, toDate) =>
  items.filter((item) => {
    if (item.timeStamp >= fromDate && item.timeStamp <= toDate) {
      return true
    }
    return false
  })

export const filterTags = (items, tags) => {
  if (tags.length === 0) return items

  return items.filter((item) => {
    let pass = false

    tags.forEach((tag) => {
      if (tag === item.type) pass = true
    })

    return pass
  })
}

export const filterAmounts = (items, minAmount = 0, maxAmount = Infinity) =>
  items.filter((item) => {
    if (item.amountPure >= minAmount && item.amountPure <= maxAmount) {
      return true
    }
    return false
  })

export const getDateRangeAsText = (state) => {
  const dates = get(state, 'transactions.filters.datesStartEnd', undefined)
  const startDate = dates && dates[0]
  const endDate = dates && dates[1]
  const format = 'Do MMM YYYY'

  if (dates) {
    return `${moment(startDate).format(format)} - ${moment(endDate).format(format)}`
  }
  return ''
}

export const getCurrency = (state, accountId) => {
  const account = getAccount(state, accountId)
  const currencySymbol = getCurrencySymbol(account.currency)
  return currencySymbol
}

export const getVisibleTransactions = (state, accountId) => {
  const account = state.transactions.accounts[accountId]
  const { tempItems } = state.transactions

  let items
  if (!account) {
    items = undefined
  } else if (tempItems === false || tempItems.length === 0) {
    items = account.items
  } else {
    items = tempItems
  }

  const { filters } = state.transactions

  const { debitCreditFlag } = filters
  const searchTerm = filters.search
  const amountMin = filters.amountMinMax && filters.amountMinMax[0]
  const amountMax = filters.amountMinMax && filters.amountMinMax[1]
  const dateStart = filters.datesStartEnd && filters.datesStartEnd[0]
  const dateEnd = filters.datesStartEnd && filters.datesStartEnd[1]
  const { tags } = filters

  if (items) {
    // Dates
    if (dateStart && dateEnd) {
      items = filterDates(
        items,
        parseInt(moment(dateStart).format('X')),
        parseInt(moment(dateEnd).format('X'))
      )
    }

    // Search
    if (searchTerm) {
      items = filterSearch(items, searchTerm, ['title', 'ref'])
    }

    // Amount min/max
    if (amountMin || amountMax) {
      items = filterAmounts(items, amountMin, amountMax)
    }

    // Credit/Debit tabs
    if (debitCreditFlag) {
      items = filterDebitCreditFlag(items, debitCreditFlag)
    }

    // Tags
    if (tags) {
      items = filterTags(items, tags)
    }
  }
  return items
}

export const getLastQueriedStartDate = (state, accountId) => {
  const account = state.accounts.items[accountId]
  const earliestStartDate = get(state, `transactions.accounts[${accountId}].earliestStartDate`)
  const startDate = account.openingDate || get(account, 'details.startDate')

  const mStartDate = moment(startDate, 'DD/MM/YYYY')
  const mEarliestStartDate = moment(earliestStartDate, 'X')

  if (!earliestStartDate) return undefined

  if (!startDate || mStartDate.isBefore(mEarliestStartDate)) {
    return mEarliestStartDate
  }

  return mStartDate
}

export const getAccountOpeningDate = (state, accountId) => {
  const d = state.accounts.items[accountId].openingTimeStamp
  return d ? moment(d, 'X') : undefined
}

export const getSelectedDates = (state, datePickerId) => {
  const datePicker = state.superDatePicker.pickers[datePickerId]
  return [datePicker.startDate, datePicker.endDate]
}

export const getIsFiltered = (state, except = 'datesStartEnd') => {
  if (!state.transactions.filters) return false

  const filterKeys = Object.keys(state.transactions.filters)

  if (filterKeys.length === 0) return false
  if (filterKeys.length === 1 && filterKeys[0] === except) return false

  return true
}

export const getTransactionsResultsState = (state, accountId) => {
  const hasFilters = Object.keys(state.transactions.filters)
  const items = getVisibleTransactions(state, accountId)
  const account = state.transactions.accounts[accountId]
  const isFetching = account ? account.isFetching : true
  const failedToFetch = account ? account.failedToFetch && account.errorMessage : false
  const errorCode = account ? account.failedToFetch && account.errorCode : false
  const gotAll = account ? account.gotAll : false

  return getResultsState(
    items && items.length,
    isFetching,
    hasFilters.length,
    failedToFetch,
    errorCode,
    gotAll
  )
}

export const getTransactionFilters = (state) => state.transactions.filters

export const getFilterPanelEnabled = (state, accountId) => {
  const { type } = state.accounts.items[accountId]
  switch (type) {
    case 'CA':
    case 'RA':
    case 'IA':
    case 'NT':
      return true
    default:
      return false
  }
}

export const getLoadMoreIsVisible = (state, resultsState) => {
  const filtersExist = Object.keys(getTransactionFilters(state)).length > 0
  const isEndOfExisting = resultsState === 'endOfExisting'

  return isEndOfExisting && !filtersExist
}

export const saveTransactions = (state, accountId, startDate, endDate) => {
  const transactions = getVisibleTransactions(state, accountId)
  const account = getAccount(state, accountId)
  saveTransactionsPDF({
    title: 'Transactions',
    transactions,
    account,
    filename: `${account.title} Transactions`,
    startDate,
    endDate,
  })
}

export const isRacingAccount = (state, accountId) =>
  state.accounts.items[accountId].group === 'racing'

// new code added for changed date filters

export const getFilterShowMoreCount = (state) => {
  const showMoreCount = state.transactions.filterShowMoreCount
  return showMoreCount
}
export const getCurrentDateRange = (state, count) => {
  const dateRangeR = state.transactions.dateRangeR[count]
  return dateRangeR
}
export const getDiffMonths = (state) => {
  const { diffMonths } = state.transactions
  return diffMonths
}

export const getKeywordSearchDetail = (state) => state.transactions.keywordSearchDetail

// end new code
