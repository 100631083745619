import get from 'lodash/get'
import moment from 'moment'
import { connect } from 'react-redux'
import { initialize, reset } from 'redux-form'
import Transactions from '../../components/Transactions'
import { modalHide, panelHide, panelToggle } from '../../store/ui'
import { isiOS } from '../../utils/getPlatform'
import niceTableData from '../../utils/niceTableData'
import {
  superDatePickerCancel,
  superDatePickerInit,
  superDatePickerUpdateLastSubmitted,
} from '../SuperDatePicker/actions'
import * as a from './actions'
import getTableFormat from './getTableFormat'
import {
  getCurrency,
  getDateRangeAsText,
  getFilterPanelEnabled,
  getIsFiltered,
  getLastQueriedStartDate,
  getLoadMoreIsVisible,
  getTransactionsResultsState,
  getVisibleTransactions,
} from './selectors'

const mapStateToProps = (state, ownProps) => {
  const items = getVisibleTransactions(state, ownProps.id)
  const { filters, keywordSearchDetail } = state.transactions
  const dates = filters.datesStartEnd
  const resultsState = getTransactionsResultsState(state, ownProps.id)
  const datePickerState = state.superDatePicker.pickers.transactions
  const tableFormat = getTableFormat(state, ownProps.id)
  const niceData = niceTableData(items, tableFormat)
  const filterPanelEnabled = getFilterPanelEnabled(state, ownProps.id)
  const earliestStartDate = getLastQueriedStartDate(state, ownProps.id)
  return {
    rows: niceData.body,
    headerCols: niceData.header,
    browser: state.browser,
    resultsState,
    datePickerShowing: state.ui.panel === 'datePicker',
    filtersShowing: state.ui.panel === 'filters',
    dateFilterSelected: state.transactions.dateFilterSelected,
    startDate: (dates && dates[0]) || earliestStartDate,
    endDate: (dates && dates[1]) || moment(),
    loadMoreIsVisible: getLoadMoreIsVisible(state, resultsState.state),
    loadMoreIsVisibleFilters:
      !getLoadMoreIsVisible(state, resultsState.state) &&
      state.transactions.filterShowMoreCount < state.transactions.diffMonths / 6 &&
      resultsState.state === 'endOfResults',
    selectedStartDate: datePickerState && datePickerState.startDate,
    selectedEndDate: datePickerState && datePickerState.endDate,
    dateRangeText: getDateRangeAsText(state),
    searchText: state.transactions.filters.search || '',
    title: get(state, `accounts.items[${ownProps.id}].title`, undefined),
    isFiltered: getIsFiltered(state),
    clearButtonIsShowing: getIsFiltered(state, 'debitCreditFlag'),
    filterPanelEnabled,
    currency: getCurrency(state, ownProps.id),
    showDownloadButton: !isiOS(),
    filters,
    keywordSearch: keywordSearchDetail?.isActive ?? false,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClickMore: () => {
    dispatch(a.transactionsFetchMore(ownProps.id))
  },
  onClickMoreFilters: () => {
    dispatch(a.transactionsFetchMoreFilters(ownProps.id))
  },
  onDatesClearClick: (e) => {
    e.preventDefault()
    dispatch(a.transactionsClearDates())
    dispatch(a.transactionsSearch(null, ownProps.id))
    dispatch(superDatePickerInit('transactions'))
  },
  onSearch: (e) => {
    dispatch(a.transactionsSearch(e.target.value, ownProps.id))
  },
  onDatesApply: (e) => {
    dispatch(a.uTransactionsFilterDates(ownProps.id))
  },
  onDateClick: () => {
    dispatch(panelToggle('datePicker'))
    dispatch(superDatePickerCancel('transactions'))
  },
  onFiltersToggleClick: (e) => {
    e.preventDefault()
    dispatch(superDatePickerCancel('transactions'))
    dispatch(reset('transactionsFilters'))
    dispatch(panelToggle('filters'))
  },
  onFiltersClearClick: (e) => {
    e.preventDefault()
    dispatch(panelHide())
    dispatch(reset('transactionsFilters'))
    dispatch(a.transactionsClearFilters())
    dispatch(superDatePickerInit('transactions'))
  },
  onMobileSubmit: (e) => {
    dispatch(superDatePickerUpdateLastSubmitted('transactions'))
    dispatch(a.uTransactionsFilterDates(ownProps.id))
    dispatch(a.transactionsSearch(e.search, ownProps.id))
    dispatch(a.transactionsFilterAmountMinMax(e.minAmount, e.maxAmount))
    dispatch(panelHide())
    dispatch(initialize('transactionsFilters', e))
  },
  onPrintClick: () => {
    dispatch(modalHide())
    setTimeout(() => {
      window.print()
    }, 10)
  },
  onDownloadClick: (type, startDate, endDate) => {
    dispatch(a.transactionsDownload(ownProps.id, type, startDate, endDate))
    dispatch(modalHide())
  },
  onClickMoreKeywordSearch: () => {
    dispatch(a.transactionsFetchMoreKeywordSearch(ownProps.id))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Transactions)
