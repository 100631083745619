export default (store) => ({
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const ServicesDepositAccounts = require('../containers/ServicesDepositAccounts').default
        cb(null, ServicesDepositAccounts)
      },
      'deposit'
    )
  },
})
