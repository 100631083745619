// This is a workaround relating to this bug
// https://github.com/ReactTraining/history/issues/427
// The real solution is to upgrate to react-router v4!
let ignoreNext = false

// Use this function to determine wether your route related function
// should fire. Function passed in is most likely some dispatch stuff
// eslint-disable-next-line import/prefer-default-export
export const dontDuplicate = (func) => {
  if (!ignoreNext) func()
  ignoreNext = true
  setTimeout(() => {
    ignoreNext = false
  }, 1)
}
