import values from 'lodash/values'
import { getFormValues } from 'redux-form'

export const getItems = (state) => {
  const formValues: any = getFormValues('chequeStatusFilters')(state)
  const vals = values(state.chequeStatus.list.items)

  const status = formValues && formValues.status && formValues.status.value
  const search = formValues && formValues.search

  const items = vals.filter(
    (item) =>
      (!status || status === item.statusCode) && (!search || String(item.id).includes(search))
  )

  // Reduce (and other methods) would look and read the prettiest,
  // but I tested these and the classic for loop wins out in terms of perf
  // See my test: https://jsperf.com/array-looping-ben
  const itemsFiltered = []
  if (formValues && formValues.from && formValues.to) {
    const fromNumber = Number(formValues.from)
    const toNumber = Number(formValues.to)

    for (let i = 0; i < items.length; i += 1) {
      const val = items[i]
      if (val.id >= fromNumber && val.id <= toNumber) itemsFiltered.push(val)
    }
  }

  // Put the most recent at the top
  const reversed = itemsFiltered.reverse()

  return reversed
}

export const getInitialRange = (state) => {
  const vals = values(state.chequeStatus.list.items)
  const last50 = vals.slice(-50)
  let end = last50[last50.length - 1]
  if (last50.length > 49) {
    // eslint-disable-next-line prefer-destructuring
    end = last50[49]
  }
  return {
    start: last50[0],
    end,
  }
}
