import { combineReducers } from 'redux'
import createListReducer, { ListState } from '../../reducerCreators/listReducer'

export interface IPaymentsFutureState {
  list: ListState
}

export default combineReducers<IPaymentsFutureState, any>({
  list: createListReducer('paymentsFuture'),
})
