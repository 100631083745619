import React from 'react'
import s from './styles.css'

type BulletPointsProps = {
  listStyle?: boolean
  items: {
    bulletPoint: string
  }[]
}

const BulletPoints = ({ items, listStyle }: BulletPointsProps) => (
  <div className={s.root}>
    <ul className={`${listStyle ? s.discList : ''}`}>
      {items.map(({ bulletPoint }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index}>
          <span>{bulletPoint}</span>
        </li>
      ))}
    </ul>
  </div>
)

export default BulletPoints
