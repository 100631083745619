import * as React from 'react'
const SvgCOPInformation = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} fill='none' {...props}>
    <path
      fill='#BFA77C'
      fillRule='evenodd'
      d='M11 16.5c4.14 0 7.5-3.36 7.5-7.5 0-4.14-3.36-7.5-7.5-7.5-4.14 0-7.5 3.36-7.5 7.5 0 4.14 3.36 7.5 7.5 7.5Zm.75-11.25v1.5h-1.5v-1.5h1.5Zm0 7.5v-4.5h-1.5v4.5h1.5Z'
      clipRule='evenodd'
    />
  </svg>
)
export default SvgCOPInformation
