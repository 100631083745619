import React from 'react'
import { Link } from 'react-router/lib'
import Breadcrumbs from '../Breadcrumbs'
import BulletPoints from '../BulletPoints'
import ContactContainer from '../ContactContainer'
import InnerFullWidthContainer from '../InnerFullWidthContainer'
import ServicesHeader from '../ServicesHeader'
import s from '../ServicesView/styles.css'
import invest from './styles.css'

const addPadding = true
const listStyle = true

export const ServicesInvestmentWealth = (/* { mappingError } */) => (
  <div>
    <div className={invest.root}>
      <div>
        <Breadcrumbs
          items={[
            {
              to: '/services',
              text: 'Additional services',
            },
            {
              to: '/services/investmentwealth',
              text: 'Investment & wealth advice',
            },
          ]}
        />
        <ServicesHeader
          title='Bespoke advice whenever you need it'
          secondaryTitle='Helping you achieve your full financial potential'
        />
        <InnerFullWidthContainer addPadding={addPadding}>
          <div className={`${s.textArea} ${s.extraTopMargin}`}>
            <p>
              You’ve worked hard to build your wealth, so you’ll be keen to make the most of it. Our
              Wealth Planning advisers help you do just that, working closely with your Private
              Banker to provide sensible, practical advice on areas including:
            </p>
          </div>
          <BulletPoints
            listStyle={listStyle}
            items={[
              {
                bulletPoint: 'pension and other structures',
              },
              {
                bulletPoint: 'inheritance planning',
              },
              {
                bulletPoint: 'insurance and life assurance',
              },
              {
                bulletPoint: 'financial planning to set out a future income and expenditure plan',
              },
            ]}
          />
        </InnerFullWidthContainer>
        <InnerFullWidthContainer addPadding={addPadding}>
          <ContactContainer
            title='Find out more'
            infoText='For more information about our Investment & Wealth Advice service, please contact our expert team'
            items={[
              {
                linkTitle: '+44 (0) 20 7292 9029',
                to: '+44 (0) 20 7292 9029',
                telNumber: true,
              },
              // {
              //   linkTitle: 'Send Secure Message',
              //   to: '/secure-mail/new'
              // }
              {
                linkTitle: 'Send Secure Message',
                to: '/secure-mail/enquiry/#investmentwealth',
              },
            ]}
          />
        </InnerFullWidthContainer>
      </div>
      <div className={invest.sidebar}>
        <p className={invest.title}>Related Services</p>
        <div>
          <Link className={invest.link} to='/services/investmentwealth/investmentmanager'>
            Investment manager
          </Link>
          <Link className={invest.link} to='/services/investmentwealth/mtportfolios'>
            Market tracking protfolios
          </Link>
        </div>
      </div>
    </div>
  </div>
)

export default ServicesInvestmentWealth
