import { call, put, select } from 'redux-saga/effects'
import { getPartyId } from './selectors'
import request from '../../utils/request'
import { requestNonce } from '../../constants'
import getFlowData from '../../selectors/getFlowData'
import { formError } from '../formErrors/actions'
import { listItemUpdate } from '../../reducerCreators/listReducer/actions'
import { modalHide } from '../../store/ui'

export default (listName, requestId1, requestId2, formAction, code) =>
  function* cancelUpcomingPayment() {
    try {
      const id = yield select(getFlowData)
      const partyId = yield select(getPartyId(id, code))
      const nonce = yield call(request, {
        id: requestNonce,
        url: 'v1/session/nonce',
      })
      yield call(request, {
        id: requestId1,
        url: `/cz/v1/payments/instructions/cancellation/${id}?code=${code}`,
        nonce,
        partyId,
      })
      const nonce1 = yield call(request, {
        id: requestNonce,
        url: 'v1/session/nonce',
      })
      yield call(request, {
        id: requestId2,
        url: `/cz/v1/payments/instructions/cancellation/${id}?code=${code}`,
        nonce: nonce1,
        partyId,
      })
      yield put(listItemUpdate(listName, id, { statusCode: 'C' }))
      yield put(modalHide())
      yield put(formAction.success())
    } catch (error) {
      yield put(formError(formAction.FAILURE, error))
    }
  }
