import React from 'react'
import Breadcrumbs from '../Breadcrumbs'
import ContactContainer from '../ContactContainer'
import InnerFullWidthContainer from '../InnerFullWidthContainer'
import ServicesHeader from '../ServicesHeader'
import s from '../ServicesView/styles.css'

const addPadding = true

export const ServicesInsurance = (/* { mappingError } */) => (
  <div>
    <Breadcrumbs
      items={[
        {
          to: '/services',
          text: 'Additional services',
        },
        {
          to: '/services/racingplus',
          text: 'Racing Plus',
        },
      ]}
    />
    <ServicesHeader title='Racing Plus' />
    <InnerFullWidthContainer addPadding={addPadding}>
      <div className={`${s.textArea} ${s.extraTopMargin}`}>
        <p>
          When you join the Racing Bank, you’ll have access to a wide range of benefits, from
          convenient online banking to our efficient Invoice Payment Service.
        </p>
      </div>
      <div className={s.textArea}>
        <p>
          As a Racing Plus client, you’ll also enjoy additional benefits like a £500 owners’ prize
          money bonus for races sponsored by Weatherbys, fixed-term prize money debit accounts and a
          higher discount on our hassle-free VAT Return Service.
        </p>
      </div>
      <div className={s.textArea}>
        <p>
          And for clients involved in the horse racing and bloodstock industries, we offer a
          comprehensive range of specialised financial services, from our Training, Vets and Stud
          Fees Payment Service to a competitive Foreign Exchange service.
        </p>
      </div>
    </InnerFullWidthContainer>
    <InnerFullWidthContainer addPadding={addPadding}>
      <ContactContainer
        title='Find out more'
        infoText='To find out more'
        items={[
          {
            linkTitle: '+44 (0) 1933 304 777',
            to: '+44 (0) 1933 304 777',
            telNumber: true,
          },
          // {
          //   linkTitle: 'Send Secure Message',
          //   to: '/secure-mail/new'
          // }
          {
            linkTitle: 'Send Secure Message',
            to: '/secure-mail/enquiry#racingplus',
          },
        ]}
      />
    </InnerFullWidthContainer>
  </div>
)

export default ServicesInsurance
