/* eslint-disable */
// @ts-nocheck
// todo - eslint and typescript
import React, { ReactNode } from 'react'
import s from './styles.css'
import CurrentAccount from './CurrentAccount'
import LoanAccount from './LoanAccount'
import TermDeposit from './TermDeposit'
import Breadcrumbs from '../Breadcrumbs'
import logoStacked from '../../assets/svg/logo-stacked.svg'

type AccountHeaderProps = {
  title: string
  params: { id: string }
  type: string
}

const AccountHeader = (props: AccountHeaderProps) => {
  const { params, title, type } = props
  let headerEl: ReactNode

  switch (type) {
    case 'LN':
    case 'MO':
      // todo - LoanAccount props do not match
      headerEl = <LoanAccount {...props} />
      break
    case 'TD':
      // todo - TermDeposit props do not match
      headerEl = <TermDeposit {...props} />
      break
    default:
      // todo - CurrentAccount props do not match
      // todo - params is mandatory for CurrentAccount
      headerEl = <CurrentAccount {...props} />
  }

  return (
    <div className={s.root}>
      <Breadcrumbs
        items={[
          {
            to: '/accounts',
            text: 'My Accounts',
          },
          {
            to: `/accounts/${params.id}`,
            text: title,
          },
        ]}
      />

      {headerEl}
      <img src={logoStacked} alt='Weatherbys' className={s.logoStacked} />
    </div>
  )
}

export default AccountHeader
