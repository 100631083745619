import values from 'lodash/values'
import moment from 'moment'
import toArray from 'lodash/toArray'
import getActiveEntityId from '../../selectors/getActiveEntityId'

export const getVisibleAuthorisations = (state) => {
  const entityId = state.entities.activeEntityId
  const { lastVisited } = state.authorisations.meta.items
  let items = values(state.authorisations.list.items).filter(
    (item) => !item.done || moment(item.done).isAfter(moment(lastVisited))
  )

  if (entityId) {
    items = items.filter((item) => item.customerId === entityId)
  }

  return items
}

export const checkPermissionForAuthorisations = (state) => {
  const canApprove = toArray(state.user.permittedFunctions).filter((item) => item === 'approver')
  return canApprove.length > 0
}

export const checkAccountCount = (state) => {
  const hasAccounts = toArray(Object.keys(state.accounts.items))
  return hasAccounts.length > 0
}

export const getCount = (state) => {
  const globalCount = state.authorisations.meta.items.count
  const isLoading = state.authorisations.list.isFetching
  const entityId = getActiveEntityId(state)
  const filteredCount = getVisibleAuthorisations(state).filter((item) => !item.done).length

  if (!isLoading) {
    return filteredCount
  }
  if (!entityId) {
    return globalCount
  }
  if (filteredCount === 0) return undefined
  return filteredCount
}
