import pickBy from 'lodash/pickBy'
import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'
import { getFormValues } from 'redux-form'
import convertToOptions from '../../utils/convertToOptions'
import accountHasStatements from '../../selectors/accountHasStatements'
import getVisibleAccounts from '../../selectors/getVisibleAccounts'

export const getAccountOptions = (state) => {
  const accs = getVisibleAccounts(state)
  const filteredAccs = pickBy(accs, (item: any) => accountHasStatements(state, item.id))
  const opts = convertToOptions(filteredAccs)

  return [
    {
      value: 'all',
      label: 'All',
    },
    ...opts,
  ]
}

export const getTypeOptions = (state) => {
  const accountTypes = state.statements.got.map((accountId) => {
    const { items } = state.accounts
    const { type } = items[accountId]
    const { typeTitle } = items[accountId]
    return {
      value: type,
      label: typeTitle,
    }
  })
  const accountTypesNoDupes = uniqBy(accountTypes, 'value')
  const typeOpts = [
    {
      value: 'all',
      label: 'All',
    },
    ...accountTypesNoDupes,
  ]
  return typeOpts
}

export const getVisibleStatements = (state, accountId) => {
  const { items: noTypeItems } = state.statements
  // Grab the title from accounts state and add to each item
  const itemsWithTitles = noTypeItems.map((item) => {
    const { type, typeTitle, title: accountName } = state.accounts.items[item.accountId]
    return {
      ...item,
      type,
      typeTitle,
      accountName,
    }
  })

  // Filter items by type
  const statementFiltersValues = getFormValues('statementsFilters')(state)
  const items = itemsWithTitles.filter((item) => {
    const value = get(statementFiltersValues, 'type.value', 'all')
    if (value !== 'all') {
      return item.type === value
    }
    return true
  })

  const year = get(statementFiltersValues, 'year.value')

  if (
    !items ||
    (accountId === 'all' && state.statements.isFetchingAll) ||
    (accountId === 'all' && state.statements.failedToFetch)
  ) {
    return []
  }

  return items.filter((item) => {
    if (accountId === 'all' || accountId === item.accountId) {
      if (!year || year === item.year) return true
    }
    return false
  })
}

export const checkIfGot = (state, accountId) => {
  const { got } = state.statements
  return got.indexOf(accountId) !== -1
}

export const getUnfetchedAccountIds = (state) => {
  const accs = getVisibleAccounts(state)
  const filteredAccs = pickBy(accs, (item: any) => accountHasStatements(state, item.id))
  const { got } = state.statements
  const accountIds = Object.keys(filteredAccs)
  return accountIds.filter((id) => got.indexOf(id) === -1)
}
