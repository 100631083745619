export default {
  mortgageloans: 'Mortagages & Loans',
  international: 'International accounts',
  investmentwealth: 'Investment & wealth advice',
  deposit: 'Deposit accounts',
  racingplus: 'Racing Plus',
  invoiceservices: 'Invoice services',
  vatservices: 'VAT services',
  investmentmanager: 'Investment manager',
  mtportfolios: 'Market tracking portfolios',
  expatriate: 'Expatriate services',
}
