import React, { useEffect } from 'react'
import { WrappedFieldInputProps } from 'redux-form'
import FieldLayout, { FieldLayoutProps } from '../FieldLayout'
import s from './styles.css'
import Button from '../../Button'

type SwitchWithLabelsProps = {
  input: WrappedFieldInputProps
  options: [string, string]
} & Omit<FieldLayoutProps, 'children'>

const SwitchWithLabels = ({
  input: { value, name, onChange },
  options,
  ...fieldLayoutProps
}: SwitchWithLabelsProps) => {
  useEffect(() => {
    // Set value by default to first value
    if (value === '') onChange(options[0])
  }, [])

  return (
    <FieldLayout {...fieldLayoutProps} name={name}>
      <p className={s.row}>
        {options.map((option) => (
          <Button
            key={option}
            className={`${s.baseButton} ${value === option ? s.active : s.inactive}`}
            onClick={(e) => {
              e.preventDefault()
              onChange(option)
            }}
            text={option}
          />
        ))}
      </p>
    </FieldLayout>
  )
}

export default SwitchWithLabels
