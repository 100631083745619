import * as c from '../../constants'

export interface ITwoFactorAuthState {
  stepTwo?: boolean
  ref?: string
  initialRequest?: string
  successAction?: any
  success?: boolean
  wentBack?: boolean
}
export default function twoFactorAuthReducer(
  state: ITwoFactorAuthState = {},
  action
): ITwoFactorAuthState {
  const p = action.payload
  switch (action.type) {
    case c.TWO_FACTOR_AUTH_NEXT_STEP: {
      return {
        ...state,
        stepTwo: true,
      }
    }
    case c.TWO_FACTOR_AUTH_SET_REF: {
      return {
        ...state,
        ref: p.ref,
      }
    }
    case c.TWO_FACTOR_AUTH_SET_INITIAL_REQUEST: {
      return {
        ...state,
        initialRequest: p.requestName,
      }
    }
    case c.TWO_FACTOR_AUTH_SET_SUCCESS_ACTION: {
      return {
        ...state,
        successAction: p.action,
      }
    }
    case c.TWO_FACTOR_AUTH_COMPLETE: {
      return {
        ...state,
        success: true,
      }
    }
    case c.TWO_FACTOR_AUTH_GO_BACK: {
      return {
        ...state,
        wentBack: true,
      }
    }
    case c.LOCATION_CHANGE:
    case c.TWO_FACTOR_AUTH_RESET: {
      return {}
    }
    default:
      return state
  }
}
