import React from 'react'
import { confirmation } from 'redux-form-validators'
import TransactionPinFormHelper from './TransactionPinFormHelper'
import * as v from '../../utils/forms/validators'

const NewTransactionPinFormHelper = (props) => (
  <div>
    <TransactionPinFormHelper
      label='Create a new 4 digit PIN'
      name='newPass'
      validate={v.strongTransactionPin}
      validateType='list'
      validationMessages={{
        maxRepetitions2: 'Do not use the same digit more than twice',
        maxSuccessive3: 'No more than 3 digits in a sequence',
        onlyLength4: 'Exactly 4 digits',
      }}
    />

    <TransactionPinFormHelper
      label='Verify new PIN'
      name='newPassVerify'
      validate={confirmation({
        field: 'newPass',
        fieldLabel: 'Create a new 4 digit PIN',
      })}
      validateType='list'
      validationMessages={{
        "doesn't match `Create a new 4 digit PIN`": 'PINs match',
      }}
    />
  </div>
)
export default NewTransactionPinFormHelper
