import * as React from 'react'

function SvgHourglass(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 26' {...props}>
      <path d='M12.07 15.281a.583.583 0 00-.59-.573.583.583 0 00-.591.573c0 .83-.51 1.65-1.435 2.315-1.977 1.42-3.457 2.961-4.399 4.582a.557.557 0 00.005.571c.106.177.3.285.51.285h11.82c.21 0 .404-.108.51-.285a.56.56 0 00.004-.571c-.94-1.621-2.421-3.163-4.398-4.582-.925-.665-1.435-1.485-1.436-2.315z' />
      <path d='M21.864 23.704h-.353c-.406-4.47-3.1-7.903-5.193-10.34 2.094-2.438 4.786-5.87 5.194-10.34h.353c.653 0 1.182-.515 1.182-1.15 0-.635-.529-1.149-1.182-1.149H1.182C.529.725 0 1.24 0 1.874c0 .635.529 1.15 1.182 1.15h.353c.407 4.47 3.099 7.903 5.193 10.34-2.094 2.437-4.786 5.869-5.193 10.34h-.353c-.653 0-1.182.514-1.182 1.148 0 .635.529 1.15 1.182 1.15h20.683c.653 0 1.182-.515 1.182-1.15 0-.634-.53-1.148-1.183-1.148zm-17.96 0c.444-4.04 3.146-7.15 5.165-9.474l.107-.124a1.123 1.123 0 000-1.482l-.107-.123c-2.018-2.325-4.72-5.436-5.165-9.476h15.238c-.444 4.04-3.146 7.15-5.165 9.476l-.106.123a1.123 1.123 0 000 1.482l.108.124c2.018 2.324 4.72 5.435 5.163 9.474H3.904z' />
    </svg>
  )
}

export default SvgHourglass
