import React from 'react'
import Breadcrumbs from '../Breadcrumbs'
import TopDetails from '../../containers/PaymentsBulkItemTopDetails'
import Main from './Main'
import ResultsInfo from '../ResultsInfo'

type PaymentsBulkItemViewProps = {
  isLoading?: boolean
  showBackToAuth: boolean
  items: any // was added as Main.tsx required it
}

const PaymentsBulkItemView = ({
  isLoading,
  showBackToAuth,
  ...rest
}: PaymentsBulkItemViewProps) => (
  <div>
    <Breadcrumbs
      items={[
        {
          to: '/payments',
          text: 'Payments',
        },
        {
          to: '/payments/bulk',
          text: 'Bulk payments',
        },
        {
          to: '/payments/bulk/file',
          text: 'Bulk file details',
        },
      ]}
      showBack={showBackToAuth}
      back={{
        to: '/settings/authorisations',
        text: 'Return to authorisations',
      }}
    />
    {isLoading ? (
      <ResultsInfo state={{ state: 'loading' }} />
    ) : (
      <div>
        <TopDetails {...rest} />
        <Main {...rest} />
      </div>
    )}
  </div>
)

export default PaymentsBulkItemView
