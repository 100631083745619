import { connect } from 'react-redux'
import { getFormValues, change } from 'redux-form'
import get from 'lodash/get'
import Form from '../../components/MarketingModal/Form'
import { marketingModalSubmit } from './actions'
import { getInitialValues } from './selectors'

const mapStateToProps = (state, ownProps) => {
  const formValues: any = getFormValues('marketingConsent')(state) || {}
  const formSyncErrors = Object.values(get(state, 'form.marketingConsent.syncErrors', {}))[0]
  const formSubmitFailed = get(state, 'form.marketingConsent.submitFailed')

  return {
    yesChecked: formValues.consent || false,
    noChecked: formValues.nonConsent || false,
    anyYesOptionsChecked:
      formValues.post || formValues.telephone || formValues.email || formValues.text,
    initialValues: getInitialValues(state),
    marketingModalSubmit,
    settingsSaved: state.ui.misc.marketingSettingsSaved,
    customErrors:
      formSyncErrors && formSubmitFailed ? 'Please select at least one option' : undefined,
    loading: state.mail.outbox.loading,
  }
}

const mapDispatchToProps = (dispatch) => ({
  uncheck(name) {
    dispatch(change('marketingConsent', name, false))
  },
  tidyUp() {
    dispatch(change('marketingConsent', 'email', false))
    dispatch(change('marketingConsent', 'telephone', false))
    dispatch(change('marketingConsent', 'post', false))
    dispatch(change('marketingConsent', 'text', false))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Form)
