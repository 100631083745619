import { connect } from 'react-redux'
import PaymentsUpcomingEditView from '../../components/PaymentsUpcomingEditView'
import { getFromAndToDetails, getPaymentDetails } from './selectors'

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps.params
  const { from, to } = getFromAndToDetails(state, id)
  const { frequency } = getPaymentDetails(state, id)
  return {
    id,
    isCustom: frequency.toLowerCase() === 'custom',
    from,
    to,
  }
}

export default connect(mapStateToProps)(PaymentsUpcomingEditView)
