/* eslint-disable */
// @ts-nocheck
// todo - eslint and typescript
import get from 'lodash/get'
export interface TidyUserTransactionLimitD {
  data: {
    limitPackageDTOList: {
      targetLimitLinkages: {
        target: {
          value: string
        }
        limits: {
          limitType: string
          amountRange: {
            minTransaction: {
              amount: number
            }
            maxTransaction: {
              amount: number
            }
          }
          maxAmount: number
          maxCount: number
        }[]
      }[]
    }[]
  }
}
export default (data: TidyUserTransactionLimitD) => {
  const limitLinkages = get(data, 'data.limitPackageDTOList[0].targetLimitLinkages', [])
  const tidyData = {}
  const targets = {
    FU_F_DFT: 'bulkPayments',
    PC_F_UK_FAS: 'makePayment',
    PC_F_SELF: 'internalTransfer',
    20001: 'makePayment',
  }
  limitLinkages &&
    limitLinkages.map((item) => {
      const limits = get(item, 'limits', [])
      let txnRange = {}
      let txnLimit = ''
      let maxCount = ''
      limits.map((limit) => {
        const { limitType } = limit
        const minTransaction = get(limit, 'amountRange.minTransaction.amount', '')
        const maxTransaction = get(limit, 'amountRange.maxTransaction.amount', '')
        const maxAmount = get(limit, 'maxAmount.amount', '')
        const maxTxnCount = get(limit, 'maxCount', '')
        if (limitType === 'TXN') {
          txnRange = {
            minTransaction,
            maxTransaction,
          }
        }
        if (limitType === 'PER') {
          txnLimit = maxAmount
        }
        if (maxTxnCount) {
          maxCount = maxTxnCount
        }
      })
      tidyData[targets[item.target.value]] = {
        txnRange,
        txnLimit,
        maxCount,
      }
    })

  return tidyData
}
