import * as React from 'react'

function SvgSearch(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 29 31' {...props}>
      <path d='M28.56 28.236l-7.15-7.673a12.719 12.719 0 002.845-8.047C24.255 5.615 18.815 0 12.128 0S0 5.615 0 12.516c0 6.902 5.44 12.516 12.128 12.516 2.51 0 4.902-.781 6.948-2.264l7.203 7.731a1.546 1.546 0 002.236.046c.629-.625.649-1.66.044-2.309zM12 3c4.963 0 9 4.262 9 9.5S16.963 22 12 22s-9-4.262-9-9.5S7.037 3 12 3z' />
    </svg>
  )
}

export default SvgSearch
