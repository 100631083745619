import get from 'lodash/get'

export interface TidyGroupTransactionLimitD {
  data: {
    limitPackageDTOList: {
      targetLimitLinkages: {
        target: {
          value: string | number
        }
        limits: [
          {
            limitType: string
            amountRange: {
              minTransaction: { amount: string | number }
              maxTransaction: { amount: string | number }
            }
            maxAmount: {
              amount: string | number
            }
            maxCount: number
            target: {
              value: number
            }
          }
        ]
      }[]
    }[]
  }
}
export default (data: TidyGroupTransactionLimitD) => {
  const limitLinkages = get(data, 'data.limitPackageDTOList[0].targetLimitLinkages', [])
  const tidyData = {}
  const targets = {
    FU_F_DFT: 'bulkPayments',
    PC_F_UK_FAS: 'makePayment',
    PC_F_SELF: 'internalTransfer',
    20001: 'makePayment',
  }

  // eslint-disable-next-line array-callback-return
  limitLinkages.map((item) => {
    const limits = get(item, 'limits', [])
    let txnRange = {}
    let txnLimit = ''
    let maxCount = ''
    // eslint-disable-next-line array-callback-return
    limits.map((limit) => {
      const { limitType } = limit
      const minTransaction = get(limit, 'amountRange.minTransaction.amount', '')
      const maxTransaction = get(limit, 'amountRange.maxTransaction.amount', '')
      const maxAmount = get(limit, 'maxAmount.amount', '')
      const maxTxnCount = limit.maxCount
      if (limitType === 'TXN') {
        txnRange = {
          minTransaction,
          maxTransaction,
        }
      }
      if (limitType === 'PER') {
        txnLimit = maxAmount
      }
      if (maxTxnCount) {
        maxCount = maxTxnCount
      }
    })
    tidyData[targets[item.target.value]] = {
      txnRange,
      txnLimit,
      maxCount,
    }
  })

  return tidyData
}
// TODO DELETEME
