export default (store) => (nextState, cb) => {
  require.ensure(
    [],
    (require) => {
      const Settings = require('../containers/Settings').default

      cb(null, Settings)
    },
    'settings'
  )
}
