import { connect } from 'react-redux'

import HeadingWithPrint from '../../components/HeadingWithPrint'

const mapDispatchToProps = (dispatch, ownProps) => ({
  onPrintClick: () => {
    window.print()
  },
})

export default connect(null, mapDispatchToProps)(HeadingWithPrint)
