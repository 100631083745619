import { clearSessionStorage, readFromSessionStorage } from '../sessionStorage'
import { SESSION_EVENT_TIMESTAMP, MAX_ALLOWED_TIME_FOR_NAVIGATING_AWAY } from '../../constants'
import refresh from '../refresh'

// Logoff if the Time diffrence is moreThan MAX_ALLOWED_TIME_FOR_NAVIGATING_AWAY milliseconds  .
export const isLogoffRequired = (sessionEventTimeStamp) =>
  sessionEventTimeStamp &&
  Date.now() - parseInt(sessionEventTimeStamp) > MAX_ALLOWED_TIME_FOR_NAVIGATING_AWAY

export default (url) => {
  const sessionEventTimeStamp = readFromSessionStorage(SESSION_EVENT_TIMESTAMP)
  clearSessionStorage(SESSION_EVENT_TIMESTAMP)
  if (isLogoffRequired(sessionEventTimeStamp)) {
    console.log('More than 10 seconds...Log off')
    document.body.className = 'hideAll' // this will hide the weatherbys online bank details till logout is completed
    refresh.callRefresh(url)
  }
}
