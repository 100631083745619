import React from 'react'
import Form from './Form'
import Breadcrumbs from '../Breadcrumbs'
import InnerContainer from '../InnerContainer'
import FlowSwitch from '../../containers/FlowSwitch'
import FlowSuccess from '../FlowSuccess'
import s from './styles.css'
import goOut from '../../utils/goOut'

// todo - need testing as the props do not match between index and form
type ManageCredentialsViewProps = {
  name: string
  type: string
  handleSubmit: () => void // todo - testing , added newly as it is defined in Form.tsx
  onSubmit: () => void // todo - testing - added newly as it is defined in Form.tsx
  info: string // todo - testing - added newly as it is defined in Form.tsx
}

const ManageCredentialsView = (props: ManageCredentialsViewProps) => {
  const { type, name } = props
  return (
    <div className={s.root}>
      <Breadcrumbs
        items={[
          {
            to: '/settings',
            text: 'Details & settings',
          },
          {
            to: `/settings/manage-credentials/${type}`,
            text: `Change ${name.toLowerCase()}`,
          },
        ]}
      />
      <InnerContainer>
        <h1>Change {name.toLowerCase()}</h1>
        {name.toLowerCase() === 'password' ? (
          <FlowSwitch
            SuccessComponent={FlowSuccess}
            successComponentProps={{
              links: [
                {
                  text: 'Please Login Again',
                  to: '/',
                  onClick: () => {
                    goOut('/')
                  },
                },
              ],
              message: `${name} changed`,
            }}
            formEl={<Form {...props} />}
          />
        ) : (
          <FlowSwitch
            SuccessComponent={FlowSuccess}
            successComponentProps={{
              links: [
                {
                  text: 'Back to my accounts',
                  to: '/accounts',
                },
              ],
              message: `${name} changed`,
            }}
            formEl={<Form {...props} />}
          />
        )}
      </InnerContainer>
    </div>
  )
}

export default ManageCredentialsView
