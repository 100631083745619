import { call, put, takeLatest, select } from 'redux-saga/effects'
import request from '../../utils/request'
import * as c from '../../constants'
import * as a from './actions'
import { flowSuccess } from '../../store/flow'
import { formError } from '../../sagas/formErrors/actions'
import getAccount from '../../selectors/getAccount'
import constructMessage from './constructMessage'
// import GA from '../../utils/GA'
import topOfPage from '../../utils/topOfPage'

export function* handleSend(action) {
  const p = action.payload
  try {
    const account = yield select(getAccount, p.fromAccount.value)
    p.fromAccount = {
      ...p.fromAccount,
      accountNumber: account.accountNumber,
      sortCode: account.sortCode,
    }
    const message = yield call(constructMessage, p)
    const nonce = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    yield call(request, {
      id: c.requestMailSend,
      url: 'v1/mailbox/mails',
      partyId: account.customerId,
      nonce,
      payload: {
        party: account.customerId,
        subjectId: '7',
        subject: 'International Payment Request',
        messageBody: message,
        messageType: 'M',
      },
    })
    yield put(flowSuccess())
    // #GA4-PROVISIONALLY-DISABLED
    // GA.event({
    //   category: 'Form',
    //   action: 'Submit',
    //   label: 'International Payment',
    // })
    // Because the form is so long, but upon completion we do NOT change
    // the url, the view stays at the bottom of the long page (just grey)
    // when someone hits SEND.
    topOfPage()
  } catch (error) {
    console.error(error)
    yield put(formError(a.send.FAILURE, error))
  }
}

export function* watchRequestInternationalPayment() {
  yield takeLatest(a.send.REQUEST, handleSend)
}
