import React from 'react'
import s from './styles.css'

import DetailsBlock from '../DetailsBlock'
import { ConfirmationOfPayeeStatus } from '../../containers/ConfirmationOfPayee/reducer'

type DetailsGroupProps = {
  items: {
    descriptor?: string
    title?: string
    extras?: any[]
    linkText?: string
    excluded?: boolean // todo - added as it is reference in this component
  }[]
  forceRows?: boolean
  copStatus?: ConfirmationOfPayeeStatus
  verificationReport?: any
}

const DetailsGroup = ({ items, forceRows, copStatus, verificationReport }: DetailsGroupProps) => {
  const respond = !forceRows && s.respond
  const blockRespondMode = forceRows && 'forceCols'
  return (
    <div className={`${s.root} ${respond}`}>
      {items
        .filter((item) => !item.excluded && item.title !== undefined)
        .map((item, index) => (
          <div key={item.title} className={`${s.col} ${respond}`}>
            <DetailsBlock
              {...item}
              respondMode={blockRespondMode}
              copStatus={copStatus}
              verificationReport={verificationReport}
            />
          </div>
        ))}
    </div>
  )
}

export default DetailsGroup
