import React from 'react'
import { Field } from 'redux-form'
import SingleField from '../Forms/SingleField'
import PasswordField from '../Forms/PasswordField'
import * as v from '../../utils/forms/validators'
import * as n from '../../utils/forms/normalizers'

const TransactionPinFormHelper = (props) => (
  <Field
    type='password'
    component={SingleField}
    InputComponent={PasswordField}
    validate={v.transactionPin}
    normalize={n.transactionPin}
    {...props}
  />
)
export default TransactionPinFormHelper
