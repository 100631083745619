import React from 'react'
import s from './styles.css'
import Button from '../Button'

type ButtonBlockProps = {
  items: {
    text: string
    to?: string
    onClick?: () => void
    excluded?: boolean // todo??? - more testing
  }[]
  forceRows?: boolean
}

const ButtonBlock = ({ items, forceRows }: ButtonBlockProps) => {
  const respond = !forceRows && s.respond

  return (
    <div className={`${s.root} ${respond}`}>
      {items
        .filter((item) => !item.excluded)
        .map((item, index) => {
          const style = index > 0 ? 'ghost-dark' : undefined
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div className={`${s.col} ${respond}`} key={index}>
              <Button
                text={item.text}
                to={item.to}
                onClick={item.onClick}
                style={style}
                size='expand'
              />
            </div>
          )
        })}
    </div>
  )
}

export default ButtonBlock
