import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import PaymentsRecurringSection from '../../components/PaymentsRecurringSection'
import niceTableData from '../../utils/niceTableData'
import filterSortPayments from '../../selectors/filterSortPayments'
import { flowUpdateData } from '../../store/flow'
import { modalShow } from '../../store/ui'
import { paymentsRecurringStopSI, paymentsRecurringStopDD } from '../PaymentsRecurring/actions'
import { getSelectedPayment, injectExtra } from './selectors'
import history from '../../history'

const mapStateToProps = (state, ownProps) => {
  const tableFormat: any = {
    mapRowIdTo: 'id',
    cols: [
      {
        header: 'From',
        mapTextTo: 'fromAccountTitle',
        wrap: true,
        perc: 30,
      },
      {
        header: 'Payee',
        mapTextTo: 'recipientName',
        perc: 30,
        wrap: true,
        mapExtraTo: 'reference',
      },
      // frequency gets added if SI
      {
        header: 'Amount',
        mapTextTo: 'amount',
      },
      // nextPaymentDate / lastPaymentDate depending on DD / SI
      {
        header: 'Actions',
        hasActions: true,
      },
    ],
    card: {
      mapTitleTo: 'fromAccountTitle',
      mapTextTo: 'recipientName',
      // mapText2To: Defined below
      // mapDetailsTo: Defined below
      mapExtraTo: 'reference',
    },
  }

  // Update table format depending on SI / DD
  if (ownProps.type === 'SI') {
    tableFormat.cols.splice(2, 0, {
      header: 'Frequency',
      mapTextTo: 'frequency',
    })
    tableFormat.cols.splice(4, 0, {
      header: 'Next payment',
      mapTextTo: 'nextPaymentDate',
    })
    tableFormat.card.mapDetailsTo = 'nextPaymentDate'
    tableFormat.card.mapText2To = 'amountAndFrequency'
  } else if (ownProps.type === 'DD') {
    tableFormat.cols.splice(3, 0, {
      header: 'Last payment',
      mapTextTo: 'lastPaymentDate',
    })
    tableFormat.card.mapDetailsTo = 'lastPaymentDate'
    tableFormat.card.mapText2To = 'amount'
  }
  const list = state.paymentsRecurring[ownProps.type]
  // inject customerId into items
  let items = injectExtra(state, list.items)
  items = filterSortPayments(state, items, ownProps.type)
  const niceData = niceTableData(items, tableFormat)
  const formValues: any = getFormValues('recurringPaymentsFilters')(state)
  const hasFilters = formValues && formValues.account.value

  return {
    cardForSmall: true,
    virtualized: true,
    rows: list.isFetching ? [] : niceData.body,
    headerCols: niceData.header,
    actions:
      ownProps.type === 'SI'
        ? [
            // Uncomment to allow editing of standing orders
            // {
            //  text: 'Edit',
            //  id: 'edit'
            // },
            {
              text: 'Cancel',
              id: 'cancel',
            },
          ]
        : [
            {
              text: 'Cancel',
              id: 'cancel',
            },
          ],
    isFetching: list.isFetching,
    hasFilters,
    failedToFetch: list.errorMessage,
    errorCode: list.errorCode,
    selectedPayment: getSelectedPayment(state, ownProps.type),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onActionClick: (actionId, rowId) => {
    if (actionId === 'cancel') {
      dispatch(flowUpdateData(rowId))
      dispatch(modalShow(`paymentsRecurringStop${ownProps.type}`))
    }
    if (actionId === 'edit') {
      dispatch(flowUpdateData(rowId))
      history.push(`payments/upcoming/edit/${rowId}`)
    }
  },
  paymentsRecurringStop: ownProps.type === 'DD' ? paymentsRecurringStopDD : paymentsRecurringStopSI,
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsRecurringSection)
