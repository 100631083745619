import React from 'react'
import AllAccounts from '../../components/AllAccounts'
import QuickLinks from '../../components/QuickLinks'
import AuthorisationsBox from '../../containers/Authorisations/box'
// import MarketingModal from '../../containers/MarketingModal'
// import ModalInfo from '../../containers/ModalInfo'
import s from './styles.css'
import { IQuickLinkMapping } from '../QuickLinks/types'

type AccountsViewProps = {
  items: any[]
  mappingList: IQuickLinkMapping
  title?: string
  isLoadingEmpty?: boolean
}

export const AccountsView = ({ items, title, isLoadingEmpty, mappingList }: AccountsViewProps) => (
  <div>
    <QuickLinks mappingList={mappingList} />
    <AuthorisationsBox />
    {/* <MarketingModal /> */}
    {/* <ModalInfo /> */}
    <div className={s.inner}>
      {title && <h1 className='underline'>{title}</h1>}
      <AllAccounts items={items} title={title} isLoadingEmpty={isLoadingEmpty} />
    </div>
  </div>
)

export default AccountsView
