import React from 'react'
import Modal from '../../containers/Modal'

type ModalInfoProps = {
  isOpen: boolean
}

const ModalInfo = ({ isOpen }: ModalInfoProps) => (
  <Modal
    id='info'
    isOpen={isOpen}
    title='Note for Android'
    size='medium'
    actionText='Ok'
    closeOnActionClick
    cancelText=''
  >
    <p>
      Your e-receipt should be downloaded soon. Please check relevant folders within your Android
      device (e.g: Downloads, My Files etc.)
    </p>
  </Modal>
)

export default ModalInfo
