import React from 'react'
import MessageGroup from '../MessageGroup'
import ActionHeader from '../ActionHeader'
import Breadcrumbs from '../Breadcrumbs'
import InnerContainer from '../InnerContainer'
import Tabs from '../Tabs'
import s from './styles.css'
import { ResultsInfoDateState } from '../../models/types'

type MailListProps = {
  navItems: any[]
  lastCrumb?: {
    to: string
    text: string
  } // todofixed - was object
  lists?: any[]
  state?: ResultsInfoDateState // todofixed-ts: deep down it should be ResultsInfoDateState
}

const MailList = ({ lists, state, lastCrumb, navItems }: MailListProps) => (
  <div>
    <Breadcrumbs
      items={[
        {
          to: '/secure-mail',
          text: 'Secure mailbox',
        },
        lastCrumb,
      ]}
    />
    <InnerContainer>
      <ActionHeader
        title='Secure mailbox'
        actionText='Compose message'
        to='/secure-mail/new'
        buttonStyle='ghost-dark'
      />
    </InnerContainer>
    <div className={s.tabs}>
      <Tabs items={navItems} />
    </div>
    <InnerContainer>
      <MessageGroup lists={lists} state={state} />
    </InnerContainer>
  </div>
)

export default MailList
