/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import s from './styles.css'

type OverlayProps = {
  heading: string
  onClose: () => void
  content: () => void
}

const Overlay = ({ heading, onClose, content }: OverlayProps) => (
  <div>
    <div className={s.genericoverlay}>
      <div className={s['genericoverlay-content']}>
        <div className={s.genericoverlaygoBack} onClick={onClose}>
          <i className={`${s.genericoverlayiconClass} ${s.genericoverlayleft}`} />
          Back
        </div>
        <div className={s.genericoverlayheader}>
          {heading}
          <span className={s.genericoverlayclose} onClick={onClose}>
            &times;
          </span>
        </div>
        {content()}
      </div>
    </div>
  </div>
)

export default Overlay
