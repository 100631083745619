import * as React from 'react'

function SvgMail(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 512 512'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M512 385.478a65.564 65.564 0 01-8.158 31.027L342.157 234.227 502.111 93.31A64.698 64.698 0 01512 127.575v257.903zM255.876 267.33L478.378 71.134A61.806 61.806 0 00447.969 63H64.031a63.339 63.339 0 00-30.656 8.134L255.876 267.33zm62.054-11.866l-51.423 45.44a15.748 15.748 0 01-10.63 3.955 15.575 15.575 0 01-10.384-3.956l-51.67-45.44L30.161 440.066a63.042 63.042 0 0033.87 9.89h383.938a63.685 63.685 0 0033.87-9.89l-163.91-184.601zM9.889 93.26A64.698 64.698 0 000 127.525v257.953a62.82 62.82 0 008.158 31.027l161.685-182.303L9.889 93.26z'
        fill='#B9B6A8'
        fillRule='evenodd'
      />
    </svg>
  )
}

export default SvgMail
