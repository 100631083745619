export default (store) => ({
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const InvestmentManager = require('../containers/InvestmentManager').default
        cb(null, InvestmentManager)
      },
      'investmentmanager'
    )
  },
})
