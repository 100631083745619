import React, { Component } from 'react'
import platform from 'platform'
import Button from '../Button'

import s from './styles.css'

const G = 'https://www.google.com/chrome/index.html'
const F = 'https://www.mozilla.org/en-GB/firefox/new/'

interface BrowserNotificationState {
  showing: boolean
  isIEEleven: boolean
}

// todo - props any
class BrowserNotification extends Component<any, BrowserNotificationState> {
  constructor(props) {
    super(props)
    this.state = {
      showing: false,
      isIEEleven: false,
    }
    this.handleClick = this.handleClick.bind(this)
    this.isBrowserUnsupported = this.isBrowserUnsupported.bind(this)
  }

  /* eslint-disable-next-line */
  UNSAFE_componentWillMount() {
    const isUnsupported = this.isBrowserUnsupported()

    const ls = localStorage.getItem('notification_unsupportedBrowser_closed')
    if (!ls && isUnsupported) {
      this.setState({ showing: true })
    }
  }

  handleClick() {
    localStorage.setItem('notification_unsupportedBrowser_closed', 'true') // todo - was true
    this.setState({ showing: false })
  }

  isBrowserUnsupported() {
    const { name, version: v } = platform
    const version = Number(v.split('.')[0])
    let isUnsupported = false
    switch (name) {
      case 'Chrome':
      case 'Chrome Mobile':
        if (version < 48) {
          isUnsupported = true
        }
        break
      case 'Firefox':
      case 'Firefox Mobile':
      case 'Firefox for iOS':
        if (version < 53) {
          isUnsupported = true
        }
        break
      case 'IE':
      case 'IE Mobile':
        if (version === 11) {
          this.setState({ isIEEleven: true })
        }
        if (version < 12) {
          isUnsupported = true
        }
        break
      case 'Safari':
      case 'Safari Mobile':
        if (version < 9) {
          isUnsupported = true
        }
        break
      default:
        break
    }
    return isUnsupported
  }

  render() {
    const { showing, isIEEleven } = this.state

    if (showing) {
      return (
        <div className={s.root}>
          {isIEEleven ? (
            <p>
              We notice you are using the Internet Explorer 11 browser. For the best experience when
              using Online Banking we recommend using the latest versions of{' '}
              <a href={G}>Google Chrome</a> or <a href={F}>Firefox</a>.
            </p>
          ) : (
            <p>
              We notice you are using an unsupported browser. Upgrading to the latest version of a
              supported browser will give you the best and safest experience on Online Banking. We
              recommend using the latest versions of <a href={G}>Google Chrome</a> or{' '}
              <a href={F}>Firefox</a>.
            </p>
          )}

          <Button onClick={this.handleClick} text='Okay' className={s.button} />
        </div>
      )
    }
    return null
  }
}

export default BrowserNotification
