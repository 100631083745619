import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Router } from 'react-router'
import { Provider } from 'react-redux'
// eslint-disable-next-line import/no-named-default
import { injectHistoryWithStore, default as history } from '../history'
import topOfPage from '../utils/topOfPage'
import scrollToEl from '../utils/scrollToEl'

function routerScroll() {
  const { hash } = window.location
  // We're using hash history, so anything after the second hash
  const id = hash.split('#')[2]

  if (id) {
    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    const el = document.getElementById(id)
    setTimeout(() => {
      scrollToEl(el)
    }, 0)
  } else {
    topOfPage()
  }
}

interface AppContainerState {
  routes: any[]
  store: any // todo - was object
}

class AppContainer extends Component<any, AppContainerState> {
  constructor(props) {
    super(props)
    injectHistoryWithStore(props.store)
  }

  shouldComponentUpdate() {
    return false
  }

  render() {
    const { routes, store } = this.props

    return (
      <Provider store={store}>
        <div style={{ height: '100%' }}>
          <Router
            history={history}
            // eslint-disable-next-line react/no-children-prop
            children={routes}
            onUpdate={routerScroll}
          />
        </div>
      </Provider>
    )
  }
}

export default AppContainer
