import React from 'react'
import Table from './Table'
import Cards from './Cards'
import ResultsInfo from '../ResultsInfo'
import { TableHeadColsProps } from './TableHead/types'

type DataTableProps = {
  browser: {
    lessThan: {
      medium: boolean | string
    }
  }
  rows: {
    id: string
    card?: string
  }[]
  headerCols: TableHeadColsProps[]
  cardForSmall?: boolean
  resultsState?: any // todo - was object,
}

const DataTable = (props: DataTableProps) => {
  let component

  if (props.browser.lessThan.medium && props.cardForSmall) {
    component = <Cards {...props} />
  } else {
    component = <Table {...props} />
  }

  return (
    <div>
      {component}
      {props.resultsState && <ResultsInfo state={props.resultsState} />}
    </div>
  )
}

export default DataTable
