import { connect } from 'react-redux'
import ChequeStatusTable from '../../components/ChequeStatusTable'
import niceTableData from '../../utils/niceTableData'
import convertToOptions from '../../utils/convertToOptions'
import getChequeStatusAccounts from '../../selectors/getChequeStatusAccounts'
import { getItems, getInitialRange } from './selectors'
import getRouteQuery from '../../selectors/getRouteQuery'
import history from '../../history'

const tableFormat = {
  mapRowIdTo: 'id',
  cols: [
    {
      header: 'Cheque number',
      mapTextTo: 'id',
      perc: 20,
    },
    {
      header: 'Status',
      mapTextTo: 'status',
    },
    {
      header: 'Date',
      mapTextTo: 'date',
    },
    {
      header: 'Amount',
      mapTextTo: 'amount',
      perc: 20,
    },
  ],
  card: {
    mapTitleTo: 'id',
    mapTextTo: 'status',
    mapDetailsTo: 'amount',
    mapExtraTo: 'date',
  },
}

export const mapStateToProps = (state, ownProps) => {
  const niceData = niceTableData(getItems(state), tableFormat)
  const { isFetching } = state.chequeStatus.list
  const accountOptions = convertToOptions(getChequeStatusAccounts(state))
  const initialRange = getInitialRange(state)
  return {
    rows: !isFetching ? niceData.body : [],
    headerCols: niceData.header,
    isFetching,
    failedToFetch: state.chequeStatus.list.errorMessage,
    errorCode: state.chequeStatus.list.errorCode,
    cardForSmall: true,
    accountOptions,
    onAccountChange: (e) => {
      history.push(`/accounts/services?accountId=${e.value}`)
    },
    initialValues: {
      account: {
        value: getRouteQuery(state).accountId || accountOptions[0],
      },
      status: {
        value: false,
      },
      from: initialRange.start && Number(initialRange.start.id),
      to: initialRange.end && Number(initialRange.end.id),
    },
    statusOptions: [
      {
        label: 'All',
        value: false,
      },
      {
        label: 'Cancelled',
        value: 'C',
      },
      {
        label: 'Not Used',
        value: 'N',
      },
      {
        label: 'Rejected',
        value: 'R',
      },
      {
        label: 'Stopped',
        value: 'S',
      },
      {
        label: 'Used',
        value: 'U',
      },
      {
        label: 'Hold',
        value: 'H',
      },
    ],
  }
}

export default connect(mapStateToProps)(ChequeStatusTable)
