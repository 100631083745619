/**
 * Transforms state items object into options array
 * @param  {Object} items
 * @return {Array} newItems
 */

export default (items) => {
  // TODO
  if (items) {
    const newItems: { label: string; value: string | number }[] = []

    Object.keys(items).forEach((key) => {
      newItems.push({
        label: items[key].title,
        value: items[key].id,
      })
    })

    return newItems
  }
  return []
}
