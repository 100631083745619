import { saveAs } from 'file-saver'

function b64toBlob(b64Data: string, contentTypeValue: string, sliceSizeValue = 512) {
  const contentType = contentTypeValue || ''
  const sliceSize = sliceSizeValue
  const byteCharacters = atob(b64Data)
  const byteArrays = []
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)
    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }
  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}
/**
 * saveFile - Takes base64 string, converts to blob, "downloads" to user's machine
 * @param {string} base64String
 * @param {string} filename
 */
export default (data, filename: string, type: string) => {
  // eslint-disable-next-line no-useless-catch
  try {
    let blob
    if (type === 'pdf') {
      blob = b64toBlob(data, 'application/pdf')
    } else if (type.includes('xl')) {
      blob = new Blob([data], { type: 'application/excel' })
    } else if (type.includes('csvPlainText')) {
      blob = new Blob([data], { type: 'text/plain' })
    } else {
      blob = new Blob([data], { type: 'text/plain;charset=utf-8' })
    }
    saveAs(blob, filename)
  } catch (error) {
    throw error
  }
}
