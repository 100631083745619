import React from 'react'

type FileInputProps = {
  input: {
    onChange: () => void
    onBlur: () => void
  }
  accept?: string
}

// todo
const adaptFileEventToValue = (delegate: any) => (e: any) => delegate(e.target.files[0])

const FileInput = ({ input, accept }: FileInputProps) => (
  <input
    onChange={adaptFileEventToValue(input.onChange)}
    onBlur={adaptFileEventToValue(input.onBlur)}
    type='file'
    accept={accept}
  />
)

export default FileInput
