import React, { Component } from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import SingleField from '../Forms/SingleField'
import * as v from '../../utils/forms/validators'
import * as n from '../../utils/forms/normalizers'
import Button from '../Button'
import BottomError from '../Forms/BottomError'
import Tooltip from '../../containers/Tooltip'
import s from './styles.css'

type FormProps = {
  handleSubmit: () => void
  PINSubmit: () => void
  error?: string
  bEError?: string
  invalid?: boolean
  submitting?: boolean
}

class Form extends Component<InjectedFormProps<any> & FormProps> {
  constructor(props) {
    super(props)
    this.changeFocus = this.changeFocus.bind(this)
  }

  // eslint-disable-next-line class-methods-use-this
  changeFocus(num: number) {
    if (num < 3) {
      document.getElementById(`pin${num + 1}`).focus()
    }
  }

  render() {
    const { handleSubmit, PINSubmit, error, bEError, invalid, submitting } = this.props
    return (
      <form onSubmit={handleSubmit(PINSubmit)}>
        <div>
          <label>Transaction PIN</label>
          <div className={s.fieldsWrap}>
            <div className={s.fieldWrap}>
              <Field
                name='pin0'
                component={SingleField as React.FC<any>}
                validate={v.required}
                normalize={n.maxLength(1)}
                type='password'
                onChange={(e) => this.changeFocus(0)}
                maxLength={1}
                autoComplete='new-password'
              />
            </div>
            <div className={s.fieldWrap}>
              <Field
                name='pin1'
                component={SingleField as React.FC<any>}
                validate={v.required}
                normalize={n.maxLength(1)}
                type='password'
                onChange={(e) => this.changeFocus(1)}
                maxLength={1}
                autoComplete='new-password'
              />
            </div>
            <div className={s.fieldWrap}>
              <Field
                name='pin2'
                component={SingleField as React.FC<any>}
                validate={v.required}
                normalize={n.maxLength(1)}
                type='password'
                onChange={(e) => this.changeFocus(2)}
                maxLength={1}
                autoComplete='new-password'
              />
            </div>
            <div className={s.fieldWrap}>
              <Field
                name='pin3'
                component={SingleField as React.FC<any>}
                validate={v.required}
                normalize={n.maxLength(1)}
                type='password'
                onChange={(e) => this.changeFocus(3)}
                maxLength={1}
                autoComplete='new-password'
              />
            </div>
            <div className={s.tooltipWrap}>
              <Tooltip
                id='twoFactorAuth'
                text={`Your Transaction Pin is the 4 digit-code
            you chose when you first logged in to Weatherby's online banking`}
                inline
              >
                <div className={s.icon} aria-hidden>
                  ?
                </div>
              </Tooltip>
            </div>
          </div>
        </div>

        <BottomError message={error} />
        <BottomError message={bEError} />
        <Button submit disabled={invalid} text='Next' loading={submitting} />
      </form>
    )
  }
}

const ReduxForm = reduxForm<any, FormProps>({
  form: 'twoFactorAuth',
  onSubmitFail: () => {
    const NUMS: number[] = [0, 1, 2, 3]
    NUMS.forEach((num) => {
      const element = document.getElementById(`pin${num}`) as HTMLInputElement
      element.value = ''
    })
  },
})(Form)

export default ReduxForm
