import moment from 'moment'
import get from 'lodash/get'
import formatMoney from '../formatMoney'
// parked for now as error from test case is occuring
/*
data: {
  transactionDTOs: {
    transactionId?: string,
    valueDate?: string,
    creationDate?: string,
    accountId?: { displayValue?: string },
    creditAccountName?: string,
    creditAccountId?: { displayValue?: string },
    name?: string,
    amount?: string | number,
    currency?: string | number,
    transactionSnapshot?: { fileRefId?: string, paymentId?: string, sortCode?: string },
    taskDTO?: { id: string },
    transactionName?: string,
    createdByDetails?: {
      firstName: string
    },
    approvalDetails?: {
      status: string | boolean
    }
  }
}

*/
export default (
  data,
  state: {
    accounts: {
      realToMasked: {
        [key: string]: string
      }
    }
  }
) => {
  const { realToMasked } = state.accounts
  const tidyData = {}
  let typeCode = ''
  let items = get(data, 'data.transactionDTOs')

  if (typeof items === 'undefined') {
    return { items: {} }
  }

  const typeToCode = {
    PC_F_DFT: 'Make payment',
    PC_F_DFTI: 'Standing Order/ Future Payment',
    PC_F_SFT: 'Internal Transfer',
    PC_F_SFTI: 'Standing Order/ Future Payment',
    FU_F_DFT: 'Bulk Payment',
    PC_N_DOP: 'Create payee',
    PC_N_DDP: 'Delete payee',
    PC_N_UDOP: 'Edit payee',
    PC_F_GNDP: 'Third Party Payment - Adhoc',
  }

  // If there is only one transaction, the response does not return an array
  if (!items[0]) {
    items = [items]
  }
  if (items.length > 0 && items[0].length !== 0) {
    items.forEach((item) => {
      const {
        transactionId,
        approvalDetails,
        valueDate,
        creationDate,
        accountId,
        createdByDetails,
        creditAccountName,
        transactionSnapshot,
        creditAccountId,
        name,
      } = item

      const amount = item.amount ? item.amount.amount : ''
      const currency = item.amount ? item.amount.currency : ''
      const formattedAmount = amount ? formatMoney(amount, currency) : ''
      const created = moment(creationDate, 'YYYY-MM-DD').format('DD/MM/YYYY')

      const fields = get(item, 'taskDTO')

      const bulkReference = fields.id === 'FU_F_DFT' ? transactionSnapshot.fileRefId : null
      typeCode = fields.id
      tidyData[transactionId] = {
        typeCode: item.transactionSnapshot.paymentId,
        transactionName: item.transactionName,
        requestedBy: createdByDetails.firstName,
        type: fields.id,
        typeTitle: typeToCode[fields.id],
        statusCode: approvalDetails.status,
        status: approvalDetails.status,
        created,
        reference: String(transactionId),
        account: {
          accountNo: accountId.displayValue,
          sortCode: '609303',
          title: '',
        },
        createPayee: {
          accountNumber: accountId.displayValue,
          title: name,
        },
        amount: formattedAmount,
        amountPure: amount,
        customerId: String(item.partyId.displayValue),
        partyId: item.partyId.value,
        bulkReference,
        valueDate: valueDate && moment(valueDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        accountId: realToMasked[accountId.displayValue],
        payee: {
          accountId: creditAccountName === 'SELF' ? realToMasked[creditAccountId.displayValue] : '',
          accountNo: creditAccountId ? creditAccountId.displayValue : '',
          title: creditAccountName,
          internalSortCode: creditAccountName === 'SELF' ? '609303' : '',
          payeeSortCode: transactionSnapshot.sortCode ? transactionSnapshot.sortCode : '',
        },
      }
    })
  }
  return {
    items: tidyData,
    code: typeCode,
  }
}
