import { connect } from 'react-redux'
import history from '../../history'

import ReactSelect from '../../components/Forms/ReactSelect'

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (option) => {
    if (ownProps.historyMode === 'replace') {
      history.replace(option.value)
    } else {
      history.push(option.value)
    }
  },
})

export default connect(null, mapDispatchToProps)(ReactSelect)
