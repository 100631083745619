import React, { ReactNode } from 'react'
import s from './styles.css'

type WrapperProps = {
  children: ReactNode
}

const Wrapper = ({ children }: WrapperProps) => (
  <div className={s.root}>
    <div className={s.box}>{children}</div>
  </div>
)

export default Wrapper
