import React from 'react'
import TextJumpoffs from '../TextJumpoffs'
import s from './links.css'

type LinksProps = {
  availableItems?: boolean // todo - not sure if string|boolean
}

const Links = ({ availableItems }: LinksProps) => (
  <div className={s.root}>
    {availableItems ? (
      <TextJumpoffs
        items={[
          {
            title: 'Bulk payments',
            bodyText: 'Pay multiple people or companies simultaneously from a Excel or CSV file.',
            linkText: 'Bulk Payments',
            to: '/payments/bulk',
          },
          {
            title: 'Manage payees',
            bodyText:
              "View, modify or delete the people and companies that you've paid in the past.",
            linkText: 'Manage payees',
            to: '/payments/payees',
          },
          {
            title: 'International payment',
            bodyText:
              'Send the instruction to execute an international transfer to our team via secure message',
            linkText: 'Request payment',
            to: '/payments/request-international',
          },
        ]}
      />
    ) : (
      <TextJumpoffs
        items={[
          {
            title: 'Manage payees',
            bodyText:
              "View, modify or delete the people and companies that you've paid in the past.",
            linkText: 'Manage payees',
            to: '/payments/payees',
          },
          {
            title: 'International payment',
            bodyText:
              'Send the instruction to execute an international transfer to our team via secure message',
            linkText: 'Request payment',
            to: '/payments/request-international',
          },
        ]}
      />
    )}
  </div>
)

export default Links
