import { connect } from 'react-redux'
import DataTable from '../../components/DataTable'
import getResultsState from '../../selectors/getResultsState'
import { panelShow, panelHide } from '../../store/ui'

const mapStateToProps = (state, ownProps) => {
  let resultsState =
    ownProps.customResultsState ||
    getResultsState(
      ownProps.rows.length,
      ownProps.isFetching,
      ownProps.hasFilters,
      ownProps.failedToFetch
    )

  // Set ignored states based on "simpleResultsState" prop
  const ignoredStates = ownProps.simpleResultsState
    ? ['endOfResults', 'endOfEverything', 'endOfExisting']
    : []
  // Only use results state if it isnt in ignored states
  resultsState = ignoredStates.indexOf(resultsState.state) === -1 && resultsState

  return {
    resultsState: !ownProps.hideResultsState ? resultsState : undefined,
    browser: state.browser,
    currentPanel: state.ui.panel,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDrawerClick: (panel, id) => {
    if (panel === id) {
      dispatch(panelHide(id)) // todo-ts: id is never used in panelHide
    } else {
      dispatch(panelShow(id))
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(DataTable)
