import React, { ChangeEvent, MouseEventHandler, ReactNode, useState } from 'react'
import Icon from '../../Icon'
import ToggleButton from '../ToggleButton'
import s from './styles.css'

export type FieldLayoutProps = {
  layout?: 'normal' | 'inline'
  subLabel?: ReactNode
  children: ReactNode
  name?: string
  position?: 'top' | false
} & (
  | {
      label?: string
      iconString?: string
      onIconClick?: MouseEventHandler<HTMLAnchorElement>
    }
  | {
      label: string
      iconString: string
      onIconClick?: MouseEventHandler<HTMLAnchorElement>
    }
) &
  (
    | {
        canToggleField?: boolean
        toggleFieldStatus?: boolean
      }
    | {
        canToggleField: true
        toggleFieldStatus: boolean
      }
  )

const FieldLayout = ({
  layout,
  label,
  subLabel,
  children,
  name,
  position,
  canToggleField,
  toggleFieldStatus,
  iconString,
  onIconClick,
}: FieldLayoutProps) => {
  const [isToggledOff, setIsToggledOff] = useState(
    canToggleField === undefined ? false : !toggleFieldStatus
  )
  const onToggleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsToggledOff(!e.target.checked)
  }

  return (
    <div className={`${s[layout]} ${position && s[position]}`}>
      {(label || canToggleField) && (
        <div className={s.row}>
          {label && (
            <>
              <label className={s.label} htmlFor={name}>
                {label}
              </label>
              {iconString && (
                <a
                  role='none'
                  onClick={(e) => {
                    e.preventDefault()
                    onIconClick(e)
                  }}
                  className={s.copInformation}
                >
                  <Icon icon={iconString} />
                </a>
              )}
            </>
          )}
          {canToggleField && <ToggleButton name={name} onChange={onToggleChange} />}
        </div>
      )}

      {subLabel && <span className={s.subLabel}>{subLabel}</span>}

      {!isToggledOff && <div className={s.col}>{children}</div>}
    </div>
  )
}

export default FieldLayout
