export default (items, searchTermIP, fields) => {
  const searchTerm = searchTermIP.toLowerCase()

  return items.filter((item) =>
    fields.some(
      (fieldName) =>
        item[fieldName] && String(item[fieldName]).toLowerCase().indexOf(searchTerm) !== -1
    )
  )
}
