import * as React from 'react'

function SvgPayments(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 512 512'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M11 466.172C11 491.442 32.65 512 58.058 512h322.096c25.373 0 45.384-20.558 45.384-45.828v-2.664H11v2.664zm112.522-95.516c-8.981 0-17.109-3.551-23.12-9.323-6.01 5.772-14.137 9.323-23.118 9.323-18.475 0-33.467-14.889-33.467-33.295 0-18.373 14.992-33.262 33.467-33.262 8.981 0 17.109 3.552 23.119 9.323 6.01-5.771 14.138-9.323 23.119-9.323 18.475 0 33.466 14.89 33.466 33.262 0 18.406-14.991 33.295-33.466 33.295zm302.016-79.977c0-25.237-20.011-45.59-45.384-45.59H58.058C32.685 245.09 11 265.442 11 290.68v121.23h414.538v-121.23zm61.503-50.61L259.299 13.387C250.284 4.474 238.571 0 226.79 0c-11.747 0-23.529 4.474-32.476 13.387L69.566 137.485c-17.928 17.86-17.928 47.365 0 65.225l20.694 21.138h47.673l-44.497-44.872c-3.107-3.107-3.585-7-3.585-8.878 0-1.879.478-5.635 3.585-8.743l16.46-15.88 78.27 78.373h75.06l-115.8-115.834 70.723-70.62c3.108-3.108 6.728-3.689 8.64-3.689 1.913 0 5.532.376 8.674 3.484l227.742 226.614a12.098 12.098 0 013.585 8.606c0 1.878-.82 5.464-3.927 8.605l-12.908 12.533v47.468l36.744-36.267c17.963-17.894 18.27-46.819.342-64.679z'
        fill='#8F8F8B'
        fillRule='evenodd'
      />
    </svg>
  )
}

export default SvgPayments
