import React from 'react'
import DetailsGroup from '../DetailsGroup'
import s from './detailsStyles.css'

type DetailsProps = {
  status: string
  type: string
  infoItems: any[]
  reference: number
}

const Details = ({ infoItems, reference, status, type }: DetailsProps) => (
  <div>
    <h2>
      <span>{type}</span> - <span>{status}</span>
    </h2>
    <h3>E-Banking reference: {reference}</h3>
    <div className={s.infoBlocks}>
      <DetailsGroup items={infoItems} />
    </div>
  </div>
)

export default Details
