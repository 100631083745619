import React, { ReactNode } from 'react'
import { Link } from 'react-router'
import Icon from '../../Icon'
import s from './styles.css'

type NavItemProps = {
  show: () => void
  hide: () => void
  onClick: (e: React.MouseEvent<any, MouseEvent>, menu: string | boolean) => void
  children: ReactNode
  navId: string
  icon: string
  megaMenu: string | boolean
}

const NavItem = ({ icon, children, show, hide, onClick, navId, megaMenu }: NavItemProps) => (
  <Link
    className={s.root}
    to={`/${navId}`}
    activeClassName={s.active}
    onMouseEnter={show}
    onMouseLeave={hide}
    onClick={(e) => onClick(e, megaMenu)}
  >
    <span className={s.inner}>
      <Icon icon={icon} /> {children}
    </span>
  </Link>
)

export default NavItem
