/**
 * Tidies deeply nested and poorly named accounts data from API into something
 * easier to deal with!
 * @param  {Object} data - raw data from API
 * @return {Object} data - tidied data
 */

export default (data: {
  data: {
    accountStatementDTO: {
      documentContents: string
      documentName: string
    }
  }
}) => {
  const doc = data.data.accountStatementDTO
  const fileName = doc.documentName
  return {
    filename: fileName,
    content: doc.documentContents,
  }
}
