import React from 'react'
import Breadcrumbs from '../Breadcrumbs'
import PayeeTable from '../../containers/PayeeTable'
import ActionHeader from '../ActionHeader'
import InnerContainer from '../InnerContainer'
import DeleteModal from './DeleteModal'

type PayeesViewProps = {
  actions: any[]
  onActionClick: () => void
  handleSubmit: () => void // todo - was added as the form requires it
  payeeDelete: () => void // todo - was added as the form requires it
}

const PayeesView = (props: PayeesViewProps) => (
  <div>
    <Breadcrumbs
      items={[
        {
          to: '/payments',
          text: 'Payments',
        },
        {
          to: '/payments/manage-payees',
          text: 'Manage payees',
        },
      ]}
    />
    <InnerContainer>
      <ActionHeader
        title='Manage my payees'
        actionText='Add a new payee'
        to='/payments/payees/new'
      />
    </InnerContainer>

    <PayeeTable actions={props.actions} onActionClick={props.onActionClick} />
    <DeleteModal {...props} />
  </div>
)

export default PayeesView
