import React from 'react'
import Breadcrumbs from '../Breadcrumbs'
import ContactContainer from '../ContactContainer'
import InnerFullWidthContainer from '../InnerFullWidthContainer'
import ServicesHeader from '../ServicesHeader'
import s from '../ServicesView/styles.css'

const addPadding = true

export const ServicesInsurance = (/* { mappingError } */) => (
  <div>
    <Breadcrumbs
      items={[
        {
          to: '/services',
          text: 'Additional services',
        },
        {
          to: '/services/vatservices',
          text: 'VAT services',
        },
      ]}
    />
    <ServicesHeader title='VAT services' secondaryTitle='Reclaim your racing VAT' />
    <InnerFullWidthContainer addPadding={addPadding}>
      <div className={`${s.textArea} ${s.extraTopMargin}`}>
        <p>
          If you own a racehorse, handling VAT yourself can prove a complex, time-consuming task.
          Our VAT Services team takes the administrative burden away from you.
        </p>
      </div>
      <div className={s.textArea}>
        <p>
          We can provide expert advice and deal with HMRC on your behalf on all VAT affairs, taking
          care of everything from online VAT applications to the collation and submission of
          quarterly VAT returns.
        </p>
      </div>
    </InnerFullWidthContainer>
    <InnerFullWidthContainer addPadding={addPadding}>
      <ContactContainer
        title='Find out more'
        infoText='To find out more'
        items={[
          {
            linkTitle: '+44 (0) 1933 304 777',
            to: '+44 (0) 1933 304 777',
            telNumber: true,
          },
          // {
          //   linkTitle: 'Send Secure Message',
          //   to: '/secure-mail/new'
          // }
          {
            linkTitle: 'Send Secure Message',
            to: '/secure-mail/enquiry#vatservices',
          },
        ]}
      />
    </InnerFullWidthContainer>
  </div>
)

export default ServicesInsurance
