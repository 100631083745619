export const callRefresh = (url, state, dispatch) => {
  dispatch({
    type: 'LOGOUT_REQUEST',
    payload: {
      url,
    },
  })
}

class ConstructRefresh {
  store: any

  injectStore(store) {
    this.store = store
  }

  callRefresh(url) {
    return callRefresh(url, this.store.getState(), this.store.dispatch)
  }
}

export default new ConstructRefresh()
