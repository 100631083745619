import React from 'react'
import { Field } from 'redux-form'
import s from './styles.css'

import FieldLayout, { FieldLayoutProps } from '../FieldLayout'

type RadioProps = {
  options: any[]
  name: string
  direction?: 'row' | 'column'
} & Omit<FieldLayoutProps, 'children' | 'name'>

const Radio = ({ options, name, direction, ...fieldLayoutProps }: RadioProps) => (
  <FieldLayout {...fieldLayoutProps} position={direction === 'column' ? 'top' : undefined}>
    <div className={`${s.inner} ${s[direction]}`}>
      {options.map((option, index) => {
        const key = `${name}__${index}`
        return (
          <div className={s.option} key={key}>
            <Field component='input' type='radio' name={name} id={key} value={option.value} />
            <label htmlFor={key} className={s.label}>
              <span className={s.dot}> </span>
              <span>{option.label}</span>
            </label>
          </div>
        )
      })}
    </div>
  </FieldLayout>
)

export default Radio
