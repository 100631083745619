// eslint-disable-next-line import/prefer-default-export
export function getItems(state) {
  // If isFetching then return nothing
  if (state.activityLog.type.isFetching) return []

  const { items } = state.activityLog.type
  const itemsVals = Object.values(items)

  const itemsFull = itemsVals.map(
    (item: {
      account: any
      currency: any
      amount: number
      reference: any
      created: any
      amountPure: any
    }) => {
      const i = {
        ...item,
        reference: item.reference,
        created: item.created.substring(0, 10),
      }

      if (item.account) i.account = item.account.accountNo
      if (item.currency) i.currency = item.currency
      if (item.amount) i.amount = item.amount
      return i
    }
  )

  return itemsFull
}
