import { call, put, takeLatest, select } from 'redux-saga/effects'
import { reset } from 'redux-form'
import * as c from '../../constants'
import request from '../../utils/request'
import getAccount from '../../selectors/getAccount'
import getAccounts from '../../selectors/getAccounts'
import { transferConfirm } from '../TransferMoneyForm/actions'
import { flowSuccess, flowMergeData } from '../../store/flow'
import { formError } from '../../sagas/formErrors/actions'
import dateOptionsProcess from '../../utils/dateOptionsProcess'
// import GA from '../../utils/GA'
import { accountsFetch } from '../Accounts/actions'
import { hasFunds } from './selectors'

export function* handleSubmit(action) {
  try {
    const formData = action.payload
    const fromId = formData.from.value
    const toId = formData.to.value
    const accounts = yield select(getAccounts)
    const account = accounts[fromId]
    const partyId = account.customerId
    let id = ''
    const dateOptions = yield call(dateOptionsProcess, formData)
    let later = false
    if (!formData.when) {
      formData.when = 'now'
    }
    let url: any = {
      default: 'cz/v1/payments/transfers/self',
      get: 'v1/payments/transfers/self',
    }

    if (formData.isRegular || formData.when !== 'now') {
      url = {
        default: 'cz/v1/payments/instructions/transfers/self',
        getPatch: 'v1/payments/instructions/transfers/self',
      }
      later = true
    }
    const canMakePayment = yield select(hasFunds, fromId, parseFloat(formData.amount))
    const alphaNumeric = /^[0-9a-zA-Z ]+$/
    let nonce = ''
    if (!canMakePayment) {
      yield put(
        formError(transferConfirm.FAILURE, {
          name: 'API',
          message: 'This account does not have the required funds to make these payments.',
        })
      )
    } else if (formData.reference && !formData.reference.match(alphaNumeric)) {
      yield put(
        formError(transferConfirm.FAILURE, {
          name: 'API',
          message: `Please provide only alphanumeric values and
        spaces for reference.`,
        })
      )
    } else {
      nonce = yield call(request, {
        id: c.requestNonce,
        url: 'v1/session/nonce',
      })
    }
    yield put(
      flowMergeData({
        disableCancel: true,
      })
    )
    const requestDetails = {
      id: c.requestTransferOrPayment,
      stage: 0,
      payload: {
        type: 'transfer',
        from: fromId,
        to: toId,
        formData,
        amount: formData.amount,
        reference: formData.reference,
        later,
        ...dateOptions.data,
      },
    }
    const requestParams = yield call(request, {
      id: requestDetails.id,
      nonce,
      partyId,
      url: `${url.default}?locale=en`,
      payload: requestDetails.payload,
    })
    let warnings = ''
    warnings =
      requestParams.data &&
      requestParams.data.status &&
      requestParams.data.status.message &&
      requestParams.data.status.message.detail
    const nonce2 = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    let baseUrl
    if (formData.isRegular || formData.when !== 'now') {
      id = requestParams.data.instructionId
      baseUrl = url.getPatch
    } else {
      id = requestParams.data.paymentId
      baseUrl = url.get
    }
    yield call(request, {
      id: c.requestTransferOrPaymentGet,
      nonce: nonce2,
      partyId,
      url: `${baseUrl}/${id}?locale=en`,
    })

    const nonce3 = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    if (!formData.isRegular && formData.when === 'now') {
      baseUrl = url.default
    } else {
      baseUrl = url.getPatch
    }

    const requestParamsConfirm = yield call(request, {
      id: c.requestTransferOrPaymentPatch,
      nonce: nonce3,
      partyId,
      url: `${baseUrl}/${id}?locale=en`,
    })

    requestDetails.stage = 1

    const from = yield select(getAccount, fromId)
    const to = yield select(getAccount, toId)

    yield put(
      flowSuccess({
        warnings,
        needsAuthoriser: requestParamsConfirm.data.status.message.code === 'DIGX_APPROVAL_REQUIRED',
        timeOutError: requestParamsConfirm.data.externalReferenceId === 'UNDER_PROCESSING',
        referenceNumber: requestParamsConfirm.data.status.referenceNumber,
        from,
        to,
        amount: formData.amount,
        reference: formData.reference,
        ...dateOptions.display,
      })
    )

    yield put(transferConfirm.success())
    yield put(reset('transferMoney'))

    // #GA4-PROVISIONALLY-DISABLED
    // GA.event({
    //   category: 'Form',
    //   action: 'Submit',
    //   label: 'Internal transfer',
    // })

    yield put(accountsFetch())
  } catch (error) {
    yield put(formError(transferConfirm.FAILURE, error))
  }
}

export function* watchTransfer() {
  yield takeLatest(transferConfirm.REQUEST, handleSubmit)
}
