import React from 'react'
import Icon from '../Icon'
import s from './styles.css'

type StatusProps = {
  text: string
  type?: 'success' | 'fail'
}

const Status = ({ text, type = 'success' }: StatusProps) => (
  <div className={`${s.root} ${type === 'success' ? s.rootSuccess : s.rootFail}`}>
    <div className={`${s.icon} ${type === 'success' ? s.iconSuccess : s.iconFail}`}>
      <Icon icon={type === 'success' ? 'check' : 'cross'} />
    </div>
    {text}
  </div>
)

export default Status
