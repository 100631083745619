import React from 'react'
import s from './styles.css'
import Icon from '../../Icon'

type ValidationListProps = {
  items: {
    title: string
    valid?: boolean // todo - was isValid - need testing
  }[]
}

const ValidationList = ({ items }: ValidationListProps) => (
  <ul className={s.root}>
    {items.map((item, index: number) => {
      const validClass = item.valid ? s.valid : undefined

      return (
        // eslint-disable-next-line react/no-array-index-key
        <li className={`${s.item} ${validClass}`} key={index}>
          <span className={s.icon}>
            <Icon icon={item.valid ? 'check' : 'cross'} />
          </span>
          {item.title}
        </li>
      )
    })}
  </ul>
)

export default ValidationList
