import React, { ReactNode } from 'react'
import Sticky from 'react-stickynode'
import DataTable from '../../containers/DataTable'
import TableHead from '../../containers/DataTableHead'
import PendingTransactionsTable from '../../containers/PendingTransactionsTable'
import s from './styles.css'

type StickyTableProps = {
  headerCols: any[]
  children?: ReactNode
  showPendingTransactions?: boolean
  filters?: any
  browser?: {
    [key: string]: {
      [key: string]: boolean | string
    }
  }
  rows: {
    id: string
    card?: string
  }[] // todofixed - was added by Transactions/index
  hasDrawer?: boolean // todo - was added by Transactions/index
  hideResultsState?: boolean
}

export const StickyTable = ({ headerCols, children, ...rest }: StickyTableProps) => (
  <div>
    {/* activeClass?? or releasedClass?? */}
    <Sticky activeClass={s.sticky}>
      <div className={s.stickyInner}>
        {children}
        <div className={s.mockTableHeader}>
          <table aria-hidden>
            <TableHead cols={headerCols} {...rest} />
          </table>
        </div>
      </div>
    </Sticky>
    <div className={s.printHeader}>
      <table aria-hidden>
        <TableHead cols={headerCols} {...rest} />
      </table>
    </div>
    {rest.showPendingTransactions && (
      <PendingTransactionsTable rows={rest.rows} filters={rest.filters} />
    )}
    <DataTable hideHeader {...rest} />
  </div>
)

export default StickyTable
