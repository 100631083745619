import React from 'react'
import InnerContainer from '../InnerContainer'
import Icon from '../Icon'
import s from './styles.css'

const EntityRedirect = () => (
  <div className={s.root}>
    <InnerContainer>
      <h2>Taking you back to your accounts</h2>
      <div className={s.spinner}>
        <Icon icon='loading' className={s.icon} />
      </div>
    </InnerContainer>
  </div>
)

export default EntityRedirect
