/* eslint-disable no-nested-ternary */
import React, { ReactNode } from 'react'
import { reduxForm, InjectedFormProps } from 'redux-form'
import s from './styles.css'
import DetailsGroup from '../DetailsGroup'
import Button from '../Button'
import BottomError from '../Forms/BottomError'
import FlowSuccess from '../FlowSuccess'
import ScrollTo from '../../containers/ScrollTo'
import TwoFactorAuth from '../../containers/TwoFactorAuth'
import Warnings from '../Warnings'
import CutoffPayments from './CutoffPayments'
import { ConfirmationOfPayeeStatus } from '../../containers/ConfirmationOfPayee/reducer'

type FlowConfirmProps = {
  details?: {
    descriptor?: string
    title?: string
    extras?: any[]
    linkText?: string
    excluded?: boolean
  }[]
  handleSubmit: () => void
  formSubmit: () => void
  onGoBackClick: () => void
  submitting?: boolean
  error?: string
  success?: boolean
  warnings?: any[]
  confirmIsVisible?: boolean
  goBackLinkIsVisible?: boolean
  authIsVisible?: boolean
  title: string
  goBackText?: string
  successMessage: string
  naMessage: string
  taMessage?: string
  successLinks?: any[]
  submitText?: string
  disableCancel?: boolean
  authTitle?: string
  needsAuthoriser?: boolean
  timeOutError?: boolean
  referenceNumber?: string
  col2Top?: string
  secondarySuccessMessage?: string
  SuccessComponent?: any // todo - was ReactNode,
  cancelText?: string
  cancelLink?: string
  isCutOffPayment?: boolean
  showPaymentCutoffModalFunc?: () => void
  hidePaymentCutoffModalFunc?: () => void
  showPaymentCutoffModal?: boolean
  cutoffMessage?: string
  copStatus?: ConfirmationOfPayeeStatus
  verificationReport?: any
}

const FlowConfirm = ({
  details,
  handleSubmit,
  formSubmit,
  error,
  submitting,
  success,
  onGoBackClick,
  goBackText = 'Edit',
  warnings,
  needsAuthoriser,
  confirmIsVisible,
  goBackLinkIsVisible,
  authIsVisible,
  title,
  successLinks,
  disableCancel = false,
  successMessage,
  naMessage,
  submitText = 'Confirm',
  authTitle,
  col2Top,
  secondarySuccessMessage,
  SuccessComponent,
  cancelText = 'Cancel',
  cancelLink = '/accounts',
  taMessage,
  timeOutError,
  referenceNumber,
  isCutOffPayment,
  showPaymentCutoffModalFunc,
  hidePaymentCutoffModalFunc,
  showPaymentCutoffModal,
  cutoffMessage,
  copStatus,
  verificationReport,
}: InjectedFormProps<any> & FlowConfirmProps) => (
  <div className={s.root}>
    <div className={s.col}>
      <ScrollTo>
        <h2>{title}</h2>
      </ScrollTo>

      <Warnings items={warnings} />
      <DetailsGroup
        forceRows
        items={details}
        copStatus={copStatus}
        verificationReport={verificationReport}
      />
      {!success && (
        <div>
          {goBackLinkIsVisible && !disableCancel && (
            <a href='#' className={s.edit} onClick={onGoBackClick}>
              {goBackText}
            </a>
          )}
          {confirmIsVisible && isCutOffPayment && (
            <CutoffPayments
              showPaymentCutoffModal={showPaymentCutoffModal}
              cancelText={cancelText}
              cancelLink={cancelLink}
              submitText={submitText}
              disableCancel={disableCancel}
              error={error}
              onSubmit={handleSubmit(formSubmit)}
              showPaymentCutoffModalFunc={showPaymentCutoffModalFunc}
              hidePaymentCutoffModalFunc={hidePaymentCutoffModalFunc}
              cutoffMessage={cutoffMessage}
              submitting={submitting}
            />
          )}
          {confirmIsVisible && !isCutOffPayment && (
            <form onSubmit={handleSubmit(formSubmit)}>
              <BottomError message={error} />
              <Button submit text={submitText} loading={submitting} />
              <Button
                // eslint-disable-next-line react/style-prop-object
                style='ghost-dark'
                disabled={disableCancel}
                to={cancelLink}
                className={s.cancel}
                text={cancelText}
              />
            </form>
          )}
        </div>
      )}
    </div>
    <div className={s.col} style={{ marginTop: col2Top }}>
      {success ? (
        SuccessComponent ? (
          <SuccessComponent success={success} />
        ) : (
          <FlowSuccess
            forceRows
            needsAuthoriser={needsAuthoriser}
            timeOutError={timeOutError}
            message={successMessage}
            naMessage={naMessage}
            taMessage={taMessage}
            links={successLinks}
            referenceNumber={referenceNumber}
            secondaryMessage={secondarySuccessMessage}
          />
        )
      ) : (
        <div>{authIsVisible && <TwoFactorAuth title={authTitle} />}</div>
      )}
    </div>
  </div>
)

const FlowConfirmReduxForm = reduxForm<any, FlowConfirmProps>({
  form: 'flowConfirm',
})(FlowConfirm)

export default FlowConfirmReduxForm
