import get from 'lodash/get'

export default (data: {
  data: {
    fileUpload: {
      attachmentId: string
    }
  }
}) => {
  const ref = get(data, 'data.fileUpload.attachmentId')
  return {
    bulkFileReference: ref,
  }
}
