export default (store) => ({
  path: 'edit/:id',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const PaymentsUpcomingEdit = require('../containers/PaymentsUpcomingEdit').default

        cb(null, PaymentsUpcomingEdit)
      },
      'payments-upcoming-edit'
    )
  },
})
