import { connect } from 'react-redux'
import ForgotPassword from '../../components/ForgotPassword'
import { panelShow } from '../../store/ui'

export const mapStateToProps = (state) => ({
  isShowing: state.ui.panel === 'forgotPassword',
})

export const mapDispatchToProps = (dispatch) => ({
  onClick: (e) => {
    e.preventDefault()
    dispatch(panelShow('forgotPassword'))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
