import React, { ChangeEventHandler } from 'react'
import { Field } from 'redux-form'
import s from './styles.css'

type ToggleButtonProps = {
  name: string
  onChange: ChangeEventHandler
}

const ToggleButton = ({ name, onChange }: ToggleButtonProps) => {
  return (
    <label className={s.switch}>
      <Field
        id={`${name}Checked`}
        name={`${name}Checked`}
        component='input'
        type='checkbox'
        onChange={onChange}
        className={s.input}
      />
      <span className={s.slider} />
    </label>
  )
}

export default ToggleButton
