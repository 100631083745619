import React from 'react'
import s from './styles.css'
import IconButton from '../IconButton'

type HeadingWithPrintProps = {
  onPrintClick?: () => void
  onReceiptClick?: () => void
  title: string
  showPrint?: boolean
  showReceipt?: boolean
}

const HeadingWithPrint = ({
  onPrintClick = () => window.print(),
  onReceiptClick,
  title,
  showPrint = true,
  showReceipt = false,
}: HeadingWithPrintProps) => (
  <div className={s.root}>
    <h1>{title}</h1>
    <div className={s.buttons}>
      {showReceipt && (
        <div className={s.button}>
          <IconButton icon='download' text='Download eReceipt' onClick={onReceiptClick} />
        </div>
      )}
      {showPrint && (
        <div className={s.button}>
          <IconButton icon='print' text='Print' onClick={onPrintClick} />
        </div>
      )}
    </div>
  </div>
)

export default HeadingWithPrint
