/* eslint-disable react/style-prop-object */
import React, { Component, ReactElement } from 'react'
import { Link } from 'react-router'
import isFunction from 'lodash/isFunction'
import Form from './Form'
import Button from '../Button'
import s from './styles.css'
import Icon from '../Icon'
import { FormProps } from './types'

type ActionProps = {
  useTextLinks?: boolean
  onActionClick?: (actionId: string, id: string) => void
  id?: string
  action?: {
    id?: string
    text?: string
    to?: string | ((value: string) => string)
    isButton?: boolean
    icon?: string
    mobileButtonStyle?: string
  }
}

class Action extends Component<ActionProps, any> {
  constructor(props: ActionProps) {
    super(props)
    this.handleActionClick = this.handleActionClick.bind(this)
  }

  // eslint-disable-next-line consistent-return
  handleActionClick(
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) {
    e.preventDefault()
    const { action, onActionClick, id } = this.props
    if (!action.to) {
      return onActionClick(action.id, id)
    }
  }

  render() {
    const { useTextLinks, action, id } = this.props

    if (useTextLinks) {
      return (
        <li>
          {action.icon ? (
            <button type='button' className={s.boxButton} onClick={this.handleActionClick}>
              <Icon icon={action.icon === 'up' ? 'chevronUp' : 'chevronDown'} />
            </button>
          ) : (
            <a href='#' onClick={this.handleActionClick}>
              {action.text}
            </a>
          )}
        </li>
      )
    }
    return (
      <li>
        <Button
          className={s.actionButton}
          onClick={this.handleActionClick}
          to={isFunction(action.to) && action.to(id)}
          size='small'
          text={action.text}
          style={action.mobileButtonStyle || 'secondary'}
        />
      </li>
    )
  }
}

type CardProps = {
  useTextLinks?: boolean
  state?: 'editing' | 'disabled'
  id?: string
  title?: string
  extra?: string
  details?: string
  text?: string
  text2?: string
  to?: string | null
  actions?: {
    id?: string
    text?: string
    to?: string | ((value: string) => string)
    isButton?: boolean
    icon?: string
    mobileButtonStyle?: string
  }[]
  form?: FormProps
  onActionClick?: () => void
  onCancelClick?: () => void
  onSaveClick?: () => void
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

const Card = ({
  state,
  id,
  title,
  details,
  text,
  actions,
  form,
  onClick,
  onActionClick,
  onCancelClick,
  onSaveClick,
  extra,
  useTextLinks,
  text2,
  to,
}: CardProps) => {
  const className = s[state]
  let endEl: ReactElement
  let startEl: ReactElement

  if (state === 'editing') {
    endEl = useTextLinks ? (
      <ul className={s.actions}>
        <li>
          <a onClick={onSaveClick} className={s.save} href='#'>
            Save
          </a>
        </li>
        <li>
          <a onClick={onCancelClick} className={s.cancel} href='#'>
            Cancel
          </a>
        </li>
      </ul>
    ) : (
      <ul className={s.actions}>
        <li>
          <Button
            className={s.actionButton}
            style='primary'
            size='small'
            onClick={onSaveClick}
            text='Save'
          />
        </li>
        <li>
          <Button
            className={s.actionButton}
            style='ghost-dark'
            size='small'
            onClick={onCancelClick}
            text='Cancel'
          />
        </li>
      </ul>
    )
    startEl = <Form {...form} />
  } else {
    endEl = actions && (
      <ul className={s.actions}>
        {actions.map((action) => (
          <Action
            key={`${id}-${action.id}`}
            action={action}
            id={id}
            useTextLinks={useTextLinks}
            onActionClick={onActionClick}
          />
        ))}
      </ul>
    )
    startEl = <>{title}</>
  }

  const inner = (
    <div className={`${s.root} ${className}`}>
      <div className={s.titleCol}>{startEl}</div>
      <div className={s.detailsCol}>{details}</div>
      {(text || extra) && (
        <div className={s.textCol}>
          <span>{text}</span>
          {extra && <span className={s.extra}>{extra}</span>}
        </div>
      )}
      {text2 && <div className={s.textCol}>{text2}</div>}
      {endEl}
    </div>
  )

  let outer = inner
  if (onClick)
    outer = (
      <a href='#' className={s.anchor} onClick={onClick}>
        {inner}
      </a>
    )
  if (to)
    outer = (
      <Link to={to} className={s.anchor}>
        {inner}
      </Link>
    )

  return outer
}

export default Card
