import { connect } from 'react-redux'
import get from 'lodash/get'
import { getFormValues } from 'redux-form'
import AccountServicesForm from '../../components/AccountServicesForm'
import getFlowData from '../../selectors/getFlowData'
import convertToOptions from '../../utils/convertToOptions'
import getPayInBookAccounts from '../../selectors/getPayInBookAccounts'
import getChequeBookAccounts from '../../selectors/getChequeBookAccounts'
import getIsAccountMappingKnown from '../../selectors/getIsAccountMappingKnown'
import getRouteQuery from '../../selectors/getRouteQuery'
import { accountCustomerAddressUpdate } from '../Accounts/actions'
import { submitOrder } from '../AccountServices/actions'

export const mapStateToProps = (state, ownProps) => {
  const formValues = getFormValues('accServ')(state) || {}
  const flowData = getFlowData(state) || {}
  const accountId = get(formValues, 'account.value')
  const address = get(state, `accounts.items[${accountId}].customerAddress`)
  const accountOptionsAreLoading = !getIsAccountMappingKnown(state, flowData.serviceType)
  const query = getRouteQuery(state)

  let type
  let accounts

  if (flowData.serviceType === 'chequeBooks') {
    type = 'cheque book'
    accounts = getChequeBookAccounts(state)
  } else {
    type = 'pay-in book'
    accounts = getPayInBookAccounts(state)
  }

  return {
    type,
    address,
    needsAuthoriser: get(state, 'flow.data.needsAuthoriser'),
    accountOptions: !accountOptionsAreLoading ? convertToOptions(accounts) : undefined,
    accountOptionsAreLoading,
    addressIsShowing: get(formValues, 'account.value') !== undefined,
    addressIsLoading: !address,
    initialValues: {
      account: {
        value: query.accountId,
      },
    },
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  submitOrder,
  onAccountSelectChange: (e) => {
    dispatch(accountCustomerAddressUpdate(e.value))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountServicesForm)
