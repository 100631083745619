/* eslint-disable */
// @ts-nocheck
// todo - eslint and typescript
// Takes an error string (many sentences) and caps it at
// x sentences. Counts the rest and includes a message
import tranformMessages from './tranformMessages'

export default (errorString: string, limit: number) => {
  // Create array based on full stops
  const arr = tranformMessages(errorString)

  return arr.reduce((string: string, item: string | number, index: number) => {
    // Ignore any item that is an empty string (e.g. the last one)
    if (item !== '' && item !== ' ') {
      if (index < limit) {
        // Concat string for as long as limit isn't reached
        string += item
      } else if (index === limit) {
        // If limit is reached, calculate the remainder and
        // append string
        const num = arr.length - limit
        string += ` ${num} other errors occured.`
      }
    }
    return string
  }, '')
}
