import { connect } from 'react-redux'
import AuthorisationsCard from '../../components/AuthorisationsCard'
import getAccount from '../../selectors/getAccount'
import { modalShow } from '../../store/ui'
import * as a from './actions'
import formatSortCode from '../../utils/formatSortCode'

const mapStateToProps = (
  state,
  {
    reference,
    accountId,
    typeCode,
    beneName,
    toSortCode,
    toAccountNo,
    valueDate,
    createPayee,
    amount,
    amountPure,
    paymentReference,
    transactionName,
    lastPaymentDate,
    frequency,
    payMode,
    payee,
  }
) => {
  let account = accountId ? getAccount(state, accountId) : {}
  const repeatUntil = lastPaymentDate || 'Further notice'
  let toAccount
  let fromDesc
  if (transactionName === 'CBR') {
    fromDesc = 'Account'
  }
  if (transactionName === 'PC_N_DOP' || transactionName === 'PC_N_UDOP') {
    fromDesc = 'Payee details'
    account = createPayee
  } else {
    fromDesc = 'From'
  }

  if (payee.accountId) {
    toAccount = getAccount(state, payee.accountId)
  } else {
    toAccount = {
      title: payee.title,
      sortCode: payee.insternalSortCode ? payee.insternalSortCode : payee.payeeSortCode,
      accountNumber: payee.accountNo,
    }
  }
  const detailItems = [
    {
      descriptor: fromDesc,
      title: account.title,
      extras: [`${account.sortCode} ${account.accountNumber}`],
    },
    {
      descriptor: 'Payee',
      title: toAccount.title,
      extras: [`${formatSortCode(toAccount.sortCode)} ${toAccount.accountNumber}`],
      excluded: !toAccount.title,
    },
    {
      descriptor: 'Amount',
      title: amount,
      excluded: amountPure === 0 || !amountPure,
    },
    {
      descriptor: 'Reference',
      title: paymentReference,
      excluded: !paymentReference,
    },
    {
      descriptor: 'When',
      title: valueDate,
      excluded: !valueDate || valueDate === '',
    },
    {
      descriptor: 'Recurring',
      title: `Pay ${frequency} until ${repeatUntil}`,
      excluded: payMode !== 'recurring',
    },
  ]

  const isSubmitting =
    state.flow.data &&
    state.flow.data.currentlyProcessing === reference &&
    state.flow.data.currentlyProcessingType === 'authorise'

  const isCancelling =
    state.flow.data &&
    state.flow.data.currentlyProcessing === reference &&
    state.flow.data.currentlyProcessingType === 'decline'

  return {
    detailItems,
    isSubmitting,
    isCancelling,
    submitDisabled: !isSubmitting && !!(state.flow.data && state.flow.data.currentlyProcessing),
    cancelDisabled: !!(state.flow.data && state.flow.data.currentlyProcessing),
    actionsAreShowing: !state.authorisations.list.items[reference].done,
    isValid: state.authorisations.list.items[reference].status !== 'EXPIRED',
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAuthoriseClick: () => {
    dispatch(a.authorisationsProcessRequest(ownProps.reference, 'authorise'))
  },
  onDeclineClick: (e) => {
    e.preventDefault()
    dispatch(modalShow(ownProps.reference))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthorisationsCard)
