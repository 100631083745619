import React from 'react'
import s from './styles.css'
import Checkboxes from '../../Forms/Checkboxes'
import MoneyFormHelper from '../../formHelpers/MoneyFormHelper'
import { SupportedCurrenciesType } from '../../../models/enum'

type FieldsProps = {
  currency?: SupportedCurrenciesType // todo - was mandatory, but the child components desnt seem to pass it
  isRacingAccount?: boolean // todo - was mandatory, but the child components desnt seem to pass it
}

const Fields = ({ currency, isRacingAccount }: FieldsProps) => {
  const firstColumnOptions = [
    {
      name: 'all',
      label: 'All transactions',
    },
    {
      name: 'cardTransactions',
      label: 'Card transactions',
    },
    {
      name: 'cheques',
      label: 'Cheques',
    },
    {
      name: 'payments',
      label: 'Payments',
    },
    {
      name: 'reccurentPayments',
      label: 'Standing orders and direct debits',
    },
    {
      name: 'incomingTransfers',
      label: 'Incoming Transfers',
    },
  ]

  const secondColumnOptions = [
    {
      name: 'cash',
      label: 'Cash',
    },
    {
      name: 'internalTransfers',
      label: 'Internal Transfers',
    },
    {
      name: 'international',
      label: 'International',
    },
    {
      name: 'loanRepayments',
      label: 'Loan repayments',
    },
    {
      name: 'interestAndCharges',
      label: 'Interest and charges',
    },
  ]

  return (
    <div className={s.root}>
      <div className={s.col}>
        <fieldset>
          <legend>Type</legend>
          <div className={s.row}>
            <div className={s.col}>
              <Checkboxes options={firstColumnOptions} direction='column' />
            </div>
            <div className={s.col}>
              <Checkboxes options={secondColumnOptions} direction='column' />
            </div>
          </div>
        </fieldset>
      </div>
      <div className={s.col}>
        <fieldset>
          <legend>Amount</legend>
          <MoneyFormHelper label='Minimum' name='minAmount' placeholder={currency} />
          <MoneyFormHelper label='Maximum' name='maxAmount' placeholder={currency} />
        </fieldset>
      </div>
    </div>
  )
}

export default Fields
