import { connect } from 'react-redux'
import { organiseItems } from './selectors'
import Details from '../../components/ActivityLogSingleView/details'

const mapStateToProps = (state, ownProps) => {
  const { reference, status, type } = state.activityLog.single.items
  return {
    infoItems: organiseItems(state),
    reference,
    status,
    type: type === 'My Account Transfer' ? 'Internal Transfer' : type,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Details)
