import get from 'lodash/get'
import moment from 'moment'
import { decodeHTML } from '../../utils/decodeHTML'

/**
 * Tidies deeply nested and poorly named accounts data from API into something
 * easier to deal with!
 * @param  {Object} data - raw data from API
 * @return {Object} data - tidied data
 */
export interface TidyBulletins {
  data: {
    status: {
      result: string
      contextID: string
      message: {
        code: string | number
        type: string
      }
    }
    mailerUserMapDTOs: [
      {
        dictionaryArray: {
          nameValuePairDTOArray: {
            name: string
            genericName: string
            value: string
            datatype: string
          }[]
        }[]
        creationDate: string
        version: number
        generatedPackageId: boolean
        auditSequence: number
        receivedDate: string
        mapId: string
        subject: string
        messageId: {
          displayValue: string
          value: string
        }
        userId: string
        status: string
        priority: string
        messageBody: string
      }
    ]
  }
}
export default (data: TidyBulletins) => {
  const tidyData = { items: {} }

  let bulletin = get(data, 'data.mailerUserMapDTOs')

  if (bulletin) {
    if (!(bulletin instanceof Array)) {
      bulletin = [bulletin]
    }

    bulletin.forEach((item) => {
      tidyData.items[`${item.messageId.value}-${item.mapId}`] = {
        id: String(item.messageId.value),
        threadId: String(item.mapId),
        sender: '',
        subject: decodeHTML(item.subject),
        isRead: item.status !== 'U',
        date: moment(item.receivedDate, 'YYYY-MM-DD HH:mm:ss').format('dddd, Do MMMM YYYY - HH:mm'),
        folderName: item.folderid === 'O' ? 'outbox' : 'inbox',
        timeStamp: parseInt(moment(item.receivedDate, 'YYYY-MM-DD HH:mm:ss').format('X')),
      }
    })
  }
  return tidyData
}
