import get from 'lodash/get'
import moment from 'moment'
import formatMoney from '../formatMoney'
/**
 * Tidies deeply nested and poorly named loan data from API into something
 * easier to deal with!
 * @param  {Object} data - raw data from API
 * @return {Object} data - tidied data
 */
export interface TidyPaymentsFutureD {
  data: {
    upcomingPaymentInstructionList: {
      amount: {
        amount: number
        currency: string
      }
      startDate: string
      externalReferenceNumber: string | number
      idfcatref: number
      narrative: string | number
      partyId: {
        value: string | number
      }
      statusType: string
      txntype: string
      payeeNickName: string
      debitAccountId: {
        displayValue: string | number
      }
    }[]
  }
}
export default (
  data: TidyPaymentsFutureD,
  state: {
    accounts: {
      realToMasked: {
        [key: string]: string
      }
    }
  }
) => {
  const tidyData = {}
  const { realToMasked } = state.accounts
  let items = get(data, 'data.upcomingPaymentInstructionList')

  if (items) {
    if (!(items instanceof Array)) {
      items = [items]
    }

    items.forEach((item) => {
      const id = String(item.externalReferenceNumber)
      const fromAccountNumber = String(item.debitAccountId.displayValue)
      const fromId = realToMasked[fromAccountNumber]
      const curr = item.amount.currency
      const statusCode = item.statusType && item.statusType.toLowerCase() === 'active' ? 'A' : 'C'
      const recipientName = String(item.payeeNickName)

      tidyData[id] = {
        id,
        statusCode,
        fromId,
        recipientName,
        transactionType: item.txntype,
        auditNumber: String(item.externalReferenceNumber),
        catRef: String(item.idfcatref),
        amount: formatMoney(item.amount.amount, curr),
        amountPure: item.amount.amount,
        customerId: String(item.partyId.value),
        date: moment(item.startDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      }
    })
  }

  return {
    items: tidyData,
  }
}
