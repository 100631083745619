import React from 'react'
import { reduxForm, InjectedFormProps } from 'redux-form'
import PasswordFormHelper from '../formHelpers/PasswordFormHelper'
import NewPasswordFormHelper from '../formHelpers/NewPasswordFormHelper'
import TransactionPinFormHelper from '../formHelpers/TransactionPinFormHelper'
import NewTransactionPinFormHelper from '../formHelpers/NewTransactionPinFormHelper'
import Button from '../Button'
import BottomError from '../Forms/BottomError'
import s from './styles.css'
import { ManageCredentialsType } from '../../models/enum'

type FormProps = {
  type: string
  handleSubmit: () => void
  onSubmit: () => void
  name: string // todo - added newly (but not currenlty used), because index do pass it ?? - testing
  info: string
  invalid?: boolean
  submitting?: boolean
  error?: string
}

const Form = ({
  type,
  handleSubmit,
  onSubmit,
  error,
  invalid,
  submitting,
  info,
}: InjectedFormProps<any> & FormProps) => (
  <div className={s.root}>
    <p>{info}</p>
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset className='narrow left'>
        {type === ManageCredentialsType.password ? (
          <div>
            <PasswordFormHelper name='oldPass' label='Current password' hideErrors />
            <NewPasswordFormHelper />
          </div>
        ) : (
          <div>
            <TransactionPinFormHelper name='oldPass' label='Current PIN' hideErrors />
            <NewTransactionPinFormHelper />
          </div>
        )}
      </fieldset>

      <fieldset className='narrow left'>
        {type === ManageCredentialsType.password && (
          <div className={s.passwordchangewarning}>
            Please note that for security purposes, you are automatically redirected to the login
            page after successfully changing the password.
          </div>
        )}
        <BottomError
          message={
            type === ManageCredentialsType.password
              ? error
              : error &&
                error
                  .replace(/password/i, 'Transaction PIN')
                  .replace(/password/gi, 'transaction PIN')
                  .replace('can not be', 'cannot be the')
          }
        />
        <Button disabled={invalid} submit text='Save' loading={submitting} />
      </fieldset>
    </form>
  </div>
)

const FormRedux = reduxForm<any, FormProps>({
  form: 'manageCredentials',
  enableReinitialize: true,
})(Form)

export default FormRedux
