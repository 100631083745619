import React from 'react'
import { reduxForm, InjectedFormProps } from 'redux-form'
import BottomError from '../Forms/BottomError'
import Modal from '../../containers/Modal'
import SubmitCancel from '../Forms/SubmitCancel'

type DeleteModalProps = {
  handleSubmit: () => void
  payeeDelete: () => void
  submitting?: boolean
  error?: string
  selectedPayee?: {
    title: string
  }
}

const DeleteModal = ({
  handleSubmit,
  payeeDelete,
  error,
  submitting,
  selectedPayee,
}: InjectedFormProps<any> & DeleteModalProps) => (
  <Modal id='payeeDelete' size='medium' title='Delete payee'>
    <form onSubmit={handleSubmit(payeeDelete)}>
      {selectedPayee && (
        <div>
          <p>Are you sure you want to delete {selectedPayee.title}?</p>
        </div>
      )}
      <BottomError message={error} />
      <SubmitCancel submitText='Delete' submitting={submitting} cancelClosesModal />
    </form>
  </Modal>
)

const DeleteReduxModal = reduxForm<any, DeleteModalProps>({
  form: 'payeeDelete',
})(DeleteModal)

export default DeleteReduxModal
