import React from 'react'
import s from './styles.css'
import { MaxHeightEnum } from '../../models/enum'

// const defaultSlideyProps = {
//   maxHeight: MaxHeight.medium,
// }

type SlideyProps = {
  children: React.ReactNode
  isOpen?: boolean
  maxHeight?: MaxHeightEnum
  id?: string // todo - is passed by SlideyorModal
  onClose?: () => void // todo - is passed by SlideyorModal
}

type SlideyState = {
  overflowClass: string
}

class Slidey extends React.Component<SlideyProps, SlideyState> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    maxHeight: MaxHeightEnum.medium,
  }

  constructor(props: SlideyProps | Readonly<SlideyProps>) {
    super(props)

    this.state = {
      overflowClass: s.overflow,
    }

    this.handleTransitionEnd = this.handleTransitionEnd.bind(this)
  }

  handleTransitionEnd() {
    this.setState({
      overflowClass: this.props.isOpen ? s.overflow : undefined,
    })
  }

  render() {
    const stateClass = this.props.isOpen ? s.open : s.closed

    return (
      <div
        className={`${s.root} ${stateClass} ${this.state.overflowClass} ${s[this.props.maxHeight]}`}
        onTransitionEnd={this.handleTransitionEnd}
      >
        {this.props.children}
      </div>
    )
  }
}

export default Slidey
