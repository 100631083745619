import { takeLatest, select, call, put, delay } from 'redux-saga/effects'
import { getUserContacts, getAlertsIds, getSubId, getPartyId } from './selectors'
import getUserId from '../../selectors/getUserId'
import { formError } from '../../sagas/formErrors/actions'
import request from '../../utils/request'
import * as c from '../../constants'
import * as a from './actions'

export function* handleAlertsInit(action: any) {
  try {
    const nonce = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    const userAlerts = yield call(request, {
      id: c.requestUserAlerts,
      url: 'v1/activityEventActions?alertType=S&moduleId=PI&locale=en',
      nonce,
    })
    const nonce1 = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    const userSubscribedAlerts = yield call(request, {
      id: c.requestSubscribedUserAlerts,
      url: 'v1/actionSubscriptions?subscriptionLevelType=PARTY&locale=en',
      nonce: nonce1,
    })
    if (userSubscribedAlerts.fields) {
      // todo-ts: was map, changed to forEach, due to lack of return
      Object.keys(userAlerts.fields).forEach((item) => {
        if (userSubscribedAlerts.fields[item]) {
          userAlerts.fields[item].email = userSubscribedAlerts.fields[item].email
          userAlerts.fields[item].sms = userSubscribedAlerts.fields[item].sms
        }
      })
      userAlerts.subEndDate = userSubscribedAlerts.subEndDate
      userAlerts.subStartDate = userSubscribedAlerts.subStartDate
      userAlerts.subscriptionId = userSubscribedAlerts.subscriptionId
    }
    yield put(
      a.alertsUpdate({
        user: userAlerts,
      })
    )
  } catch (error) {
    console.error(error)
  }
}

export function* handleAlertsSubmit(action) {
  try {
    const formData = action.payload
    const { type } = formData
    const subId = yield select(getSubId)
    const userId = yield select(getUserId)
    const contacts = yield select(getUserContacts)
    const alertIds = yield select(getAlertsIds, type)
    const partyId = yield select(getPartyId)
    const userAlerts = []
    const userLogin = []
    const userFailed = []
    let url = 'v1/actionSubscriptions?locale=en'
    let method = 'post'
    if (type === 'user') {
      if (formData['Login Alert Email'] || formData['Login Alert SMS']) {
        userAlerts.push('loginSuccess')
        if (formData['Login Alert Email']) {
          userLogin.push('email')
        }
      }
      if (formData['Login Alert SMS']) {
        userLogin.push('sms')
      }
      if (formData['Login Failed Alert Email'] || formData['Login Failed Alert SMS']) {
        userAlerts.push('loginFailure')
        if (formData['Login Failed Alert Email']) {
          userFailed.push('email')
        }
      }
      if (formData['Login Failed Alert SMS']) {
        userFailed.push('sms')
      }
    }
    if (subId) {
      method = 'put'
      url = `v1/actionSubscriptions/${subId}?locale=en`
      if (userAlerts.length === 0) {
        method = 'delete'
      }
    }
    const params = {
      userId,
      contacts,
      alertIds,
      type,
      partyId,
      method,
      userAlerts,
      userLogin,
      userFailed,
      subId,
    }
    const nonce = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    yield call(request, {
      id: c.requestAlertsUpload,
      url,
      nonce,
      payload: {
        ...params,
        stage: 0,
      },
    })
    yield call(handleAlertsInit, {}) // todo-ts: added param {}
    yield put(a.alertsSubmit.success())
    yield put(a.alertsSaved(type))
    yield delay(3000)
    yield put(a.alertsSaved(type, false))
  } catch (error) {
    console.error(error)
    yield put(formError(a.alertsSubmit.FAILURE, error))
  }
}

export function* watchAlerts() {
  yield takeLatest(c.ALERTS_INIT, handleAlertsInit)
  yield takeLatest(a.alertsSubmit.REQUEST, handleAlertsSubmit)
}
