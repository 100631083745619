import transactionCodes from '../lookup/transactionCodes'

export default (
  data: {
    data: {
      allowedTransactions: {
        id: string
      }[]
    }
  },
  state
) => {
  const tidyData = data.data.allowedTransactions
  let permittedFunctions = tidyData
  if (!(permittedFunctions instanceof Array)) {
    permittedFunctions = [permittedFunctions]
  }

  const tidyUser = {
    permittedFunctions: permittedFunctions.reduce((arr, item) => {
      let funcTitle = ''
      if (transactionCodes[item.id] instanceof Array) {
        transactionCodes[item.id].forEach((code) => {
          funcTitle = code
          if (funcTitle) arr.push(funcTitle)
        })
      } else {
        funcTitle = transactionCodes[item.id]
        if (funcTitle) arr.push(funcTitle)
      }
      return arr
    }, []),
  }

  return {
    user: {
      permittedFunctions: tidyUser.permittedFunctions,
    },
  }
}
