import { connect } from 'react-redux'
import PaymentsBulkItemList from '../../components/PaymentsBulkItemList'
import niceTableData from '../../utils/niceTableData'

const mapStateToProps = (state) => {
  const tableFormat = {
    mapRowIdTo: 'name',
    incrementId: true,
    cols: [
      {
        header: 'Payee Name',
        mapTextTo: 'name',
        perc: 20,
        wrap: true,
      },
      {
        header: 'Payee Acc. Details',
        mapTextTo: 'accountDetails',
        perc: 20,
        wrap: true,
      },
      {
        header: 'Amount',
        mapTextTo: 'amount',
        perc: 20,
      },
      {
        header: 'Date',
        mapTextTo: 'date',
        perc: 20,
      },
      {
        header: 'Status',
        mapTextTo: 'status',
        perc: 20,
      },
    ],
    card: {
      mapTitleTo: 'name',
      mapTextTo: 'amount',
      mapDetailsTo: 'date',
      mapExtraTo: 'status',
    },
  }

  const { isFetching } = state.paymentsBulk.item

  const details = isFetching ? [] : state.paymentsBulk.item.items.details

  const niceData = niceTableData(details, tableFormat)

  return {
    rows: niceData.body,
    headerCols: niceData.header,
    isFetching: state.paymentsBulk.item.isFetching,
    cardForSmall: true,
  }
}

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsBulkItemList)
