import React from 'react'
import Breadcrumbs from '../Breadcrumbs'
import ActionHeader from '../ActionHeader'
import InnerContainer from '../InnerContainer'
import ButtonBlock from '../ButtonBlock'
import Panel from '../Panel'
import AccountServicesForm from '../../containers/AccountServicesForm'
import ChequeStatus from '../../containers/ChequeStatus'
import ResultsInfo from '../ResultsInfo'

type AccountServicesViewProps = {
  onOrderChequeBookClick: () => void
  onOrderPayInBookClick: () => void
  chequeStatusIsVisible?: boolean
  chequeBooksIsVisible?: boolean
  payInBooksIsVisible?: boolean
  viewIsLoading?: boolean
}

const AccountServicesView = ({
  onOrderChequeBookClick,
  onOrderPayInBookClick,
  viewIsLoading,
  chequeBooksIsVisible,
  payInBooksIsVisible,
  chequeStatusIsVisible,
}: AccountServicesViewProps) => (
  <div>
    <Breadcrumbs
      items={[
        {
          to: '/accounts',
          text: 'My accounts',
        },
        {
          to: '/accounts/services',
          text: 'Cheque services',
        },
      ]}
    />
    <InnerContainer>
      <ActionHeader title='Cheque services' />
    </InnerContainer>
    {viewIsLoading ? (
      <ResultsInfo state={{ state: 'loading' }} />
    ) : (
      <div>
        {(chequeBooksIsVisible || payInBooksIsVisible) && (
          <InnerContainer>
            <Panel>
              <ButtonBlock
                items={[
                  {
                    text: 'Order a new cheque book',
                    onClick: onOrderChequeBookClick,
                    excluded: !chequeBooksIsVisible,
                  },
                  {
                    text: 'Order a new pay-in book',
                    onClick: onOrderPayInBookClick,
                    excluded: !payInBooksIsVisible,
                  },
                ]}
              />
            </Panel>
          </InnerContainer>
        )}
        {chequeStatusIsVisible && <ChequeStatus />}
        <AccountServicesForm />
      </div>
    )}
  </div>
)

export default AccountServicesView
