import React from 'react'
import s from './styles.css'
import ButtonBlock from '../ButtonBlock'
import Status from '../Status'
import { MESSAGES } from '../../messages/messages'

type FlowSuccessProps = {
  links?: any[]
  message: string
  naMessage?: string
  taMessage?: string
  timeOutError?: boolean
  referenceNumber?: string
  forceRows?: boolean
  needsAuthoriser?: boolean
  secondaryMessage?: any // todo - this property is getting used as HTMLElement and a string
}

const FlowSuccess: React.FC<FlowSuccessProps> = ({
  links,
  message,
  naMessage,
  forceRows,
  needsAuthoriser,
  timeOutError,
  referenceNumber,
  taMessage,
  secondaryMessage,
}: FlowSuccessProps) => (
  <div className={s.root}>
    <div className={s.status}>
      {!needsAuthoriser && !timeOutError && <Status text={message} />}
      {timeOutError && (
        /* todo - type=error, did change to match  status.type */
        <Status type='fail' text={taMessage} />
      )}
      {needsAuthoriser && <Status text={naMessage} />}
      {needsAuthoriser && (
        <p className={s.authMessage}>
          This will be completed once authorised by another account holder
        </p>
      )}
      {timeOutError && (
        <p className={s.timeOutMessage}>{MESSAGES.MSG_PAYMENT_ISSUE({ referenceNumber })}</p>
      )}
      {secondaryMessage && <p className={s.secondaryMessage}>{secondaryMessage}</p>}
    </div>
    {links && (
      <div className={s.buttonWrap}>
        <ButtonBlock items={links} forceRows={forceRows} />
      </div>
    )}
  </div>
)

export default FlowSuccess
