import React from 'react'
import { Dispatch } from 'redux'
import COPWarningSVG from '../../assets/icons/COPWarning'
import COPAccountNotFoundIcon from '../../assets/icons/COPAccountNotFoundIcon'
import s from './styles.css'
import Button from '../Button'
import ErrorList from './ErrorList'
import {
  PAYEE_FLOW_CONFIRMATION_OF_PAYEE_SUBMIT,
  PAYMENT_FLOW_CONFIRMATION_OF_PAYEE_SUBMIT,
} from '../../constants'
import {
  ConfirmationOfPayeeStatus,
  IErrorState,
} from '../../containers/ConfirmationOfPayee/reducer'
import { prepareErrorButtons } from '../../containers/ConfirmationOfPayee/helper'
import Modal from '../../containers/Modal'
import LoadingSpinner from '../LoadingSpinner'

interface ConfirmationOfPayeeProps {
  // Passed in as props to container
  formName: string
  exitCopAction:
    | typeof PAYEE_FLOW_CONFIRMATION_OF_PAYEE_SUBMIT
    | typeof PAYMENT_FLOW_CONFIRMATION_OF_PAYEE_SUBMIT
  cancelCopLocation: string

  // Set in container
  header?: string
  subHeaders?: string[]
  errorDetails?: Array<
    [
      { label: string; value: string; type: 'Error' },
      { label: string; value: string; type: 'Success' }
    ]
  >
  copStatus: ConfirmationOfPayeeStatus
  errorState: IErrorState
  copLoading?: boolean
  dispatch: Dispatch
  formData: Record<string, string>
}

const ConfirmationOfPayee: React.FC<ConfirmationOfPayeeProps> = ({
  header,
  subHeaders,
  errorDetails,
  copStatus,
  errorState,
  dispatch,

  formName,
  exitCopAction,
  cancelCopLocation,
  formData,
}) => {
  if (
    copStatus === ConfirmationOfPayeeStatus.SUCCESS ||
    copStatus === ConfirmationOfPayeeStatus.SUCCESS_BYPASS
  ) {
    dispatch({ type: exitCopAction })
    return <LoadingSpinner />
  }
  try {
    const { errorCode, errorDetails: errorCopDetails } = errorState

    const isUnableToCheckAccount = ![
      'MBAM',
      'BAMM',
      'BANM',
      'PANM',
      'PAMM',
      'AC01',
      'IVCR',
      'CASS',
      'ANNM',
    ].includes(errorCode)

    const isSevereWarning = ['MBAM', 'BANM', 'PANM', 'BAMM', 'PAMM', 'ANNM'].includes(errorCode)

    const buttons = prepareErrorButtons(
      formName,
      errorCode,
      errorCopDetails,
      exitCopAction,
      cancelCopLocation,
      dispatch
    )
    const { accountType, accountNumber, name, sortCode, reference } = formData

    const IconToUse = isUnableToCheckAccount ? COPAccountNotFoundIcon : COPWarningSVG
    return (
      <div className={s.container}>
        <div className={`${s.headingMessage} ${isUnableToCheckAccount && s.accountNotFoundHeader}`}>
          <p>
            <span>
              <IconToUse className={s.svgIcon} />
            </span>
            {header && <span>{header}</span>}
          </p>
        </div>

        {subHeaders.map((subHeader) => (
          <div
            className={`${s.subHeading} ${isUnableToCheckAccount && s.subHeadingNotFoundCase}`}
            key={subHeader}
          >
            <span>{subHeader}</span>
          </div>
        ))}

        {isUnableToCheckAccount && (
          <div className={s.accountDetails}>
            {[
              { key: 'Account type:', value: accountType },
              {
                key: accountType === 'Personal' ? 'Full name on account:' : 'Full business name:',
                value: name,
              },
              { key: 'Account number:', value: accountNumber },
              { key: 'Sort code:', value: sortCode },
              { key: 'Reference:', value: reference },
            ]
              .filter(({ value }) => value !== undefined)
              .map(({ key, value }) => (
                <div className={s.accountDetailsRow} key={key}>
                  <span className={s.title}>{key}</span>
                  <span className={s.value}>{value}</span>
                </div>
              ))}
          </div>
        )}
        {errorDetails && errorDetails.length > 0 && (
          <div className={s.listErrors}>
            {errorDetails?.map((items) =>
              items.map((errorDetail, index) => (
                <ErrorList
                  key={errorDetail.label}
                  index={index}
                  value={errorDetail.value}
                  label={errorDetail.label}
                  type={errorDetail.type}
                />
              ))
            )}
          </div>
        )}

        <div>
          <button type='button' className={s.actionButton} onClick={buttons.primaryButton.onClick}>
            {buttons.primaryButton.label}
          </button>
          <button
            type='button'
            className={`${s.actionButton} ${s.actionButton2}`}
            onClick={buttons.secondaryButton.onClick}
          >
            {buttons.secondaryButton.label}
          </button>
          {buttons.tertiaryButton && (
            <button
              type='button'
              className={`${s.actionButton} ${s.cancel}`}
              onClick={buttons.tertiaryButton.onClick}
            >
              {buttons.tertiaryButton.label}
            </button>
          )}
        </div>

        <Modal id='confirmContinueAnyway' size='medium' noScroll className={s.modalComponent}>
          <div className={s.modal}>
            <div className={s.modalIcon}>
              <COPWarningSVG className={s.modalSVG} />
            </div>
            <div className={s.modalContent}>
              <div className={s.modalHeader}>
                <h1>
                  {isSevereWarning
                    ? 'We recommend you do not continue'
                    : 'Are you sure you want to continue?'}
                </h1>
              </div>
              <div className={s.modalText}>
                If you continue, your money may be sent to the wrong account and the bank may not be
                able to recover it.
              </div>
              <div>
                <Button
                  onClick={buttons.modalSecondaryButton?.onClick}
                  className={s.cancelButton}
                  text={buttons.modalSecondaryButton?.label}
                />
                <Button
                  onClick={buttons.modalPrimaryButton?.onClick}
                  className={`${s.continueAnywayButton} ghost-dark`}
                  text={buttons.modalPrimaryButton?.label}
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    )
  } catch (e) {
    return null
  }
}

export default ConfirmationOfPayee
