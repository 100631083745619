import * as React from 'react'

function COPWarningIcon(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='none'
      className={props.className}
    >
      <circle cx='7.5' cy='7.5' r='7.5' fill='#F1DC1D'></circle>
      <path
        fill='#fff'
        d='m7.255 9.154-.357-3.633v-1.68h1.485v1.68L8.03 9.154h-.776ZM6.957 11V9.628h1.372V11H6.957Z'
      ></path>
    </svg>
  )
}
export default COPWarningIcon
