import React from 'react'
import s from './styles.css'

type SelectedAccountNameProps = {
  accountName: string
}

const SelectedAccountName = ({ accountName }: SelectedAccountNameProps) => (
  <div className={s.selectedAccountName}>{accountName}</div>
)

export default SelectedAccountName
