import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import get from 'lodash/get'
import TransferMoneyForm from '../../components/TransferMoneyForm'
import {
  getFromAccounts,
  getToAccounts,
  getHasTransferableAccounts,
  getHasReference,
} from './selectors'
import convertToOptions from '../../utils/convertToOptions'
import { transferConfirm, transferSubmit } from './actions'
import getAccount from '../../selectors/getAccount'
import getIsAccountMappingKnown from '../../selectors/getIsAccountMappingKnown'
import { modalShow } from '../../store/ui'

const mapDispatchToProps = (dispatch, ownProps) => ({
  transferConfirm,
  checkReference: (e, hasRef) => {
    e.preventDefault()
    if (hasRef) {
      dispatch(transferSubmit)
    } else {
      dispatch(modalShow('paymentNoReference'))
    }
  },
  onSubmitButtonClick: () => {
    dispatch(transferSubmit)
  },
})

const mapStateToProps = (state, ownProps) => {
  const formValues: any = getFormValues('transferMoney')(state)
  const fromVal = formValues && formValues.from && formValues.from.value
  const fromAccount = getAccount(state, fromVal)
  const toVal = formValues && formValues.to && formValues.to.value
  const toAccount = getAccount(state, toVal)
  const currency = fromAccount && fromAccount.currency
  const isLoadingOptions = !getIsAccountMappingKnown(state, 'transfer')
  const fromOptions = convertToOptions(getFromAccounts(state))
  const toOptions = convertToOptions(getToAccounts(state))
  const accId = ownProps.params.id
  const hasURLOption = fromOptions.filter((o) => o.value === accId).length > 0
  const initialFrom = isLoadingOptions ? undefined : accId
  const notMapped = accId && !fromVal && !isLoadingOptions && !hasURLOption
  const disableCancel = state.flow.data && state.flow.data.disableCancel
  return {
    initialValues: {
      when: 'now',
      from: {
        value: hasURLOption && initialFrom,
      },
      frequencyCode: {
        value: '50',
      },
      repeatUntil: {
        value: 'forever',
      },
      isRegular: get(state, 'routing.locationBeforeTransitions.query.isRegular'),
    },
    toDisabled: !fromVal, // Disable to if 'from' is empty
    fromAvailableBalance: fromAccount && fromAccount.availableBalance,
    toAvailableBalance: toAccount && toAccount.availableBalance,
    fromOptions,
    toOptions,
    currency,
    cancelLink: '/payments',
    disableCancel,
    isLoadingOptions,
    notMapped,
    hasTransferableAccounts: getHasTransferableAccounts(state),
    isRegular: get(state, 'routing.locationBeforeTransitions.query.isRegular'),
    hasReference: getHasReference(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TransferMoneyForm)
