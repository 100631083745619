import * as React from 'react'

function SvgCross(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 153 159'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <use xlinkHref='#cross_svg__path0_fill' transform='translate(.881)' />
      <use xlinkHref='#cross_svg__path1_fill' transform='matrix(-1 0 0 1 153 0)' />
      <defs>
        <path
          id='cross_svg__path0_fill'
          d='M146.906 147.132c3.102-3.103 4.619-6.799 4.619-11.222 0-4.422-1.583-8.119-4.685-11.155L34.008 4.621C30.908 1.518 27.212 0 22.791 0c-4.42 0-8.116 1.518-11.217 4.62L4.619 12.5C1.518 15.602 0 19.299 0 23.721c0 4.423 1.518 8.12 4.619 11.222L118.507 154.35c3.101 3.102 6.796 4.621 11.217 4.621s8.116-1.519 11.217-4.621l5.965-7.219z'
        />
        <path
          id='cross_svg__path1_fill'
          d='M146.397 147.158c3.091-3.103 4.603-6.8 4.603-11.223 0-4.424-1.578-8.121-4.669-11.158L33.891 4.621C30.8 1.518 27.118 0 22.712 0c-4.405 0-8.088 1.518-11.178 4.621l-6.931 7.881C1.513 15.605 0 19.302 0 23.726c0 4.423 1.512 8.12 4.603 11.223l113.493 119.43c3.09 3.103 6.773 4.621 11.178 4.621 4.406 0 8.088-1.518 11.178-4.621l5.945-7.221z'
        />
      </defs>
    </svg>
  )
}

export default SvgCross
