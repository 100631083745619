import * as React from 'react'

function COPWarningSVG(props) {
  return (
    <svg
      width='32px'
      height='28px'
      viewBox='0 0 36 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M18 23.0134H18.0177M18 17.7735V12.5335M5.55263 30H30.4473C33.1851 30 34.891 27.0754 33.5114 24.7466L21.0641 3.73312C19.6952 1.42229 16.3047 1.42229 14.9358 3.73312L2.48856 24.7466C1.10907 27.0754 2.81486 30 5.55263 30Z'
        stroke='white'
        stroke-width='3'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default COPWarningSVG
