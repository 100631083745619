import React from 'react'
import moment from 'moment'
import Breadcrumbs from '../Breadcrumbs'
import ActionHeader from '../ActionHeader'
import InnerContainer from '../InnerContainer'
import Button from '../Button'
import PaymentsBulkAccounts from '../../containers/PaymentsBulkAccounts'
import UploadModal from './UploadModal'
import PaymentsBulkFilesTable from '../../containers/PaymentsBulkFilesTable'
import NothingToShow from '../NothingToShow'
import Warnings from '../Warnings'
import s from './styles.css'

type ViewProps = {
  bulkUploadVerify: () => void
  minDate: moment.Moment // todofixed - was object.
  mappingList: { bulkUpload: string | boolean } // todofixed - was object.
  isViewShowing?: boolean
  show3pmMessage?: boolean
  mappingError?: string // todo - was string | boolean, was changed to match Warnings props.
}

const View = ({
  bulkUploadVerify,
  minDate,
  mappingList,
  isViewShowing,
  show3pmMessage,
  mappingError,
}: ViewProps) => (
  <div>
    <Breadcrumbs
      items={[
        {
          to: '/payments',
          text: 'Payments',
        },
        {
          to: '/payments/bulk',
          text: 'Bulk payments',
        },
      ]}
    />
    <InnerContainer>
      <ActionHeader title='Bulk payments' />
      {mappingError && <Warnings items={[mappingError]} />}
    </InnerContainer>
    {isViewShowing ? (
      <div>
        {mappingError === undefined && (
          <div className={s.top}>
            {mappingList.bulkUpload && (
              <div className={s.top}>
                <PaymentsBulkAccounts />
              </div>
            )}
          </div>
        )}
        {!mappingError && (
          <UploadModal
            onFileVerify={bulkUploadVerify}
            minDate={minDate}
            show3pmMessage={show3pmMessage}
          />
        )}
        <InnerContainer>
          <div className={s.row}>
            <div className={s.colWide}>
              <h2>Recent uploads</h2>
              <div className={s.tableFix}>
                <PaymentsBulkFilesTable limit={6} simpleResultsState />
              </div>
              <div className={s.allButton}>
                <Button text='View All' to='/payments/bulk/all' />
              </div>
            </div>
            {mappingList.bulkUpload && (
              <div className={`${s.colNarrow} copy`}>
                <h2>Template</h2>
                <p>
                  Pay multiple payees simultaneously, uploading the payment details on an Excel or
                  CSV file.
                </p>
                <p>
                  <a download href='bulk-upload-XLS.xls'>
                    Excel template
                  </a>
                </p>
                <p>
                  <a download href='bulk-upload-CSV.txt'>
                    CSV template
                  </a>
                </p>
                <h2>Bulk upload help</h2>
                <p>To avoid errors while uploading files, please ensure that:</p>
                <ul>
                  <li>Files are not password protected</li>
                  <li>There are no hidden rows</li>
                  <li>There are no spaces between rows (blank)</li>
                  <li>
                    Reference can be up to 35 letters and numbers. Please do not use special
                    characters.
                  </li>
                </ul>
              </div>
            )}
          </div>
        </InnerContainer>
      </div>
    ) : (
      <div>
        <NothingToShow title='You have no accounts with bulk upload permission.' />
      </div>
    )}
  </div>
)

export default View
