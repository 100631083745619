import { connect } from 'react-redux'
import * as a from '../../store/ui'

import FilterPanel from '../../components/FilterPanel'

const mapDispatchToProps = (dispatch, ownProps) => ({
  onToggleClick: () => {
    dispatch(a.panelToggle(ownProps.id))
  },
})

const mapStateToProps = (state, ownProps) => ({
  isOpen: ownProps.id === state.ui.panel || state.browser.greaterThan.small,
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterPanel)
