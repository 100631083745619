/* eslint-disable no-shadow */
export default (nextState, cb) => {
  cb(null, {
    getComponent(nextState, cb) {
      require.ensure(
        [],
        (require) => {
          const ChangeCredentials = require('../containers/ChangeCredentials').default
          cb(null, ChangeCredentials)
        },
        'changeCredentials'
      )
    },
  })
}
