import React, { ReactNode } from 'react'
import scrollToEl from '../../utils/scrollToEl'

type ScrollToProps = {
  shouldScroll?: boolean
  children?: ReactNode
}
class ScrollTo extends React.Component<ScrollToProps> {
  scrollToEl: any

  componentDidMount() {
    setTimeout(() => {
      if (this.props.shouldScroll) scrollToEl(this.scrollToEl)
    }, 0)
  }

  render() {
    return (
      <div
        ref={(el) => {
          this.scrollToEl = el
        }}
      >
        {this.props.children}
      </div>
    )
  }
}

export default ScrollTo
