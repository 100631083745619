import * as c from '../../constants'
// import processErrorsAndWarnings from '../processErrorsAndWarnings'
// import specialRequestIds from '../lookup/specialRequestIds'
// import ignoredErrorCodes from '../lookup/ignoredErrorCodes'
// import goOut from '../goOut'
// import APIError from '../APIError'
import tidyAccountsSummary from './tidyAccountsSummary'
import tidyNonce from './tidyNonce'
import tidyNonceAtLogin from './tidyNonceAtLogin'
import tidyPermissions from './tidyPermissions'
import tidyTransactions from './tidyTransactions'
import tidyLoanTransactions from './tidyLoanTransactions'
import tidyTermDepositTransactions from './tidyTermDepositTransactions'
import tidyLastTransaction from './tidyLastTransaction'
import tidyLogin1 from './tidyLogin1'
import tidyLogin2 from './tidyLogin2'
import tidyLoginFlow from './tidyLoginFlow'
import tidyFirstTimeFlags from './tidyFirstTimeFlags'
import tidyStatements from './tidyStatements'
import tidyStatementDownload from './tidyStatementDownload'
import tidyLoanDetails from './tidyLoanDetails'
import tidyLoanDetailsPrincipalbalance from './tidyLoanDetailsPrincipalbalance'
import tidyTermDepositDetails from './tidyTermDepositDetails'
import tidyAccountDetails from './tidyAccountDetails'
import tidyTransferMapping from './tidyTransferMapping'
import tidyMailDetail from './tidyMailDetail'
import tidyPaymentMapping from './tidyPaymentMapping'
import tidyAccountNameChange from './tidyAccountNameChange'
import tidyMail from './tidyMail'
import tidyBulletins from './tidyBulletins'
import tidyBulletinDetail from './tidyBulletinDetail'
import tidyPayeeGroups from './tidyPayeeGroups'
import tidyPayees from './tidyPayees'
import tidyPayeeNewInit from './tidyPayeeNewInit'
import tidyPayeeNewInit2 from './tidyPayeeNewInit2'
import tidyTwoFactorAuth from './tidyTwoFactorAuth'
import tidyVerifySortCode from './tidyVerifySortCode'
import tidyPayeeCreate from './tidyPayeeCreate'
import tidyPayeeDetails from './tidyPayeeDetails'
import tidyPaymentsFuture from './tidyPaymentsFuture'
import tidyPaymentsRecurringMapping from './tidyPaymentsRecurringMapping'
import tidyPaymentsBulkMapping from './tidyPaymentsBulkMapping'
import tidyBulkId from './tidyBulkId'
import tidyStandingOrders from './tidyPaymentsStandingOrders'
import tidyDirectDebit from './tidyPaymentsDirectDebit'
import tidyPaymentsBulkSearch from './tidyPaymentsBulkSearch'
import tidyStatementsMapping from './tidyStatementsMapping'
import tidyChequeBookMapping from './tidyChequeBookMapping'
import tidyPayInBookMapping from './tidyPayInBookMapping'
import tidyPaymentsBulkItem from './tidyPaymentsBulkItem'
import tidyPaymentsBulkErrorLog from './tidyPaymentsBulkErrorLog'
import tidyPaymentsBulkDownloadFile from './tidyPaymentsBulkDownloadFile'
import tidyChequeStatusMapping from './tidyChequeStatusMapping'
import tidyActivityLogTypes from './tidyActivityLogTypes'
import tidyActivityLogType from './tidyActivityLogType'
import tidyPendingApprovals from './tidyPendingApprovals'
import tidyActivityLogSingle from './tidyActivityLogSingle'
import tidyAccountCustomerAddress from './tidyAccountCustomerAddress'
import tidyChequeStatus from './tidyChequeStatus'
import tidyAlertsMapping from './tidyAlertsMapping'
import tidyAlerts from './tidyAlerts'
import tidySubscribedAlerts from './tidySubscribedAlerts'
import tidyPaymentsBulkUpload from './tidyPaymentsBulkUpload'
import tidyGroupTransactionLimitSpent from './tidyGroupTransactionLimitSpent'
import tidyUserTransactionLimitSpent from './tidyUserTransactionLimitSpent'
import tidyGroupTransactionLimit from './tidyGroupTransactionLimit'
import tidyUserTransactionLimit from './tidyUserTransactionLimit'
import tidyPublicKey from './tidyPublicKey'
import tidySalt from './tidySalt'

import { isTrue } from '../common'
import tidyApprovalCount from './tidyApprovalCount'
/**
 * Tidies data from API. Needs to know request to decide how to tidy.
 * @param {Object} data - raw data from API
 * @param {Object} request - object with details of request.
 * @param {string} request.id should contain one of the request constants (e.g. requestAccounts)
 * @return {Object} data - Tidied data
 */
export const tidyData = (data, request, state) => {
  let tidyDataResponse
  const p = request.payload
  // const { errorMessage, warnings, needsAuthoriser } = processErrorsAndWarnings(
  //   data, goOut, ignoredErrorCodes, specialRequestIds
  // )

  // if (errorMessage) throw new APIError(errorMessage)

  switch (request.id) {
    case c.requestNonceAtLogin:
      tidyDataResponse = tidyNonceAtLogin(data)
      break
    case c.requestNonce:
      tidyDataResponse = tidyNonce(data) // todo-ts : was (data, state)
      break
    case c.requestPermissions:
      tidyDataResponse = tidyPermissions(data, state)
      break
    case c.requestAccounts:
      tidyDataResponse = tidyAccountsSummary(data, state)
      break
    case c.requestTransactions:
      tidyDataResponse = tidyTransactions(data, state)
      break
    case c.requestLastTransaction:
      tidyDataResponse = tidyLastTransaction(data, state)
      break
    case c.requestUserTransactionLimit:
      tidyDataResponse = tidyUserTransactionLimit(data) // todo-ts : was (data, state)
      break
    case c.requestGroupTransactionLimit:
      tidyDataResponse = tidyGroupTransactionLimit(data) // todo-ts : was (data, state)
      break
    case c.requestUserTransactionLimitSpent:
      tidyDataResponse = tidyUserTransactionLimitSpent(data) // todo-ts : was (data, state)
      break
    case c.requestGroupTransactionLimitSpent:
      tidyDataResponse = tidyGroupTransactionLimitSpent(data) // todo-ts : was (data, state)
      break
    case c.requestLoanTransactions:
      tidyDataResponse = tidyLoanTransactions(data, state)
      break
    case c.requestTermDepositTransactions:
      tidyDataResponse = tidyTermDepositTransactions(data, state)
      break
    case c.requestLogin1:
      tidyDataResponse = tidyLogin1(data) // todo-ts : was (data, state)
      break
    case c.requestLogin2:
      tidyDataResponse = tidyLogin2(data) // todo-ts : was (data, state)
      break
    case c.requestLoginFlow:
      tidyDataResponse = tidyLoginFlow(data) // todo-ts : was (data, state)
      break
    case c.requestFirstTimeFlags:
      tidyDataResponse = tidyFirstTimeFlags(data) // todo-ts : was (data, state)
      break
    case c.requestStatements:
      tidyDataResponse = tidyStatements(data, state)
      break
    case c.requestStatementDownload:
      tidyDataResponse = tidyStatementDownload(data) // todo-ts : was (data, state)
      break
    case c.requestLoanDetails:
      tidyDataResponse = tidyLoanDetails(data) // todo-ts : was (data, state)
      break
    case c.requestLoanDetailsPrincipalbalance:
      tidyDataResponse = tidyLoanDetailsPrincipalbalance(data) // todo-ts : was (data, state)
      break
    case c.requestTermDepositDetails:
      tidyDataResponse = tidyTermDepositDetails(data) // todo-ts : was (data, state)
      break
    case c.requestAccountDetails:
      tidyDataResponse = tidyAccountDetails(data) // todo-ts : was (data, state)
      break
    case c.requestTransferMapping:
      tidyDataResponse = tidyTransferMapping(data, state)
      break
    case c.requestMail:
      tidyDataResponse = tidyMail(data) // todo-ts : was (data, state)
      break
    case c.requestBulletins:
      tidyDataResponse = tidyBulletins(data) // todo-ts : was (data, state)
      break
    case c.requestBulletinDetail:
      tidyDataResponse = tidyBulletinDetail(data, state)
      break
    case c.requestMailDetail:
      tidyDataResponse = tidyMailDetail(data, state, request)
      break
    case c.requestPaymentMapping:
      tidyDataResponse = tidyPaymentMapping(data, state)
      break
    case c.requestAccountNameChange:
      tidyDataResponse = tidyAccountNameChange(data) // todo-ts : was (data, state)
      break
    case c.requestPayeeGroup:
      tidyDataResponse = tidyPayeeGroups(data) // todo-ts : was (data, state)
      break
    case c.requestPayeeNewInit:
      tidyDataResponse = tidyPayeeNewInit(data) // todo-ts : was (data, state)
      break
    case c.requestPayeeNewInit2:
      tidyDataResponse = tidyPayeeNewInit2(data) // todo-ts : was (data, state)
      break
    case c.requestPayeeDetails:
      tidyDataResponse = tidyPayeeDetails(data, state)
      break
    case c.requestPayees:
      tidyDataResponse = tidyPayees(data) // todo-ts : was (data, state)
      break
    case c.requestBulkId:
      tidyDataResponse = tidyBulkId(data, state)
      break
    case c.requestPaymentsBulkUpload:
    case c.requestPayeeCreateOrModify:
    case c.requestPayeeNew:
      tidyDataResponse = tidyTwoFactorAuth(data) // todo-ts : was (data, state)
      break
    case c.requestTwoFactorAuth:
      // First two factor auth returns ref
      if (p.type === 'OTP') {
        tidyDataResponse = tidyTwoFactorAuth(data) // todo-ts : was (data, state)
        // Second two factor auth returns response from previous request
      } else {
        switch (p.requestType) {
          case 'payeeCreate':
            tidyDataResponse = tidyPayeeCreate(data) // todo-ts : was (data, state)
            break
          case 'bulkUpload':
            tidyDataResponse = tidyPaymentsBulkUpload(data) // todo-ts : was (data, state)
            break
          default:
        }
      }
      break
    case c.requestVerifySortCode:
      tidyDataResponse = tidyVerifySortCode(data) // todo-ts : was (data, state)
      break
    case c.requestFuturePayments:
      tidyDataResponse = tidyPaymentsFuture(data, state)
      break
    case c.requestPaymentsRecurringMapping:
      tidyDataResponse = tidyPaymentsRecurringMapping(data, state)
      break
    case c.requestStandingOrders:
      tidyDataResponse = tidyStandingOrders(data, state)
      break
    case c.requestDirectDebit:
      tidyDataResponse = tidyDirectDebit(data, state)
      break
    case c.requestActivityLogTypes:
      tidyDataResponse = tidyActivityLogTypes(data) // todo-ts : was (data, state)
      break
    case c.requestActivityLogType:
      tidyDataResponse = tidyActivityLogType(data, state)
      break
    case c.requestPendingApprovals:
      tidyDataResponse = tidyPendingApprovals(data) // todo-ts : was (data, state)
      break
    case c.requestApprovalCount:
      tidyDataResponse = tidyApprovalCount(data)
      break
    case c.requestActivityLogSingle:
      tidyDataResponse = tidyActivityLogSingle(data, state)
      break
    case c.requestPaymentsBulkMapping:
      tidyDataResponse = tidyPaymentsBulkMapping(data, state, request)
      break
    case c.requestPaymentsBulkSearch:
      tidyDataResponse = tidyPaymentsBulkSearch(data, state)
      break
    case c.requestPaymentsBulkItem:
      tidyDataResponse = tidyPaymentsBulkItem(data, state)
      break
    case c.requestStatementsMapping:
      tidyDataResponse = tidyStatementsMapping(data, state)
      break
    case c.requestPayInBookMapping:
      tidyDataResponse = tidyPayInBookMapping(data, state)
      break
    case c.requestChequeBookMapping:
      tidyDataResponse = tidyChequeBookMapping(data, state)
      break
    case c.reqChqStatMapp:
      tidyDataResponse = tidyChequeStatusMapping(data, state)
      break
    case c.requestChequeStatus:
      tidyDataResponse = tidyChequeStatus(data, state)
      break
    case c.requestAccountCustomerAddress:
      tidyDataResponse = tidyAccountCustomerAddress(data) // todo-ts : was (data, state)
      break
    case c.requestPaymentsBulkErrorLog:
      tidyDataResponse = tidyPaymentsBulkErrorLog(data) // todo-ts : was (data, state)
      break
    case c.requestPaymentsBulkDownloadFile:
      tidyDataResponse = tidyPaymentsBulkDownloadFile(data) // todo-ts : was (data, state)
      break
    case c.requestAlertsMapping:
      tidyDataResponse = tidyAlertsMapping(data, state)
      break
    case c.requestSubscribedUserAlerts:
      tidyDataResponse = tidySubscribedAlerts(data, state)
      break
    case c.requestUserAlerts:
      tidyDataResponse = tidyAlerts(data, state)
      break

    case c.requestPublicKey:
      tidyDataResponse = tidyPublicKey(data, state)
      break

    case c.requestSalt:
      tidyDataResponse = tidySalt(data, state)
      break
    default:
      // eslint-disable-next-line no-underscore-dangle
      if (isTrue(process.env.__DEV__))
        console.warn(`Response for request id '${request.id}' has not been tidied.`)
      tidyDataResponse = data
  }

  // if (warnings) tidyData._warnings = warnings
  // if (needsAuthoriser) tidyData._needsAuthoriser = needsAuthoriser

  return tidyDataResponse
}

class TidyResponse {
  store: any

  injectStore(store) {
    this.store = store
  }

  tidy(data, request) {
    return tidyData(data, request, this.store.getState())
  }
}

export default new TidyResponse()
