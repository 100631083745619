import { connect } from 'react-redux'
import ServicesView from '../../components/ServicesView'
import groupAccounts from '../../selectors/groupAccounts'
import getVisibleAccounts from '../../selectors/getVisibleAccounts'
import { getMenuItems } from '../MegaMenu/selectors'

const mapStateToProps = (state) => ({
  items: getMenuItems(groupAccounts(getVisibleAccounts(state))),
})

export default connect(mapStateToProps, null)(ServicesView)
