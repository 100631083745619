import * as React from 'react'

function COPAccountNotFoundIcon(props) {
  return (
    <svg
      className={props.className}
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='18' cy='18' r='16.5' stroke='#2D3236' stroke-width='3' />
      <path
        d='M18.5167 20.6392C18.1886 20.6392 17.8604 20.6379 17.5322 20.6398C17.4421 20.6398 17.3809 20.6185 17.3862 20.5154C17.4197 19.8688 17.4079 19.2165 17.5934 18.5867C17.7966 17.895 18.1912 17.3238 18.7285 16.8333C19.2527 16.355 19.7762 15.8728 20.2556 15.3532C20.6752 14.8981 20.8607 14.3308 20.8344 13.7088C20.8153 13.2466 20.6042 12.8901 20.2096 12.6393C19.3526 12.094 17.9038 12.1081 17.058 12.6722C16.5523 13.01 16.3412 13.5173 16.234 14.0807C16.2241 14.1336 16.2129 14.1858 16.205 14.2386C16.1726 14.4587 16.0419 14.5683 15.8131 14.5674C15.1403 14.5655 14.4675 14.561 13.7947 14.5661C13.6493 14.5674 13.5934 14.5345 13.6013 14.3779C13.6533 13.3213 13.8802 12.3228 14.549 11.4577C15.2067 10.6081 16.0972 10.1182 17.1185 9.83324C18.4996 9.44775 19.861 9.54122 21.1711 10.0995C22.5147 10.6719 23.2829 11.7046 23.4887 13.1247C23.6288 14.0923 23.492 15.0135 22.9343 15.8502C22.6581 16.2647 22.2819 16.5851 21.9129 16.9113C21.4822 17.2916 21.0474 17.6681 20.6048 18.0342C19.9906 18.5422 19.738 19.21 19.6821 19.97C19.6696 20.1409 19.6525 20.3123 19.6519 20.4832C19.6519 20.6031 19.6032 20.643 19.4848 20.6411C19.1619 20.6366 18.8397 20.6392 18.5167 20.6392Z'
        fill='#2D3236'
      />
      <path
        d='M18.5328 24.7998C17.7627 24.7986 17.1524 24.1933 17.1622 23.4326C17.1728 22.5991 17.8738 22.095 18.5571 22.1169C19.3273 22.1414 19.9126 22.6932 19.9106 23.439C19.908 24.2049 19.3049 24.8011 18.5335 24.7998H18.5328Z'
        fill='#2D3236'
      />
    </svg>
  )
}

export default COPAccountNotFoundIcon
