import React, { Component } from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import SingleField from '../Forms/SingleField'
import * as v from '../../utils/forms/validators'
import * as n from '../../utils/forms/normalizers'
import Button from '../Button'
import Warnings from '../Warnings'
import BottomError from '../Forms/BottomError'
import s from './styles.css'

type FormProps = {
  handleSubmit: () => void
  OTPSubmit: () => void
  error?: string
  invalid?: boolean
  submitting?: boolean
  showRecentMessage?: boolean
}

class Form extends Component<InjectedFormProps<any> & FormProps> {
  constructor(props) {
    super(props)
    this.changeFocus = this.changeFocus.bind(this)
  }

  // eslint-disable-next-line class-methods-use-this
  changeFocus(num: number) {
    if (num < 5) {
      document.getElementById(`pass${num + 1}`).focus()
    }
  }

  render() {
    const { handleSubmit, OTPSubmit, error, invalid, submitting, showRecentMessage } = this.props
    return (
      <form onSubmit={handleSubmit(OTPSubmit)} autoComplete='off'>
        <div>
          <label>One time password (OTP)</label>
          <div className={s.fieldsWrap}>
            <div className={s.fieldWrap}>
              <Field
                name='pass0'
                component={SingleField as React.FC<any>}
                validate={v.required}
                type='password'
                normalize={n.maxLength(1)}
                onChange={(e) => this.changeFocus(0)}
                maxLength={1}
                autoComplete='new-password'
              />
            </div>
            <div className={s.fieldWrap}>
              <Field
                name='pass1'
                component={SingleField as React.FC<any>}
                validate={v.required}
                type='password'
                normalize={n.maxLength(1)}
                onChange={(e) => this.changeFocus(1)}
                maxLength={1}
                autoComplete='new-password'
              />
            </div>
            <div className={s.fieldWrap}>
              <Field
                name='pass2'
                component={SingleField as React.FC<any>}
                validate={v.required}
                type='password'
                normalize={n.maxLength(1)}
                onChange={(e) => this.changeFocus(2)}
                maxLength={1}
                autoComplete='new-password'
              />
            </div>
            <div className={s.fieldWrap}>
              <Field
                name='pass3'
                component={SingleField as React.FC<any>}
                validate={v.required}
                type='password'
                normalize={n.maxLength(1)}
                onChange={(e) => this.changeFocus(3)}
                maxLength={1}
                autoComplete='new-password'
              />
            </div>
            <div className={s.fieldWrap}>
              <Field
                name='pass4'
                component={SingleField as React.FC<any>}
                validate={v.required}
                type='password'
                normalize={n.maxLength(1)}
                onChange={(e) => this.changeFocus(4)}
                maxLength={1}
                autoComplete='new-password'
              />
            </div>
            <div className={s.fieldWrap}>
              <Field
                name='pass5'
                component={SingleField as React.FC<any>}
                validate={v.required}
                type='password'
                normalize={n.maxLength(1)}
                onChange={(e) => this.changeFocus(5)}
                maxLength={1}
                autoComplete='new-password'
              />
            </div>
          </div>
        </div>
        <p>
          If you&apos;ve been asked to share this password, please don&apos;t proceed with this
          payment and contact Weatherbys Bank on +44 (0)1933 543543.
        </p>
        <p>
          <strong>
            For your security, you should never share your one time password or full security
            details with anyone.
          </strong>
        </p>
        <Warnings
          items={
            showRecentMessage && [
              `A new OTP has been sent since you
        edited this transaction. The old OTP is now invalid, please use the most recent one.`,
            ]
          }
        />
        <BottomError message={error} />
        <Button submit disabled={invalid} text='Next' loading={submitting} />
      </form>
    )
  }
}

const ReduxForm = reduxForm<any, FormProps>({
  form: 'twoFactorAuth',
  onSubmitFail: () => {
    const NUMS: number[] = [0, 1, 2, 3, 4, 5]
    NUMS.forEach((num) => {
      const element = document.getElementById(`pass${num}`) as HTMLInputElement
      element.value = ''
    })
  },
})(Form)

export default ReduxForm
