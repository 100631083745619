import React from 'react'
import PropTypes from 'prop-types'
import Breadcrumbs from '../Breadcrumbs'
import StickyTable from '../StickyTable'
import FilterPanel from '../../containers/FilterPanel'
import StatementsFiltersForm from '../StatementsFiltersForm'

import s from './styles.css'

type StatementsViewProps = {
  id: string
  accountOpts: any[]
  typeOpts: any[]
  yearOpts: any[]
  onAccountChange: () => void
  rows: any[] // todo - added as StickyTable need them
  headerCols: any[] // todo - added as StickyTable need them
}

export const StatementsView = (props: StatementsViewProps) => {
  const { accountOpts, typeOpts, yearOpts, id, onAccountChange } = props
  return (
    <div>
      <Breadcrumbs
        items={[
          {
            to: '/accounts',
            text: 'Accounts',
          },
          {
            to: '/statements',
            text: 'Statements',
          },
        ]}
      />

      <header className={s.inner}>
        <h1>Statements</h1>
      </header>

      <StickyTable {...props}>
        <FilterPanel id='statements' collapseForSmall>
          <StatementsFiltersForm
            accountOpts={accountOpts}
            typeOpts={typeOpts}
            yearOpts={yearOpts}
            account={id}
            onAccountChange={onAccountChange}
          />
        </FilterPanel>
      </StickyTable>
    </div>
  )
}

export default StatementsView
