import { createFormAction } from 'redux-form-saga'
import * as c from '../../constants'

export const alertsSubmit = createFormAction('ALERTS_SUBMIT')

export function alertsInit(accountNumber: string | number) {
  return {
    type: c.ALERTS_INIT,
    payload: {
      accountNumber,
    },
  }
}

export function alertsUpdate({ user }) {
  return {
    type: c.ALERTS_UPDATE,
    payload: {
      user,
    },
  }
}

export function alertsSaved(type: string, show = true) {
  return {
    type: c.ALERTS_SAVED,
    payload: {
      type,
      show,
    },
  }
}
