import RSA from './rsa-encrypt'

const rsa = new RSA()

export default (
  pubKeyMod: string | number,
  pubKeyexponent: string | number,
  saltId: string,
  textToEncrypt: string
) => {
  // eslint-disable-next-line no-useless-catch
  try {
    if (!pubKeyMod) {
      throw new Error('pubKeyMod value undefined')
    }
    if (!pubKeyexponent) {
      throw new Error('pubKeyexponent value undefined')
    }
    if (!saltId) {
      throw new Error('saltId value undefined')
    }
    if (!textToEncrypt) {
      throw new Error('textToEncrypt value undefined')
    }

    rsa.setPublic(pubKeyMod, pubKeyexponent)

    const text = `${textToEncrypt} ${decodeURIComponent(saltId)}`
    return rsa.encryptb64(text)
  } catch (e) {
    throw e
  }
}
