import { dontDuplicate } from '../utils/routerBugWorkaround'

export default (store) => ({
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const PaymentsBulk = require('../containers/PaymentsBulk').default
        const { paymentsBulkInit } = require('../containers/PaymentsBulk/actions')

        dontDuplicate(() => {
          store.dispatch(paymentsBulkInit())
        })

        cb(null, PaymentsBulk)
      },
      'payments-bulk'
    )
  },
})
