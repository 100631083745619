/* eslint-disable */
// @ts-nocheck
// todo - eslint and typescript
import { call, put, takeLatest, select, delay } from 'redux-saga/effects'
import { formError } from '../../sagas/formErrors/actions'
import { setMisc } from '../../store/ui'
import request from '../../utils/request'
import * as c from '../../constants'
import * as a from './actions'
import { getCustomerId } from './selectors'

export function* sendMessage(subject) {
  const customerId = yield select(getCustomerId)
  const nonce = yield call(request, {
    id: c.requestNonce,
    url: 'v1/session/nonce',
  })
  yield call(request, {
    id: c.requestMailSend,
    url: 'v1/mailbox/mails',
    nonce,
    partyId: customerId,
    payload: {
      messageBody: 'Marketing response, see body.',
      subjectId: 8,
      subject: `MARKETING_RESPONSE?${subject}`,
      party: customerId,
      messageId: null,
      messageType: 'M',
    },
  })
}

export function* handleSubmit(action) {
  try {
    const { payload: p } = action
    let subject = `consent=${!!p.consent}`
    // This is messy but I couldn't think of a better way to do it
    // Outbox does not return &'s either so went with all ?
    if (p.consent) {
      subject = `${subject}?post=${!!p.post}?telephone=${!!p.telephone}?email=${!!p.email}?text=${!!p.text}`
    }

    yield put(setMisc('marketingModal', 'submitted'))
    yield* sendMessage(subject)
    yield put(a.marketingModalSubmit.success())
    yield put(setMisc('marketingSettingsSaved', true))
    yield delay(3000)
    yield put(setMisc('marketingSettingsSaved', false))
  } catch (error) {
    console.error(error)
    yield put(formError(a.marketingModalSubmit.FAILURE, error))
  }
}

export function* handleDoNotWishToSee(action) {
  try {
    yield put(setMisc('marketingModal', 'doNotWishToSee'))
    yield* sendMessage('consent=doNotWishToSeeViaWeb')
    yield put(a.marketingModalSubmit.success())
  } catch (error) {
    console.error(error)
  }
}

export function* handleClose(action) {
  try {
    yield put(setMisc('marketingModal', 'closed'))
  } catch (error) {
    console.error(error)
  }
}

export function* watchMarketingForm() {
  yield takeLatest(a.marketingModalSubmit.REQUEST, handleSubmit)
  yield takeLatest(c.MARKETING_MODAL_DO_NOT_WISH_TO_SEE, handleDoNotWishToSee)
  yield takeLatest(c.MARKETING_MODAL_CLOSED, handleClose)
}
