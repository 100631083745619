import React from 'react'
import { Link } from 'react-router'
import s from './styles.css'
import Icon from '../../Icon'
import ModalLink from '../../../containers/ModalLink'
import Notifications from '../../Notifications'
import Tooltip from '../../../containers/Tooltip'

type NotificationIconProps = {
  icon: string
  showDot?: boolean
}

const NotificationIcon = ({ icon, showDot }: NotificationIconProps) => {
  const dotClass = showDot ? s.showDot : undefined
  return (
    <span className={`${s.dotContainer} ${dotClass}`}>
      <Icon icon={icon} />
    </span>
  )
}

type SecondaryNavProps = {
  onBulletinIconClick: () => void
  hasUnreadMail?: boolean
  hasUnreadBulletins?: boolean
  bulletins?: any[]
  bulletinsAreLoading?: boolean
  bulletinsAreVisible?: boolean
}

const SecondaryNav = ({
  hasUnreadMail,
  hasUnreadBulletins,
  bulletins,
  onBulletinIconClick,
  bulletinsAreLoading,
  bulletinsAreVisible,
}: SecondaryNavProps) => (
  <nav className={s.root}>
    <ul className={s.menu}>
      <li className={`${s.secondaryItem} ${s.noBorder}`}>
        <Link to='/settings'>Details & settings</Link>
      </li>
      {/* <li className={`${s.secondaryItem} ${s.noBorder} ${s.ourServices}`}>
        <Link to='/our-services'>Our services</Link>
      </li> */}
      <Tooltip
        id='notificationIcon'
        text='Notifications'
        showOnHover
        showOnMobile={false}
        rootClassName={s.foo}
      >
        <li className={s.end}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className={`${s.icon} ${s.hasTriangle}`} href='#' onClick={onBulletinIconClick}>
            <NotificationIcon icon='flag' showDot={hasUnreadBulletins} />
          </a>
        </li>
      </Tooltip>
      <Tooltip id='messagesIcon' text='Secure Messaging' showOnHover showOnMobile={false}>
        <li className={s.end}>
          <Link className={s.icon} to='/secure-mail'>
            <NotificationIcon icon='mail' showDot={hasUnreadMail} />
          </Link>
        </li>
      </Tooltip>
      <li className={`${s.secondaryItem} ${s.end}`}>
        <ModalLink className={s.logout} id='logOut'>
          Log Out
        </ModalLink>
      </li>
    </ul>
    {bulletinsAreVisible && <Notifications items={bulletins} isLoading={bulletinsAreLoading} />}
  </nav>
)

export default SecondaryNav
