import React from 'react'
import s from './styles.css'
import TppDetails from './TppDetails'

type CompaniesCompProps = {
  onCompanyClick: () => void
  tpps: any // todo - was object
}

const CompaniesComp = ({ onCompanyClick, tpps }: CompaniesCompProps) => (
  <div className={s.psdImageDiv}>
    {tpps &&
      tpps.userAccessPointRelationship &&
      tpps.userAccessPointRelationship.map((item, index) => (
        <TppDetails
          accessPointId={item.accessPointId}
          onCompanyClick={onCompanyClick}
          key={item.accessPointId}
        />
      ))}
  </div>
)

export default CompaniesComp
