import { call, put, takeEvery } from 'redux-saga/effects'
import * as c from '../../constants'
import request from '../../utils/request'
import { authenticate } from './actions'
import history from '../../history'
import { formError } from '../../sagas/formErrors/actions'
import { entitiesUpdateItems } from '../../store/entities'
import { userDetailsUpdate } from '../../store/user'
import { changeCredentialsRegisterSteps } from '../ChangeCredentials/actions'
import { sessionIdUpdate } from '../../store/session'
import { registerTabOrScreenOnStorage } from '../../utils/goOutMultiLogin/registerTabOrScreen'
import RSAPwdEncrypt from '../../utils/RSAPwdEncrypt/encrypt'
import tidyNonce from '../../utils/tidyResponse/tidyNonce'

function delay(ms) {
  return new Promise((resolve) => setTimeout(() => resolve(true), ms))
}

export function* handleAuthentication(action) {
  try {
    const formData = action.payload
    try {
      const nonce0 = yield call(request, {
        id: c.requestNonceAtLogin,
        url: 'v1/session/nonce',
      })

      if (nonce0) {
        registerTabOrScreenOnStorage()

        yield call(delay, c.WAIT_BETWEEN_MULTI_LOGIN)
      }
    } catch (error) {
      yield put(formError(authenticate.FAILURE, error))
    }

    let response1
    let noncePublicKey = null
    let nonceSalt = null
    try {
      noncePublicKey = yield call(request, {
        id: c.requestNonceAtLogin,
        url: 'v1/session/nonce',
      })
      const { modulus, publicExponent, publicKey } = yield call(request, {
        id: c.requestPublicKey,
        url: 'v1/publicKey?locale=en',
        nonce: noncePublicKey,
      })

      if (publicKey && modulus && publicExponent) {
        if (noncePublicKey) {
          nonceSalt = yield call(request, {
            id: c.requestNonceAtLogin,
            url: 'v1/session/nonce',
          })
        }
        const { salt } = yield call(request, {
          id: c.requestSalt,
          url: 'v1/salt?locale=en',
          nonce: nonceSalt,
        })

        const encryptedPwd = RSAPwdEncrypt(modulus, publicExponent, salt, formData.pass)

        response1 = yield call(request, {
          id: c.requestLogin1,
          url: '/j_security_check',
          payload: {
            user: formData.user,
            pass: encryptedPwd,
          },
        })
      }
    } catch (error) {
      yield put(formError(authenticate.FAILURE, error))
    }
    const nonce = tidyNonce(response1)

    const response2 = yield call(request, {
      id: c.requestLogin2,
      url: 'v1/me',
      nonce,
    })
    yield put(sessionIdUpdate(nonce))
    yield put(entitiesUpdateItems(response2.entities))
    yield put(userDetailsUpdate(response2.user))
    if (response2.isFirstTime) {
      const nonce2 = yield call(request, {
        id: c.requestNonce,
        url: 'v1/session/nonce',
      })
      const loginFlow = yield call(request, {
        id: c.requestLoginFlow,
        url: 'v1/me/loginFlow',
        nonce: nonce2,
      })
      yield put(changeCredentialsRegisterSteps(loginFlow))
      yield call(history.replace, '/change-credentials')
    } else {
      const nonce1 = yield call(request, {
        id: c.requestNonce,
        url: 'v1/session/nonce',
      })
      const permissions = yield call(request, {
        id: c.requestPermissions,
        url: `/cz/v1/permissions?locale=en`,
        nonce: nonce1,
      })

      yield put(userDetailsUpdate(permissions.user))
      yield call(history.replace, '/accounts')
    }
  } catch (error) {
    yield put(formError(authenticate.FAILURE, error))

    // // 9000390 : You have entered invalid security details. Please try again
    // if (error.code === 9000390) {
    //   yield put(change('login', 'pass', ''))
    // }
  }
}

export function* watchAuthentication() {
  yield takeEvery(authenticate.REQUEST, handleAuthentication)
}
