export const countries = {
  Ireland: 'IB',
  France: 'IB',
  'United States of America': 'AB',
  Australia: 'AB',
  Spain: 'IB',
  _SEPERATOR: true,
  Afghanistan: 'SW',
  Albania: 'IB',
  Algeria: 'SW',
  'American Samoa': 'SW',
  Andorra: 'IB',
  Angola: 'SW',
  Anguilla: 'SW',
  'Antigua and Barbuda': 'SW',
  Argentina: 'SW',
  Armenia: 'SW',
  Aruba: 'SW',
  Austria: 'IB',
  'Republic of Azerbaijan': 'IB',
  Bahamas: 'SW',
  Bahrain: 'SW',
  Bangladesh: 'SW',
  Barbados: 'SW',
  Belarus: 'SW',
  Belgium: 'IB',
  Belize: 'SW',
  Benin: 'SW',
  Bermuda: 'SW',
  Bhutan: 'SW',
  Bolivia: 'SW',
  'Bosnia and Herzegovina': 'IB',
  Botswana: 'SW',
  'Bouvet Islands': 'SW',
  Brazil: 'IB',
  'British Virgin Islands': 'SW',
  Brunei: 'SW',
  Bulgaria: 'IB',
  'Burkina Faso': 'SW',
  Burundi: 'SW',
  Cambodia: 'SW',
  Cameroon: 'SW',
  Canada: 'AB',
  'Cape Verde': 'SW',
  'Cayman Islands': 'SW',
  Chad: 'SW',
  Chile: 'SW',
  China: 'SW',
  'Cocos Islands': 'SW',
  Colombia: 'SW',
  'Comoro Islands': 'SW',
  Congo: 'SW',
  'Cook Islands': 'SW',
  'Costa Rica': 'SW',
  Croatia: 'IB',
  Cuba: 'SW',
  Cyprus: 'IB',
  Curacao: 'SW',
  'Czech Republic': 'IB',
  Denmark: 'IB',
  Djibouti: 'SW',
  Dominica: 'SW',
  'Dominican Republic': 'SW',
  Ecuador: 'SW',
  Egypt: 'SW',
  'El Salvador': 'SW',
  'Equatorial Guinea': 'SW',
  Eritrea: 'SW',
  Estonia: 'IB',
  Ethiopia: 'SW',
  'Falkland Islands': 'SW',
  'Faroe Islands': 'IB',
  Fiji: 'SW',
  Finland: 'IB',
  'French Guyana': 'SW',
  'French Polynesia': 'SW',
  'French Southern Territories': 'SW',
  Gabon: 'SW',
  Gambia: 'SW',
  Georgia: 'IB',
  Germany: 'IB',
  Ghana: 'SW',
  Gibraltar: 'IB',
  Greece: 'IB',
  Greenland: 'IB',
  Grenada: 'SW',
  Guadeloupe: 'SW',
  Guam: 'SW',
  Guatemala: 'SW',
  Guernsey: 'SW',
  Guinea: 'SW',
  'Guinea Bissau': 'SW',
  Guyana: 'SW',
  Haiti: 'SW',
  Honduras: 'SW',
  'Hong Kong': 'SW',
  Hungary: 'IB',
  Iceland: 'IB',
  India: 'SW',
  Indonesia: 'SW',
  Iran: 'SW',
  Iraq: 'SW',
  'Isle of Man': 'SW',
  Israel: 'IB',
  Italy: 'IB',
  'Ivory Coast': 'SW',
  Jamaica: 'SW',
  Japan: 'SW',
  Jersey: 'SW',
  Jordan: 'IB',
  Kazakhstan: 'SW',
  Kenya: 'SW',
  Kiribati: 'SW',
  Kuwait: 'IB',
  Kyrgyzstan: 'SW',
  Laos: 'SW',
  Latvia: 'IB',
  Lebanon: 'IB',
  Lesotho: 'SW',
  Liberia: 'SW',
  Libya: 'SW',
  Liechtenstein: 'IB',
  Lithuania: 'IB',
  Luxembourg: 'IB',
  Macau: 'SW',
  Macedonia: 'IB',
  Madagascar: 'SW',
  Malawi: 'SW',
  Malaysia: 'SW',
  Maldives: 'SW',
  Mali: 'SW',
  Malta: 'IB',
  'Marshall Islands': 'SW',
  Martinique: 'SW',
  Mauritania: 'SW',
  Mauritius: 'IB',
  Mayotte: 'IB',
  Mexico: 'SW',
  Micronesia: 'SW',
  Moldova: 'IB',
  Monaco: 'IB',
  Mongolia: 'SW',
  Montserrat: 'SW',
  Morocco: 'SW',
  Mozambique: 'SW',
  Myanmar: 'SW',
  Namibia: 'SW',
  Nauru: 'SW',
  Nepal: 'SW',
  Netherlands: 'IB',
  'Netherlands Antilles': 'SW',
  'New Caledonia': 'SW',
  'New Zealand': 'SW',
  Nicaragua: 'SW',
  Niger: 'SW',
  Nigeria: 'SW',
  Niue: 'SW',
  'North Korea': 'SW',
  'North Sudan': 'SW',
  'Northern Mariana Islands': 'SW',
  Norway: 'IB',
  Oman: 'SW',
  Pakistan: 'IB',
  Palau: 'SW',
  Palestine: 'IB',
  Panama: 'SW',
  'Papua New Guinea': 'SW',
  Paraguay: 'SW',
  Peru: 'SW',
  Philippines: 'SW',
  Poland: 'IB',
  Portugal: 'IB',
  'Puerto Rico': 'SW',
  Qatar: 'IB',
  Reunion: 'SW',
  'Republic of Kosovo': 'SW',
  'Republic of Montenegro': 'SW',
  'Republic of Serbia': 'SW',
  Romania: 'IB',
  Russia: 'SW',
  Rwanda: 'SW',
  'Saint Helena': 'SW',
  'Saint Pierre and Miquelon': 'SW',
  Samoa: 'SW',
  'San Marino': 'IB',
  'Saudi Arabia': 'IB',
  Senegal: 'SW',
  Seychelles: 'SW',
  'Sierra Leone': 'SW',
  Singapore: 'SW',
  'Sint Maarten': 'SW',
  Slovakia: 'IB',
  Slovenia: 'IB',
  'Solomon Islands': 'SW',
  Somalia: 'SW',
  'South Africa': 'AB',
  'South Korea': 'SW',
  'South Sudan': 'SW',
  'Sri Lanka': 'SW',
  'St Kitts and Nevis': 'SW',
  'St Vincent': 'SW',
  'St. Lucia': 'SW',
  Suriname: 'SW',
  Swaziland: 'SW',
  Sweden: 'IB',
  Switzerland: 'IB',
  Syria: 'SW',
  Taiwan: 'SW',
  Tajikistan: 'SW',
  Tanzania: 'SW',
  Thailand: 'SW',
  "Timor L'Este": 'SW',
  Togo: 'SW',
  Tonga: 'SW',
  'Trinidad and Tobago': 'SW',
  Tunisia: 'IB',
  Turkey: 'IB',
  Turkmenistan: 'SW',
  'Turks and Caicos': 'SW',
  Tuvalu: 'SW',
  Uganda: 'SW',
  Ukraine: 'SW',
  'United Arab Emirates': 'SW',
  'United Kingdom': 'IB',
  'United States of America Minor Outlying Islands': 'SW',
  Uruguay: 'SW',
  Uzbekistan: 'SW',
  Vanuatu: 'SW',
  Venezuela: 'SW',
  Vietnam: 'SW',
  'Virgin Islands (US)': 'SW',
  'Western Sahara': 'SW',
  Yemen: 'SW',
  Zambia: 'SW',
  Zimbabwe: 'SW',
}

const currencies = [
  'GBP',
  'EUR',
  'USD',
  'AUD',
  'AED',
  'CAD',
  'CHF',
  'HKD',
  'INR',
  'JPY',
  'MAD',
  'NOK',
  'NZD',
  'PLN',
  'SEK',
  'SGD',
  'ZAR',
  'Other',
]

export const countryOptions = Object.keys(countries).map((key) => {
  if (key === '_SEPERATOR') {
    return {
      disabled: true,
      value: false,
      label: '------------------',
    }
  }
  return {
    value: key,
    label: key,
  }
})

export const currencyOptions = currencies.map((key) => ({
  value: key,
  label: key,
}))
