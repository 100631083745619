export default (original: string) => {
  if (original.includes('-')) return original

  let sortCode = ''
  for (let i = 0; i < original.length; i += 1) {
    sortCode += original[i]
    if ((i + 1) % 2 === 0 && i !== original.length - 1) sortCode += '-'
  }
  return sortCode
}
