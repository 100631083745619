import React from 'react'
import includes from 'lodash/includes'
import InnerFullWidthContainer from '../InnerFullWidthContainer'
import ServicesHeader from '../ServicesHeader'
import Primary from './primary'
import FirstLinks from './firstLinks'
import SecondLinks from './secondLinks'
import ThirdLinks from './thirdLinks'
import s from './styles.css'

let racingAccount = false
let currentAccount = false

type ServicesViewProps = {
  items?: any[]
}

export const ServicesView = ({ items }: ServicesViewProps) => (
  <div className={s.servicesView}>
    {items.map((item) => {
      if (includes(item, 'racing')) {
        racingAccount = true
      }
      if (includes(item, 'current')) {
        currentAccount = true
      }
      return false
    })}
    {currentAccount && (
      <InnerFullWidthContainer>
        <ServicesHeader
          title={racingAccount ? 'Our Services' : 'Services'}
          secondaryTitle='Bespoke financial services and solutions'
        />
        <InnerFullWidthContainer>
          <Primary />
        </InnerFullWidthContainer>
        <InnerFullWidthContainer>
          <FirstLinks />
        </InnerFullWidthContainer>
        <InnerFullWidthContainer>
          <SecondLinks racingAccount={racingAccount} />
        </InnerFullWidthContainer>
      </InnerFullWidthContainer>
    )}
    {racingAccount && (
      <InnerFullWidthContainer>
        <ServicesHeader moveDown='true' title='Racing Services' />
        <InnerFullWidthContainer>
          <ThirdLinks />
        </InnerFullWidthContainer>
      </InnerFullWidthContainer>
    )}
  </div>
)

export default ServicesView
