import { connect } from 'react-redux'
import moment from 'moment'
import { mobMenuToggle, mobMenuHide, tooltipHide, mobSubMenuToggle } from '../../store/ui'

import CoreLayout from '../../components/CoreLayout'
import userHasEntities from '../../selectors/userHasEntities'

export const mapStateToProps = (state) => ({
  hasEntities: userHasEntities(state),
  mobMenuShowing: state.ui.mobMenuShowing,
  lastLogin:
    state.user.lastLogin &&
    moment(state.user.lastLogin, 'YYYY-MM-DD HH:mm:ss').format('D MMMM YYYY [at] h:mm A'),
  displayName: !state.user.preferredName
    ? `${state.user.honorific} ${state.user.lastName}`
    : state.user.preferredName,
  prefsIsOpen: state.ui.mobSubMenuShowing,
})

const mapDispatchToProps = (dispatch) => ({
  onBurgerClick: (e) => {
    e.stopPropagation()
    dispatch(mobMenuToggle())
  },
  onOverlayClick: () => {
    dispatch(mobMenuHide())
  },
  onContentClick: () => {
    dispatch(tooltipHide())
  },
  onSubMenuClick: (e) => {
    e.preventDefault()
    dispatch(mobSubMenuToggle())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(CoreLayout)
