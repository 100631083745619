import pickBy from 'lodash/pickBy'
import get from 'lodash/get'
import { getFormValues } from 'redux-form'
import getVisibleAccounts from '../../selectors/getVisibleAccounts'
import getAccount from '../../selectors/getAccount'
import accountCanTransferOut from '../../selectors/accountCanTransferOut'

export const getFromAccounts = (state) =>
  pickBy(getVisibleAccounts(state), (item: any) => accountCanTransferOut(state, item.id))

export const getToAccounts = (state) => {
  const fromId = get(state, 'form.transferMoney.values.from.value')
  if (fromId) {
    const fromCurr = getAccount(state, fromId).currency
    return pickBy(
      getVisibleAccounts(state),
      (item: any) => item.id !== fromId && item.currency === fromCurr && item.transferIsMapped
    )
  }
  return {}
}

export const getHasTransferableAccounts = (state) => {
  const currencyGroups = {}

  // Filter out based on entity and transferIsMapped
  const accounts = pickBy(getVisibleAccounts(state), (item: any) => item.transferIsMapped)

  // Add each item into own group based on currency
  Object.keys(accounts).forEach((key) => {
    const item = accounts[key]
    let currGroup = currencyGroups[item.currency]
    if (!currGroup) {
      currencyGroups[item.currency] = []
      currGroup = currencyGroups[item.currency]
    }
    currGroup.push(item)
  })

  // Go through each group and check criteria
  // Only one group needs to pass for whole test to pass
  let pass = false
  Object.keys(currencyGroups).forEach((key) => {
    // Length must be 2 or more
    const passLength = currencyGroups[key].length > 1

    // Needs at least one item where transferOutDisabled is not true
    let passTransferOut = false
    currencyGroups[key].forEach((item) => {
      if (!item.transferOutDisabled) passTransferOut = true
    })

    if (passLength && passTransferOut) pass = true
  })

  return pass
}

export const getHasReference = (state) => {
  const formVals: any = getFormValues('transferMoney')(state) || {}
  return formVals && formVals.reference && formVals.reference.length > 0
}
