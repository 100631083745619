import * as c from '../constants'

// ------------------------------------
// Action creators
// ------------------------------------
export function sessionIdUpdate(id) {
  return {
    type: c.SESSION_ID_UPDATE,
    payload: {
      id,
    },
  }
}

export function sessionLocalStorageIdUpdate(id) {
  return {
    type: c.SESSION_LOCAL_STORAGE_ID_UPDATE,
    payload: {
      sessionLocalStorageId: id,
    },
  }
}
export interface ISession {
  id: string | boolean
  sessionLocalStorageId?: string
}
// ------------------------------------
// Reducer
// ------------------------------------
const initialState: ISession = {
  id: false,
}

export default function entityReducer(state = initialState, action): ISession {
  switch (action.type) {
    case c.SESSION_ID_UPDATE:
      return {
        ...state,
        id: action.payload.id,
      }
    case c.SESSION_LOCAL_STORAGE_ID_UPDATE:
      return {
        ...state,
        sessionLocalStorageId: action.payload.sessionLocalStorageId,
      }
    default:
      return state
  }
}
