import React from 'react'
import s from './styles.css'

type ItemProps = {
  isActive?: boolean
  onClick: () => void
  title: string
}

export const Item = ({ isActive, onClick, title }: ItemProps) => {
  const activeClass = isActive ? s.active : undefined
  return (
    <a href='#' onClick={onClick} className={activeClass}>
      {title}
    </a>
  )
}

export default Item
