import React, { ReactNode } from 'react'
import s from './styles.css'

type InnerFullWidthContainerProps = {
  children: ReactNode
  addPadding?: boolean
}

export const InnerFullWidthContainer = ({ children, addPadding }: InnerFullWidthContainerProps) => (
  <div className={`${s.root} ${addPadding ? s.addPadding : ''}`}>{children}</div>
)

export default InnerFullWidthContainer
