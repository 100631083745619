import toArray from 'lodash/toArray'

export function getParams(state) {
  const { locationBeforeTransitions: location } = state.routing
  return {
    statusCode: location.query.status,
    typeCode: location.pathname.split('/').slice(-1)[0],
  }
}

export const getLogTypeItems = (state) => toArray(state.activityLog.types.items)
export const getTypeCode = (state) => state.activityLog.type.code
