/* eslint-disable react/style-prop-object */
import React from 'react'
import s from './styles.css'
import { Button } from '../../Button'
import Status from '../../Status'
import goOut from '../../../utils/goOut'

type SuccessStepProps = {
  successMessage: string
  ready?: boolean
}

export const PassChangedStep = ({ ready, successMessage }: SuccessStepProps) => (
  <div className={s.root}>
    <div className={s.status}>
      {ready ? <Status text={successMessage} /> : <Status text='Details are being updated' />}
    </div>

    <div className={s.mb10}>
      For security purposes, please click on the button below to log in again.
    </div>
    <div className={s.button}>
      <Button
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.preventDefault()
          goOut('/digital')
        }}
        disabled={!ready}
        replace='/login'
        style='secondary'
        text='Login'
      />
    </div>
  </div>
)

export default PassChangedStep
