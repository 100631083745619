/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import PropTypes from 'prop-types'
import s from './styles.css'
import Overlay from '../Overlay'

interface InfoCompProps {
  isSuccess: boolean
  showPermissionsForm: boolean
  currentView: string
  selectedAccessPointId: string
  showEntityForm: boolean
}

interface InfoCompState {
  showOverlay: boolean
}
class InfoComp extends React.Component<InfoCompProps, InfoCompState> {
  constructor(props) {
    super(props)
    this.state = {
      showOverlay: false,
    }
  }

  showHideOverlayFunc = () => {
    const { showOverlay } = this.state
    this.setState({ showOverlay: !showOverlay })
  }

  overlayContent = () => (
    <div className={s.genericoverlaymainContent}>
      <p className={s.genericoverlaycontent}>
        Following a number of changes to the law and regulation you can now give certain permitted
        companies (third party providers or TPPs) access to some of your accounts, so they can
        provide services such as:
      </p>
      <p className={s.genericoverlaycontent}>
        <ul className={s.psdInfoUl}>
          <li className={s.psdInfoLi}>
            Account aggregation: see your accounts with different providers all in one place. Banks,
            building societies and price comparison websites will be some of the companies providing
            this type of service.
          </li>
          <li className={s.psdInfoLi}>
            Payment initiation:online payments can be made on your behalf, as an alternative to
            using your debit or credit card.Some online retailers will be providing this service.
          </li>
        </ul>
      </p>

      <p className={s.genericoverlaycontent}>
        TPPs can only access your information and provide these types of services if you give your
        consent.{' '}
      </p>
      <p className={s.genericoverlaycontent}>
        We will list the available TPPs to you here and you can provide consent to share your data,
        and revoke it at a later date, as you wish.
      </p>
      <p className={s.genericoverlaycontent}>
        You must also register directly with the TPP. &nbsp;For help or further enquiries, please
        contact us on +44(0)1933 543543 or email us at{' '}
        <a href='mailto:bank@weatherbys.bank' target='_top' className={s.psdInfoEmailId}>
          bank@weatherbys.bank
        </a>
      </p>
    </div>
  )

  render() {
    const { isSuccess, showPermissionsForm, currentView, selectedAccessPointId, showEntityForm } =
      this.props
    const { showOverlay } = this.state

    return (
      <span>
        {isSuccess && (
          <p className={s.psdHeading1}>
            <span className={s.psdSuccess} />
            {selectedAccessPointId} has successfully linked to your accounts.
          </p>
        )}
        {!isSuccess && !showPermissionsForm && !showEntityForm && (
          <p className={s.psdHeading1}>
            View and manage the account data you share with other companies
          </p>
        )}
        {!isSuccess && !showPermissionsForm && !showEntityForm && (
          <p>You must also register your Weatherbys accounts with the provider.</p>
        )}

        {!isSuccess && !showPermissionsForm && showEntityForm && (
          <p className={s.psdHeading1}>Please select entity to continue.</p>
        )}
        {!isSuccess && showPermissionsForm && currentView === 'edit' && (
          <p className={s.psdHeading1}>
            Select the account data you wish to share with other companies
          </p>
        )}
        {!isSuccess && showPermissionsForm && currentView === 'view' && (
          <p className={s.psdHeading1}>
            Review the information below and confirm you are happy to share your data
          </p>
        )}

        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <span className={s.psdInfo} onClick={this.showHideOverlayFunc}>
          <span className={s.psdInfoModal}>&#105;</span>Find out more about sharing your data
        </span>
        {showOverlay && (
          <Overlay
            heading='Your data sharing'
            onClose={this.showHideOverlayFunc}
            content={this.overlayContent}
          />
        )}
      </span>
    )
  }
}

export default InfoComp
