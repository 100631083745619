import { connect } from 'react-redux'
import MainError from '../../components/PaymentsBulkItemView/MainError'
import { getErrorLogJson } from '../PaymentsBulk/selectors'

const mapStateToProps = (state) => {
  const errors = getErrorLogJson(state)
  return {
    errors,
  }
}

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(MainError)
