import React from 'react'
import MailForm from '../../containers/MailForm'
import FlowSuccess from '../FlowSuccess'
import FlowSwitch from '../../containers/FlowSwitch'
import ActionHeader from '../ActionHeader'
import InnerContainer from '../InnerContainer'
import Breadcrumbs from '../Breadcrumbs'

const MailNew = () => (
  <div>
    <Breadcrumbs
      items={[
        {
          to: '/secure-mail/f/inbox',
          text: 'Secure mailbox',
        },
        {
          to: '/secure-mail/new',
          text: 'Compose Message',
        },
      ]}
    />
    <InnerContainer>
      <ActionHeader title='Secure mailbox' />
      <h2>Compose Message</h2>
      <FlowSwitch
        SuccessComponent={FlowSuccess}
        successComponentProps={{
          message: 'Message sent',
          links: [
            {
              to: '/accounts',
              text: 'Back to my accounts',
            },
          ],
        }}
        formEl={<MailForm />}
      />
    </InnerContainer>
  </div>
)

export default MailNew
