import React from 'react'
import Icon from '../Icon'
import s from './styles.css'

type LoadingSpinnerPros = {
  text?: string
}

const LoadingSpinner = ({ text = 'Loading' }: LoadingSpinnerPros) => (
  <div className={s.root}>
    <Icon icon='loading' className={s.icon} />
    <span className={s.text}>{text}</span>
  </div>
)

export default LoadingSpinner
