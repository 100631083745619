import React from 'react'

import AccountHeader from '../AccountHeader'
import Transactions from '../../containers/Transactions'

type AccountViewProps = {
  id?: string
  title: string // todo - was added as AccountHeader requires it
  params: any // todo - was added as AccountHeader requires it
  type: string // todo - was added as AccountHeader requires it
}

export const AccountView = (props: AccountViewProps) => {
  const { id } = props

  return (
    <div>
      <AccountHeader {...props} />
      <Transactions id={id} />
    </div>
  )
}

export default AccountView
