import { dontDuplicate } from '../utils/routerBugWorkaround'

export default (store) => ({
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const PaymentsUpcoming = require('../containers/PaymentsUpcoming').default
        const { paymentsFutureInit } = require('../containers/PaymentsFuture/actions')
        const { paymentsRecurringInit } = require('../containers/PaymentsRecurring/actions')

        dontDuplicate(() => {
          store.dispatch(paymentsFutureInit())
          store.dispatch(paymentsRecurringInit())
        })

        cb(null, PaymentsUpcoming)
      },
      'payments-upcoming'
    )
  },
})
