/* eslint-disable */
// @ts-nocheck
// todo - eslint and typescript
import values from 'lodash/values'
import { select, call, put, takeLatest, fork } from 'redux-saga/effects'
import humanError from '../../utils/humanError'
import request from '../../utils/request'
import { modalErrorShow, modalHide } from '../../store/ui'
import {
  listUpdateAll,
  listItemUpdate,
  listMergeItems,
  listFetchStart,
} from '../../reducerCreators/listReducer/actions'
import { checkPermissionForAuthorisations, checkAccountCount } from './selectors'
import { flowMergeData } from '../../store/flow'
import APIError from '../../utils/APIError'
import moment from 'moment'
import * as c from '../../constants'

export function* handleAuthorisationInit() {
  yield call(fetchAllAuthorisations)
  yield put(listItemUpdate('authorisations-meta', 'lastVisited', moment()))
}

export function* handleAuthorisationProcess(action) {
  try {
    const p = action.payload
    yield put(
      flowMergeData({
        currentlyProcessing: p.id,
        currentlyProcessingType: p.type,
      })
    )
    const partyId = yield select((state) => state.authorisations.list.items[p.id].partyId)
    const nonce = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    if (p.type === 'decline') {
      yield call(request, {
        id: c.requestAuthorisationProcess,
        url: `/v1/transactions/${p.id}/reject?locale=en`,
        nonce: nonce,
        partyId: partyId,
        multiapproval: true,
        targetUnit: 'OBDX_BU',
        payload: {
          id: p.id,
          type: p.type,
        },
      })
    } else {
      yield call(request, {
        id: c.requestAuthorisationProcess,
        url: `/v1/transactions/${p.id}/approve?locale=en`,
        nonce: nonce,
        multiapproval: true,
        targetUnit: 'OBDX_BU',
        partyId: partyId,
        payload: {
          id: p.id,
          type: p.type,
        },
      })
    }

    yield put(
      flowMergeData({
        currentlyProcessing: false,
      })
    )

    yield put(
      listItemUpdate('authorisations', p.id, {
        status: p.type === 'authorise' ? 'Authorised' : 'Declined',
        done: moment(),
      })
    )

    yield put(modalHide())
  } catch (error) {
    console.error(error)
    const message = yield call(humanError, error)
    yield put(modalErrorShow(message))
  }
}

export function* fetchAllAuthorisations() {
  const canAuthorise = yield select(checkPermissionForAuthorisations)
  const hasAccounts = yield select(checkAccountCount)
  if (canAuthorise) {
    try {
      if (!hasAccounts) {
        throw new APIError(`You currently have no accounts that can be viewed.
          If you feel this is an error, please contact Weatherbys on +44 (0)1933 543543
          or email us at bank@weatherbys.bank .`)
      } else {
        yield put(listUpdateAll('authorisations', {}))
        yield put(listFetchStart('authorisations'))
        let nonce = yield call(request, {
          id: c.requestNonce,
          url: 'v1/session/nonce',
        })

        const { approvalsExist } = yield call(request, {
          id: c.requestApprovalCount,
          url: '/cz/v1/transactions/count?countFor=approval&locale=en',
          nonce: nonce,
        })

        let count = 0
        let types = []
        if (approvalsExist > 0) {
          nonce = yield call(request, {
            id: c.requestNonce,
            url: 'v1/session/nonce',
          })
          const response = yield call(request, {
            id: c.requestPendingApprovals,
            url: '/v1/transactions?discriminator=ACCOUNT_FINANCIAL&view=approval&status=PENDING_APPROVAL',
            nonce: nonce,
          })

          types = values(response.items)
          count = types.reduce((count, item) => {
            count += item.count
            return count
          }, 0)
        }

        yield put(listMergeItems('authorisations-meta', { count }))
        if (count > 0) {
          // yield put(listMergeItems('authorisations-meta', { count }))
          yield fork(fetchIndividualAuthorisations, types)
        } else {
          yield put(listUpdateAll('authorisations'))
        }
        // nonce = yield call(request, {
        //   id: c.requestNonce,
        //   url: 'v1/session/nonce'
        // })

        // const payeeResponse = yield call(request, {
        //   id: c.requestPendingApprovals,
        //   url: '/v1/transactions?discriminator=PAYEE_BILLER&view=approval&status=PENDING_APPROVAL',
        //   nonce: nonce
        // })
        // const payeeTypes = values(payeeResponse.items)
        // const payeeCount = types.reduce((payeeCount, item) => {
        //   payeeCount += item.count
        //   return count
        // }, 0)
        // if (count > 0) {
        //   yield put(listMergeItems('authorisations-meta', { payeeCount }))
        //   yield fork(fetchIndividualAuthorisations, payeeTypes)
        // } else {
        //   yield put(listUpdateAll('authorisations'))
        // }
      }
    } catch (error) {
      console.error(error)
      const message = yield call(humanError, error)
      yield put(modalErrorShow(message))
    }
  }
}

/**
API1: /v1/transactions?discriminator=ACCOUNT_FINANCIAL&view=approval&status=PENDING_APPROVAL
Purpose: To retrive active PENDING_APPROVALs

API2: /digx/cz/v1/transactions/count?countFor=approval&locale=en"
Purpose: To check if current user do have any Approvals which the user can perform,

Note: Prior to calling API1, it is important to query API2 if the current user
do have any Approvals to perform.

Note: Currently the check is not perform in fetchIndividualAuthorisations,
as it is chained from fetchAllAuthorisations and not called directly.
 */

export function* fetchIndividualAuthorisations(types) {
  const allItems = yield select((state) => state.authorisations.list.items)

  let url = '/v1/transactions?discriminator=ACCOUNT_FINANCIAL&view=approval&status=PENDING_APPROVAL'

  const nonce = yield call(request, {
    id: c.requestNonce,
    url: 'v1/session/nonce',
  })
  if (types[0].type === 'PC_N_DOP') {
    url = '/v1/transactions?discriminator=PAYEE_BILLER&view=approval&status=PENDING_APPROVAL'
  }
  const res = yield call(request, {
    id: c.requestActivityLogType,
    url: url,
    nonce: nonce,
  })
  const items = values(res.items)
  items.forEach((item) => {
    allItems[item.reference] = item
  })
  yield put(listUpdateAll('authorisations', allItems))
}

export function* watchAuthorisations() {
  yield takeLatest(c.AUTHORISATIONS_FETCH_REQUEST, fetchAllAuthorisations)
  yield takeLatest(c.AUTHORISATIONS_PROCESS_REQUEST, handleAuthorisationProcess)
  yield takeLatest(c.AUTHORISATIONS_INIT, handleAuthorisationInit)
}
