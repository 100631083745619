import React from 'react'
import FlowConfirm from '../../containers/FlowConfirm'
import FlowSwitch from '../../containers/FlowSwitch'
import ActionHeader from '../ActionHeader'
import Breadcrumbs from '../Breadcrumbs'
import InnerContainer from '../InnerContainer'
import Form from './Form'

type PayeeNewProps = {
  successComponentProps?: any
  onGoBackClick?: () => void
}

const PayeeNew = (props: PayeeNewProps & any) => {
  const onGoBackClick = { onGoBackClick: props.onGoBackClick }
  const successComponentProps = {
    ...props.successComponentProps,
    ...onGoBackClick,
  }
  return (
    <div>
      <Breadcrumbs
        items={[
          {
            to: '/payments',
            text: 'Payments',
          },
          {
            to: '/payments/payees',
            text: 'Manage payees',
          },
          {
            to: '/payments/payees/new',
            text: 'Add a new payee',
          },
        ]}
      />
      <InnerContainer>
        <ActionHeader title='Add a new payee' />
        <FlowSwitch
          SuccessComponent={FlowConfirm}
          successComponentProps={successComponentProps}
          formEl={<Form {...props} />}
        />
      </InnerContainer>
    </div>
  )
}

export default PayeeNew
