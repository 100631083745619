import React from 'react'
import s from './styles.css'
import Slidey from '../Slidey'
import image from '../../assets/img/entities.jpg'

type EntitiesHelpProps = {
  isOpen?: boolean
  isInDom?: boolean
  onCloseClick: () => void
}

const EntitiesHelp = ({ isInDom, isOpen, onCloseClick }: EntitiesHelpProps) => (
  <div>
    {isInDom && (
      <div className={s.root}>
        <div className={s.inner}>
          <Slidey isOpen={isOpen}>
            <div className={isOpen && s.triangleTop} />
            <div className={s.row}>
              <div className={s.image}>
                <img src={image} alt='Entities screenshot' />
              </div>
              <div className={s.text}>
                <h3>You have entities enabled</h3>
                <p>
                  You can use the entity selection on the top right of the page to view all
                  accounts, or only a subset of them.
                </p>

                <a href='#' className={s.close} title='close' onClick={onCloseClick}>
                  &times;
                </a>
              </div>
            </div>
          </Slidey>
        </div>
      </div>
    )}
  </div>
)

export default EntitiesHelp
