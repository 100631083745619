import moment from 'moment'
import getAccount from '../../selectors/getAccount'
import { saveeReceiptPDF } from '../../utils/savePDF'
import { decodeHTML } from '../../utils/decodeHTML'

export function organiseItems(state) {
  const {
    items: {
      toAccountNo,
      toSortCode,
      beneName,
      accountId,
      toAccountId,
      amount,
      valueDate,
      newBeneDetails,
    },
  } = state.activityLog.single

  const infoBlocks = []

  if (accountId) {
    const account = getAccount(state, accountId)
    infoBlocks.push({
      descriptor: 'From Account',
      title: account.title,
      extras: [`${account.sortCode} ${account.accountNumber}`],
    })
  }
  if (toAccountId) {
    const account = getAccount(state, toAccountId)
    console.log('account', account)
    infoBlocks.push({
      descriptor: 'Payee',
      title: account.title,
      extras: [`${account.sortCode} ${account.accountNumber}`],
    })
  } else if (toAccountNo) {
    infoBlocks.push({
      descriptor: 'Payee',
      title: beneName,
      extras: [`${toSortCode} ${toAccountNo}`],
    })
  }
  if (amount) {
    infoBlocks.push({ descriptor: 'Amount', title: amount })
  }

  if (newBeneDetails) {
    infoBlocks.push(
      { descriptor: 'Payee name', title: newBeneDetails.title },
      {
        descriptor: 'Payee account number',
        title: newBeneDetails.accountNumber,
      },
      { descriptor: 'Payee sort code', title: newBeneDetails.sortCode }
    )
  }

  if (valueDate) {
    infoBlocks.push({ descriptor: 'When', title: valueDate })
  }

  return infoBlocks
}

export const showReceipt = (state) => {
  const { type, status } = state.activityLog.single.items
  const correctType =
    type === 'Make a Payment' ||
    type === 'Internal Transfer' ||
    type === 'Bulk Domestic Transfer' ||
    type === 'External Standing Order/Future Payment' ||
    type === 'Internal Standing Order/Future Payment' ||
    type === 'Third Party Payment - Adhoc'
  const correctStatus = status === 'Accepted' || status === 'File Processed'
  return correctType && correctStatus
}

// eslint-disable-next-line consistent-return
export const saveeReceipt = (state) => {
  if (!showReceipt(state)) return null

  const { type } = state.activityLog.single.items

  let pdfConfig: any = {}
  switch (type) {
    case 'Make a Payment': {
      const {
        accountId,
        toAccountNo: toAccountNumber,
        valueDate: when,
        amount,
        beneName,
        toSortCode,
        reference,
        repeatsUntil,
      } = state.activityLog.single.items
      const fromAccount = getAccount(state, accountId)
      pdfConfig = {
        title: 'Payment Confirmation',
        rows: [
          { label: 'From account', value: decodeHTML(fromAccount.title) },
          {
            label: '',
            value: `60-93-03 XXXXX${String(fromAccount.accountNumber).substring(5)}`,
          },
          { label: '', value: '' },
          { label: 'Payee account', value: decodeHTML(beneName) },
          {
            label: '',
            value: `${toSortCode} XXXXX${String(toAccountNumber).substring(5)}`,
          },
          { label: '', value: '' },
          { label: 'Amount', value: `${amount} GBP` },
          { label: `${repeatsUntil ? 'Start date' : 'Date'}`, value: when },
          { label: 'Reference', value: reference },
        ],
      }
      break
    }
    case 'Third Party Payment - Adhoc': {
      const {
        accountId,
        toAccountNo: toAccountNumber,
        valueDate: when,
        amount,
        beneName,
        toSortCode,
        reference,
        repeatsUntil,
      } = state.activityLog.single.items
      const fromAccount = getAccount(state, accountId)
      pdfConfig = {
        title: 'Payment Confirmation',
        rows: [
          { label: 'From account', value: decodeHTML(fromAccount.title) },
          {
            label: '',
            value: `60-93-03 XXXXX${String(fromAccount.accountNumber).substring(5)}`,
          },
          { label: '', value: '' },
          { label: 'Payee account', value: decodeHTML(beneName) },
          {
            label: '',
            value: `${toSortCode} XXXXX${String(toAccountNumber).substring(5)}`,
          },
          { label: '', value: '' },
          { label: 'Amount', value: `${amount} GBP` },
          { label: `${repeatsUntil ? 'Start date' : 'Date'}`, value: when },
          { label: 'Reference', value: reference },
        ],
      }
      break
    }
    case 'External Standing Order/Future Payment': {
      const {
        accountId,
        toAccountNo: toAccountNumber,
        valueDate: when,
        amount,
        beneName,
        toSortCode,
        reference,
        repeatsUntil,
      } = state.activityLog.single.items
      const fromAccount = getAccount(state, accountId)
      pdfConfig = {
        title: 'Payment Confirmation',
        rows: [
          { label: 'From account', value: decodeHTML(fromAccount.title) },
          {
            label: '',
            value: `60-93-03 XXXXX${String(fromAccount.accountNumber).substring(5)}`,
          },
          { label: '', value: '' },
          { label: 'Payee account', value: decodeHTML(beneName) },
          {
            label: '',
            value: `${toSortCode} XXXXX${String(toAccountNumber).substring(5)}`,
          },
          { label: '', value: '' },
          { label: 'Amount', value: `${amount} GBP` },
          { label: `${repeatsUntil ? 'Start date' : 'Date'}`, value: when },
          { label: 'Reference', value: reference },
        ],
      }
      break
    }
    case 'Internal Standing Order/Future Payment': {
      const { accountId, amount, valueDate, reference, toAccountId, repeatsUntil } =
        state.activityLog.single.items

      const fromAccount = getAccount(state, accountId)
      const toAccount = getAccount(state, toAccountId)
      pdfConfig = {
        title: 'Transfer Confirmation',
        rows: [
          { label: 'From account', value: decodeHTML(fromAccount.title) },
          {
            label: '',
            value: `60-93-03 ${decodeHTML(fromAccount.accountNumber)}`,
          },
          { label: '', value: '' },
          { label: 'Payee account', value: decodeHTML(toAccount.title) },
          {
            label: '',
            value: `60-93-03 ${decodeHTML(toAccount.accountNumber)}`,
          },
          { label: '', value: '' },
          { label: 'Amount', value: amount },
          {
            label: `${repeatsUntil ? 'Start date' : 'Date'}`,
            value: valueDate,
          },
          { label: 'Reference', value: reference },
        ],
      }
      break
    }
    case 'Internal Transfer': {
      const { accountId, amount, valueDate, reference, toAccountId, repeatsUntil } =
        state.activityLog.single.items

      const fromAccount = getAccount(state, accountId)
      const toAccount = getAccount(state, toAccountId)
      pdfConfig = {
        title: 'Transfer Confirmation',
        rows: [
          { label: 'From account', value: decodeHTML(fromAccount.title) },
          {
            label: '',
            value: `60-93-03 ${decodeHTML(fromAccount.accountNumber)}`,
          },
          { label: '', value: '' },
          { label: 'Payee account', value: decodeHTML(toAccount.title) },
          {
            label: '',
            value: `60-93-03 ${decodeHTML(toAccount.accountNumber)}`,
          },
          { label: '', value: '' },
          { label: 'Amount', value: amount },
          {
            label: `${repeatsUntil ? 'Start date' : 'Date'}`,
            value: valueDate,
          },
          { label: 'Reference', value: reference },
        ],
      }

      break
    }
    case 'Bulk Domestic Transfer': {
      const { accountId, valueDate, amount } = state.activityLog.single.items

      const fromAccount = getAccount(state, accountId)

      pdfConfig = {
        title: 'Bulk Payment',
        rows: [
          { label: 'From Account', value: fromAccount.title },
          {
            label: '',
            value: `${fromAccount.sortCode} ${fromAccount.accountNumber}`,
          },
          { label: '', value: '' },
          { label: 'Download Date', value: moment().format('DD/MM/YYYY') },
          { label: 'e-Receipt Date', value: valueDate },
          { label: 'Amount', value: amount },
          { label: '', value: '' },
          {
            label: '',
            value: 'To track the progress of your bulk upload and the individual',
          },
          {
            label: '',
            value: 'payments included in this submission, please log on to Online Banking',
          },
          {
            label: '',
            value: "and select ‘Bulk Payments’ in the 'Payments & Transfers' section.",
          },
        ],
      }
      break
    }
    default:
  }

  saveeReceiptPDF(pdfConfig)
}

export function getActivity(state, aTSingle) {
  const activity = state.activityLog.type.items[aTSingle.id]
  return activity
}
