import { dontDuplicate } from '../utils/routerBugWorkaround'

export default (store, state) => ({
  path: 'alerts',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const Alerts = require('../containers/Alerts').default
        const { alertsInit } = require('../containers/Alerts/actions')

        const { accountNumber } = nextState.location.query

        dontDuplicate(() => {
          store.dispatch(alertsInit(accountNumber))
        })

        cb(null, Alerts)
      },
      'alerts'
    )
  },
})
