import { connect } from 'react-redux'
import * as a from '../../store/ui'

import Modal from '../../components/Modal'

const mapDispatchToProps = (dispatch, ownProps) => {
  const onCloseHandler = (e) => {
    e.preventDefault()
    dispatch(a.modalHide())
  }

  return {
    onClose: ownProps.onClose || onCloseHandler,
  }
}

const mapStateToProps = (state, ownProps) => ({
  isOpen: ownProps.isOpen || ownProps.id === state.ui.modal,
})

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
