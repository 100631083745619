/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import s from './styles.css'

type PendingTableDDProps = {
  showPendingTransactions: boolean
  showPendingTransactionsFunc: () => void
  hidePendingTransactionsFunc: () => void
}

export const PendingTableDD = ({
  showPendingTransactions,
  showPendingTransactionsFunc,
  hidePendingTransactionsFunc,
}: PendingTableDDProps) => (
  <div className={s.pendingTransactionsHeader}>
    <span className={s.pendingTransactionHeading1}>Pending Transactions </span>
    {showPendingTransactions ? (
      <span className={s.pendingTransactionHide} onClick={hidePendingTransactionsFunc}>
        Hide
        <i className={`${s.iconClass} ${s.up}`} />
      </span>
    ) : (
      <span className={s.pendingTransactionShow} onClick={showPendingTransactionsFunc}>
        Show <i className={`${s.iconClass} ${s.down}`} />
      </span>
    )}
  </div>
)

export default PendingTableDD
