/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import s from './styles.css'

type AccountTabsProps = {
  defaultTabState: string
  selectAll: () => void
  deselectAll: () => void
}

const AccountTabs = ({ defaultTabState, selectAll, deselectAll }: AccountTabsProps) => (
  <span className={s.tabMainDivDesktop}>
    <div className={s.tabMainDiv}>
      <div className={s.psdTab}>CURRENT ACCOUNT</div>
      <div className={s.psdTabInfo}> Select the individual accounts to connect to below.</div>
      {defaultTabState === 'select' && (
        <div className={s.psdselectAll} onClick={selectAll}>
          Select All
        </div>
      )}
      {defaultTabState === 'deselect' && (
        <div className={s.psddeselectAll} onClick={deselectAll}>
          Deselect
        </div>
      )}
    </div>
  </span>
)

export default AccountTabs
