import { connect } from 'react-redux'
import AccountCards from '../../components/AccountCards'
import getPayingAccounts from '../../selectors/getPayingAccounts'
import { displayAccountList, getShowAllIsVisible } from '../PaymentMake/selectors'
import { panelShow } from '../../store/ui'
import getIsAccountMappingKnown from '../../selectors/getIsAccountMappingKnown'
import * as a from '../PaymentMake/actions'

const mapStateToProps = (state, { initialNumberShown }) => ({
  accounts: displayAccountList(state, getPayingAccounts(state), initialNumberShown),
  accountsAreLoading: !getIsAccountMappingKnown(state, 'payment'),
  showAllIsVisible: getShowAllIsVisible(state, getPayingAccounts(state), initialNumberShown),
})

const mapDispatchToProps = (dispatch) => ({
  onShowAllClick: (e) => {
    e.preventDefault()
    dispatch(panelShow('showAllAccounts'))
  },
  onItemClick: (id) => {
    dispatch(a.uPaymentMakeFromUpdate(id))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountCards)
