import React, { ReactElement } from 'react'
import isObject from 'lodash/isObject'
import s from './styles.css'

type InlineFieldColumnsProps = {
  field1: {
    name: string
    label: string
    component: ReactElement
  }
  field2?:
    | boolean
    | {
        name: string
        label?: string
        component: ReactElement
      }
}

const InlineFieldColumns = ({ field1, field2 }: InlineFieldColumnsProps) => {
  const field2El = field2 ? (
    <div className={s.col2}>
      {isObject(field2) && field2.label && (
        <label className={s.label2} htmlFor={field2.name}>
          {field2.label}
        </label>
      )}
      {isObject(field2) && <div className={s.input2}>{field2.component}</div>}
    </div>
  ) : (
    false
  )

  return (
    <div>
      <div className={s.root}>
        <div className={s.col1}>
          <label className={s.label1} htmlFor={field1.name}>
            {field1.label}
          </label>
          <div className={s.input1}>{field1.component}</div>
        </div>
        {field2El}
      </div>
    </div>
  )
}

export default InlineFieldColumns
