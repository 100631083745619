import React from 'react'
import { confirmation } from 'redux-form-validators'
import PasswordFormHelper from './PasswordFormHelper'
import * as v from '../../utils/forms/validators'

const NewPasswordFormHelper = (props) => (
  <div>
    <PasswordFormHelper
      label='Choose a new password'
      name='newPass'
      validate={v.strongPassword}
      validateType='list'
      validationMessages={{
        between8And20Chars: 'Has between 8 and 20 characters',
        atLeastOneUpperCaseAndLowerCase: 'Has at least one uppercase and lowercase letter',
        maxRepetitions5: 'Does not use the same character more than 5 times',
        maxSuccessive3: 'No more than 3 letters or digits in a sequence',
        onlyPermittedChars: 'Allowed characters: A-Z 0-9 $ @ _ #',
      }}
    />
    <PasswordFormHelper
      label='Verify new password'
      name='newPassVerify'
      validate={confirmation({
        field: 'newPass',
        fieldLabel: 'Choose a new password',
      })}
      validateType='list'
      validationMessages={{
        "doesn't match `Choose a new password`": 'Passwords match',
      }}
    />
  </div>
)
export default NewPasswordFormHelper
