import React from 'react'
import { Link } from 'react-router'
import s from './styles.css'

type TabProps = {
  text: string
  to?: string
  active?: boolean
  onClick?: () => void
}

export const Tab = ({ text, active, onClick, to }: TabProps) => {
  const activeClass = active ? s.active : undefined
  let el

  if (onClick) {
    el = (
      <a href='#' onClick={onClick} className={`${s.tab} ${activeClass}`}>
        {text}
      </a>
    )
  } else {
    el = (
      <Link className={s.tab} to={to} activeClassName={s.active}>
        {text}
      </Link>
    )
  }
  return <li>{el}</li>
}

export default Tab
