export interface ISalt {
  data: {
    saltDTO: {
      version: number
      generatedPackageId: boolean
      auditSequence: number
      id: string
    }
  }
}
export default (data: ISalt, state): { salt?: string } => {
  if (data?.data?.saltDTO?.id) {
    const { id } = data?.data?.saltDTO
    return { salt: id }
  }

  return { salt: undefined }
}
