import * as c from '../../constants'

export interface IPendingTransactionsTableState {
  showPendingTransactions: boolean
}

const initialState: IPendingTransactionsTableState = {
  showPendingTransactions: true,
}
export default function pendingTransactionsTableReducer(
  state = initialState,
  action
): IPendingTransactionsTableState {
  let newState: any = {}

  switch (action.type) {
    case c.SHOW_PENDING_TRANSACTIONS:
      newState = { ...state }
      newState.showPendingTransactions = true
      return newState
    case c.HIDE_PENDING_TRANSACTIONS:
      newState = { ...state }
      newState.showPendingTransactions = false
      return newState
    default:
      return state
  }
}
