import React from 'react'
import { Field } from 'redux-form'
import Icon from '../../Icon'
import s from '../Checkboxes/styles.css'

type CheckboxProps = {
  name: string
  label?: string
  className?: string
  boldLabel?: string
  disabled?: boolean
  checked?: boolean // todo - added newly - passed by child
  onChange?: () => void // todo - added newly - passed by child
  validate?: any // todo - added newly - passed by child
}

const Checkbox = ({ name, label, className, boldLabel, disabled, ...props }: CheckboxProps) => (
  <div className={`${s.option} ${className}`} key={name}>
    <Field
      type='checkbox'
      component='input'
      name={name}
      key={name}
      id={name}
      disabled={disabled}
      {...props}
    />
    <label htmlFor={name} className={s.label}>
      <span className={s.box}>
        <Icon icon='check' />
      </span>
      {label && (
        <span>
          {boldLabel && <strong>{boldLabel} </strong>}
          {label}
        </span>
      )}
    </label>
  </div>
)

export default Checkbox
