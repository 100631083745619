export default {
  payees: {
    header: [
      {
        text: 'Name',
        perc: 15,
      },
      {
        text: 'Bank Account',
        perc: 5,
      },
      {
        text: 'Last Reference',
        perc: 45,
      },
      {
        text: 'Last Paid',
        perc: 10,
      },
    ],
    body: [
      {
        id: '01',
        cols: [
          {
            text: 'Richard D James',
            perc: 45,
            showFor: 'medium',
          },
          {
            text: '12345678',
            extra: '01-02-03',
            perc: 20,
          },
          {
            text: 'AVRIL 14',
            perc: 10,
          },
          {
            text: '10/11/2017',
            perc: 12,
          },
        ],
      },
      {
        id: '02',
        cols: [
          {
            text: 'Axel Boman',
            perc: 45,
            showFor: 'medium',
          },
          {
            text: '12345678',
            extra: '01-02-03',
            perc: 20,
          },
          {
            text: 'FAMILY VACATION',
            perc: 10,
          },
          {
            text: '10/11/2017',
            perc: 12,
          },
        ],
      },
      {
        id: '03',
        cols: [
          {
            text: 'John Talabot',
            perc: 45,
            showFor: 'medium',
          },
          {
            text: '12345678',
            extra: '01-02-03',
            perc: 20,
          },
          {
            text: 'DESTINY',
            perc: 10,
          },
          {
            text: '10/11/2017',
            perc: 12,
          },
        ],
      },
      {
        id: '04',
        cols: [
          {
            text: 'Nicolas Hasquitealongname Jaar',
            perc: 45,
            showFor: 'medium',
          },
          {
            text: '12345678',
            extra: '01-02-03',
            perc: 20,
          },
          {
            text: 'SPACE',
            perc: 10,
          },
          {
            text: '10/11/2017',
            perc: 12,
          },
        ],
      },
    ],
  },
  transactions: {
    header: [
      {
        text: '',
        perc: 15,
        showFor: 'medium',
      },
      {
        text: '',
        perc: 5,
        showFor: 'medium',
      },
      {
        text: '',
        perc: 45,
      },
      {
        text: 'Debit',
        perc: 10,
        showFor: 'large',
      },
      {
        text: 'Credit',
        perc: 10,
        showFor: 'large',
      },
      {
        text: 'Debit/Credit',
        perc: 12,
        hideFor: 'large',
      },
      {
        text: 'Balance',
        perc: 40,
        showFor: 'medium',
      },
    ],
    body: [
      {
        id: '01',
        cols: [
          {
            text: '23/11/2016',
            perc: 15,
            showFor: 'medium',
          },
          {
            perc: 5,
            showFor: 'medium',
          },
          {
            text: 'Bank Transfer',
            extra: '09345-234-342',
            perc: 45,
          },
          {
            text: '£114.00',
            perc: 10,
            showFor: 'large',
          },
          {
            perc: 10,
            showFor: 'large',
          },
          {
            text: '£114.00',
            perc: 12,
            hideFor: 'large',
          },
          {
            text: '£145,001,230.00',
            perc: 40,
            showFor: 'medium',
          },
        ],
      },
      {
        id: '02',
        cols: [
          {
            text: '23/11/2016',
            perc: 15,
            showFor: 'medium',
          },
          {
            perc: 5,
            showFor: 'medium',
          },
          {
            text: 'By Cheque Deposit',
            extra: '09345-234-342',
            perc: 45,
          },
          {
            text: '£1832',
            perc: 10,
            showFor: 'large',
          },
          {
            perc: 10,
            showFor: 'large',
          },
          {
            text: '£114.00',
            perc: 12,
            hideFor: 'large',
          },
          {
            text: '£145,001,230.00',
            perc: 40,
            showFor: 'medium',
          },
        ],
      },
      {
        id: '03',
        cols: [
          {
            text: '23/11/2016',
            perc: 15,
            showFor: 'medium',
          },
          {
            perc: 5,
            showFor: 'medium',
            iconGlyph: '#direct-debit_04de6daf6be117daaeeafe2f5e9b8dfb',
          },
          {
            text: 'By Cheque Deposit',
            extra: '09345-234-342',
            perc: 45,
          },
          {
            text: '£500',
            perc: 10,
            showFor: 'large',
          },
          {
            perc: 10,
            showFor: 'large',
          },
          {
            text: '£114.00',
            perc: 12,
            hideFor: 'large',
          },
          {
            text: '£145,001,230.00',
            perc: 40,
            showFor: 'medium',
          },
        ],
      },
      {
        id: '04',
        cols: [
          {
            text: '23/11/2016',
            perc: 15,
            showFor: 'medium',
          },
          {
            perc: 5,
            showFor: 'medium',
          },
          {
            text: 'By Cheque Deposit',
            extra: '09345-234-342',
            perc: 45,
          },
          {
            perc: 10,
            showFor: 'large',
          },
          {
            text: '£500',
            perc: 10,
            showFor: 'large',
          },
          {
            text: '-£500.00',
            perc: 12,
            hideFor: 'large',
          },
          {
            text: '£145,001,230.00',
            perc: 40,
            showFor: 'medium',
          },
        ],
      },
      {
        id: '05',
        cols: [
          {
            text: '23/11/2016',
            perc: 15,
            showFor: 'medium',
          },
          {
            perc: 5,
            showFor: 'medium',
            iconGlyph: '#direct-debit_04de6daf6be117daaeeafe2f5e9b8dfb',
          },
          {
            text: 'Bank Transfer',
            extra: '09345-234-342',
            perc: 45,
          },
          {
            text: '£114.00',
            perc: 10,
            showFor: 'large',
          },
          {
            perc: 10,
            showFor: 'large',
          },
          {
            text: '£114.00',
            perc: 12,
            hideFor: 'large',
          },
          {
            text: '£145,001,230.00',
            perc: 40,
            showFor: 'medium',
          },
        ],
      },
      {
        id: '06',
        cols: [
          {
            text: '23/11/2016',
            perc: 15,
            showFor: 'medium',
          },
          {
            perc: 5,
            showFor: 'medium',
          },
          {
            text: 'By Cheque Deposit',
            extra: '09345-234-342',
            perc: 45,
          },
          {
            text: '£114.00',
            perc: 10,
            showFor: 'large',
          },
          {
            perc: 10,
            showFor: 'large',
          },
          {
            text: '£114.00',
            perc: 12,
            hideFor: 'large',
          },
          {
            text: '£145,001,230.00',
            perc: 40,
            showFor: 'medium',
          },
        ],
      },
    ],
  },
}
