import React from 'react'
import InnerContainer from '../InnerContainer'
import DataTable from '../../containers/DataTable'
import StopModal from './StopModal'
import s from './styles.css'

export const PaymentsUpcomingView = (props) => (
  <div className={s.root}>
    <InnerContainer>
      <h1>Future payments and transfers</h1>
    </InnerContainer>
    <DataTable {...props} simpleResultsState />
    <StopModal {...props} />
  </div>
)

export default PaymentsUpcomingView
