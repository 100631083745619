import { connect } from 'react-redux'
import PaymentsRecurring from '../../components/PaymentsRecurring'
import { getVisibleRecurringPaymentAccounts } from './selectors'
import convertToOptions from '../../utils/convertToOptions'

const mapStateToProps = (state, ownProps) => ({
  //  const SIItems = filterSortPayments(state, state.paymentsRecurring.SI.items)
  //  const DDItems = filterSortPayments(state, state.paymentsRecurring.DD.items)
  accountSelectOptions: [
    {
      label: 'All Accounts',
      value: false,
    },
    ...convertToOptions(getVisibleRecurringPaymentAccounts(state)),
  ],
  initialValues: {
    account: {
      label: 'All Accounts',
      value: false,
    },
  },
})

export default connect(mapStateToProps)(PaymentsRecurring)
