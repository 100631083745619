import React, { ReactNode } from 'react'
import s from './styles.css'

type PanelProps = {
  children: ReactNode
}

const Panel = ({ children }: PanelProps) => <div className={s.root}>{children}</div>

export default Panel
