import { combineReducers } from 'redux'
import createListReducer, { ListState } from '../../reducerCreators/listReducer'

export interface IAuthorisationsState {
  list: ListState
  meta: ListState
}

export default combineReducers<IAuthorisationsState, any>({
  list: createListReducer('authorisations'),
  // Bad use of createListReducer
  meta: createListReducer('authorisations-meta'),
})
