import React from 'react'
import s from './styles.css'

import responsiveCheck from '../../../utils/responsiveCheck'
import { TableHeadColsProps } from './types'

/**
 * TableHead component
 * This is seperated out for use in sticky navigation, as a
 * seperate element
 * @param   {boolean} hideHeader - Collapses the header so still visible for
 * screen readers. Useful for when header being displayed as sticky elsewhere.
 * @param   {Object[]} cols - Array of objects for displaying the th elements
 * @param   {string} cols[].showFor - ('medium'/'large') Show the element on this media query
 * @param   {string} cols[].hideFor - ('medium'/'large') Hide the element on this media query
 * @param   {number} cols[].perc - Base percentage width for this column
 * @param {string} cols[].text - Text for the th
 * @param  {object} browser - Object with media query info, comes from responsiveReducer
 * @return {ReactElement}
 */

type TableHeadProps = {
  hasDrawer?: boolean
  browser?: {
    greaterThan?: {
      small: string | number | boolean
      medium: string | number | boolean
    }
  }
  cols: TableHeadColsProps[]
  hideHeader?: boolean
}

const TableHead = ({ hideHeader, cols = [], browser, hasDrawer }: TableHeadProps) => {
  const headerClass = hideHeader ? s.collapsed : undefined
  return (
    <thead className={headerClass}>
      <tr>
        {cols.map((item, index) => {
          const condition = item.showFor ? item.showFor : item.hideFor
          let show = responsiveCheck(condition, browser)
          // Reverse responsive check if hiding for instead of showing for
          if (item.hideFor) {
            show = !show
          }
          const width = item.perc ? `${item.perc}%` : 'auto'
          const textIndent = item.headerHide ? '-999rem' : 0
          const style = {
            width,
            textIndent,
          }
          const element = show ? (
            // eslint-disable-next-line react/no-array-index-key
            <th key={index} style={style}>
              {item.text}
            </th>
          ) : (
            false
          )
          return element
        })}
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        {hasDrawer && <th className={s.drawerSpacer} />}
      </tr>
    </thead>
  )
}

export default TableHead
