import React from 'react'
import { InjectedFormProps, reduxForm } from 'redux-form'
import { PAYMENT_FLOW_CONFIRMATION_OF_PAYEE_SUBMIT } from '../../constants'
import ConfirmationOfPayee from '../../containers/ConfirmationOfPayee'
import { ConfirmationOfPayeeStatus } from '../../containers/ConfirmationOfPayee/reducer'
import PayeeFields from '../../containers/PayeeFields'
import ScrollTo from '../../containers/ScrollTo'
import ActionHeader from '../ActionHeader'
import Button from '../Button'
import BottomError from '../Forms/BottomError'
import InnerContainer from '../InnerContainer'
import s from './styles.css'

type PaymentMakeNewPayeeProps = {
  confirmPayee?: () => void
  error?: string
  onViewPayeesClick: () => void
  copStatus: ConfirmationOfPayeeStatus
  onCopFlowReset: () => void
  handleSubmit?: () => void
  copLoading?: boolean
}

const PaymentMakeNewPayee = class PaymentMakeNewPayee extends React.Component<
  InjectedFormProps<any> & PaymentMakeNewPayeeProps
> {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount(): void {
    this.props.onCopFlowReset()
  }

  render() {
    const { handleSubmit, confirmPayee, error, onViewPayeesClick, copStatus, copLoading } =
      this.props
    const showForm = copStatus === ConfirmationOfPayeeStatus.NOT_STARTED

    return (
      <InnerContainer>
        <ScrollTo>
          <ActionHeader
            title='New payment or transfer'
            actionText='View my payees'
            headerSize='2'
            onClick={onViewPayeesClick}
          />
        </ScrollTo>
        <form
          onSubmit={handleSubmit(confirmPayee)}
          style={{ display: showForm ? 'block' : 'none' }}
        >
          <fieldset className='narrow'>
            <PayeeFields formName='paymentMakeNewPayee' />
            <BottomError message={error} />
            <Button loading={copLoading} submit text='Check Details' />
            <button
              type='button'
              className={s.formActionButton}
              onClick={() => {
                window.location.href = '#/payments/payees'
              }}
            >
              Cancel
            </button>
          </fieldset>
        </form>

        {!showForm && (
          <ConfirmationOfPayee
            formName='paymentMakeNewPayee'
            exitCopAction={PAYMENT_FLOW_CONFIRMATION_OF_PAYEE_SUBMIT}
            cancelCopLocation='#/payments/payees'
          />
        )}
      </InnerContainer>
    )
  }
}

const PaymentMakeNewPayeeReduxForm = reduxForm<any, PaymentMakeNewPayeeProps>({
  form: 'paymentMakeNewPayee',
})(PaymentMakeNewPayee)

export default PaymentMakeNewPayeeReduxForm
