import React from 'react'
import s from './styles.css'
import { decimal2Places } from '../../../utils/forms/formatters'
import { SupportedCurrenciesType } from '../../../models/enum'

type MoneyFieldProps = {
  input: {
    name?: string
    onBlur?: (event: any) => void
    value?: string
  }
  placeholder?: SupportedCurrenciesType | ''
  autoFocus?: boolean
}

const MoneyField = ({ input, placeholder, autoFocus }: MoneyFieldProps) => (
  <div className={s.root}>
    <input
      className={s.input}
      id={input.name}
      placeholder={placeholder}
      autoFocus={autoFocus}
      type='text'
      onBlur={() => input.onBlur(decimal2Places(input.value))}
      {...input}
    />
  </div>
)

export default MoneyField
