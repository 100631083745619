import React from 'react'
import s from './styles.css'
import LinkList from '../LinkList'
import Jumpoffs from '../Jumpoffs'
import LinkSelect from '../../containers/LinkSelect'
import HeadingWithDetails from '../HeadingWithDetails'
import DetailList from '../DetailList'
import AvailableBalanceInfoModal from '../../containers/AvailableBalanceInfoModal'

type CurrentAccountProps = {
  title: string
  balance: string
  typeTitle: string
  availableBalance: string
  overdraftLimit?: string
  iban?: string
  accountNumber: string
  sortCode: string
  params: { id: string }
  canMakePayment?: boolean
  canTransfer?: boolean
  isInternational?: boolean
  statementsIsShowing?: boolean
  specialMessage?: string
  accountServicesIsShowing?: boolean
}

const CurrentAccount = ({
  title,
  balance,
  typeTitle,
  availableBalance,
  overdraftLimit,
  accountNumber,
  sortCode,
  canMakePayment,
  canTransfer,
  isInternational,
  params,
  iban,
  specialMessage,
  statementsIsShowing,
  accountServicesIsShowing,
}: CurrentAccountProps) => {
  // let detailItems = [
  //   {
  //     title: 'Available balance',
  //     value: availableBalance
  //   }
  // ]
  const detailItems = []
  if (overdraftLimit) {
    detailItems.push({
      title: 'Overdraft limit',
      value: overdraftLimit,
    })
  }

  // Construct jump off items
  let makeAPaymentItem = []
  if (isInternational) {
    makeAPaymentItem = [
      {
        title: 'Request payment',
        icon: 'makePayment',
        to: `/payments/request-international/${params.id}`,
      },
    ]
  } else if (canMakePayment) {
    makeAPaymentItem = [
      {
        title: 'Make a Payment',
        icon: 'makePayment',
        to: `/payments/make/${params.id}`,
      },
    ]
  }

  const transferMoneyItem = canTransfer
    ? [
        {
          title: 'Internal Transfer',
          icon: 'transferMoney',
          to: `/payments/transfer-money/${params.id}`,
        },
      ]
    : []

  const jumpoffItems = [
    ...makeAPaymentItem,
    {
      title: 'Set up alerts',
      icon: 'alert',
      to: '/settings/alerts',
    },
    ...transferMoneyItem,
  ]

  // Construct Link list items
  const linkListItems = [
    {
      to: `accounts/statements/${params.id}`,
      title: 'Statements',
      excluded: !statementsIsShowing,
    },
    {
      to: `accounts/services?accountId=${params.id}`,
      title: 'Cheque services',
      excluded: !accountServicesIsShowing,
    },
  ]

  // Combine the above to create quick link options
  const quickLinksOptions = [...jumpoffItems, ...linkListItems].map((item) => ({
    value: item.to,
    label: item.title,
  }))
  return (
    <div className={s.root}>
      <div className={s.inner}>
        <div className={s.row}>
          <div className={s.col}>
            <HeadingWithDetails title={title} details={[typeTitle, sortCode, accountNumber]} />
            {iban && (
              <DetailList
                items={[
                  {
                    title: 'IBAN',
                    value: iban,
                  },
                ]}
              />
            )}
            <div className={s.mobDetails}>
              <h1 className='reduced'>{balance}</h1>
              {availableBalance && (
                <AvailableBalanceInfoModal availableBalance={availableBalance} />
              )}
              <DetailList items={detailItems} />
            </div>
          </div>
          <div className={`${s.col} ${s.details}`}>
            <h1 className='reduced'>{balance}</h1>
            {availableBalance && <AvailableBalanceInfoModal availableBalance={availableBalance} />}
            <DetailList items={detailItems} />
          </div>
          <div>
            <div className={s.jumpoffs}>
              <Jumpoffs items={jumpoffItems} />
            </div>
            {specialMessage && <p className={s.message}>{specialMessage}</p>}
          </div>
        </div>
        <div className={s.linksDropdown}>
          <LinkSelect placeholder='Quick links' options={quickLinksOptions} />
        </div>
        <div className={s.actions}>
          <LinkList items={linkListItems} />
        </div>
      </div>
    </div>
  )
}

export default CurrentAccount
