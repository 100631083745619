/* eslint-disable */
// @ts-nocheck
// todo - eslint and typescript
import { takeLatest, call, put, select, delay } from 'redux-saga/effects'
import get from 'lodash/get'
import moment from 'moment'
import { modalErrorShow } from '../../store/ui'
import humanError from '../../utils/humanError'
import convertFromFile from '../../utils/convertFromFile'
import parseBulkJson from '../../utils/parseBulkJson'
import sheetToJson from '../../utils/sheetToJson'
import updateList from '../../sagas/updateList'
import {
  listMergeItems,
  listFetchStop,
  listFetchStart,
  listMergeItemDetails,
} from '../../reducerCreators/listReducer/actions'
import { formError } from '../../sagas/formErrors/actions'
import { getPicker } from '../../containers/SuperDatePicker/selectors'
import * as c from '../../constants'
import * as a from './actions'
import {
  getBulkItemProps,
  hasFunds,
  getBulkId,
  getFromAccount,
  hasTransactionRange,
  hasDailyTransactionCountLeft,
  hasDailyLimitLeft,
} from './selectors'
import getFlowData from '../../selectors/getFlowData'
import { flowSuccess, flowMergeData } from '../../store/flow'
import { userDetailsUpdate } from '../../store/user'
import request from '../../utils/request'
import { twoFactorAuthSetInitialRequest, twoFactorAuthSetRef } from '../TwoFactorAuth/actions'
// import GA from '../../utils/GA'

const endDate = moment().format('YYYY-MM-DD')
const startDate = moment().subtract(3, 'month').format('YYYY-MM-DD')
const baseUrl = '/v1/fileUploads/files?pageNumber=1&pageSize=25'
const dateRange = `uploadDateEndRange=${endDate}&uploadDateStartRange=${startDate}`

const requestPaymentsBulkSearch = {
  id: c.requestPaymentsBulkSearch,
  url: `${baseUrl}&${dateRange}&locale=en`,
}

const updateHomeListSaga = updateList('paymentBulkHomeList', requestPaymentsBulkSearch)

export function* handleItemInit(action) {
  try {
    const p = action.payload
    let countedPages = 1
    const requestPaymentsBulkItem = {
      id: c.requestPaymentsBulkItem,
      url: `/v1/fileUploads/files/${p.id}/records?pageNumber=${countedPages}&pageSize=25&transactionType=DTF`,
    }

    yield call(updateList('paymentBulkFileItem', requestPaymentsBulkItem))
    const props = yield select(getBulkItemProps)
    const pages = Math.ceil(props.records / 25)
    let unreadPages = pages
    while (unreadPages > 1) {
      unreadPages = pages - countedPages
      countedPages++
      const nonce = yield call(request, {
        id: c.requestNonce,
        url: 'v1/session/nonce',
      })
      const requestPaymentsBulkItem = yield call(request, {
        id: c.requestPaymentsBulkItem,
        url: `/v1/fileUploads/files/${p.id}/records?pageNumber=${countedPages}&pageSize=25&transactionType=DTF`,
        nonce,
      })
      yield put(listMergeItemDetails('paymentBulkFileItem', requestPaymentsBulkItem.items))
    }
    const nonce = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    const fileRes = yield call(request, {
      id: c.requestPaymentsBulkDownloadFile,
      url: `cz/v1/fileUploads/files/${props.reference}/responsefile?locale=en`,
      nonce,
    })
    yield put(listMergeItems('paymentBulkFileItem', { downloadFile: fileRes }))
    // If status is error, then get the error log also
    if (props.status === 'error') {
      const nonce = yield call(request, {
        id: c.requestNonce,
        url: 'v1/session/nonce',
      })
      const res = yield call(request, {
        id: c.requestPaymentsBulkErrorLog,
        url: `cz/v1/fileUploads/files/${props.reference}/responsefile?fileType=error&locale=en`,
        nonce,
      })
      yield put(listMergeItems('paymentBulkFileItem', { errorLog: res.data }))
    }
  } catch (error) {
    console.error(error)
  }
}

export function* handleUpdate() {
  const picker = yield select(getPicker, 'paymentsBulkFiles')
  const startDate = moment(picker.startDateText, 'DD/MM/YY').format('YYYY-MM-DD')
  const endDate = moment(picker.endDateText, 'DD/MM/YY').format('YYYY-MM-DD')
  const pickerDateRange = `uploadDateEndRange=${endDate}&uploadDateStartRange=${startDate}`
  const requestPaymentsBulkSearch = {
    id: c.requestPaymentsBulkSearch,
    url: `${baseUrl}&${pickerDateRange}&locale=en`,
  }
  yield call(updateList('paymentBulkFullList', requestPaymentsBulkSearch))
}

export function* handleInit() {
  try {
    const isBulkIdKnown = yield select(getBulkId)

    if (!isBulkIdKnown) {
      const nonce = yield call(request, {
        id: c.requestNonce,
        url: 'v1/session/nonce',
      })
      const info = yield call(request, {
        id: c.requestBulkId,
        url: '/cz/v1/fileUploads/userFileIdentifiersMappings?locale=en',
        nonce,
      })

      yield put(
        userDetailsUpdate({
          bulkId: info,
        })
      )
    }
    yield call(updateHomeListSaga)
  } catch (error) {
    console.error(error)
    const message = yield call(humanError, error)
    yield put(modalErrorShow(message))
  }
}

export function* handleUploadVerify(action) {
  try {
    const formData = action.payload
    const fileData = yield call(convertFromFile, formData.file)
    const base64Only = fileData.split(',')[1]
    const jsonObj = yield call(sheetToJson, base64Only)
    const data = yield call(parseBulkJson, jsonObj)
    const accountId = yield select(getFlowData)
    const fromAccount = yield select(getFromAccount, accountId)
    const canMakePayment = yield select(hasFunds, accountId, data.totalAmount)
    const userLimitUrl = 'v1/me/assignedLimitPackage?'
    const partyLimitUrl = 'v1/me/party/assignedLimitPackage?'
    const spentMoneyUrl = 'v1/financialLimitUtilization?entityType='
    const spentMoneyEndPoint =
      '&limitType=PER%23DAILY&accessPointValue=APINTERNET&accessPointGroupType=SINGLE'
    const spentPartyMoneyEndPoint =
      '&limitType=PER%23DAILY&accessPointValue=APINTERNET&accessPointGroupType=SINGLE'

    let limitNonce = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })

    const userLimits = yield call(request, {
      id: c.requestUserTransactionLimit,
      nonce: limitNonce,
      url: `${userLimitUrl}accessPointValue=APINTERNET&accessPointGroupType=SINGLE&locale=en`,
    })

    limitNonce = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })

    const partyLimits = yield call(request, {
      id: c.requestGroupTransactionLimit,
      nonce: limitNonce,
      url: `${partyLimitUrl}accessPointValue=APINTERNET&accessPointGroupType=GROUP&locale=en`,
      partyId: fromAccount.customerId,
    })

    limitNonce = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })

    const userLimitUsed = yield call(request, {
      id: c.requestUserTransactionLimitSpent,
      nonce: limitNonce,
      url: `${spentMoneyUrl}USER${spentMoneyEndPoint}`,
    })

    limitNonce = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })

    const partyLimitUsed = yield call(request, {
      id: c.requestGroupTransactionLimitSpent,
      nonce: limitNonce,
      url: `${spentMoneyUrl}PARTY${spentPartyMoneyEndPoint}`,
      partyId: fromAccount.customerId,
    })
    const userLimitUsedAmount = get(userLimitUsed, 'bulkPayments.amount', '')
    const userPaymentCount = get(userLimitUsed, 'bulkPayments.count', '')
    const partyLimitUsedAmount = get(partyLimitUsed, 'bulkPayments.amount', '')
    const partyPaymentCount = get(partyLimitUsed, 'bulkPayments.count', '')
    let userPaymentLimit = {}
    if (userLimits) {
      userPaymentLimit = {
        txnRange: userLimits.bulkPayments.txnRange,
        maxCount: parseInt(userLimits.bulkPayments.maxCount) - userPaymentCount,
        maxAmount: userLimits.bulkPayments.txnLimit - userLimitUsedAmount,
      }
    }
    const partyPaymentLimit = {}
    if (partyLimits) {
      partyPaymentLimit[fromAccount.customerId] = {
        txnRange: partyLimits.bulkPayments.txnRange,
        maxCount: parseInt(partyLimits.bulkPayments.maxCount) - partyPaymentCount,
        maxAmount: partyLimits.bulkPayments.txnLimit - partyLimitUsedAmount,
      }
    }
    yield put(
      userDetailsUpdate({
        userBulkPaymentLimit: userPaymentLimit,
      })
    )
    yield put(
      userDetailsUpdate({
        partyBulkPaymentLimit: partyPaymentLimit,
      })
    )

    const transactionLimit = yield select(
      hasTransactionRange,
      fromAccount.customerId,
      parseFloat(data.totalAmount)
    )
    const transactionLimitLeft = yield select(
      hasDailyLimitLeft,
      fromAccount.customerId,
      parseFloat(data.totalAmount)
    )
    const transactionCountLeft = yield select(hasDailyTransactionCountLeft, fromAccount.customerId)
    const alphaNumeric = /^[0-9a-zA-Z]+$/
    if (!canMakePayment) {
      yield put(
        formError(a.bulkUploadVerify.FAILURE, {
          name: 'API',
          message: 'This account does not have the required funds to make these payments.',
        })
      )
    } else if (transactionLimit === 'amountTooSmall') {
      yield put(
        formError(a.bulkUploadVerify.FAILURE, {
          name: 'API',
          message: `This amount for this Payment Type is too small.
          Please contact the Bank Helpdesk on +44 (0) 1933 543543 for assistance.`,
        })
      )
    } else if (transactionLimit === 'amountTooBig') {
      yield put(
        formError(a.bulkUploadVerify.FAILURE, {
          name: 'API',
          message: `This amount exceeds the limit for this Payment Type.
          Please contact the Bank Helpdesk on +44 (0) 1933 543543 for assistance.`,
        })
      )
    } else if (transactionLimitLeft !== undefined && !transactionLimitLeft) {
      yield put(
        formError(a.bulkUploadVerify.FAILURE, {
          name: 'API',
          message: `The transfer requested is above the daily limit and will not be processed.
          Please contact our Helpdesk on 01933 543543 for further information.`,
        })
      )
    } else if (!transactionCountLeft) {
      yield put(
        formError(a.bulkUploadVerify.FAILURE, {
          name: 'API',
          message: `The transfer requested is above the daily amount of transactions limit and will not be processed.
          Please contact our Helpdesk on 01933 543543 for further information.`,
        })
      )
    } else if (formData.file.name.length > 30) {
      yield put(
        formError(a.bulkUploadVerify.FAILURE, {
          name: 'API',
          message:
            'The bulk payment file could not be uploaded as the file name exceeds 26 characters.' +
            ' Please rename the file and upload.',
        })
      )
    } else if (
      formData.file.name.replace(/\s/g, '').split('.').length !== 2 ||
      !formData.file.name.replace(/\s/g, '').split('.')[0].match(alphaNumeric)
    ) {
      yield put(
        formError(a.bulkUploadVerify.FAILURE, {
          name: 'API',
          message: 'Please provide only alphanumeric values and spaces for the file name.',
        })
      )
    } else {
      yield put(
        flowSuccess({
          accountId,
          numRows: data.numRows,
          totalAmount: data.totalAmount,
          date: formData.date,
          fileName: formData.file.name,
          fileData: base64Only,
        })
      )
    }
  } catch (error) {
    console.error(error)
    yield put(formError(a.bulkUploadVerify.FAILURE, error))
  }
}

export function* handleUploadConfirm(action) {
  try {
    yield put(twoFactorAuthSetInitialRequest('bulkUpload'))
    const { fileName, fileData, date, accountId } = yield select(getFlowData)
    const partyId = yield select((state) => state.accounts.items[accountId].customerId)
    yield put(
      flowMergeData({
        disableCancel: true,
      })
    )
    const nonce = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })

    const res = yield call(request, {
      id: c.requestPaymentsBulkUpload,
      url: '/cz/v1/fileUploads/files?locale=en',
      nonce,
      partyId,
      payload: {
        accountId,
        date,
        fileName,
        fileData,
      },
    })
    const paymentDetails = {
      refNumber: res.ref,
      partyId,
    }
    yield put(twoFactorAuthSetRef(paymentDetails))
    //#GA4-PROVISIONALLY-DISABLED
    // GA.event({
    //   category: 'Form',
    //   action: 'Submit',
    //   label: 'Bulk Payment',
    // })
  } catch (error) {
    console.error(error)
    yield put(formError(a.bulkUploadConfirm.FAILURE, error))
  }
}

export function* handleBulkValidationAfterAuth(action) {
  try {
    const { href } = window.location
    if (!href.includes('/payments/bulk')) {
      return
    }
    yield put(listFetchStart('paymentBulkValidation'))
    const ref = action.payload.response.bulkFileReference

    // The file needs to go through the validation process on the backend
    yield delay(5000)
    const nonce = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })

    const bulkFiles = yield call(request, {
      id: c.requestPaymentsBulkSearch,
      url: `${baseUrl}&${dateRange}&locale=en`,
      nonce,
    })
    const newlyUploadedFile = bulkFiles.items[ref]

    const hasErrors = newlyUploadedFile.status === 'ERROR'

    yield put(listFetchStop('paymentBulkValidation'))
    yield put(listMergeItems('paymentBulkValidation', { hasErrors, ref }))
  } catch (error) {
    console.error(error)
  }
}

export function* watchPaymentsBulk() {
  yield takeLatest(c.PAYMENTS_BULK_INIT, handleInit)
  yield takeLatest(c.PAYMENTS_BULK_LIST_UPDATE, handleUpdate)
  yield takeLatest(c.PAYMENTS_BULK_ITEM_INIT, handleItemInit)
  yield takeLatest(a.bulkUploadVerify.REQUEST, handleUploadVerify)
  yield takeLatest(a.bulkUploadConfirm.REQUEST, handleUploadConfirm)
  yield takeLatest(c.TWO_FACTOR_AUTH_COMPLETE, handleBulkValidationAfterAuth)
}
