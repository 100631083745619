import React, { ReactNode } from 'react'

type FlowSwitchProps = {
  SuccessComponent: React.FC
  successComponentProps?: any // todo - was object
  formEl: ReactNode
  success?: boolean
  data?: any // todo - was object
}

const FlowSwitch = ({
  SuccessComponent,
  successComponentProps,
  formEl,
  success,
  data,
}: FlowSwitchProps) => (
  <div>{success ? <SuccessComponent data={data} {...successComponentProps} /> : formEl}</div>
)

export default FlowSwitch
