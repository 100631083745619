import { connect } from 'react-redux'
import moment from 'moment'
import get from 'lodash/get'
import StatementsView from '../../components/StatementsView'
import niceTableData from '../../utils/niceTableData'
import { getAccountOptions, getVisibleStatements, getTypeOptions } from './selectors'
import history from '../../history'
import * as a from './actions'

const tableFormat = {
  mapRowIdTo: 'id',
  cols: [
    {
      header: 'Account Name',
      mapTextTo: 'accountName',
      perc: 20,
    },
    {
      header: 'File',
      mapTextTo: 'title',
      perc: 50,
    },
    {
      header: 'Date',
      mapTextTo: 'date',
      perc: 10,
    },
  ],
  card: {
    mapTitleTo: 'accountName',
    mapTextTo: 'title',
    mapExtraTo: 'date',
  },
}

const year = moment().startOf('year')
const yearsSinceStart = year.diff('2015-01-01', 'years')

const yearNum = parseInt(year.format('YYYY'))

const yearOpts = [
  {
    value: '', // todo-ts : was false
    label: 'All',
  },
]

for (let i = yearNum; i > yearNum - yearsSinceStart - 1; i -= 1) {
  yearOpts.push({
    value: i.toString(),
    label: i.toString(),
  })
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.params.id ? ownProps.params.id : 'all'
  const items = getVisibleStatements(state, id)
  const accountOpts = getAccountOptions(state)
  const niceData = niceTableData(items, tableFormat)
  const typeOpts = getTypeOptions(state)
  return {
    cardForSmall: true,
    isFetching: state.statements.isFetching || state.statements.isFetchingAll,
    failedToFetch: state.statements.failedToFetch && state.statements.errorMessage,
    errorCode: state.statements.errorCode,
    hasFilters: ownProps.params.id || get(state, 'form.statementsFilters.values.year.value'),
    rows: niceData.body,
    headerCols: niceData.header,
    accountOpts,
    typeOpts,
    yearOpts,
    id,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAccountChange: (e) => {
    const path = e.value !== 'all' ? e.value : ''
    history.replace(`accounts/statements/${path}`)
  },
  onRowClick: (id) => {
    dispatch(a.statementDownloadRequest(id))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(StatementsView)
