import React from 'react'
import { reduxForm, InjectedFormProps } from 'redux-form'
import BottomError from '../Forms/BottomError'
import Modal from '../../containers/Modal'
import SubmitCancel from '../Forms/SubmitCancel'

type StopModalProps = {
  handleSubmit: () => void
  paymentsRecurringStop: () => void
  submitting?: boolean
  error?: string
  selectedPayment?: {
    recipientName: string
  }
  type: string
  typeName: string
}

const StopModal = ({
  handleSubmit,
  paymentsRecurringStop,
  error,
  submitting,
  selectedPayment,
  type,
  typeName,
}: InjectedFormProps<any> & StopModalProps) => {
  const recipient = selectedPayment && selectedPayment.recipientName
  const isSI = type === 'SI'
  const topText = isSI
    ? 'Are you sure you want to cancel Standing Order Instruction?'
    : `Cancelling the ${typeName} only cancels the payments, not the contract with the originator.`
  const bottomText = isSI ? (
    <p>
      This will stop all future payments to <strong>{recipient}</strong>.
    </p>
  ) : (
    <p>
      Are you sure you want to stop your payments to <strong>{recipient}</strong>
    </p>
  )

  return (
    <Modal id={`paymentsRecurringStop${type}`} size='medium' title={`Stop ${typeName}?`}>
      <form onSubmit={handleSubmit(paymentsRecurringStop)}>
        {selectedPayment && (
          <div>
            <p>{topText}</p>
            {bottomText}
          </div>
        )}
        <BottomError message={error} />
        <SubmitCancel
          submitText='Stop payment'
          cancelText='Go back'
          submitting={submitting}
          cancelClosesModal
        />
      </form>
    </Modal>
  )
}

const StopModalReduxForm = reduxForm<any, StopModalProps>({
  form: 'paymentsRecurringStop',
})(StopModal)

export default StopModalReduxForm
