import React from 'react'
import Icon from '../Icon'
import s from './styles.css'

const Pagination = () => (
  <nav>
    <ul className={s.list}>
      <li>
        <a className={s.disabled}>Previous</a>
      </li>
      <li>
        <a className={s.active}>1</a>
      </li>
      <li>
        <a>2</a>
      </li>
      <li>
        <a>3</a>
      </li>
      <li>
        <a>4</a>
      </li>
      <li>
        <a>Next</a>
      </li>
    </ul>

    <ul className={s.listMob}>
      <li>
        <a>
          <Icon icon='chevronDoubleLeft' />
        </a>
      </li>
      <li>
        <a>
          <Icon icon='chevronLeft' />
        </a>
      </li>
      <li className={s.currentPage}>Page 2</li>
      <li>
        <a>
          <Icon icon='chevronRight' />
        </a>
      </li>
    </ul>
  </nav>
)

export default Pagination
