import { connect } from 'react-redux'
import ActivityLogTypeView from '../../components/ActivityLogTypeView'

export const mapStateToProps = (state, ownProps) => ({
  isFetching: state.activityLog.type.isFetching,
  datePickerShowing: state.ui.panel === 'datePicker',
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLogTypeView)
