import { connect } from 'react-redux'
import Form from '../../components/PaymentsUpcomingEditView/Form'
import { getPaymentDetails, getFrequencyCode } from './selectors'

const mapStateToProps = (state, ownProps) => {
  const { amount, reference, frequency, nextPaymentDate, endDate } = getPaymentDetails(
    state,
    ownProps.id
  )
  const repeatUntil = endDate === '' ? 'forever' : 'endDate'
  return {
    initialValues: {
      amount: amount.slice(1),
      reference,
      frequency,
      date: nextPaymentDate,
      isRegular: true,
      when: 'later',
      frequencyCode: { value: getFrequencyCode(frequency) },
      laterDate: nextPaymentDate,
      repeatUntil: { value: repeatUntil },
      endDate: endDate === '' ? null : endDate.split(' ')[0],
    },
  }
}

const mapDispatchToProps = (dispatch) => ({
  handleSubmit(e) {
    console.log(e)
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Form)
