import { connect } from 'react-redux'
import SidebarLayout from '../../components/SidebarLayout'

export const mapStateToProps = (state) => ({
  navItems: [
    {
      to: 'secure-mail/f/inbox',
      title: 'Inbox',
    },
    {
      to: 'secure-mail/f/outbox',
      title: 'Sent messages',
    },
  ],
})

export default connect(mapStateToProps)(SidebarLayout)
