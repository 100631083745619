import moment from 'moment'
import formatMoney from '../formatMoney'

/**
 * Tidies deeply nested and poorly named loan data from API into something
 * easier to deal with!
 * @param  {Object} inputData - raw data from API
 * @return {Object} data - tidied data
 */
export interface TidyLoanDetailsD {
  data: {
    loanAccountDetails: {
      currencyshortname: string
      installmentarrear: number
      maturityDate: string
      openingDate: string
      approvedAmount: {
        amount: number
      }
      outstandingAmount: {
        amount: number
      }
      interestRate: number
      penaltyinterest: number
      accountNickname: string
      loanScheduleDTO: {
        nextDueDate: string
        nextInstallmentAmount: {
          amount: number
        }
        amountPaid: {
          amount: number
        }
        principalRepaymentFrequency: string
        interestRepaymentFrequency: string
        installementDueCount: number
      }
    }
  }
}
export default (inputData: TidyLoanDetailsD) => {
  const data: any = inputData.data.loanAccountDetails

  const curr = data.currencyshortname
  const loanSchedule = data.loanScheduleDTO
  const f1 = 'YYYY-MM-DD HH:mm:ss'
  const f2 = 'DD/MM/YYYY'

  const showPen = data.installmentarrear > 0

  return {
    maturityDate: moment(data.maturityDate, f1).startOf('day').format(f2),
    startDate: moment(data.openingDate, f1).startOf('day').format(f2),
    nextDate: moment(loanSchedule.nextDueDate, f1).startOf('day').format(f2),
    nextAmount: formatMoney(loanSchedule.nextInstallmentAmount.amount, curr),
    loanAmount: formatMoney(data.approvedAmount.amount, curr),
    outstandingAmount: `${formatMoney(data.outstandingAmount.amount, curr)} DR`,
    amountPaid: formatMoney(loanSchedule.amountPaid.amount, curr),
    principalBalance: '',
    accountName: data.accountNickname,
    instalmentArrears: '',
    interestRate: `${data.interestRate}%`,
    penalty: showPen ? formatMoney(data.penaltyinterest, curr) : '£0.00',
    principalRepaymentFrequency: loanSchedule.principalRepaymentFrequency,
    interestRepaymentFrequency: loanSchedule.interestRepaymentFrequency,
    remainingInstalments: loanSchedule.installementDueCount,
  }
}
