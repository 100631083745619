import React from 'react'
import TextJumpoffs from '../TextJumpoffs'
import s from './links.css'

const FirstLinks = () => (
  <div className={`${s.root} ${s.first}`}>
    <TextJumpoffs
      items={[
        {
          title: 'Deposit Accounts',
          bodyText:
            'We go above and beyond industry standards to ensure your money is safe and secure',
          linkText: 'Find out more',
          to: '/services/deposit',
          buttonClass: 'button',
          border: true,
          button: false,
          marginTop: true,
        },
        {
          title: 'Expatriate Services',
          bodyText: 'Specially designed for UK domiciled individuals who live abroad',
          linkText: 'Find out more',
          to: '/services/expatriate',
          buttonClass: 'button',
          border: true,
          button: false,
          marginTop: true,
        },
        {
          title: 'Investment & Wealth Advice',
          bodyText: 'Helping you achieve your full financial potential',
          linkText: 'Find out more',
          to: '/services/investmentwealth',
          button: false,
          border: true,
        },
      ]}
    />
  </div>
)

export default FirstLinks
