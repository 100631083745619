import * as c from '../constants'

let megaMenuTimer

// ------------------------------------
// Actions
// ------------------------------------
export function mobMenuToggle() {
  return {
    type: c.MOB_MENU_TOGGLE,
  }
}

export function mobMenuHide() {
  return {
    type: c.MOB_MENU_HIDE,
  }
}

export function mobSubMenuToggle() {
  return {
    type: c.MOB_SUB_MENU_TOGGLE,
  }
}

export function mobMenuShow() {
  return {
    type: c.MOB_MENU_SHOW,
  }
}

export function panelToggle(id) {
  return {
    type: c.PANEL_TOGGLE,
    payload: {
      id,
    },
  }
}

export function panelShow(id) {
  return {
    type: c.PANEL_SHOW,
    payload: {
      id,
    },
  }
}

// todo-ts: added id, it was passed by DataTable/index.tsx, but currently not used
export function panelHide(id?: any) {
  return {
    type: c.PANEL_HIDE,
  }
}

export function tooltipToggle(id) {
  return {
    type: c.TOOLTIP_TOGGLE,
    payload: {
      id,
    },
  }
}

export function tooltipShow(id) {
  return {
    type: c.TOOLTIP_SHOW,
    payload: {
      id,
    },
  }
}

export function tooltipHide() {
  return {
    type: c.TOOLTIP_HIDE,
  }
}

export function megaMenuShow(id) {
  clearTimeout(megaMenuTimer)

  return {
    type: c.MEGA_MENU_SHOW,
    payload: {
      id,
    },
  }
}

export function megaMenuHide() {
  return {
    type: c.MEGA_MENU_HIDE,
  }
}

export function modalShow(id) {
  return {
    type: c.MODAL_SHOW,
    payload: {
      id,
    },
  }
}

export function modalErrorShow(message) {
  return {
    type: c.MODAL_ERROR_SHOW,
    payload: {
      message,
    },
  }
}

export function modalHide() {
  return {
    type: c.MODAL_HIDE,
  }
}

export function setMisc(prop, value) {
  return {
    type: c.SET_MISC,
    payload: {
      prop,
      value,
    },
  }
}

export interface IUiState {
  mobMenuShowing: boolean
  mobSubMenuShowing: boolean
  megaMenu: boolean
  modal: boolean | string // todo
  panel: boolean
  tooltip: boolean
  misc: any
  modalErrorMessage?: string
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState: IUiState = {
  mobMenuShowing: false,
  mobSubMenuShowing: false,
  megaMenu: false,
  modal: false,
  panel: false,
  tooltip: false,
  misc: {},
}

export default function uiReducer(state = initialState, action): IUiState {
  const p = action.payload

  switch (action.type) {
    case c.MOB_MENU_TOGGLE:
      return {
        ...state,
        mobMenuShowing: !state.mobMenuShowing,
      }
    case c.MOB_MENU_HIDE:
      return {
        ...state,
        mobMenuShowing: false,
      }
    case c.MOB_MENU_SHOW:
      return {
        ...state,
        mobMenuShowing: true,
      }
    case c.MOB_SUB_MENU_TOGGLE:
      return {
        ...state,
        mobSubMenuShowing: !state.mobSubMenuShowing,
      }
    case c.PANEL_TOGGLE:
      return {
        ...state,
        panel: state.panel === p.id ? false : p.id,
      }
    case c.PANEL_HIDE:
      return {
        ...state,
        panel: false,
      }
    case c.PANEL_SHOW:
      return {
        ...state,
        panel: p.id,
      }
    case c.TOOLTIP_TOGGLE:
      return {
        ...state,
        tooltip: state.tooltip === p.id ? false : p.id,
      }
    case c.TOOLTIP_HIDE:
      return {
        ...state,
        tooltip: false,
      }
    case c.TOOLTIP_SHOW:
      return {
        ...state,
        tooltip: p.id,
      }
    case c.MEGA_MENU_HIDE:
      return {
        ...state,
        megaMenu: false,
      }
    case c.MEGA_MENU_SHOW:
      return {
        ...state,
        megaMenu: p.id,
      }
    case c.MODAL_SHOW:
      return {
        ...state,
        modal: p.id,
      }
    case c.MODAL_ERROR_SHOW:
      return {
        ...state,
        modal: 'genericError',
        modalErrorMessage: p.message,
      }
    case c.MODAL_HIDE:
      return {
        ...state,
        modal: false,
      }
    case c.LOCATION_CHANGE:
      return {
        ...state,
        modal: false,
        megaMenu: false,
        mobMenuShowing: false,
        panel: false,
      }
    case c.SET_MISC:
      return {
        ...state,
        misc: {
          ...state.misc,
          [p.prop]: p.value,
        },
      }
    default:
      return state
  }
}
