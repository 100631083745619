import React from 'react'
import s from './styles.css'
import Card from '../../../containers/Card'

type CardsProps = {
  rows: any[]
  onRowClick?: (v: any) => void // todo - any
  onActionClick?: () => void
  actions?: any[]
  rowLink?: (v: any) => void // todo - any
}

const Cards = ({ rows, onRowClick, actions, onActionClick, rowLink }: CardsProps) => (
  <div className={s.root}>
    {rows.map((row) => {
      let onClick
      if (onRowClick) {
        onClick = (e) => {
          e.preventDefault()
          return onRowClick(row.id)
        }
      }
      const cd = row.card

      return (
        <Card
          {...cd}
          to={cd.to || (rowLink && rowLink(row))}
          key={cd.id}
          onActionClick={onActionClick}
          onClick={onClick}
          actions={actions}
        />
      )
    })}
  </div>
)

export default Cards
