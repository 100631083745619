import React from 'react'
import s from './styles.css'
import Item from '../../containers/PayeesAlphabetItem'

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')

export const AToZ = () => (
  <nav className={s.root}>
    <ul className={s.list}>
      <li>
        <Item title='All' charSet={undefined} />
      </li>
      <li>
        <Item title='0-9' charSet='0123456789' />
      </li>

      {alphabet.map((letter) => (
        <li key={letter}>
          <Item title={letter} charSet={letter} />
        </li>
      ))}
    </ul>
  </nav>
)

export default AToZ
