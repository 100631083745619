import { call, put, takeEvery } from 'redux-saga/effects'
import * as c from '../../constants'
import request from '../../utils/request'
import { logout } from '../LogoutModal/actions'
import { formError } from '../../sagas/formErrors/actions'
import { handleLogout } from '../LogoutModal/sagas'

export function* handleMultiLogout(action) {
  try {
    const nonce0 = yield call(request, {
      id: 'requestNonceAtLogin',
      url: 'v1/session/nonce',
    })

    if (nonce0) {
      yield call(handleLogout, { payload: { url: 'https://www.weatherbys.bank/sessionexpired' } })
    }
  } catch (error) {
    yield put(formError(logout.FAILURE, error))
  }
}

export function* watchMultiLogout() {
  yield takeEvery(c.LOGOUT_MULTI_LOGIN_REQUEST, handleMultiLogout)
}
