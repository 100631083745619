import React from 'react'
import { reduxForm, Field, InjectedFormProps } from 'redux-form'
import moment from 'moment'
import s from './styles.css'
import SingleField from '../../Forms/SingleField'
import SuperDatePicker from '../../../containers/SuperDatePicker'
import Fields from '../../TransactionsFiltersForm/Fields'
import SubmitCancel from '../../Forms/SubmitCancel'
import { SupportedCurrenciesType } from '../../../models/enum'

type MobileFiltersProps = {
  initialStartDate: moment.Moment
  initialEndDate: moment.Moment
  onCancelClick: () => void
  handleSubmit: () => void
  currency: SupportedCurrenciesType
}

const MobileFilters = ({
  initialStartDate,
  initialEndDate,
  handleSubmit,
  onCancelClick,
  currency,
}: InjectedFormProps<any> & MobileFiltersProps) => (
  <form className={s.root} onSubmit={handleSubmit}>
    <fieldset>
      <legend>Keyword</legend>
      <Field
        label='Search'
        name='search'
        type='search'
        placeholder='Search for a transaction keyword (min 3 chars)'
        component={SingleField}
      />
    </fieldset>

    <fieldset>
      <legend>Dates</legend>
      <SuperDatePicker
        id='transactions'
        initialStartDate={initialStartDate}
        initialEndDate={initialEndDate}
        hideSubmit
      />
    </fieldset>

    <Fields currency={currency} />

    <footer className={s.footer}>
      <SubmitCancel submitText='Apply Filters' onCancelClick={onCancelClick} />
    </footer>
  </form>
)

const MobileFiltersReduxForm = reduxForm<any, MobileFiltersProps>({
  form: 'transactionsFilters',
  destroyOnUnmount: false,
  initialValues: {
    all: true,
  },
})(MobileFilters)

export default MobileFiltersReduxForm
