import { connect } from 'react-redux'
import SuperDatePickerField from '../../components/SuperDatePickerField'
import { panelToggle } from '../../store/ui'
import { superDatePickerCancel } from '../SuperDatePicker/actions'
import { getFieldText } from './selectors'

const mapStateToProps = (state, ownProps) => ({
  dateRangeText: getFieldText(state, ownProps.id),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDateClick: () => {
    dispatch(panelToggle('datePicker'))
    dispatch(superDatePickerCancel(ownProps.id))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(SuperDatePickerField)
