import React from 'react'
import Breadcrumbs from '../Breadcrumbs'
import BulletPoints from '../BulletPoints'
import ContactContainer from '../ContactContainer'
import InnerFullWidthContainer from '../InnerFullWidthContainer'
import ServicesHeader from '../ServicesHeader'
import s from '../ServicesView/styles.css'

const addPadding = true
const listStyle = true

export const ServicesInsurance = (/* { mappingError } */) => (
  <div>
    <Breadcrumbs
      items={[
        {
          to: '/services',
          text: 'Additional services',
        },
        {
          to: '/services/insurance',
          text: 'Insurance',
        },
      ]}
    />
    <ServicesHeader
      title='Comprehensive insurance for your personal possessions'
      secondaryTitle='Protecting your best assets'
    />
    <InnerFullWidthContainer addPadding={addPadding}>
      <div className={`${s.textArea} ${s.extraTopMargin}`}>
        <p>
          Many of our private clients have specialist insurance requirements. Weatherbys Hamilton
          provides tailored insurance to meet these unique needs, covering areas including:
        </p>
      </div>
      <BulletPoints
        listStyle={listStyle}
        items={[
          {
            bulletPoint: 'owner-occupied property',
          },
          {
            bulletPoint: 'buy-to-let property',
          },
          {
            bulletPoint: 'classic cars',
          },
          {
            bulletPoint: 'horses',
          },
        ]}
      />
      <div className={s.textArea}>
        <p>
          Our team can expertly handle your insurance affairs from start to finish and can discuss
          your requirements at a time and place that works for you.
        </p>
      </div>
    </InnerFullWidthContainer>
    <InnerFullWidthContainer addPadding={addPadding}>
      <ContactContainer
        title='Find out more'
        infoText='To find out more about our insurance services, please contact Clare Bethell'
        items={[
          {
            linkTitle: '+44 (0) 20 7292 9029',
            to: '+44 (0) 20 7292 9029',
            telNumber: true,
          },
          {
            linkTitle: 'Email Clare',
            to: 'cbethell@weatherbyshamilton.co.uk',
            email: true,
          },
          {
            linkTitle: 'Visit Website',
            to: 'www.weatherbyshamilton.co.uk/',
            external: true,
          },
        ]}
      />
    </InnerFullWidthContainer>
  </div>
)

export default ServicesInsurance
