import React, { Component } from 'react'
import LoginForm from '../../containers/LoginForm'
import ForgotPassword from '../../containers/ForgotPassword'
import s from './styles.css'

interface LoginViewProps {
  onKey: (e: any) => void
}

class LoginView extends Component<LoginViewProps> {
  root: HTMLDivElement

  constructor(props: LoginViewProps | Readonly<LoginViewProps>) {
    super(props)
    this.handleKeyUp = this.handleKeyUp.bind(this)
  }

  componentDidMount() {
    window.addEventListener('keyup', this.handleKeyUp)
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.handleKeyUp)
  }

  handleKeyUp(e: any) {
    this.props.onKey(e)
  }

  render() {
    return (
      <div
        className={s.root}
        ref={(div) => {
          this.root = div
        }}
      >
        <LoginForm />
        <ForgotPassword />
      </div>
    )
  }
}

export default LoginView
