import get from 'lodash/get'
import moment from 'moment'
import { decodeHTML } from '../../utils/decodeHTML'

/**
 * Tidies deeply nested and poorly named mail data from API into something
 * easier to deal with!
 * @param  {Object} data - raw data from API
 * @return {Object} data - tidied data
 */
export interface TidyBulletinDetail {
  data: {
    mailerUserMapDTO: {
      creationDate: string
      version: number
      generatedPackageId: boolean
      auditSequence: number
      subject: string
      messageBody: string
    }
  }
}
export default (data: TidyBulletinDetail, state, request?: any) => {
  const tidyData: {
    detail?: any
  } = {}

  const message = get(data, 'data.mailerUserMapDTO', [])

  tidyData.detail = {
    id: String(message.version),
    subject: decodeHTML(message.subject),
    subjectCode: message.subject,
    isRead: true,
    date: moment(message.creationDate, 'YYYY-MM-DD HH:mm:ss').format('dddd, Do MMMM YYYY - HH:mm'),
    body: decodeHTML(message.messageBody),
    folderName: 'bulletins',
  }

  return tidyData
}
