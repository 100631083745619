/* eslint-disable react/prefer-stateless-function */
import React from 'react'
import { InjectedFormProps, reduxForm } from 'redux-form'
import { PAYEE_FLOW_CONFIRMATION_OF_PAYEE_SUBMIT } from '../../constants'
import ConfirmationOfPayee from '../../containers/ConfirmationOfPayee'
import { ConfirmationOfPayeeStatus } from '../../containers/ConfirmationOfPayee/reducer'
import PayeeFields from '../../containers/PayeeFields'
import * as v from '../../utils/forms/validators'
import Button from '../Button'
import BottomError from '../Forms/BottomError'
import InputWithTooltip from '../Forms/InputWithTooltip'
import Select from '../Forms/Select'
import SingleField from '../Forms/SingleField'
import s from './styles.css'

type PayeeNewFormProps = {
  handleSubmit?: () => void
  confirmPayee?: () => void
  error?: string
  selectOptions?: any[]
  copStatus: ConfirmationOfPayeeStatus
  onCopFlowReset: () => void
  copLoading: boolean
}

const PayeeNewForm = class PayeeNewForm extends React.Component<
  InjectedFormProps<any> & PayeeNewFormProps
> {
  componentDidMount(): void {
    this.props.onCopFlowReset()
  }

  render() {
    const { handleSubmit, confirmPayee, error, selectOptions, copStatus, copLoading } = this.props
    const showForm = copStatus === ConfirmationOfPayeeStatus.NOT_STARTED

    return (
      <div>
        <form
          onSubmit={handleSubmit(confirmPayee)}
          style={{
            display: showForm ? 'block' : 'none',
          }}
        >
          <fieldset className='narrow'>
            <InputWithTooltip
              tooltip={{
                id: 'newPayeeEntity',
                text: 'Each payee is directly tied to an entity',
              }}
              field={{
                name: 'entity',
                label: 'Entity',
                component: SingleField,
                InputComponent: Select,
                inputComponentProps: {
                  options: selectOptions,
                },
                validate: v.required,
                validationMessages: {
                  required: 'Please select an entity',
                },
              }}
            />
            <PayeeFields formName='payeeNew' error={error} />
            <BottomError message={error} />
            <Button loading={copLoading} submit text='Check Details' />
            <button
              type='button'
              className={s.formActionButton}
              onClick={() => {
                window.location.href = '#/payments/payees'
              }}
            >
              Cancel
            </button>
          </fieldset>
        </form>
        {!showForm && (
          <ConfirmationOfPayee
            formName='payeeNew'
            exitCopAction={PAYEE_FLOW_CONFIRMATION_OF_PAYEE_SUBMIT}
            cancelCopLocation='#/payments/payees'
          />
        )}
      </div>
    )
  }
}

const PayeeNewReduxForm = reduxForm<any, PayeeNewFormProps>({
  form: 'payeeNew',
})(PayeeNewForm)

export default PayeeNewReduxForm
