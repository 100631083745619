import React from 'react'
import s from './styles.css'
import Breadcrumbs from '../Breadcrumbs'
import InnerContainer from '../InnerContainer'
import HeadingWithPrint from '../HeadingWithPrint'
import Details from '../../containers/ActivityLogSingle/details'
import Notes from '../../containers/ActivityLogSingle/notes'
import ResultsInfo from '../ResultsInfo'
import Modal from '../../containers/ModalReceipt'
import Stripey from '../Stripey'

type ActivityLogSingleViewProps = {
  onPrintClick: () => void
  isLoading?: boolean
  showReceipt?: boolean
  saveReceiptClick?: () => void
  saveReceiptModal?: () => void
}

const ActivityLogSingleView = ({
  onPrintClick,
  isLoading,
  showReceipt,
  saveReceiptClick,
  saveReceiptModal,
  ...props
}: ActivityLogSingleViewProps) => (
  <div>
    <Breadcrumbs
      items={[
        {
          to: '/settings',
          text: 'Details & settings',
        },
        {
          to: '/settings/activity-log',
          text: 'Activity log',
        },
      ]}
    />
    <InnerContainer>
      <HeadingWithPrint
        title='Activity details'
        onPrintClick={onPrintClick}
        showReceipt={showReceipt}
        onReceiptClick={() => {
          saveReceiptClick()
          saveReceiptModal()
        }}
      />
      {isLoading ? (
        <ResultsInfo state={{ state: 'loading' }} />
      ) : (
        <div>
          <Details {...props} />
          <Stripey />
          <Notes />
        </div>
      )}
    </InnerContainer>
    <Modal id='eReceipt' className={s.modal} size='small' />
  </div>
)

export default ActivityLogSingleView
