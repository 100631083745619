import React from 'react'
import DataTable from '../../containers/DataTable'
import SuperDatePicker from '../../containers/SuperDatePicker'
import SuperDatePickerField from '../../containers/SuperDatePickerField'
import SlideyOrModal from '../../containers/SlideyOrModal'
import FilterPanel from '../../containers/FilterPanel'
import ResultsInfo from '../ResultsInfo'
import s from './styles.css'

type ActivityLogTypeListProps = {
  onDateSubmit: () => void
  isFetching?: boolean
  showCustomResultsState?: boolean
}

const ActivityLogTypeList = ({
  isFetching,
  onDateSubmit,
  showCustomResultsState,
  ...props
}: ActivityLogTypeListProps) => (
  <div className={s.activityLogTypeList}>
    <FilterPanel>
      <SuperDatePickerField id='activityLogType' />
      <SlideyOrModal id='datePicker'>
        <SuperDatePicker id='activityLogType' onSubmit={onDateSubmit} className={s.datePicker} />
      </SlideyOrModal>
    </FilterPanel>
    {isFetching ? (
      <ResultsInfo state={{ state: 'loading' }} />
    ) : (
      <DataTable
        {...props}
        customResultsState={showCustomResultsState && { state: 'noResultsFilter' }}
      />
    )}
  </div>
)

export default ActivityLogTypeList
