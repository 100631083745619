import React from 'react'
import Strip from '../Strip'
import Button from '../Button'
import s from './styles.css'

type AuthorisationsBoxProps = {
  isVisible: boolean
  count: number
}

const AuthorisationsBox = ({ isVisible, count }: AuthorisationsBoxProps) => (
  <div>
    {isVisible && (
      <Strip className={s.strip}>
        <p>
          You have {count} authorisation{count > 1 && 's'} pending. To authorise{' '}
          {count > 1 ? 'them' : 'it'}, go to your authorisation log.
        </p>
        <Button className={s.button} text='Authorisation Log' to='/settings/authorisations' />
      </Strip>
    )}
  </div>
)

export default AuthorisationsBox
