export const typeCodesByCode = {
  CBR: 'Cheque Book Request',
  PC_F_SFT: 'My Account Transfer',
  SIC: 'Standing Instruction Cancellation',
  VPU: 'Bulk Domestic Transfer',
  VSI: 'Standing Orders and Direct Debits',
  PBR: 'Pay-In Book Request',
  PC_F_DOM: 'Make a Payment',
  PTC: 'Cancel Future Dated Transfers',
}

export const typeCodesByDesc = {
  'Cheque Book Request': 'CBR',
  'My Account Transfer': 'OAT',
  'Standing Instruction Cancellation': 'SIC',
  'Bulk Domestic Transfer': 'VPU',
  'Standing Orders and Direct Debits': 'VSI',
  'Pay-In Book Request': 'PBR',
  'Make a Payment': 'PPT',
  'Cancel Future Dated Transfer': 'PTC',
}

export const typeCodesByCodeNewNames = {
  CBR: 'Cheque Book Request',
  PC_F_SFT: 'Internal Transfer',
  PC_N_DOP: 'Create a payee',
  PC_N_UDOP: 'Edit a payee',
  PC_N_DDP: 'Delete a payee',
  SIC: 'Standing Instruction Cancellation',
  FU_F_DFT: 'Bulk Domestic Transfer',
  VSI: 'Standing Orders and Direct Debits',
  PBR: 'Pay-In Book Request',
  PC_F_DFT: 'Make a Payment',
  PTC: 'Cancel Future Dated Transfers',
}
