import React from 'react'
import ResultsInfo from '../ResultsInfo'
import s from './styles.css'
import MessageList from '../MessageList'
import { ResultsInfoDateState } from '../../models/types'

type MessageGroupProps = {
  lists?: {
    title?: string
    messages?: any[]
  }[]
  state?: ResultsInfoDateState // todo - was string, changed to match ResultsInfo state type
}

const MessageGroup = ({ lists, state }: MessageGroupProps) =>
  lists ? (
    <ul className={s.root}>
      {lists.map((list, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li className={s.list} key={index}>
          <h2>{list.title}</h2>
          <MessageList items={list.messages} />
        </li>
      ))}
    </ul>
  ) : (
    <div className={s.results}>
      <ResultsInfo state={{ state }} />
    </div>
  )

export default MessageGroup
