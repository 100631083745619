import get from 'lodash/get'
import { getFormValues } from 'redux-form'

// eslint-disable-next-line import/prefer-default-export
export const getIsFiltered = (state) => {
  const formValues = getFormValues('payeeFilters')(state)
  const alphabet = get(state, 'payees.filters.alphabet')
  const searchTerm = get(formValues, 'search')

  return alphabet || (searchTerm && searchTerm !== '')
}
