import { connect } from 'react-redux'
import PaymentsBulkConfirmView from '../../components/PaymentsBulkConfirmView'
import Success from './Success'
import { flowReset } from '../../store/flow'
import { modalHide, modalShow } from '../../store/ui'
import { isAndroid } from '../../utils/getPlatform'
import getFlowData from '../../selectors/getFlowData'
import getAccount from '../../selectors/getAccount'
import formatMoney from '../../utils/formatMoney'
import { bulkUploadConfirm } from '../PaymentsBulk/actions'
import { saveeReceipt, showWeekendWarning } from '../PaymentsBulk/selectors'

const mapStateToProps = (state) => {
  const data = getFlowData(state)
  const account = getAccount(state, data.accountId)

  const details = {
    title: account.title,
    accountNumber: `${account.sortCode} ${account.accountNumber}`,
    balance: `Available balance: ${account.availableBalance}`,
    date: data.date,
    payments: data.numRows,
    amount: formatMoney(data.totalAmount, account.currency),
  }

  return {
    title: 'Payment details',
    authTitle: 'Authorise bulk payment',
    cancelLink: '/payments',
    SuccessComponent: Success,
    disableCancel: data.disableCancel,
    onReceiptClick: () => saveeReceipt(details),
    printIsVisible: state.twoFactorAuth ? state.twoFactorAuth.success : false,
    warnings: showWeekendWarning(state) && [
      'Your payment will not arrive at the beneficiary bank until tomorrow.',
      'The payment cannot be recalled after you pressed confirm.',
    ],
    details: [
      {
        descriptor: 'From',
        title: details.title,
        extras: [details.accountNumber, details.balance],
      },
      {
        descriptor: 'Date',
        title: details.date,
      },
      {
        descriptor: 'Payments',
        title: details.payments,
      },
      {
        descriptor: 'Amount',
        title: details.amount,
      },
    ],
  }
}

const mapDispatchToProps = (dispatch) => ({
  formSubmit: bulkUploadConfirm,
  onGoBackClick: (e) => {
    e.preventDefault()
    dispatch(modalHide())
    dispatch(flowReset())
  },
  onReceiptModal() {
    if (isAndroid()) {
      dispatch(modalShow('eReceipt'))
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsBulkConfirmView)
