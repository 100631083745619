import React from 'react'
import Breadcrumbs from '../Breadcrumbs'
import PaymentsFuture from '../../containers/PaymentsFuture'
import PaymentsRecurring from '../../containers/PaymentsRecurring'
import PaymentsUpcomingClosed from '../../containers/PaymentsUpcomingClosed'

type PaymentsUpcomingViewProps = {
  mappingList: any // todo - was object
}

export const PaymentsUpcomingView = ({ mappingList }: PaymentsUpcomingViewProps) => (
  <div>
    <Breadcrumbs
      items={[
        {
          to: '/payments',
          text: 'Payments & transfers',
        },
        {
          to: '/payments/upcoming',
          text: 'Standing orders & direct debits',
        },
      ]}
    />
    <PaymentsFuture />
    {mappingList.SIandDD && <PaymentsRecurring />}
    <PaymentsUpcomingClosed />
  </div>
)

export default PaymentsUpcomingView
