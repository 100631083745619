import { Dispatch } from 'redux'
import { change, stopSubmit } from 'redux-form'
import {
  COP_ACCOUNT_HAS_BEEN_SWITCHED_DESCRIPTION,
  COP_ACCOUNT_HAS_BEEN_SWITCHED_HEADER,
  COP_ACCOUNT_NAME_DOES_NOT_MATCH_DESCRIPTION,
  COP_ACCOUNT_NAME_DOES_NOT_MATCH_HEADER,
  COP_ACCOUNT_TYPE_ON_RECORD_LABEL,
  COP_ACCOUNT_TYPE_YOU_ENTERED_LABEL,
  COP_CANCEL,
  COP_CONTINUE,
  COP_CONTINUE_ANYWAY,
  COP_DETAILS_DO_NOT_MATCH_DESCRIPTION,
  COP_DETAILS_DO_NOT_MATCH_HEADER,
  COP_EDIT_DETAILS,
  COP_NAME_ON_ACCOUNT_LABEL,
  COP_NO_ACCOUNT_AND_REFERENCE_FOUND_DESCRIPTION,
  COP_NO_ACCOUNT_FOUND_HEADER,
  COP_NO_REFERENCE_FOUND,
  COP_NO_REFERENCE_FOUND_DESCRIPTION,
  COP_PROCEED_WITHOUT_UPDATING,
  COP_UNABLE_TO_CHECK_ACCOUNT_DESCRIPTION,
  COP_UNABLE_TO_CHECK_ACCOUNT_HEADER,
  COP_UNABLE_TO_CHECK_ACCOUNT_SECOND_SUB_DESCRIPTION,
  COP_UNABLE_TO_CHECK_ACCOUNT_SUB_DESCRIPTION,
  COP_UPDATE_DETAILS_AND_PROCEED,
  COP_YOU_ENTERED_LABEL,
} from '../../constants'
import { modalHide, modalShow } from '../../store/ui'
import { copReset, copSuccess, copSuccessBypass } from './actions'
import {
  ConfirmationOfPayeeInvalidTypes,
  IErrorButtonActions,
  IErrorButtonLabels,
  IErrorDetail,
  IMessageDetail,
} from './reducer'

export function findHeaderAndDescription(reasonCode: string): IMessageDetail {
  switch (reasonCode) {
    case 'MBAM':
    case 'BAMM':
    case 'BANM':
    case 'PANM':
    case 'PAMM':
      return {
        header: COP_DETAILS_DO_NOT_MATCH_HEADER,
        subHeaders: [COP_DETAILS_DO_NOT_MATCH_DESCRIPTION],
      }
    case 'AC01':
      return {
        header: COP_NO_ACCOUNT_FOUND_HEADER,
        subHeaders: [COP_NO_ACCOUNT_AND_REFERENCE_FOUND_DESCRIPTION],
      }
    case 'IVCR':
      return {
        header: COP_NO_REFERENCE_FOUND,
        subHeaders: [COP_NO_REFERENCE_FOUND_DESCRIPTION],
      }
    case 'CASS':
      return {
        header: COP_ACCOUNT_HAS_BEEN_SWITCHED_HEADER,
        subHeaders: [COP_ACCOUNT_HAS_BEEN_SWITCHED_DESCRIPTION],
      }
    case 'ANNM':
      return {
        header: COP_ACCOUNT_NAME_DOES_NOT_MATCH_HEADER,
        subHeaders: [COP_ACCOUNT_NAME_DOES_NOT_MATCH_DESCRIPTION],
      }
    case 'SCNS':
    case 'ACNS':
    case 'OPTO':
      return {
        header: COP_UNABLE_TO_CHECK_ACCOUNT_HEADER,
        subHeaders: [
          COP_UNABLE_TO_CHECK_ACCOUNT_DESCRIPTION,
          COP_UNABLE_TO_CHECK_ACCOUNT_SUB_DESCRIPTION,
        ],
      }
    default:
      return {
        header: COP_UNABLE_TO_CHECK_ACCOUNT_HEADER,
        subHeaders: [
          COP_UNABLE_TO_CHECK_ACCOUNT_DESCRIPTION,
          COP_UNABLE_TO_CHECK_ACCOUNT_SECOND_SUB_DESCRIPTION,
        ],
      }
  }
}

export function prepareErrorState(
  reasonCode: string,
  responseName: string | undefined,
  accountType: string
): IErrorDetail {
  const invertedAccountType = accountType === 'Personal' ? 'Business' : 'Personal'
  switch (reasonCode) {
    case 'MBAM':
      return {
        errorDetails: [{ type: ConfirmationOfPayeeInvalidTypes.NAME, value: responseName }],
      }
    case 'BAMM':
    case 'PAMM':
      return {
        errorDetails: [
          { type: ConfirmationOfPayeeInvalidTypes.NAME, value: responseName },
          { type: ConfirmationOfPayeeInvalidTypes.ACCOUNT_TYPE, value: invertedAccountType },
        ],
      }
    case 'BANM':
    case 'PANM':
      return {
        errorDetails: [
          { type: ConfirmationOfPayeeInvalidTypes.ACCOUNT_TYPE, value: invertedAccountType },
        ],
      }
    default:
      return {}
  }
}

export function prepareHumanErrorState(
  reasonCode: string,
  errorType: ConfirmationOfPayeeInvalidTypes,
  formFieldValue: string,
  expectedValue?: string
) {
  switch (reasonCode) {
    case 'MBAM':
      return [
        { label: COP_YOU_ENTERED_LABEL, value: formFieldValue, type: 'Error' },
        { label: COP_NAME_ON_ACCOUNT_LABEL, value: expectedValue, type: 'Success' },
      ]

    case 'BAMM':
    case 'PAMM': {
      if (errorType === ConfirmationOfPayeeInvalidTypes.NAME)
        return [
          { label: COP_YOU_ENTERED_LABEL, value: formFieldValue, type: 'Error' },
          { label: COP_NAME_ON_ACCOUNT_LABEL, value: expectedValue, type: 'Success' },
        ]
      return [
        { label: COP_ACCOUNT_TYPE_YOU_ENTERED_LABEL, value: formFieldValue, type: 'Error' },
        { label: COP_ACCOUNT_TYPE_ON_RECORD_LABEL, value: expectedValue, type: 'Success' },
      ]
    }
    case 'BANM':
    case 'PANM':
      return [
        { label: COP_ACCOUNT_TYPE_YOU_ENTERED_LABEL, value: formFieldValue, type: 'Error' },
        { label: COP_ACCOUNT_TYPE_ON_RECORD_LABEL, value: expectedValue, type: 'Success' },
      ]
    default:
      return []
  }
}

function prepareErrorButtonLabels(reasonCode: string): IErrorButtonLabels {
  switch (reasonCode) {
    case 'MBAM':
    case 'BAMM':
    case 'PAMM':
    case 'BANM':
    case 'PANM':
      return {
        primaryButtonLabel: COP_UPDATE_DETAILS_AND_PROCEED,
        secondaryButtonLabel: COP_PROCEED_WITHOUT_UPDATING,
        tertiaryButtonLabel: COP_CANCEL,
      }
    case 'AC01':
      return {
        primaryButtonLabel: COP_EDIT_DETAILS,
        secondaryButtonLabel: COP_CANCEL.toUpperCase(),
      }

    case 'IVCR':
    case 'CASS':
    case 'ANNM':
      return {
        primaryButtonLabel: COP_EDIT_DETAILS,
        secondaryButtonLabel: COP_CANCEL.toUpperCase(),
        tertiaryButtonLabel: COP_CONTINUE_ANYWAY,
      }
    case 'SCNS':
    case 'ACNS':
    case 'OPTO':
      return {
        primaryButtonLabel: COP_CONTINUE,
        secondaryButtonLabel: COP_EDIT_DETAILS,
        tertiaryButtonLabel: COP_CANCEL,
      }
    default:
      return {
        primaryButtonLabel: COP_CANCEL.toLocaleUpperCase(),
        secondaryButtonLabel: COP_EDIT_DETAILS,
        tertiaryButtonLabel: COP_CONTINUE_ANYWAY,
      }
  }
}

export function prepareErrorButtons(
  formName: string,
  reasonCode: string,
  expectedData: Array<{ type: ConfirmationOfPayeeInvalidTypes; value: string }> | undefined,
  exitCopAction: string,
  cancelCopLocation: string,
  dispatch: Dispatch
): IErrorButtonActions {
  const buttonLabels = prepareErrorButtonLabels(reasonCode)
  switch (reasonCode) {
    case 'MBAM':
    case 'BAMM':
    case 'PAMM':
    case 'BANM':
    case 'PANM':
      return {
        primaryButton: {
          label: buttonLabels.primaryButtonLabel,
          onClick: (e) => {
            e.preventDefault()
            expectedData.forEach(({ type, value }) => {
              dispatch(change(formName, type, value))
            })
            dispatch(copSuccess())
            dispatch({ type: exitCopAction })
          },
        },

        secondaryButton: {
          label: buttonLabels.secondaryButtonLabel,
          onClick: (e) => {
            e.preventDefault()

            dispatch(modalShow(`confirmContinueAnyway`))
          },
        },
        tertiaryButton: {
          label: buttonLabels.tertiaryButtonLabel,
          onClick: (e) => {
            e.preventDefault()

            dispatch(stopSubmit(formName))
            window.location.href = cancelCopLocation
          },
        },

        modalPrimaryButton: {
          label: COP_CONTINUE_ANYWAY,
          onClick: (e) => {
            e.preventDefault()
            dispatch(copSuccessBypass())
            dispatch(modalHide())

            dispatch({ type: exitCopAction })
          },
        },
        modalSecondaryButton: {
          label: COP_CANCEL,
          onClick: (e) => {
            e.preventDefault()
            dispatch(stopSubmit(formName))
            dispatch(modalHide())

            window.location.href = cancelCopLocation
          },
        },
      }

    case 'AC01':
      return {
        primaryButton: {
          label: buttonLabels.primaryButtonLabel,
          onClick: (e) => {
            e.preventDefault()

            dispatch(stopSubmit(formName))
            dispatch(copReset())
          },
        },
        secondaryButton: {
          label: buttonLabels.secondaryButtonLabel,
          onClick: (e) => {
            e.preventDefault()
            window.location.href = cancelCopLocation
          },
        },
      }

    case 'IVCR':
    case 'CASS':
    case 'ANNM':
      return {
        primaryButton: {
          label: buttonLabels.primaryButtonLabel,
          onClick: (e) => {
            e.preventDefault()

            dispatch(stopSubmit(formName))
            dispatch(copReset())
          },
        },
        secondaryButton: {
          label: buttonLabels.secondaryButtonLabel,
          onClick: (e) => {
            e.preventDefault()
            window.location.href = cancelCopLocation
          },
        },
        tertiaryButton: {
          label: buttonLabels.tertiaryButtonLabel,
          onClick: (e) => {
            e.preventDefault()

            dispatch(modalShow(`confirmContinueAnyway`))
          },
        },
        modalPrimaryButton: {
          label: COP_CONTINUE_ANYWAY,
          onClick: (e) => {
            e.preventDefault()
            dispatch(copSuccessBypass())
            dispatch(modalHide())

            dispatch({ type: exitCopAction })
          },
        },
        modalSecondaryButton: {
          label: COP_CANCEL,
          onClick: (e) => {
            e.preventDefault()
            dispatch(stopSubmit(formName))
            dispatch(modalHide())
            window.location.href = cancelCopLocation
          },
        },
      }

    case 'SCNS':
    case 'ACNS':
    case 'OPTO':
      return {
        primaryButton: {
          label: buttonLabels.primaryButtonLabel,
          onClick: (e) => {
            e.preventDefault()

            dispatch(modalShow(`confirmContinueAnyway`))
          },
        },
        secondaryButton: {
          label: buttonLabels.secondaryButtonLabel,
          onClick: (e) => {
            e.preventDefault()

            dispatch(stopSubmit(formName))
            dispatch(copReset())
          },
        },
        tertiaryButton: {
          label: buttonLabels.tertiaryButtonLabel,
          onClick: (e) => {
            e.preventDefault()
            window.location.href = cancelCopLocation
          },
        },

        modalPrimaryButton: {
          label: COP_CONTINUE_ANYWAY,
          onClick: (e) => {
            e.preventDefault()
            dispatch(copSuccessBypass())
            dispatch(modalHide())

            dispatch({ type: exitCopAction })
          },
        },
        modalSecondaryButton: {
          label: COP_CANCEL,
          onClick: (e) => {
            e.preventDefault()
            dispatch(stopSubmit(formName))
            dispatch(modalHide())
            window.location.href = cancelCopLocation
          },
        },
      }
    default:
      return {
        primaryButton: {
          label: buttonLabels.primaryButtonLabel,
          onClick: (e) => {
            e.preventDefault()
            window.location.href = cancelCopLocation
          },
        },
        secondaryButton: {
          label: buttonLabels.secondaryButtonLabel,
          onClick: (e) => {
            e.preventDefault()

            dispatch(stopSubmit(formName))
            dispatch(copReset())
          },
        },
        tertiaryButton: {
          label: buttonLabels.tertiaryButtonLabel,
          onClick: (e) => {
            e.preventDefault()

            dispatch(modalShow(`confirmContinueAnyway`))
          },
        },

        modalPrimaryButton: {
          label: COP_CONTINUE_ANYWAY,
          onClick: (e) => {
            e.preventDefault()
            dispatch(copSuccessBypass())
            dispatch(modalHide())

            dispatch({ type: exitCopAction })
          },
        },
        modalSecondaryButton: {
          label: COP_CANCEL,
          onClick: (e) => {
            e.preventDefault()
            dispatch(stopSubmit(formName))
            dispatch(modalHide())
            window.location.href = cancelCopLocation
          },
        },
      }
  }
}
