import { call, put } from 'redux-saga/effects'
import request from '../../utils/request'
import humanError from '../../utils/humanError'
import * as c from '../../constants'
import {
  listUpdateAll,
  listFetchFail,
  listFetchStart,
} from '../../reducerCreators/listReducer/actions'

export default (listName, req) =>
  function* updateList(action) {
    try {
      yield put(listFetchStart(listName))
      const nonce = yield call(request, {
        id: c.requestNonce,
        url: 'v1/session/nonce',
      })
      const response = yield call(request, {
        id: req.id,
        url: req.url,
        nonce,
      })
      yield put(listUpdateAll(listName, response.items))
    } catch (error) {
      const message = yield call(humanError, error)
      yield put(listFetchFail(listName, message))
    }
  }
