export default (store) => ({
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const ServicesMortgageLoans = require('../containers/ServicesMortgageLoans').default
        cb(null, ServicesMortgageLoans)
      },
      'mortgageloans'
    )
  },
})
