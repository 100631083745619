import { getFormValues, stopSubmit } from 'redux-form'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  PAYEE_FLOW_CONFIRMATION_OF_PAYEE_SUBMIT,
  TWO_FACTOR_AUTH_COMPLETE,
  requestNonce,
  requestPayeeNew,
  requestPayeeNewInit,
  requestPayeeNewInit2,
  requestPayeeNewInit3,
} from '../../constants'
import { formError } from '../../sagas/formErrors/actions'
import { flowSuccess } from '../../store/flow'
import request from '../../utils/request'
import { confirmPayee, copReset, copLoading } from '../ConfirmationOfPayee/actions'
import { paymentMakeNewPayeeDetailsClear } from '../PaymentMake/actions'
import { twoFactorAuthSetInitialRequest, twoFactorAuthSetRef } from '../TwoFactorAuth/actions'
// import GA from '../../utils/GA'
import { modalHide } from '../../store/ui'
import { getPartyId } from '../../sagas/cancelUpcomingPayment/selectors'

export function* handlePayeeNewSubmit() {
  try {
    const selector = yield call(getFormValues, 'payeeNew')
    const p = yield select(selector)
    yield put(paymentMakeNewPayeeDetailsClear())
    yield put(twoFactorAuthSetInitialRequest('payeeCreate'))
    let nonce = yield call(request, {
      id: requestNonce,
      url: 'v1/session/nonce',
    })

    const details = yield call(request, {
      id: requestPayeeNewInit,
      url: 'cz/v1/payments/payeeGroup?locale=en',
      partyId: p.entity.value,
      nonce,
      payload: { ...p },
    })

    nonce = yield call(request, {
      id: requestNonce,
      url: 'v1/session/nonce',
    })

    const copApiResponse = yield select((state) => state.cop.apiResponse)
    console.log('copApiResponse', copApiResponse)
    const details1 = yield call(request, {
      id: requestPayeeNewInit2,
      url: `cz/v1/payments/payeeGroup/${details}/payees/external?locale=en`,
      partyId: p.entity.value,
      nonce,
      payload: { ...p, copApiResponse },
    })

    nonce = yield call(request, {
      id: requestNonce,
      url: 'v1/session/nonce',
    })

    const bankDetails = yield select((state) => state.paymentMake.newPayeeDetails)

    yield call(request, {
      id: requestPayeeNewInit3,
      url: `cz/v1/payments/payeeGroup/${details}/payees/external/${details1.id}?locale=en`,
      partyId: p.entity.value,
      nonce,
      payload: { stage: 2, ...p, ...bankDetails },
    })

    nonce = yield call(request, {
      id: requestNonce,
      url: 'v1/session/nonce',
    })

    const res = yield call(request, {
      id: requestPayeeNew,
      url: `cz/v1/payments/payeeGroup/${details}/payees/external/${details1.id}?locale=en`,
      nonce,
      partyId: p.entity.value,
      payload: { stage: 2, ...p, ...bankDetails },
    })

    const payeeDetails = {
      refNumber: res.ref,
      groupId: details,
      payeeId: details1.id,
      partyId: p.entity.value,
    }

    yield put(modalHide())

    yield put(twoFactorAuthSetRef(payeeDetails))
    yield put(flowSuccess(p))

    // #GA4-PROVISIONALLY-DISABLED
    // GA.event({
    //   category: 'Form',
    //   action: 'Submit',
    //   label: 'Add New Payee',
    // })
  } catch (error) {
    yield put(copReset())
    yield put(formError(confirmPayee.FAILURE, error))
    yield put(stopSubmit('payeeNew'))
    yield put(modalHide())
  }
}

// eslint-disable-next-line require-yield
export function* handlePayeeAfterAuth(action: any) {
  try {
    const { payload: p } = action
    const { href } = window.location
    if (!href.includes('payments/payees/new')) {
      return
    }
  } catch (error) {
    console.error(error)
  }
}

export function* watchPayeeNew() {
  yield takeLatest(PAYEE_FLOW_CONFIRMATION_OF_PAYEE_SUBMIT, handlePayeeNewSubmit)
  yield takeLatest(TWO_FACTOR_AUTH_COMPLETE, handlePayeeAfterAuth)
}
