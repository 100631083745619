/* eslint-disable */
// @ts-nocheck
// todo - eslint and typescript
import axios from 'axios'
import tidyResponse from '../tidyResponse'
import tidyNonce from '../tidyResponse/tidyNonce'
import constructRequest from './constructRequest'
import goOut from '../goOut'
import { decodeBlockedText, decodeWaf } from '../decodeHTML'
import APIError from '../APIError'
import { isTrue } from '../common'
const isProd = isTrue(process.env.__PROD__)
const url = process.env.__SERVICEURL__
const mode = process.env.__REQUEST_CONFIG_MODE__
const credentials = process.env.__REQUEST_CONFIG_CREDENTIALS__
const withCredentials = isProd
/**
 * Abstraction layer for interacting with the API
 * to make requests with a simple object
 * @param  {object} request - object with details of request.
 * @param {string} request.id should contain one of the request constants (e.g. requestAccounts)
 * @return {promise} - Returns the JSON data, clean and ready to go!
 */
export default (request: {
  nonce?: string
  partyId?: string | number
  multiapproval?: string | boolean
  targetUnit?: string | number
  xChallenge?: string
  url?: string
  payload?: {
    [key: string]: any
  }
  id?: string
  [key: string]: any //  putting any here as keys  are different for different requests and lets not take any risk here
}) => {
  const { nonce } = request
  const partyId = request.partyId ? request.partyId : ''
  const { xChallenge } = request
  const { multiapproval } = request
  const { targetUnit } = request
  const headers = {
    Accept: '*/*',
    'Content-type': constructRequest.build(request).contentType,
  }
  if (nonce) {
    headers['x-nonce'] = nonce
    if (partyId) {
      headers['X-Party-Id'] = partyId
    }
    if (xChallenge) {
      headers['X-CHALLENGE_RESPONSE'] = JSON.stringify(xChallenge)
    }
    if (multiapproval) {
      headers['X-MULTIAPPROVAL'] = true
    }
    if (targetUnit) {
      headers['X-Target-Unit'] = targetUnit
    }
  }
  return Promise.race([
    axios({
      baseURL: url,
      url: request.url,
      headers,
      method: constructRequest.build(request).method,
      withCredentials,
      mode,
      credentials,
      data: constructRequest.build(request).body,
    }),
    new Promise((resolve, reject) => {
      setTimeout(() => reject(new Error('request_timeout')), 120000)
    }),
  ])
    .then((response) => {
      if (!isProd) {
        // console.info('API response', response);
      }
      if (response) {
        if (
          response.headers['content-type'] === 'text/html; charset=utf-8' ||
          response.headers['content-type'] === 'text/html'
        ) {
          let { data } = response
          if (decodeBlockedText(data).trim() === 'Page Blocked') {
            const errorCode = decodeWaf(data)
            throw new APIError(
              `${
                'There has been an unexpected technical issue.' +
                ' To resolve this please contact the Private Bank Team on +44 (0) 1933 543 600 or the Racing Bank' +
                ' Team on +44 (0) 1933 543 543 and quote this error code:' +
                '\n'
              }${errorCode}`,
              errorCode
            )
          }
        }
        if (request.id === 'requestNonce') {
          if (tidyNonce(response) === undefined) {
            goOut('https://www.weatherbys.bank/sessionexpired')
          }
        }
        return response
      }
      throw new Error('bad_network_response')
    })
    .then((data) => tidyResponse.tidy(data, request))
    .catch((error) => {
      if (
        error.response !== undefined &&
        error.response.status === 404 &&
        request.id === 'requestLogin1'
      ) {
        return axios({
          baseURL: url,
          url: 'v1/session/nonce',
          headers: {
            Accept: '*/*',
            'Content-type': 'application/json',
          },
          method: constructRequest.build(request).method,
          withCredentials: true,
          mode,
          credentials,
          data: '',
        })
          .then((response) => {
            if (response) {
              return response
            }
          })
          .then((data) => tidyResponse.tidy(data, request))
          .catch((error) => {
            const errorResponse = error.response
            if (
              errorResponse !== undefined &&
              errorResponse.status === 401 &&
              errorResponse.data === 'User session expired.'
            ) {
              goOut('https://www.weatherbys.bank/sessionexpired')
            }
            if (
              errorResponse !== undefined &&
              errorResponse.status === 419 &&
              errorResponse.data === 'Prospect user session expired.'
            ) {
              goOut('https://www.weatherbys.bank/sessionexpired')
            }
            throw error
          })
      }
      const errorResp = error.response
      if (
        errorResp !== undefined &&
        errorResp.status === 401 &&
        errorResp.data === 'User session expired.'
      ) {
        goOut('https://www.weatherbys.bank/sessionexpired')
      }
      if (
        errorResp !== undefined &&
        errorResp.status === 419 &&
        errorResp.data === 'Prospect user session expired.'
      ) {
        goOut('https://www.weatherbys.bank/sessionexpired')
      }
      const errorResponse = error.response !== undefined && error.response.status === 417
      const reqPayeeId = request.id === 'requestPayeeNew'
      const reqPayeeCOrMId = request.id === 'requestPayeeCreateOrModify'
      const reqPaymentsBulkUpload = request.id === 'requestPaymentsBulkUpload'
      const reqTFA = request.id === 'requestTwoFactorAuth'
      if (
        !(
          error.response &&
          error.response.data &&
          error.response.data.message &&
          (error.response.data.message.code === 'DIGX_TFA_0004' ||
            error.response.data.message.code === 'DIGX_CZ_TFA_0001' ||
            error.response.data.message.code === 'DIGX_AUTH_0007') &&
          error.response.data.message.code !== 'DIGX_AUTH_0003'
        )
      ) {
        if (errorResponse && (reqPayeeId || reqPayeeCOrMId || reqPaymentsBulkUpload || reqTFA)) {
          if (error.response.headers['x-challenge']) {
            return tidyResponse.tidy(error.response, request)
          }
        }
      }
      console.warn(error)
      throw error
    })
}
