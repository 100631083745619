import { connect } from 'react-redux'
import { megaMenuShow, megaMenuHide } from '../../store/ui'

import MegaMenuItem from '../../components/Header/MegaMenuItem'

const mapDispatchToProps = (dispatch, ownProps) => ({
  onMouseEnter: () => {
    dispatch(megaMenuShow(ownProps.navId))
  },
  onMouseLeave: () => {
    dispatch(megaMenuHide())
  },
})

const mapStateToProps = (state, ownProps) => ({
  active: ownProps.navId === state.ui.megaMenu,
})

export default connect(mapStateToProps, mapDispatchToProps)(MegaMenuItem)
