/*
  Converts format of regular payment frequency that comes
  from API into human readable string.
  e.g. 0 days, 3 months, 0 years becomes "Quarterly"
*/
export default (days: string | number, months: string | number, years: string | number) => {
  if (months === 0 && years === 0) {
    switch (days) {
      case 1:
        return 'Daily'
      case 7:
        return 'Weekly'
      case 14:
        return 'Fortnightly'
      default:
    }
  }

  if (days === 0 && years === 0) {
    switch (months) {
      case 1:
        return 'Monthly'
      case 2:
        return 'Bi-Monthly'
      case 3:
        return 'Quarterly'
      case 6:
        return 'Half-Yearly'
      default:
    }
  }

  if (days === 0 && months === 0 && years === 1) return 'Yearly'

  return 'Custom'
}
