import { connect } from 'react-redux'
import MailList from '../../components/MailList'
import { getMessageGroups } from './selectors'

export const mapStateToProps = (state, ownProps) => {
  const { folderName } = ownProps.params
  const folder = state.mail[folderName]
  return {
    navItems: [
      {
        to: 'secure-mail/f/inbox',
        title: 'Inbox',
      },
      {
        to: 'secure-mail/f/outbox',
        title: 'Sent messages',
      },
    ],
    lastCrumb:
      folderName === 'inbox'
        ? {
            to: '/secure-mail/f/inbox',
            text: 'Inbox',
          }
        : {
            to: '/secure-mail/f/outbox',
            text: 'Outbox',
          },
    lists: getMessageGroups(state, folderName),
    state: folder.loading ? 'loading' : 'completelyEmpty',
    folderName,
  }
}

export default connect(mapStateToProps)(MailList)
