/* eslint-disable */
// @ts-nocheck
// todo - eslint and typescript
import React from 'react'
import ServicesFormEnquire from '../../containers/ServicesFormEnquire'
import FlowSuccess from '../FlowSuccess'
import FlowSwitch from '../../containers/FlowSwitch'
import ActionHeader from '../ActionHeader'
import InnerContainer from '../InnerContainer'
import Breadcrumbs from '../Breadcrumbs'
import subjectNames from '../../utils/lookup/subjectNames'

const ServicesEnquire = () => {
  const urlHash = window.location.hash.split('#')[2]
  let parentUrl = '/services/'
  if (urlHash === 'investmentmanager' || urlHash === 'mtportfolios') {
    parentUrl += 'investmentwealth/'
  }
  return (
    <div>
      <Breadcrumbs
        items={[
          {
            to: `${parentUrl}${urlHash}`,
            text: subjectNames[urlHash],
          },
          {
            to: '/secure-mail/new',
            text: 'Compose Message',
          },
        ]}
      />
      <InnerContainer>
        <ActionHeader title='Enquiry' />
        <h2>Compose Message</h2>
        <FlowSwitch
          SuccessComponent={FlowSuccess}
          successComponentProps={{
            message: 'Message sent',
            links: [
              {
                to: '/accounts',
                text: 'Back to my accounts',
              },
            ],
          }}
          formEl={<ServicesFormEnquire />}
        />
      </InnerContainer>
    </div>
  )
}

export default ServicesEnquire
