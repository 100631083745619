import * as React from 'react'

function SvgContinueAlert(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={112} height={98} fill='none' {...props}>
      <path
        stroke='#fff'
        strokeLinejoin='round'
        strokeWidth={6}
        d='M58.598 4.5a3 3 0 0 0-5.196 0L4.038 90a3 3 0 0 0 2.599 4.5h98.726a3.001 3.001 0 0 0 2.599-4.5L58.598 4.5Z'
      />
      <path
        fill='#fff'
        d='m53.872 63.488-2.176-26.176c-.256-2.496 1.024-4.224 3.52-4.224H57.2c2.496 0 3.776 1.728 3.52 4.224l-2.176 26.176c-.192 1.472-1.024 2.304-2.368 2.304-1.28 0-2.112-.832-2.304-2.304Zm-2.176 12.416V75.2c0-2.56 1.92-4.48 4.48-4.48 2.624 0 4.544 1.92 4.544 4.48v.704c0 2.496-1.92 4.48-4.544 4.48-2.56 0-4.48-1.984-4.48-4.48Z'
      />
    </svg>
  )
}
export default SvgContinueAlert
