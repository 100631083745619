import { connect } from 'react-redux'
import FlowConfirm from '../FlowConfirm'
import { paymentMakeSubmit, paymentMakeDetailsUpdate } from '../PaymentMake/actions'
import {
  getFromAccount,
  getToAccount,
  getStage,
  showRecurringWarning,
} from '../PaymentMake/selectors'
import { twoFactorAuthGoBack } from '../TwoFactorAuth/actions'
import generatePaymentDetails from '../../utils/generatePaymentDetails'

const mapStateToProps = (state) => {
  const pm = state.paymentMake
  const from = getFromAccount(state)
  const to = getToAccount(state)
  const d = {
    ...pm,
    from,
    to,
    currency: from.currency,
  }
  // On recurring payments, we show a warning if the selected date is today
  // as the first payment from recurring payment will always be tomorrow
  const recurringWarnings = showRecurringWarning(state)
  // Warnings that come back from the server are for things like when the
  // scheduled date is set to a weekend or bank holiday
  const apiWarnings = pm.warnings
  const warnings = []
  if (recurringWarnings)
    warnings.push('Your first payment will be tomorrow (or the next working day)')
  if (apiWarnings) warnings.push(apiWarnings)
  return {
    title: 'Payment details',
    cancelLink: `/payments/make/${state.paymentMake.from}`,
    col2Top: warnings.length > 0 && '11px',
    details: generatePaymentDetails(d),
    success: getStage(state) === 4,
    successShouldBePassed: true,
    warnings,
    needsAuthoriser: pm.needsAuthoriser,
    timeOutError: pm.timeOutError,
    goBackText: 'Edit payment',
    submitText: state.paymentMake.isNewPayee ? 'Next' : 'Confirm Payment',
    disableCancel: state.paymentMake.cancelDisabled,
    cancelText: 'Cancel Payment',
    successMessage: 'Payment complete',
    naMessage: 'Payment initiated',
    taMessage: 'We are trying to send your payment',
    referenceNumber: pm.referenceNumber,
    successLinks: [
      {
        text: 'Make another payment',
        to: `/payments/make/${state.paymentMake.from}`,
      },
      {
        text: 'Back to my accounts',
        to: '/accounts',
      },
    ],
  }
}
const mapDispatchToProps = (dispatch, ownProps) => ({
  formSubmit: paymentMakeSubmit,
  onGoBackClick: (e) => {
    e.preventDefault()
    dispatch(
      paymentMakeDetailsUpdate({
        amount: undefined,
      })
    )
    dispatch(twoFactorAuthGoBack())
  },
})
export default connect(mapStateToProps, mapDispatchToProps)(FlowConfirm)
