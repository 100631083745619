import { dontDuplicate } from '../utils/routerBugWorkaround'

export default (store) => ({
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const ActivityLog = require('../containers/ActivityLog').default

        const { activityLogInit } = require('../containers/ActivityLog/actions')

        dontDuplicate(() => {
          store.dispatch(activityLogInit())
        })

        cb(null, ActivityLog)
      },
      'activity-log'
    )
  },
})
