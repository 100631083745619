/* eslint-disable */
// @ts-nocheck

function PrimeRandomNumberGenerator() {
  this.i = 0
  this.j = 0
  this.S = []
}

function init(key) {
  let i, j, t

  for (i = 0; i < 256; ++i) {
    this.S[i] = i
  }

  j = 0

  for (i = 0; i < 256; ++i) {
    j = (j + this.S[i] + key[i % key.length]) & 255
    t = this.S[i]
    this.S[i] = this.S[j]
    this.S[j] = t
  }

  this.i = 0
  this.j = 0
}

function next() {
  this.i = (this.i + 1) & 255
  this.j = (this.j + this.S[this.i]) & 255

  const t = this.S[this.i]

  this.S[this.i] = this.S[this.j]
  this.S[this.j] = t

  return this.S[(t + this.S[this.i]) & 255]
}

PrimeRandomNumberGenerator.prototype.init = init
PrimeRandomNumberGenerator.prototype.next = next

const primeRandomNumberGenerator = new PrimeRandomNumberGenerator()
export default primeRandomNumberGenerator
