import React from 'react'
import InnerContainer from '../InnerContainer'
import DataTable from '../../containers/DataTable'
import Button from '../Button'
import s from './styles.css'

type PaymentsUpcomingViewProps = {
  showLoadMore: boolean
  onShowAllClick?: () => void
}

export const PaymentsUpcomingView = (props: PaymentsUpcomingViewProps) => (
  <div className={s.root}>
    <InnerContainer>
      <h1>Closed payments and expired standing orders</h1>
    </InnerContainer>
    <DataTable {...props} simpleResultsState />
    <InnerContainer>
      {props.showLoadMore && (
        <div className={s.showAll}>
          <Button className={s.showAllButton} onClick={props.onShowAllClick} text='Show All' />
        </div>
      )}
    </InnerContainer>
  </div>
)

export default PaymentsUpcomingView
