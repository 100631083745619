import moment from 'moment'
import get from 'lodash/get'
import formatSortCode from '../formatSortCode'
import formatMoney from '../formatMoney'

export interface TidyActivityLogSingleI {
  data: {
    transactionDTO: {
      createdBy: string
      creationDate: string
      lastUpdatedBy: string
      lastUpdatedDate: string
      version: number
      generatedPackageId: boolean
      auditSequence: boolean
      approvalDetails: {
        stepNo: boolean
        status: string
        action: string
        approvalType: string
        countOfApprovals: number
      }
      processingDetails: {
        currentStep: string
        status: string
      }
      transactionSnapshot: {
        payeeId: string | number
        groupId: string | number
      }
      transactionId: string
      transactionName: string
      limitTaskCode: string
      serviceId: string
      transactionHistoryDTOs: {
        createdBy: string
        creationDate: string
        lastUpdatedBy: string
        lastUpdatedDate: string
        version: number
        generatedPackageId: boolean
        auditSequence: number
        approvalDetails: {
          stepNo: number
          status: string
          action: string
          approvalType: string
          countOfApprovals: number
        }
        processingDetailDTO: {
          currentStep: string
          status: string
        }
        updatedByDetails: {
          title: string
          firstName: string
          lastName: string
          emailId: string
          mobileNumber: string | number
        }
      }[]
      partyId: {
        displayValue: string
        value: string
      }
      name: string
      category: string
      payeeType: string
      accountId: {
        displayValue: string | number
        value: string
      }
    }
  }
}
interface TidyActivityLogSingleS {
  accounts: {
    isFetching: boolean
    isSaving: boolean
    showSavedMessage: boolean
    items: {
      [key: string]: {
        customerId: string
        accountNumber: string | number
        accountId: string
        partyId: string
        currency: string
        originalType: string
        branchCode: string | number
        type: string
        accountType: string
        productType: string
        typeTitle: string
        group: string
        isInternational: boolean
        canTransferGlobal: boolean
        canTransferLocal: boolean
        balance: string
        availableBalancePure: string | number
        title: string
        order: number | string
        id: string
        canMakePayment: boolean
        canMakeBulkPayment: boolean
        transferIsMapped: boolean
        hasPayInBooks: boolean
        hasChequeStatus: boolean
        hasStatements: boolean
        hasChequeBooks: boolean
      }
    }
    itemsOrder: [string]
    realToMasked: {
      [key: string]: string
    }
    lastUpdated: string | number
    knownMappings: [string]
    knownMappingsErrors: any
  }
  transactions: {
    accounts: {
      [key: string]: {
        isFetching: boolean
        failedToFetch: boolean
        earliestStartDate: string
        gotAll: boolean
        items: {
          id: string | number
          title: string
          credit: string
          ref: string
          debitCredit: string
          debitCreditFlag: string
          date: string
          itemStamp: string | number
        }[]
      }
    }
    filters: any
    tempItems: boolean
  }
}
export default (data: TidyActivityLogSingleI, state: TidyActivityLogSingleS) => {
  const { realToMasked } = state.accounts

  const stats = get(data, 'data.transactionDTO')
  const statusCode = stats.approvalDetails.status

  const items: any = {}
  items.status = stats.approvalDetails.status
  switch (statusCode) {
    case 'APPROVED':
      items.status = 'Accepted'
      break
    case 'PENDING_APPROVAL':
      items.status = 'Pending'
      break
    case 'SENT_TO_HOST':
      items.status = 'Time Out Error'
      break
    case 'REJECTED':
      items.status = 'Rejected'
      break
    default:
  }
  items.reference = stats.transactionId
  items.partyId = stats.partyId
  items.accountId = realToMasked[stats.accountId.displayValue]
  items.amount = stats.amount && formatMoney(stats.amount.amount, stats.amount.currency)
  items.amountPure = stats.amount && stats.amount.amount
  items.typeCode = stats.transactionName
  items.valueDate = stats.valueDate && moment(stats.valueDate, 'YYYY-MM-DD').format('DD/MM/YYYY')

  let auditData = get(stats, 'transactionHistoryDTOs')

  items.audit = []
  if (auditData) {
    if (!(auditData instanceof Array)) {
      auditData = [auditData]
    }
    items.audit = auditData
      .map((d) => ({
        authedBy: d.lastUpdatedBy,
        authedOn: moment(d.lastUpdatedDate, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm:ss'),
        status: `${d.approvalDetails.status}-${d.processingDetailDTO.currentStep}`,
        date: stats.valueDate && moment(stats.valueDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        note: '',
      }))
      .reverse()
  }
  if (auditData && auditData.length !== 0) {
    items.audit.push({
      authedBy: stats.lastUpdatedBy,
      authedOn: moment(stats.lastUpdatedDate, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm:ss'),
      status: `${stats.approvalDetails.status}-${stats.processingDetails.currentStep}`,
      date: stats.valueDate && moment(stats.valueDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      note: '',
    })
  }
  switch (stats.transactionName) {
    case 'FU_F_DFT':
      items.type = 'Bulk Domestic Transfer'
      break
    case 'CH_N_CBR':
      items.type = 'Cheque Book Request'
      break
    case 'CH_N_PBR':
      items.type = 'Pay-in book request'
      break
    case 'PC_N_DOP':
      items.activityType = 'payee'
      items.payeeId = stats.transactionSnapshot.payeeId
      items.groupId = stats.transactionSnapshot.groupId
      items.type = 'Create Payee'
      break
    case 'PC_N_DDP':
      items.activityType = 'payee'
      items.type = 'Delete Payee'
      items.payeeId = stats.transactionSnapshot.payeeId
      items.groupId = stats.transactionSnapshot.groupId
      break
    case 'PC_N_UDOP':
      items.activityType = 'payee'
      items.payeeId = stats.transactionSnapshot.domesticPayee.id
      items.groupId = stats.transactionSnapshot.domesticPayee.groupId
      items.type = 'Edit Payee'
      break
    case 'PC_F_PIC_DD':
      items.type = 'Direct Debit Cancellation'
      break
    case 'PC_F_PIC_SI':
      items.type = 'Standing Order Cancellation'
      items.toAccountId = realToMasked[stats.creditAccountId.displayValue]
      items.toAccountNo = stats.creditAccountId.displayValue
      break
    case 'PC_F_PIC_FP':
      items.type = 'Future payment Cancellation'
      items.toAccountId = realToMasked[stats.creditAccountId.displayValue]
      items.toAccountNo = stats.creditAccountId.displayValue
      break
    case 'PC_F_DFT':
      items.type = 'Make a Payment'
      items.toAccountNo = stats.creditAccountId.displayValue
      items.toSortCode = formatSortCode(stats.transactionSnapshot.sortCode)
      items.beneName = stats.creditAccountName
      break
    case 'PC_F_DFTI':
      items.type = 'External Standing Order/Future Payment'
      items.toAccountNo = stats.creditAccountId.displayValue
      items.toSortCode = formatSortCode(stats.transactionSnapshot.sortCode)
      items.beneName = stats.creditAccountName
      break
    case 'PC_F_SFT':
      items.type = 'Internal Transfer'
      items.toAccountId = realToMasked[stats.creditAccountId.displayValue]
      items.toAccountNo = stats.creditAccountId.displayValue
      break
    case 'PC_F_SFTI':
      items.type = 'Internal Standing Order/Future Payment'
      items.toAccountId = realToMasked[stats.creditAccountId.displayValue]
      items.toAccountNo = stats.creditAccountId.displayValue
      break
    case 'PC_F_GNDP': {
      items.type = 'Third Party Payment - Adhoc'

      items.toAccountNo = stats.creditAccountId.displayValue
      const sortCode =
        (stats.transactionSnapshot &&
          stats.transactionSnapshot.dictionaryArray &&
          stats.transactionSnapshot.dictionaryArray[0] &&
          stats.transactionSnapshot.dictionaryArray[0].nameValuePairDTOArray[0] &&
          stats.transactionSnapshot.dictionaryArray[0].nameValuePairDTOArray[0].value) ||
        null
      items.toSortCode = (sortCode && formatSortCode(sortCode)) || ''
      items.beneName = stats.creditAccountName
      break
    }
    default:
  }

  const bulkUploadDetails = get(stats, 'authinfo.bulkfileuploadinitiaterequestdto')

  if (bulkUploadDetails) {
    const account = bulkUploadDetails.accoutdetails.accountnoinputdto
    items.accountId = realToMasked[account.nbraccount]
    items.amount = formatMoney(bulkUploadDetails.amount, account.ccydesc)
  }
  if (!items.valueDate) {
    items.valueDate =
      stats.creationDate && moment(stats.creationDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
  }

  return { items }
}
