import React from 'react'
import Modal from '../../containers/Modal'
import tranformMessages from '../../utils/truncateErrors/tranformMessages'

type ErrorModalProps = {
  message?: string
}

const ErrorModal = ({ message }: ErrorModalProps) => {
  let errorList = tranformMessages(message)
  errorList = errorList.map((err) => err.trim())
  return (
    <Modal id='genericError' size='small'>
      <h2>Something went wrong</h2>
      <p>
        {errorList && errorList[0]} {errorList && errorList[1]}
      </p>
      <p>{errorList && errorList[2]}</p>
    </Modal>
  )
}

export default ErrorModal
