export default {
  access_denied: 'Access Denied. User does not have permission to perform this action.',
  alpha_numeric: 'Please provide only alphanumeric values and spaces.',
  alpha_numeric_first_character: `A special character cannot be used as the first character of a payee name.
  Please update the payee name before proceeding.`,
  alpha_numeric_second_character_onwards: `The following special characters cannot be used in payee names:
  @ % ^ = { } ; " < > ~
  Please note, no special characters can be used as the first character of a payee name.`,
  alpha_numeric_file: 'Please provide only alphanumeric values and spaces for file name.',
  alpha_numeric_reference: 'Please provide only alphanumeric values and spaces for reference.',
  character_limit:
    'Please amend the reference details to 35 alphanumeric characters or less and try again.',
  credit_in_not_allowed:
    'The requested transfer cannot be undertaken.' +
    ' Please contact the Bank Helpdesk on +44 (0)1933 543543 for assistance.', // TBC: change?
  daily_cumulative_limit:
    'The transfer requested is above the daily limit and will not be processed.' +
    'Please contact our Helpdesk on 01933 543543 for further information.', // TBC: change?
  daily_limit:
    'The transfer requested is above the daily limit and will not be processed.' +
    ' Please contact our Helpdesk on 01933 543543 for further information.', // TBC: change?
  default:
    'There has been an unexpected technical issue. Please contact Weatherbys Bank Helpdesk on +44 (0)1933 543 543', // TBC: change?
  duplicate_file_name: 'Duplicate file name, please rename and try again',
  duplicate_payee: 'A payee with this account already exists.',
  invalid_account_number: 'Account details not found',
  locked_user:
    'You have entered invalid security details more than three times and your account' +
    ' is now locked. Please contact the Private Bank Helpdesk on +44 (0) 1933 543 600 or the Racing Bank Helpdesk on' +
    ' +44 (0) 1933 543 543.',
  admin_locked_user:
    'Your account is locked. Please contact Weatherbys Bank Helpdesk on +44 (0) 1933 543 543', // TBC: change?
  locked_transaction_pin:
    'Your transaction PIN has been temporarily locked.' +
    ' Please contact our Helpdesk on 01933 543543 for assistance.', // TBC: change?
  nickname_character_limit:
    'Please amend the Account name details to 35 alphanumeric characters' +
    ' or less and try again.',
  no_limit_set: 'No Limit set',
  not_enough_funds: 'This account does not have the required funds to make this payment.',
  payment_approval_rules:
    'You do not have permission to send payments from this account.' +
    ' If you believe this is an error please contact Weatherbys Bank Helpdesk on +44 (0)1933 543543.', // TBC: change?
  pin_in_history:
    'The new Transaction PIN cannot be the same as any previously used Transaction PIN,' +
    '  please select a new Transaction PIN.',
  pswd_in_history:
    'The new password cannot be the same as any previously used passwords,' +
    ' please select a new Password.',
  request_timeout:
    'There seems to be an issue with the network, please try again later.' +
    ' If the problem persists, please contact Weatherbys Bank Helpdesk on +44 (0)1933 543543.', // TBC: change?
  select_when: `Please select 'When' would you like your transfer to be made.`,
  temp_locked_transaction_pin:
    'You have exceeded maximum allowed limit for generating authorization ' +
    'tokens. Please try after some time.',
  transaction_limit:
    'This amount exceeds the limit for this Payment Type.' +
    ' Please contact the Bank Helpdesk on +44 (0) 1933 543543 for assistance.', // TBC: change?
  transaction_rules:
    'Your account does not currently permit this type of transaction.' +
    ' Please contact the Bank Helpdesk on +44 (0) 1933 543543 for assistance.', // TBC: change?
  wrong_credentials: `You have entered invalid security details. Please try again.`,
  wrong_details: 'You have entered invalid details. Please try again.',
  wrong_current_password:
    'The current password entered is incorrect. Please verify the credentials and retry.',
  wrong_pin: 'You have entered invalid Transaction PIN details. Please try again.',
  account_marked_no_debits:
    'There has been an unexpected technical issue. Please contact Weatherbys Bank Helpdesk on +44 (0)1933 543543', // TBC: change?
  cut_off_time_passed:
    'There has been an unexpected technical issue. Please contact Weatherbys Bank Helpdesk on +44 (0)1933 543543', // TBC: change?
  post_value_date:
    'There has been an unexpected technical issue. Please contact Weatherbys Bank Helpdesk on +44 (0)1933 543543', // TBC: change?
}
