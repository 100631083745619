/* eslint-disable */
// @ts-nocheck
// todo - eslint and typescript
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import get from 'lodash/get'
import MailForm from '../../components/MailForm'
import { send } from '../MailLayout/actions'
import getEntities from '../../selectors/getEntities'
import subjectCodes from '../../utils/lookup/subjectCodes'

const mapStateToProps = (state, ownProps) => {
  const entityId = state.entities.activeEntityId
  const subjectOptions = Object.keys(subjectCodes).map((key) => ({
    value: key,
    label: subjectCodes[key],
  }))
  const formValues = getFormValues('mail')(state)
  const customSubjectIsVisible = get(formValues, 'subjectCode.value') === 'CUSTOM'
  const customerId = ownProps.customerId || entityId
  const { customSubject } = ownProps
  return {
    entityOptions: getEntities(state),
    subjectOptions,
    customSubjectIsVisible,
    entitySelectIsVisible: entityId === false,
    initialValues: {
      customSubject,
      customerId: customerId && {
        value: customerId,
      },
      replyingTo: ownProps.replyingTo,
      threadId: ownProps.threadId,
    },
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  send,
})

export default connect(mapStateToProps, mapDispatchToProps)(MailForm)
