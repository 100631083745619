import React from 'react'
import s from './styles.css'
import DetailsBlock from '../DetailsBlock'
import ReactSelect from '../Forms/ReactSelect'
import { ConfirmationOfPayeeStatus } from '../../containers/ConfirmationOfPayee/reducer'
import COPTickIcon from '../../assets/icons/COPTickIcon'
import COPWarningIcon from '../../assets/icons/COPWarningIcon'

type PaymentMakeFromToProps = {
  onChangeFromAccountClick: () => void
  onChangeFromAccountSelect: () => void
  onChangeToAccountClick: () => void
  fromAccount?: any // todo - was object
  toAccount?: any // todo - was object
  isChangingAccount?: boolean
  fromOptions: any[]
  copStatus?: ConfirmationOfPayeeStatus
  verificationReport?: any
}

const PaymentMakeFromTo = ({
  fromAccount,
  toAccount,
  onChangeFromAccountClick,
  onChangeFromAccountSelect,
  isChangingAccount,
  fromOptions,
  onChangeToAccountClick,
  copStatus,
  verificationReport,
}: PaymentMakeFromToProps) => (
  <div className={s.row}>
    <div className={s.item}>
      {isChangingAccount ? (
        <div className={s.form}>
          <ReactSelect options={fromOptions} onChange={onChangeFromAccountSelect} />
        </div>
      ) : (
        <DetailsBlock
          descriptor='From Account'
          respondMode='forceRows'
          title={fromAccount.title}
          extras={[
            `${fromAccount.sortCode} ${fromAccount.accountNumber}`,
            `Current balance: ${fromAccount.balance}`,
            `Available balance: ${fromAccount.availableBalance}`,
          ]}
          actionText='Change account'
          onActionClick={onChangeFromAccountClick}
        />
      )}
    </div>
    <div className={s.item}>
      {toAccount && (
        <DetailsBlock
          descriptor='To Account'
          respondMode='forceRows'
          title={toAccount.title}
          extras={[`${toAccount.sortCodeNice} ${toAccount.accountNumber}`]}
          {...(![
            ConfirmationOfPayeeStatus.SUCCESS,
            ConfirmationOfPayeeStatus.SUCCESS_BYPASS,
          ].includes(copStatus) && {
            actionText: 'Pay someone else?',
            onActionClick: onChangeToAccountClick,
          })}
        />
      )}
      <div>
        {copStatus && copStatus !== ConfirmationOfPayeeStatus.NOT_STARTED ? (
          <span>
            {toAccount && copStatus === ConfirmationOfPayeeStatus.SUCCESS && (
              <div className={s.successMessage}>
                <COPTickIcon className={s.miniIcons} />
                All details matched
              </div>
            )}
            {toAccount && copStatus === ConfirmationOfPayeeStatus.SUCCESS_BYPASS && (
              <div className={s.warningMessage}>
                <COPWarningIcon className={s.miniIcons} />
                Check complete. Continue at risk.
              </div>
            )}
          </span>
        ) : (
          <>
            {verificationReport && verificationReport.enabled && (
              <>
                {verificationReport.matched === 'Y' ? (
                  <div className={`${s.successMessage} ${s.mt10}`}>
                    <COPTickIcon className={s.miniIcons} />
                    All details matched
                  </div>
                ) : (
                  <>
                    {verificationReport.matched === 'N' ? (
                      <div className={`${s.warningMessage} ${s.mt10}`}>
                        <COPWarningIcon className={s.miniIcons} />
                        Check complete. Continue at risk.
                      </div>
                    ) : (
                      <>
                        {verificationReport.selectionStatus ? (
                          <div className={`${s.successMessage} ${s.mt10}`}>
                            <COPTickIcon className={s.miniIcons} />
                            All details matched
                          </div>
                        ) : (
                          <div className={`${s.warningMessage} ${s.mt10}`}>
                            <COPWarningIcon className={s.miniIcons} />
                            Check complete. Continue at risk.
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  </div>
)

export default PaymentMakeFromTo
