/* eslint-disable */
// @ts-nocheck
// todo - eslint and typescript
import { call, put, takeLatest, select } from 'redux-saga/effects'
import moment from 'moment'
import request from '../../utils/request'
import { formError } from '../../sagas/formErrors/actions'
import { getFlowData, getTwoFactorAuthDetails } from './selectors'
import * as a from './actions'
import * as c from '../../constants'

import updateList from '../../sagas/updateList'

export function* handleOTPSubmit(action) {
  try {
    const formData = action.payload
    const joinedPass = Object.values(formData).join('')
    const tFAData = yield select(getTwoFactorAuthDetails)
    const requestType = tFAData.initialRequest
    let groupId
    let payeeId
    let refNumber
    let partyId
    let url
    let method = 'patch'
    let params = ''
    let bulkData = ''
    let editPayeeData = ''
    let flowData = ''
    const nonce = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    groupId = tFAData.ref.groupId
    payeeId = tFAData.ref.payeeId
    partyId = tFAData.ref.partyId
    refNumber = tFAData.ref.refNumber
    if (requestType === 'payeeCreate') {
      url = `cz/v1/payments/payeeGroup/${groupId}/payees/external/${payeeId}?locale=en`
      method = 'patch'
    } else if (requestType === 'payeeModify') {
      flowData = yield select(getFlowData)
      url = `cz/v1/payments/payeeGroup/${groupId}/payees/external/${payeeId}?locale=en`
      method = 'put'
      editPayeeData = {
        domesticPayeeType: 'UK',
        nickName: flowData.name,
        payeeName: flowData.name,
        payeeType: null,
        status: 'ACT',
        ukDomesticPayee: {
          accountName: flowData.name,
          accountNumber: flowData.accountNumber,
          bankDetails: {
            address: null,
            branch: null,
            city: null,
            code: flowData.sortCode,
            codeType: null,
            country: null,
            name: null,
          },
          shared: true,
          network: 'SORT',
          paymentType: 'FAS',
        },
      }
    } else if (requestType === 'bulkUpload') {
      url = '/cz/v1/fileUploads/files?locale=en'
      method = 'post'
      params = yield select(getFlowData)
      const bulkId = yield select((state) => state.user.bulkId.items[partyId].bulkId)
      bulkData = {
        fileName: params.fileName,
        fileContent: params.fileData,
        valueDate: moment(params.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        customerId: yield select((state) => state.accounts.items[params.accountId].customerId),
        debitAccountId: yield select((state) => state.accounts.items[params.accountId].accountId),
        fileIdentifier: bulkId,
      }
    }
    const res = yield call(request, {
      id: c.requestTwoFactorAuth,
      url,
      nonce,
      partyId,
      targetUnit: 'OBDX_BU', //TODO Need more testing
      xChallenge: {
        authType: 'OTP',
        otp: joinedPass,
        referenceNo: refNumber,
      },
      payload: {
        type: 'OTP',
        requestType,
        bulkData,
        method,
        editPayeeData,
      },
    })
    const payeeDetails = {
      refNumber: res.ref,
      partyId,
      editPayeeData,
      bulkData,
      method,
      url,
    }
    yield put(a.twoFactorAuthSetRef(payeeDetails))
    yield put(a.twoFactorAuthNextStep())
  } catch (error) {
    console.error(error)
    yield put(formError(a.OTPSubmit.FAILURE, error))
  }
}

export function* handlePINSubmit(action) {
  try {
    const formData = action.payload
    const joinedPass = Object.values(formData).join('')
    const details = yield select(getTwoFactorAuthDetails)
    const { initialRequest } = details
    let bulkData
    let requestType
    let editPayeeData
    const tFAData = yield select((state) => state.twoFactorAuth)
    requestType = tFAData.initialRequest
    const { partyId } = tFAData.ref
    const { method } = tFAData.ref
    const { url } = tFAData.ref
    const { refNumber } = tFAData.ref
    const nonce = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    if (requestType === 'payeeModify') {
      editPayeeData = tFAData.ref.editPayeeData
    } else if (requestType === 'bulkUpload') {
      bulkData = tFAData.ref.bulkData
    }
    const res = yield call(request, {
      id: c.requestTwoFactorAuth,
      url,
      nonce,
      partyId,
      xChallenge: {
        authType: 'OTP',
        otp: joinedPass,
        referenceNo: refNumber,
      },
      payload: {
        method,
        bulkData,
        requestType,
        editPayeeData,
      },
    })
    const { href } = window.location
    if (res && res.warning) {
      details.warning = res.warning
      yield put(a.twoFactorAuthSetRef(details))
      if (href.includes('payments/make')) {
        yield put(
          formError(a.PINSubmit.FAILURE, {
            name: 'API',
            message: `Payee Initiated!
            The payee must be authorised by another user before you can create this payment.
            Once the payee has been authorised please create a new payment to them.  This payment has
            not been processed..`,
          })
        )
      }
    }
    const updatePayeeList = {
      id: c.requestPayees,
      url: 'cz/v1/payments/payeeGroup?expand=ALL',
    }
    yield call(updateList('payees', updatePayeeList))
    yield put(a.twoFactorAuthComplete(initialRequest, res))
  } catch (error) {
    console.error(error)
    yield put(formError(a.PINSubmit.FAILURE, error))
  }
}

export function* watchTwoFactorAuth() {
  yield takeLatest(a.OTPSubmit.REQUEST, handleOTPSubmit)
  yield takeLatest(a.PINSubmit.REQUEST, handlePINSubmit)
}
