import React from 'react'
import { Link } from 'react-router'
import s from './styles.css'

type Props = {
  id: string
  title: string
  accountNumber: string
  balance: string
  currency?: string // todo - was mandatory, but AccountList is not passing it
  sortCode?: string
  isLoadingEmpty?: boolean
  canMakePayment?: boolean
}

const AccountListItem = ({
  id,
  title,
  accountNumber,
  sortCode,
  balance,
  isLoadingEmpty,
  canMakePayment,
  currency,
}: Props) => {
  const emptyClass = isLoadingEmpty ? s.empty : undefined

  const titleEl = isLoadingEmpty ? (
    <span className={s.empty}>{title}</span>
  ) : (
    <Link to={`/accounts/transactions/${id}`} dangerouslySetInnerHTML={{ __html: title }} />
  )

  let linkEl: React.ReactElement = <></>

  if (isLoadingEmpty) {
    linkEl = <span className={s.empty}>Make a Payment</span>
  }

  if (canMakePayment) {
    linkEl =
      currency === 'GBP' ? (
        <Link to={`/payments/make/${id}`}>Make a Payment</Link>
      ) : (
        <Link to={`/payments/request-international/${id}`}>Request a Payment</Link>
      )
  }

  return (
    <div className={s.root}>
      <div className={s.titleCol}>
        <h4 className={s.rowTitle}>{titleEl}</h4>
        <span className={`${s.details} ${emptyClass}`}>
          {sortCode} {accountNumber}
        </span>
      </div>
      <div className={s.balanceCol}>
        <span className={emptyClass}>{balance}</span>
      </div>

      <div className={s.actionCol}>{linkEl}</div>
      <Link to={`/accounts/transactions/${id}`} className={s.mobViewLink} title='View Account' />
    </div>
  )
}

export default AccountListItem
