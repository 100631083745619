import React from 'react'
import Button from '../Button'
import s from './help.css'

const Help = () => (
  <div className={s.root}>
    <h3 className={s.heading}>Help with payments</h3>
    <p className={s.body}>
      If you have any questions about making a payment, need to send us a CHAPS transfer or require
      assistance with international payments, our team is here to help
    </p>
    <h2 className={s.tel}>+44 (0)1933 543543</h2>
    <Button text='Send us a secure message' to='secure-mail/new' />
  </div>
)

export default Help
