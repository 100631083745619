import XLSX from 'xlsx'

// Takes in sheet, returns json
export default (data) => {
  const parsed = XLSX.read(data, {
    type: 'base64',
  })

  const sheet = parsed.Sheets[Object.keys(parsed.Sheets)[0]]

  return XLSX.utils.sheet_to_json(sheet, {
    header: ['name', 'sortCode', 'accountNumber', 'type', 'reference', 'amount'],
    raw: false,
  })
}
