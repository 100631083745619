import React from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import StyleguideItem from '../StyleguideItem'
import FieldsetLegend from '../../../components/Forms/FieldsetLegend'
import SingleField from '../../../components/Forms/SingleField'
import Radio from '../../../components/Forms/Radio'
import Checkboxes from '../../../components/Forms/Checkboxes'
import InlineFieldColumns from '../../../components/Forms/InlineFieldColumns'
import SortCode from '../../../components/Forms/SortCode'
import DatePicker from '../../../components/Forms/DatePicker'
import Select from '../../../components/Forms/Select'
import Button from '../../../components/Button'
import BottomError from '../../../components/Forms/BottomError'
import MoneyFormHelper from '../../formHelpers/MoneyFormHelper'
import PasswordFormHelper from '../../formHelpers/PasswordFormHelper'
import * as v from '../../../utils/forms/validators'
import * as n from '../../../utils/forms/normalizers'

type FormComponentsProps = {
  layout?: 'normal' | 'inline' // todo - testing - was string
}

const FormComponents = ({ layout }: FormComponentsProps) => (
  <div>
    <fieldset className='border'>
      <FieldsetLegend title='Fieldset Legend' />
      <Field
        label='Text Field (required)'
        name='text0'
        type='text'
        component={SingleField}
        placeholder='e.g. Richard D James'
        validate={v.required}
        validationMessages={{
          required: 'Please enter your name',
        }}
        layout={layout}
      />

      <Field
        label='Text Field with subLabel'
        subLabel='This is a subLabel'
        name='textSubLabel'
        type='text'
        component={SingleField}
        placeholder='e.g. Richard D James'
        layout={layout}
      />

      <Field
        label='Text Field (required, validate on blur)'
        name='textonblur'
        type='text'
        component={SingleField}
        placeholder='e.g. Richard D James'
        validate={v.required}
        validateType='onBlur'
        validationMessages={{
          required: 'This message appears on blur',
        }}
        layout={layout}
      />

      <PasswordFormHelper label='Password Field (required)' name='pass0' layout={layout} />
    </fieldset>

    <fieldset className='border'>
      <FieldsetLegend
        title='Fieldset Legend with info'
        info='This is some extra info below a legend.'
      />
      <PasswordFormHelper
        label='Password Field (Creating)'
        name='passCreating'
        layout={layout}
        validate={v.strongPassword}
        validateType='list'
        validationMessages={{
          // atLeastLength8: 'Has at least 8 characters',
          between8And20Chars: 'Has between 8 and 20 characters',
          atLeastOneUpperCase: 'Has at least one uppercase letter',
          atLeastOneLowerCase: 'Has at least one lowercase letter',
        }}
      />

      <PasswordFormHelper
        label='Verify new password'
        name='passMatching'
        layout={layout}
        validate={v.matchesFieldInList('passCreating')}
        validateType='list'
        validationMessages={{
          matchesField: 'Passwords match',
        }}
      />
    </fieldset>

    <MoneyFormHelper label='Money (decimal and formats on blur)' name='money0' layout={layout} />

    <Field
      label='Account number (only numbers & length 8)'
      name='accno0'
      type='text'
      component={SingleField}
      placeholder='12345678'
      validate={v.accountNo}
      normalize={n.accountNo}
      layout={layout}
    />

    <Field
      label='Select'
      name='select0'
      component={SingleField}
      InputComponent={Select}
      inputComponentProps={{
        options: [
          {
            value: 'A',
            label: 'Test Option A',
          },
          {
            value: 'B',
            label: 'Test Option B',
          },
        ],
      }}
      layout={layout}
    />

    <Field
      label='Sort code'
      name='sortcode0'
      component={SingleField}
      InputComponent={SortCode}
      validate={v.sortCode}
      layout={layout}
      validationMessages={{
        required: 'Please enter your sort code',
        onlyLength6: 'Sort Code should look like this: 12 - 34 - 56',
      }}
    />

    <Radio
      label='Radio Buttons'
      name={`${layout}_radio0`}
      options={[
        {
          value: 'A',
          label: 'Test Option A',
        },
        {
          value: 'B',
          label: 'Test Option B',
        },
      ]}
      layout={layout}
    />

    <Radio
      label='Radio Buttons (column)'
      name={`${layout}_radio1`}
      options={[
        {
          value: 'A',
          label: 'Test Option A',
        },
        {
          value: 'B',
          label: 'Test Option B',
        },
      ]}
      direction='column'
      layout={layout}
    />

    <Checkboxes
      label='Checkboxes'
      options={[
        {
          name: `${layout}_checkboxA`,
          label: 'Test Option A',
        },
        {
          name: `${layout}_checkboxB`,
          label: 'Test Option B',
        },
      ]}
      layout={layout}
    />

    <Checkboxes
      label='Checkboxes (column)'
      options={[
        {
          name: `${layout}_checkboxA2`,
          label: 'Test Option A',
        },
        {
          name: `${layout}_checkboxB2`,
          label: 'Test Option B',
        },
      ]}
      layout={layout}
      direction='column'
    />

    <Field
      label='Date Picker'
      name='date0'
      component={SingleField}
      InputComponent={DatePicker}
      layout={layout}
    />

    {layout === 'inline' && (
      <InlineFieldColumns
        field1={{
          label: 'Column 1',
          name: 'col1',
          component: (
            <Field
              name='col1'
              component={Select}
              options={[
                {
                  value: 'A',
                  label: 'Option A',
                },
                {
                  value: 'B',
                  label: 'Option B',
                },
              ]}
            />
          ),
        }}
        field2={{
          label: 'Column 2',
          name: 'col2',
          component: <Field name='col2' component='input' type='text' />,
        }}
      />
    )}

    <BottomError message='Either your User ID or Password are incorrect. Please try again.' />
    <Button text='Submit' submit />
  </div>
)

type FormProps = {
  handleSubmit?: () => void
}

const Form = ({ handleSubmit }: InjectedFormProps<any> & FormProps) => (
  <StyleguideItem title='Form Components'>
    <form className='narrow' onSubmit={handleSubmit}>
      <FormComponents layout='normal' />
    </form>
  </StyleguideItem>
)

type LongFormProps = {
  handleSubmit?: () => void
}

const LongForm = ({ handleSubmit }: InjectedFormProps<any> & LongFormProps) => (
  <StyleguideItem title='Inline Form Components'>
    <form className='narrow' onSubmit={handleSubmit}>
      <FormComponents layout='inline' />
    </form>
  </StyleguideItem>
)

const ReduxForm = reduxForm<any, FormProps>({
  form: 'styleguideFormNormal',
  // onSubmit: values => console.log(values) // todo - not sure if required for future
})(Form)

const LongReduxForm = reduxForm<any, LongFormProps>({
  form: 'styleguideFormLong',
  // onSubmit: values => console.log(values) // todo - not sure if required for future
})(LongForm)

const StyleguideForms = () => (
  <div>
    <ReduxForm />
    <LongReduxForm />
  </div>
)

export default StyleguideForms
