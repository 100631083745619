export interface TidyPayeeDetailsD {
  data: {
    domesticPayee: {
      ukDomesticPayee: {
        accountNumber: string
        bankDetails: {
          code: string
        }
        name: string
      }
    }
  }
}
export default (data: TidyPayeeDetailsD, state) => {
  const tidyData = data.data.domesticPayee.ukDomesticPayee
  return {
    accountNumber: tidyData.accountNumber,
    sortCode: tidyData.bankDetails.code,
    title: tidyData.name,
  }
}
