import React, { useEffect } from 'react'
import s from './styles.css'
import StickyTable from '../StickyTable'
import FilterPanel from '../../containers/FilterPanel'
import Form from './Form'
import AToZ from '../AToZ'

type PayeeTableProps = {
  resultsState?: any
  rows: any[] // todo - added because StickyTable required
  headerCols: any[] // todo - added because StickyTable required
  entityOptions?: any[] // todo - added because form.tsx  requires it
  copReset?: () => void
}

const PayeeTable = (props: PayeeTableProps) => {
  useEffect(() => {
    props.copReset()
  }, [])
  return (
    <div>
      <StickyTable {...props}>
        <div className={s.inner}>
          <FilterPanel collapseForSmall>
            {/* todo - mismatch */}
            {/* <Form {...props} /> */}
            <Form {...props} />
            <AToZ />
          </FilterPanel>
        </div>
      </StickyTable>
    </div>
  )
}

export default PayeeTable
