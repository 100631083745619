import { connect } from 'react-redux'
import SuccessComponent from '../../components/PaymentsBulkConfirmView/Success'

const mapStateToProps = (state) => ({
  successMessage: 'Upload complete',
  isFetching: state.paymentsBulk.validation.isFetching,
  hasErrors: state.paymentsBulk.validation.items.hasErrors,
  fileRef: state.paymentsBulk.validation.items.ref,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(SuccessComponent)
