import { connect } from 'react-redux'
import PaymentsBulkItemView from '../../components/PaymentsBulkItemView'
import { getBulkItemProps } from '../PaymentsBulk/selectors'

const mapStateToProps = (state) => ({
  items: getBulkItemProps(state),
  isLoading: state.paymentsBulk.item.isFetching,
  showBackToAuth: state.routing.locationBeforeTransitions.search === '?back=auth',
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsBulkItemView)
