import { connect } from 'react-redux'
import PaymentsBulkListView from '../../components/PaymentsBulkListView'
import { superDatePickerCancel } from '../SuperDatePicker/actions'
import { paymentsBulkListUpdate } from '../PaymentsBulk/actions'
import { getBulkFilesTableProps } from '../PaymentsBulk/selectors'

const mapStateToProps = (state) => ({
  datePickerShowing: state.ui.panel === 'datePicker',
  ...getBulkFilesTableProps(state, 'fullList'),
})

const mapDispatchToProps = (dispatch) => ({
  onDateClick: () => {
    dispatch(superDatePickerCancel('paymentsBulkFiles'))
  },
  onDateSubmit: () => {
    dispatch(paymentsBulkListUpdate())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsBulkListView)
