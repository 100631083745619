export default (url) => {
  const urlObject = new URL(url)
  const subdomains = urlObject.hostname.split('.')
  const subdomainsSplit = subdomains[0].split('-')
  // checks if 'pvte' is already added to the first subdomain
  if (!subdomainsSplit[0].endsWith('pvte')) {
    subdomainsSplit[0] += 'pvte'
    subdomains[0] = subdomainsSplit.join('-')
    // update the hostname with the modified subdomains
    urlObject.hostname = subdomains.join('.')
    // returns the modified url
    return urlObject.origin
  }

  // if 'pvte' is already present, return the original URL.
  return urlObject.origin
}
