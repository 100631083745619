/* eslint-disable no-shadow */
import { MouseEventHandler } from 'react'
import { COP_FAIL, COP_LOADING, COP_RESET, COP_SUCCESS, COP_SUCCESS_BYPASS } from '../../constants'

export enum ConfirmationOfPayeeStatus {
  NOT_STARTED = 'NOT_STARTED',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
  SUCCESS_BYPASS = 'SUCCESS_BYPASS',
}

export enum ConfirmationOfPayeeInvalidTypes {
  NAME = 'name',
  ACCOUNT_TYPE = 'accountType',
}

export interface IMessageDetail {
  header: string
  subHeaders: string[]
}

export interface IErrorDetail {
  errorDetails?: Array<{ type: ConfirmationOfPayeeInvalidTypes; value: string }>
}

export interface IErrorButtonLabels {
  primaryButtonLabel: string
  secondaryButtonLabel: string
  tertiaryButtonLabel?: string
}

export interface IErrorButtonActions {
  primaryButton: { label: string; onClick: MouseEventHandler }
  secondaryButton: { label: string; onClick: MouseEventHandler }
  tertiaryButton?: { label: string; onClick: MouseEventHandler }
  modalPrimaryButton?: { label: string; onClick: MouseEventHandler }
  modalSecondaryButton?: { label: string; onClick: MouseEventHandler }
}

export interface IErrorState extends IErrorDetail {
  errorCode: string
}

export interface ICopApiResponse {
  matched: 'Y' | 'N'
  enabled: boolean
  selectionStatus: true
  reasonCode: string
  payeeAccountName: string | undefined | null
}

export type IConfirmationOfPayee = {
  apiResponse?: ICopApiResponse
} & (
  | {
      status: ConfirmationOfPayeeStatus
      errorState?: never
      copLoading?: boolean
    }
  | {
      status: ConfirmationOfPayeeStatus.FAILED
      errorState: IErrorState
      copLoading?: boolean
    }
)
const initialState: IConfirmationOfPayee = {
  status: ConfirmationOfPayeeStatus.NOT_STARTED,
  copLoading: false,
}
export default function confirmationOfPayeeReducer(
  state: IConfirmationOfPayee = { status: ConfirmationOfPayeeStatus.NOT_STARTED },
  action
): IConfirmationOfPayee {
  const { payload, error } = action
  switch (action.type) {
    case COP_FAIL:
      return {
        ...state,
        errorState: error,
        apiResponse: payload,
        status: ConfirmationOfPayeeStatus.FAILED,
        copLoading: false,
      }
    case COP_RESET:
      return initialState
    case COP_SUCCESS:
      return {
        apiResponse: payload || state.apiResponse,
        status: ConfirmationOfPayeeStatus.SUCCESS,
        copLoading: false,
      }
    case COP_SUCCESS_BYPASS: {
      const payloadToSend = payload || state.apiResponse
      if (payloadToSend.matched === 'A') {
        payloadToSend.selectionStatus = false
      }
      return {
        apiResponse: payloadToSend,
        status: ConfirmationOfPayeeStatus.SUCCESS_BYPASS,
        copLoading: false,
      }
    }
    case COP_LOADING:
      return { ...state, copLoading: true }
    default:
      return state
  }
}
