import get from 'lodash/get'
import moment from 'moment'
import { decodeHTML } from '../../utils/decodeHTML'

/**
 * Tidies deeply nested and poorly named mail data from API into something
 * easier to deal with!
 * @param  {Object} data - raw data from API
 * @return {Object} data - tidied data
 */
export interface TidyMailDetailD {
  data: {
    mail: {
      creationDate: string
      version: number
      generatedPackageId: boolean
      auditSequence: number
      messageId: {
        displayValue: string
        value: string
      }
      contentDTO: any
      messageType: string
      subject: string
      messageBody: string
      expiryDate: string
      senderName: string
      messageUserMappings: {
        version: number
        generatedPackageId: boolean
        auditSequence: number
        mapId: string
        userId: string
        username: string
        deleteStatus: boolean
        msgFlag: string
        status: string
      }[]
      subjectId: string | number
      linkedParent: {
        creationDate: string
        version: number
        generatedPackageId: boolean
        auditSequence: number
        messageId: {
          displayValue: string
          value: string
        }
        contentDTO: any
        messageType: string
        subject: string
        messageBody: string
        senderName: string
        messageUserMappings: {
          version: number
          generatedPackageId: boolean
          auditSequence: number
          mapId: string
          userId: string
          username: string
          deleteStatus: boolean
          msgFlag: string
          status: string
        }[]
        subjectId: string
        party: {
          displayValue: string | null
          value: string
        }
        interactionId: string
      }
      party: {
        displayValue: string | null
        value: string
      }
      interactionId: string
    }
  }
}
export default (data: TidyMailDetailD, state, request) => {
  const message = get(data, 'data.mail', [])
  const tidyData = {
    detail: {},
    message: {},
  }

  if (message) {
    tidyData.detail = {
      id: message.messageId.value,
      threadId: String(message.messageUserMappings[0].mapId),
      sender: message.senderName,
      subject: decodeHTML(message.subject),
      subjectId: message.subjectId,
      isRead: message.messageUserMappings[0].status === 'R',
      date: moment(message.creationDate, 'YYYY-MM-DD HH:mm:ss').format(
        'dddd, Do MMMM YYYY - HH:mm'
      ),
      folderName: message.messageUserMappings[0].msgFlag === 'F' ? 'outbox' : 'inbox',
      timeStamp: parseInt(moment(message.creationDate, 'YYYY-MM-DD HH:mm:ss').format('X')),
      interactionId: message.interactionId,
      body: message.messageBody ? decodeHTML(message.messageBody) : '',
      party: message.party.value,
    }
  }
  tidyData.message = message
  return tidyData
}
