import * as React from 'react'

function SvgChevronRight(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 515'
      width='1em'
      height='1em'
      {...props}
    >
      <path d='M113.9 408.7l151.2-151.2-151.2-151.2c-3.6-3.6-5.4-7.9-5.4-12.8s1.8-9.2 5.4-12.8l47.3-47.3c3.6-3.6 7.9-5.4 12.8-5.4s9.2 1.8 12.8 5.4l211.3 211.3c3.6 3.6 5.4 7.9 5.4 12.8s-1.8 9.2-5.4 12.8L186.8 481.6c-3.6 3.6-7.9 5.4-12.8 5.4s-9.2-1.8-12.8-5.4l-47.3-47.3c-3.6-3.6-5.4-7.9-5.4-12.8s1.8-9.2 5.4-12.8z' />
    </svg>
  )
}

export default SvgChevronRight
