import React from 'react'
import MainSuccess from './MainSuccess'
import MainError from '../../containers/PaymentsBulkItemError'
import InnerContainer from '../InnerContainer'

type MainProps = {
  items: any // todo - was object
}

const Main = (props: MainProps) => (
  <main>
    {props.items.status === 'error' ? (
      <InnerContainer>
        <MainError {...props} />
      </InnerContainer>
    ) : (
      <MainSuccess {...props} />
    )}
  </main>
)

export default Main
