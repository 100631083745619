import { connect } from 'react-redux'
import { isAndroid } from '../../utils/getPlatform'
import PaymentMakeView from '../../components/PaymentMakeView'
import getPayingAccounts from '../../selectors/getPayingAccounts'
import { getShowAllIsVisible, getStage, saveeReceipt } from './selectors'
import { modalShow } from '../../store/ui'

const mapStateToProps = (state) => {
  const currentStage = getStage(state)
  return {
    showAllIsVisible: getShowAllIsVisible(state, getPayingAccounts(state)), // todo-ts: missing 3rd argument
    fromToIsVisible: currentStage > 0 && currentStage < 3,
    accountSelectIsVisible: state.paymentMake.from === undefined,
    payeesAreVisible: state.paymentMake.from !== undefined,
    currentStage,
    printIsVisible: currentStage === 4,
    onReceiptClickSave: () => saveeReceipt(state),
    mappingError: state.accounts.knownMappingsErrors.payment,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onReceiptClickModal() {
    if (isAndroid()) {
      dispatch(modalShow('eReceipt'))
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMakeView)
