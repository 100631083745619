import React from 'react'
import { Link } from 'react-router'
import s from './styles.css'

type MenuListProps = {
  title?: string
  endLink?: string
  services?: boolean
  secondColumn?: boolean
  items: {
    title: string
    to: string
    excluded?: boolean // todo - newly added - need testing
  }[]
  moveLeft?: boolean // todo - newly added - need testing
}

const MenuList = ({ title, items, secondColumn, endLink, services }: MenuListProps) => (
  <dl className={`${s.root} ${services ? s.services : ''} ${s.topMenuNew}`}>
    {title && <dt className={`${secondColumn ? s.invisible : ''}`}>{title}</dt>}
    {items
      .filter((item) => !item.excluded)
      .map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <dd key={`item_${index}`}>
          <Link to={item.to}>{item.title}</Link>
        </dd>
      ))}
    {endLink && (
      <dd key='end'>
        <Link to={endLink}>[view all]</Link>
      </dd>
    )}
  </dl>
)

export default MenuList
