import React from 'react'
import { Link } from 'react-router'
import s from './styles.css'

type MessageListProps = {
  items?: {
    id: string
    subject: string
    sender: string
    date: string
    folderName: string
    isRead: boolean
  }[]
}

const MessageList = ({ items }: MessageListProps) => (
  <ul className={s.root}>
    {items.map((item) => {
      const className = item.isRead ? false : s.unread

      return (
        <li className={s.item} key={item.id}>
          <Link
            className={`${s.link} ${className}`}
            to={`/secure-mail/f/${item.folderName}/${item.id}`}
          >
            <div className={s.top}>
              <h3 className={s.subject}>{item.subject}</h3>
              <span className={s.date}>{item.date}</span>
            </div>
            <div className={s.excerpt}>{item.sender}</div>
          </Link>
        </li>
      )
    })}
  </ul>
)

export default MessageList
