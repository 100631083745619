import React from 'react'
import { Field } from 'redux-form'
import moment from 'moment'
import InlineFieldColumns from '../InlineFieldColumns'
import Select from '../Select'
import Radio from '../Radio'
import DatePicker from '../../../containers/DatePicker'
import Checkboxes from '../Checkboxes'
import s from './styles.css'
import SingleField from '../SingleField'
import * as v from '../../../utils/forms/validators'

type PaymentDateOptionsPros = {
  freqOptions: any[]
  laterIsVisible?: boolean
  regularIsVisible?: boolean
  frequencyCode?: string
  repeatUntil?: string
  laterMinDate?: moment.Moment
  laterMaxDate?: moment.Moment
  showNextDayWarning?: boolean
  layout?: 'normal' | 'inline' // todo - testing - was string - to Match Radio component
  type?: string
  showRecurringCheckbox?: boolean
  showWhenOptions?: boolean
}

const PaymentDateOptions = ({
  laterIsVisible,
  regularIsVisible,
  frequencyCode,
  repeatUntil,
  laterMinDate,
  laterMaxDate,
  showNextDayWarning,
  freqOptions,
  layout,
  type,
  showRecurringCheckbox = true,
  showWhenOptions = true,
}: PaymentDateOptionsPros) => {
  const repeatUntilOptions = {
    forever: false,
    endDate: {
      label: 'Date',
      name: 'endDate',
      component: (
        <Field
          name='endDate'
          component={SingleField}
          InputComponent={DatePicker}
          inputComponentProps={{
            minDate: laterMinDate,
          }}
          validate={v.required}
          validationMessages={{
            required: 'On what date should the payments end?',
          }}
        />
      ),
    },
  }

  let verb: string
  let noun: string
  if (type === 'transfer') {
    verb = 'Transfer'
    noun = 'transfer'
  } else {
    verb = 'Pay'
    noun = 'payment'
  }

  return (
    <div>
      <fieldset className='border narrow'>
        {showWhenOptions && (
          <Radio
            name='when'
            label='When'
            options={[
              { label: `${verb} now`, value: 'now' },
              { label: `${verb} later`, value: 'later' },
            ]}
            direction='column'
            layout={layout}
          />
        )}

        {laterIsVisible && (
          <div>
            <Field
              name='laterDate'
              label='Payment date'
              layout={layout}
              component={SingleField}
              InputComponent={DatePicker}
              inputComponentProps={{
                minDate: laterMinDate,
                maxDate: laterMaxDate,
              }}
              validate={v.required}
              validationMessages={{
                required: 'On what date should the transaction happen?',
              }}
            />
            <p>
              <em>Payments will only be made on working days</em>
            </p>
          </div>
        )}
      </fieldset>
      <fieldset>
        <div className='border narrow'>
          {showRecurringCheckbox && (
            <Checkboxes
              name='checkboxes'
              options={[
                {
                  name: 'isRegular',
                  label: `Make this a recurring ${noun}`,
                },
              ]}
            />
          )}
          {showNextDayWarning && (
            <p>
              <em>Your first payment will be tomorrow (or the next working day).</em>
            </p>
          )}
        </div>
        {!showRecurringCheckbox && (
          <fieldset className='narrow'>
            <label>Frequency</label>
          </fieldset>
        )}
        {regularIsVisible && (
          <div className={showRecurringCheckbox ? s.panel : undefined}>
            <div className='narrow'>
              <InlineFieldColumns
                field1={{
                  label: 'Repeat',
                  name: 'frequencyCode',
                  component: (
                    <Field name='frequencyCode' component={Select} options={freqOptions} />
                  ),
                }}
              />
              <InlineFieldColumns
                field1={{
                  label: 'Until',
                  name: 'repeatUntil',
                  component: (
                    <Field
                      name='repeatUntil'
                      component={Select}
                      options={[
                        {
                          value: 'forever',
                          label: 'Further Notice',
                        },
                        {
                          value: 'endDate',
                          label: 'Last Payment Date',
                        },
                      ]}
                    />
                  ),
                }}
                field2={repeatUntilOptions[repeatUntil]}
              />
            </div>
          </div>
        )}
      </fieldset>
    </div>
  )
}

export default PaymentDateOptions
