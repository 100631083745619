import React from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import Button from '../Button'
import SingleField from '../Forms/SingleField'
import BottomError from '../Forms/BottomError'
import * as v from '../../utils/forms/validators'
import * as n from '../../utils/forms/normalizers'
import s from './styles.css'

type LoginFormProps = {
  handleSubmit?: () => void
  authenticate?: () => void
  error?: string
  invalid?: boolean
  submitting?: boolean
  isCapsLockOn?: boolean
}

type FieldProps = {
  user: string
  pass: string
}

const LoginForm = ({
  handleSubmit,
  error,
  submitting,
  authenticate,
  invalid,
  isCapsLockOn,
}: InjectedFormProps<FieldProps> & LoginFormProps) => (
  <form onSubmit={handleSubmit(authenticate)} autoComplete='off'>
    <Field
      label='User ID'
      name='user'
      type='text'
      component={SingleField}
      validate={v.required}
      normalize={n.alwaysUppercase}
      validateType='onSubmit'
      hideErrors
    />

    <Field
      label='Password'
      name='pass'
      type='password'
      component={SingleField}
      validate={v.required}
      validateType='onSubmit'
      hideErrors
    />

    {isCapsLockOn && <span className={s.capsLock}>Caps Lock is on</span>}
    <span className={s.handleWhiteSpace}>
      <BottomError message={error} />
    </span>

    <Button disabled={invalid} submit text='Log In' loading={submitting} />
  </form>
)

const LoginReduxForm = reduxForm<FieldProps, LoginFormProps>({
  form: 'login',
})(LoginForm)

export default LoginReduxForm
