import { connect } from 'react-redux'
import SidebarLayout from '../../components/SidebarLayout'
import { hasAuthorisations } from './selectors'

export const mapStateToProps = (state) => {
  const navItems = [
    {
      to: 'settings/alerts',
      // title: 'View & Set up Alerts'
      title: 'View details',
    },
    {
      to: 'settings/manage-accounts',
      title: 'Reorder & rename accounts',
    },
    // {
    //   to: 'settings/paymentcompanies',
    //   title: 'Your data sharing'
    // },
    {
      to: 'settings/manage-credentials/password',
      title: 'Change password',
    },
    {
      to: 'settings/manage-credentials/pin',
      title: 'Change transaction PIN',
    },
    {
      to: 'settings/activity-log',
      title: 'Activity log',
    },
    {
      to: 'settings/marketing-preferences',
      title: 'Marketing preferences',
    },
    // Uncomment for marketing preferences
    // {
    //  to: 'settings/marketing-preferences',
    //  title: 'Marketing Preferences'
    // }
  ]

  if (hasAuthorisations(state)) {
    navItems.push({
      to: 'settings/authorisations',
      title: 'Authorisations',
    })
  }

  return {
    navItems,
  }
}

export default connect(mapStateToProps)(SidebarLayout)
