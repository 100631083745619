import React from 'react'
import InnerContainer from '../InnerContainer'
import s from './styles.css'
import ButtonBlock from '../ButtonBlock'

const RouteNotFound = () => (
  <div className={s.root}>
    <InnerContainer>
      <h1 className={s.title}>We could not find the page you are looking for</h1>
      <ButtonBlock
        items={[
          {
            text: 'Back to my accounts',
            to: '/accounts',
          },
        ]}
      />
    </InnerContainer>
  </div>
)

export default RouteNotFound
