import { combineReducers } from 'redux'
import createListReducer, { ListState } from '../../reducerCreators/listReducer'

export interface IPaymentsRecurringState {
  SI: ListState
  DD: ListState
}

export default combineReducers<IPaymentsRecurringState, any>({
  SI: createListReducer('paymentsRecurringSI'),
  DD: createListReducer('paymentsRecurringDD'),
})
