import { connect } from 'react-redux'

import LoggedOutLayout from '../../components/LoggedOutLayout'
import { getSubheader, getIsLoading, getAuthError, pathName, getCurrentStep } from './selectors'

export const mapStateToProps = (state) => {
  return {
    subheader: getSubheader(state),
    authError:
      getCurrentStep(state) !== 'passChanged' && pathName(state) !== 'login'
        ? getAuthError(state)
        : false,
    isLoading: getIsLoading(state),
    currentStep: getCurrentStep(state),
  }
}

export default connect(mapStateToProps, null)(LoggedOutLayout)
