import { connect } from 'react-redux'
import getAccount from '../../selectors/getAccount'
import accountCanTransferOut from '../../selectors/accountCanTransferOut'
import accountHasStatements from '../../selectors/accountHasStatements'
import accountHasServices from '../../selectors/accountHasServices'
import AccountView from '../../components/AccountView'

const mapStateToProps = (state, ownProps) => {
  const account = getAccount(state, ownProps.params.id)
  return {
    id: ownProps.params.id,
    title: account.title,
    balance: account.balance,
    availableBalance: account.availableBalance,
    accountNumber: account.accountNumber,
    sortCode: account.sortCode,
    type: account.type,
    typeTitle: account.typeTitle,
    details: account.details || {},
    overdraftLimit: account.overdraftLimit,
    canMakePayment: account.canMakePayment,
    canTransfer: accountCanTransferOut(state, ownProps.params.id),
    isInternational: account.isInternational,
    iban: account.isInternational ? account.iban : undefined,
    specialMessage:
      account.type === 'NT' ? 'To withdraw funds please call +44\xa0(0)1933\xa0543543' : undefined,
    statementsIsShowing: accountHasStatements(state, ownProps.params.id),
    accountServicesIsShowing: accountHasServices(state, ownProps.params.id),
  }
}

export default connect(mapStateToProps, null)(AccountView)
