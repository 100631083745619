import { getFormAsyncErrors } from 'redux-form'
import getNewPayeeDetails from '../../selectors/getNewPayeeDetails'

// eslint-disable-next-line import/prefer-default-export
export const getBankName = (state, ownProps) => {
  const payeeDetails = getNewPayeeDetails(state)
  const asyncErrors: any = getFormAsyncErrors(ownProps.formName)(state)
  const sortCodeError = asyncErrors && asyncErrors.sortCode === 'badSortCode'
  const bankName = payeeDetails && payeeDetails.bankName

  return !sortCodeError && (bankName || ownProps.initialBankName)
}
