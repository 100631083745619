import { combineReducers } from 'redux'
import createListReducer, { ListState } from '../../reducerCreators/listReducer'

export interface IActivityLogState {
  types: ListState
  type: ListState
  single: ListState
}

export default combineReducers<IActivityLogState, any>({
  types: createListReducer('activity-log'),
  type: createListReducer('activity-log-type'),
  single: createListReducer('activity-log-single'),
})
