import get from 'lodash/get'

/**
 * Tidies deeply nested data from API into something easier to deal with!
 * @param  {Object} data - raw data from API
 * @return {Object} data - tidied data
 */

export default (data: {
  status: string
  data: {
    idsequence: string
    status?: {
      referenceNumber?: string
      message?: {
        code: string
      }
    }
  }
}) => {
  const payee = get(data, 'data')
  let warning = ''
  let id = ''
  if (data.status.toString() === '202') {
    id = payee.status.referenceNumber
    warning = payee.status.message.code
  } else {
    id = payee.idsequence
  }
  return {
    id,
    warning,
  }
}
