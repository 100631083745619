import * as c from '../../constants'
import { ItransactionsAddMore, ItransactionsFilterTags } from './interface'

export function transactionsFetchFail(id: string, error: string) {
  return {
    type: c.TRANSACTIONS_FETCH_FAIL,
    payload: {
      id,
      error,
    },
  }
}

export function transactionsAddMore(id: string, items: ItransactionsAddMore[], startDate: string) {
  return {
    type: c.TRANSACTIONS_ADD_MORE,
    payload: {
      id,
      items,
      startDate,
    },
  }
}

export function transactionsTempItemsPopulate(
  id: string,
  items: ItransactionsAddMore[],
  keywordSearchDetail = {}
) {
  return {
    type: c.TRANSACTIONS_TEMP_ITEMS_POPULATE,
    payload: {
      id,
      items,
      keywordSearchDetail,
    },
  }
}

export function transactionsTempItemsClear(id: string) {
  return {
    type: c.TRANSACTIONS_TEMP_ITEMS_CLEAR,
    payload: {
      id,
    },
  }
}

export function transactionsFetchMore(id: string, startDate?: string, endDate?: string) {
  return {
    type: c.TRANSACTIONS_FETCH_MORE,
    payload: {
      id,
    },
  }
}

export function transactionsFetchStart(id: string) {
  return {
    type: c.TRANSACTIONS_FETCH_START,
    payload: {
      id,
    },
  }
}

export function transactionsFetchStop(id: string) {
  return {
    type: c.TRANSACTIONS_FETCH_STOP,
    payload: {
      id,
    },
  }
}

export function transactionsGotAll(id: string) {
  return {
    type: c.TRANSACTIONS_GOT_ALL,
    payload: {
      id,
    },
  }
}

export function transactionsFetchLatest(id: string) {
  return {
    type: c.TRANSACTIONS_FETCH_LATEST,
    payload: {
      id,
    },
  }
}

export function transactionsMergeLatest(id: string, items: ItransactionsAddMore) {
  return {
    type: c.TRANSACTIONS_MERGE_LATEST,
    payload: {
      id,
      items,
    },
  }
}

export function transactionsMergeMore(id: string, items: ItransactionsAddMore) {
  return {
    type: c.TRANSACTIONS_MERGE_MORE,
    payload: {
      id,
      items,
    },
  }
}

export function transactionsSwitchDebitCredit(filter: string) {
  return {
    type: c.TRANSACTIONS_SWITCH_DEBIT_CREDIT,
    payload: {
      filter,
    },
  }
}

export function transactionsSearch(searchTerm: string, id: string) {
  return {
    type: c.TRANSACTIONS_SEARCH,
    payload: {
      searchTerm,
      id,
    },
  }
}

export function transactionsFilterAmountMinMax(min: number | string, max: number | string) {
  return {
    type: c.TRANSACTIONS_FILTER_AMOUNT_MIN_MAX,
    payload: {
      min,
      max,
    },
  }
}

export function transactionsFilterTags(tags: ItransactionsFilterTags) {
  return {
    type: c.TRANSACTIONS_FILTER_TAGS,
    payload: {
      tags,
    },
  }
}

export function uTransactionsFilterDates(id: string) {
  return {
    type: c.U_TRANSACTIONS_FILTER_DATES,
    payload: {
      id,
    },
  }
}

export function rTransactionsFilterDates(
  id: string,
  startDate: string,
  endDate: string,
  diffMonths = 0,
  dateRangeR: string | number
) {
  return {
    type: c.R_TRANSACTIONS_FILTER_DATES,
    payload: {
      id,
      startDate,
      endDate,
      diffMonths,
      dateRangeR,
    },
  }
}

export function transactionsClearDates() {
  return {
    type: c.TRANSACTIONS_CLEAR_DATES,
  }
}

export function transactionsClearFilters() {
  return {
    type: c.TRANSACTIONS_CLEAR_FILTERS,
  }
}

export function transactionsDownload(id: string, type: string, startDate: string, endDate: string) {
  return {
    type: c.TRANSACTIONS_DOWNLOAD,
    payload: {
      id,
      type,
      startDate,
      endDate,
    },
  }
}

// new functions added for date filter pagination changes

export function transactionsTempItemsMerge(id: string, items: ItransactionsAddMore) {
  return {
    type: c.TEMP_ITEM_MERGE,
    payload: {
      id,
      items,
    },
  }
}

export function transactionsTempItemsPopulateDate(id: string, items: ItransactionsAddMore) {
  return {
    type: c.TRANSACTIONS_TEMP_ITEMS_POPULATE_DATE,
    payload: {
      id,
      items,
    },
  }
}

export function transactionsFetchMoreFilters(id: string) {
  return {
    type: c.TRANSACTIONS_FETCH_MORE_FILTERS,
    payload: {
      id,
    },
  }
}

export function updateFilterShowMoreCountZero() {
  return {
    type: c.UPDATE_FILTER_SHOW_MORE_COUNT_ZERO,
  }
}
export function updateFilterShowMoreCount() {
  return {
    type: c.UPDATE_FILTER_SHOW_MORE_COUNT,
  }
}

export function transactionsFetchMoreKeywordSearch(id: string) {
  return {
    type: c.TRANSACTIONS_KEYWORD_SEARCH_FETCH_MORE,
    payload: {
      id,
    },
  }
}

export function transactionsMoreKeywordSearchPopulate(
  id: string,
  items: ItransactionsAddMore[],
  keywordSearchDetail = {}
) {
  return {
    type: c.TRANSACTIONS_KEYWORD_SEARCH_MERGE_MORE,
    payload: {
      id,
      items,
      keywordSearchDetail,
    },
  }
}
// end new functions
