import React, { ReactNode } from 'react'
import s from './styles.css'

type InfoStripProps = {
  title?: string
  children?: ReactNode
}

const InfoStrip = ({ title, children }: InfoStripProps) => {
  const headerEl = title ? <h2 className={s.title}>{title}</h2> : false

  return (
    <div className={s.root}>
      {headerEl}
      {children}
    </div>
  )
}

export default InfoStrip
