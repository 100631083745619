import React from 'react'
import Breadcrumbs from '../../Breadcrumbs'
import ContactContainer from '../../ContactContainer'
import InnerFullWidthContainer from '../../InnerFullWidthContainer'
import ServicesHeader from '../../ServicesHeader'
import s from '../../ServicesView/styles.css'

const addPadding = true

const InvestmentManager = () => (
  <div>
    <Breadcrumbs
      items={[
        {
          to: '/services',
          text: 'Additional services',
        },
        {
          to: '/services/investmentwealth',
          text: 'Investment & wealth advice',
        },
        {
          to: '/services/mtportfolios',
          text: 'Market tracking portfolios',
        },
      ]}
    />
    <ServicesHeader title='Market tracking portfolios' secondaryTitle='Match market performance' />
    <InnerFullWidthContainer addPadding={addPadding}>
      <div className={`${s.textArea} ${s.extraTopMargin}`}>
        <p>
          If you want to invest in a low-cost, passively managed fund that mirrors the markets, a
          tracker fund could be an intuitive choice for you. Each of our five standard tracker
          portfolios features a different risk-and-return profile and uses a robust investment
          process to maximise diversification and minimise losses in tough times.
        </p>
      </div>
      <div className={`${s.textArea} ${s.extraTopMargin} ${s.boldTextArea}`}>
        <p>
          Past performance is not a guide to future performance. The value of an investment and its
          income can both increase and decrease and you may not get back the full amount originally
          invested. The value of overseas investments will be influenced by the rate of exchange.
        </p>
      </div>
      <ContactContainer
        title='Find out more'
        infoText='For more information about our Investments & Wealth Advice service, please contact our expert team'
        items={[
          {
            linkTitle: '+44 (0)20 7292 9025',
            to: '+44 (0)20 7292 9025',
            telNumber: true,
          },
          // {
          //   linkTitle: 'Send Secure Message',
          //   to: '/secure-mail/new'
          // }
          {
            linkTitle: 'Send Secure Message',
            to: '/secure-mail/enquiry#mtportfolios',
          },
        ]}
      />
    </InnerFullWidthContainer>
  </div>
)

export default InvestmentManager
