import get from 'lodash/get'

/**
 * Takes state and returns only accounts visible due to entity filtering
 * @param  {Object} state - Entire state tree
 * @return {Object} newItems - Visible items
 */

export default (state) => {
  const entityId = get(state, 'entities.activeEntityId', undefined)
  const items = get(state, 'accounts.items', {})
  const itemsOrder = get(state, 'accounts.itemsOrder', [])
  const typeOrder = ['current', 'racing', 'deposit', 'lending']

  const orderedItems = typeOrder.reduce((ordered, type) => {
    const itemsOfType = itemsOrder.reduce((acc, val) => {
      if (items[val].group === type) acc[val] = items[val]
      return acc
    }, {})

    const orderedNew = {
      ...ordered,
      ...itemsOfType,
    }

    return orderedNew
  }, {})

  if (entityId === false) return orderedItems

  const newItems = {}

  Object.keys(orderedItems).forEach((key) => {
    if (orderedItems[key].customerId === entityId) {
      newItems[key] = orderedItems[key]
    }
  })

  return newItems
}
