import get from 'lodash/get'
import formatSortCode from '../formatSortCode'

/**
 * Tidies deeply nested data from API into something easier to deal with!
 * @param  {Object} data - raw data from API
 * @return {Object} data - tidied data
 */
export interface TidyPayeesD {
  data: {
    payeeGroups: {
      groupId: string
      name: string
      listPayees: {
        createdBy: string
        creationDate: string
        version: number
        generatedPackageId: boolean
        auditSequence: number
        id: string
        name: string
        nickName: string
        payeeType: string
        groupId: string
        status: string
        domesticPayeeType: string
        ukDomesticPayee: {
          createdBy: string
          creationDate: string
          version: number
          generatedPackageId: boolean
          auditSequence: number
          id: string
          name: string
          nickName: string
          payeeType: string
          groupId: string
          status: string
          shared: boolean
          payeeAccessType: string
          userDetails: {
            firstName: string
            lastName: string
          }
          paymentType: string
          isAdminCreated: string
          network: string
          accountNumber: string
          accountName: string
          bankDetails: {
            name: string
            address: string
            city: string
            country: string
            code: string
          }
          adhocPayee: boolean
        }
        adhocPayee: boolean
        payeeDescription?: string
      }[]
      party: {
        displayValue: string
        value: string
      }
    }[]
  }
}
export default (data: TidyPayeesD) => {
  const tidyData = { items: {} }

  let payees = get(data, 'data.payeeGroups')
  if (payees) {
    if (!(payees instanceof Array)) {
      payees = [payees]
    }

    payees.forEach((item) => {
      const payee = item.listPayees[0]

      const b = payee.ukDomesticPayee.bankDetails
      const sortCode = String(b.code)
      const id = String(payee.id)
      const lastPaymentReference = get(payee, 'ukDomesticPayee.lastPaymentReference')
      const payeeReference = get(
        payee,
        'ukDomesticPayee.dictionaryArray[0].nameValuePairDTOArray[0].value'
      )
      const verificationReport = payee.ukDomesticPayee.verificationPayeeReport
      const payeeDescriptionValue = payee.ukDomesticPayee?.payeeDescription
      tidyData.items[id] = {
        id,
        groupId: String(item.groupId),
        creatorId: String(payee.createdBy),
        sortCode,
        customerId: item.party.value,
        lastReference: lastPaymentReference || payeeReference,
        lastPaymentReference,
        payeeReference,
        accountNumber: String(payee.ukDomesticPayee.accountNumber),
        title: String(payee.nickName),
        sortCodeNice: formatSortCode(sortCode),
        bankName: String(b.name),
        bankAddress1: String(b.address),
        bankCity: String(b.city),
        bankCountry: String(b.country),
        party: {
          value: item.party.value,
          dValue: item.party.displayValue,
        },
        verificationReport,
        payeeDescription: payeeDescriptionValue || String(payee.nickName),
      }
    })
  }

  return tidyData
}
