import { call, put, takeEvery, select } from 'redux-saga/effects'
import includes from 'lodash/includes'
import * as c from '../../constants'
import request from '../../utils/request'
import * as a from './actions'
import { formError } from '../../sagas/formErrors/actions'
import topOfPage from '../../utils/topOfPage'
import { userDetailsUpdate } from '../../store/user'
import RSAPwdEncrypt from '../../utils/RSAPwdEncrypt/encrypt'
import goOut from '../../utils/goOut'
import addPvteToSubDomain from '../../utils/addPvteToSubDomain'
import { PasswordPolicyEnum } from '../../models/enum'
import { getParam } from '../../utils/verifyAuthToken'

export function* handleNextStep() {
  yield call(topOfPage)
}

export function* handleForceChangeCredential(action) {
  const type = action.type === 'FORCE_CHANGE_PASS_REQUEST' ? 'password' : 'pin'

  try {
    const formData = action.payload
    if (type === 'password') {
      const { modulus, publicExponent, publicKey } = yield call(request, {
        id: c.requestPublicKey,
        url: 'v1/publicKey?locale=en',
      })

      if (publicKey && modulus && publicExponent) {
        const { salt } = yield call(request, {
          id: c.requestSalt,
          url: 'v1/salt?locale=en',
        })

        const encryptedPwd = RSAPwdEncrypt(modulus, publicExponent, salt, formData.newPass)
        const token = getParam('token')

        yield call(request, {
          id: c.requestNewForceChange,
          url: '/v1/credentials/changeCredentials?locale=en',
          payload: {
            newPassword: encryptedPwd,
            token,
          },
        })
      }
    } else {
      const nonce = yield call(request, {
        id: c.requestNonce,
        url: 'v1/session/nonce',
      })
      yield call(request, {
        id: c.requestManageCredentials,
        url: '/cz/v1/credentials/transactionPin',
        nonce,
        payload: {
          old: formData.oldPass,
          new: formData.newPass,
          type: 'pin',
        },
      })
    }

    yield put(a.changeCredentialsNextStep())

    if (yield select((state: any) => includes(state.changeCredentials.steps, 'success'))) {
      const nonce1 = yield call(request, {
        id: c.requestNonce,
        url: 'v1/session/nonce',
      })
      const permissions = yield call(request, {
        id: c.requestPermissions,
        url: '/cz/v1/permissions?locale=en',
        nonce: nonce1,
      })
      permissions.user.ready = true
      yield put(userDetailsUpdate(permissions.user))
    }
  } catch (error) {
    const errorAction = type === 'password' ? a.forceChangePass : a.forceChangePin
    yield put(formError(errorAction.FAILURE, error))
  }
}

export function* handleAcceptTerms() {
  try {
    const nonce = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    yield call(request, {
      id: c.requestAcceptTerms,
      url: 'v1/me/loginFlow?locale=en',
      nonce,
      payload: {
        loginConfigId: 'terms-and-conditions',
      },
    })
    yield put(a.changeCredentialsNextStep())
  } catch (error) {
    yield put(formError(a.acceptTerms.FAILURE, error))
  }
}

export function* handleTokenVerification(action) {
  const { token } = action.payload
  /* Here we are making a call to check if the token is valid or not. */
  yield put(a.displayLoader())

  try {
    const tokenCall = yield call(request, {
      id: c.requestAuthTokenVerification,
      url: `v1/token/${token}?locale=en`,
    })
    const { tokenValid } = tokenCall.data.token

    if (!tokenValid) {
      yield put(a.authTokenFailure())
    } else {
      const passwordPolicyCall = yield call(request, {
        id: c.requestPasswordPolicy,
        url: `v1/passwordPolicy?token=${token}&locale=en`,
      })
      const policyType = passwordPolicyCall.data.passwordPolicyDTO[0].pwdPolicyName
      if (policyType === PasswordPolicyEnum.CorporatePolicy) {
        const flags = {
          forcePasswordChange: 'true',
          passChanged: 'true',
        }
        yield put(a.changeCredentialsRegisterSteps(flags))
      } else {
        const otherParams = window.location.hash.split('?')[1]
        goOut(`${addPvteToSubDomain(window.location.href)}?${otherParams}`)
      }
    }
  } catch (error) {
    yield put(a.authTokenFailure())
  }
}

export function* watchChangeCredentials() {
  yield takeEvery(a.forceChangePass.REQUEST, handleForceChangeCredential)
  yield takeEvery(a.forceChangePin.REQUEST, handleForceChangeCredential)
  yield takeEvery(c.CHANGE_CREDENTIALS_NEXT_STEP, handleNextStep)
  yield takeEvery(c.VERIFY_AUTH_TOKEN, handleTokenVerification)
  yield takeEvery(a.acceptTerms.REQUEST, handleAcceptTerms)
}
