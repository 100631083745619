import { connect } from 'react-redux'
import { reset } from 'redux-form'
import AccountCards from '../../components/AccountCards'
import { getBulkAccounts } from '../PaymentsBulk/selectors'
import getIsAccountMappingKnown from '../../selectors/getIsAccountMappingKnown'
import { modalShow } from '../../store/ui'
import { flowUpdateData } from '../../store/flow'

const mapStateToProps = (state) => ({
  accountsAreLoading: !getIsAccountMappingKnown(state, 'bulk'),
  accounts: getBulkAccounts(state),
  title: 'Select an account to start your bulk payment',
})
const mapDispatchToProps = (dispatch) => ({
  onItemClick: (accountId) => {
    dispatch(flowUpdateData(accountId))
    dispatch(reset('bulkUploadFile'))
    dispatch(modalShow('bulkUploadFile'))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountCards)
