import { dontDuplicate } from '../utils/routerBugWorkaround'

export default (store) => ({
  path: 'services',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const AccountServices = require('../containers/AccountServices').default
        const { accountServicesInit } = require('../containers/AccountServices/actions')

        dontDuplicate(() => {
          store.dispatch(accountServicesInit())
        })

        cb(null, AccountServices)
      },
      'account-services'
    )
  },
})
