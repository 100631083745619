/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import s from './styles.css'
import Icon from '../Icon'

type IconButtonProps = {
  text: string
  icon: string
  className?: any
  onClick?: () => void
}

const IconButton = ({ text, icon, onClick, className }: IconButtonProps) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
  <span onClick={onClick} className={`${s.root} ${className || undefined}`}>
    <Icon icon={icon} />
    {text}
  </span>
)

export default IconButton
