import React, { Component } from 'react'
import PropTypes from 'prop-types'
import s from './styles.css'
import SubmitCancel from '../../Forms/SubmitCancel'
import Shortcuts from '../Shortcuts'

interface MediumProps {
  startDateNative: string
  endDateNative: string
  maxDateNative: string
  onNativeInputChange: (v1: string, v2: string) => void
  onShortcutClick: () => void
  onSubmit: () => void
  onCancelClick: () => void
  shortcuts?: any[]
  hideSubmit?: boolean
  className?: string
}

class Medium extends Component<MediumProps> {
  constructor(props) {
    super(props)
    this.onStartChange = this.onStartChange.bind(this)
    this.onEndChange = this.onEndChange.bind(this)
  }

  onStartChange(e) {
    this.props.onNativeInputChange('start', e.target.value)
  }

  onEndChange(e) {
    this.props.onNativeInputChange('end', e.target.value)
  }

  render() {
    const {
      onSubmit,
      startDateNative,
      endDateNative,
      maxDateNative,
      shortcuts,
      onShortcutClick,
      onCancelClick,
      hideSubmit,
      className,
    } = this.props

    const rootClass = className ? `${s.root} ${className}` : s.root

    return (
      <form className={rootClass} onSubmit={onSubmit}>
        <Shortcuts items={shortcuts} onShortcutClick={onShortcutClick} />

        <label htmlFor='dateStart'>Start date</label>
        <input
          type='date'
          id='dateStart'
          value={startDateNative}
          max={maxDateNative}
          onChange={this.onStartChange}
        />

        <label htmlFor='dateEnd'>End date</label>
        <input
          type='date'
          id='dateEnd'
          value={endDateNative}
          max={maxDateNative}
          onChange={this.onEndChange}
        />
        {!hideSubmit && <SubmitCancel submitText='Apply Dates' onCancelClick={onCancelClick} />}
      </form>
    )
  }
}

export default Medium
