import React from 'react'
import InnerContainer from '../InnerContainer'
import Button from '../Button'
import Form from './Form'
import FilterPanel from '../FilterPanel'
import PaymentsRecurringSection from '../../containers/PaymentsRecurringSection'
import s from './styles.css'

export const PaymentsRecurring = (props) => (
  <div>
    <InnerContainer>
      <div className={s.heading}>
        <h1 className={s.title}>Recurring payments</h1>
        <div>
          <Button
            text='Set up recurring payment'
            className={s.paymentButton}
            to={{ pathname: '/payments/make', query: { isRegular: true } }}
          />
          <Button
            text='Set up recurring transfer'
            to={{
              pathname: '/payments/transfer-money',
              query: { isRegular: true },
            }}
          />
        </div>
      </div>
    </InnerContainer>
    <FilterPanel>
      <div className={s.col}>
        <Form {...props} />
      </div>
    </FilterPanel>
    <PaymentsRecurringSection
      type='SI'
      title='Active standing orders and regular transfers'
      typeName='standing order'
      showStripey
    />
    <PaymentsRecurringSection type='DD' title='Direct debits' typeName='direct debit' />
  </div>
)

export default PaymentsRecurring
