/* eslint-disable */
// @ts-nocheck
// todo - eslint and typescript

import React from 'react'
import Breadcrumbs from '../Breadcrumbs'
import ActionHeader from '../ActionHeader'
import InnerContainer from '../InnerContainer'
import s from './styles.css'
import InfoComp from './InfoComp'
import CompaniesComp from './CompaniesComp'
import AccountPermissions from './AccountPermissions'
import { MakeRequest } from './MakeRequest'
import goOut from '../../utils/goOut'
import Button from '../Button'
import { Link } from 'react-router'
import LoadingSpinner from '../LoadingSpinner'
import SelectEntities from './SelectEntities'
let Request = new MakeRequest()
class PaymentCompanies extends React.Component {
  constructor() {
    super()
    this.state = {
      showEntityForm: false,
      showPermissionsForm: false,
      isSuccess: false,
      currentView: 'edit',
      tpps: {},
      loading: true,
      selectedAccessPointId: null,
      isError: false,
      errorMessage: null,
      selectedEntity: null,
      allEntities: [],
    }
  }

  /* eslint-disable-next-line */
  UNSAFE_componentWillReceiveProps() {
    this.setState({
      showPermissionsForm: false,
      isSuccess: false,
      currentView: 'edit',
      isError: false,
      errorMessage: null,
      showEntityForm: false,
      selectedEntity: null,
      selectedAccessPointId: null,
    })
  }
  onEntityClick = (selectedEntity) => {
    this.setState({ showPermissionsForm: true, selectedEntity, showEntityForm: false })
  }

  onCompanyClick = (accessPointId) => {
    let { allEntities } = this.state
    if (allEntities.length < 2) {
      this.setState({ selectedAccessPointId: accessPointId }, () => {
        this.onEntityClick(allEntities[0].partyId.value)
      })
    } else {
      this.setState({ showEntityForm: true, selectedAccessPointId: accessPointId })
    }
  }
  onSuccess = () => {
    this.setState({ isSuccess: true })
  }
  changeCurrentView = (val) => {
    this.setState({ currentView: val })
  }
  componentDidMount() {
    Request.post('/digx/v1/session/nonce', {}).then((res) => {
      let nonce = null
      let nonceAvailable = false
      for (let pair of res.headers.entries()) {
        if (pair[0] === 'x-nonce') {
          nonce = JSON.parse(pair[1]).nonce[0]
          nonceAvailable = true
          Request.get('/digx/v1/me/preferences?locale=en', nonce)
            .then((response) => response.json())
            .then((res) => {
              if (res.message && res.message.type.toLowerCase() === 'error') {
                this.setState({ isError: true, errorMessage: res.message.detail, loading: false })
              } else {
                this.setState({ tpps: res }, () => {
                  this.setState({ loading: false })
                })
              }
            })
            .catch((error) => {
              console.log(error)
            })

          break
        }
      }
      if (!nonceAvailable) {
        goOut('https://www.weatherbys.bank/sessionexpired')
      }
    })

    // get all entities

    Request.post('/digx/v1/session/nonce', {}).then((res) => {
      let nonce = null
      let nonceAvailable = false
      for (let pair of res.headers.entries()) {
        if (pair[0] === 'x-nonce') {
          nonce = JSON.parse(pair[1]).nonce[0]
          nonceAvailable = true
          Request.get(`/digx/cz/v1/relations?locale=en`, nonce)
            .then((response) => response.json())
            .then((res) => {
              this.setState({ allEntities: res.userPartyRelationshipDTOs })
            })
            .catch((error) => {
              console.log(error)
            })

          break
        }
      }
      if (!nonceAvailable) {
        goOut('https://www.weatherbys.bank/sessionexpired')
      }
    })
  }
  render() {
    let {
      showPermissionsForm,
      isSuccess,
      currentView,
      tpps,
      selectedAccessPointId,
      isError,
      errorMessage,
      loading,
      showEntityForm,
      selectedEntity,
      allEntities,
    } = this.state
    return (
      <div>
        <Breadcrumbs
          items={[
            {
              to: '/settings',
              text: 'Details & Settings',
            },
            {
              to: '/settings/paymentcompanies',
              text: 'Your data sharing',
            },
          ]}
        />

        <InnerContainer>
          <div>
            <ActionHeader title='Your data sharing' />
            <InfoComp
              selectedAccessPointId={selectedAccessPointId}
              isSuccess={isSuccess}
              showPermissionsForm={showPermissionsForm}
              currentView={currentView}
              showEntityForm={showEntityForm}
            />
            {!isSuccess && !showPermissionsForm && showEntityForm && (
              <SelectEntities onEntityClick={this.onEntityClick} allEntities={allEntities} />
            )}
            {!isSuccess &&
              !showEntityForm &&
              !showPermissionsForm &&
              Object.keys(tpps).length > 0 && (
                <CompaniesComp tpps={tpps} onCompanyClick={this.onCompanyClick} />
              )}
            {!isSuccess && showPermissionsForm && (
              <AccountPermissions
                onSuccess={this.onSuccess}
                changeCurrentView={this.changeCurrentView}
                selectedAccessPointId={selectedAccessPointId}
                selectedEntity={selectedEntity}
              />
            )}
            {isError && errorMessage && <div className={s.psdErrorDiv}>{errorMessage}</div>}
            {isSuccess && (
              <div className={s.psdGoBack}>
                <Link to={'/settings/paymentcompanies'}>
                  <Button text={'Go back to Your data  sharing'} />
                </Link>
              </div>
            )}
            {loading && (
              <div className={s.psdSpinner}>
                <LoadingSpinner text={'Loading...'} />
              </div>
            )}
          </div>
        </InnerContainer>
      </div>
    )
  }
}

export default PaymentCompanies
