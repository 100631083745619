import { createFormAction } from 'redux-form-saga'
import * as c from '../../constants'

export const renameAccount = createFormAction('MANAGE_ACCOUNTS_RENAME')

export const accountsOrderChange = (
  accountId: string | number,
  direction: string,
  entity: string | number
) => ({
  type: c.ACCOUNTS_ORDER_CHANGE,
  payload: {
    accountId,
    direction,
    entity,
  },
})

export const accountsOrderSubmit = () => ({
  type: c.ACCOUNTS_ORDER_SUBMIT,
})

export const accountsOrderSubmitFinished = (showSavedMessage = false) => ({
  type: c.ACCOUNTS_ORDER_SUBMIT_FINISHED,
  payload: {
    showSavedMessage,
  },
})
