import { connect } from 'react-redux'
import AuthorisationsView from '../../components/AuthorisationsView'
import { getVisibleAuthorisations } from './selectors'

const mapStateToProps = (state, ownProps) => {
  let resultsState

  const items = getVisibleAuthorisations(state)
  if (state.authorisations.list.isFetching) {
    resultsState = 'loading'
  } else if (items.length === 0) {
    resultsState = 'completelyEmpty'
  }

  return {
    items,
    resultsState,
  }
}

export default connect(mapStateToProps)(AuthorisationsView)
