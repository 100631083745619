import base64ToBlob from '../base64ToBlob'

export default (response: {
  data: {
    fileContent: string
    fileName: string
  }
}) => {
  const { data } = response
  const { fileContent } = data
  const name = data.fileName
  const type = name.split('.')[1]

  const blob = fileContent && base64ToBlob(fileContent)

  return {
    data: blob,
    file: {
      name,
      type,
    },
  }
}
