export default (
  condition?: string,
  browser?: {
    greaterThan?: {
      small: string | number | boolean
      medium: string | number | boolean
    }
  }
) => {
  switch (condition) {
    case 'medium':
      return browser.greaterThan.small
    case 'large':
      return browser.greaterThan.medium
    default:
      return true
  }
}
