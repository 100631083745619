import React from 'react'
import { reduxForm, InjectedFormProps } from 'redux-form'
import FieldsetLegend from '../../Forms/FieldsetLegend'
import Button from '../../Button'
import BottomError from '../../Forms/BottomError'
import termsHTML from '../../../content/terms.html'
import s from './styles.css'

type TermsStepProps = {
  handleSubmit?: () => void // todo - was mandatory- but ChangeCredentialsViewn do not pass
  onSubmit: () => void
  submitting?: boolean
  error?: string
  onClickAccept?: any // todo - was added - bcas ChangeCredentialsViewn do pass it
}

const TermsStep = ({
  handleSubmit,
  onSubmit,
  error,
  submitting,
}: InjectedFormProps<any> & TermsStepProps) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <fieldset className='narrow'>
      <FieldsetLegend title='Read and Accept Terms and Conditions' />
      <p>
        These Terms and Conditions are in addition to those provided in Section 1 of the{' '}
        <a href='https://www.weatherbys.bank/terms-and-conditions'>General Terms and Conditions</a>.
      </p>

      {/* eslint-disable-next-line react/no-danger */}
      <div className={s.terms} dangerouslySetInnerHTML={{ __html: termsHTML }} />
    </fieldset>
    <fieldset className={`narrow ${s.actions}`}>
      <BottomError message={error} />
      <Button submit text='Next' loading={submitting} />

      <p>
        If you do not wish to accept the Terms and Conditions, simply close this window. You will
        not be able to access any Online Banking services.
      </p>
    </fieldset>
  </form>
)

const TermsStepReduxForm = reduxForm<any, TermsStepProps>({
  form: 'acceptTerms',
})(TermsStep)

export default TermsStepReduxForm
