import React from 'react'
import { InnerFullWidthContainer } from '../InnerFullWidthContainer'
import s from './styles.css'

const addPadding = true

type ServicesHeaderProps = {
  // mappingError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  title: string
  secondaryTitle?: string // todo - ServicesRacingPlus/index is not passing
  moveDown?: string // todo - can be string | boolean
}

export const ServicesHeader = ({ title, secondaryTitle, moveDown }: ServicesHeaderProps) => (
  <InnerFullWidthContainer addPadding={addPadding}>
    <h1 className={moveDown ? `${s.moveDown} ${s.title}` : s.title}>{title}</h1>
    {secondaryTitle && <h2 className={s.secondaryTitle}>{secondaryTitle}</h2>}
  </InnerFullWidthContainer>
)

export default ServicesHeader
