import React from 'react'
import Modal from '../../containers/Modal'
import FlowSwitch from '../../containers/FlowSwitch'
import FlowSuccess from '../FlowSuccess'
import Form from './Form'

type AccountServicesFormProps = {
  type: string
  needsAuthoriser?: boolean
}

const AccountServicesForm = (props: AccountServicesFormProps) => {
  const checkForm = () => {
    const message =
      props.type === 'cheque book'
        ? 'Your cheque book will arrive in 7 to 10 working days'
        : 'Your pay-in book will arrive in 7 to 10 working days'
    /* if (props.type === 'cheque book' ? '') {
      message = 'Your cheque book will arrive in 7 to 10 working days'
    } else {
      message = 'Your pay-in book will arrive in 7 to 10 working days'
    } */
    return message
  }
  return (
    <Modal id='accServ' title={`New ${props.type}`} size='medium' noScroll>
      <FlowSwitch
        SuccessComponent={FlowSuccess}
        successComponentProps={{
          message: `Request for ${props.type} complete. ${checkForm()}`,
          needsAuthoriser: props.needsAuthoriser,
        }}
        formEl={<Form {...props} />}
      />
    </Modal>
  )
}

export default AccountServicesForm
