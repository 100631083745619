// Takes in File object, returns base 64 string
export default (file: Blob) =>
  new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = (error) => {
      // console.error(error)
    }
  })
