/* eslint-disable import/prefer-default-export */
import { createFormAction } from 'redux-form-saga'
import { ICopApiResponse, IErrorState } from './reducer'
import { COP_FAIL, COP_LOADING, COP_RESET, COP_SUCCESS, COP_SUCCESS_BYPASS } from '../../constants'

export const confirmPayee = createFormAction('COP_VERIFY')

export function copFail(payload: ICopApiResponse, error: IErrorState) {
  return {
    type: COP_FAIL,
    payload,
    error,
  }
}

export function copReset() {
  return {
    type: COP_RESET,
  }
}

export function copSuccess(payload?: ICopApiResponse) {
  return {
    type: COP_SUCCESS,
    payload,
  }
}

export function copSuccessBypass(payload?: ICopApiResponse) {
  return {
    type: COP_SUCCESS_BYPASS,
    payload,
  }
}

export function copLoading() {
  return {
    type: COP_LOADING,
  }
}
