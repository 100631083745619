import React from 'react'
import { Link } from 'react-router'
import TransferMoneyForm from '../../containers/TransferMoneyForm'
import PaymentMakeAccounts from '../../containers/PaymentMakeAccounts'
import InnerContainer from '../InnerContainer'
import Upcoming from '../../containers/Payments/upcoming'
import Links from './links'
import Help from './help'
import Warnings from '../Warnings'
import s from './styles.css'

type PaymentsViewProps = {
  mappingError?: string // todo - was string|boolean, was changed to match Warnings props
  availableItems?: boolean
}

export const PaymentsView = ({ mappingError, availableItems }: PaymentsViewProps) => (
  <div className={s.paymentsView}>
    <InnerContainer>
      <h1>Payments</h1>
      <h2 className={s.secondaryTitle}>Make a Payment</h2>
    </InnerContainer>

    {mappingError ? (
      <InnerContainer>
        <Warnings items={[mappingError]} />
      </InnerContainer>
    ) : (
      <PaymentMakeAccounts initialNumberShown='2' accountsAsLinks />
    )}

    <div className={s.darkSection}>
      <h2 className={s.panelTitle}>Transfer money between accounts</h2>
      <TransferMoneyForm
        params={{ id: undefined }}
        layout='strip'
        title='Transfer money between accounts'
      />

      <InnerContainer>
        <Link to='/payments/transfer-money' className={s.moreOptions}>
          More transfer options
        </Link>
      </InnerContainer>
    </div>

    <InnerContainer>
      <Upcoming />
    </InnerContainer>

    <div className={s.dottedLine} />

    <InnerContainer>
      <Links availableItems={availableItems} />
    </InnerContainer>

    <div className={s.darkSection}>
      <InnerContainer>
        <Help />
      </InnerContainer>
    </div>
  </div>
)

export default PaymentsView
