import { call, put, takeLatest } from 'redux-saga/effects'
import moment from 'moment'
import humanError from '../../utils/humanError'
import request from '../../utils/request'
import * as c from '../../constants'
import {
  listUpdateAll,
  listFetchFail,
  listFetchStart,
} from '../../reducerCreators/listReducer/actions'

const today = moment().format('YYYY-MM-DD')
const sixMonthAgo = moment().subtract(6, 'months').format('YYYY-MM-DD')

export function* fetchActivityLogCount() {
  try {
    yield put(listFetchStart('activity-log'))
    const nonce = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    const response = yield call(request, {
      id: c.requestActivityLogTypes,
      url: `/cz/v1/transactions/count?countFor=all&fromDate=${sixMonthAgo}&toDate=${today}`,
      nonce,
    })
    yield put(listUpdateAll('activity-log', response.items))
  } catch (error) {
    const message = yield call(humanError, error)
    yield put(listFetchFail('activity-log', message))
  }
}

export function* watchActivityLog() {
  yield takeLatest(c.ACTIVITY_LOG_INIT, fetchActivityLogCount)
}
