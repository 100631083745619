import { dontDuplicate } from '../utils/routerBugWorkaround'

export default (store) => ({
  path: 'log/:id',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const ActivityLogSingle = require('../containers/ActivityLogSingle').default

        const { activityLogSingleInit } = require('../containers/ActivityLogSingle/actions')

        const { id } = nextState.params
        const { statusCode, typeCode, bit } = nextState.location.query

        dontDuplicate(() => {
          store.dispatch(activityLogSingleInit(id, statusCode, typeCode, bit))
        })

        cb(null, ActivityLogSingle)
      },
      'activity-log-type'
    )
  },
})
