import { v4 as uuidv4 } from 'uuid'

export default function createMaskedIds(items: { [key: string]: { accountNumber: string } }) {
  const realToMasked = {}

  Object.keys(items).forEach((key: string) => {
    const uniqueString: string = uuidv4()
    const { accountNumber } = items[key]
    realToMasked[accountNumber] = uniqueString
  })

  return realToMasked
}
