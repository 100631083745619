import React from 'react'
import moment from 'moment'
import s from './styles.css'
import { currenyObj } from './constants'
import { decodeHTML } from '../../utils/decodeHTML'
import formatMoney from '../../utils/formatMoney'

export const PendingTableRow = (data: {
  data: {
    description: string
    transactionDate?: string
    valueDate?: string
    creditDebitFlag?: string
    debitAmount?: {
      amount: string | number
      currency: string
    }
    creditAmount: {
      amount: string | number
      currency: string
    }
  }
}) => {
  const pendingData = data.data
  return (
    <div className={s.pendingCardDiv}>
      <span className={s.pendingMarkDiv}>
        <span className={s.pendingMark}>PENDING</span>
      </span>

      <span className={s.pendingTransName}>
        {pendingData.description ? decodeHTML(pendingData.description) : ''}
        {pendingData.transactionDate &&
          ` (${moment(pendingData.transactionDate, 'YYYY-MM-DD').format('DD/MM/YYYY')})`}
        {pendingData.valueDate &&
          ` (${moment(pendingData.valueDate, 'YYYY-MM-DD').format('DD/MM/YYYY')})`}
      </span>
      <span className={s.pendingAmountDebit}>
        {pendingData.creditDebitFlag === 'D' && <span className={s.pendingMinus}> - </span>}
        {pendingData.creditDebitFlag === 'D'
          ? `${formatMoney(
              pendingData.debitAmount.amount,
              currenyObj[pendingData.debitAmount.currency],
              false
            ).replace(/ +/g, '')}`
          : ''}
      </span>
      <span className={s.pendingAmountCredit}>
        {pendingData.creditDebitFlag === 'D'
          ? ''
          : `${formatMoney(
              pendingData.creditAmount.amount,
              currenyObj[pendingData.creditAmount.currency],
              false
            ).replace(/ +/g, '')}`}{' '}
      </span>
    </div>
  )
}

export default PendingTableRow
