import moment from 'moment'
import frequencyCodes from '../lookup/frequencyCodes'

export interface DateOptionsProcessD {
  when: string
  frequencyCode: { value: string | number }
  repeatUntil: { value: string }
  endDate?: string
  laterDate?: string
  isRegular?: boolean
}
export default (formData: DateOptionsProcessD) => {
  const repeatUntilVal = formData.repeatUntil ? formData.repeatUntil.value : undefined
  const frequencyCode = formData.frequencyCode ? formData.frequencyCode.value : undefined
  const frequencyName = frequencyCodes[frequencyCode]
  const isRegular = formData.isRegular || false
  const { when } = formData
  const repeatFreq = isRegular ? frequencyCodes[frequencyCode] : undefined
  const { endDate } = formData

  let repeatsUntil
  if (repeatFreq) {
    repeatsUntil = repeatUntilVal === 'endDate' ? endDate : 'Further notice'
  }

  return {
    data: {
      when,
      isRegular,
      frequencyCode,
      frequencyName,
      repeatUntil: repeatUntilVal,
      laterDate: formData.laterDate,
      endDate,
    },
    display: {
      when: when === 'now' ? moment().format('DD/MM/YY') : formData.laterDate,
      repeatFreq,
      repeatsUntil,
    },
  }
}
