import * as React from 'react'

function SvgServices(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 28 28'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <use xlinkHref='#services_svg__path0_fill' transform='translate(0 9.885)' fill='#999' />
      <use xlinkHref='#services_svg__path1_fill' transform='translate(23.219)' fill='#999' />
      <use xlinkHref='#services_svg__path2_fill' transform='translate(11.959 10.727)' fill='#999' />
      <use xlinkHref='#services_svg__path3_fill' transform='translate(15.099 2.439)' fill='#999' />
      <defs>
        <path
          id='services_svg__path0_fill'
          d='M25.536 0h-1.637L18.97 4.932a.823.823 0 01-.213.154l-4.878 2.44a2.454 2.454 0 01-2.84-.462 2.461 2.461 0 01-.461-2.84L12.689 0H2.46A2.464 2.464 0 000 2.463v13.189a2.464 2.464 0 002.46 2.463h23.076a2.464 2.464 0 002.46-2.463V2.462A2.464 2.464 0 0025.536 0zM4.1 6.567h4.976a.82.82 0 110 1.642H4.1a.82.82 0 110-1.642zm19.794 8.264H4.101a.82.82 0 110-1.642h19.794a.82.82 0 110 1.642zm0-3.284H4.101a.82.82 0 110-1.641h19.794a.82.82 0 110 1.641z'
        />
        <path
          id='services_svg__path1_fill'
          d='M4.06.697c-.93-.93-2.553-.929-3.48 0L0 1.278l3.48 3.483.58-.58a2.45 2.45 0 00.72-1.742c0-.658-.256-1.277-.72-1.742z'
        />
        <path
          id='services_svg__path2_fill'
          d='M2.142 0L.087 4.115a.821.821 0 001.1 1.101L5.3 3.16 2.142 0z'
        />
        <path id='services_svg__path3_fill' d='M6.96 0L0 6.965l3.48 3.484 6.96-6.966L6.96 0z' />
      </defs>
    </svg>
  )
}

export default SvgServices
