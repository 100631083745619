/**
 * Converts wizard step into flags
 * @param  {Object} data - raw data from API
 * @return {Object} data - tidied data
 */
export default (data: { data: string | number }) => {
  const step = data.data

  const flags: any = {
    isFirstTime: true,
  }

  flags.termsNotAccepted = step < 1
  flags.forcePasswordChange = step < 2
  flags.forcePinChange = step < 3

  return flags
}
