import sortBy from 'lodash/sortBy'
import convertToOptions from '../utils/convertToOptions'

export default (state) => {
  const { items } = state.entities
  const options = convertToOptions(items)
  if (options.length === 0) return options
  const sorted = sortBy(options, ['label'])
  return sorted
}
