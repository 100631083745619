import { connect } from 'react-redux'
import ManageAccountsView from '../../components/ManageAccountsView'
import groupAccounts from '../../selectors/groupAccounts'
import getVisibleAccounts from '../../selectors/getVisibleAccounts'
import * as a from './actions'

export const mapStateToProps = (state) => ({
  groups: groupAccounts(getVisibleAccounts(state)),
  showSecondButton: Object.keys(state.accounts.items).length > 12,
  isSaving: state.accounts.isSaving,
  showSavedMessage: state.accounts.showSavedMessage,
  entity: state.entities.activeEntityId,
})

const mapDispatchToProps = (dispatch) => ({
  onSave: () => dispatch(a.accountsOrderSubmit()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageAccountsView)
