import * as React from 'react'

function SvgPrint(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 19.2 17.5'
      width='1em'
      height='1em'
      {...props}
    >
      <g data-name='Layer 2'>
        <path
          d='M18.55 7.94A2.14 2.14 0 0017 7.29h-.74V4.38a2.6 2.6 0 00-.23-1 2.62 2.62 0 00-.55-.87L13.71.77a2.69 2.69 0 00-.88-.55 2.7 2.7 0 00-1-.23H4.06a1.07 1.07 0 00-.78.32 1.05 1.05 0 00-.28.78v6.2h-.78a2.15 2.15 0 00-1.56.64A2.09 2.09 0 000 9.48v4.74a.35.35 0 00.11.26.36.36 0 00.26.11H3v1.82a1 1 0 00.32.77 1.07 1.07 0 00.78.32h11.04a1.07 1.07 0 00.78-.32 1 1 0 00.32-.77v-1.82h2.58a.36.36 0 00.26-.11.35.35 0 00.11-.26V9.48a2.09 2.09 0 00-.64-1.54zM14.77 16H4.43v-2.87h10.34zm0-7.29H4.43V1.46h7.38v1.82a1 1 0 00.32.77 1.08 1.08 0 00.78.32h1.85zM17.5 10a.73.73 0 01-1 0 .71.71 0 010-1 .73.73 0 011 0 .71.71 0 010 1z'
          fill='#5e5e5e'
          data-name='Layer 1'
        />
      </g>
    </svg>
  )
}

export default SvgPrint
