/* eslint-disable */
// @ts-nocheck
// todo - eslint and typescript
import { call, put, select, takeLatest, delay } from 'redux-saga/effects'
import { getFormValues, startAsyncValidation, stopAsyncValidation, touch } from 'redux-form'
import * as c from '../../constants'
import request from '../../utils/request'
import { decodeHTML } from '../../utils/decodeHTML'
import {
  paymentMakeNewPayeeDetailsUpdate,
  paymentMakeNewPayeeDetailsClear,
} from '../../containers/PaymentMake/actions'

/**
 * Checks sortcode to see if valid, passes error to relevant
 * redux form if validation fails
 */
export function* handleValidate(action) {
  const p = action.payload
  let details

  yield delay(0)
  const selector = yield call(getFormValues, p.formId)
  const formData = yield select(selector)
  const sortCode = formData && formData.sortCode

  if (sortCode && sortCode.length === 6) {
    yield put(startAsyncValidation(p.formId))

    try {
      const nonce = yield call(request, {
        id: c.requestNonce,
        url: 'v1/session/nonce',
      })
      details = yield call(request, {
        id: c.requestVerifySortCode,
        url: `v1/financialInstitution/nationalClearingDetails/UK/${sortCode}`,
        nonce,
      })
      if (details.badSortCode) {
        yield put(
          stopAsyncValidation(p.formId, {
            sortCode: 'badSortCode',
          })
        )
        yield put(paymentMakeNewPayeeDetailsClear())

        yield put(touch(p.formId, ['sortCode']))
      } else if (details.noFastPayments) {
        yield put(
          stopAsyncValidation(p.formId, {
            sortCode: 'noFastPayments',
          })
        )
        yield put(paymentMakeNewPayeeDetailsClear())

        yield put(touch(p.formId, ['sortCode']))
      } else {
        yield put(stopAsyncValidation(p.formId))
        details.bankName = decodeHTML(details.bankName)
        yield put(paymentMakeNewPayeeDetailsUpdate(details))
      }
    } catch (error) {
      yield put(paymentMakeNewPayeeDetailsClear())
      yield put(touch(p.formId, ['sortCode']))
    }
  }
}

export function* watchSortCodeValidate() {
  yield takeLatest(c.SORTCODE_VALIDATE, handleValidate)
}
