import React from 'react'
import s from './styles.css'

type DetailListProps = {
  items: any[]
}

const DetailList = ({ items }: DetailListProps) => (
  <ul className={s.root}>
    {items.map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <li key={index} className={s[item.type]}>
        <strong>{item.title}:</strong>
        <span> {item.value}</span>
      </li>
    ))}
  </ul>
)

export default DetailList
