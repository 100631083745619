import React, { KeyboardEvent } from 'react'
import s from './styles.css'

import SortCodeField from './SortCodeField'

interface SortCodeProps {
  input: any
}

class SortCode extends React.Component<SortCodeProps> {
  inputRefs: any[]

  constructor(props: SortCodeProps | Readonly<SortCodeProps>) {
    super(props)
    this.handleKeyUp = this.handleKeyUp.bind(this)
    this.inputRefs = []
  }

  componentDidMount() {
    this.constructVal()
  }

  // todo - any ??
  // handleKeyUp(e : KeyboardEvent<HTMLInputElement | HTMLSelectElement>) {
  handleKeyUp(e: any) {
    this.constructVal()

    if (e.target.value.length === 2 && e.key !== 'Shift' && e.key !== 'Tab') {
      switch (e.target.name) {
        case `${this.props.input.name}_0`:
          this.inputRefs[1].getRenderedComponent().focus()
          break
        case `${this.props.input.name}_1`:
          this.inputRefs[2].getRenderedComponent().focus()
          break
        case `${this.props.input.name}_2`:
          this.inputRefs[2].getRenderedComponent().blur()
          break
        default:
      }
    }
  }

  constructVal() {
    const val = `${this.inputRefs[0].getRenderedComponent().value}${
      this.inputRefs[1].getRenderedComponent().value
    }${this.inputRefs[2].getRenderedComponent().value}`

    this.props.input.onChange(val)
  }

  render() {
    return (
      <div className={s.root}>
        <div className={s.field}>
          <SortCodeField
            name={`${this.props.input.name}_0`}
            inputRef={(input) => {
              this.inputRefs[0] = input
            }}
            onKeyUp={this.handleKeyUp}
          />
        </div>
        <div className={s.field}>
          <SortCodeField
            name={`${this.props.input.name}_1`}
            inputRef={(input) => {
              this.inputRefs[1] = input
            }}
            onKeyUp={this.handleKeyUp}
          />
        </div>
        <div className={s.field}>
          <SortCodeField
            name={`${this.props.input.name}_2`}
            inputRef={(input) => {
              this.inputRefs[2] = input
            }}
            onKeyUp={this.handleKeyUp}
          />
        </div>
      </div>
    )
  }
}

export default SortCode
