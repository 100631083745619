import React from 'react'
import { Link, browserHistory } from 'react-router'
import IconButton from '../IconButton'
import s from './styles.css'
import Button from '../Button'

const renderLink = (index: number, text: string, to: string, items: any[]) => {
  if (index === items.length - 1) {
    return (
      <li key={index} className={s.item}>
        {text}
      </li>
    )
  }
  return (
    <li key={index} className={s.item}>
      <Link to={to}>{text}</Link>
    </li>
  )
}

type BreadcrumbsProps = {
  items: any[]
  showBack?: boolean
  back?: any
}

const Breadcrumbs = ({ items, showBack, back }: BreadcrumbsProps) => (
  <div className={s.root}>
    <IconButton
      icon='chevronLeft'
      text='Go Back'
      onClick={browserHistory.goBack}
      className={s.backStyle}
    />
    <nav className={s.nav}>
      <ul className={s.list}>
        {items.map((item, index) => renderLink(index, item.text, item.to, items))}
      </ul>
    </nav>
    {showBack && <Button text={back.text} to={back.to} />}
  </div>
)

export default Breadcrumbs
