import { call, put, takeEvery, select } from 'redux-saga/effects'
import * as c from '../../constants'
import * as a from './actions'
import { accountsMappingAdd } from '../Accounts/sagas'
import humanError from '../../utils/humanError'
import { modalErrorShow } from '../../store/ui'
import { flowSuccess, flowMergeData } from '../../store/flow'
import getRouteQuery from '../../selectors/getRouteQuery'
import getFlowData from '../../selectors/getFlowData'
import getAccounts from '../../selectors/getAccounts'
import { formError } from '../../sagas/formErrors/actions'
import request from '../../utils/request'
import getUserPermittedFunctions from '../../selectors/getUserPermittedFunctions'
import getChequeStatusAccounts from '../../selectors/getChequeStatusAccounts'
import {
  listMergeItems,
  listClearItems,
  listFetchStop,
} from '../../reducerCreators/listReducer/actions'
import { accountCustomerAddressUpdate } from '../Accounts/actions'

export function* handleInit() {
  try {
    yield call(
      accountsMappingAdd,
      'chequeBooks',
      'accServ',
      'hasChequeBooks',
      c.requestChequeBookMapping,
      'CH_N_CBR'
    )
    yield call(
      accountsMappingAdd,
      'payInBooks',
      'accServ',
      'hasPayInBooks',
      c.requestPayInBookMapping,
      'CH_N_PBR'
    )
    yield call(
      accountsMappingAdd,
      'chequeStatus',
      'chequeStatus',
      'hasChequeStatus',
      c.reqChqStatMapp,
      'CH_I_CSE'
    )
    let { accountId } = yield select(getRouteQuery)
    const userPermittedFunctions = yield select(getUserPermittedFunctions)
    const dDBaseUrl = '/v1/accounts/demandDeposit'
    if (userPermittedFunctions.chequeStatus) {
      let accounts = yield select(getAccounts)
      if (!accountId) {
        accounts = yield select(getChequeStatusAccounts)
        accountId = 0
      }
      const nonce = yield call(request, {
        id: c.requestNonce,
        url: 'v1/session/nonce',
      })
      const response = yield call(request, {
        id: c.requestChequeStatus,
        url: `${dDBaseUrl}/${accounts[accountId].accountId}/issuedCheques?startChequeNo=000001&endChequeNo=010000`,
        nonce,
        partyId: accounts[accountId].customerId,
      })
      if (response.items) {
        yield put(listClearItems('cheque-status-list'))
        yield put(listMergeItems('cheque-status-list', response.items))
        yield put(listFetchStop('cheque-status-list'))
      }
      if (accountId) {
        yield put(accountCustomerAddressUpdate(accountId))
      }
    }
  } catch (error) {
    console.error(error)
    const message = yield call(humanError, error)
    yield put(modalErrorShow(message))
  }
}

export function* handleSubmit(action) {
  try {
    const formData = action.payload
    const flowData = yield select(getFlowData)
    const type = flowData.serviceType
    const account = yield select((state: any) => state.accounts.items[formData.account.value])
    const partyId = account.customerId
    const { accountId } = account
    let service = 'chequebookRequest'
    if (type !== 'chequeBooks') {
      service = 'payInBookRequest'
    }
    const nonce = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    const response = yield call(request, {
      id: c.requestAccountServicesDeliverBook,
      url: `/cz/v1/${service}`,
      nonce,
      partyId,
      payload: {
        accNo: accountId,
      },
    })

    yield put(flowSuccess())
    yield put(
      flowMergeData({
        // eslint-disable-next-line no-underscore-dangle
        needsAuthoriser: response._needsAuthoriser,
      })
    )
  } catch (error) {
    console.error(error)
    yield put(formError(a.submitOrder.FAILURE, error))
  }
}

export function* watchAccountServices() {
  yield takeEvery(c.ACCOUNT_SERVICES_INIT, handleInit)
  yield takeEvery(a.submitOrder.REQUEST, handleSubmit)
}
