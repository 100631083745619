import React from 'react'
import PropTypes from 'prop-types'
import ActionHeader from '../ActionHeader'
import InnerContainer from '../InnerContainer'
import Breadcrumbs from '../Breadcrumbs'
import MailForm from '../../containers/MailForm'
import FlowSuccess from '../FlowSuccess'
import FlowSwitch from '../../containers/FlowSwitch'
import s from './styles.css'

type MailDetailProps = {
  subject: string
  date: string
  sender: string
  threadId: string
  id: string
  body?: string
  thread?: any[]
  canReply?: boolean
  customerId?: string
}

type MessageProps = {
  subject: string
  date: string
  sender: string
  body?: string
}

const Message = ({ subject, date, sender, body }: MessageProps) => (
  <div className={s.message}>
    <span className={s.from}>From: {sender}</span>
    <span className={s.date}>{date}</span>
    <p>{body}</p>
  </div>
)

const MailDetail = ({
  id,
  subject,
  date,
  sender,
  body,
  threadId,
  thread,
  canReply,
  customerId,
}: MailDetailProps) => (
  <div>
    <Breadcrumbs
      items={[
        {
          to: '/secure-mail/f/inbox',
          text: 'Secure mailbox',
        },
        {
          to: '/secure-mail/f/inbox',
          text: subject,
        },
      ]}
    />
    <InnerContainer>
      <ActionHeader title='Secure mailbox' />
      <h2 className={s.subject}>{subject}</h2>
      <Message subject={subject} date={date} sender={sender} body={body} />

      {thread && thread.map((item) => <Message {...item} key={item.id} />)}
      {canReply && (
        <div className={s.reply}>
          <h2>Reply</h2>
          <FlowSwitch
            SuccessComponent={FlowSuccess}
            successComponentProps={{
              message: 'Reply sent',
              links: [
                {
                  to: '/accounts',
                  text: 'Back to my accounts',
                },
              ],
            }}
            formEl={
              <MailForm
                replyingTo={id}
                threadId={threadId}
                customerId={customerId}
                customSubject={subject}
              />
            }
          />
        </div>
      )}
    </InnerContainer>
  </div>
)

export default MailDetail
