import React from 'react'
import PaymentsBulkConfirm from '../../containers/PaymentsBulkConfirm'
import View from './View'

type PaymentsBulkViewProps = {
  verifySuccess?: boolean
  bulkUploadVerify: () => void // todo - was added as View do need them
  minDate: any // todo - was added as View do need them
  mappingList: any // todo - was added as View do need them
}

const PaymentsBulkView = (props: PaymentsBulkViewProps) => (
  <div>{props.verifySuccess ? <PaymentsBulkConfirm /> : <View {...props} />}</div>
)

export default PaymentsBulkView
