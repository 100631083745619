import { createFormAction } from 'redux-form-saga'
import * as c from '../../constants'

export interface IchangeCredentialsRegisterSteps {
  [key: string]: string
}
export function changeCredentialsRegisterSteps(flags: IchangeCredentialsRegisterSteps) {
  return {
    type: c.CHANGE_CREDENTIALS_REGISTER_STEPS,
    payload: {
      flags,
    },
  }
}

export function changeCredentialsNextStep() {
  return {
    type: c.CHANGE_CREDENTIALS_NEXT_STEP,
  }
}

export function authTokenFailure() {
  return {
    type: c.AUTH_TOKEN_FAILURE,
  }
}

export function displayLoader() {
  return {
    type: c.DISPLAY_LOADER,
  }
}

export const forceChangePass = createFormAction('FORCE_CHANGE_PASS')
export const forceChangePin = createFormAction('FORCE_CHANGE_PIN')
export const acceptTerms = createFormAction('ACCEPT_TERMS')
