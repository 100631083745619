import React, { ReactNode } from 'react'
import { Field } from 'redux-form'
import Tooltip from '../../../containers/Tooltip'
import s from './styles.css'

type InputWithTooltipProps = {
  field: {
    component?: ReactNode
    name: string
    label: string
    layout?: string
    type?: string
    InputComponent?: ReactNode
    inputComponentProps?: any
    validate?: (value: any) => void
    validationMessages?: any
  }
  tooltip: {
    id: string
    text: string
    icon?: string
  }
}

const InputWithTooltip = ({ tooltip, field }: InputWithTooltipProps) => (
  <div className={s.block}>
    <Field {...field} />
    <Tooltip id={tooltip.id} text={tooltip.text} inline>
      <div className={`${s.icon} ${field.layout === 'inline' ? s.inline : undefined}`} aria-hidden>
        {tooltip.icon || '?'}
      </div>
    </Tooltip>
  </div>
)

export default InputWithTooltip
