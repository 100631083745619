import React from 'react'
import s from './styles.css'

type FormProps = {
  onSearch: () => void
  onDatesClearClick: () => void
  onDateClick: () => void
  dateRangeText?: string
  searchText?: string
  dateFilterSelected?: boolean
}

export const Form = ({
  onSearch,
  onDateClick,
  dateRangeText,
  onDatesClearClick,
  searchText,
  dateFilterSelected,
}: FormProps) => (
  <form className={s.root}>
    <div className={s.largeCol} style={{ width: dateFilterSelected ? '49%' : '42%' }}>
      <label>Search</label>
      <input
        type='search'
        placeholder='Search for a transaction keyword (min 3 chars)'
        onChange={onSearch}
      />
    </div>
    <div className={s.largeCol}>
      <label>Dates</label>
      <div className={s.dates}>
        <input
          readOnly
          placeholder='No dates specified'
          type='text'
          onClick={onDateClick}
          onKeyPress={onDateClick}
          value={dateRangeText}
        />
        {dateRangeText && (
          <a href='#' className={s.clearDates} onClick={onDatesClearClick}>
            &times;
          </a>
        )}
      </div>
    </div>
  </form>
)

export default Form
