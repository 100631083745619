import React from 'react'
import s from './styles.css'
import Icon from '../Icon'

type NothingToShowProps = {
  title: string
}

const NothingToShow = ({ title }: NothingToShowProps) => (
  <div className={s.root}>
    <Icon icon='search' className={s.icon} />
    <p>
      <strong>{title}</strong>
    </p>
    <p>
      You may need to select a different entity. If you feel this is an error, please contact
      Weatherbys on +44&nbsp;(0)1933&nbsp;543543 or email us at{' '}
      <a href='mailto:bank@weatherbys.bank'>bank@weatherbys.bank</a>.
    </p>
  </div>
)

export default NothingToShow
