import moment from 'moment'
import get from 'lodash/get'

// eslint-disable-next-line import/prefer-default-export
export const getFieldText = (state, id) => {
  const picker = get(state, `superDatePicker.pickers.${id}`)
  const startDate = picker.lastSubmittedStartDate
  const endDate = picker.lastSubmittedEndDate
  const format = 'Do MMM YYYY'
  return `${moment(startDate).format(format)} - ${moment(endDate).format(format)}`
}
