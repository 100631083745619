import React from 'react'
import { Link } from 'react-router'
import s from './styles.css'

type SidebarProps = {
  items: {
    to: string
    title: string
  }[]
}

const Sidebar = ({ items }: SidebarProps) => (
  <nav className={s.root}>
    <ul className={s.list}>
      {items.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li className={s.item} key={index}>
          <Link to={item.to} className={s.link} activeClassName={s.active}>
            <span className={s.linkInner}>{item.title}</span>
          </Link>
        </li>
      ))}
    </ul>
  </nav>
)

export default Sidebar
