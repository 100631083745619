import React from 'react'
import { Link } from 'react-router'
import SubmitCancel from '../Forms/SubmitCancel'
import DetailsGroup from '../DetailsGroup'
import AuthorisationsDeclineModal from '../../containers/Authorisations/modal'
import s from './styles.css'

type AuthorisationsCardProps = {
  type: string
  typeTitle: string
  requestedBy: string
  created: string
  onDeclineClick: () => void
  onAuthoriseClick: () => void
  detailItems?: {
    descriptor?: string
    title?: string
    extras?: string[]
    actionText?: string
    onActionClick?: () => void
    respondMode?: string
  }[]
  isSubmitting?: boolean
  isCancelling?: boolean
  submitDisabled?: boolean
  cancelDisabled?: boolean
  status?: string
  reference?: string
  actionsAreShowing?: boolean
  isValid?: boolean
  bulkReference?: string | null
}

export const AuthorisationsCard = ({
  type,
  typeTitle,
  requestedBy,
  created,
  onAuthoriseClick,
  onDeclineClick,
  detailItems,
  isSubmitting,
  isCancelling,
  submitDisabled,
  cancelDisabled,
  status,
  reference,
  actionsAreShowing,
  isValid,
  bulkReference,
}: AuthorisationsCardProps) => (
  <div className={`${s.root} ${isSubmitting ? s.nonClickable : ''}`}>
    <header className={s.header}>
      <span className={s.headerItem}>
        <strong>Type</strong> {typeTitle}
      </span>
      <span className={s.headerItem}>
        <strong>Requested by</strong> {requestedBy}
      </span>
      <span className={s.headerItem}>
        <strong>Date initiated</strong> {created}
      </span>
    </header>
    <div className={s.body}>
      <DetailsGroup items={detailItems} />
    </div>
    {bulkReference && (
      <Link to={`/payments/bulk/file/${bulkReference}?back=auth`} className={s.bulkLink}>
        View bulk file details
      </Link>
    )}
    <footer className={s.footer}>
      {!isValid && (
        <span className={s.pastMessage}>
          This transaction can no longer be authorised as it is dated in the past.
        </span>
      )}
      {actionsAreShowing && (
        <SubmitCancel
          submitting={isSubmitting}
          submitText='Authorise'
          cancelText='Decline'
          onSubmitClick={onAuthoriseClick}
          onCancelClick={onDeclineClick}
          showSubmit={isValid}
          submitDisabled={submitDisabled}
          cancelDisabled={cancelDisabled}
          cancelling={isCancelling}
          refreshWarning="Please do not refresh your browser or click the 'back' button"
          cancelLoaderText='Declining'
        />
      )}
      {status === 'Authorised' && <div className={s.authorised}>{status}</div>}
      {status === 'Declined' && <div className={s.declined}>{status}</div>}
      {status === 'Semi Authorised' && <div className={s.semi}>{status}</div>}
    </footer>
    <AuthorisationsDeclineModal id={reference} />
  </div>
)

export default AuthorisationsCard
