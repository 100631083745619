class APIError extends Error {
  code: string | number

  constructor(message: string, code: string | number) {
    super(message)
    this.name = 'API'
    this.code = code
  }
}

export default APIError
