import { dontDuplicate } from '../utils/routerBugWorkaround'

export default (store) => ({
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const Payees = require('../containers/Payees').default
        const { payeesFetchRequest } = require('../containers/Payees/actions')

        dontDuplicate(() => {
          store.dispatch(payeesFetchRequest())
        })

        cb(null, Payees)
      },
      'payees'
    )
  },
})
