import { connect } from 'react-redux'
import includes from 'lodash/includes'
import mapValues from 'lodash/mapValues'
import MegaMenu from '../../components/Header/MegaMenu'
import groupAccounts from '../../selectors/groupAccounts'
import getUserPermittedFunctions from '../../selectors/getUserPermittedFunctions'
import getVisibleAccounts from '../../selectors/getVisibleAccounts'
import { getMenuItems } from './selectors'

const mapStateToProps = (state) => ({
  items: getMenuItems(groupAccounts(getVisibleAccounts(state))),
  mappingList: getUserPermittedFunctions(state),
  availableItems: includes(mapValues(state.accounts.items, 'canMakeBulkPayment'), true),
})

export default connect(mapStateToProps, null)(MegaMenu)
