import * as React from 'react'

function COPErrorCrossIcon(props) {
  return (
    <svg
      {...props}
      width='15'
      height='15'
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='7.5' cy='7.5' r='7.5' fill='#B45353' />
      <line x1='4.29289' y1='10.2929' x2='10.2929' y2='4.29289' stroke='white' stroke-width='2' />
      <path d='M10 10.5L4 4.5' stroke='white' stroke-width='2' />
    </svg>
  )
}

export default COPErrorCrossIcon
