import * as c from '../constants'
import { IAccountsSummary } from '../utils/tidyResponse/tidyAccountsSummary'

// ------------------------------------
// Action creators
// ------------------------------------

export function entitiesChangeActive(id) {
  return {
    type: c.ENTITIES_CHANGE_ACTIVE,
    payload: {
      id,
    },
  }
}

export function entitiesUpdateItems(items) {
  return {
    type: c.ENTITIES_UPDATE_ITEMS,
    payload: {
      items,
    },
  }
}

export interface IEntitiesState {
  activeEntityId: boolean | string
  activeEntityTitle?: string
  items: IAccountsSummary['entities']
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState: IEntitiesState = {
  activeEntityId: false,
  activeEntityTitle: undefined,
  items: {},
}

export default function entityReducer(state = initialState, action): IEntitiesState {
  switch (action.type) {
    case c.ENTITIES_CHANGE_ACTIVE: {
      let id: string | boolean = false // todo - needs to refactor for it to be only string
      let title

      if (action.payload.id) {
        id = state.items[action.payload.id].id
        title = state.items[action.payload.id].title
      }

      return {
        ...state,
        activeEntityId: id,
        activeEntityTitle: title,
      }
    }
    case c.ENTITIES_UPDATE_ITEMS:
      return {
        ...state,
        items: action.payload.items,
      }
    default:
      return state
  }
}
