export interface GetURLS {
  routing: {
    locationBeforeTransitions: {
      pathname: string
      search: string
      hash: string
      action: string
      key: string | null | number
      basename: string
      query: {
        name: string
      }
    }
  }
}
export default (state: GetURLS) => {
  const { locationBeforeTransitions: location } = state.routing
  const { query, pathname, basename } = location

  const broken = pathname.split('/')
  const last = broken[broken.length - 1]
  const full = `${basename}${pathname}`
  const path = {
    last,
    broken,
    full,
  }

  const url = {
    path,
    query,
  }

  return url
}
