export default (response: {
  faml: {
    response: {
      xmldatadownloadresponsedto: {
        data: string
      }
    }
  }
}) => {
  const { data } = response.faml.response.xmldatadownloadresponsedto
  return {
    data,
  }
}
