import React, { ReactNode } from 'react'
import s from './styles.css'

type StyleguideItemProps = {
  children: ReactNode // todo - was element.
  title: string
  type?: string
}

const StyleguideItem = ({ children, title, type }: StyleguideItemProps) => {
  let className = ''

  switch (type) {
    case 'wide':
      className = ''
      break
    default:
      className = s.inner
  }

  return (
    <div>
      <div className={s.inner}>
        <h2 className={s.label}>{title}</h2>
      </div>
      <div className={`${className} ${s.spaceBelow}`}>{children}</div>
    </div>
  )
}

export default StyleguideItem
