import React from 'react'
import Breadcrumbs from '../Breadcrumbs'
import HeadingWithPrint from '../HeadingWithPrint'
import s from './styles.css'
import InnerContainer from '../InnerContainer'
import Modal from '../../containers/ModalReceipt'
import FlowConfirm from '../../containers/FlowConfirm'

type PaymentsBulkConfirmViewProps = {
  printIsVisible: boolean
  onReceiptClick?: () => void
  onReceiptModal?: () => void
}

const PaymentsBulkConfirmView = ({
  onReceiptClick,
  onReceiptModal,
  printIsVisible,
  ...rest
}: PaymentsBulkConfirmViewProps) => (
  <div>
    <Breadcrumbs
      items={[
        {
          to: '/payments',
          text: 'Payments',
        },
        {
          to: '/payments/bulk',
          text: 'Bulk payments',
        },
        {
          to: '/payments/bulk/confirm',
          text: 'Confirm bulk payment',
        },
      ]}
    />
    <InnerContainer>
      <HeadingWithPrint
        title='Confirm bulk payment'
        showPrint={printIsVisible}
        showReceipt={printIsVisible}
        onReceiptClick={() => {
          onReceiptClick()
          onReceiptModal()
        }}
      />
    </InnerContainer>
    <FlowConfirm {...rest} />
    <Modal id='eReceipt' className={s.modal} size='small' />
  </div>
)

export default PaymentsBulkConfirmView
