import { connect } from 'react-redux'
import PaymentMakeNewPayee from '../../components/PaymentMakeNewPayee'
import { confirmPayee, copReset } from '../ConfirmationOfPayee/actions'
import { paymentMakeNewPayeeHide } from '../PaymentMake/actions'

const mapStateToProps = (state) => ({
  copStatus: state.cop.status,
  copLoading: state.cop.copLoading,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  confirmPayee,
  onViewPayeesClick: () => {
    dispatch(paymentMakeNewPayeeHide())
  },
  onCopFlowReset: () => {
    dispatch(copReset())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMakeNewPayee)
