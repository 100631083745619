import { connect } from 'react-redux'
import Notes from '../../components/ActivityLogSingleView/notes'
import niceTableData from '../../utils/niceTableData'

const mapStateToProps = (state, ownProps) => {
  const tableFormat = {
    mapRowIdTo: 'status',
    cols: [
      {
        header: 'Authoriser',
        mapTextTo: 'authedBy',
        perc: 20,
      },
      {
        header: 'Authorised on',
        mapTextTo: 'authedOn',
        perc: 20,
      },
      {
        header: 'Status',
        mapTextTo: 'status',
        perc: 20,
      },
      {
        header: 'Value date',
        mapTextTo: 'date',
        perc: 20,
      },
      {
        header: 'Notes',
        mapTextTo: 'note',
        perc: 20,
        wrap: true,
      },
    ],
    card: {
      mapTitleTo: 'status',
      mapTextTo: 'authedBy',
      mapDetailsTo: 'authedOn',
    },
  }

  const { audit } = state.activityLog.single.items
  const auditsCorrectDates =
    audit &&
    audit.map((a) => ({
      ...a,
      date: a.date ? a.date.substring(0, 10) : '',
    }))

  const niceData = niceTableData(auditsCorrectDates, tableFormat)

  return {
    rows: niceData.body,
    headerCols: niceData.header,
    failedToFetch: state.activityLog.single.errorMessage,
    errorCode: state.activityLog.single.errorCode,
    isFetching: state.activityLog.single.isFetching,
    cardForSmall: true,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Notes)
