import getRouteQuery from '../../selectors/getRouteQuery'
import getPayInBookAccounts from '../../selectors/getPayInBookAccounts'
import getChequeBookAccounts from '../../selectors/getChequeBookAccounts'
import getChequeStatusAccounts from '../../selectors/getChequeStatusAccounts'
import getAccount from '../../selectors/getAccount'

// eslint-disable-next-line import/prefer-default-export
export const getAccountServicesVisibility = (state) => {
  const { accountId } = getRouteQuery(state)
  const permitted = state.user.permittedFunctions
  const chequeBooksEnabled = permitted.includes('chequeBooks')
  const payInBooksEnabled = permitted.includes('payInBooks')
  const chequeStatusEnabled = permitted.includes('chequeStatus')
  const hasAnyChequeBookAccounts = getChequeBookAccounts(state).length > 0
  const hasAnyPayInBookAccounts = getPayInBookAccounts(state).length > 0
  const hasAnyChequeStatusAccounts = getChequeStatusAccounts(state).length > 0

  if (!accountId) {
    return {
      chequeBooks: chequeBooksEnabled && hasAnyChequeBookAccounts,
      payInBooks: payInBooksEnabled && hasAnyPayInBookAccounts,
      chequeStatus: chequeStatusEnabled && hasAnyChequeStatusAccounts,
    }
  }
  const account = getAccount(state, accountId)
  return {
    chequeBooks: chequeBooksEnabled && account.hasChequeBooks,
    payInBooks: payInBooksEnabled && account.hasPayInBooks,
    chequeStatus: chequeStatusEnabled && account.hasChequeStatus,
  }
}
