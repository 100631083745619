import * as c from '../../constants'

export function authorisationsInit() {
  return {
    type: c.AUTHORISATIONS_INIT,
  }
}

export function authorisationsFetchRequest() {
  return {
    type: c.AUTHORISATIONS_FETCH_REQUEST,
  }
}

export function authorisationsProcessRequest(id: string, type: string) {
  return {
    type: c.AUTHORISATIONS_PROCESS_REQUEST,
    payload: { id, type },
  }
}

export function authorisationsProcessSuccess() {
  return {
    type: c.AUTHORISATIONS_PROCESS_SUCCESS,
  }
}
