import React from 'react'
import TextJumpoffs from '../TextJumpoffs'
import s from './links.css'

const ThirdLinks = () => (
  <div className={s.root}>
    <TextJumpoffs
      items={[
        {
          title: 'Racing Plus',
          bodyText: `Access a range of benefits such as prize money bonuses and access to a range of
          financial services`,
          linkText: 'Find out more',
          to: '/services/racingplus',
          buttonClass: 'button',
          button: false,
          border: true,
        },
        {
          title: 'Invoice services',
          bodyText:
            'Our expert Racing Bank team can pay your bills and take care of queries on your behalf',
          linkText: 'Find out more',
          to: '/services/invoiceservices',
          button: false,
          border: true,
        },
        {
          title: 'VAT services',
          bodyText:
            'Receive expert VAT advice and let us take the administrative burden away from you',
          linkText: 'Find out more',
          to: '/services/vatservices',
          button: false,
          border: true,
        },
      ]}
    />
  </div>
)

export default ThirdLinks
