import React from 'react'
import { Link } from 'react-router'
import s from './styles.css'

type LinkListProps = {
  items: any[]
}

const LinkList = ({ items }: LinkListProps) => (
  <nav className={s.root}>
    <ul className={s.list}>
      {items
        .filter((item) => !item.excluded)
        .map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={index} className={s.item}>
            <Link to={item.to}>{item.title}</Link>
          </li>
        ))}
    </ul>
  </nav>
)

export default LinkList
