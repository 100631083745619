import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import { Dispatch } from 'redux'
import ConfirmationOfPayee from '../../components/ConfirmationOfPayee'
import { findHeaderAndDescription, prepareHumanErrorState } from './helper'
import { ConfirmationOfPayeeStatus } from './reducer'

const mapStateToProps = (state, ownProps) => {
  const formData = getFormValues(ownProps.formName)(state)

  return {
    copStatus: state.cop.status,
    copLoading: state.cop.copLoading,
    formData,
    ...(state.cop.status === ConfirmationOfPayeeStatus.FAILED
      ? {
          ...findHeaderAndDescription(state.cop.errorState.errorCode),
          errorDetails: state.cop.errorState.errorDetails?.map((errorDetail) =>
            prepareHumanErrorState(
              state.cop.errorState?.errorCode,
              errorDetail.type,
              formData[errorDetail.type],
              errorDetail.value
            )
          ),
          errorState: state.cop.errorState,
        }
      : {}),
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationOfPayee)
