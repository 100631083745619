import React from 'react'
import { Link } from 'react-router'
import s from './styles.css'
import LinkReplace from '../LinkReplace'
import Icon from '../Icon'

type ButtonProps = {
  text: string
  to?: string | any
  replace?: string
  style?: string
  size?: string
  className?: string
  submit?: boolean
  disabled?: boolean
  loading?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const Button = ({
  text,
  style = 'secondary',
  size,
  submit,
  onClick,
  disabled,
  loading,
  to,
  replace,
  className,
}: ButtonProps) => {
  if (disabled) {
    // eslint-disable-next-line no-param-reassign
    if (submit) style = 'secondary'
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    return <a className={`${s.root} ${s[style]} ${s[size]} ${s.disabled} ${className}`}>{text}</a>
  }
  if (submit || onClick) {
    const loadingClass = loading ? s.loading : undefined
    return (
      // eslint-disable-next-line react/button-has-type
      <button
        onClick={onClick}
        className={`${s.root} ${s[style]} ${s[size]} ${loadingClass} ${className}`}
      >
        <div className={s.inner}>{text}</div>
        <div className={s.spinContainer}>
          <div className={s.spinInner}>
            <div className={s.spinner}>
              <Icon icon='loading' />
            </div>
          </div>
        </div>
      </button>
    )
  }
  if (to) {
    return (
      <Link to={to} className={`${s.root} ${s[style]} ${s[size]} ${className}`}>
        {text}
      </Link>
    )
  }
  if (replace) {
    return (
      <LinkReplace replace={replace} className={`${s.root} ${s[style]} ${s[size]} ${className}`}>
        {text}
      </LinkReplace>
    )
  }
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  return <a className={`${s.root} ${s[style]} ${s[size]} ${className}`}>{text}</a>
}

export default Button
