export default (store) => ({
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const ServicesExpatriate = require('../containers/ServicesExpatriate').default
        cb(null, ServicesExpatriate)
      },
      'expatriate'
    )
  },
})
