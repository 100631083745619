/* eslint-disable */
// @ts-nocheck
// todo - eslint and typescript
import React, { Component, ReactNode } from 'react'
import debounce from 'lodash/debounce'
import s from './styles.css'

interface TooltipProps {
  active?: boolean
  text: string
  onClick?: () => void
  onMouseOver: () => void
  onMouseOut: () => void
  inline?: boolean
  showOnHover?: boolean
  showOnMobile?: boolean
  children?: ReactNode
  isAndroidOriOS: () => void
}

class Tooltip extends Component<TooltipProps> {
  constructor(props) {
    super(props)
    this.onHover = debounce(this.props.onMouseOver, 0, { leading: true }).bind(this)
    this.onUnhover = debounce(this.props.onMouseOut, 0, { leading: true }).bind(this)
  }

  componentDidMount() {
    if (this.props.showOnHover && !this.props.isAndroidOriOS()) {
      this.root.addEventListener('mouseover', this.onHover)
      this.root.addEventListener('mouseout', this.onUnhover)
    }
  }

  componentWillUnmount() {
    if (this.props.showOnHover && !this.props.isAndroidOriOS()) {
      this.root.removeEventListener('mouseover', this.onHover)
      this.root.removeEventListener('mouseout', this.onUnhover)
    }
  }

  render() {
    const {
      text,
      active,
      onClick,
      inline,
      children,
      isAndroidOriOS,
      showOnMobile = true,
    } = this.props

    const rootClass = inline ? `${s.root} ${s.inline}` : s.root
    const className = active ? s.isShowing : undefined
    const rect = this.root && this.root.getBoundingClientRect()
    const left = rect && rect.left
    const right = rect && rect.right
    let tipClass = `${s.tip} ${className}`

    if (left < 60) {
      tipClass = `${tipClass} ${s.tipLeft}`
    } else if ((right - window.innerWidth) * -1 < 60) {
      tipClass = `${tipClass} ${s.tipRight}`
    }

    return (
      <div
        className={rootClass}
        onClick={isAndroidOriOS() && !showOnMobile ? undefined : onClick}
        ref={(div) => {
          this.root = div
        }}
      >
        <div className={s.content}>{children}</div>
        <div className={tipClass} role='tooltip'>
          {text}
        </div>
      </div>
    )
  }
}

export default Tooltip
