import React from 'react'
import DetailsGroup from '../DetailsGroup'
import Warnings from '../Warnings'
import s from './styles.css'
import FlowSuccess from '../FlowSuccess'
import Modal from '../../containers/ModalReceipt'
import HeadingWithPrint from '../../containers/HeadingWithPrint'

type TransferMoneySuccessProps = {
  details?: any // todo - was object
  warnings?: any[]
  needsAuthoriser?: boolean
  onReceiptClick?: () => void
  onReceiptModal?: () => void
  layout?: string
  onMakeAnotherClick?: () => void
  timeOutError?: boolean
  referenceNumber?: string
}

const TransferMoneySuccess = ({
  details,
  warnings,
  layout,
  needsAuthoriser,
  onReceiptClick,
  onReceiptModal,
  timeOutError,
  referenceNumber,
  onMakeAnotherClick,
}: TransferMoneySuccessProps) => {
  const isFull = layout === 'full'
  const links = isFull
    ? [
        { text: 'Make another transfer', to: '/payments/transfer-money' },
        { text: 'Back to my accounts', to: '/accounts' },
      ]
    : [{ text: 'Make another transfer', onClick: onMakeAnotherClick }]
  return (
    <div>
      {isFull && (
        <HeadingWithPrint
          title='Transfer complete'
          showReceipt
          onReceiptClick={() => {
            onReceiptClick()
            onReceiptModal()
          }}
        />
      )}
      {isFull && <Warnings items={warnings} />}
      {isFull && <DetailsGroup items={details} />}
      <FlowSuccess
        message='Successful transaction'
        needsAuthoriser={needsAuthoriser}
        naMessage='Transfer initiated'
        taMessage='Transaction process timed out'
        links={links}
        referenceNumber={referenceNumber}
        timeOutError={timeOutError}
      />
      <Modal id='eReceipt' className={s.modal} size='small' />
    </div>
  )
}

export default TransferMoneySuccess
