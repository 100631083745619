import userIsFirstTime from '../selectors/userIsFirstTime'
import userHasEntities from '../selectors/userHasEntities'
import { panelShow } from '../store/ui'
import { dontDuplicate } from '../utils/routerBugWorkaround'

let entitiesOnce = true

export default (store) => ({
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const state = store.getState()
        const Accounts = require('../containers/Accounts').default
        const actions = require('../containers/Accounts/actions')
        const { mailListFetchRequest } = require('../containers/MailLayout/actions')

        cb(null, Accounts)

        dontDuplicate(() => {
          store.dispatch(actions.accountsFetch())
          store.dispatch(mailListFetchRequest('outbox'))
        })

        // Check for entities on first time login, display help bar
        if (userHasEntities(state) && userIsFirstTime(state) && entitiesOnce) {
          store.dispatch(panelShow('entitiesHelp'))
          entitiesOnce = false
        }
      },
      'accounts'
    )
  },
})
