import { createFormAction } from 'redux-form-saga'
import * as c from '../../constants'

export const payeeSubmit = createFormAction('PAYEE_EDIT')
export const payeeDelete = createFormAction('PAYEE_DELETE')

export function payeesFetchRequest() {
  return {
    type: c.PAYEES_FETCH_REQUEST,
  }
}

export function payeesFetchFail(error: string) {
  return {
    type: c.PAYEES_FETCH_FAIL,
    payload: {
      error,
    },
  }
}

export function payeesFilterAlphabet(charSet: string | number) {
  return {
    type: c.PAYEES_FILTER_ALPHABET,
    payload: {
      charSet,
    },
  }
}
