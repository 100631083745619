import * as c from '../../constants'

export interface IAlertsState {
  user: any
  account: {
    alerts: {
      fields: any
    }
    isFetching?: boolean
  }
}

const initialState: IAlertsState = {
  user: {},
  account: {
    alerts: {
      fields: {},
    },
  },
}

export default function alertsReducer(state = initialState, action): IAlertsState {
  const { payload: p, type } = action
  switch (type) {
    case c.ALERTS_INIT: {
      return {
        ...state,
        user: {
          ...state.user,
          isFetching: true,
        },
        account: {
          ...state.account, // todo-ts: was ...state.accounts
          isFetching: true,
        },
      }
    }
    case c.ALERTS_UPDATE: {
      return {
        ...state,
        user: {
          ...p.user,
          isFetching: false,
        },
        // account: {
        //   number: p.account.id,
        //   title: p.account.title,
        //   alerts: p.account.alerts,
        //   isFetching: false
        // }
      }
    }
    case c.ALERTS_SAVED: {
      return {
        ...state,
        [p.type]: {
          ...state[p.type],
          showSaved: p.show,
        },
      }
    }
    default: {
      return state
    }
  }
}
