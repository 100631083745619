import React, { ReactNode } from 'react'
import s from './styles.css'

type InnerContainerProps = {
  children: ReactNode
  width?: 'narrow'
}

export const InnerContainer = ({ children, width }: InnerContainerProps) => (
  <div className={`${s.root} ${s[width]}`}>{children}</div>
)

export default InnerContainer
