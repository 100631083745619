import React, { KeyboardEvent } from 'react'
import { Field } from 'redux-form'
import * as v from '../../../../utils/forms/validators'
import * as n from '../../../../utils/forms/normalizers'

interface SortCodeFieldProps {
  name: string
  onKeyUp: (event: KeyboardEvent<HTMLInputElement | HTMLSelectElement>) => void
  inputRef: (value: any) => void
}

class SortCodeField extends React.Component<SortCodeFieldProps> {
  render() {
    return (
      <Field
        id={this.props.name}
        name={this.props.name}
        component='input'
        type='text'
        placeholder=''
        onKeyUp={this.props.onKeyUp}
        ref={this.props.inputRef}
        forwardRef
        normalize={n.sortCodeField}
        validate={v.sortCodeField}
      />
    )
  }
}

export default SortCodeField
