import sepaCountries from '../../utils/lookup/sepaCountries'

export const isEuroCountry = (state) => {
  // Can't use lodash/get cause of the hyphens
  const formData = state.form['request-international-payment']
  return (
    formData && formData.values.country && sepaCountries.includes(formData.values.country.value)
  )
}

export const getPaymentCharges = (state) => {
  const euroCountry = isEuroCountry(state)
  const formData = state.form['request-international-payment']
  const country = formData && formData.values.country && formData.values.country.value
  // Monaco and Switzerland are weird
  const isWeird = country === 'Monaco' || country === 'Switzerland'
  const isEuroCurrency = formData && formData.values.currency.value === 'EUR'

  let type
  if (euroCountry && isEuroCurrency && !isWeird) {
    type = 'Low'
  } else if (isWeird && isEuroCurrency) {
    type = 'All'
  } else if (isWeird && !isEuroCurrency) {
    type = 'High'
  } else if (euroCountry && !isEuroCurrency) {
    type = 'Fixed'
    /* eslint-disable no-debugger */
    // debugger
  } else {
    type = 'High'
  }

  return type
}
