import sortBy from 'lodash/sortBy'
import * as c from '../../constants'

export interface IStatementsState {
  items: any[]
  isFetching: boolean
  got: any[]
  failedToFetch?: boolean
  isFetchingAll?: boolean
  errorMessage?: any
}
// ------------------------------------
// Reducer
// ------------------------------------
const initialState: IStatementsState = {
  items: [],
  isFetching: false,
  got: [],
}

export default function statementsReducer(state = initialState, action): IStatementsState {
  const p = action.payload

  switch (action.type) {
    case c.R_STATEMENTS_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        failedToFetch: false,
      }
    case c.STATEMENTS_FETCH_SUCCESS: {
      let items = [...state.items, ...p.items]
      items = sortBy(items, ['timeStamp']).reverse()

      return {
        ...state,
        isFetching: false,
        items,
        got: [...state.got, p.id],
      }
    }
    case c.STATEMENTS_FETCH_STOP:
      return {
        ...state,
        isFetching: false,
        failedToFetch: false,
      }
    case c.STATEMENTS_FETCH_ALL_START:
      return {
        ...state,
        isFetchingAll: true,
        failedToFetch: false,
      }
    case c.STATEMENTS_FETCH_ALL_STOP:
      return {
        ...state,
        isFetchingAll: false,
      }
    case c.STATEMENTS_FETCH_FAIL:
      return {
        ...state,
        isFetching: false,
        isFetchingAll: false,
        failedToFetch: true,
        errorMessage: p.error,
      }
    default:
      return state
  }
}
