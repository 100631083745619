export default (store) => ({
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const ServicesVAT = require('../containers/ServicesVAT').default
        cb(null, ServicesVAT)
      },
      'vatservices'
    )
  },
})
