import { createFormAction } from 'redux-form-saga'
import { clearSubmitErrors } from 'redux-form'

import * as c from '../../constants'

export const marketingModalSubmit = createFormAction('MARKETING_MODAL')
export const marketingModalErrorClear = clearSubmitErrors('marketingConsent')

export const marketingModalDoNotWishToSee = () => ({
  type: c.MARKETING_MODAL_DO_NOT_WISH_TO_SEE,
})
