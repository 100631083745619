import React, { ReactNode } from 'react'
import s from './styles.css'
import Sidebar from '../Sidebar'

type SidebarLayoutProps = {
  children: ReactNode
  navItems: any[]
}

const SidebarLayout = ({ children, navItems }: SidebarLayoutProps) => (
  <div className={s.root}>
    <div className={s.sidebar}>
      <Sidebar items={navItems} />
    </div>
    <div className={s.main}>{children}</div>
  </div>
)

export default SidebarLayout
