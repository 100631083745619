import React from 'react'
import { reduxForm, InjectedFormProps } from 'redux-form'
import s from './styles.css'
import SubmitCancel from '../Forms/SubmitCancel'
import Fields from './Fields'

type TransactionsFiltersFormProps = {
  handleSubmit?: () => void
  onCancelClick: () => void
  isRacingAccount?: boolean
}

const TransactionsFiltersForm = ({
  handleSubmit,
  onCancelClick,
  ...rest
}: InjectedFormProps<any> & TransactionsFiltersFormProps) => (
  <form className={s.root} onSubmit={handleSubmit}>
    <Fields {...rest} />
    <SubmitCancel submitText='Apply filters' onCancelClick={onCancelClick} />
  </form>
)

const TransactionsFiltersReduxForm = reduxForm<any, TransactionsFiltersFormProps>({
  form: 'transactionsFilters',
  destroyOnUnmount: false,
})(TransactionsFiltersForm)

export default TransactionsFiltersReduxForm
