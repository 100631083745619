import * as React from 'react'

function SvgTransferMoney(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 26.65 29.17'
      width='1em'
      height='1em'
      {...props}
    >
      <defs>
        <style>{'.transfer-money_svg__cls-1{fill:#5e5e5e}'}</style>
      </defs>
      <g id='transfer-money_svg__Layer_2' data-name='Layer 2'>
        <g id='transfer-money_svg__Layer_1-2' data-name='Layer 1'>
          <ellipse className='transfer-money_svg__cls-1' cx={8.6} cy={14.83} rx={5.21} ry={2.48} />
          <path
            className='transfer-money_svg__cls-1'
            d='M13.81 15.7c0 1.37-2.33 2.48-5.21 2.48s-5.21-1.11-5.21-2.48v1.24c0 1.37 2.33 2.48 5.21 2.48s5.21-1.11 5.21-2.48zM13.81 20.66v-1.24c0 1.37-2.33 2.48-5.21 2.48s-5.21-1.11-5.21-2.48v1.24c0 1.37 2.33 2.48 5.21 2.48s5.21-1.14 5.21-2.48z'
          />
          <path
            className='transfer-money_svg__cls-1'
            d='M8.6 20c-2.88 0-5.21-1.11-5.21-2.48v1.28c0 1.37 2.33 2.48 5.21 2.48s5.21-1.11 5.21-2.48v-1.24c0 1.37-2.33 2.44-5.21 2.44z'
          />
          <ellipse
            className='transfer-money_svg__cls-1'
            cx={17.26}
            cy={10.74}
            rx={5.21}
            ry={2.48}
          />
          <path
            className='transfer-money_svg__cls-1'
            d='M22.47 11.61c0 1.37-2.33 2.48-5.21 2.48a9.71 9.71 0 01-2.85-.4 1.57 1.57 0 01.59 1.14 1.21 1.21 0 010 .25 1.13 1.13 0 000-.19v.19a10.18 10.18 0 002.26.24c2.88 0 5.21-1.11 5.21-2.48zM17.26 17.81a10.2 10.2 0 01-2.26-.25v1.24a10.18 10.18 0 002.26.24c2.88 0 5.21-1.11 5.21-2.48v-1.23c0 1.37-2.33 2.48-5.21 2.48z'
          />
          <path
            className='transfer-money_svg__cls-1'
            d='M22.47 13.47c0 1.37-2.33 2.48-5.21 2.48A10.18 10.18 0 0115 15.7v1.24a10.17 10.17 0 002.26.24c2.88 0 5.21-1.11 5.21-2.48zM24.67 14.59a11.33 11.33 0 01-11.34 11.29v-1.16a.15.15 0 00-.24-.12L10 26.74a.16.16 0 000 .26l3.13 2.14a.15.15 0 00.24-.13v-1.15a13.31 13.31 0 0013.28-13.27zM13.33 3.29v1.16a.15.15 0 00.24.13l3.13-2.14a.16.16 0 000-.26L13.57 0a.15.15 0 00-.24.13v1.18A13.32 13.32 0 000 14.59h2a11.33 11.33 0 0111.33-11.3z'
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgTransferMoney
