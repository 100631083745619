import React from 'react'
import { Link } from 'react-router'
import Breadcrumbs from '../Breadcrumbs'
import ContactContainer from '../ContactContainer'
import InnerFullWidthContainer from '../InnerFullWidthContainer'
import ServicesHeader from '../ServicesHeader'
import s from '../ServicesView/styles.css'

const addPadding = true
export const ServicesDepositAccounts = () => (
  <div>
    <Breadcrumbs
      items={[
        {
          to: '/services',
          text: 'Additional services',
        },
        {
          to: '/services/deposit',
          text: 'Deposit accounts',
        },
      ]}
    />

    <ServicesHeader
      title='Safe, secure deposit accounts'
      secondaryTitle='We go above and beyond industry standards to ensure that your money is safe and secure'
    />

    <InnerFullWidthContainer addPadding={addPadding}>
      <div className={`${s.textArea} ${s.extraTopMargin}`}>
        <p>
          When it comes to managing your money, we take a reassuringly prudent approach. Our secure,
          flexible deposit accounts include instant access, longer-term fixed rates and third-party
          cash solutions.
        </p>
      </div>
      <div className={s.linkContainer}>
        <p>
          For more information about the Scheme, visit the&nbsp;
          <a href='http://www.fscs.org.uk/' rel='noreferrer' target='_blank'>
            FSCS website
          </a>
          .
        </p>
      </div>
      <div className={s.textArea}>
        <p>
          Deposits you hold with us, up to a total of £85,000, are protected by the Financial
          Services Compensation Scheme, the UK&aposs deposit protection scheme. Any amounts that you
          hold with us above the £85,000 limit are not covered by this Scheme.
        </p>
      </div>
      <div className={s.linkContainer}>
        <Link to='/services/international'>
          Click here to learn more about our currency accounts and rates
        </Link>
      </div>
      <a href='https://www.weatherbys.bank/WeatherbysBank/media/NEW-IMAGES/Weatherbys-Bank-Deposit-Rates_August-2017.pdf'>
        Click here to see our Deposit Rates
      </a>
    </InnerFullWidthContainer>
    <InnerFullWidthContainer addPadding={addPadding}>
      <ContactContainer
        title='Find out more'
        infoText='To find out more about our Deposit Accounts, please contact the Private Bank team'
        items={[
          {
            linkTitle: '+44 (0) 20 7292 9029',
            to: '+44 (0) 20 7292 9029',
            telNumber: true,
          },
          // {
          //   linkTitle: 'Send Secure Message',
          //   to: '/secure-mail/new'
          // }
          {
            linkTitle: 'Send Secure Message',
            to: '/secure-mail/enquiry#deposit',
          },
        ]}
      />
    </InnerFullWidthContainer>
  </div>
)

export default ServicesDepositAccounts
