import moment from 'moment'
import { dontDuplicate } from '../utils/routerBugWorkaround'

export default (store) => ({
  path: 'type/:type',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const ActivityLogType = require('../containers/ActivityLogType').default
        const { activityLogTypeInit } = require('../containers/ActivityLogType/actions')
        const { superDatePickerInit } = require('../containers/SuperDatePicker/actions')
        const { type } = nextState.params
        const { status } = nextState.location.query
        dontDuplicate(() => {
          store.dispatch(activityLogTypeInit(type, status))
          store.dispatch(
            superDatePickerInit(
              'activityLogType',
              moment().startOf('day').subtract(1, 'month'),
              moment().startOf('day')
            )
          )
        })

        cb(null, ActivityLogType)
      },
      'activity-log-type'
    )
  },
})
