import { connect } from 'react-redux'
import RequestInternationalPayment from '../../components/RequestInternationalPayment'
import getUserPermittedFunctions from '../../selectors/getUserPermittedFunctions'

const mapStateToProps = (state) => ({
  userHasAccess: getUserPermittedFunctions(state).payment,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  accountId: ownProps.params.id,
})

export default connect(mapStateToProps, mapDispatchToProps)(RequestInternationalPayment)
