import React from 'react'
import s from './styles.css'

import AccountList from '../AccountList'

type AllAccountsProps = {
  items: any[]
  isLoadingEmpty?: boolean
  title?: string // todo - AccountsView is passing - but not used here??
}

const AllAccounts = ({ items, isLoadingEmpty }: AllAccountsProps) => (
  <ul>
    {isLoadingEmpty ? (
      <li className={s.row}>
        <AccountList isLoadingEmpty />
      </li>
    ) : (
      items.map((item) => (
        <li key={item.id} className={s.row} id={item.id}>
          <AccountList {...item} />
        </li>
      ))
    )}
  </ul>
)

export default AllAccounts
