import React from 'react'
import { Field, reduxForm, Form, InjectedFormProps } from 'redux-form'
import Button from '../Button'
import BottomError from '../Forms/BottomError'
import Select from '../Forms/Select'
import MoneyFormHelper from '../formHelpers/MoneyFormHelper'
import PaymentDateOptions from '../../containers/PaymentDateOptions'
import SingleField from '../Forms/SingleField'
import FlowSwitch from '../../containers/FlowSwitch'
import TransferMoneySuccess from '../../containers/TransferMoneySuccess'
import NothingToShow from '../NothingToShow'
import Modal from '../../containers/Modal'
import SubmitCancel from '../Forms/SubmitCancel'
import * as v from '../../utils/forms/validators'
import s from './styles.css'

type TransferFormProps = {
  toDisabled?: boolean
  fromOptions?: any[]
  fromAvailableBalance?: string
  toAvailableBalance?: string
  toOptions?: any[]
  handleSubmit: () => void
  transferConfirm: () => void
  error?: string
  invalid?: boolean
  cancelLink?: string
  submitting?: boolean
  disableCancel?: boolean
  currency?: string
  isLoadingOptions?: boolean
  notMapped?: boolean
  layout?: 'full' | 'strip'
  hasTransferableAccounts?: boolean
  isRegular?: boolean
  hasReference?: boolean
  onSubmitButtonClick?: () => void
  checkReference?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    hasReference: boolean
  ) => void
}

const TransferForm = ({
  toDisabled,
  fromOptions,
  fromAvailableBalance,
  toAvailableBalance,
  toOptions,
  handleSubmit,
  transferConfirm,
  error,
  invalid,
  submitting,
  isLoadingOptions,
  currency = 'GBP',
  notMapped,
  layout = 'full',
  hasTransferableAccounts,
  isRegular,
  disableCancel,
  hasReference,
  onSubmitButtonClick,
  checkReference,
  cancelLink = '/accounts',
}: InjectedFormProps<any> & TransferFormProps) => {
  const transferErrorMessage = true
  const isFull = layout === 'full'
  const fieldSetClassName = isFull ? 'border narrow' : s.fieldSet
  const fieldLayout = isFull ? 'inline' : null
  const formEl =
    !hasTransferableAccounts && !isLoadingOptions ? (
      <NothingToShow title='You have no accounts that can make transfers.' />
    ) : (
      <Form onSubmit={handleSubmit(transferConfirm)} className={isFull ? s.formFull : s.formStrip}>
        <div className={`narrow ${isFull ? s.isFull : ''}`}>
          <BottomError
            message={
              notMapped
                ? 'Sorry, the account chosen is not permitted to make transfers.'
                : undefined
            }
          />
          <span className={s.stripTop}>
            <BottomError transferErrorMessage={transferErrorMessage} message={error} />
          </span>
        </div>
        <div className={isFull ? null : s.fieldSetWrap}>
          <fieldset className={fieldSetClassName}>
            {isFull && <legend>Between</legend>}
            <div className={!isFull && s.flexWrap}>
              <div>
                <Field
                  name='from'
                  label='From'
                  component={SingleField}
                  options={fromOptions}
                  layout={fieldLayout}
                  className={s.field}
                  InputComponent={Select}
                  inputComponentProps={{
                    options: fromOptions,
                    isLoading: isLoadingOptions,
                    disabled: isLoadingOptions,
                    placeholder: isLoadingOptions ? 'Loading Options...' : undefined,
                  }}
                  validate={v.required}
                  validationMessages={{
                    required: 'Which account are you transfering from?',
                  }}
                />
                {fromAvailableBalance && (
                  <p>
                    <em>Available balance: {fromAvailableBalance}</em>
                  </p>
                )}
              </div>
              <div>
                <Field
                  name='to'
                  label='To'
                  component={SingleField}
                  options={toOptions}
                  layout={fieldLayout}
                  InputComponent={Select}
                  inputComponentProps={{
                    options: toOptions,
                    disabled: toDisabled,
                    isLoading: isLoadingOptions,
                    placeholder: isLoadingOptions ? 'Loading Options...' : undefined,
                  }}
                  validate={v.required}
                  validationMessages={{
                    required: 'Which account are you transfering to?',
                  }}
                />
                {toAvailableBalance && (
                  <p>
                    <em>Available balance: {toAvailableBalance}</em>
                  </p>
                )}
              </div>
            </div>
          </fieldset>
          <fieldset className={fieldSetClassName}>
            {isFull && <legend>Details</legend>}
            <div className={!isFull && s.flexWrap}>
              <MoneyFormHelper
                label={`Amount in ${currency}`}
                name='amount'
                validate={v.required}
                layout={fieldLayout}
                validationMessages={{
                  required: 'How much would you like to transfer?',
                }}
              />

              <Field
                name='reference'
                label='Reference'
                component={SingleField}
                layout={fieldLayout}
                type='text'
              />
            </div>
          </fieldset>

          {isFull && (
            <PaymentDateOptions formName='transferMoney' layout='inline' type='transfer' />
          )}

          <fieldset className={isFull ? 'narrow' : s.confirmWrap}>
            <span className={s.stripBottom}>
              <BottomError message={error} />
            </span>
            <Button
              disabled={invalid}
              onClick={(e) => checkReference(e, hasReference)}
              text={isFull ? 'Confirm Transfer' : 'Transfer'}
              loading={submitting}
            />
            {isFull && (
              <Button
                className={s.cancel}
                // eslint-disable-next-line react/style-prop-object
                style='ghost-dark'
                to={cancelLink}
                text='Cancel Transfer'
                loading={submitting}
                disabled={disableCancel}
              />
            )}
          </fieldset>
        </div>
        <Modal id='paymentNoReference' size='small' title='Missing reference'>
          <p>
            You have not included a reference on this transfer. Would you like to continue without a
            reference?
          </p>
          <SubmitCancel
            submitText='Continue'
            onSubmitClick={onSubmitButtonClick}
            submitClosesModal
            cancelText='Go back'
            cancelClosesModal
          />
        </Modal>
      </Form>
    )

  return (
    <FlowSwitch
      SuccessComponent={TransferMoneySuccess}
      successComponentProps={{ layout }}
      formEl={formEl}
    />
  )
}

const TransferReduxForm = reduxForm<any, TransferFormProps>({
  form: 'transferMoney',
})(TransferForm)

export default TransferReduxForm
