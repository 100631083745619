import get from 'lodash/get'

export default (data: {
  data: {
    limitUtilizationDTOs: {
      amount: {
        amount: number | string
      }
      count: number
      utilizationId: string
    }[]
  }
}) => {
  const limitLinkages = get(data, 'data.limitUtilizationDTOs', [])
  const tidyData = {}
  const targets = {
    FU_F_DFT: 'bulkPayments',
    PC_F_UK_FAS: 'makePayment',
    PC_F_SELF: 'internalTransfer',
  }

  // todo-ts: change map to forEach
  limitLinkages.forEach((item) => {
    tidyData[targets[item.utilizationId]] = {
      amount: item.amount.amount,
      count: item.count,
    }
  })

  return tidyData
}
