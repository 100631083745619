import * as c from '../../constants'

export const goOutIfSecondLoginDetected = (url, state, dispatch) => {
  dispatch({
    type: c.LOGOUT_MULTI_LOGIN_REQUEST,
    payload: {
      url,
    },
  })
}

export const updateSessionLocalStorageId = (id, state, dispatch) => {
  dispatch({
    type: c.SESSION_LOCAL_STORAGE_ID_UPDATE,
    payload: {
      sessionLocalStorageId: id,
    },
  })
}

class ConstructGoOutMultiLogin {
  store: any

  injectStore(store) {
    this.store = store
  }

  goOutIfSecondLoginDetected(url) {
    return goOutIfSecondLoginDetected(url, this.store.getState(), this.store.dispatch)
  }

  updateSessionLocalStorageId(id) {
    updateSessionLocalStorageId(id, this.store.getState(), this.store.dispatch)
  }
}

export default new ConstructGoOutMultiLogin()
