import { connect } from 'react-redux'
import { megaMenuShow, megaMenuHide } from '../../store/ui'
import NavItem from '../../components/Header/NavItem'

const mapStateToProps = (state) => ({
  megaMenu: state.ui.megaMenu,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  show: () => {
    dispatch(megaMenuShow(ownProps.navId))
  },
  hide: () => {
    dispatch(megaMenuHide())
  },
  onClick: (e, megaMenu) => {
    if (megaMenu !== ownProps.navId) {
      e.preventDefault()
      dispatch(megaMenuShow(ownProps.navId))
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(NavItem)
