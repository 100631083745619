import React from 'react'
import TableRow from '../TableRow'

type TableBodyProps = {
  rows: any[]
}

const TableBody = ({ rows, ...props }: TableBodyProps) => (
  <tbody>
    {rows.map(({ id, cols, ...row }) => (
      <TableRow {...props} key={id} id={id} cols={cols} currentRow={row} />
    ))}
  </tbody>
)

export default TableBody
