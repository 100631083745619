import get from 'lodash/get'

/**
 * Tidies deeply nested data from API into something easier to deal with!
 * @param  {Object} data - raw data from API
 * @return {Object} data - tidied data
 */

export default (data: {
  data: {
    status: {
      referenceNumber: string
      resendsLeft: string | number
      message: {
        code?: any
      }
    }
  }
}) => {
  let res = get(data, "headers['x-challenge']")
  let reference = ''
  let attemptsLeft
  let warningMsg = ''
  if (res) {
    reference = JSON.parse(res).referenceNo
    attemptsLeft = JSON.parse(res).resendsLeft
  } else {
    res = data.data.status
    reference = res.referenceNumber
    attemptsLeft = res.resendsLeft
    warningMsg =
      data.data && data.data.status && data.data.status.message && data.data.status.message.code
  }
  return {
    ref: res && reference,
    attemptsLeft: res && attemptsLeft,
    warning: warningMsg === 'DIGX_APPROVAL_REQUIRED',
  }
}
