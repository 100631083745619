import numeral from 'numeral'

const generateLocale = (code: string) => {
  const symbol: string = code === 'GBP' ? '£' : `${code} `

  return {
    delimiters: {
      thousands: ',',
      decimal: '.',
    },
    abbreviations: {},
    ordinal: {},
    currency: {
      symbol,
    },
  }
}

const formatMoney = (value: number | string, currencyCode = 'GBP', showDRCR = false) => {
  const localeCode: string = currencyCode.toLowerCase()

  if (!numeral.locales[localeCode]) {
    numeral.register('locale', localeCode, generateLocale(currencyCode))
  }

  numeral.locale(localeCode)

  let DRCR = ''

  if (showDRCR) {
    DRCR = value < 0 ? ' DR' : ' CR'
  }

  return numeral(value).format('$0,0.00') + DRCR
}

export const getCurrencySymbol = (currencyCode: string) => {
  switch (currencyCode) {
    case 'USD':
      return '$'
    case 'EUR':
      return '€'
    case 'GBP':
    default:
      return '£'
  }
}

export default formatMoney
