export interface IPublicKey {
  data: {
    status: {
      result: string
    }
    publicKeyDTO: {
      publicKey: string
      modulus: string
      publicExponent: string
    }
  }
}
export default (
  data: IPublicKey,
  state
): { modulus?: string; publicExponent?: string; publicKey?: string } => {
  if (data?.data?.publicKeyDTO?.publicKey) {
    const { modulus, publicExponent, publicKey } = data.data.publicKeyDTO
    return { modulus, publicExponent, publicKey }
  }

  return { modulus: undefined, publicExponent: undefined, publicKey: undefined }
}
