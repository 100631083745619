/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import ResultsInfo from '../ResultsInfo'
import s from './styles.css'

type NotificationsProps = {
  items?: any[]
  isLoading: boolean
}

const Notifications = ({ items, isLoading }: NotificationsProps) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
  <div className={s.root} onClick={(e) => e.stopPropagation()}>
    {/* eslint-disable-next-line no-nested-ternary */}
    {isLoading ? (
      <ResultsInfo state={{ state: 'loading' }} />
    ) : items.length ? (
      <ul>
        {items.map((item) => (
          <li key={item.id} className={s.item}>
            <h3>{item.subject}</h3>
            <p>{item.body}</p>
          </li>
        ))}
      </ul>
    ) : (
      <div className={s.noNots}>You have no notifications.</div>
    )}
  </div>
)

export default Notifications
