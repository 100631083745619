import get from 'lodash/get'
import includes from 'lodash/includes'

/**
 * Convenience function for getting mappings. 'custaccounts' is
    always in the same format for any mapping request, this handles it
    from there and returns array of account ids
 * @param  {Object} data - raw data from API
 * @param {Object} state - current state of application
 * @param {String} propertyPathToCustomers - for get() to grab customer object
 * @return {Object} accIds - Account ids with relevant mapping info
 */

// todo-ts: was request = ''
export default (data, state, request?: any) => {
  const { realToMasked } = state.accounts
  const accIds = []
  let customers = get(data, 'data.accounts')
  if (!customers) return { accountIds: [] }

  if (!(customers instanceof Array)) {
    customers = [customers]
  }

  customers.forEach((customer) => {
    let accounts = customer

    if (accounts === undefined) return

    if (!(accounts instanceof Array)) {
      accounts = [accounts]
    }
    if (request && request.id === 'requestPaymentsBulkMapping') {
      const item = Object.keys(state.user.bulkId.items)
      const mapped = includes(item, customer.partyId.value)
      if (mapped) {
        const id = realToMasked[customer.id.displayValue]
        accIds.push(id)
      }
    } else {
      const id = realToMasked[customer.id.displayValue]
      accIds.push(id)
    }
  })

  return {
    accountIds: accIds,
  }
}
