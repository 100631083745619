import { call, put } from 'redux-saga/effects'
import request from '../../utils/request'
import * as c from '../../constants'
import { listItemDelete } from '../../reducerCreators/listReducer/actions'

export default (listName, requestId, id, groupId) =>
  function* listItemDeleteSaga() {
    const nonce = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    yield call(request, {
      id: requestId,
      nonce,
      url: `/cz/v1/payments/payeeGroup/${groupId}/payees/external/${id}?locale=en`,
      payload: {
        stage: 1,
        id,
      },
    })
    yield put(listItemDelete(listName, id))
  }
