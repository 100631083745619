import * as React from 'react'

function SvgFlag(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 512 512'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M435.451 28.232a266.926 266.926 0 00-90.474-16.964A158.895 158.895 0 00269.205 28.6a162.542 162.542 0 01-76.337 15.353A446.263 446.263 0 0188.823 29.589V10.024a8.482 8.482 0 10-16.681 0v491.952a8.482 8.482 0 1016.68 0v-200.4a453.67 453.67 0 00104.046 13.798 178.997 178.997 0 0084.254-17.332 142.185 142.185 0 0167.855-15.352 252.31 252.31 0 0184.254 15.72 8.482 8.482 0 007.916-.849 8.482 8.482 0 003.676-7.011V36.092a8.256 8.256 0 00-5.372-7.86z'
        fill='#B9B6A8'
        fillRule='evenodd'
      />
    </svg>
  )
}

export default SvgFlag
