export interface Data1 {
  data: {
    name: string
    fcubsaccountAddressDTO: {
      city: string
      country: string
      addr1: string
      addr2: string
      addr3: string
      state: string
      postalCode: string | number
    }
  }
}
export default (data: Data1) => {
  const d = data.data

  const address = d.fcubsaccountAddressDTO

  return {
    city: address.city,
    country: address.country,
    addressLine1: address.addr1,
    addressLine2: address.addr2,
    addressLine3: address.addr3,
    region: address.state,
    postcode: address.postalCode,
    fullName: d.name,
  }
}
