import React from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { Link } from 'react-router'
import SingleField from '../Forms/SingleField'
import Button from '../Button'
import * as v from '../../utils/forms/validators'
import PaymentDateOptions from '../../containers/PaymentDateOptions'
import s from './styles.css'

type FormProps = {
  id: string
  handleSubmit: () => void
  submitting?: boolean
}

const Form = ({ id, handleSubmit, submitting }: InjectedFormProps<any> & FormProps) => (
  <form onSubmit={handleSubmit}>
    <fieldset className='narrow'>
      <Field
        name='amount'
        label='Amount in GBP'
        component={SingleField}
        type='text'
        placeholder='£'
      />

      <Field
        name='reference'
        label='Reference'
        component={SingleField}
        type='text'
        validate={[v.reference, v.maxLength35AN]}
      />
    </fieldset>
    <PaymentDateOptions
      formName='editRecurringPayment'
      showRecurringCheckbox={false}
      showWhenOptions={false}
    />
    <fieldset className='narrow'>
      <Button submit text='Continue & Review' loading={submitting} />
      <Link to='/payments/upcoming' className={s.cancel}>
        Cancel
      </Link>
    </fieldset>
  </form>
)

const ReduxForm = reduxForm<any, FormProps>({
  form: 'editRecurringPayment',
})(Form)

export default ReduxForm
