import * as c from '../constants'

export function flowSuccess(data?: any) {
  return {
    type: c.FLOW_SUCCESS,
    payload: data,
  }
}

export function flowReset() {
  return {
    type: c.FLOW_RESET,
  }
}

export function flowResetWithData(data) {
  return {
    type: c.FLOW_RESET_WITH_DATA,
    payload: data,
  }
}

export function flowUpdateData(data) {
  return {
    type: c.FLOW_UPDATE_DATA,
    payload: data,
  }
}

export function flowMergeData(data) {
  return {
    type: c.FLOW_MERGE_DATA,
    payload: data,
  }
}

export interface IFlowState {
  success: boolean
  data?: any
}

const initialState: IFlowState = {
  success: false,
  data: undefined,
}

export default function flowReducer(state = initialState, action): IFlowState {
  const p = action.payload
  switch (action.type) {
    case c.FLOW_SUCCESS:
      return {
        ...state,
        success: true,
        data: p || state.data,
      }
    case c.FLOW_UPDATE_DATA:
      return {
        ...state,
        data: p,
      }
    case c.FLOW_MERGE_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...p,
        },
      }
    case c.LOCATION_CHANGE:
    case c.FLOW_RESET:
      return {
        success: false,
        data: undefined,
      }
    case c.FLOW_RESET_WITH_DATA:
      return {
        ...state,
        success: false,
        data: p,
      }
    default:
      return state
  }
}
