/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import s from './styles.css'

type AccountTabsMobProps = {
  defaultTabState: string
  selectAll: () => void
  deselectAll: () => void
}

const AccountTabsMob = ({ defaultTabState, selectAll, deselectAll }: AccountTabsMobProps) => (
  <span className={s.tabMainDivMobile}>
    <div className={s.tabMainDiv}>
      <span className={s.psdTabHeaderSpan}>
        <div className={s.psdTabInfo}> Select the individual accounts to connect to below.</div>

        {defaultTabState === 'select' && (
          <div className={s.psdselectAll} onClick={selectAll}>
            Select All
          </div>
        )}
        {defaultTabState === 'deselect' && (
          <div className={s.psddeselectAll} onClick={deselectAll}>
            Deselect
          </div>
        )}
      </span>
      <div className={s.psdTab}>CURRENT ACCOUNT</div>
    </div>
  </span>
)

export default AccountTabsMob
