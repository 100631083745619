import { connect } from 'react-redux'
import AuthorisationsDeclineModal from '../../components/AuthorisationsDeclineModal'
import * as a from './actions'

const mapStateToProps = (state, ownProps) => ({
  isSubmitting: state.flow.data && state.flow.data.currentlyProcessing === ownProps.id,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDeclineSubmit: () => {
    dispatch(a.authorisationsProcessRequest(ownProps.id, 'decline'))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthorisationsDeclineModal)
