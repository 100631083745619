import * as c from '../../constants'

// eslint-disable-next-line import/prefer-default-export
export function activityLogTypeUpdate(codeType?: any, status?: any) {
  return {
    type: c.ACTIVITY_LOG_TYPE_UPDATE,
    payload: {
      codeType,
      status,
    },
  }
}
