import moment from 'moment'
import formatMoney from '../formatMoney'

/**
 * Tidies deeply nested and poorly named data from API into something
 * easier to deal with!
 * @param  {Object} data - raw data from API
 * @return {Object} data - tidied data
 */
export interface TidyAccountDetails1 {
  data: {
    demandDepositAccountDTO: {
      openingDate: string
      currencyCode: string
      accountNickname: string
      overDraftLimit: {
        sanctionedLimitAmount: { amount: string | number }
      }
      productDTO: {
        demandDepositProductFacilitiesDTO: {
          hasOverDraftFacility: boolean | string
        }
      }
      [key: string]: any
    }
  }
}
export default (data: TidyAccountDetails1) => {
  let account: any = {}
  if (data.data.demandDepositAccountDTO) {
    account = data.data.demandDepositAccountDTO
  }

  const curr = account.currencyCode
  const hasOverdraft = account.productDTO.demandDepositProductFacilitiesDTO.hasOverDraftFacility // TODO-API-QUERY
  // const hasOverdraft = true // TODO-API
  const hardStartDate = moment('2016-01-01', 'YYYY-MM-DD')
  let openingDate = moment(account.openingDate, 'YYYY-MM-DD')
  if (openingDate.isBefore(hardStartDate)) {
    openingDate = hardStartDate
  }

  return {
    overdraftLimit: hasOverdraft
      ? formatMoney(account.overDraftLimit.sanctionedLimitAmount.amount, curr)
      : undefined,
    openingDate: openingDate.format('DD/MM/YYYY'),
    // IBAN code will be added to the code
    iban: '',
    accountName: account.accountNickname,
  }
}
