import { connect } from 'react-redux'
import get from 'lodash/get'
import Item from '../../components/AToZ/Item'
import * as a from '../Payees/actions'

const mapStateToProps = (state, ownProps) => ({
  isActive: get(state, 'payees.filters.alphabet') === ownProps.charSet,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: (e) => {
    e.preventDefault()
    dispatch(a.payeesFilterAlphabet(ownProps.charSet))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Item)
