import moment from 'moment'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import PaymentDateOptions from '../../components/Forms/PaymentDateOptions'
import frequencyCodes from '../../utils/lookup/frequencyCodes'

const mapStateToProps = (state, ownProps) => {
  const formValues: any = getFormValues(ownProps.formName)(state)
  const whenVal = formValues && formValues.when
  const isRegularVal = formValues && formValues.isRegular
  const frequencyCode = formValues && formValues.repeatFreq && formValues.repeatFreq.value
  const repeatUntil = formValues && formValues.repeatUntil && formValues.repeatUntil.value
  const laterMinDate = moment().add(1, 'days')
  const laterMaxDate = moment().add(90, 'days')
  const showNextDayWarning = isRegularVal && whenVal === 'now'
  const freqOptions = Object.keys(frequencyCodes).map((key) => ({
    value: key,
    label: frequencyCodes[key],
  }))

  return {
    laterIsVisible: whenVal === 'later',
    regularIsVisible: isRegularVal,
    frequencyCode,
    repeatUntil,
    laterMinDate,
    laterMaxDate,
    showNextDayWarning,
    freqOptions,
  }
}

export default connect(mapStateToProps)(PaymentDateOptions)
