import React, { ReactNode } from 'react'
import Slidey from '../Slidey'
import Modal from '../../containers/Modal'

type SlideyorModalProps = {
  isOpen?: boolean
  children: ReactNode
  browser: any // todo - was object.
  onCloseClick: () => void
  id: string
}

const SlideyorModal = ({ isOpen, children, browser, id, onCloseClick }: SlideyorModalProps) => {
  const Container = browser.greaterThan.medium ? Slidey : Modal

  return (
    /* todo - both id and onClose is not used in Slidey/Modal */
    <Container isOpen={isOpen} id={id} onClose={onCloseClick}>
      {children}
    </Container>
  )
}

export default SlideyorModal
