import { call, put, takeLatest } from 'redux-saga/effects'
import * as c from '../../constants'
import request from '../../utils/request'
import { manageCredential } from './actions'
import { formError } from '../../sagas/formErrors/actions'
import { flowSuccess } from '../../store/flow'
import RSAPwdEncrypt from '../../utils/RSAPwdEncrypt/encrypt'
import goOut from '../../utils/goOut'

export function* handleSubmit(action) {
  try {
    const formData = action.payload
    const { type } = formData
    let url = 'v1/me/credentials'

    if (type === 'pin') {
      url = 'cz/v1/credentials/transactionPin'
    }

    let oldPassword = formData.oldPass
    let newPassword = formData.newPass

    if (type === 'password') {
      const nonce0 = yield call(request, {
        id: c.requestNonce,
        url: 'v1/session/nonce',
      })
      const { modulus, publicExponent, publicKey } = yield call(request, {
        id: c.requestPublicKey,
        url: 'v1/publicKey?locale=en',
        nonce: nonce0,
      })

      if (publicKey && modulus && publicExponent) {
        const nonce1 = yield call(request, {
          id: c.requestNonce,
          url: 'v1/session/nonce',
        })
        const { salt } = yield call(request, {
          id: c.requestSalt,
          url: 'v1/salt?locale=en',
          nonce: nonce1,
        })

        oldPassword = RSAPwdEncrypt(modulus, publicExponent, salt, oldPassword)
        newPassword = RSAPwdEncrypt(modulus, publicExponent, salt, newPassword)
      }
    }
    const nonce2 = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    yield call(request, {
      id: c.requestManageCredentials,
      nonce: nonce2,
      url,
      payload: {
        type,
        old: oldPassword,
        new: newPassword,
      },
    })
    yield put(flowSuccess())
    if (type === 'password') {
      setTimeout(() => {
        goOut('/digital')
      }, 500)
    }
  } catch (error) {
    yield put(formError(manageCredential.FAILURE, error))
  }
}

export function* watchManageCredentials() {
  yield takeLatest(manageCredential.REQUEST, handleSubmit)
}
