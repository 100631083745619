import * as c from '../constants'

// ------------------------------------
// Action creators
// ------------------------------------
export function userDetailsUpdate(details) {
  return {
    type: c.USER_DETAILS_UPDATE,
    payload: {
      details,
    },
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case c.USER_DETAILS_UPDATE:
      return {
        ...state,
        ...action.payload.details,
      }
    default:
      return state
  }
}
