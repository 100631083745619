export const getSelectedPayment = (state, type) =>
  state.paymentsRecurring[type].items[state.flow.data]

export const injectExtra = (state, obj) => {
  const newObj = {}

  Object.keys(obj).forEach((key) => {
    const item = obj[key]
    newObj[key] = {
      ...item,
      customerId: state.accounts.items[item.fromId]
        ? state.accounts.items[item.fromId].customerId
        : '',
    }
  })

  return newObj
}
