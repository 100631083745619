import { call, put, takeEvery } from 'redux-saga/effects'
import { SubmissionError } from 'redux-form'
import * as c from '../../constants'
import { decodeHTML } from '../../utils/decodeHTML'
import humanError from '../../utils/humanError'

/**
 * Checks error after form submission. If error is from API,
 * it passes it on. If error is some browser error that the user shouldn't see,
 * it uses a generic message instead
 */
export function* handleFormError(action) {
  const { error } = action.payload

  let message = yield call(humanError, error)
  if (error.response !== undefined && error.response.data !== undefined) {
    if (
      error.response.data.message !== undefined &&
      error.response.data.message.title !== undefined &&
      !error.response.data.message.validationError
    ) {
      message = decodeHTML(error.response.data.message.title)
    }
    message = yield call(humanError, error)
  }

  yield put({
    type: action.payload.formActionType,
    payload: new SubmissionError({ _error: message }),
  })
}

export function* watchFormErrors() {
  yield takeEvery(c.FORM_ERROR, handleFormError)
}
