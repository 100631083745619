import React from 'react'
import includes from 'lodash/includes'
import s from './styles.css'
import MenuList from '../MenuList'
import MegaMenuItem from '../../../containers/MegaMenuItem'

let racingAccount = false
let currentAccount = false

type MegaMenuProps = {
  items: any[]
  mappingList: any
  availableItems?: boolean
}

const MegaMenu = ({ items, mappingList, availableItems }: MegaMenuProps) => (
  <nav className={s.root}>
    {items.map((item) => {
      if (includes(item, 'racing')) {
        racingAccount = true
      }
      if (includes(item, 'current')) {
        currentAccount = true
      }
      return false
    })}
    <MegaMenuItem navId='accounts'>
      <div className={s.inner}>
        <div className={s.row}>
          {items.slice(0, 3).map((item) => (
            <div className={s.col} key={item.id}>
              <MenuList
                title={item.title}
                items={item.items}
                endLink={item.isTruncated ? `accounts/#${item.id}` : undefined}
              />
            </div>
          ))}
          <div className={s.col}>
            {items[3] && (
              <MenuList
                title={items[3].title}
                items={items[3].items}
                endLink={items[3].isTruncated ? `accounts/#${items[3].id}` : undefined}
              />
            )}
            <MenuList
              title='Services'
              items={[
                {
                  title: 'Statements',
                  to: '/accounts/statements',
                  excluded: !mappingList.statements,
                },
                {
                  title: 'Cheque services',
                  to: '/accounts/services',
                  excluded: !mappingList.accServ,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </MegaMenuItem>
    <MegaMenuItem navId='payments'>
      <div className={s.inner}>
        <div className={s.row}>
          <div className={s.col}>
            <MenuList
              items={[
                {
                  title: 'Make a Payment',
                  to: '/payments/make',
                  excluded: !mappingList.payment,
                },
                {
                  title: 'Internal Transfer',
                  to: '/payments/transfer-money',
                  excluded: !mappingList.transfer,
                },
                {
                  title: 'Bulk payments',
                  to: '/payments/bulk',
                  excluded: !mappingList.bulk && !availableItems,
                },
                {
                  title: 'Standing orders & direct debits',
                  to: '/payments/upcoming',
                },
                {
                  title: 'Request international payment',
                  to: '/payments/request-international',
                  excluded: !mappingList.reqIntPayment,
                },
                {
                  title: 'Manage my payees',
                  to: '/payments/payees',
                  excluded: !mappingList.payees,
                },
              ]}
            />
          </div>
          <div className={s.wideCol}>
            <h3>Need help?</h3>
            <p>
              Please contact Weatherbys on +44&nbsp;(0)1933&nbsp;543543
              <br /> or email us at <a href='mailto:bank@weatherbys.bank'>bank@weatherbys.bank</a>.
              We are happy to help.
            </p>
          </div>
        </div>
      </div>
    </MegaMenuItem>
    <MegaMenuItem navId='services' services>
      <div
        className={
          // eslint-disable-next-line no-nested-ternary
          racingAccount && currentAccount
            ? `${s.inner} ${s.services} ${s.extraWidth}`
            : racingAccount
            ? `${s.singleWidth} ${s.inner} ${s.services}`
            : `${s.inner} ${s.services}`
        }
      >
        <div className={s.row}>
          {currentAccount ? (
            <div className={s.col}>
              <MenuList
                title={racingAccount ? 'Private Account Services' : ''}
                services
                items={[
                  { title: 'Mortgages & Loans', to: '/services/mortgageloans' },
                  {
                    title: 'International Accounts',
                    to: '/services/international',
                  },
                  { title: 'Asset Finance', to: '/services/assetfinance' },
                  {
                    title: 'Investment & Wealth Advice',
                    to: '/services/investmentwealth',
                  },
                ]}
              />
            </div>
          ) : (
            ''
          )}
          {currentAccount ? (
            <div className={s.col}>
              <MenuList
                title={racingAccount ? '.' : ''}
                services
                secondColumn
                items={[
                  { title: 'Expatriate Services', to: '/services/expatriate' },
                  { title: 'Deposit Accounts', to: '/services/deposit' },
                  { title: 'Insurance', to: '/services/insurance' },
                ]}
              />
            </div>
          ) : (
            ''
          )}
          {racingAccount ? (
            <div className={s.col}>
              <MenuList
                title={currentAccount ? 'Racing Account Services' : ''}
                services
                moveLeft={!currentAccount}
                items={[
                  { title: 'Racing Plus account', to: '/services/racingplus' },
                  {
                    title: 'Invoice services',
                    to: '/services/invoiceservices',
                  },
                  { title: 'VAT services', to: '/services/vatservices' },
                ]}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </MegaMenuItem>
  </nav>
)

export default MegaMenu
