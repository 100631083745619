import { all, fork } from 'redux-saga/effects'
import { formActionSaga } from 'redux-form-saga'
import { watchTransactions } from '../containers/Transactions/sagas'
import { watchAccounts } from '../containers/Accounts/sagas'
import { watchAuthentication } from '../containers/LoginForm/sagas'
import { watchChangeCredentials } from '../containers/ChangeCredentials/sagas'
import { watchLogout } from '../containers/LogoutModal/sagas'
import { watchMultiLogout } from '../containers/LogoutMultiLogin/sagas'
import { watchFormErrors } from './formErrors'
import { watchStatements } from '../containers/Statements/sagas'
import { watchTransfer } from '../containers/TransferMoney/sagas'
import { watchMail } from '../containers/MailLayout/sagas'
import { watchEnquireMail } from '../containers/ServicesLayoutEnquire/sagas'
import { watchRequestInternationalPayment } from '../containers/RequestInternationalPayment/sagas'
import { watchManageCredentials } from '../containers/ManageCredentials/sagas'
import { watchManageAccounts } from '../containers/ManageAccounts/sagas'
import { watchPaymentMake } from '../containers/PaymentMake/sagas'
import { watchPaymentsFuture } from '../containers/PaymentsFuture/sagas'
import { watchTwoFactorAuth } from '../containers/TwoFactorAuth/sagas'
import { watchPayees } from '../containers/Payees/sagas'
import { watchPayeeNew } from '../containers/PayeeNew/sagas'
import { watchPaymentsRecurring } from '../containers/PaymentsRecurring/sagas'
import { watchPaymentsBulk } from '../containers/PaymentsBulk/sagas'
import { watchSortCodeValidate } from './sortCodeValidate'
import { watchEntities } from '../store/entities/sagas'
import { watchActivityLog } from '../containers/ActivityLog/sagas'
import { watchActivityLogType } from '../containers/ActivityLogType/sagas'
import { watchActivityLogSingle } from '../containers/ActivityLogSingle/sagas'
import { watchAccountServices } from '../containers/AccountServices/sagas'
import { watchAlerts } from '../containers/Alerts/sagas'
import { watchAuthorisations } from '../containers/Authorisations/sagas'
import { watchMarketingForm } from '../containers/MarketingPreferencesForm/sagas'
import { watchConfirmationOfPayee } from '../containers/ConfirmationOfPayee/sagas'

export default function* rootSaga() {
  yield all([
    fork(watchAuthentication),
    fork(watchLogout),
    fork(watchMultiLogout),
    fork(watchTransactions),
    fork(watchChangeCredentials),
    fork(watchAccounts),
    fork(watchFormErrors),
    fork(watchStatements),
    fork(formActionSaga),
    fork(watchTransfer),
    fork(watchMail),
    fork(watchEnquireMail),
    fork(watchRequestInternationalPayment),
    fork(watchManageCredentials),
    fork(watchManageAccounts),
    fork(watchPaymentMake),
    fork(watchPayees),
    fork(watchPayeeNew),
    fork(watchConfirmationOfPayee),
    fork(watchSortCodeValidate),
    fork(watchTwoFactorAuth),
    fork(watchPaymentsFuture),
    fork(watchPaymentsRecurring),
    fork(watchPaymentsBulk),
    fork(watchEntities),
    fork(watchActivityLog),
    fork(watchActivityLogType),
    fork(watchActivityLogSingle),
    fork(watchAccountServices),
    fork(watchAlerts),
    fork(watchAuthorisations),
    fork(watchMarketingForm),
  ])
}
