import React from 'react'
import { reduxForm, Field, InjectedFormProps } from 'redux-form'
import s from './styles.css'
import ReactSelect from '../Forms/ReactSelect'
import Select from '../Forms/Select'
import SingleField from '../Forms/SingleField'

type StatementsFiltersFormProps = {
  accountOpts: any[]
  typeOpts: any[]
  yearOpts: any[]
  account: string
  onAccountChange: () => void
}

const StatementsFiltersForm = ({
  accountOpts,
  typeOpts,
  yearOpts,
  account,
  onAccountChange,
}: InjectedFormProps<any> & StatementsFiltersFormProps) => (
  <form className={s.root}>
    <div className={s.accountSelectCol}>
      <label htmlFor='account'>Account</label>
      <ReactSelect
        onChange={onAccountChange}
        className={s.accountSelect}
        name='account'
        options={accountOpts}
        value={account}
      />
    </div>
    <div className={s.typeSelectCol}>
      <Field
        name='type'
        label='Type'
        className={s.typeSelect}
        component={SingleField}
        InputComponent={Select}
        inputComponentProps={{
          options: typeOpts,
        }}
      />
    </div>
    <div className={s.yearSelectCol}>
      <Field
        name='year'
        label='Year'
        className={s.yearSelect}
        component={SingleField}
        InputComponent={Select}
        inputComponentProps={{
          options: yearOpts,
        }}
      />
    </div>
  </form>
)

const StatementsFiltersReduxForm = reduxForm<any, StatementsFiltersFormProps>({
  form: 'statementsFilters',
  initialValues: {
    year: { value: false },
    type: { value: 'all' },
  },
})(StatementsFiltersForm)

export default StatementsFiltersReduxForm
