/**
 * Returns nice, responsive data for use in Table component
 * @param  {array} originalData
 * @param  {object} format - Bunch of options to describe how the data should return
 * @return {object} niceData
 * @return {object} niceData.body - For use in TableBody
 * @return {object} niceData.header - For use in TableHeader
 */

export default (
  originalData,
  format: {
    // TODO- not 100% sure about the format
    mapRowIdTo: string | number
    [key: string]: any
    cols: {
      header?: string
      mapTextTo?: string
      showFor?: string
      perc?: number
      mapExtraTo?: string
      wrap?: boolean
      headerHide?: boolean
      [key: string]: any
    }[]
  }
) => {
  const newData = {
    header: [],
    body: [],
  }

  // Build header
  format.cols.forEach((item) => {
    newData.header.push({
      text: item.header,
      perc: item.perc,
      showFor: item.showFor,
      hideFor: item.hideFor,
      headerHide: item.headerHide,
    })
  })

  // Build body
  if (originalData && originalData.length) {
    originalData.forEach((dataItem, idx) => {
      const cols = []

      let card

      if (format.card) {
        card = {
          id: format.incrementId
            ? `${dataItem[format.mapRowIdTo]}-${idx}`
            : dataItem[format.mapRowIdTo],
          title: dataItem[format.card.mapTitleTo],
          extra: dataItem[format.card.mapExtraTo],
          text: dataItem[format.card.mapTextTo],
          text2: dataItem[format.card.mapText2To],
          details: dataItem[format.card.mapDetailsTo],
        }
      }

      newData.body.push({
        ...dataItem,
        id: format.incrementId
          ? `${dataItem[format.mapRowIdTo]}-${idx}`
          : dataItem[format.mapRowIdTo],
        cols,
        hasDrawer: false,
        card,
      })

      format.cols.forEach((formatItem) => {
        const iconName = dataItem[formatItem.mapIconTo]
        const icon = iconName ? formatItem.iconMap[iconName] : undefined

        cols.push({
          text: dataItem[formatItem.mapTextTo],
          extra: dataItem[formatItem.mapExtraTo],
          drawer: dataItem[formatItem.mapDrawerTo],
          perc: formatItem.perc,
          showFor: formatItem.showFor,
          hideFor: formatItem.hideFor,
          wrap: formatItem.wrap,
          hasActions: formatItem.hasActions,
          icon,
        })
      })
    })
  }

  return newData
}
