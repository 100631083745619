// This converts the base64 to blob
// Someone smarter than me figured it out and I'm not sure how it works
// Stack thread: https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript

export default (base64: string) => {
  const b64: string = base64 && atob(base64)

  const byteNumbers: number[] = new Array(b64.length)
  for (let i = 0; i < b64.length; i += 1) {
    byteNumbers[i] = b64.charCodeAt(i)
  }

  const byteArray = new Uint8Array(byteNumbers)

  const blob = new Blob([byteArray])

  return blob
}
