import values from 'lodash/values'

// eslint-disable-next-line import/prefer-default-export
export const getMessageGroups = (state, folderName) => {
  const folder = state.mail[folderName]
  const items = values(folder.items)

  if (Object.keys(items).length === 0) return undefined

  if (folderName === 'outbox') {
    return [
      {
        title: 'Sent messages',
        messages: items.reverse(),
      },
    ]
  }
  const read = []
  const unread = []
  const lists = []

  items.forEach((item) => {
    if (item.isRead) {
      read.push(item)
    } else {
      unread.push(item)
    }
  })

  if (unread.length > 0) {
    lists.push({
      title: 'Unread',
      messages: unread.reverse(),
    })
  }

  if (read.length > 0) {
    lists.push({
      title: 'Read',
      messages: read.reverse(),
    })
  }

  return lists
}
