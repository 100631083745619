/* eslint-disable */
// @ts-nocheck
// todo - eslint and typescript
import { useRouterHistory } from 'react-router'
import createHashHistory from 'history/lib/createHashHistory'
import { syncHistoryWithStore } from 'react-router-redux'

let history = useRouterHistory(createHashHistory)({
  basename: process.env.__BASEURL__,
})

// todo any
export const injectHistoryWithStore = (store: any) => {
  history = syncHistoryWithStore(history, store)
}

export default history
