/* eslint-disable no-nested-ternary */
import React from 'react'
import { Link } from 'react-router'
import FlowSuccess from '../FlowSuccess'
import LoadingSpinner from '../LoadingSpinner'
import Status from '../Status'
import s from './styles.css'

type SuccessComponentProps = {
  success: boolean
  successMessage?: string
  isFetching: boolean
  hasErrors?: boolean
  fileRef?: string
}

const SuccessComponent = ({
  success,
  successMessage,
  isFetching,
  hasErrors,
  fileRef,
}: SuccessComponentProps) => (
  <div>
    {isFetching ? (
      <div className={s.loading}>
        <LoadingSpinner text='Checking file for errors' />
      </div>
    ) : hasErrors ? (
      <div>
        <Status text='Upload failed' type='fail' />
        <p className={s.message}>
          The bulk file has errors and could not be processed. Please see the&nbsp;
          <Link to={`/payments/bulk/file/${fileRef}`}>detailed report</Link>, fix the issues and
          re-upload the file.
        </p>
      </div>
    ) : (
      <FlowSuccess
        forceRows
        message={successMessage}
        secondaryMessage={
          <span>
            To track progress of your bulk upload and the individual payments included in this
            submission, please check the Recent uploads section of the{' '}
            <Link to='/payments/bulk'>Bulk Payments</Link> screen.
          </span>
        }
      />
    )}
  </div>
)

export default SuccessComponent
