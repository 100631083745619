import values from 'lodash/values'
import sortBy from 'lodash/sortBy'
import { getFormValues } from 'redux-form'
import getAccount from './getAccount'

export default (state, itemList, recurringType?: string) => {
  const items = values(itemList)
  const entityId = state.entities.activeEntityId
  const formValues: any = getFormValues('recurringPaymentsFilters')(state)
  const accountFilterId = formValues && formValues.account.value
  let filteredItems

  filteredItems = items.filter(
    (item) =>
      (!entityId || item.customerId === entityId) &&
      (!recurringType || !accountFilterId || item.fromId === accountFilterId) &&
      item.statusCode !== 'C'
  )

  filteredItems = filteredItems.map((item) => {
    const fromAccount = getAccount(state, item.fromId)

    let newItem = {
      ...item,
    }
    // todo-ts: refactored code
    if (recurringType === 'SI') {
      newItem = {
        ...newItem,
        amountAndFrequency: `${item.amount} ${item.frequency}`,
      }
    }

    return {
      fromAccountTitle: fromAccount.title,
      ...newItem,
    }
  })

  const sortedItems = sortBy(filteredItems, ['timeStamp'])

  if (recurringType === 'DD') {
    sortedItems.reverse()
  }

  // Move all items without a date to the bottom of the pile
  const getDate = (obj) => obj.date || obj.nextPaymentDate || obj.lastPaymentDate || obj.timeStamp
  const ordered = sortedItems.reduce(
    (acc, item) => {
      const date = getDate(item)
      if (date) {
        acc.top.push(item)
      } else {
        acc.bottom.push({
          ...item,
          amount: '—',
          lastPaymentDate: '—',
          nextPaymentDate: '—',
          date: '—',
        })
      }
      return acc
    },
    { top: [], bottom: [] }
  )

  return [...ordered.top, ...ordered.bottom]
}
