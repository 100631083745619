import * as c from '../../constants'

export interface IAvailableBalanceInfoModalState {
  showAvailableBalanceInfoModal: boolean
}

const initialState: IAvailableBalanceInfoModalState = {
  showAvailableBalanceInfoModal: false,
}

export default function availableBalanceInfoModalReducer(
  state = initialState,
  action
): IAvailableBalanceInfoModalState {
  switch (action.type) {
    case c.SHOW_AVAILABLE_BALANCE_INFO_MODAL: {
      return {
        ...state,
        showAvailableBalanceInfoModal: true,
      }
    }

    case c.HIDE_AVAILABLE_BALANCE_INFO_MODAL: {
      return {
        ...state,
        showAvailableBalanceInfoModal: false,
      }
    }
    default:
      return state
  }
}
