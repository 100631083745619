import moment from 'moment'
import { saveeReceiptPDF } from '../../utils/savePDF'
import { decodeHTML } from '../../utils/decodeHTML'

export const getPaymentMakeDetails = (state) => state

export const saveeReceipt = (d, state) => {
  const { repeatFreq, repeatsUntil } = state.flow.data
  let frequency: any = {} // todo-ts, was ''
  let until: any = {} // todo-ts, was ''
  const pdfConfig = {
    title: 'Transfer',
    rows: [
      { label: 'From account', value: decodeHTML(d[0].title) },
      { label: '', value: d[0].extras[0] },
      { label: '', value: '' },
      { label: 'Payee account', value: decodeHTML(d[1].title) },
      { label: '', value: d[1].extras[0] },
      { label: '', value: '' },
      { label: 'Amount', value: d[2].title },
      { label: `${d[5] ? 'Start date' : 'Date'}`, value: d[3].title },
      { label: 'Reference', value: d[4].title },
    ],
  }

  if (repeatFreq) {
    frequency = {
      label: 'Frequency',
      value: repeatFreq,
    }
    until = {
      label: 'Until',
      value: repeatsUntil,
    }
    if (repeatsUntil === undefined) {
      until = {
        label: 'Until',
        value: 'Until further notice',
      }
    }
    pdfConfig.rows.splice(8, 0, until)
    pdfConfig.rows.splice(8, 0, frequency)
  }

  saveeReceiptPDF(pdfConfig)
}

export const showRecurringWarning = (data) => {
  const { when, repeatFreq } = data
  const now = moment().format('DD/MM/YY')
  return repeatFreq && when === now
}
