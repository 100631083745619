/**
 * Takes grouped items and returns object to be used in megamenu
 * @param  {Array} groups - grouped data
 * @return {Array} menu array
 */
// eslint-disable-next-line import/prefer-default-export
export const getMenuItems = (groups) =>
  groups.map((group) => ({
    id: group.id,
    title: group.title,
    items: group.items.slice(0, 3).map((item) => ({
      to: `/accounts/transactions/${item.id}`,
      title: item.title,
    })),
    isTruncated: group.items.length > 3,
  }))
