import React, { Component } from 'react'
import s from './styles.css'

type ButtonProps = {
  onClickHandler: (v1: any, v2: string) => void
  item: {
    id: string
    text: string
  } // todofixed - was object.
  activeClass?: string
}

const Button = ({ onClickHandler, item, activeClass }: ButtonProps) => (
  <li className={s.item} key={item.id}>
    <a
      className={`${s.anchor} ${activeClass}`}
      href='#'
      role='button'
      onClick={(e) => onClickHandler(e, item.id)}
    >
      {item.text}
    </a>
  </li>
)

interface ShortcutsProps {
  items?: any[]
  onShortcutClick?: (v1: string) => void
}

class Shortcuts extends Component<ShortcutsProps> {
  constructor(props) {
    super(props)
    this.onClick = this.onClick.bind(this)
  }

  onClick(e, id) {
    e.preventDefault()
    this.props.onShortcutClick(id)
  }

  render() {
    const { items } = this.props

    return (
      <div>
        {items && items.length > 0 && (
          <div>
            <ul className={s.list}>
              {items.slice(0, 2).map((item) => (
                <Button
                  item={item}
                  onClickHandler={this.onClick}
                  activeClass={item.active ? s.active : undefined}
                  key={item.id}
                />
              ))}
            </ul>
            <ul className={s.list}>
              {items.slice(2).map((item) => (
                <Button
                  item={item}
                  onClickHandler={this.onClick}
                  activeClass={s.backForward}
                  key={item.id}
                />
              ))}
            </ul>
          </div>
        )}
      </div>
    )
  }
}

export default Shortcuts
