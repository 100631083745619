import { combineReducers } from 'redux'
import createListReducer, { ListState } from '../../reducerCreators/listReducer'

export interface IChequeStatusState {
  list: ListState
}

export default combineReducers<IChequeStatusState, any>({
  list: createListReducer('cheque-status-list'),
})
