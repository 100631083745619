import { createFormAction } from 'redux-form-saga'
import * as c from '../../constants'
import { ItwoFactorAuthSetRef } from './interface'

export const OTPSubmit = createFormAction('TWO_FACTOR_AUTH_OTP')
export const PINSubmit = createFormAction('TWO_FACTOR_AUTH_PIN')

export function twoFactorAuthSetInitialRequest(requestName: string) {
  return {
    type: c.TWO_FACTOR_AUTH_SET_INITIAL_REQUEST,
    payload: {
      requestName,
    },
  }
}

export function twoFactorAuthNextStep() {
  return {
    type: c.TWO_FACTOR_AUTH_NEXT_STEP,
  }
}

export function twoFactorAuthSetRef(ref: ItwoFactorAuthSetRef) {
  return {
    type: c.TWO_FACTOR_AUTH_SET_REF,
    payload: {
      ref,
    },
  }
}

export function twoFactorAuthSetSuccessAction(action: { type: string }) {
  return {
    type: c.TWO_FACTOR_AUTH_SET_SUCCESS_ACTION,
    payload: {
      action,
    },
  }
}

export function twoFactorAuthReset() {
  return {
    type: c.TWO_FACTOR_AUTH_RESET,
  }
}

export function twoFactorAuthComplete(initialRequest: any, response: any) {
  return {
    type: c.TWO_FACTOR_AUTH_COMPLETE,
    payload: {
      initialRequest,
      response,
    },
  }
}

export function twoFactorAuthGoBack() {
  return {
    type: c.TWO_FACTOR_AUTH_GO_BACK,
  }
}
