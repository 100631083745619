/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactNode } from 'react'
import ReactModal from 'react-modal'
import s from './styles.css'
import Button from '../Button'

type ModalProps = {
  onClose: () => void
  isOpen?: boolean
  children?: ReactNode
  contentLabel?: string
  size?: 'small' | 'medium' | 'large'
  className?: string
  title?: string
  onActionClick?: () => void
  onAction2Click?: () => void
  closeOnActionClick?: boolean
  actionText?: string
  action2Text?: string
  actionStyle?: string
  action2Style?: string
  cancelText?: string
  footerText?: string
  noScroll?: boolean
  isNonClickable?: boolean
}

const Modal = ({
  className,
  isOpen,
  children,
  contentLabel = 'Dialog Box',
  noScroll,
  onClose,
  size,
  title,
  onActionClick,
  closeOnActionClick = false,
  actionText,
  actionStyle,
  cancelText = 'Cancel',
  action2Text,
  onAction2Click,
  action2Style,
  footerText,
  isNonClickable,
}: ModalProps) => {
  const noScrollClass = noScroll ? s.noScroll : undefined

  return (
    <ReactModal
      className={`${s.modal} ${s[size]} ${className} ${noScrollClass} ${
        isNonClickable ? s.nonClickable : ''
      }`}
      overlayClassName={s.root}
      isOpen={isOpen}
      contentLabel={contentLabel}
      onRequestClose={onClose}
      appElement={document.getElementById('root') as HTMLElement}
    >
      <a href='#' className={s.close} onClick={onClose} aria-label='Close'>
        &times;
      </a>
      {title && <h1 className={s.title}>{title}</h1>}
      {children}
      {(onActionClick || closeOnActionClick) && (
        <div className={s.actions}>
          <Button
            style={actionStyle || 'secondary'}
            text={actionText}
            onClick={closeOnActionClick ? onClose : onActionClick}
          />
          {action2Text && (
            <Button
              style={action2Style || 'secondary'}
              text={action2Text}
              onClick={onAction2Click}
            />
          )}
          <a href='#' onClick={onClose}>
            {cancelText}
          </a>
        </div>
      )}
      {footerText && <p className={s.footer}>{footerText}</p>}
    </ReactModal>
  )
}

export default Modal
