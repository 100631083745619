import * as React from 'react'

function SvgCalendar(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 512 512'
      {...props}
    >
      <path d='M0 480c0 17.674 14.327 32 32 32h448c17.674 0 32-14.326 32-32V192H0v288zm352-256h128v64H352v-64zm0 96h128v64H352v-64zm0 96h128v64H352v-64zM192 224h128v64H192v-64zm0 96h128v64H192v-64zm0 96h128v64H192v-64zM32 224h128v64H32v-64zm0 96h128v64H32v-64zm0 96h128v64H32v-64zM480 64h-64V18.286C416 8.187 408.837 0 400 0s-16 8.187-16 18.286V64H128V18.286C128 8.187 120.837 0 112 0S96 8.187 96 18.286V64H32C14.327 64 0 78.327 0 96v64h512V96c0-17.673-14.327-32-32-32zm-368 80c-17.673 0-32-14.327-32-32 0-11.842 6.438-22.174 16-27.708V112c0 8.836 7.164 16 16 16s16-7.164 16-16V84.292c9.562 5.534 16 15.866 16 27.708 0 17.673-14.327 32-32 32zm288 0c-17.672 0-32-14.327-32-32 0-11.842 6.438-22.174 16-27.708V112c0 8.836 7.164 16 16 16s16-7.164 16-16V84.292c9.563 5.534 16 15.866 16 27.708 0 17.673-14.326 32-32 32z' />
    </svg>
  )
}

export default SvgCalendar
