import React from 'react'
import s from './styles.css'
import COPTickIcon from '../../assets/icons/COPTickIcon'
import COPWarningIcon from '../../assets/icons/COPWarningIcon'
import { ConfirmationOfPayeeStatus } from '../../containers/ConfirmationOfPayee/reducer'

type DetailsBlockProps = {
  descriptor?: string
  title?: string
  extras?: string[]
  actionText?: string
  onActionClick?: () => void
  respondMode?: string
  copStatus?: ConfirmationOfPayeeStatus
  verificationReport?: any
}

const DetailsBlock = ({
  descriptor,
  title,
  extras,
  actionText,
  onActionClick,
  respondMode,
  copStatus,
  verificationReport,
}: DetailsBlockProps) => {
  let respond: string
  if (respondMode === 'forceRows') {
    respond = s.forceRows
  } else if (respondMode !== 'forceCols') {
    respond = s.respond
  }
  if (descriptor && title) {
    return (
      <div className={`${s.root} ${respond}`}>
        {descriptor && title && <span className={`${s.descriptor} ${respond}`}>{descriptor}</span>}
        {/* eslint-disable-next-line react/no-danger */}
        {title && <h3 className={s.title} dangerouslySetInnerHTML={{ __html: title }} />}
        {extras && (
          <ul className={s.extras}>
            {extras.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        )}
        <>
          {descriptor.includes('Payee') && copStatus === ConfirmationOfPayeeStatus.NOT_STARTED ? (
            <>
              {verificationReport && verificationReport.enabled && (
                <>
                  {verificationReport.matched === 'Y' ? (
                    <div className={`${s.successMessage} `}>
                      <COPTickIcon className={s.miniIcons} />
                      All details matched
                    </div>
                  ) : (
                    <>
                      {verificationReport.matched === 'N' ? (
                        <div className={`${s.warningMessage}`}>
                          <COPWarningIcon className={s.miniIcons} />
                          Check complete. Continue at risk.
                        </div>
                      ) : (
                        <>
                          {verificationReport.selectionStatus ? (
                            <div className={`${s.successMessage} `}>
                              <COPTickIcon className={s.miniIcons} />
                              All details matched
                            </div>
                          ) : (
                            <div className={`${s.warningMessage} `}>
                              <COPWarningIcon className={s.miniIcons} />
                              Check complete. Continue at risk.
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {descriptor.includes('Payee') && copStatus === ConfirmationOfPayeeStatus.SUCCESS && (
                <div className={s.successMessage}>
                  <COPTickIcon className={s.miniIcons} />
                  All details matched
                </div>
              )}
              {descriptor.includes('Payee') &&
                copStatus === ConfirmationOfPayeeStatus.SUCCESS_BYPASS && (
                  <div className={s.warningMessage}>
                    <COPWarningIcon className={s.miniIcons} />
                    Check complete. Continue at risk.
                  </div>
                )}
            </>
          )}
          {actionText && (
            <a href='#' onClick={onActionClick} className={s.link}>
              {actionText}
            </a>
          )}
        </>
      </div>
    )
  }
  return null
}

export default DetailsBlock
