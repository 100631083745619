import { connect } from 'react-redux'
import PaymentsUpcomingClosed from '../../components/PaymentsUpcomingClosed'
import niceTableData from '../../utils/niceTableData'
import { panelShow } from '../../store/ui'
import { getPaymentsUpcomingClosed, getIsAllClosedPaymentsShowing } from './selectors'

const tableFormat = {
  mapRowIdTo: 'id',
  cols: [
    {
      header: 'From',
      mapTextTo: 'fromAccountTitle',
      wrap: true,
      perc: 30,
    },
    {
      header: 'Payee',
      mapTextTo: 'recipientName',
      perc: 30,
      wrap: true,
      mapExtraTo: 'reference',
    },
    {
      header: 'Type',
      mapTextTo: 'type',
      wrap: true,
    },
  ],
  card: {
    mapTitleTo: 'fromAccountTitle',
    mapTextTo: 'recipientName',
    mapDetailsTo: 'type',
    mapExtraTo: 'reference',
  },
}

const mapStateToProps = (state, ownProps) => {
  const limit = 10
  const items = getPaymentsUpcomingClosed(state, limit)
  const showLoadMore = !getIsAllClosedPaymentsShowing(state, limit)
  const niceData = niceTableData(items, tableFormat)

  return {
    cardForSmall: true,
    rows: niceData.body,
    headerCols: niceData.header,
    showLoadMore,
    virtualized: true,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onShowAllClick: (e) => {
    e.preventDefault()
    dispatch(panelShow('showAllClosedPayments'))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsUpcomingClosed)
