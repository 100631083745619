import React from 'react'
import s from './styles.css'

type UserStatusProps = {
  lastLogin?: string
  displayName?: string
}

const UserStatus = ({ lastLogin, displayName }: UserStatusProps) => (
  <div className={s.root}>
    <span>
      You are logged in as <strong>{displayName}</strong>
    </span>
    {lastLogin && <time className={s.light}>Last Login: {lastLogin}</time>}
  </div>
)

export default UserStatus
