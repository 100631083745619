/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'

type ModalLinkProps = {
  component?: any
}

const ModalLink = (props: ModalLinkProps) => {
  if (props.component) {
    const Component = props.component
    return <Component {...props} />
  }
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a {...props} href='#' />
  )
}

export default ModalLink
