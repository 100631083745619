import React from 'react'
import s from './styles.css'
import HeadingWithDetails from '../HeadingWithDetails'
import DetailList from '../DetailList'
import Strip from '../Strip'

type TermDepositProps = {
  title: string
  typeTitle: string
  balance: string
  accountNumber: string
  details?: {
    productName: string
    startDate: string
    maturityDate: string
    payoutInstructions: string
    originalAmount: string | number
    interestRate: string | number
  }
}

const TermDeposit = ({ title, balance, typeTitle, accountNumber, details }: TermDepositProps) => (
  <div className={s.root}>
    <div className={`${s.inner} ${s.innerLoanDeposit}`}>
      <div className={s.row}>
        <div className={s.col}>
          <HeadingWithDetails title={title} details={[details.productName, accountNumber]} />
        </div>
        <div className={s.col}>
          <DetailList
            items={[
              {
                title: 'Current balance',
                value: balance,
                type: 'title',
              },
            ]}
          />
        </div>
        <div className={s.col}>
          <DetailList
            items={[
              {
                title: 'Start date',
                value: details.startDate,
              },
              {
                title: 'Maturity date',
                value: details.maturityDate,
              },
            ]}
          />
        </div>
      </div>
    </div>
    <Strip accType='True'>
      <div className={s.row}>
        <div className={s.col}>
          <DetailList
            items={[
              {
                title: 'Rollover instructions',
                value: details.payoutInstructions,
                type: 'long',
              },
            ]}
          />
        </div>
        <div className={s.col}>
          <DetailList
            items={[
              {
                title: 'Original deposit',
                value: details.originalAmount,
              },
            ]}
          />
        </div>
        <div className={s.col}>
          <DetailList
            items={[
              {
                title: 'Interest rate',
                value: details.interestRate,
              },
            ]}
          />
        </div>
      </div>
    </Strip>
  </div>
)

export default TermDeposit
