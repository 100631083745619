import { takeLatest, select, call, put } from 'redux-saga/effects'
import toArray from 'lodash/toArray'
import includes from 'lodash/includes'
import moment from 'moment'
import request from '../../utils/request'
import { getPicker } from '../../containers/SuperDatePicker/selectors'
import { getParams, getLogTypeItems } from './selectors'
import {
  listUpdateAll,
  listFetchStop,
  listFetchStart,
} from '../../reducerCreators/listReducer/actions'
import * as c from '../../constants'

let baseActivityURL = '/cz/v1/transactions?discriminator=PAYMENTS&'

export function* handleActivityLogUpdate() {
  try {
    const picker = yield select(getPicker, 'activityLogType')
    const { statusCode, typeCode } = yield select(getParams)
    const fromDate = moment(picker.startDate, 'X').format('YYYY-MM-DD')
    const toDate = moment(picker.endDate, 'X').format('YYYY-MM-DD')
    const dateRange = `fromDate=${fromDate}&toDate=${toDate}`
    yield put(listFetchStart('activity-log-type'))
    const activityLogTypes = toArray(yield select(getLogTypeItems))
    const activityLogType = activityLogTypes.filter((logType) => includes(logType, typeCode))[0]
    baseActivityURL = `/cz/v1/transactions?discriminator=${activityLogType.discriminator}&`
    const typeAndStatus = `status=${statusCode}&view=all&transactionName=${typeCode}&locale=en`
    const nonce = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    const res = yield call(request, {
      id: c.requestActivityLogType,
      url: `${baseActivityURL}${dateRange}&${typeAndStatus}`,
      nonce,
    })
    yield put(listUpdateAll('activity-log-type', res.items))
  } catch (err) {
    console.error(err)
  }
}

export function* handleActivityLogInit(
  action,
  start = moment().startOf('day').subtract(1, 'month').format('YYYY-MM-DD'),
  end = moment().startOf('day').format('YYYY-MM-DD')
) {
  try {
    yield put(listFetchStart('activity-log-type'))
    const { statusCode } = action.payload
    const { typeCode } = action.payload
    const activityLogTypes = toArray(yield select(getLogTypeItems))
    const activityLogType = activityLogTypes.filter((logType) => includes(logType, typeCode))[0]
    const dateRange = `fromDate=${start}&toDate=${end}`
    baseActivityURL = `/cz/v1/transactions?discriminator=${activityLogType.discriminator}&`
    const nonce = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    const res = yield call(request, {
      id: c.requestActivityLogType,
      url: `${baseActivityURL}${dateRange}&status=${statusCode}&view=all&transactionName=${typeCode}&locale=en`,
      nonce,
    })
    const { items } = res
    yield put(listUpdateAll('activity-log-type', items))
    yield put(listFetchStop('activity-log-type'))
  } catch (err) {
    console.error(err)
  }
}

export function* watchActivityLogType() {
  yield takeLatest(c.ACTIVITY_LOG_TYPE_INIT, handleActivityLogInit)
  yield takeLatest(c.ACTIVITY_LOG_TYPE_UPDATE, handleActivityLogUpdate)
}
