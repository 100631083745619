import * as c from '../../constants'

// eslint-disable-next-line import/prefer-default-export
export function formError(formActionType, error) {
  return {
    type: c.FORM_ERROR,
    payload: {
      formActionType,
      error,
    },
  }
}
