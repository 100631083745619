import formatMoney from '../formatMoney'

export interface TidyLoanDetailsPrincipalbalanceD {
  data: {
    outStandingLoanDetailsDTO: {
      installmentArrear: {
        amount: number | string
        currency: string
      }
      principalBalance: {
        amount: number | string
        currency: string
      }
    }
  }
}
export default (inputData: TidyLoanDetailsPrincipalbalanceD) => {
  const data = inputData.data.outStandingLoanDetailsDTO
  const showPen = data.installmentArrear.amount > 0

  return {
    principalBalance: formatMoney(data.principalBalance.amount, data.principalBalance.currency),
    instalmentArrears: showPen
      ? formatMoney(data.installmentArrear.amount, data.installmentArrear.currency)
      : undefined,
  }
}
