import APIError from '../APIError'
import truncateErrors from '../truncateErrors'
import { sortCode } from '../forms/validators'

const expectedColNum = 6

export default (
  jsonObj: {
    accountNumber?: string | number
    amount?: any
    name?: string
    reference?: string
    sortCode?: string
    type?: string
  }[]
) => {
  let errorMessage = ''
  let totalAmount: number

  if (errorMessage === '') {
    totalAmount = jsonObj.reduce((total, item, index) => {
      const humanIndex = index + 1
      const amount = parseFloat(item.amount)
      const colNum = Object.keys(item).length
      if (colNum !== expectedColNum) {
        errorMessage += `Wrong number of columns on row ${humanIndex}. `
      } else {
        if (Number.isNaN(amount)) errorMessage += `Bad transaction amount on row ${humanIndex}. `
        if (String(item.sortCode).length !== 6)
          errorMessage += `Bad sort code on row ${humanIndex}. `
        if (String(item.accountNumber).length !== 8)
          errorMessage += `Bad account number on row ${humanIndex}. `
        if (!item.name || item.name === '') errorMessage += `Bad payee name on row ${humanIndex}. `
        if (item.type !== 'FP' && item.type !== 'BACS')
          errorMessage += `Bad payment type on row ${humanIndex}. `
      }

      const newTotal = total + amount
      return newTotal
    }, 0)
  }

  if (errorMessage !== '') {
    throw new APIError(truncateErrors(errorMessage, 5), '')
  }

  return {
    numRows: jsonObj.length,
    totalAmount,
  }
}
