/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import s from './styles.css'

interface SelectEntitiesProps {
  onEntityClick: (v: string) => void
  allEntities: any // todo - was object
}

class SelectEntities extends React.Component<SelectEntitiesProps> {
  constructor(props: SelectEntitiesProps | Readonly<SelectEntitiesProps>) {
    super(props)
    this.state = {
      // entityData: [],
    }
  }

  render() {
    const { onEntityClick, allEntities } = this.props
    if (allEntities.length === 0) {
      return null
    }
    return (
      <div className={s.psdImageDiv}>
        {allEntities.map((item, index) => {
          const partyName = item.dictionaryArray[0].nameValuePairDTOArray[1].value || null
          const approvalType =
            item.dictionaryArray[0].nameValuePairDTOArray[2] &&
            item.dictionaryArray[0].nameValuePairDTOArray[2].value
              ? item.dictionaryArray[0].nameValuePairDTOArray[2].value
              : null
          if (approvalType && approvalType.toLowerCase() === 'ze') {
            return (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              <span
                className={s.psdImgSpan}
                id={item.partyId.value}
                onClick={() => {
                  onEntityClick(item.partyId.value)
                }}
              >
                <span className={s.psdPartyId}>
                  {item.partyId.displayValue} - {partyName}
                </span>
                <i className={`${s.psdiconClass} ${s.psdright}`} />
              </span>
            )
          }
          return <span />
        })}
      </div>
    )
  }
}

export default SelectEntities
