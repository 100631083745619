import React from 'react'
import Large from './Large'
import Medium from './Medium'
import s from './styles.css'

type SuperDatePickerProps = {
  browser: {
    greaterThan: {
      medium: string | boolean
    }
  } // todofixed - was object.
  layout?: 'Large' | 'Medium'
}

const SuperDatePicker = ({ layout, ...rest }: SuperDatePickerProps) => {
  const { browser } = rest

  let Layout

  if (layout) {
    Layout = layout === 'Large' ? Large : Medium
  } else if (browser.greaterThan.medium) {
    Layout = Large
  } else {
    Layout = Medium
  }

  return (
    <div className={s.root}>
      <Layout {...rest} />
    </div>
  )
}

export default SuperDatePicker
