import get from 'lodash/get'

export interface TidyalertsI {
  data: {
    alertResponseDTO: {
      moduleType: string
      alertDTO: {
        alertName: string
        alertType: string
        alertKeyDTO: {
          eventId: string
          activityId: string
          actionId: string | number
        }
        alertTemplate: {
          urgency: string | number
          keyDTO: any[]
        }
        inputparamdata: {
          inputparamdto: {
            paramdata: {
              datadto: {
                code: string
              }
            }
          }
        }
      }
    }[]
  }
}
interface TidyAlertsS {
  user: {
    email: string
    phoneNo: {
      sms: string | number
    }
  }
}
export default (data: TidyalertsI, state: TidyAlertsS) => {
  const alerts = data.data.alertResponseDTO
  const tidy: any = {}
  tidy.fields = alerts.reduce((acc, alert) => {
    const { alertName: alertDesc, alertType: paramType } = alert.alertDTO
    const { eventId: alertId, activityId, actionId } = alert.alertDTO.alertKeyDTO
    const { urgency, keyDTO } = alert.alertDTO.alertTemplate
    const module = alert.moduleType
    const input = get(alert, 'inputparamdata.inputparamdto.paramdata.datadto.code', '')
    acc[alertDesc] = {
      sms: false,
      email: false,
      input,
      alertDesc,
      activityId,
      actionId,
      alertId,
      paramType,
      urgency,
      module,
      keyDTO,
    }
    return acc
  }, {})

  const { email, phoneNo: sms = '447724677048' } = state.user
  tidy.contacts = {
    email,
    sms,
  }
  return tidy
}
