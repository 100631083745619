import React from 'react'
import { reduxForm, InjectedFormProps } from 'redux-form'
import s from './styles.css'
import Checkbox from '../Forms/Checkbox'

type AccountPermissionsEditProps = {
  onCheckboxClick: (name: string, v2: string, v3: string) => void
  name: string
  accountName: string
  accountNumber: string
  paymentTransfers: boolean
  accountEnquiries: boolean
  showPayments: boolean
  showAccountEnquiries: boolean
}

const AccountPermissionsEdit = ({
  onCheckboxClick,
  accountEnquiries,
  paymentTransfers,
  name,
  accountName,
  accountNumber,
  showPayments,
  showAccountEnquiries,
}: InjectedFormProps<any> & AccountPermissionsEditProps) => (
  <div className={s.accountCardBox}>
    <div className={s.psdAccountName}>{accountName}</div>
    <div className={s.psdAccountNumber}>{accountNumber} 60-93-03</div>
    <div className={s.psdCheckboxDiv}>
      {showAccountEnquiries && (
        <span className={s.psdCheckboxSpan}>
          <Checkbox
            label='Account enquiries'
            onChange={() => {
              onCheckboxClick(name, 'accountEnquiries', accountNumber)
            }}
            checked={accountEnquiries}
            name={`${name}AE`}
          />
        </span>
      )}
      {showPayments && (
        <span className={s.psdCheckboxSpan}>
          <Checkbox
            label='Payments and transfers'
            checked={paymentTransfers}
            onChange={() => {
              onCheckboxClick(name, 'paymentTransfers', accountNumber)
            }}
            name={`${name}PT`}
          />
        </span>
      )}
    </div>
  </div>
)

const AccountPermissionsEditReduxForm = reduxForm<any, AccountPermissionsEditProps>({
  form: 'psdaccountpermissions',
  enableReinitialize: true,
})(AccountPermissionsEdit)

export default AccountPermissionsEditReduxForm
