import { purgeStoredState } from 'redux-persist'
import { asyncSessionStorage } from 'redux-persist/storages'
import { clearSessionStorage } from '../sessionStorage'
import { SESSION_EVENT_TIMESTAMP } from '../../constants'

// Purges local storage and sends user to external URL
export default (url) => {
  // White overlay to prevent flashes of modals etc on log out
  document.body.className = 'hideAll'
  clearSessionStorage(SESSION_EVENT_TIMESTAMP)
  purgeStoredState({ storage: asyncSessionStorage }).then(() => {
    window.location = url
  })
}
