import { takeLatest, put, call, select } from 'redux-saga/effects'
import request from '../../utils/request'
import { listUpdateAll, listFetchFail } from '../../reducerCreators/listReducer/actions'
import * as c from '../../constants'
import humanError from '../../utils/humanError'
import formatSortCode from '../../utils/formatSortCode'
import { getActivity } from './selectors'

// todo-ts: removed 2nd param state
export function* handleActivityLogSingle(action: any) {
  try {
    const aTSingle = action.payload
    const activity = yield select(getActivity, aTSingle)
    let details: any = {}
    yield put(listUpdateAll('activity-log-single', false))
    const nonce = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    const response = yield call(request, {
      id: c.requestActivityLogSingle,
      url: `/v1/transactions/${aTSingle.id}`,
      nonce,
      partyId: activity.partyId,
    })
    if (response.items.activityType === 'payee') {
      // eslint-disable-next-line no-shadow
      const nonce = yield call(request, {
        id: c.requestNonce,
        url: 'v1/session/nonce',
      })
      details = yield call(request, {
        id: c.requestPayeeDetails,
        nonce,
        partyId: response.items.partyId.value,
        url: `/cz/v1/payments/payeeGroup/${response.items.groupId}/payees/external/${response.items.payeeId}?locale=en`,
      })
      response.items.newBeneDetails = {
        title: details.title,
        accountNumber: details.accountNumber,
        sortCode: formatSortCode(details.sortCode),
      }
    }
    yield put(listUpdateAll('activity-log-single', response.items))
  } catch (error) {
    const message = yield call(humanError, error)
    yield put(listFetchFail('activity-log', message))
  }
}

export function* watchActivityLogSingle() {
  yield takeLatest(c.ACTIVITY_LOG_SINGLE_INIT, handleActivityLogSingle)
}
