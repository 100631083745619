import frequencyCodes from '../../utils/lookup/frequencyCodes'

export function getPaymentDetails(state, id) {
  return state.paymentsRecurring.SI.items[id]
}

export function getFromAndToDetails(state, id) {
  const { fromId, recipientName, toAccountNumber } = getPaymentDetails(state, id)
  const from = state.accounts.items[fromId]

  const detailBlockFormatted = {
    from: {
      descriptor: 'From Account',
      title: from.title,
      extras: [
        `${from.sortCode} ${from.accountNumber}`,
        `Current balance: ${from.balance}`,
        `Available balance: ${from.availableBalance}`,
      ],
    },
    to: {
      descriptor: 'To Account',
      title: recipientName,
      extras: [toAccountNumber],
    },
  }

  return detailBlockFormatted
}

export function getFrequencyCode(label) {
  const codes = Object.values(frequencyCodes)
  const index = codes.findIndex((code) => code.toLowerCase() === label.toLowerCase())
  return String(index + 1)
}
