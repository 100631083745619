export default (hasItems, isFetching, hasFilters, error, errCode?: string, gotAll?: boolean) => {
  let state
  let message
  let errorCode

  if (error) {
    state = 'error'
    message = error
    errorCode = errCode || ''
  } else if (hasItems && isFetching) {
    state = 'loading'
  } else if (!hasItems && isFetching) {
    state = 'loadingEmpty'
  } else if (!hasItems && !isFetching && !hasFilters) {
    state = 'completelyEmpty'
  } else if (!hasItems && !isFetching && hasFilters) {
    state = 'noResults'
  } else if (hasItems && !isFetching && hasFilters) {
    state = 'endOfResults'
  } else if (hasItems && !isFetching && !hasFilters && gotAll) {
    state = 'endOfEverything'
  } else if (hasItems && !isFetching && !hasFilters) {
    state = 'endOfExisting'
  }

  return { state, message, errorCode }
}
