/* eslint-disable */
// @ts-nocheck
// todo - eslint and typescript
import React from 'react'
import s from './styles.css'
import { MakeRequest } from './MakeRequest'
import goOut from '../../utils/goOut'

const Request = new MakeRequest()

interface TppDetailsProps {
  accessPointId: string
  onCompanyClick: () => void
}

interface TppDetailsState {
  // imageUrl: string,
  loading: boolean
  // description: string,
  accessPointDetails: any
  imageRef: any
}

class TppDetails extends React.Component<TppDetailsProps, TppDetailsState> {
  constructor(props) {
    super(props)
    this.state = {
      // imageUrl: '',
      loading: true,
      // description: '',
      accessPointDetails: {},
      imageRef: {},
    }
  }

  componentDidMount() {
    const { accessPointId } = this.props
    // todo - added more params for nounce and selectedEntity
    Request.post('/digx/v1/session/nonce', {}, null, null).then((res) => {
      let nonce = null
      let nonceAvailable = false
      // eslint-disable-next-line no-restricted-syntax
      for (const pair of res.headers.entries() as any) {
        // todo - any
        if (pair[0] === 'x-nonce') {
          nonce = JSON.parse(pair[1]).nonce[0]
          nonceAvailable = true
          Request.get(`/digx/v1/accessPoints/${accessPointId}`, nonce)
            .then((response) => response.json())
            .then((res) => {
              this.setState({ accessPointDetails: res }, () => {
                this.setState({ loading: false })

                const imageReFVal =
                  (res.accessPointDTO &&
                    res.accessPointDTO.imgRefno &&
                    res.accessPointDTO.imgRefno.value) ||
                  ''

                // ajax call to fetch image
                if (res.accessPointDTO.type === 'EXT') {
                  Request.post('/digx/v1/session/nonce', {}).then((res) => {
                    let nonce = null
                    let nonceAvailable = false
                    // eslint-disable-next-line no-restricted-syntax
                    for (const pair of res.headers.entries()) {
                      if (pair[0] === 'x-nonce') {
                        nonce = JSON.parse(pair[1]).nonce[0]
                        nonceAvailable = true
                        Request.get(`/digx/v1/contents/${imageReFVal}`, nonce)
                          .then((response) => response.json())
                          .then((res) => {
                            const base64URL =
                              (res.contentDTOList &&
                                res.contentDTOList.length > 0 &&
                                res.contentDTOList[0].content) ||
                              ''

                            const newImgRefObj = JSON.parse(JSON.stringify(this.state.imageRef))
                            newImgRefObj[imageReFVal] = base64URL
                            this.setState({ imageRef: newImgRefObj })
                          })
                          .catch((error) => {
                            console.log(error)
                          })

                        break
                      }
                    }
                    if (!nonceAvailable) {
                      goOut('https://www.weatherbys.bank/sessionexpired')
                    }
                  })
                  // end
                }
              })
            })
            .catch((error) => {
              console.log(error)
            })

          break
        }
      }
      if (!nonceAvailable) {
        goOut('https://www.weatherbys.bank/sessionexpired')
      }
    })
  }

  render() {
    const { onCompanyClick, accessPointId } = this.props
    const { loading, accessPointDetails, imageRef } = this.state

    if (
      loading ||
      Object.keys(accessPointDetails).length === 0 ||
      (Object.keys(accessPointDetails).length > 0 &&
        accessPointDetails.accessPointDTO.type !== 'EXT')
    ) {
      return null
    }

    return (
      <span
        className={s.psdImgSpan}
        onClick={() => {
          onCompanyClick(accessPointId)
        }}
        id={accessPointId}
      >
        {imageRef[accessPointDetails.accessPointDTO.imgRefno.value] && (
          <img
            className={s.psdImage}
            src={`data:image/png;base64, ${
              imageRef[accessPointDetails.accessPointDTO.imgRefno.value]
            }`}
          />
        )}
        <i className={`${s.psdiconClass} ${s.psdright}`} />
      </span>
    )
  }
}

export default TppDetails
