import { connect } from 'react-redux'
import ManageCredentialsView from '../../components/ManageCredentialsView'
import { manageCredential } from './actions'

export const mapStateToProps = (state, ownProps) => {
  const { type } = ownProps.params
  return {
    type,
    name: type === 'password' ? 'Password' : 'Transaction PIN',
    info:
      type === 'password'
        ? 'You will use this password to log in to your online banking.'
        : 'You will use this PIN to validate your transactions in your online banking.',
    initialValues: {
      type,
    },
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: manageCredential,
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageCredentialsView)
