/**
 * Tidies deeply nested and poorly named data from API into something
 * easier to deal with!
 * @param  {Object} data - raw data from API
 */
export interface TidyBulkId {
  data: {
    status: {
      result: string
      contextID: string
      message: {
        code: string | number
        type: string
      }
    }
    userTemplateRelationships: any[]
  }
}
export default (data: TidyBulkId, state) => {
  const identifiers = data.data.userTemplateRelationships

  const tidyData = { items: {} }
  // eslint-disable-next-line array-callback-return
  identifiers.map((item) => {
    const fileIdentifier = item.userFIMappingDTO.fileIdentifierRegistrationDTO
    tidyData.items[item.partyId.value] = {
      bulkId: fileIdentifier.fileIdentifier,
    }
  })
  return tidyData
}
