import * as React from 'react'

function SvgLoading(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' width='1em' height='1em' {...props}>
      <path d='M20 35.5c-8.5 0-15.5-7-15.5-15.5S11.5 4.5 20 4.5s15.5 7 15.5 15.5c0 .5-.4.9-.9.9s-.9-.4-.9-.9c0-7.6-6.2-13.7-13.7-13.7S6.3 12.4 6.3 20 12.4 33.7 20 33.7c4.8 0 9.4-2.6 11.8-6.8.2-.4.8-.6 1.2-.3.4.2.6.8.3 1.2-2.7 4.8-7.8 7.7-13.3 7.7z' />
      <path d='M35 21.5c-.2 0-.3 0-.5-.1l-5.6-3.3c-.4-.3-.6-.8-.3-1.2.2-.4.8-.6 1.2-.3l4.9 2.9 3.1-4.5c.3-.4.8-.5 1.2-.2.4.3.5.8.2 1.2l-3.6 5.2c0 .2-.3.3-.6.3z' />
    </svg>
  )
}

export default SvgLoading
