import { dontDuplicate } from '../utils/routerBugWorkaround'

export default (store) => ({
  path: 'f/:folderName',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const MailList = require('../containers/MailList').default
        const { mailListFetchRequest } = require('../containers/MailLayout/actions')

        dontDuplicate(() => {
          store.dispatch(mailListFetchRequest(nextState.params.folderName))
        })

        cb(null, MailList)
      },
      'mail-list'
    )
  },
})
