import React from 'react'
import s from './styles.css'
import { decodeHTML } from '../../utils/decodeHTML'

type HeadingWithDetailsProps = {
  title: string
  details?: any[]
}

const HeadingWithDetails = ({ title, details }: HeadingWithDetailsProps) => (
  <div>
    <h1 className='reduced'>{decodeHTML(title)}</h1>
    <ul className={s.details}>
      {details?.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index}>{decodeHTML(item)}</li>
      ))}
    </ul>
  </div>
)

export default HeadingWithDetails
