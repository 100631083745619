import * as React from 'react'

function SvgHome(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 512 512'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M415.968 197.144V46.704h-63.987v83.59L255.983 30 0 297.37v16.737h63.987v167.11h159.985V380.958h64.022v100.26h159.985v-167.11H512v-16.739l-96.032-100.225'
        fill='#8F8F8B'
        fillRule='evenodd'
      />
    </svg>
  )
}

export default SvgHome
