import get from 'lodash/get'

export default (state, folderName = 'inbox') => {
  const items = get(state, `mail[${folderName}].items`)
  if (items) {
    let hasUnread = false
    Object.keys(items).forEach((key) => {
      if (!items[key].isRead) hasUnread = true
    })
    return hasUnread
  }
  return false
}
