/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { call, put, takeEvery, select, fork } from 'redux-saga/effects'
import { destroy } from 'redux-form'
import request from '../../utils/request'
import createMaskedIds from '../../utils/createMaskedIds'
import * as c from '../../constants'
import * as a from './actions'
import getRealToMasked from '../../selectors/getRealToMasked'
import getAccount from '../../selectors/getAccount'
import getTransactions from '../../selectors/getTransactions'
import getIsAccountMappingKnown from '../../selectors/getIsAccountMappingKnown'
import { superDatePickerInit } from '../SuperDatePicker/actions'
import { transactionsFetchMore, transactionsFetchLatest } from '../Transactions/actions'
import { mailListFetchRequest } from '../MailLayout/actions'
import humanError from '../../utils/humanError'
import { modalErrorShow } from '../../store/ui'
import { fetchAllAuthorisations } from '../Authorisations/sagas'
import { userDetailsUpdate } from '../../store/user'
import { entitiesUpdateItems } from '../../store/entities'
import getUserPermittedFunctions from '../../selectors/getUserPermittedFunctions'

export function* fetchData() {
  try {
    const nonce = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    const response = yield call(request, {
      id: c.requestAccounts,
      url: '/v1/accounts?expand=all',
      nonce,
    })
    const { items, entities } = response

    // Also - update the entities
    yield put(entitiesUpdateItems(entities))

    let realToMasked = yield select(getRealToMasked)

    if (!realToMasked) {
      realToMasked = yield call(createMaskedIds, items)
      yield put(a.accountsRealToMaskedUpdate(realToMasked))
    }

    const maskedItems = {}
    for (const key in items) {
      const maskedId = realToMasked[key]
      maskedItems[maskedId] = items[key]
      maskedItems[maskedId].id = maskedId
    }

    const itemsOrder = []
    for (const key in maskedItems) {
      const i = maskedItems[key]
      itemsOrder[i.order - 1] = key
    }
    // Remove any null items that stem from accounts potentially
    // having gaps in the order 1, 2, 3, null, null, 6, 7, 8
    const itemsOrderNulled = itemsOrder.filter((i) => i)

    yield put(a.accountsUpdate(maskedItems, itemsOrderNulled))

    yield call(
      accountsMappingAdd,
      'payment',
      'payment',
      'canMakePayment',
      c.requestPaymentMapping,
      'PC_F_DOM'
    )
    yield call(fetchAllAuthorisations)

    yield call(
      accountsMappingAdd,
      'bulk',
      'bulkUpload',
      'canMakeBulkPayment',
      c.requestPaymentsBulkMapping,
      'FU_F_DFT'
    )
    yield fork(
      accountsMappingAdd,
      'transfer',
      'transfer',
      'transferIsMapped',
      c.requestTransferMapping,
      'PC_F_SELF'
    )
    yield fork(
      accountsMappingAdd,
      'statements',
      'statements',
      'hasStatements',
      c.requestStatementsMapping,
      'CH_I_ST'
    )
    // yield fork(accountsMappingAdd, 'hasLoanStatements', c.requestStatementsMapping, 'CH_I_ST', 'loan')
    yield fork(
      accountsMappingAdd,
      'alerts',
      'alerts',
      'hasAlerts',
      c.requestAlertsMapping,
      'PC_F_DOM'
    )
    yield fork(
      accountsMappingAdd,
      'chequeBooks',
      'accServ',
      'hasChequeBooks',
      c.requestChequeBookMapping,
      'CH_N_CBR'
    )
    yield fork(
      accountsMappingAdd,
      'chequeStatus',
      'chequeStatus',
      'hasChequeStatus',
      c.reqChqStatMapp,
      'CH_I_CSE'
    )
    yield fork(
      accountsMappingAdd,
      'payInBooks',
      'accServ',
      'hasPayInBooks',
      c.requestPayInBookMapping,
      'CH_N_PBR'
    )
    yield put(mailListFetchRequest('inbox'))
    yield put(mailListFetchRequest('bulletins'))
  } catch (error) {
    console.error(error)
    const message = yield call(humanError, error)
    yield put(modalErrorShow(message))
  }
}

export function* accountsMappingAdd(
  mappingName,
  permissions,
  propertyName,
  requestId,
  mappingCode,
  accountType?: any
) {
  const isMappingKnown = yield select(getIsAccountMappingKnown, mappingName)
  const userPermittedFunctions = yield select(getUserPermittedFunctions)
  if (userPermittedFunctions[permissions] && !isMappingKnown && mappingCode !== undefined) {
    let nonce
    if (mappingCode === 'FU_F_DFT') {
      nonce = yield call(request, {
        id: c.requestNonce,
        url: 'v1/session/nonce',
      })
      const info = yield call(request, {
        id: c.requestBulkId,
        url: '/cz/v1/fileUploads/userFileIdentifiersMappings?locale=en',
        nonce,
      })
      yield put(
        userDetailsUpdate({
          bulkId: info,
        })
      )
    }
    nonce = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    let mappings
    if (accountType === 'LN') {
      mappings = yield call(request, {
        id: requestId,
        url: `/v1/accounts/loan?taskCode=${mappingCode}&locale=en`,
        nonce,
      })
    } else {
      mappings = yield call(request, {
        id: requestId,
        url: `/v1/accounts/demandDeposit?taskCode=${mappingCode}&locale=en`,
        nonce,
      })
    }
    yield put(a.accountsMappingAdd(mappings, mappingName, propertyName))
  }
}

export function* initAccount(action) {
  const p = action.payload
  const account = yield select(getAccount, p.id)
  const transactions = yield select(getTransactions, p.id)
  let details
  let principalBalance
  const partyId = account.customerId

  try {
    if (account.group === 'lending') {
      const nonce = yield call(request, {
        id: c.requestNonce,
        url: 'v1/session/nonce',
      })

      details = yield call(request, {
        id: c.requestLoanDetails,
        url: `/v1/accounts/loan/${account.accountId}`,
        nonce,
        partyId,
      })
      const nonce1 = yield call(request, {
        id: c.requestNonce,
        url: 'v1/session/nonce',
      })

      principalBalance = yield call(request, {
        id: c.requestLoanDetailsPrincipalbalance,
        url: `/v1/accounts/loan/${account.accountId}/outstanding`,
        nonce: nonce1,
        partyId,
      })
      details.principalBalance = principalBalance.principalBalance
      details.instalmentArrears = principalBalance.instalmentArrears
      yield put(a.accountDetailsUpdate(p.id, { details }))
    } else if (account.type === 'TD') {
      const response1 = yield call(request, {
        id: c.requestNonce,
        url: 'v1/session/nonce',
      })

      details = yield call(request, {
        id: c.requestTermDepositDetails,
        url: `/v1/accounts/deposit/${account.accountId}`,
        nonce: response1,
        partyId,
      })
      yield put(a.accountDetailsUpdate(p.id, { details }))
    } else {
      const response1 = yield call(request, {
        id: c.requestNonce,
        url: 'v1/session/nonce',
      })
      details = yield call(request, {
        id: c.requestAccountDetails,
        url: `/v1/accounts/demandDeposit/${account.accountId}?locale=en`,
        nonce: response1,
        partyId,
      })
      yield put(a.accountDetailsUpdate(p.id, details))
    }

    yield put(superDatePickerInit('transactions'))

    if (account.group === 'current') {
      yield put(destroy('transactionsFilters'))
    }
    if (!transactions || transactions.length === 0) {
      yield put(transactionsFetchMore(p.id))
    } else {
      yield put(transactionsFetchLatest(p.id))
    }
  } catch (error) {
    console.error(error)
    const message = yield call(humanError, error)
    yield put(modalErrorShow(message))
  }
}

export function* handleCustomerAddressUpdate(action) {
  try {
    const p = action.payload
    const account = yield select(getAccount, p.id)
    if (!account.customerAddress) {
      const response1 = yield call(request, {
        id: c.requestNonce,
        url: 'v1/session/nonce',
      })
      const details = yield call(request, {
        id: c.requestAccountCustomerAddress,
        url: `/cz/v1/accountAddress/${account.accountId}`,
        nonce: response1,
        partyId: account.customerId,
      })
      yield put(
        a.accountDetailsUpdate(p.id, {
          customerAddress: details,
        })
      )
    }
  } catch (error) {
    console.error(error)
    const message = yield call(humanError, error)
    yield put(modalErrorShow(message))
  }
}

export function* watchAccounts() {
  yield takeEvery(c.ACCOUNTS_FETCH, fetchData)
  yield takeEvery(c.ACCOUNT_INIT, initAccount)
  yield takeEvery(c.ACCOUNT_CUSTOMER_ADDRESS_UPDATE, handleCustomerAddressUpdate)
}
