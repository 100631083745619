import { connect } from 'react-redux'
import { panelHide } from '../../store/ui'
import Styleguide from '../../components/SlideyOrModal'

const mapStateToProps = (state, ownProps) => ({
  browser: state.browser,
  isOpen: state.ui.panel === ownProps.id,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCloseClick: (e) => {
    e.preventDefault()
    dispatch(panelHide())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Styleguide)
