import React from 'react'
import s from './styles.css'

type AccountPermissionsViewProps = {
  accountName: string
  accountNumber: string
  paymentTransfers: boolean
  accountEnquiries: boolean
}

const AccountPermissionsView = ({
  accountEnquiries,
  paymentTransfers,
  accountName,
  accountNumber,
}: AccountPermissionsViewProps) => (
  <div className={s.accountCardBoxView}>
    <div className={s.psdViewMode}>
      <span className={s.psdViewLeft}>
        <div className={s.psdAccountView}>{accountName}</div>
        <div className={s.psdAccountNumber}>{accountNumber} 60-93-03</div>
      </span>
      <span className={s.psdViewRight}>
        <div className={s.psdViewAccessTo}>Access To:</div>
        {accountEnquiries && <div className={s.psdViewPermissions}>Accounts and enquiries</div>}
        {paymentTransfers && <div className={s.psdViewPermissions}>Payments and transfers</div>}
      </span>
    </div>
  </div>
)

export default AccountPermissionsView
