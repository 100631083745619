import get from 'lodash/get'

/**
API1: /v1/transactions?discriminator=ACCOUNT_FINANCIAL&view=approval&status=PENDING_APPROVAL
Purpose: To retrive active PENDING_APPROVALs

API2: /digx/cz/v1/transactions/count?countFor=approval&locale=en"
Purpose: To check if current user do have any Approvals which the user can perform,

Note: Prior to calling API1, it is important to query API2 if the current user
do have any Approvals to perform.
Note: Currently from API2 List we check for any transactionType (Less stricter option)
 */

export default (data) => {
  const items = get(data, 'data.countDTOList')
  if (!items) return { approvalsExist: false }
  return {
    approvalsExist: items.some((item) => item.transactionList && item.transactionList.length > 0),
  }
}
