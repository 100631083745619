import get from 'lodash/get'
import toArray from 'lodash/toArray'
import moment from 'moment'

export interface TidySubscribedAlertsD {
  data: {
    actionSubscriptionDTO: {
      subscriptionId: string
      subscriptionStartDate: string
      subscriptionEndDate: string
      subscribedActions: {
        alertName: number
        subscriptionPreferences: {
          destination: string
        }[]
      }[]
    }[]
  }
}
export default (data: TidySubscribedAlertsD, state) => {
  const alerts = get(data, 'data.actionSubscriptionDTO[0]')
  const tidy: any = {}
  if (alerts) {
    tidy.subscriptionId = alerts.subscriptionId
    tidy.subStartDate = moment(alerts.subscriptionStartDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
    tidy.subEndDate = moment(alerts.subscriptionEndDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
    tidy.fields = []
    let subActions = get(alerts, 'subscribedActions')
    if (!(subActions instanceof Array)) {
      subActions = toArray(subActions)
    }
    // todo-ts: converted map to forEach
    subActions.forEach((alert) => {
      // todo-ts: converted map to forEach
      alert.subscriptionPreferences.forEach((item) => {
        if (item.destination === 'EMAIL') {
          if (tidy.fields && tidy.fields[alert.alertName]) {
            tidy.fields[alert.alertName].email = true
          } else {
            tidy.fields[alert.alertName] = {
              email: true,
            }
          }
        } else if (tidy.fields && tidy.fields[alert.alertName]) {
          tidy.fields[alert.alertName].sms = true
        } else {
          tidy.fields[alert.alertName] = {
            sms: true,
          }
        }
      })
    })
  }
  return tidy
}
