import React from 'react'
import Form from '../../containers/MarketingPreferencesForm'
import Breadcrumbs from '../Breadcrumbs'
import ActionHeader from '../ActionHeader'
import InnerContainer from '../InnerContainer'
import s from './styles.css'

const MarketingPreferencesView = () => (
  <div>
    <Breadcrumbs
      items={[
        {
          to: '/settings',
          text: 'Details & Settings',
        },
        {
          to: '/settings/marketing-preferences',
          text: 'Marketing preferences',
        },
      ]}
    />

    <InnerContainer>
      <ActionHeader title='Marketing preferences' />
      <div className={s.formWrap}>
        <Form standalone>
          <p>
            Your current contact preferences are detailed below. If you would like to update them
            please select the relevant preferences and confirm the change. Our records will
            automatically be updated within 2 working days.
          </p>
          <p>
            We will treat your data with care and respect and you can change your contact
            preferences at any time. You can find all the details in our{' '}
            <a href='http://www.weatherbys.bank/privacypolicy'>Privacy Policy</a>. We do not share
            or pass your personal data to other organisations for their own purposes.
          </p>
        </Form>
      </div>
    </InnerContainer>
  </div>
)

export default MarketingPreferencesView
