import result from 'lodash/result'
import * as c from '../../constants'

export const isLoggedOut = (
  state: {
    session?: {
      id?: string | number
    }
    changeCredentials?: {
      isNeeded?: string | boolean
    }
  },
  replace
) => {
  // todo-ts: changed the if conditional grouping - need testing
  if (!state.session || (state.session && !state.session.id)) {
    replace('/login')
  } else if (state.changeCredentials.isNeeded) {
    replace('/change-credentials')
  }
}

export const isLoggedIn = (state, replace) => {
  if (state.session && state.session.id && !state.changeCredentials.isNeeded) {
    replace('/accounts')
  }
}

export const isChangingCredentials = (state, replace) => {
  if (state.changeCredentials.isNeeded) {
    replace('/change-credentials')
  }
}

export const isNotChangingCredentials = (state, replace) => {
  if (!state.changeCredentials.isNeeded) {
    replace('/login')
  }
}

export const isSessionIdFromLocalStorageSafe = (state, replace) => {
  const tabOrScreenId = window.localStorage.getItem(c.tabOrScreenId)
  return !!tabOrScreenId && tabOrScreenId === result(state, 'session.sessionLocalStorageId')
}

export const isLocalStorageSessionActive = (state, replace, url) => {
  const sessionIdSafe = isSessionIdFromLocalStorageSafe(state, replace)
  if (!sessionIdSafe) {
    replace('/login')
  } else {
    replace(url)
  }
}

export const isLocalStorageSessionActiveBasic = (state, dispatch, replace) => {
  const sessionIdSafe = isSessionIdFromLocalStorageSafe(state, replace)
  if (!sessionIdSafe) {
    dispatch({
      type: c.LOGOUT_MULTI_LOGIN_REQUEST,
      payload: {
        url: 'https://www.weatherbys.bank/sessionexpired',
      },
    })
  }
}
