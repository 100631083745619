import React from 'react'
import ReactSelect from '../ReactSelect'
import s from './styles.css'

type SelectProps = {
  options: any[]
  input: any
  disabled?: boolean
  placeholder?: string
  isLoading?: boolean
}

const Select: React.FC<SelectProps> = ({
  input,
  options,
  disabled,
  placeholder = 'Please Choose...',
  isLoading,
}: SelectProps) => (
  <ReactSelect
    {...input}
    name={input.name}
    options={options}
    disabled={disabled}
    value={input.value.value}
    onBlur={() => input.onBlur(input.value)}
    placeholder={placeholder}
    inputProps={{
      id: input.name,
    }}
    isLoading={isLoading}
    className={s.root}
  />
)

export default Select
