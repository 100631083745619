import { takeLatest } from 'redux-saga/effects'
import * as c from '../../constants'
import updateList from '../../sagas/updateList'
import cancelUpcomingPayment from '../../sagas/cancelUpcomingPayment'
import { paymentStop } from './actions'

const futurePayments = {
  id: c.requestFuturePayments,
  url: 'cz/v1/payments/instructions?code=FD&type=NONREC&locale=en',
}

// eslint-disable-next-line import/prefer-default-export
export function* watchPaymentsFuture() {
  yield takeLatest(c.PAYMENTS_FUTURE_INIT, updateList('paymentsFuture', futurePayments))
  yield takeLatest(
    paymentStop.REQUEST,
    cancelUpcomingPayment(
      'paymentsFuture',
      c.requestFuturePaymentsStop,
      c.requestFuturePaymentsStopPatch,
      paymentStop,
      'FD'
    )
  )
}
