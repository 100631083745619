import React from 'react'
import DataTable from '../../containers/DataTable'
// import SuperDatePicker from '../../containers/SuperDatePicker'
// import SuperDatePickerField from '../../containers/SuperDatePickerField'
// import SlideyOrModal from '../../containers/SlideyOrModal'
import FilterPanel from '../../containers/FilterPanel'
import Form from './Form'

const ChequeStatusTable = (props) => (
  <div>
    <FilterPanel>
      <Form {...props} />
      {/* <SuperDatePickerField id='activityLogType' />
      <SlideyOrModal id='datePicker'>
        <SuperDatePicker
          id='activityLogType'
          onSubmit={props.onDateSubmit}
          className={s.datePicker}
        />
      </SlideyOrModal> */}
    </FilterPanel>
    <DataTable {...props} />
  </div>
)

export default ChequeStatusTable
