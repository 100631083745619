import React from 'react'
import Breadcrumbs from '../Breadcrumbs'
import s from './styles.css'
import FlowSwitch from '../../containers/FlowSwitch'
import FlowSuccess from '../FlowSuccess'
import InnerContainer from '../InnerContainer'
import Form from '../../containers/RequestInternationalPaymentForm'
import Warnings from '../Warnings'

type RequestInternationalPaymentProps = {
  accountId?: string
  userHasAccess?: boolean
}

const RequestInternationalPayment = ({
  accountId,
  userHasAccess,
}: RequestInternationalPaymentProps) => (
  <div className={s.root}>
    <Breadcrumbs
      items={[
        {
          to: '/payments',
          text: 'Payments',
        },
        {
          to: '/payments/request-interational',
          text: 'International payment',
        },
      ]}
    />
    <InnerContainer>
      {userHasAccess ? (
        <div>
          <h1>Request international payment</h1>
          <FlowSwitch
            SuccessComponent={FlowSuccess}
            successComponentProps={{
              message: 'Request sent',
              links: [
                {
                  to: '/payments/request-international',
                  text: 'Make another request',
                },
                {
                  to: '/accounts',
                  text: 'Back to my accounts',
                },
              ],
            }}
            formEl={<Form accountId={accountId} />}
          />
        </div>
      ) : (
        <Warnings
          items={[
            'This account does not have the required permissions to access this functionality.',
          ]}
        />
      )}
    </InnerContainer>
  </div>
)

export default RequestInternationalPayment
