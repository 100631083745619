/**
 * Tidies deeply nested and poorly named data from API into something
 * easier to deal with!
 * @param  {Object} data - raw data from API
 * @return {Object} data - tidied data
 */
export interface TidyVerifySortCodeD {
  data: {
    name: string
    branchAddress?: {
      line1?: string
      line2?: string
      line3?: string
      city?: string
      country?: string
      state?: string
    }
  }
}
export default (data: TidyVerifySortCodeD) => {
  const b = data.data
  const d = b.branchAddress
  if (d.state && d.state === 'FT-RESP-999') {
    return {
      noFastPayments: true,
    }
  }
  if (d.state && d.state === 'DIGX_BNK_DET001') {
    return {
      badSortCode: true,
    }
  }
  return {
    bankName: b.name,
    bankAddress1: d.line1,
    bankAddress2: d.line2,
    bankAddress3: d.line3,
    bankCity: d.city,
    bankCountry: d.country,
  }
}
