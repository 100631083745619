/**
 * Builds out body of message for international payment request
 * @param {obj} formData - Form data
 * @return {string} message - body of message
 */
export default (formData) => `
From: ${formData.fromAccount.label} -
From Account Number: ${formData.fromAccount.accountNumber} -
From Sort Code: ${formData.fromAccount.sortCode} -
Country: ${formData.country.label} -
Recipient: ${formData.name} -
Address: ${formData.address} - 
Reference: ${formData.reference} -
BIC/SWIFT: ${formData.swift} -
ABA: ${formData.aba} -
IBAN: ${formData.iban} -
Recipient Account Number: ${formData.accountNumber} -
Date: ${formData.date} -
Amount: ${formData.amount} -
Currency: ${formData.currency.value} -
Payment Option: ${formData.paymentOption ? formData.paymentOption.value : 'N/A'}
Payment Charges: ${formData.paymentCharges ? formData.paymentCharges.label : 'N/A'}
Comments: ${formData.comments}
`
