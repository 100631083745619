import React from 'react'
import COPErrorCross from '../../assets/icons/COPErrorCrossIcon'
import COPTickIcon from '../../assets/icons/COPTickIcon'
import s from './styles.css'

interface ErrorListProps {
  label: string
  value: string
  index: number
  type: 'Error' | 'Success'
}

const ErrorList: React.FC<ErrorListProps> = ({ label, value, index, type }) => (
  <div className={s.errorDiv} key={index}>
    <div className={s.innerErrorDiv}>
      <span className={s.innerErrorList}>
        {type === 'Error' ? (
          <COPErrorCross className={s.miniIcons} />
        ) : (
          <COPTickIcon className={s.miniIcons} />
        )}
        {label}
      </span>
      <span className={`${s.innerErrorList} ${s.innerErrorListBottom}`}>{value}</span>
    </div>
  </div>
)

export default ErrorList
