const STATIC_MESSAGE_DATA = {
  WEATHERBYS_TEL: '(0)1933 543543',
  WEATHERBYS_TEL_INTL: '+44 (0)1933 543543',
  WEATHERBYS_EMAIL: 'bank@weatherbys.bank',
}

export const constructMessage = (text, ...keysOrParams) => {
  return function (dataParams) {
    const data = { ...STATIC_MESSAGE_DATA, ...dataParams }
    const message = text.slice()
    keysOrParams.forEach((keysOrParam, i) => {
      message[i] = `${message[i]}${data[keysOrParam] || keysOrParam}`
    })
    return message.join('')
  }
}

export const MESSAGES = {
  MSG_PAYMENT_ISSUE_V1: constructMessage`There may have been an issue with this payment. Please contact the Helpdesk
    on ${'WEATHERBYS_TEL'} to confirm if this payment has been processed and quote
    reference number: ${'referenceNumber'}`,
  MSG_PAYMENT_ISSUE: constructMessage`To confirm if the payment has been sent please view your account transactions 
    list before trying again. You should wait 5 minutes to be sure. Alternatively, contact the Bank Helpdesk team on 
    ${'WEATHERBYS_TEL_INTL'} and quote reference ${'referenceNumber'}`,
}
