import React, { ReactNode } from 'react'
import s from './styles.css'

type MegaMenuItemProps = {
  children: ReactNode
  active?: boolean
  onMouseLeave?: () => void
  onMouseEnter?: () => void
  services?: boolean
}

const MegaMenuItem = ({
  active,
  children,
  onMouseLeave,
  onMouseEnter,
  services,
}: MegaMenuItemProps) => {
  let className = ''

  if (active) {
    className = s.active
  }

  return (
    <div
      className={`${s.root} ${services ? s.services : ''} ${className}`}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    >
      {children}
    </div>
  )
}

export default MegaMenuItem
