import * as c from '../../constants'
import { ITransferMoneyFormState } from '../TransferMoneyForm/reducer'

export interface ITransactionsFilterFormValue {
  cardTransactions?: boolean
  cheques?: boolean
  payments?: boolean
  reccurentPayments?: boolean
  internalTransfers?: boolean
  international?: boolean
  loanRepayments?: boolean
  interestAndCharges?: boolean
  cash?: boolean
  vat?: boolean
  incomingTransfers?: boolean

  all?: boolean
}
export interface ITransactionsFilterFormState {
  values: ITransactionsFilterFormValue
  fields?: any
}

export default function transactionsFilterFormReducer(
  state: ITransactionsFilterFormState,
  action
): ITransactionsFilterFormState {
  const m = action.meta
  const value = action.payload

  switch (action.type) {
    case c.REDUX_FORM_CHANGE:
      if (m && m.form === 'transactionsFilters') {
        // eslint-disable-next-line default-case
        switch (m.field) {
          case 'all':
            if (value === true) {
              return {
                ...state,
                values: {
                  ...state.values,
                  cardTransactions: false,
                  cheques: false,
                  payments: false,
                  reccurentPayments: false,
                  internalTransfers: false,
                  international: false,
                  loanRepayments: false,
                  interestAndCharges: false,
                  cash: false,
                  vat: false,
                  incomingTransfers: false,
                },
              }
            }
            break
          case 'cardTransactions':
          case 'cheques':
          case 'payments':
          case 'reccurentPayments':
          case 'internalTransfers':
          case 'international':
          case 'loanRepayments':
          case 'interestAndCharges':
          case 'cash':
          case 'vat':
          case 'incomingTransfers':
            if (value === true) {
              return {
                ...state,
                values: {
                  ...state.values,
                  all: false,
                },
              }
            }
            break
        }
      }
      return state
    case c.TRANSACTIONS_CLEAR_FILTERS:
      return undefined
    default:
      return state
  }
}
