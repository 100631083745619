import React from 'react'
import s from './styles.css'
import HeadingWithDetails from '../HeadingWithDetails'
import DetailList from '../DetailList'
import Strip from '../Strip'

type LoanAccountProps = {
  balance: string
  title: string
  typeTitle: string
  accountNumber: string
  details?: {
    instalmentArrears: string | number
    penalty: string
    amountPaid: string | number
    nextDate: string
    nextAmount: string | number
    interestRate: string | number
    maturityDate: string
    loanAmount: string | number
    interestRepaymentFrequency: string | number
    remainingInstalments: string | number
    principalRepaymentFrequency: string | number
  }
}

const LoanAccount = ({ title, balance, accountNumber, typeTitle, details }: LoanAccountProps) => {
  const instalmentArrears = details.instalmentArrears
    ? [
        {
          title: 'Instalment arrears',
          value: details.instalmentArrears,
        },
      ]
    : []

  const penalty = details.instalmentArrears
    ? [
        {
          title: 'Penalty',
          value: details.penalty,
        },
      ]
    : []

  return (
    <div className={s.root}>
      <div className={`${s.inner} ${s.innerLoanDeposit}`}>
        <div className={s.row}>
          <div className={s.col}>
            <HeadingWithDetails title={title} details={[typeTitle, accountNumber]} />
          </div>
          <div className={s.col}>
            <DetailList
              items={[
                {
                  title: 'Outstanding',
                  value: balance,
                  type: 'title',
                },
                {
                  title: 'Amount repaid',
                  value: details.amountPaid,
                },
              ]}
            />
          </div>
          <div className={s.col}>
            <DetailList
              items={[
                {
                  title: 'Next instalment date',
                  value: details.nextDate,
                },
                {
                  title: 'Next instalment amount',
                  value: details.nextAmount,
                },
              ]}
            />
          </div>
        </div>
      </div>
      <Strip accType='True'>
        <div className={s.row}>
          <div className={s.col}>
            <DetailList
              items={[
                {
                  title: 'Interest rate',
                  value: details.interestRate,
                },
                {
                  title: 'End date',
                  value: details.maturityDate,
                },
                ...penalty,
              ]}
            />
          </div>
          <div className={s.col}>
            <DetailList
              items={[
                {
                  title: 'Amount borrowed',
                  value: details.loanAmount,
                },
                {
                  title: 'Interest repayment frequency',
                  value: details.interestRepaymentFrequency,
                },
                ...instalmentArrears,
              ]}
            />
          </div>
          <div className={s.col}>
            <DetailList
              items={[
                {
                  title: 'Remaining instalments',
                  value: details.remainingInstalments,
                },
                {
                  title: 'Principal repayment frequency',
                  value: details.principalRepaymentFrequency,
                },
              ]}
            />
          </div>
        </div>
      </Strip>
    </div>
  )
}

export default LoanAccount
