import { connect } from 'react-redux'
import { entitiesChangeActive } from '../../store/entities'
import { mobMenuHide } from '../../store/ui'
import getCurrentPath from '../../selectors/getCurrentPath'
import SelectEntity from '../../components/SelectEntity'
import getEntities from '../../selectors/getEntities'

const mapDispatchToProps = (dispatch) => ({
  onChange: (e) => {
    dispatch(entitiesChangeActive(e.value))
    dispatch(mobMenuHide())
  },
})

const mapStateToProps = (state) => {
  let value
  let isDisabled
  const path = getCurrentPath(state)
  const parts = path.split('/')

  if (parts[0] === 'settings' && parts[1] === 'activity-log') {
    value = false
    isDisabled = true
  } else {
    value = state.entities.activeEntityId
  }

  return {
    value,
    options: [
      {
        label: 'All Accounts',
        value: false,
      },
      ...getEntities(state),
    ],
    isDisabled,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectEntity)
