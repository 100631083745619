import { connect } from 'react-redux'
import { submit } from 'redux-form'
import Card from '../Card'
import getAccount from '../../selectors/getAccount'
import { panelShow, panelHide } from '../../store/ui'
import { renameAccount, accountsOrderChange } from '../ManageAccounts/actions'

export const mapStateToProps = (state, ownProps) => {
  const account = getAccount(state, ownProps.id)
  let thisState
  if (state.ui.panel === ownProps.id) {
    thisState = 'editing'
  } else if (state.ui.panel !== false) {
    thisState = 'disabled'
  }

  let details

  if (account.sortCode) {
    details = `${account.sortCode} ${account.accountNumber}`
  } else {
    details = account.accountNumber
  }

  return {
    title: account.title,
    details,
    actions: [
      {
        text: 'Rename',
        id: 'rename',
        mobileButtonStyle: 'ghost-dark',
      },
      {
        text: 'Up',
        id: 'up',
        icon: 'up',
        mobileButtonStyle: 'ghost-dark',
      },
      {
        text: 'Down',
        id: 'down',
        icon: 'down',
        mobileButtonStyle: 'ghost-dark',
      },
    ],
    state: thisState,
    form: {
      save: renameAccount,
      initialValues: {
        id: ownProps.id,
        accountName: account.title,
      },
    },
  }
}

export const mapDispatchToProps = (dispatch, ownProps) => ({
  onActionClick: (actionId) => {
    if (actionId === 'rename') dispatch(panelShow(ownProps.id))
    if (actionId === 'up') dispatch(accountsOrderChange(ownProps.id, 'up', ownProps.entity))
    if (actionId === 'down') dispatch(accountsOrderChange(ownProps.id, 'down', ownProps.entity))
  },
  onCancelClick: (e) => {
    e.preventDefault()
    dispatch(panelHide())
  },
  onSaveClick: (e) => {
    e.preventDefault()
    dispatch(submit('account-edit-single'))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Card)
