import React from 'react'
import Button from '../../Button'
import s from './styles.css'

type WelcomeStepProps = {
  steps: string[]
  onClickStart: () => void
}

export const WelcomeStep = ({ steps, onClickStart }: WelcomeStepProps) => {
  const allSteps = {
    terms: 'Read and accept the Terms and Conditions',
    pass: 'Choose a Password',
    pin: 'Choose a Transaction PIN',
  }

  return (
    <div className={`${s.root} copy`}>
      <h2 className='legend'>Getting started</h2>

      <p>As this is your first time logging in, we ask you to:</p>
      <ol>
        {Object.keys(allSteps).map((key) => {
          const text = allSteps[key]
          const stepClass = steps.indexOf(key) > -1 ? '' : s.disabled
          return (
            <li className={stepClass} key={key}>
              {text}
            </li>
          )
        })}
      </ol>

      <p>You will need the temporary PIN we have sent you.</p>

      {/* eslint-disable-next-line react/style-prop-object */}
      <Button text='Next' style='secondary' onClick={onClickStart} />
    </div>
  )
}

export default WelcomeStep
