// eslint-disable-next-line import/prefer-default-export
export const getSubheader = (state) => {
  const { pathname } = state.routing.locationBeforeTransitions
  const { isFirstTime } = state.changeCredentials
  const { isNeeded } = state.changeCredentials

  if (pathname === 'login') {
    return 'Simple and Secure'
  }
  if (pathname === 'change-credentials' && isNeeded && isFirstTime) {
    return 'Welcome'
  }
  if (pathname === 'change-credentials-new') {
    return 'Welcome'
  }
  return undefined
}
export const pathName = (state) => {
  const { pathname } = state.routing.locationBeforeTransitions
  return pathname
}
export const getIsLoading = (state) => state.changeCredentials.isLoading

export const getAuthError = (state) => state.changeCredentials.authError

export const getCurrentStep = (state) => {
  const { currentStep, steps } = state.changeCredentials
  return steps[currentStep]
}
