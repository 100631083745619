import React from 'react'
import { Link } from 'react-router'
import s from './styles.css'

type TextJumpoffsProps = {
  items: {
    title: string
    bodyText: string
    linkText: string
    to: string
    marginTop?: boolean
    border?: boolean
    button?: boolean
    buttonClass?: string // todo - was added due to TextJumpoffs/index.tsx passing it
  }[]
}

const TextJumpoffs = ({ items }: TextJumpoffsProps) => (
  <div className={s.root}>
    {items.map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={index} className={`${s.item} ${item.border ? s.border : ''}`}>
        <h3 className={s.title}>{item.title}</h3>
        <p className={item.marginTop ? s.marginTop : s.body}>{item.bodyText}</p>
        <Link className={item.button ? s.button : s.link} to={item.to}>
          {item.linkText}
        </Link>
      </div>
    ))}
  </div>
)

export default TextJumpoffs
