import * as c from '../../constants'

export interface IMailLayoutState {
  inbox: {
    loading: boolean
    items: any
  }
  outbox: {
    loading: boolean
    items: any
  }
  bulletins: {
    loading: boolean
    items: any
  }
}

const initialState: IMailLayoutState = {
  inbox: {
    loading: true,
    items: {},
  },
  outbox: {
    loading: true,
    items: {},
  },
  bulletins: {
    loading: true,
    items: {},
  },
}

export default function mailLayoutReducer(state = initialState, action): IMailLayoutState {
  const p = action.payload
  switch (action.type) {
    case c.MAIL_LIST_UPDATE:
      return {
        ...state,
        [p.folderName]: {
          loading: false,
          items: p.items,
        },
      }
    case c.MAIL_LIST_FETCH_REQUEST:
      return {
        ...state,
        [p.folderName]: {
          ...state[p.folderName],
          loading: true,
        },
      }
    case c.MAIL_DETAIL_UPDATE:
      return {
        ...state,
        [p.folderName]: {
          ...state[p.folderName],
          items: {
            ...state[p.folderName].items,
            [p.id]: p.detail,
          },
        },
      }
    default:
      return state
  }
}
