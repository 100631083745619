import React from 'react'
import s from './styles.css'

type SuperDatePickerFieldProps = {
  onDateClick: () => void
  dateRangeText?: string
}

export const SuperDatePickerField = ({ onDateClick, dateRangeText }: SuperDatePickerFieldProps) => (
  <div>
    <label>Dates</label>
    <div className={s.input}>
      <input
        readOnly
        placeholder='No dates specified'
        type='text'
        onClick={onDateClick}
        onKeyPress={onDateClick}
        value={dateRangeText}
      />
    </div>
  </div>
)

export default SuperDatePickerField
