/* eslint-disable */
// @ts-nocheck
// todo - eslint and typescript
import React from 'react'
import s from './styles.css'

import StyleguideItem from './StyleguideItem'
import StyleguideForms from './StyleguideForms'

import Button from '../../components/Button'
import ButtonBlock from '../../components/ButtonBlock'
import FilterPanel from '../../components/FilterPanel'
import DataTable from '../../components/DataTable'
import Pagination from '../../components/Pagination'
import DetailsBlock from '../../components/DetailsBlock'
import DetailsGroup from '../../components/DetailsGroup'
import Jumpoffs from '../../components/Jumpoffs'
import AccountList from '../../components/AccountList'
import QuickLinks from '../../components/QuickLinks'
import AlertPanel from '../../components/AlertPanel'
import Card from '../../containers/Card'
import MiniPanel from '../../components/MiniPanel'
import InfoStrip from '../../components/InfoStrip'
import TextJumpoffs from '../../components/TextJumpoffs'
import Steps from '../../components/Steps'
import Status from '../../components/Status'
import DocOptions from '../../components/DocOptions'
import Breadcrumbs from '../../components/Breadcrumbs'
import Sidebar from '../../components/Sidebar'
import MessageList from '../../components/MessageList'
import Tooltip from '../../containers/Tooltip'
import LinkList from '../../components/LinkList'
import SuperDatePicker from '../../containers/SuperDatePicker'
import ResultsInfo from '../../components/ResultsInfo'
import HeadingWithDetails from '../../components/HeadingWithDetails'
import DetailList from '../../components/DetailList'
import Warnings from '../../components/Warnings'
import Stripey from '../../components/Stripey'

const onClick = () => {
  // console.log('DO NOTHING'); // todo - not sure why this exist
}

type StyleguideViewProps = {
  browser?: {
    lessThan: {
      medium: string | boolean
    }
  }
  transactions: any // todo - was object.
  payees: any // todo - was object
}

const StyleguideView = ({ transactions, browser, payees }: StyleguideViewProps) => (
  <div>
    <div className={s.inner}>
      <h1>Styleguide</h1>
    </div>

    <StyleguideItem title='Typography'>
      <div className='copy'>
        <h1>This is a Level 1 Heading</h1>

        <h1>This is a Level 1 Heading (with subheading)</h1>
        <h2 className='subheader'>This is a Level 2 Subheading</h2>

        <h1 className='underline'>This is a Level 1 Heading (underlined)</h1>

        <h1 className='reduced'>This is a Level 1 Heading (reduced)</h1>

        <h2>This is a Level 2 Heading</h2>
        <h3>This is a Level 3 Heading</h3>

        <h2 className='legend'>This is a form legend style h2</h2>

        <p>
          This is some paragraph text. <a href='#'>This is an anchor tag</a>, consectetur adipiscing
          elit. In aliquam tempus risus et ullamcorper. Nullam bibendum risus sit amet ante pretium
          cursus. Quisque in aliquam magna. In vulputate lobortis viverra. Praesent maximus arcu
          eget hendrerit rmentum. Aliquam gravida fringilla dolor, consectetur mattis lorem vehicula
          in.
        </p>

        <p>
          This is some some more paragraph text. <strong>This is some strong text</strong>,
          <em>here is some emphasised text</em>.
        </p>

        <ul>
          <li>List item one.</li>
          <li>List item two.</li>
          <li>List item three.</li>
        </ul>

        <ol>
          <li>List item one.</li>
          <li>List item two.</li>
          <li>List item three.</li>
        </ol>
      </div>
    </StyleguideItem>

    <StyleguideForms />

    <StyleguideItem title='Buttons'>
      <div className={s.row}>
        <div className={s.col}>
          <Button text='Primary button' />
        </div>
        <div className={s.col}>
          <Button style='secondary' text='Secondary button' />
        </div>
        <div className={s.col}>
          <Button style='dark' text='Dark button' />
        </div>
        <div className={s.col}>
          <Button style='ghost-dark' text='Ghost Button' />
        </div>
        <div className={s.col}>
          <Button submit loading text='Loading Button' />
        </div>
      </div>
    </StyleguideItem>

    <StyleguideItem title='Buttons (disabled)'>
      <div className={s.row}>
        <div className={s.col}>
          <Button text='Primary button' disabled />
        </div>
        <div className={s.col}>
          <Button style='secondary' text='Secondary button' disabled />
        </div>
        <div className={s.col}>
          <Button style='dark' text='Dark button' disabled />
        </div>
        <div className={s.col}>
          <Button style='ghost-dark' text='Ghost Button' disabled />
        </div>
      </div>
    </StyleguideItem>

    <StyleguideItem title='Button Block'>
      <ButtonBlock
        items={[
          {
            text: 'Some button',
            to: '/',
          },
          {
            text: 'Some other button',
            to: '/',
          },
        ]}
      />
    </StyleguideItem>

    <StyleguideItem title='Super Date Picker'>
      <SuperDatePicker id='styleguide' />
    </StyleguideItem>

    <StyleguideItem title='Breadcrumbs' type='wide'>
      <Breadcrumbs
        items={[
          {
            to: '/accounts',
            text: 'My Accounts',
          },
          {
            to: '/accounts/010101',
            text: 'Current Account Tom',
          },
        ]}
      />
    </StyleguideItem>

    <StyleguideItem title='Filter Panel' type='wide'>
      <FilterPanel />
    </StyleguideItem>

    <StyleguideItem title='Filter Panel - A to Z' type='wide'>
      <FilterPanel navType='AToZ' />
    </StyleguideItem>

    <StyleguideItem title='Data Table' type='wide'>
      <DataTable rows={transactions.body} headerCols={transactions.header} browser={browser} />
    </StyleguideItem>

    <StyleguideItem title='Data Table - Clickable' type='wide'>
      <DataTable
        rows={payees.body}
        headerCols={payees.header}
        browser={browser}
        // onRowClick={onClick} // todo - never used by DataTable
      />
    </StyleguideItem>

    <StyleguideItem title='Results Info - loading'>
      <ResultsInfo state={{ state: 'loading' }} />
    </StyleguideItem>

    <StyleguideItem title='Results Info - loading empty'>
      <ResultsInfo state={{ state: 'loadingEmpty' }} />
    </StyleguideItem>

    <StyleguideItem title='Results Info - completely empty'>
      <ResultsInfo state={{ state: 'completelyEmpty' }} />
    </StyleguideItem>

    <StyleguideItem title='Results Info - no results'>
      <ResultsInfo state={{ state: 'noResults' }} />
    </StyleguideItem>

    <StyleguideItem title='Results Info - end of results'>
      <ResultsInfo state={{ state: 'endOfResults' }} />
    </StyleguideItem>

    <StyleguideItem title='Results Info - end of existing'>
      <ResultsInfo state={{ state: 'endOfExisting' }} />
    </StyleguideItem>

    <StyleguideItem title='Results Info - error'>
      <ResultsInfo
        state={{ state: 'error', message: 'This is an error message from the server.' }}
      />
    </StyleguideItem>

    <StyleguideItem title='Pagination'>
      <Pagination />
    </StyleguideItem>

    <StyleguideItem title='Heading With Details'>
      <HeadingWithDetails title='Current Account Tom' details={['12-34-56', '12345678']} />
    </StyleguideItem>

    <StyleguideItem title='Detail List'>
      <DetailList
        items={[
          {
            title: 'Available Balance',
            value: '£1,000,000.00',
          },
          {
            title: 'Overdraft Limit',
            value: '£22,000.00',
          },
        ]}
      />
    </StyleguideItem>

    <StyleguideItem title='Detail List (with title item and long item)'>
      <DetailList
        items={[
          {
            title: 'Available Balance',
            value: '£1,000,000.00',
            type: 'title',
          },
          {
            title: 'Something a bit longer',
            value: 'With a long value string',
            type: 'long',
          },
        ]}
      />
    </StyleguideItem>

    <StyleguideItem title='Stripey Separator' type='wide'>
      <Stripey />
    </StyleguideItem>

    <StyleguideItem title='Details Block'>
      <DetailsBlock
        descriptor='From'
        title='Current Account Tom'
        extras={['12-34-56 12345678', 'Other details']}
        linkText='Change account'
      />
    </StyleguideItem>

    <StyleguideItem title='Details Group'>
      <DetailsGroup
        items={[
          {
            descriptor: 'From',
            title: 'Current Account Tom',
            extras: ['12-34-56 12345678'],
          },
          {
            descriptor: 'Payee',
            title: 'John Lewis',
            extras: ['12-34-56 12345678'],
          },
          {
            descriptor: 'Amount',
            title: '£30,000,000.00',
          },
          {
            descriptor: 'Reference',
            title: 'Long reference to test what happens here',
          },
          {
            descriptor: 'When',
            title: '20/01/2017',
          },
          {
            descriptor: 'Recurrent',
            title: 'Pay monthly until further notice',
          },
        ]}
      />
    </StyleguideItem>

    <StyleguideItem title='Jumpoffs'>
      <Jumpoffs
        items={[
          {
            title: 'Make a Payment',
            icon: 'makePayment',
            to: '/accounts',
          },
          {
            title: 'Set up alerts',
            icon: 'alert',
            to: '/accounts',
          },
          {
            title: 'Transfer money',
            icon: 'transferMoney',
            to: '/accounts',
          },
        ]}
      />
    </StyleguideItem>

    <StyleguideItem title='Account List'>
      <AccountList
        title='Current Account'
        footerText='Going away soon? Get ready with some US dollars
          of Euros. <a>Contact our team</a> for rates and more information.'
        items={[
          {
            id: '1',
            title: 'Test Item Title',
            balance: '£1,000,000',
            accountNumber: '12345678',
            sortCode: '00 00 00',
            canMakePayment: true,
          },
          {
            id: '2',
            title: 'Other Test Item Title',
            details: '0000 00000',
            balance: '£1,000,000',
            accountNumber: '12345678',
            sortCode: '00 00 00',
          },
        ]}
      />
    </StyleguideItem>

    <StyleguideItem title='Account List (no advert)'>
      <AccountList
        title='Current Account'
        items={[
          {
            id: '1',
            title: 'Test Item Title',
            details: '0000 00000',
            balance: '£1,000,000',
            accountNumber: '12345678',
            sortCode: '00 00 00',
            canMakePayment: true,
          },
          {
            id: '2',
            title: 'Other Test Item Title',
            details: '0000 00000',
            balance: '£1,000,000',
            accountNumber: '12345678',
            sortCode: '00 00 00',
          },
        ]}
      />
    </StyleguideItem>

    <StyleguideItem title='Account List (Loading Empty)'>
      <AccountList isLoadingEmpty />
    </StyleguideItem>

    {
      // Todo - add a dummy mapping list for the styleguide
    }
    <StyleguideItem title='Quick links' type='wide'>
      <QuickLinks mappingList={{}} />
    </StyleguideItem>

    <StyleguideItem title='Alert Panel' type='wide'>
      <AlertPanel />
    </StyleguideItem>

    <StyleguideItem title='Info Strip' type='wide'>
      <InfoStrip title='Help with payments?'>
        <p>
          If you have any questions about making a payment, need to send us a CHAPS transfer or
          require assistance with international payments, our team is here to help you by phone at{' '}
          <a>+44 (0) 1933 543543</a> or send us a <a>Secure Message</a>
        </p>
      </InfoStrip>
    </StyleguideItem>

    <StyleguideItem title='Card (Basic)'>
      <Card title='Current Account Tom' details='12-34-56 12345678' text='£1,100,00.00' />
      <Card title='Current Account Alice' details='12-34-56 12345678' text='£100.00' />
    </StyleguideItem>

    <StyleguideItem title='Card (Action)'>
      <Card title='Current Account Tom' details='12-34-56 12345678' action='Rename' />
      <Card title='Current Account Alice' details='12-34-56 12345678' action='Rename' />
      <Card title='Current Account JimBob' details='12-34-56 12345678' action='Rename' />
    </StyleguideItem>

    <StyleguideItem title='Card (Editing)'>
      <Card
        title='Current Account Tom'
        details='12-34-56 12345678'
        action='Rename'
        state='disabled'
      />
      <Card
        title='Current Account Alice'
        details='12-34-56 12345678'
        action='Rename'
        state='disabled'
      />
      <Card
        title='Current Account JimBob'
        details='12-34-56 12345678'
        action='Rename'
        state='editing'
        form={{
          save: () => {},
        }}
      />
    </StyleguideItem>

    <StyleguideItem title='Mini Panel'>
      <div className={s.width4}>
        <MiniPanel />
      </div>
    </StyleguideItem>

    <StyleguideItem title='Text Jumpoffs'>
      <TextJumpoffs
        items={[
          {
            title: 'Bulk Payments',
            bodyText: 'Pay multiple people or companies simultaneously from an Excel or CSV File.',
            linkText: 'Bulk Payments',
          },
          {
            title: 'Manage payees',
            bodyText:
              'View, modify or delete the people and companies that you have paid in the past.',
            linkText: 'Manage payees',
          },
          {
            title: 'International Payment',
            bodyText: 'Testing this item with less text.',
            linkText: 'Request Payment',
          },
        ]}
      />
    </StyleguideItem>

    <StyleguideItem title='Steps'>
      <Steps numItems={2} activeItem={0} />
      <Steps numItems={2} activeItem={1} />

      <Steps numItems={3} activeItem={0} />
      <Steps numItems={3} activeItem={1} />
      <Steps numItems={3} activeItem={2} />

      <Steps numItems={5} activeItem={0} />
      <Steps numItems={5} activeItem={1} />
      <Steps numItems={5} activeItem={2} />
      <Steps numItems={5} activeItem={3} />
      <Steps numItems={5} activeItem={4} />
    </StyleguideItem>

    <StyleguideItem title='Status'>
      <Status text='Setup Done' />
    </StyleguideItem>

    <StyleguideItem title='Warnings'>
      <Warnings
        items={[
          'This is a warning. It can be quite long, it may be a little bit descriptive.',
          'This is a second warning.',
        ]}
      />
    </StyleguideItem>

    <StyleguideItem title='Document Options'>
      <DocOptions />
    </StyleguideItem>

    <StyleguideItem title='Sidebar' type='wide'>
      <Sidebar
        items={[
          { to: '/accounts', title: 'Some Link' },
          { to: '/styleguide', title: 'Active Link' },
          { to: '/accounts', title: 'Another link' },
        ]}
      />
    </StyleguideItem>

    <StyleguideItem title='Message List'>
      <MessageList
        items={[
          {
            id: '1',
            subject: 'Re: Loan Application',
            excerpt: 'Dear Mr Thomson, we are pleased to announce that...',
            date: 'Today - 09:21',
            isRead: false,
          },
          {
            id: '2',
            subject: 'Re: Loan Application',
            excerpt: 'Dear Mr Thomson, we received your paper work and...',
            date: 'Friday 9th February - 10:20',
            isRead: true,
          },
          {
            id: '3',
            subject: 'Weatherbys Update 2017',
            excerpt: 'Happy New Year! At Weatherbys we all wish you...',
            date: '1st January - 00:00',
            isRead: true,
          },
        ]}
      />
    </StyleguideItem>

    <StyleguideItem title='LinkList'>
      <LinkList
        items={[
          {
            to: '/account-fees',
            title: 'View account fees',
          },
          {
            to: '/statements',
            title: 'Statements',
          },
          {
            to: '/account-services',
            title: 'Account services',
          },
        ]}
      />
    </StyleguideItem>

    <StyleguideItem title='Tooltip'>
      <Tooltip
        text={`This is some tooltip text.
      It should provide useful information about a
      specific part of the app.`}
        id='styleguide1'
      >
        <span style={{ color: '#333' }}>SWIFT Code</span>
      </Tooltip>
    </StyleguideItem>
  </div>
)

export default StyleguideView
