import { connect } from 'react-redux'

import Tab from '../../components/Tabs/Tab'
import * as a from '../Transactions/actions'
import { isTabActive } from './selectors'

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: (e) => {
    e.preventDefault()
    dispatch(a.transactionsSwitchDebitCredit(ownProps.filter))
  },
})

const mapStateToProps = (state, ownProps) => ({
  active: isTabActive(state, ownProps),
})

export default connect(mapStateToProps, mapDispatchToProps)(Tab)
