export default {
  10: 'Once',
  20: 'Daily',
  30: 'Weekly',
  40: 'Fortnightly',
  50: 'Monthly',
  60: 'Bi-monthly',
  70: 'Quarterly',
  80: 'Half-yearly',
  90: 'Yearly',
}
