import React from 'react'
import s from './styles.css'
import FieldLayout, { FieldLayoutProps } from '../FieldLayout'
import Checkbox from '../Checkbox'

type CheckboxesProps = {
  options: { [key: string]: string }[]
  direction?: 'row' | 'column'
} & Omit<FieldLayoutProps, 'children' | 'position'>

const Checkboxes = ({ options, direction, ...fieldLayoutProps }: CheckboxesProps) => (
  <FieldLayout {...fieldLayoutProps} position={direction === 'column' ? 'top' : undefined}>
    <div className={`${s.inner} ${s[direction]}`}>
      {options.map(({ name: optionName, label: optionLabel }) => (
        <Checkbox name={optionName} label={optionLabel} key={optionName} />
      ))}
    </div>
  </FieldLayout>
)

export default Checkboxes
