import { connect } from 'react-redux'
import data from './data'
import Styleguide from '../../components/StyleguideView'

const mapDispatchToProps = {}

const mapStateToProps = (state) => ({
  transactions: data.transactions,
  payees: data.payees,
  browser: state.browser,
})

export default connect(mapStateToProps, mapDispatchToProps)(Styleguide)
