import { call, put, takeLatest, select, delay } from 'redux-saga/effects'
import * as c from '../../constants'
import request from '../../utils/request'
import humanError from '../../utils/humanError'
import { renameAccount, accountsOrderSubmitFinished } from './actions'
import { modalErrorShow, panelHide } from '../../store/ui'
import { accountDetailsUpdate } from '../Accounts/actions'
import { getAccounts } from './selectors'

export function* handleNameChange(action) {
  try {
    const formData = action.payload
    const accounts = yield select(getAccounts)
    const { id } = formData
    const newName = formData.accountName
    const partyId = accounts.items[id].customerId
    const selectedAccount = accounts.items[id]
    let details: any = {} // todo-ts was ''

    const nonce = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })

    yield call(request, {
      id: c.requestAccountNameChange,
      url: 'cz/v1/accountNickname?locale=en',
      nonce,
      partyId,
      payload: {
        id,
        newName,
      },
    })
    if (selectedAccount.group === 'lending') {
      const response1 = yield call(request, {
        id: c.requestNonce,
        url: 'v1/session/nonce',
      })

      details = yield call(request, {
        id: c.requestLoanDetails,
        url: `/v1/accounts/loan/${selectedAccount.accountId}`,
        nonce: response1,
        partyId,
      })
      yield put(
        accountDetailsUpdate(id, {
          title: details.accountName,
        })
      )
    } else if (selectedAccount.type === 'termDeposit') {
      const response1 = yield call(request, {
        id: c.requestNonce,
        url: 'v1/session/nonce',
      })

      details = yield call(request, {
        id: c.requestTermDepositDetails,
        url: `/v1/accounts/deposit/${selectedAccount.accountId}`,
        nonce: response1,
        partyId,
      })
      yield put(
        accountDetailsUpdate(id, {
          title: details.accountName,
        })
      )
    } else {
      const response1 = yield call(request, {
        id: c.requestNonce,
        url: 'v1/session/nonce',
      })
      details = yield call(request, {
        id: c.requestAccountDetails,
        // url: `/v1/accounts/demandDeposit/${selectedAccount.accountId}`, // TODO-API-DELETE
        url: `/v1/accounts/demandDeposit/${selectedAccount.accountId}?locale=en`,
        nonce: response1,
        partyId,
      })
      yield put(
        accountDetailsUpdate(id, {
          title: details.accountName,
        })
      )
    }

    yield put(panelHide())
  } catch (error) {
    console.error(error)
    const message = yield call(humanError, error)
    yield put(panelHide())
    yield put(modalErrorShow(message))
  }
}

export function* handleOrderSubmit(action) {
  try {
    const accounts = yield select(getAccounts)
    const { items, itemsOrder } = accounts

    const nonce = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })

    yield call(request, {
      id: c.requestAccountOrderChange,
      url: 'cz/v1/accountNickname?locale=en',
      nonce,
      payload: {
        items,
        itemsOrder,
      },
    })

    yield put(accountsOrderSubmitFinished(true))
    yield delay(5000)
    yield put(accountsOrderSubmitFinished())
  } catch (error) {
    console.error(error)
    const message = yield call(humanError, error)
    yield put(accountsOrderSubmitFinished())
    yield put(panelHide())
    yield put(modalErrorShow(message))
  }
}

export function* watchManageAccounts() {
  yield takeLatest(renameAccount.REQUEST, handleNameChange)
  yield takeLatest(c.ACCOUNTS_ORDER_SUBMIT, handleOrderSubmit)
}
