import React from 'react'
import { reduxForm, InjectedFormProps } from 'redux-form'
import BottomError from '../Forms/BottomError'
import Modal from '../../containers/Modal'
import SubmitCancel from '../Forms/SubmitCancel'
import { decodeHTML } from '../../utils/decodeHTML'

type StopModalProps = {
  handleSubmit: () => void
  paymentStop: () => void
  submitting?: boolean
  error?: string
  selectedPayment?: {
    recipientName?: string
    date: string
  }
}

const StopModal = ({
  handleSubmit,
  paymentStop,
  error,
  submitting,
  selectedPayment,
}: InjectedFormProps<any> & StopModalProps) => (
  <Modal id='paymentsFutureStop' size='medium' title='Cancel payment?'>
    <form onSubmit={handleSubmit(paymentStop)}>
      {selectedPayment && (
        <p>
          This will cancel the payment to{' '}
          <strong>{decodeHTML(selectedPayment.recipientName)}</strong> you had scheduled for{' '}
          <strong>{selectedPayment.date}.</strong> Do you wish to continue?
        </p>
      )}
      <BottomError message={error} />
      <SubmitCancel
        submitText='Cancel payment'
        cancelText='Go back'
        submitting={submitting}
        cancelClosesModal
      />
    </form>
  </Modal>
)

const StopModalReduxForm = reduxForm<any, StopModalProps>({
  form: 'paymentsFutureStop',
})(StopModal)

export default StopModalReduxForm
