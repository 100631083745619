import { isTrue } from '../../utils/common'

// eslint-disable-next-line no-underscore-dangle
const isProd = isTrue(process.env.__PROD__)

const mode = process.env.__REQUEST_CONFIG_MODE__
const credentials = process.env.__REQUEST_CONFIG_CREDENTIALS__

// eslint-disable-next-line import/prefer-default-export
export class MakeRequest {
  // eslint-disable-next-line class-methods-use-this
  async makeRequest(
    url: RequestInfo,
    nonce?: string,
    data?: any,
    method?: string,
    selectedEntity?: string | number
  ) {
    // todo - any
    const config: any = {
      headers: {
        'Content-Type': 'application/json',
        'x-nonce': nonce,
        'X-Party-Id': selectedEntity || null,
      },
      method,
      mode,
      credentials,
    }
    if (Object.keys(data).length > 0) {
      config.body = JSON.stringify(data)
    }
    const response = await fetch(url, config)
    return response
  }

  async get(url: RequestInfo, nonce?: string, selectedEntity?: string | number) {
    return this.makeRequest(url, nonce, {}, 'GET', selectedEntity)
  }

  async post(url: RequestInfo, data, nonce?: string, selectedEntity?: string | number) {
    return this.makeRequest(url, nonce, data, 'POST', selectedEntity)
  }

  async put(url: RequestInfo, data, nonce?: string, selectedEntity?: string | number) {
    return this.makeRequest(url, nonce, data, 'PUT', selectedEntity)
  }

  async delete(url: RequestInfo, data, nonce?: string, selectedEntity?: string | number) {
    return this.makeRequest(url, nonce, data, 'DELETE', selectedEntity)
  }
}
