import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import momentPropTypes from 'react-moment-proptypes'
import s from './styles.css'
import SubmitCancel from '../../Forms/SubmitCancel'
import Shortcuts from '../Shortcuts'

interface LargeProps {
  startDateText: string
  endDateText: string
  onChange: () => void
  onInputClick: (v: string) => void
  onInputBlur: (v: string) => void
  onInputChange: (v1: string, v2: string) => void
  onInputKeypress: (v1: string, v2: any) => void
  onShortcutClick: () => void
  selecting?: 'start' | 'end'
  startDate: moment.Moment
  endDate: moment.Moment
  maxDate: moment.Moment
  onSubmit: () => void
  onCancelClick: () => void
  shortcuts?: any[]
}
class Large extends Component<LargeProps> {
  constructor(props) {
    super(props)
    this.onStartFocus = this.onStartFocus.bind(this)
    this.onStartBlur = this.onStartBlur.bind(this)
    this.onStartChange = this.onStartChange.bind(this)
    this.onStartKeyPress = this.onStartKeyPress.bind(this)
  }

  onStartFocus() {
    this.props.onInputClick('start')
  }

  onStartBlur() {
    this.props.onInputBlur('start')
  }

  onStartChange(e) {
    this.props.onInputChange('start', e.target.value)
  }

  onStartKeyPress(e) {
    this.props.onInputKeypress('start', e)
  }

  onEndFocus() {
    this.props.onInputClick('end')
  }

  onEndBlur() {
    this.props.onInputBlur('end')
  }

  onEndChange(e) {
    this.props.onInputChange('end', e.target.value)
  }

  onEndKeyPress(e) {
    this.props.onInputKeypress('end', e)
  }

  render() {
    const {
      onChange,
      onSubmit,
      selecting,
      startDate,
      endDate,
      startDateText,
      endDateText,
      maxDate,
      shortcuts,
      onShortcutClick,
      onCancelClick,
    } = this.props

    let startClass
    let endClass
    let selectsStart
    let selectsEnd

    if (selecting === 'start') {
      startClass = s.inputActive
      selectsStart = true
    } else {
      endClass = s.inputActive
      selectsEnd = true
    }

    return (
      <form className={s.root} onSubmit={onSubmit}>
        <div className={s.calendars}>
          <DatePicker
            inline
            monthsShown={2}
            selectsStart={selectsStart}
            selectsEnd={selectsEnd}
            startDate={startDate}
            endDate={endDate}
            openToDate={startDate}
            maxDate={maxDate}
            onChange={onChange}
          />
        </div>
        <div className={s.controls}>
          <div className={s.inputs}>
            <div className={s.input}>
              <label htmlFor='dateStart'>Start date</label>
              <input
                id='dateStart'
                type='text'
                onFocus={this.onStartFocus}
                onBlur={this.onStartBlur}
                onChange={this.onStartChange}
                onKeyPress={this.onStartKeyPress}
                value={startDateText}
                className={`${startClass} ${s.input}`}
              />
            </div>
            <div className={s.input}>
              <label htmlFor='dateEnd'>End Date</label>
              <input
                id='dateEnd'
                type='text'
                onFocus={this.onEndFocus}
                onBlur={this.onEndBlur}
                onChange={this.onEndChange}
                onKeyPress={this.onEndKeyPress}
                value={endDateText}
                className={`${endClass} ${s.input}`}
              />
            </div>
          </div>

          <Shortcuts items={shortcuts} onShortcutClick={onShortcutClick} />

          <div className={s.actions}>
            <SubmitCancel submitText='Apply Dates' onCancelClick={onCancelClick} />
          </div>
        </div>
      </form>
    )
  }
}

export default Large
