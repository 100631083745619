// Permitted functions a user has
export default (state) => {
  const permitted = state.user.permittedFunctions
  return {
    payment: permitted.includes('payment'),
    reqIntPayment: permitted.includes('payment'),
    accServ: permitted.includes('chequeBooks') || permitted.includes('payInBooks'),
    transfer: permitted.includes('transfer'),
    paymentsFuture: permitted.includes('paymentsFuture'),
    transactionLog: permitted.includes('transactionLog'),
    bulkView: permitted.includes('bulkView'),
    bulkUpload: permitted.includes('bulkUpload'),
    payees: permitted.includes('payeesManage'),
    statements: permitted.includes('statements'),
    SIandDD: permitted.includes('paymentsFuture'),
    chequeStatus: permitted.includes('chequeStatus'),
  }
}
