import React from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import moment from 'moment'
import Button from '../Button'
import SingleField from '../Forms/SingleField'
import BottomError from '../Forms/BottomError'
import * as v from '../../utils/forms/validators'
import Select from '../Forms/Select'
import CurrencyAndAmount from '../Forms/CurrencyAndAmount'
import DatePicker from '../../containers/DatePicker'
import FieldsetLegend from '../Forms/FieldsetLegend'
import InputWithTooltip from '../Forms/InputWithTooltip'
import s from './styles.css'

type RequestInternationalPaymentFormProps = {
  codeType?: string // todofixed - was object
  fullFormIsShowing?: boolean
  handleSubmit: () => void
  fromOptions: any[]
  currencyOptions: any[]
  countryOptions: any[]
  send: () => void
  fromAvailableBalance?: string
  error?: string
  invalid?: boolean
  submitting?: boolean
  abaIsShowing?: boolean
  ibanIsShowing?: boolean
  accountNumberIsShowing?: boolean
  addressValidation?: () => void
  currencyNote?: string
  minDate: moment.Moment // todofixed - was object.
  showPaymentOption?: boolean
  showPaymentCharges?: boolean
  paymentChargeOptions?: any[]
}

const RequestInternationalPaymentForm = ({
  handleSubmit,
  error,
  submitting,
  send,
  invalid,
  fromOptions,
  currencyOptions,
  currencyNote,
  countryOptions,
  fullFormIsShowing,
  minDate,
  codeType,
  abaIsShowing,
  ibanIsShowing,
  accountNumberIsShowing,
  addressValidation,
  fromAvailableBalance,
  showPaymentOption,
  showPaymentCharges,
  paymentChargeOptions,
}: InjectedFormProps<any> & RequestInternationalPaymentFormProps) => (
  <form onSubmit={handleSubmit(send)}>
    <p>
      This will generate a secure message to Weatherbys Bank. If your payment is urgent, please call
      +44 (0)1933 543543.
    </p>
    <fieldset className='narrow'>
      <legend>From</legend>
      <Field
        name='fromAccount'
        label='From *'
        component={SingleField}
        layout='inline'
        InputComponent={Select}
        inputComponentProps={{
          options: fromOptions,
        }}
        validate={v.required}
        validationMessages={{
          required: 'Which account do you want to pay from?',
        }}
      />
      {fromAvailableBalance && fromOptions.length > 0 && (
        <p>
          <em>Available balance: {fromAvailableBalance}</em>
        </p>
      )}
    </fieldset>
    {fromOptions.length > 0 ? (
      <div>
        <fieldset className='narrow'>
          <legend>Where do you want to send money?</legend>
          <Field
            name='country'
            label='Country *'
            component={SingleField}
            layout='inline'
            InputComponent={Select}
            inputComponentProps={{
              options: countryOptions,
            }}
            validate={v.required}
            validationMessages={{
              required: 'Which account are you transfering from?',
            }}
          />
        </fieldset>
        {fullFormIsShowing && (
          <div>
            <fieldset className='narrow'>
              <legend>Payee Details</legend>

              <Field
                name='name'
                label='Full name *'
                component={SingleField}
                props={{ autoFocus: true }}
                layout='inline'
                type='text'
                validate={v.required}
                validationMessages={{
                  required: 'Who are you sending the money to?',
                }}
              />

              <Field
                name='address'
                label='Address *'
                component={SingleField}
                layout='inline'
                type='textarea'
                validate={addressValidation}
                validationMessages={{
                  required: 'Please give the full address of the payee.',
                }}
              />

              {accountNumberIsShowing && (
                <Field
                  name='accountNumber'
                  label='Account Number *'
                  validate={v.required}
                  component={SingleField}
                  layout='inline'
                  type='text'
                  validationMessages={{
                    required: 'What is the account number?',
                  }}
                />
              )}

              {abaIsShowing && (
                <InputWithTooltip
                  tooltip={{
                    id: 'abaTip',
                    text: `An ABA (American Bankers Association) number identifies banks in the United States.
                A BSB (Bank State Branch) is a Bank branch identifier used in Australia, Canada and South Africa.`,
                  }}
                  field={{
                    name: 'aba',
                    label: 'ABA/BSB/Routing number',
                    component: SingleField,
                    layout: 'inline',
                    type: 'text',
                    validationMessages: {
                      required: 'What is the ABA number?',
                    },
                  }}
                />
              )}

              {ibanIsShowing && (
                <InputWithTooltip
                  tooltip={{
                    id: 'ibanTip',
                    text: `IBAN stands for International Bank Account Number and helps overseas banks
                  to process payments to the correct beneficiary accounts. Each country that requires an IBAN have
                  their own standardized format so the length of them can vary, but they will always start with
                  their country code.`,
                  }}
                  field={{
                    name: 'iban',
                    label: 'IBAN *',
                    validate: v.required,
                    component: SingleField,
                    layout: 'inline',
                    type: 'text',
                    validationMessages: {
                      required: 'What is the IBAN number?',
                    },
                  }}
                />
              )}

              <InputWithTooltip
                tooltip={{
                  id: 'bicTip',
                  text: `A Swift or BIC (Bank Identifier Code) is a code that helps overseas
              banks route payments to the correct beneficiary bank. This should be 11 characters in length.
              If you have 8 characters, please add ‘XXX’ to the end.`,
                }}
                field={{
                  name: 'swift',
                  label: 'BIC/SWIFT code *',
                  validate: v.required,
                  component: SingleField,
                  layout: 'inline',
                  type: 'text',
                  validationMessages: {
                    required: 'What is the BIC/SWIFT code?',
                  },
                }}
              />

              <Field
                name='reference'
                label='Reference'
                component={SingleField}
                layout='inline'
                type='text'
              />
            </fieldset>
            <fieldset className='narrow'>
              <FieldsetLegend title='Payment'>
                <p>
                  Any International instructions sent outside business hours will be carried out the
                  next business day.
                </p>
                <p>
                  Subject to that the International instructions will be carried out in the same way
                  as at present being received by Fax, Email or phone.
                </p>
              </FieldsetLegend>

              {/* todo - required was changed */}
              <CurrencyAndAmount currencyOptions={currencyOptions} showRequired />

              {currencyNote && <p>{currencyNote}</p>}

              <Field
                label='When'
                name='date'
                component={SingleField}
                InputComponent={DatePicker}
                inputComponentProps={{
                  minDate,
                }}
                layout='inline'
              />

              {showPaymentOption && (
                <Field
                  label='Payment Option *'
                  name='paymentOption'
                  component={SingleField}
                  layout='inline'
                  InputComponent={Select}
                  inputComponentProps={{
                    options: [
                      {
                        label: 'Next Working Day/Standard (£10)',
                        value: 'nextDay',
                      },
                      { label: 'Urgent (£30)', value: 'urgent' },
                    ],
                  }}
                  validate={showPaymentOption && v.required}
                  validationMessages={{
                    required: 'Which payment option?',
                  }}
                />
              )}

              {showPaymentCharges && (
                <InputWithTooltip
                  tooltip={{
                    id: 'chargeTip',
                    text: `By selecting ‘All charges’, the beneficiary should receive the
                full amount of the transfer. If ‘Weatherbys Charges’ is selected the amount
                credited to the beneficiary will have correspondent and/or any beneficiary bank charges deducted.`,
                  }}
                  field={{
                    label: 'Payment charges *',
                    name: 'paymentCharges',
                    component: SingleField,
                    layout: 'inline',
                    InputComponent: Select,
                    inputComponentProps: {
                      options: paymentChargeOptions,
                    },
                    validate: showPaymentCharges && v.required,
                    validationMessages: {
                      required: 'Which payment charges?',
                    },
                  }}
                />
              )}

              <Field
                name='comments'
                label='Other comments'
                component={SingleField}
                placeholder={`Please provide any additional information to
help us process your payment request e.g. correspondent bank details`}
                layout='inline'
                type='textarea'
              />
              <p>
                This will generate a secure message to Weatherbys Bank. It may be necessary for us
                to call and verify details of your request.
              </p>
              <BottomError message={error} />
              <Button submit text='Send' loading={submitting} />
            </fieldset>
          </div>
        )}
      </div>
    ) : (
      <p className={s.error}>
        The current entity does not have any accounts that can make international payments.
      </p>
    )}
  </form>
)

const RequestInternationalPaymentReduxForm = reduxForm<any, RequestInternationalPaymentFormProps>({
  form: 'request-international-payment',
  enableReinitialize: true,
})(RequestInternationalPaymentForm)

export default RequestInternationalPaymentReduxForm
