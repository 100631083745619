import { createFormAction } from 'redux-form-saga'
import * as c from '../../constants'

export const submitOrder = createFormAction('ACCOUNT_SERVICES_ORDER')

export function accountServicesInit() {
  return {
    type: c.ACCOUNT_SERVICES_INIT,
  }
}

export function accountServicesSelectAccount(id: string, type: string) {
  return {
    type: c.ACCOUNT_SERVICES_SELECT_ACCOUNT,
    payload: {
      id,
      type,
    },
  }
}
