import React, { ReactNode } from 'react'
import history from '../../history'

interface LinkReplaceProps {
  className?: string
  children?: ReactNode
  replace?: string
}

class LinkReplace extends React.Component<LinkReplaceProps> {
  constructor(props: LinkReplaceProps | Readonly<LinkReplaceProps>) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(e) {
    e.preventDefault()
    history.replace(this.props.replace)
  }

  render() {
    return (
      <a href={this.props.replace} className={this.props.className} onClick={this.handleClick}>
        {this.props.children}
      </a>
    )
  }
}

export default LinkReplace
