export default (store) => ({
  path: 'enquiry',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const ServicesEnquire = require('../components/ServicesEnquire').default
        cb(null, ServicesEnquire)
      },
      'mail'
    )
  },
})
