import { createFormAction } from 'redux-form-saga'
import * as c from '../../constants'

export const paymentsRecurringStopDD = createFormAction('PAYMENTS_RECURRING_STOP_DD')
export const paymentsRecurringStopSI = createFormAction('PAYMENTS_RECURRING_STOP_SI')

export function paymentsRecurringInit(id: string | number) {
  return {
    type: c.PAYMENTS_RECURRING_INIT,
    payload: {
      id,
    },
  }
}
