import { combineReducers } from 'redux'
import createListReducer, { ListState } from '../../reducerCreators/listReducer'

export interface IPaymentBulkState {
  homeList: ListState
  fullList: ListState
  item: ListState
  validation: ListState
}

export default combineReducers<IPaymentBulkState, any>({
  homeList: createListReducer('paymentBulkHomeList'),
  fullList: createListReducer('paymentBulkFullList'),
  item: createListReducer('paymentBulkFileItem'),
  validation: createListReducer('paymentBulkValidation'),
})
