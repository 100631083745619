import { connect } from 'react-redux'
import { initialize, reset } from 'redux-form'
import TransactionsFiltersForm from '../../components/TransactionsFiltersForm'
import { transactionsFilterAmountMinMax, transactionsFilterTags } from '../Transactions/actions'
import { panelHide } from '../../store/ui'
import { isRacingAccount } from '../Transactions/selectors'

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: (e) => {
    dispatch(panelHide())
    dispatch(transactionsFilterAmountMinMax(e.minAmount, e.maxAmount))
    dispatch(
      transactionsFilterTags({
        cardTransactions: e.cardTransactions,
        cheques: e.cheques,
        payments: e.payments,
        reccurentPayments: e.reccurentPayments,
        internalTransfers: e.internalTransfers,
        international: e.international,
        loanRepayments: e.loanRepayments,
        interestAndCharges: e.interestAndCharges,
        cash: e.cash,
        vat: e.vat,
        incomingTransfers: e.incomingTransfers,
      })
    )
    dispatch(initialize('transactionsFilters', e))
  },
  onCancelClick: (e) => {
    e.preventDefault()
    dispatch(panelHide())
    dispatch(reset('transactionsFilters'))
  },
})

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    all: true,
  },
  isRacingAccount: isRacingAccount(state, ownProps.id),
})

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsFiltersForm)
