import * as React from 'react'

function SvgCheck(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      width='1em'
      height='1em'
      {...props}
    >
      <path d='M449.8 165.5c0 6.7-2.3 12.3-7 17l-215 215c-4.7 4.7-10.3 7-17 7s-12.3-2.3-17-7L69.2 273c-4.7-4.7-7-10.3-7-17 0-6.7 2.3-12.3 7-17l34-34c4.7-4.7 10.3-7 17-7s12.3 2.3 17 7l73.5 73.8 164-164.2c4.7-4.7 10.3-7 17-7s12.3 2.3 17 7l34 34c4.7 4.6 7.1 10.2 7.1 16.9z' />
    </svg>
  )
}

export default SvgCheck
