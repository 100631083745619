import { call, put, takeEvery } from 'redux-saga/effects'
import result from 'lodash/result'
import request from '../../utils/request'
import goOut from '../../utils/goOut'
import * as c from '../../constants'
import { logout } from './actions'
import { formError } from '../../sagas/formErrors/actions'

export function* handleLogout(action) {
  try {
    const nonce = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    yield call(request, {
      id: c.requestLogout,
      url: '/v1/session?locale=en',
      nonce,
    })
    const url = result(action, 'payload.url') || 'https://www.weatherbys.bank/logoff'
    yield call(goOut, url)
  } catch (error) {
    yield put(formError(logout.FAILURE, error))
  }
}

export function* watchLogout() {
  yield takeEvery(logout.REQUEST, handleLogout)
}
