import { submit } from 'redux-form'
import { createFormAction } from 'redux-form-saga'
import * as c from '../../constants'
import { IpaymentMakeNewPayeeDetailsUpdate } from './interface'

export const paymentMakeVerify = createFormAction('PAYMENTMAKE_VERIFY')
export const paymentMakeSubmit = createFormAction('PAYMENTMAKE_SUBMIT')
export const paymentMakeVerifySubmit = submit('paymentMakeVerify')

export function uPaymentMakeFromUpdate(id: string | number) {
  return {
    type: c.U_PAYMENTMAKE_FROM_UPDATE,
    payload: {
      id,
    },
  }
}

export function rPaymentMakeFromUpdate(id: string | number) {
  return {
    type: c.R_PAYMENTMAKE_FROM_UPDATE,
    payload: {
      id,
    },
  }
}

export function paymentMakeToUpdate(id: string | number | boolean) {
  return {
    type: c.PAYMENTMAKE_TO_UPDATE,
    payload: {
      id,
    },
  }
}

export function paymentMakeInit(
  fromAccountId: string | number,
  toAccountId: string | number,
  isNewPayee: string | boolean
) {
  return {
    type: c.PAYMENTMAKE_INIT,
    payload: {
      fromAccountId,
      toAccountId,
      isNewPayee,
    },
  }
}

export function paymentMakeDetailsUpdate(details) {
  return {
    type: c.PAYMENTMAKE_DETAILS_UPDATE,
    payload: {
      details,
    },
  }
}

export function paymentMakeNewPayeeDetailsUpdate(details: IpaymentMakeNewPayeeDetailsUpdate) {
  return {
    type: c.PAYMENTMAKE_NEWPAYEE_DETAILS_UPDATE,
    payload: {
      details,
    },
  }
}

export function paymentMakeNewPayeeDetailsClear() {
  return {
    type: c.PAYMENTMAKE_NEWPAYEE_DETAILS_CLEAR,
  }
}

// todo-ts: removed param id  - as not used
export function paymentMakeReset() {
  return {
    type: c.PAYMENTMAKE_RESET,
  }
}

// todo-ts: removed param id  - as not used
export function paymentMakeIgnoreNextReset() {
  return {
    type: c.PAYMENTMAKE_IGNORE_NEXT_RESET,
  }
}

export function paymentMakeNewPayeeShow() {
  return {
    type: c.PAYMENTMAKE_NEWPAYEE_SHOW,
  }
}

export function paymentMakeNewPayeeHide() {
  return {
    type: c.PAYMENTMAKE_NEWPAYEE_HIDE,
  }
}

export function paymentMakeResetSuccess() {
  return {
    type: c.PAYMENTMAKE_RESET_SUCCESS,
  }
}

export function showPaymentCutoffModal() {
  return {
    type: c.SHOW_PAYMENT_CUTOFF_MODAL,
  }
}
export function hidePaymenCutoffModal() {
  return {
    type: c.HIDE_PAYMENT_CUTOFF_MODAL,
  }
}
