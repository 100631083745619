import React from 'react'

const SvgCOPLargeInformation = (props) => (
  <svg
    width='90'
    height='90'
    viewBox='0 0 90 90'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45 90C69.84 90 90 69.84 90 45C90 20.16 69.84 -1.76244e-06 45 -3.93402e-06C20.16 -6.10561e-06 6.10561e-06 20.16 3.93402e-06 45C1.76244e-06 69.84 20.16 90 45 90ZM49.5 22.5L49.5 31.5L40.5 31.5L40.5 22.5L49.5 22.5ZM49.5 67.5L49.5 40.5L40.5 40.5L40.5 67.5L49.5 67.5Z'
      fill='white'
      fillOpacity='0.5'
    />
  </svg>
)

export default SvgCOPLargeInformation
