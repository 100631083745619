import { connect } from 'react-redux'
import includes from 'lodash/includes'
import mapValues from 'lodash/mapValues'
import PaymentsView from '../../components/PaymentsView'

const mapStateToProps = (state) => ({
  mappingError: state.accounts.knownMappingsErrors.payment,
  availableItems: includes(mapValues(state.accounts.items, 'canMakeBulkPayment'), true),
})

export default connect(mapStateToProps)(PaymentsView)
