import moment from 'moment'
import formatMoney from '../formatMoney'
import deduceFrequency from '../deduceFrequency'

/**
 * Tidies deeply nested and poorly named data from API into something
 * easier to deal with!
 * @param  {Object} data - raw data from API
 * @return {Object} data - has SI and DD objects underneat
 */
// TODO LATER
export default (data, state) => {
  const obj = data.data.instructionsList
  const tidyData = {}
  // todo-ts: was map, coverted to forEach as nothing is returned
  obj.forEach((item) => {
    const { realToMasked } = state.accounts

    let extraDetails = {}
    let date = item.nextExecutionDate
    let timeStamp

    const id = String(item.externalReferenceNumber)
    const statusCode = item.statusType && item.statusType.toLowerCase() === 'active' ? 'A' : 'C'

    if (date && date !== '') {
      date = moment(item.nextExecutionDate, 'YYYY-MM-DD').format('DD/MM/YY')
      timeStamp = moment(date, 'DD/MM/YY').format('X')
    } else {
      date = undefined
      timeStamp = undefined
    }

    extraDetails = {
      nextPaymentDate: date,
      productCode: item.prodCode,
      toAccountNumber: item.creditAccountId.displayValue,
      currency: item.amount.currency,
      frequency: deduceFrequency(item.freqDays, item.freqMonths, item.freqYears),
      endDate: item.enddate,
    }

    tidyData[id] = {
      id,
      statusCode,
      fccRef: item.externalReferenceNumber,
      type: item.prodCode,
      customerId: item.partyId.value,
      fromId: realToMasked[item.debitAccountId.displayValue],
      numInstallments: item.instances,
      recipientName: item.payeeNickName,
      reference: item.remarks,
      partyId: item.partyId,
      amount: formatMoney(item.amount.amount, item.amount.currency),
      timeStamp,
      ...extraDetails,
    }
  })
  return {
    SI: tidyData,
  }
}
