import React from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import SingleField from '../Forms/SingleField'
import Select from '../Forms/Select'
import s from './styles.css'

type FormProps = {
  accountOptions?: any[]
  onAccountChange?: () => void
  statusOptions?: any[]
}

type FieldProps = {
  account: string
  status: string
  search: string
  from: string
  to: string
}

const Form = ({
  accountOptions,
  onAccountChange,
  statusOptions,
}: InjectedFormProps<FieldProps> & FormProps) => (
  <form className={s.form} onSubmit={(e) => e.preventDefault()}>
    <div className={`${s.col} ${s.col1}`}>
      <Field
        name='account'
        label='Account'
        component={SingleField}
        InputComponent={Select}
        inputComponentProps={{
          options: accountOptions,
        }}
        onChange={onAccountChange}
      />
    </div>
    <div className={`${s.col} ${s.col2}`}>
      <Field
        name='status'
        label='Status'
        component={SingleField}
        InputComponent={Select}
        inputComponentProps={{
          options: statusOptions,
        }}
      />
    </div>
    <div className={`${s.col} ${s.col3}`}>
      <Field
        name='search'
        label='Search'
        placeholder='Cheque number'
        component={SingleField}
        type='text'
      />
    </div>
    <div className={s.rangeCol}>
      <label className={s.rangeLabel}>Cheque number range</label>
      <Field name='from' placeholder='000001' component={SingleField} type='text' />
      <span className={s.rangeTo}>to</span>
      <Field name='to' placeholder='00030' component={SingleField} type='text' />
    </div>
  </form>
)

const FormReduxForm = reduxForm<FieldProps, FormProps>({
  form: 'chequeStatusFilters',
  enableReinitialize: true,
})(Form)

export default FormReduxForm
