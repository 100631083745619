/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import s from './styles.css'

interface AvailableBalanceInfoModalProps {
  showAvailableBalanceInfoModal: boolean
  showAvailableBalanceInfoModalFunction: () => void
  hideAvailableBalanceInfoModalFunction: () => void
  availableBalance: string
}

class AvailableBalanceInfoModal extends React.Component<AvailableBalanceInfoModalProps> {
  componentDidMount() {
    this.props.hideAvailableBalanceInfoModalFunction()
  }

  render() {
    const {
      showAvailableBalanceInfoModal,
      showAvailableBalanceInfoModalFunction,
      hideAvailableBalanceInfoModalFunction,
      availableBalance,
    } = this.props
    return (
      <div>
        <span className={s.abavBalanceDiv}>
          <span className={s.abinfoModal} onClick={showAvailableBalanceInfoModalFunction}>
            &#105;
          </span>
          <a className={s.abavailableBalanceHeading}>{availableBalance} Available</a>
        </span>
        {showAvailableBalanceInfoModal && (
          <div className={s.abmodal}>
            <div className={s['abmodal-content']}>
              <div className={s.abgoBack} onClick={hideAvailableBalanceInfoModalFunction}>
                <i className={`${s.abiconClass} ${s.ableft}`} />
                Back
              </div>
              <div className={s.abheader}>
                What is my ‘Available Balance’?{' '}
                <span className={s.abclose} onClick={hideAvailableBalanceInfoModalFunction}>
                  &times;
                </span>
              </div>
              <div className={s.abmainContent}>
                <p className={s.abheading}>Summary</p>

                <p className={s.abcontent}>
                  Your ‘available’ balance is the amount you have available to spend i.e. cleared
                  funds. It comprises the funds you have in your account, plus the amount of any
                  approved overdraft facility and adjusted by any uncleared cheques and pending
                  debit card transactions that you have recently made, and which are due to be
                  processed to your account over the coming days.
                </p>
                <hr className={s.abHr} />
                <p className={s.abheading}>Account Balance</p>
                <p className={s.abcontent}>
                  The total amount of money in your account after all authorised transactions have
                  been processed.
                </p>

                <p className={s.abheading}>Your Overdraft</p>
                <p className={s.abcontent}>The agreed amount of money you can borrow.</p>
                <p className={s.abheading}>Pending Transactions</p>
                <p className={s.abcontent}>
                  These are transactions that we have been notified of but which have not yet been
                  debited or credited to your account.
                  <br />
                  Pending transactions such as debit card payments and refunds are taken into
                  account when we calculate your ‘available’ balance. BACS credits and Direct Debits
                  are shown for information only.
                  <br />
                  Pending transactions are not included in your account balance until they have been
                  debited or credited to your account.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default AvailableBalanceInfoModal
