import get from 'lodash/get'
import toArray from 'lodash/toArray'

/**
 * Tidies deeply nested and poorly named data from API into something
 * easier to deal with!
 * @param  {Object} data - raw data from API
 * @return {Object} data - tidied data
 */
export interface TidyPendingApprovalsD {
  data: {
    transactionDTOs: {
      taskDTO: {
        id: string
        name: string
      }
      approvalDetails: {
        status: string
      }
      transactionSnapshot: string
    }[]
  }
}
export default (data: TidyPendingApprovalsD) => {
  const tidyData = {}

  let items = get(data, 'data.transactionDTOs')

  if (!items) return { items: {} }
  if (!(items instanceof Array)) {
    items = toArray(items)
  }
  items.forEach((item) => {
    const type = item.taskDTO.id
    const statusCode = item.approvalDetails.status
    const count = items.filter((filteredItem) => filteredItem.taskDTO.id === item.taskDTO.id).length
    tidyData[`${type}-${statusCode}`] = {
      id: `${type}-${statusCode}`,
      type,
      count,
      statusCode,
      title: item.taskDTO.name,
      status: item.approvalDetails.status,
      snapshot: item.transactionSnapshot,
    }
  })
  return {
    items: tidyData,
  }
}
