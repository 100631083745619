import { connect } from 'react-redux'
import TopDetails from '../../components/PaymentsBulkItemView/TopDetails'
import { onDownloadClick } from '../PaymentsBulk/selectors'

const mapStateToProps = (state) => ({
  onDownloadClick: () => onDownloadClick(state),
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(TopDetails)
