import React from 'react'
import s from './styles.css'
import PayeeTable from '../../containers/PayeeTable'
import InnerContainer from '../InnerContainer'
import ScrollTo from '../../containers/ScrollTo'
import ActionHeader from '../ActionHeader'

type PaymentMakeFormProps = {
  onPayeeClick: () => void
  onNewPayeeClick: () => void
}

const PaymentMakeForm = ({ onPayeeClick, onNewPayeeClick }: PaymentMakeFormProps) => (
  <div className={s.root}>
    <InnerContainer>
      <ScrollTo>
        <ActionHeader
          title='Who do you want to pay?'
          actionText='New payment or transfer'
          headerSize='2'
          onClick={onNewPayeeClick}
        />
      </ScrollTo>
    </InnerContainer>
    <PayeeTable onRowClick={onPayeeClick} entitySelectIsVisible />
  </div>
)

export default PaymentMakeForm
