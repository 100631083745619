import { dontDuplicate } from '../utils/routerBugWorkaround'

export default (store) => ({
  path: 'file/:id',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const PaymentsBulkItem = require('../containers/PaymentsBulkItem').default
        const { paymentsBulkItemInit } = require('../containers/PaymentsBulk/actions')

        const { id } = nextState.params

        dontDuplicate(() => {
          store.dispatch(paymentsBulkItemInit(id))
        })

        cb(null, PaymentsBulkItem)
      },
      'payments-bulk-item'
    )
  },
})
