import * as React from 'react'

function COPSuccessIcon(props) {
  return (
    <svg
      className={props.className}
      width='38'
      height='39'
      viewBox='0 0 38 39'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Tick icon'>
        <path
          id='Oval 3 (Stroke)'
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M19 2.875C9.81827 2.875 2.375 10.3183 2.375 19.5C2.375 28.6817 9.81827 36.125 19 36.125C28.1817 36.125 35.625 28.6817 35.625 19.5C35.625 10.3183 28.1817 2.875 19 2.875ZM0 19.5C0 9.00659 8.50659 0.5 19 0.5C29.4934 0.5 38 9.00659 38 19.5C38 29.9934 29.4934 38.5 19 38.5C8.50659 38.5 0 29.9934 0 19.5Z'
          fill='white'
        />
        <path
          id='Shape'
          d='M16.2275 23.3905L12.0957 19.1988L10.6875 20.6086L16.2275 26.2287L28.1042 14.1802L26.7145 12.7704L16.2275 23.3905Z'
          fill='white'
        />
      </g>
    </svg>
  )
}

export default COPSuccessIcon
