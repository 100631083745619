import React, { ReactNode } from 'react'
import s from './styles.css'
import Tab from './Tab'

type TabsProps = {
  children?: ReactNode
  items?: {
    to: string
    title: string
  }[]
}

export const Tabs = ({ children, items }: TabsProps) => (
  <ul className={s.tabs}>
    {children ||
      items.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Tab key={index} text={item.title} to={item.to} />
      ))}
  </ul>
)

export default Tabs
