export default (store) => ({
  path: 'manage-accounts',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const ManageAccounts = require('../containers/ManageAccounts').default

        cb(null, ManageAccounts)
      },
      'manage-accounts'
    )
  },
})
