// eslint-disable-next-line import/prefer-default-export
export const getBulletins = (state) => {
  const { items } = state.mail.bulletins
  let loading = false

  const bulls = Object.keys(items).map((key) => {
    const { body } = items[key]
    if (!body) loading = true
    return {
      id: key,
      body,
      subject: items[key].subject,
    }
  })

  if (loading) {
    return undefined
  }
  return bulls
}
