import { createResponsiveStateReducer } from 'redux-responsive'

export interface IResponsiveState {
  small: number
  medium: number
}

export default createResponsiveStateReducer({
  small: 599,
  medium: 959,
})
