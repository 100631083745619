export default (store) => ({
  path: 'styleguide',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const Styleguide = require('../containers/Styleguide').default
        cb(null, Styleguide)
      },
      'styleguide'
    )
  },
})
