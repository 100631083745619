import * as React from 'react'

function SvgMenu(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 512 512'
      {...props}
    >
      <path d='M32 96h448v96H32zm0 128h448v96H32zm0 128h448v96H32z' />
    </svg>
  )
}

export default SvgMenu
