/* eslint-disable */
// @ts-nocheck
// todo - eslint and typescript - many conflicts in the file below
import React, { Component } from 'react'
import moment from 'moment'
import Modal from '../../containers/Modal'
import ModalLink from '../../containers/ModalLink'
import IconButton from '../IconButton'
import DetailList from '../DetailList'
import TallButton from './TallButton'
import quickbooksImage from '../../assets/img/quickbooks.png'
import xeroImage from '../../assets/svg/xero.svg'
import csvImage from '../../assets/svg/CSV.svg'
import sageImage from '../../assets/svg/sage.svg'
import pdfImage from '../../assets/svg/PDF.svg'
import s from './styles.css'
import Tooltip from '../../containers/Tooltip'

type DocOptionsProps = {
  onDownloadClick?: (v1: string, v2: string, v3: string) => void
  startDate: moment.Moment
  endDate: moment.Moment
  isFiltered?: boolean
  onPrintClick: () => void
  showToolTip?: boolean
  title?: string // todo - was passed by Transactions
}
class DocOptions extends Component<DocOptionsProps> {
  startDateShow: any

  endDateShow: any

  constructor(props: DocOptionsProps | Readonly<DocOptionsProps>) {
    super(props)
    this.downloadCSV = this.downloadCSV.bind(this)
    this.downloadPDF = this.downloadPDF.bind(this)
    this.downloadQuickbooks = this.downloadQuickbooks.bind(this)
    this.downloadXero = this.downloadXero.bind(this)
    this.downloadSage = this.downloadSage.bind(this)
    this.startDateShow = null
    this.endDateShow = null
  }

  downloadCSV() {
    this.props.onDownloadClick('csv', this.startDate, this.endDate)
  }

  startDate(arg0: string, startDate: any, endDate: any) {
    throw new Error('Method not implemented.')
  }

  endDate(arg0: string, startDate: any, endDate: any) {
    throw new Error('Method not implemented.')
  }

  downloadPDF() {
    this.props.onDownloadClick('pdf', this.startDate, this.endDate)
  }

  downloadQuickbooks() {
    this.props.onDownloadClick('quickbooks', this.startDate, this.endDate)
  }

  downloadXero() {
    this.props.onDownloadClick('xero', this.startDate, this.endDate)
  }

  downloadSage() {
    this.props.onDownloadClick('sage', this.startDate, this.endDate)
  }

  render() {
    const { startDate, endDate, isFiltered, onPrintClick, onDownloadClick, showToolTip } =
      this.props
    const format = 'Do MMMM YYYY'
    this.startDate = moment(startDate).format('DD/MM/YYYY')
    this.endDate = moment(endDate).format('DD/MM/YYYY')

    const detailsEl = (
      <div>
        <div className={s.list}>
          <DetailList
            items={[
              {
                title: 'From',
                value: moment(this.startDate, 'DD/MM/YYYY').format(format),
              },
              {
                title: 'To',
                value: moment(this.endDate, 'DD/MM/YYYY').format(format),
              },
            ]}
          />
        </div>
        {isFiltered && (
          <p>
            <em>Filters are applied.</em>
          </p>
        )}
        <p>To print or download a different period, please filter the transactions table first.</p>
      </div>
    )

    return (
      <div>
        <nav className={s.root}>
          <ul className={s.buttons}>
            {showToolTip ? (
              <Tooltip
                id='printbuttonTooltip'
                text="To print more than 6 months of transactions
              you must first scroll to the bottom of the transaction
              list and select 'SHOW MORE'. Once all transactions are visible
              you can proceed to print them."
                showOnHover
                showOnMobile
                rootClassName={s.foo}
              >
                {' '}
                <li>
                  <ModalLink className={s.button} id='print'>
                    <IconButton icon='print' text='Print' />
                  </ModalLink>
                </li>
              </Tooltip>
            ) : (
              <li>
                <ModalLink className={s.button} id='print'>
                  <IconButton icon='print' text='Print' />
                </ModalLink>
              </li>
            )}

            {onDownloadClick ? (
              showToolTip ? (
                <Tooltip
                  id='downloadbuttonTooltip'
                  text="To download more than 6 months of transactions
              you must first scroll to the bottom of the transaction
              list and select 'SHOW MORE'. Once all transactions are visible
              you can proceed to download them."
                  showOnHover
                  showOnMobile
                  rootClassName={s.foo}
                >
                  <li>
                    <ModalLink className={s.button} id='download'>
                      <IconButton icon='download' text='Download' />
                    </ModalLink>
                  </li>
                </Tooltip>
              ) : (
                <li>
                  <ModalLink className={s.button} id='download'>
                    <IconButton icon='download' text='Download' />
                  </ModalLink>
                </li>
              )
            ) : (
              ''
            )}
          </ul>
        </nav>
        <Modal
          id='print'
          size='medium'
          className={s.modal}
          title='Print Transactions'
          onActionClick={onPrintClick}
          actionText='Print'
        >
          {detailsEl}
        </Modal>

        <Modal id='download' size='large' className={s.modal} title='Download Transactions'>
          {detailsEl}
          <div className={s.downloadButtonWrap}>
            <TallButton
              text='CSV'
              mobileText='Download CSV'
              onClick={this.downloadCSV}
              image={csvImage}
            />
            <TallButton
              text='PDF'
              mobileText='Download PDF'
              onClick={this.downloadPDF}
              image={pdfImage}
            />
            <TallButton
              text='QuickBooks'
              mobileText='Download for QuickBooks'
              onClick={this.downloadQuickbooks}
              image={quickbooksImage}
            />
            <TallButton
              text='Sage'
              mobileText='Download for Sage'
              onClick={this.downloadSage}
              image={sageImage}
            />
            <TallButton
              text='Xero'
              mobileText='Download for Xero'
              onClick={this.downloadXero}
              image={xeroImage}
              imageClassName={s.xeroImage}
            />
          </div>
        </Modal>
      </div>
    )
  }
}

export default DocOptions
