import React from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import Button from '../Button'
import SingleField from '../Forms/SingleField'
import BottomError from '../Forms/BottomError'
import * as v from '../../utils/forms/validators'
import Select from '../Forms/Select'
import style from './styles.css'
import subjectNames from '../../utils/lookup/subjectNames'

const SubjectMessage = () => {
  const hashFromUrlSplit = window.location.hash.split('#')
  const hashFromUrl = hashFromUrlSplit[hashFromUrlSplit.length - 1]
  return (
    <div>
      <label>Subject</label>
      <p>I&apos;d like to find more about your {subjectNames[hashFromUrl]}</p>
    </div>
  )
}

type ServicesFormEnquireProps = {
  handleSubmit: () => void
  send: () => void
  error?: string
  invalid?: boolean
  submitting?: boolean
  entityOptions: any[]
  entitySelectIsVisible?: boolean
  customSubjectIsVisible?: boolean
  replyingTo?: string
}

type FieldProps = {
  customerId?: string
  subjectLabel?: string
  subjectCode?: string
  customSubject?: string
  body?: string
}

const ServicesFormEnquire = ({
  handleSubmit,
  error,
  submitting,
  send,
  invalid,
  entityOptions,
  entitySelectIsVisible,
  customSubjectIsVisible,
  replyingTo,
}: InjectedFormProps<FieldProps> & ServicesFormEnquireProps) => (
  <form onSubmit={handleSubmit(send)}>
    {!replyingTo && (
      <div>
        {entitySelectIsVisible && (
          <Field
            name='customerId'
            label='From'
            component={SingleField}
            InputComponent={Select}
            inputComponentProps={{
              options: entityOptions,
            }}
            validate={v.required}
          />
        )}

        <Field name='subjectLabel' component={SubjectMessage} />
        <div className={style.hidden}>
          <Field name='subjectCode' component={SingleField} type='text' />
        </div>

        {customSubjectIsVisible && (
          <Field
            name='customSubject'
            component={SingleField}
            type='text'
            validate={v.required}
            placeholder='Subject of your message...'
          />
        )}
      </div>
    )}

    <Field
      label='Please include any further information below'
      name='body'
      type='textarea'
      component={SingleField}
      validate={v.required}
      validateType='onSubmit'
    />

    <BottomError message={error} />
    <Button disabled={invalid} submit text='Send Enquiry' loading={submitting} />
  </form>
)

const ServicesFormEnquireReduxForm = reduxForm<FieldProps, ServicesFormEnquireProps>({
  form: 'mail',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ServicesFormEnquire)

export default ServicesFormEnquireReduxForm
