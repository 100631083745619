import { createFormAction } from 'redux-form-saga'
import * as c from '../../constants'

export const bulkUploadVerify = createFormAction('BULK_UPLOAD_VERIFY')
export const bulkUploadConfirm = createFormAction('BULK_UPLOAD_CONFIRM')

export function paymentsBulkInit() {
  return {
    type: c.PAYMENTS_BULK_INIT,
  }
}

/*
export function paymentBulkDetailsUpdate (details) {
  return {
    type    : c.PAYMENTS_BULK_FLOW_UPDATE,
    payload: {
      details
    }
  }
}
*/

export function paymentsBulkItemInit(id: string) {
  return {
    type: c.PAYMENTS_BULK_ITEM_INIT,
    payload: {
      id,
    },
  }
}

export function paymentsBulkListUpdate() {
  return {
    type: c.PAYMENTS_BULK_LIST_UPDATE,
  }
}
