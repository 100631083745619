import React from 'react'
import Button from '../Button'
import s from './upcoming.css'

const Upcoming = () => (
  <div className={s.root}>
    <h2 className={s.heading}>Upcoming payments and transfers</h2>
    <p>View your recently paid and next due standing orders</p>
    <div className={s.buttons}>
      <Button text='View' to='/payments/upcoming' className={s.viewButton} />
      <Button text='Set up new' to='/payments/make?isRegular=true' />
    </div>
  </div>
)

export default Upcoming
