import { createFormAction } from 'redux-form-saga'
import * as c from '../../constants'

export const paymentStop = createFormAction('PAYMENTS_FUTURE_STOP')

export function paymentsFutureInit(id: string | number) {
  return {
    type: c.PAYMENTS_FUTURE_INIT,
    payload: {
      id,
    },
  }
}
