import * as React from 'react'

function SvgAlert(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 26.66 26.8'
      width='1em'
      height='1em'
      {...props}
    >
      <defs>
        <style>{'.alert_svg__cls-1{fill:#5e5e5e}'}</style>
      </defs>
      <g id='alert_svg__Layer_2' data-name='Layer 2'>
        <g id='alert_svg__Layer_1-2' data-name='Layer 1'>
          <path
            className='alert_svg__cls-1'
            d='M26.61 18.71a.47.47 0 00-.47-.36 1.61 1.61 0 01-1.1-2.8.4.4 0 000-.55 7.2 7.2 0 00-1.15-1.17.41.41 0 00-.55 0 1.68 1.68 0 01-1.82.41 1.62 1.62 0 01-1-1.58.4.4 0 00-.36-.43 7.27 7.27 0 00-1.64 0 .41.41 0 00-.36.42 1.62 1.62 0 01-1 1.55 1.68 1.68 0 01-1.8-.41.41.41 0 00-.55 0A7.23 7.23 0 0013.69 15a.41.41 0 000 .55 1.62 1.62 0 01.41 1.82 1.68 1.68 0 01-1.58 1 .4.4 0 00-.42.36 7.3 7.3 0 000 1.66.48.48 0 00.48.36 1.6 1.6 0 011.5 1 1.62 1.62 0 01-.41 1.8.4.4 0 000 .55 7.22 7.22 0 001.15 1.17.4.4 0 00.55 0 1.68 1.68 0 011.82-.41 1.62 1.62 0 011 1.58.4.4 0 00.36.43 7.26 7.26 0 001.64 0 .41.41 0 00.36-.42 1.62 1.62 0 011-1.55 1.68 1.68 0 011.8.41.41.41 0 00.55 0 7.25 7.25 0 001.18-1.17.41.41 0 000-.55 1.61 1.61 0 011.08-2.81h.09a.41.41 0 00.43-.36 7.29 7.29 0 00-.07-1.71zM19.38 22a2.43 2.43 0 112.43-2.43A2.43 2.43 0 0119.38 22zM6.73 22a3 3 0 004.51 2.52A9 9 0 0110 22zM9.69 19.48a9 9 0 016.79-8.75V9.5A6.79 6.79 0 0011 2.84V1.33a1.33 1.33 0 10-2.66 0v1.5A6.79 6.79 0 002.9 9.5v4.89L.23 18.3a1.35 1.35 0 001.11 2.1h8.39a9.1 9.1 0 01-.04-.92z'
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgAlert
