import React from 'react'
import { Link } from 'react-router'
import s from './styles.css'
import Icon from '../Icon'

type JumpoffsProps = {
  items: {
    title: string
    icon: string
    to: string
  }[]
}

const Jumpoffs = ({ items }: JumpoffsProps) => (
  <nav className={s.root}>
    <ul>
      {items.map(({ icon, title, to }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index}>
          <Link className={s.button} to={to}>
            <span className={s.icon}>
              <Icon icon={icon} />
            </span>
            {title}
            <span className={s.chevron}>
              <Icon icon='chevronRight' />
            </span>
          </Link>
        </li>
      ))}
    </ul>
  </nav>
)

export default Jumpoffs
