import * as c from '../../constants'
import { ChangeCredentialsSteps } from '../../models/enum'

export interface IChangeCredentialsState {
  steps: any[]
  isFirstTime: boolean
  currentStep: boolean | number
  isNeeded: boolean
  authError: boolean
  isLoading: boolean
}

const initialState: IChangeCredentialsState = {
  steps: [],
  isFirstTime: false,
  currentStep: false,
  isNeeded: false,
  authError: false,
  isLoading: false,
}

export default function changeCredentialsReducer(
  state = initialState,
  action
): IChangeCredentialsState {
  const p = action.payload

  switch (action.type) {
    case c.CHANGE_CREDENTIALS_REGISTER_STEPS: {
      const steps = []
      if (p.flags.isFirstTime && p.flags.termsNotAccepted) {
        steps.push(ChangeCredentialsSteps.welcome)
        if (p.flags.termsNotAccepted) {
          steps.push(ChangeCredentialsSteps.terms)
        } else {
          steps.push(false)
        }
        if (p.flags.forcePasswordChange) {
          steps.push(ChangeCredentialsSteps.pass)
          steps.push(ChangeCredentialsSteps.passChanged)
        } else {
          steps.push(false)
        }

        if (p.flags.forcePinChange) {
          steps.push(ChangeCredentialsSteps.pin)
        } else {
          steps.push(false)
        }
      } else if (!p.flags.termsNotAccepted) {
        if (p.flags.forcePasswordChange) steps.push(ChangeCredentialsSteps.pass)
        if (p.flags.forcePasswordChange) steps.push(ChangeCredentialsSteps.passChanged)
        if (p.flags.forcePinChange) steps.push(ChangeCredentialsSteps.pin)
      }

      steps.push(ChangeCredentialsSteps.success)

      return {
        ...state,
        steps,
        currentStep: 0,
        isFirstTime: p.flags.isFirstTime,
        isNeeded: true,
        isLoading: false,
        authError: false,
      }
    }
    case c.CHANGE_CREDENTIALS_NEXT_STEP: {
      // Skip over steps if they are false
      const incStep = (currentStep) => {
        const index = currentStep + 1
        if (state.steps[index] !== false) return index
        return incStep(index)
      }
      const currentStep = incStep(state.currentStep)
      const isNeeded = currentStep !== state.steps.length - 1

      return {
        ...state,
        currentStep,
        isNeeded,
      }
    }
    case c.AUTH_TOKEN_FAILURE: {
      return {
        ...state,
        authError: true,
        isLoading: false,
      }
    }
    case c.DISPLAY_LOADER: {
      return {
        ...state,
        isLoading: true,
        authError: false,
      }
    }
    default:
      return state
  }
}
