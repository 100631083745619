/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import { Link } from 'react-router'
import s from './styles.css'
import PrimaryNav from './PrimaryNav'
import SecondaryNav from '../../containers/SecondaryNav'
import MegaMenu from '../../containers/MegaMenu'
import UserStatus from './UserStatus'
import SelectEntity from '../../containers/SelectEntity'
import EntitiesHelp from '../../containers/EntitiesHelp'

import logoStacked from '../../assets/svg/logo-stacked.svg'

type HeaderProps = {
  onBurgerClick?: () => void
  loggedOut?: boolean
  hasEntities?: boolean
  lastLogin?: string
  displayName?: string
}

const Header = ({ onBurgerClick, loggedOut, hasEntities, lastLogin, displayName }: HeaderProps) => {
  const loggedClass = loggedOut ? s.loggedOut : s.loggedIn
  const bottom = (
    <div className={s.bottom}>
      <div className={s.inner}>
        <div className={s.moduleLeft}>
          <PrimaryNav />
        </div>
        <div className={s.moduleRight}>
          <UserStatus lastLogin={lastLogin} displayName={displayName} />
        </div>
        {hasEntities && (
          <div className={`${s.moduleRight} ${s.selectEntity}`}>
            <SelectEntity />
          </div>
        )}
      </div>
    </div>
  )

  const menuIcon = (
    /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */
    <span onClick={onBurgerClick} className={s.mobMenuButton}>
      Menu
    </span>
  )

  const to = loggedOut ? undefined : '/accounts'

  return (
    <div>
      <header className={`${s.root} ${loggedClass}`}>
        <div className={s.top}>
          <div className={s.inner}>
            <Link to={to}>
              <img src={logoStacked} alt='Weatherbys' className={s.logoStacked} />
            </Link>
            {!loggedOut && <SecondaryNav />}
            {!loggedOut && menuIcon}
          </div>
        </div>

        {!loggedOut && bottom}
        {!loggedOut && <MegaMenu />}
      </header>
      {!loggedOut && <EntitiesHelp />}
    </div>
  )
}

export default Header
