import React from 'react'
import Breadcrumbs from '../Breadcrumbs'
import ContactContainer from '../ContactContainer'
import InnerFullWidthContainer from '../InnerFullWidthContainer'
import ServicesHeader from '../ServicesHeader'
import s from '../ServicesView/styles.css'

const addPadding = true

export const ServicesAssets = (/* { mappingError } */) => (
  <div>
    <Breadcrumbs
      items={[
        {
          to: '/services',
          text: 'Additional services',
        },
        {
          to: '/services/assetfinance',
          text: 'Asset finance',
        },
      ]}
    />
    <ServicesHeader
      title='Flexible asset finance'
      secondaryTitle='Experts in financing for both business and personal use'
    />
    <InnerFullWidthContainer addPadding={addPadding}>
      <div className={`${s.textArea} ${s.extraTopMargin}`}>
        <p>
          Specialising in asset finance and leasing, Arkle Finance Ltd (AFL) helps businesses and
          private individuals to secure equipment and vehicles through a wide range of tailored
          financial products.
        </p>
      </div>
      <ContactContainer
        title='Find out more'
        infoText={`To find out more about our Asset Finance services, please visit
         the Arkle Finance website or contact the team`}
        items={[
          {
            linkTitle: '+44 (0)1933 304 899',
            to: '+44 (0)1933 304 899',
            telNumber: true,
          },
          {
            linkTitle: 'Visit Arkle Finance',
            to: 'www.arklefinance.co.uk',
            external: true,
          },
        ]}
      />
    </InnerFullWidthContainer>
  </div>
)

export default ServicesAssets
