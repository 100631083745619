import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import get from 'lodash/get'
import moment from 'moment'
import RequestInternationalPaymentForm from '../../components/RequestInternationalPaymentForm'
import { send } from '../RequestInternationalPayment/actions'
import convertToOptions from '../../utils/convertToOptions'
import getInternationalPayingAccounts from '../../selectors/getInternationalPayingAccounts'
import getAccount from '../../selectors/getAccount'
import { countryOptions, currencyOptions, countries } from './options'
import { getPaymentCharges } from './selectors'
import * as v from '../../utils/forms/validators'

const getPaymentChargeOption = (paymentCharges) => {
  switch (paymentCharges) {
    case 'Fixed':
      return [{ label: 'Shared charges (£30)', value: 'weatherbys' }]

    case 'High':
      return [
        { label: 'Shared charges (£30)', value: 'weatherbys' },
        {
          label: 'All charges, to pay foreign bank charges (£38.50)',
          value: 'all',
        },
      ]
    case 'All':
      return [
        { label: 'Next Working Day/Standard (£10)', value: 'nextDay' },
        { label: 'Shared charges (£30)', value: 'weatherbys' },
        {
          label: 'All charges, to pay foreign bank charges (£38.50)',
          value: 'all',
        },
      ]
    default:
      return []
  }
}
const mapStateToProps = (state, ownProps) => {
  const formValues: any = getFormValues('request-international-payment')(state)
  const fromVal = get(formValues, 'fromAccount.value')
  const currVal = get(formValues, 'currency.value')
  const fromAccount = getAccount(state, fromVal)
  const productType = fromAccount && fromAccount.productType
  const fullFormIsShowing = formValues && formValues.country
  const code = fullFormIsShowing && countries[get(formValues, 'country.value')]
  const currencyNote =
    currVal === 'Other' ? 'Please let us know the currency in the comments field below.' : undefined
  let abaIsShowing
  let ibanIsShowing
  let accountNumberIsShowing
  let addressValidation

  switch (code) {
    case 'IB':
      abaIsShowing = false
      ibanIsShowing = true
      accountNumberIsShowing = false
      addressValidation = undefined
      break
    case 'AB':
      abaIsShowing = true
      ibanIsShowing = false
      accountNumberIsShowing = true
      addressValidation = v.required
      break
    default:
      abaIsShowing = false
      ibanIsShowing = false
      accountNumberIsShowing = true
      addressValidation = v.required
      break
  }

  const paymentCharges = getPaymentCharges(state)

  let paymentChargeOptions
  if (productType === 'CURRPL') {
    paymentChargeOptions = [{ label: 'Racing Plus Charge £7', value: 'all' }]
  } else {
    paymentChargeOptions = getPaymentChargeOption(paymentCharges)
  }

  return {
    fullFormIsShowing: formValues && formValues.country !== undefined,
    fromOptions: convertToOptions(getInternationalPayingAccounts(state)),
    currencyOptions,
    fromAvailableBalance: fromAccount && fromAccount.availableBalance,
    countryOptions,
    abaIsShowing,
    ibanIsShowing,
    accountNumberIsShowing,
    addressValidation,
    currencyNote,
    minDate: moment(),
    showPaymentOption: paymentCharges === 'Low',
    showPaymentCharges:
      paymentCharges === 'High' || paymentCharges === 'Fixed' || paymentCharges === 'All',
    paymentChargeOptions,
    initialValues: {
      currency: {
        value: 'GBP',
      },
      fromAccount: {
        value: ownProps.accountId,
      },
    },
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  send,
})

export default connect(mapStateToProps, mapDispatchToProps)(RequestInternationalPaymentForm)
