import moment from 'moment'
import { dontDuplicate } from '../utils/routerBugWorkaround'

export default (store) => ({
  path: 'all',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const PaymentsBulkList = require('../containers/PaymentsBulkList').default
        const { paymentsBulkListUpdate } = require('../containers/PaymentsBulk/actions')
        const { superDatePickerInit } = require('../containers/SuperDatePicker/actions')

        dontDuplicate(() => {
          store.dispatch(
            superDatePickerInit(
              'paymentsBulkFiles',
              moment().startOf('day').subtract(1, 'year'),
              moment().startOf('day')
            )
          )
          store.dispatch(paymentsBulkListUpdate())
        })

        cb(null, PaymentsBulkList)
      },
      'payments-bulk-list'
    )
  },
})
