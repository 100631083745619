import React from 'react'
import s from './styles.css'
import { Button } from '../../Button'
import Status from '../../Status'

type SuccessStepProps = {
  successMessage: string
  ready?: boolean
}

export const SuccessStep = ({ ready, successMessage }: SuccessStepProps) => (
  <div className={s.root}>
    <div className={s.status}>
      {ready ? <Status text={successMessage} /> : <Status text='Details are being updated' />}
    </div>

    <div className={s.button}>
      {/* eslint-disable-next-line react/style-prop-object */}
      <Button disabled={!ready} replace='/accounts' style='secondary' text='View My Accounts' />
    </div>
  </div>
)

export default SuccessStep
