import * as c from '../../constants'
import { IuStatementsFetchRequest } from './interface'

export function rStatementsFetchRequest() {
  return {
    type: c.R_STATEMENTS_FETCH_REQUEST,
  }
}

export function uStatementsFetchRequest(id: string, items: IuStatementsFetchRequest[]) {
  return {
    type: c.U_STATEMENTS_FETCH_REQUEST,
    payload: {
      id,
    },
  }
}

export function statementsFetchStop() {
  return {
    type: c.STATEMENTS_FETCH_STOP,
  }
}

export function statementsFetchAllStop() {
  return {
    type: c.STATEMENTS_FETCH_ALL_STOP,
  }
}

export function statementsFetchAllStart() {
  return {
    type: c.STATEMENTS_FETCH_ALL_START,
  }
}

export function statementsFetchSuccess(items: IuStatementsFetchRequest[], id) {
  return {
    type: c.STATEMENTS_FETCH_SUCCESS,
    payload: {
      items,
      id,
    },
  }
}

export function statementsFetchFail(error: string) {
  return {
    type: c.STATEMENTS_FETCH_FAIL,
    payload: {
      error,
    },
  }
}

export function statementDownloadRequest(id: string | number) {
  return {
    type: c.STATEMENT_DOWNLOAD_REQUEST,
    payload: {
      id,
    },
  }
}

export function statementDownloadSuccess() {
  return {
    type: c.STATEMENT_DOWNLOAD_SUCCESS,
  }
}

export function statementDownloadFail(error: string) {
  return {
    type: c.STATEMENT_DOWNLOAD_FAIL,
    payload: {
      error,
    },
  }
}
