/* eslint-disable */
// @ts-nocheck
// todo - eslint and typescript
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import values from 'lodash/values'
import { getFormValues } from 'redux-form'
import filterSearch from '../../selectors/filterSearch'
import { decodeHTML } from '../../utils/decodeHTML'

export const getSelectedPayee = (state) => state.payees.list.items[state.flow.data]

export const filterAlphabet = (items, charSetIp, titles = []) => {
  const newItems = []
  const charSet = charSetIp.toLowerCase()

  // Loop through items
  items.forEach((item) => {
    // eslint-disable-next-line no-underscore-dangle
    let _order = false
    let index = 0
    let checkedFirst = false
    const words = item.title.split(' ')
    // Loop through words in each title
    words.forEach((word) => {
      // Check first letter exists, in case of multiple spaces
      // Check if already passed, no need to check other words if so
      // Check if first word is included in titles arr
      // Modified to check if first letter exists
      if (
        (word[0] || /[0-9]/gi.test(word[0])) &&
        _order === false &&
        (checkedFirst || titles.indexOf(word.toLowerCase()) === -1)
      ) {
        // Letter of word against charSet
        const l = word[0].toLowerCase()
        if (charSet.toLowerCase().indexOf(l) !== -1) {
          _order = index
        }
        index++
        checkedFirst = true
      }
    })
    if (_order !== false) {
      newItems.push({
        ...item,
        _order,
      })
    }
  })
  return sortBy(newItems, (item) => item._order)
}

export const filterEntity = (items, entityId) =>
  items.filter((item) => item.customerId === entityId)

export const orderAlphabetically = (items) => sortBy(items, (o) => o.title.toLowerCase())

export const getVisiblePayees = (state, entityFilterMode) => {
  let items = values(state.payees.list.items).map((item) => {
    item.details = `${item.sortCodeNice} ${item.accountNumber}`
    item.title = item.payeeDescription ? decodeHTML(item.payeeDescription) : decodeHTML(item.title)
    return item
  })

  let entityId

  const formValues = getFormValues('payeeFilters')(state)
  const searchTerm = get(formValues, 'search')

  if (entityFilterMode === 'inner') {
    entityId = get(formValues, 'entity.value')
  } else {
    entityId = state.entities.activeEntityId
  }

  const alphabetChars = state.browser.greaterThan.medium
    ? get(state, 'payees.filters.alphabet')
    : get(formValues, 'alphabet.value')

  if (items) {
    items = orderAlphabetically(items)

    if (entityId) {
      items = filterEntity(items, entityId)
    }

    if (alphabetChars) {
      // Titles MUST be lowercase (mr, mrs), NOT (Mr, Mrs)
      items = filterAlphabet(items, alphabetChars, ['mr', 'mrs', 'dr', 'ms'])
    }
    // Search
    if (searchTerm) {
      items = filterSearch(items, searchTerm, ['title', 'lastReference'])
    }
  }

  return items
}
