import React, { ReactElement } from 'react'
import s from './styles.css'

type InputProps = {
  input: { value?: string; name?: string }
  type: string
  placeholder?: string
  showError?: boolean
  autoFocus?: boolean
  className?: string // todo - testing- was added newly due to the fact parent is passing - but the below code do not use,
  autoComplete?: string
  maxLength?: number
}

const Input = ({
  input,
  type,
  placeholder,
  showError,
  autoFocus,
  autoComplete,
  maxLength,
}: InputProps) => {
  const className = showError ? s.error : undefined
  let el: ReactElement

  const inp = { ...input }

  if (type === 'password') delete inp.value

  if (type !== 'textarea') {
    el = (
      <input
        {...inp}
        id={inp.name}
        className={s.inp}
        type={type}
        placeholder={placeholder}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        maxLength={maxLength}
      />
    )
  } else {
    el = (
      <textarea
        {...inp}
        id={inp.name}
        className={s.inp}
        placeholder={placeholder}
        autoFocus={autoFocus}
      />
    )
  }

  return <div className={`${s.root} ${className}`}>{el}</div>
}

export default Input
