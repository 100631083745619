import * as React from 'react'

function SvgChevronUp(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 459 295'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <use xlinkHref='#chevron-up_svg__path0_fill' transform='rotate(90 229.5 229.5)' />
      <defs>
        <path
          id='chevron-up_svg__path0_fill'
          d='M289.6 78.3L138.4 229.5l151.2 151.2c3.6 3.6 5.4 7.9 5.4 12.8s-1.8 9.2-5.4 12.8l-47.3 47.3c-3.6 3.6-7.9 5.4-12.8 5.4s-9.2-1.8-12.8-5.4L5.4 242.3c-3.6-3.6-5.4-7.9-5.4-12.8s1.8-9.2 5.4-12.8L216.7 5.4c3.6-3.6 7.9-5.4 12.8-5.4s9.2 1.8 12.8 5.4l47.3 47.3c3.6 3.6 5.4 7.9 5.4 12.8s-1.8 9.2-5.4 12.8z'
        />
      </defs>
    </svg>
  )
}

export default SvgChevronUp
