import React from 'react'
import { Link } from 'react-router'
import s from './styles.css'
import Icon from '../Icon'
import { IQuickLinkMapping } from './types'

type ButtonProps = {
  text: string
  icon: string
  to: string
  className?: string
}

const Button = ({ text, icon, to, className }: ButtonProps) => (
  <Link to={to} className={s.button}>
    <span className={`${s.icon} ${className}`}>
      <Icon icon={icon} />
    </span>
    {text}
    <span className={s.chevron}>
      <Icon icon='chevronRight' />
    </span>
  </Link>
)

type QuickLinksProps = {
  mappingList: IQuickLinkMapping // todo - introduced interface
}

const QuickLinks = ({ mappingList }: QuickLinksProps) => (
  <nav className={s.root}>
    <div className={s.inner}>
      <h2 className={s.title}>Quick links:</h2>
      <ul className={s.list}>
        {mappingList.accServ && (
          <li>
            <Button
              text='Cheque services'
              icon='services'
              to='/accounts/services'
              className={s.cheque}
            />
          </li>
        )}
        {mappingList.statements && (
          <li>
            <Button text='Statements' icon='document' to='/accounts/statements' />
          </li>
        )}
      </ul>
    </div>
  </nav>
)

export default QuickLinks
