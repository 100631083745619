/* eslint-disable guard-for-in */
import toArray from 'lodash/toArray'
import moment from 'moment'
import formatMoney from '../formatMoney'

const typeToCode = {
  cardTransactions: ['POT', 'T00'],

  cash: ['CCT', 'CDP', 'CWD', 'UOC', 'WCD'],

  cheques: ['CQD', 'CQR', 'FCF', 'FCW', 'MCQ'],

  incomingTransfers: ['IBT'],

  interestAndCharges: [
    'ACC',
    'ACE',
    'ACI',
    'AQI',
    'BLC',
    'BRE',
    'BTD',
    'CDC',
    'CIA',
    'CRI',
    'CTC',
    'DIA',
    'DRI',
    'FCC',
    'FCD',
    'FCH',
    'FCN',
    'FDG',
    'FIC',
    'FMC',
    'FMD',
    'FMG',
    'FMO',
    'FNU',
    'FOC',
    'FOD',
    'FOG',
    'FSC',
    'FSF',
    'FSO',
    'FSU',
    'FTL',
    'FUC',
    'FUD',
    'FUS',
    'IIP',
    'LAC',
    'LAF',
    'LAT',
    'LIC',
    'LOC',
    'MMF',
    'OCI',
    'OCP',
    'ODP',
    'OTD',
    'PIC',
    'POF',
    'REI',
    'RGL',
    'RNL',
    'SIN',
    'SIP',
    'SIS',
    'SIT',
    'TAX',
  ],

  internalTransfers: ['AAT', 'DRW', 'FTC', 'FTD', 'JNL', 'NSA', 'OIT', 'SWP'],

  international: ['DRC', 'FXB', 'FXR', 'SEP'],

  loanRepayments: ['REP'],

  miscellaneous: [
    'ACA',
    'BHR',
    'BJN',
    'CMA',
    'CMC',
    'COM',
    'DAL',
    'EEX',
    'FBL',
    'FCR',
    'FDL',
    'WAI',
    'YET',
    'FDP',
    'FPF',
    'FXS',
    'HRG',
    'IAP',
    'IDI',
    'IDR',
    'LWO',
    'MMA',
    'MMB',
    'MMD',
    'MMP',
    'OGT',
    'PFA',
    'PUL',
    'PWB',
    'RVL',
    'SCP',
    'SDA',
    'SDC',
    'SIA',
    'SNA',
    'SPA',
    'SPM',
    'SPR',
    'SRL',
    'SRP',
    'SSD',
  ],

  payments: [
    'BDR',
    'BGC',
    'BPB',
    'BPC',
    'CHP',
    'DDR',
    'DRS',
    'FDC',
    'FDD',
    'FIT',
    'FTP',
    'FTS',
    'GLF',
    'INV',
    'JFS',
    'MCR',
    'MDR',
    'OLB',
    'PMY',
    'REF',
    'REV',
    'ROA',
    'TBD',
    'BFN',
    'EFS',
  ],

  reccurentPayments: ['DDC', 'DDI', 'DDX', 'OSO', 'SIF', 'STD', 'STO'],

  vat: ['VAT'],
}

// Do this once so it's easy to lookup
const codeToType = {}

// eslint-disable-next-line no-restricted-syntax
for (const key in typeToCode) {
  typeToCode[key].forEach((code) => {
    codeToType[code] = key
  })
}
export interface TidyLoanTransactionsD {
  data: {
    accountId: {
      displayValue: string
    }
    items: {
      amountInAccountCurrency: {
        amount: number | string
      }
      key: {
        subSequenceNumber: number
        transactionReferenceNumber: number
      }
      description: string
      transactionType: string
      transactionDate: string
      txncode: string
    }[]
  }
}
export default (data: TidyLoanTransactionsD, state) => {
  const tidyData: any = {}

  const accountNr = data.data.accountId.displayValue
  let accountItems = state.accounts.items

  if (!(accountItems instanceof Array)) {
    accountItems = toArray(accountItems)
  }
  const account = accountItems.filter((item) => item.accountNumber === accountNr)
  let { items } = data.data

  if (!(items instanceof Array)) {
    items = toArray(items)
  }

  tidyData.items = items.map((item) => {
    const { currency } = account
    let debit
    let credit
    let amount

    if (item.transactionType === 'D') {
      debit = formatMoney(item.amountInAccountCurrency.amount, currency)
      amount = -item.amountInAccountCurrency.amount
    } else if (item.transactionType === 'C') {
      credit = formatMoney(item.amountInAccountCurrency.amount, currency)
      amount = item.amountInAccountCurrency.amount
    } else {
      console.error('Unrecognised creditdebitflag')
    }

    return {
      id: String(item.key.subSequenceNumber),
      title: item.description,
      debit,
      credit,
      ref: item.key.transactionReferenceNumber,
      debitCredit: formatMoney(amount, currency),
      debitCreditFlag: item.transactionType,
      date: moment(item.transactionDate, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY'),
      itemStamp: parseInt(moment(item.transactionDate, 'YYYY-MM-DD HH:mm:ss').format('X')),
      type: codeToType[item.txncode],
      transactionCode: item.txncode,
    }
  })

  return tidyData
}
