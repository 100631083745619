export const getInitialValues = (state) => {
  const { items } = state.mail.outbox
  // Makes the most recent sent message come first
  const sent = Object.values(items).reverse()

  const message: any = sent.find((msg: any) => msg && msg.subject.startsWith('MARKETING_RESPONSE'))

  if (!message) {
    return {}
  }

  const { subject } = message
  const values = {
    consent: subject.includes('consent=true'),
    nonConsent: subject.includes('consent=false'),
    post: subject.includes('post=true'),
    telephone: subject.includes('telephone=true'),
    email: subject.includes('email=true'),
    text: subject.includes('text=true'),
  }

  return values
}

export const getCustomerId = (state) => {
  const { mainPartyId } = state.user
  return mainPartyId
}
