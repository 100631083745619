import React from 'react'
import AccountPermissionsView from './AccountPermissionsView'
import AccountPermissionsEdit from './AccountPermissionsEdit'

type AccountPermissionsCardProps = {
  onCheckboxClick: (name: string, v2: string, v3: string) => void
  name: string
  permissionData: any // todo - was object.
  defaultView: string
  accountName: string
  accountNumber: string
  availabilityInfo: any
}

const AccountPermissionsCard = ({
  onCheckboxClick,
  name,
  permissionData,
  defaultView,
  accountName,
  accountNumber,
  availabilityInfo,
}: AccountPermissionsCardProps) => {
  const { paymentTransfers, accountEnquiries } = permissionData
  if (defaultView === 'view' && (paymentTransfers || accountEnquiries)) {
    return (
      <AccountPermissionsView
        accountNumber={accountNumber}
        accountEnquiries={accountEnquiries || false}
        paymentTransfers={paymentTransfers || false}
        accountName={accountName}
      />
    )
  }
  if (defaultView === 'edit') {
    return (
      <AccountPermissionsEdit
        onCheckboxClick={onCheckboxClick}
        accountNumber={accountNumber}
        accountName={accountName}
        accountEnquiries={accountEnquiries || false}
        paymentTransfers={paymentTransfers || false}
        name={name}
        showPayments={availabilityInfo.paymentTransfers || false}
        showAccountEnquiries={availabilityInfo.accountEnquiries || false}
      />
    )
  }
  return null
}

export default AccountPermissionsCard
