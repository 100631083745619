import { connect } from 'react-redux'
import TwoFactorAuth from '../../components/TwoFactorAuth'
import { OTPSubmit, PINSubmit } from './actions'
import { showRecentMessage } from './selectors'

const mapStateToProps = (state, ownProps) => {
  const step = state.twoFactorAuth.stepTwo ? 'PIN' : 'OTP'
  const isFirst = step === 'OTP'
  return {
    step,
    OTPSubmit,
    PINSubmit,
    stepNum: isFirst ? 0 : 1,
    showRecentMessage: showRecentMessage(state),
    description: isFirst
      ? `A one time password (OTP) has been sent to your mobile number or registered email address. 
      The OTP will be valid for 10 minutes.`
      : `To confirm it's you, please enter your Transaction PIN to
      authorise a payment to somebody new.`,
  }
}

export default connect(mapStateToProps)(TwoFactorAuth)
