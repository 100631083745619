import { connect } from 'react-redux'
import LoginForm from '../../components/LoginForm'
import { authenticate } from './actions'

const mapStateToProps = (state) => ({
  isCapsLockOn: state.ui.tooltip === 'CapsLock',
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  // This action isn't being dispatched here, but passed in as a prop
  // for redux-form's handleSubmit to make use of
  authenticate,
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
