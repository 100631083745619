import moment from 'moment'
import get from 'lodash/get'

/**
 * Tidies deeply nested and poorly named accounts data from API into something
 * easier to deal with!
 * @param  {Object} data - raw data from API
 * @return {Object} data - tidied data
 */

const hardDate = moment('16-04-2016', 'DD-MM-YYYY').format('YYYY-MM-DD')
export interface TidyStatementsD {
  data: {
    statements:
      | {
          documentId: {
            displayValue: string
            value: string
          }
          accountno: string
          fromDate: string
          toDate: string
          statementType?: string
          documentName: string
        }[]
      | {
          documentId: {
            displayValue: string
            value: string
          }
          accountno: string
          fromDate: string
          toDate: string
          statementType?: string
          documentName: string
        }
  }
}
export default (data: TidyStatementsD, state?: any) => {
  let statements = get(data, 'data.statements', [])
  if (!(statements instanceof Array)) {
    statements = [statements]
  }

  return statements.map((item) => {
    const endDate = moment(item.toDate, 'YYYY-MM-DD').startOf('day')
    const startDate = moment(item.fromDate, 'YYYY-MM-DD').startOf('day')
    const date = endDate.isSameOrAfter(hardDate) ? endDate : startDate

    const title = `${item.statementType} ${date.format('MMMM YYYY')} (${item.documentName})`

    return {
      id: item.documentId.value,
      documentId: item.documentId.value,
      title,
      timeStamp: parseInt(date.format('X')),
      date: date.format('DD/MM/YYYY'),
      year: date.format('YYYY'),
    }
  })
}
