import React from 'react'
import s from './styles.css'

import Button from '../Button'

const AlertPanel = () => (
  <div className={s.root}>
    <p>
      A new payment to <strong>Prestigious Gallery</strong> requires your authorisation.
    </p>
    {/* eslint-disable-next-line react/style-prop-object */}
    <Button text='View' style='light' />
  </div>
)

export default AlertPanel
