import { connect } from 'react-redux'
import toArray from 'lodash/toArray'
import ActivityLogTypeList from '../../components/ActivityLogTypeList'
import { getItems } from './selectors'
import niceTableData from '../../utils/niceTableData'
import { superDatePickerCancel } from '../SuperDatePicker/actions'
import { activityLogTypeUpdate } from './actions'

const mapStateToProps = (state, ownProps) => {
  const tableItems = toArray(getItems(state))

  const cols = [
    {
      header: 'Reference',
      mapTextTo: 'reference',
      perc: 25,
    },
    {
      header: 'Status',
      mapTextTo: 'status',
      perc: 20,
    },
    {
      header: 'Created',
      mapTextTo: 'created',
      perc: 20,
    },
  ]

  if (tableItems[0] && tableItems[0].account) {
    cols.push({
      header: 'Account No.',
      mapTextTo: 'account',
      perc: 25,
    })
  }

  if (tableItems[0] && tableItems[0].amountPure) {
    cols.push({
      header: 'Amount',
      mapTextTo: 'amount',
      perc: 20,
    })
  }

  const tableFormat = {
    mapRowIdTo: 'reference',
    cols,
    card: {
      mapTitleTo: 'reference',
      mapTextTo: 'status',
      mapDetailsTo: 'type',
      mapExtraTo: 'created',
    },
  }

  const niceData = niceTableData(tableItems, tableFormat)

  const rowLink = ({ reference: ref, statusCode: status, typeCode: type, bit }) =>
    `settings/activity-log/log/${ref}?statusCode=${status}&typeCode=${type}&bit=${bit}`

  const { isFetching } = state.activityLog.type

  return {
    rows: niceData.body,
    headerCols: niceData.header,
    virtualized: true,
    rowLink,
    isFetching,
    failedToFetch: state.activityLog.type.errorMessage,
    errorCode: state.activityLog.type.errorCode,
    extraErrorMessage: state.activityLog.type.extraErrorMessage,
    cardForSmall: true,
    showCustomResultsState: !isFetching && niceData.body.length === 0,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDateClick: () => {
    dispatch(superDatePickerCancel('activityLogType'))
  },
  onDateSubmit: () => {
    dispatch(activityLogTypeUpdate())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLogTypeList)
