import React from 'react'
import s from './styles.css'
import { MaxHeightEnum } from '../../../models/enum'
import Slidey from '../../Slidey'

interface BottomErrorState {
  message?: string
}

interface BottomErrorProps {
  message?: string
  transferErrorMessage?: boolean // todo - was added as child was passing, but not used  in child
}

class BottomError extends React.Component<BottomErrorProps, BottomErrorState> {
  constructor(props) {
    super(props)
    this.state = {
      message: props.message,
    }
  }

  /* eslint-disable-next-line */
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    // To prevent dialog from jumping when sliding to hide,
    // we dont change the message to empty
    if (nextProps.message) {
      this.setState({
        message: nextProps.message,
      })
    }
  }

  render() {
    const isOpen = this.props.message !== undefined

    return (
      <Slidey isOpen={isOpen} maxHeight={MaxHeightEnum.small}>
        <div className={s.root}>{this.state.message}</div>
      </Slidey>
    )
  }
}

export default BottomError
