/* eslint-disable */
// @ts-nocheck
// todo - eslint and typescript
import React from 'react'
import PropTypes from 'prop-types'
import CoreLayout from '../containers/CoreLayout'
import LoggedOutLayout from '../containers/LoggedOutLayout'
import Login from './Login'
import ChangeCredentials from './ChangeCredentials'
import AccountsRoute from './Accounts'
import StatementsRoute from './Statements'
import AccountRoute from './Account'
import StyleguideRoute from './Styleguide'
import Payments from './Payments'
import PaymentMake from './PaymentMake'
import TransferMoney from './TransferMoney'
import RequestInternationalPayment from './RequestInternationalPayment'
import Mail from './Mail'
import MailList from './MailList'
import MailNew from './MailNew'
import MailView from './MailView'
import ServicesEnquire from './ServicesEnquire'
import Settings from './Settings'
import ManageAccounts from './ManageAccounts'
import ManageCredentials from './ManageCredentials'
import PaymentsUpcoming from './PaymentsUpcoming'
import PaymentsUpcomingEdit from './PaymentsUpcomingEdit'
import Payees from './Payees'
import PayeeEdit from './PayeeEdit'
import PayeeNew from './PayeeNew'
import ActivityLog from './ActivityLog'
import ActivityLogType from './ActivityLogType'
import ActivityLogSingle from './ActivityLogSingle'
import Authorisations from './Authorisations'
import PaymentsBulk from './PaymentsBulk'
import AccountServices from './AccountServices'
import MarketingPreferences from './MarketingPreferences'
import Alerts from './Alerts'
import PaymentCompanies from './PaymentCompanies'
import PaymentsBulkList from './PaymentsBulkList'
import PaymentsBulkItem from './PaymentsBulkItem'
import Services from './Services'
import ServicesInternational from './ServicesInternational'
import ServicesMortgageLoans from './ServicesMortgageLoans'
import ServicesAssets from './ServicesAssets'
import ServicesInvestmentWealth from './ServicesInvestmentWealth'
import ServicesExpatriate from './ServicesExpatriate'
import ServicesDepositAccounts from './ServicesDepositAccounts'
import ServicesInsurance from './ServicesInsurance'
import ServicesRacingPlus from './ServicesRacingPlus'
import ServicesVAT from './ServicesVAT'
import ServicesInvoice from './ServicesInvoice'
import InvestmentManager from './InvestmentManager'
import MarketTrackingPortfolios from './MarketTrackingPortfolios'
import EntityRedirect from '../components/EntityRedirect'
import RouteNotFound from '../components/RouteNotFound'
import { verifyAuthToken } from '../utils/verifyAuthToken'

import {
  isLoggedOut,
  isLoggedIn,
  isChangingCredentials,
  isNotChangingCredentials,
  isLocalStorageSessionActive,
  isLocalStorageSessionActiveBasic,
} from '../utils/redirectChecks'
import { clearSessionStorage, setSessionStorage } from '../utils/sessionStorage'

// import GA from '../utils/GA'
import { userAutoLogoutCheck } from '../utils/userAutoLogoutCheck'
import autoLogout from '../utils/autoLogout'
import { SESSION_EVENT_TIMESTAMP } from '../constants'
import { registerTabOrScreenOnStorage } from '../utils/goOutMultiLogin/registerTabOrScreen'

//#GA4-PROVISIONALLY-DISABLED
// GA.initialize(true)
window.autoLogout = false
interface AnalyticsState {
  location: any // todo - was object.
  children: PropTypes.any
}

class Analytics extends React.Component<any, AnalyticsState> {
  constructor(props) {
    super(props)
    // Initial page load - only fired once
    this.sendPageChange(props.location.pathname, props.location.search)
    this.setSessionValues = this.setSessionValues.bind(this)
  }

  /* eslint-disable-next-line */
  UNSAFE_componentWillReceiveProps(nextProps) {
    // When props change, check if the URL has changed or not
    if (
      this.props.location.pathname !== nextProps.location.pathname ||
      this.props.location.search !== nextProps.location.search
    ) {
      this.sendPageChange(nextProps.location.pathname, nextProps.location.search)
    }
  }

  captureVisibilitychange() {
    if (document.visibilityState === 'hidden' && !this.capturedEvent) {
      this.capturedEvent = true
      this.setSessionValues()
    }
  }

  unloadEvents() {
    if (!this.capturedEvent) {
      this.capturedEvent = true
      this.setSessionValues()
    }
  }

  setSessionValues() {
    setSessionStorage(SESSION_EVENT_TIMESTAMP, Date.now())
  }

  componentDidMount() {
    autoLogout('https://www.weatherbys.bank/sessionexpired')
    this.capturedEvent = false

    document.addEventListener('visibilitychange', this.captureVisibilitychange.bind(this))
    window.addEventListener('pagehide', this.unloadEvents.bind(this))
    window.addEventListener('beforeunload', this.unloadEvents.bind(this))
    window.addEventListener('unload', this.unloadEvents.bind(this))
    registerTabOrScreenOnStorage()
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.captureVisibilitychange.bind(this))
    window.removeEventListener('pagehide', this.unloadEvents.bind(this))
    window.removeEventListener('beforeunload', this.unloadEvents.bind(this))
    window.removeEventListener('unload', this.unloadEvents.bind(this))
  }

  sendPageChange(pathname, search = '') {
    if (pathname !== '' && pathname !== 'login') {
      document.title = 'Weatherbys Online Banking'
      !window.autoLogout && userAutoLogoutCheck(window.autoLogout)
    }
    const page = pathname + search
    //#GA4-PROVISIONALLY-DISABLED
    // GA.set({ page })
    // GA.pageview(page)
  }

  render() {
    return this.props.children
  }
}

export const createRoutes = (store) => [
  {
    path: '',
    component: Analytics,
    childRoutes: [
      {
        path: '/login',
        component: LoggedOutLayout,
        indexRoute: Login,
        hasBackgroundImage: true,
        onEnter: (nextState, replace) => {
          isLoggedIn(store.getState(), replace)
          isChangingCredentials(store.getState(), replace)
          clearSessionStorage(SESSION_EVENT_TIMESTAMP)
        },
      },
      {
        path: '/change-credentials',
        component: LoggedOutLayout,
        getIndexRoute: ChangeCredentials,
        firstTimeLogin: true,
        onEnter: (nextState, replace) => {
          isLoggedIn(store.getState(), replace)
          isNotChangingCredentials(store.getState(), replace)
        },
      },
      {
        path: '/change-credentials-password',
        component: LoggedOutLayout,
        getIndexRoute: ChangeCredentials,
        firstTimeLogin: true,
        onEnter: (nextState, replace) => {
          isLoggedIn(store.getState(), replace), verifyAuthToken(store.dispatch)
        },
      },
      {
        path: '/',
        component: CoreLayout,
        onEnter: (nextState, replace) => {
          isLoggedOut(store.getState(), replace)
        },
        indexRoute: {
          onEnter: (nextState, replace) => {
            isLocalStorageSessionActive(store.getState(), replace, '/accounts')
          },
        },
        childRoutes: [
          StyleguideRoute(store),
          {
            path: '/entity-redirect',
            component: EntityRedirect,
          },
          {
            path: '/accounts',
            onEnter: (nextState, replace) => {
              isLocalStorageSessionActiveBasic(store.getState(), store.dispatch, replace)
            },
            indexRoute: AccountsRoute(store),
            childRoutes: [AccountRoute(store), StatementsRoute(store), AccountServices(store)],
          },
          {
            path: '/payments',
            onEnter: (nextState, replace) => {
              isLocalStorageSessionActiveBasic(store.getState(), store.dispatch, replace)
            },
            indexRoute: Payments(store),
            childRoutes: [
              PaymentMake(store),
              TransferMoney(store),
              RequestInternationalPayment(store),
              {
                path: 'upcoming',
                indexRoute: PaymentsUpcoming(store),
                childRoutes: [PaymentsUpcomingEdit(store)],
              },
              {
                path: 'payees',
                indexRoute: Payees(store),
                childRoutes: [/*PayeeEdit(store), */ PayeeNew(store)],
              },
              {
                path: 'bulk',
                indexRoute: PaymentsBulk(store),
                childRoutes: [PaymentsBulkList(store), PaymentsBulkItem(store)],
              },
            ],
          },
          {
            path: '/services',
            onEnter: (nextState, replace) => {
              isLocalStorageSessionActiveBasic(store.getState(), store.dispatch, replace)
            },
            indexRoute: Services(store),
            childRoutes: [
              {
                path: 'mortgageloans',
                indexRoute: ServicesMortgageLoans(store),
                childRoutes: [],
              },
              {
                path: 'international',
                indexRoute: ServicesInternational(store),
                childRoutes: [],
              },
              {
                path: 'assetfinance',
                indexRoute: ServicesAssets(store),
                childRoutes: [],
              },
              {
                path: 'investmentwealth',
                indexRoute: ServicesInvestmentWealth(store),
                childRoutes: [
                  {
                    path: 'investmentmanager',
                    indexRoute: InvestmentManager(store),
                    childRoutes: [],
                  },
                  {
                    path: 'mtportfolios',
                    indexRoute: MarketTrackingPortfolios(store),
                    childRoutes: [],
                  },
                ],
              },
              {
                path: 'expatriate',
                indexRoute: ServicesExpatriate(store),
                childRoutes: [],
              },
              {
                path: 'deposit',
                indexRoute: ServicesDepositAccounts(store),
                childRoutes: [],
              },
              {
                path: 'insurance',
                indexRoute: ServicesInsurance(store),
                childRoutes: [],
              },
              {
                path: 'vatservices',
                indexRoute: ServicesVAT(store),
                childRoutes: [],
              },
              {
                path: 'invoiceservices',
                indexRoute: ServicesInvoice(store),
                childRoutes: [],
              },
              {
                path: 'racingplus',
                indexRoute: ServicesRacingPlus(store),
                childRoutes: [],
              },
            ],
          },
          {
            path: '/secure-mail',
            onEnter: (nextState, replace) => {
              isLocalStorageSessionActiveBasic(store.getState(), store.dispatch, replace)
            },
            getComponent: Mail(store),
            indexRoute: {
              onEnter: (nextState, replace) => replace('/secure-mail/f/inbox'),
            },
            childRoutes: [MailList(store), MailNew(store), MailView(store), ServicesEnquire(store)],
          },
          {
            path: '/settings',
            onEnter: (nextState, replace) => {
              isLocalStorageSessionActiveBasic(store.getState(), store.dispatch, replace)
            },
            getComponent: Settings(store),
            indexRoute: {
              onEnter: (nextState, replace) => replace('/settings/alerts'),
            },
            childRoutes: [
              Alerts(store),
              PaymentCompanies(store),
              ManageAccounts(store),
              ManageCredentials(store),
              Authorisations(store),
              MarketingPreferences(store),
              {
                path: 'activity-log',
                indexRoute: ActivityLog(store),
                childRoutes: [ActivityLogType(store), ActivityLogSingle(store)],
              },
            ],
          },
          {
            path: '*',
            component: RouteNotFound,
          },
        ],
      },
    ],
  },
]

export default createRoutes
