import React from 'react'
import Breadcrumbs from '../Breadcrumbs'
import InnerContainer from '../InnerContainer'
import s from './styles.css'
import HeadingWithPrint from '../../containers/HeadingWithPrint'
import PaymentMakeFromTo from '../../containers/PaymentMakeFromTo'
import PaymentMakeAccounts from '../../containers/PaymentMakeAccounts'
import PaymentMakePayees from '../../containers/PaymentMakePayees'
import PaymentMakeNewPayee from '../../containers/PaymentMakeNewPayee'
import PaymentMakeForm from '../../containers/PaymentMakeForm'
import PaymentMakeConfirm from '../../containers/PaymentMakeConfirm'
import Modal from '../../containers/ModalReceipt'
import Warnings from '../Warnings'

type PaymentMakeViewProps = {
  title?: string
  showAllIsVisible?: boolean
  accountSelectIsVisible?: boolean
  payeesAreVisible?: boolean
  currentStage: number
  formIsVisible?: boolean
  fromToIsVisible?: boolean
  printIsVisible?: boolean
  initialNumberShown?: number
  accountsAsLinks?: boolean
  mappingError?: string // todo - was string | boolean, was changed to match Warnings props
  onReceiptClickModal: () => void
  onReceiptClickSave: () => void
}

const PaymentMakeView = ({
  showAllIsVisible,
  currentStage,
  fromToIsVisible,
  accountSelectIsVisible,
  payeesAreVisible,
  formIsVisible,
  printIsVisible,
  title,
  onReceiptClickModal,
  onReceiptClickSave,
  accountsAsLinks = false,
  initialNumberShown = 3,
  mappingError, // todo - was default to false, but now will be undefined
}: PaymentMakeViewProps) => (
  <div className={s.root}>
    <Breadcrumbs
      items={[
        {
          to: '/payments',
          text: 'Payments',
        },
        {
          to: '/payments/from',
          text: 'Make a Payment',
        },
      ]}
    />

    <InnerContainer>
      <HeadingWithPrint
        title='Make a Payment'
        showPrint={printIsVisible}
        showReceipt={printIsVisible}
        onReceiptClick={() => {
          onReceiptClickSave()
          onReceiptClickModal()
        }}
      />
      {fromToIsVisible && <PaymentMakeFromTo />}
    </InnerContainer>

    {mappingError && (
      <InnerContainer>
        <Warnings items={[mappingError]} />
      </InnerContainer>
    )}

    {!mappingError && currentStage === 0 && (
      <PaymentMakeAccounts
        initialNumberShown={initialNumberShown}
        accountsAsLinks={accountsAsLinks}
      />
    )}
    {!mappingError && currentStage === 1 && <PaymentMakePayees />}
    {!mappingError && currentStage === 1.1 && <PaymentMakeNewPayee />}
    {!mappingError && currentStage === 2 && <PaymentMakeForm />}
    {!mappingError && currentStage > 2 && <PaymentMakeConfirm />}

    <Modal id='eReceipt' className={s.modal} size='small' />
  </div>
)

export default PaymentMakeView
