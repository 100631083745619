import { connect } from 'react-redux'
import MailDetail from '../../components/MailDetail'

export const mapStateToProps = (state, ownProps) => {
  const { folderName } = ownProps.params
  const detail = state.mail[folderName].items[ownProps.params.id]
  return {
    subject: detail.subject,
    date: detail.date,
    sender: detail.sender,
    body: detail.body,
    threadId: detail.threadId,
    id: detail.id,
    thread: detail.thread,
    customerId: detail.customerId,
    subjectCode: detail.subjectCode,
    canReply: folderName === 'inbox',
  }
}

export default connect(mapStateToProps)(MailDetail)
