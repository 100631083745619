import React from 'react'
import Breadcrumbs from '../../Breadcrumbs'
import BulletPoints from '../../BulletPoints'
import ContactContainer from '../../ContactContainer'
import InnerFullWidthContainer from '../../InnerFullWidthContainer'
import ServicesHeader from '../../ServicesHeader'
import s from '../../ServicesView/styles.css'

const addPadding = true
const listStyle = true

const InvestmentManager = () => (
  <div>
    <Breadcrumbs
      items={[
        {
          to: '/services',
          text: 'Additional services',
        },
        {
          to: '/services/investmentwealth',
          text: 'Investment & wealth advice',
        },
        {
          to: '/services/investmentmanager',
          text: 'Investment manager',
        },
      ]}
    />
    <ServicesHeader title='Investment manager' secondaryTitle='Invest Wisely' />
    <InnerFullWidthContainer addPadding={addPadding}>
      <div className={`${s.textArea} ${s.extraTopMargin}`}>
        <p>
          Choosing an actively managed fund comes with an important decision attached: selecting the
          right firm to manage your investments. We only recommend investment managers that meet our
          rigorous criteria for inclusion in our panel.
        </p>
      </div>
      <div className={`${s.textArea} ${s.extraTopMargin}`}>
        <p>As your adviser, we will:</p>
      </div>
      <BulletPoints
        listStyle={listStyle}
        items={[
          {
            bulletPoint: 'design an investment mandate for your chosen manager',
          },
          {
            bulletPoint: 'attend update meetings with your manager',
          },
          {
            bulletPoint: 'monitor your investment portfolio',
          },
          {
            bulletPoint: 'negotiate management fees on your behalf',
          },
          {
            bulletPoint: 'recommend a change of manager if necessary',
          },
        ]}
      />
      <div className={`${s.textArea} ${s.boldTextArea}`}>
        <p>
          Past performance is not a guide to future performance. The value of an investment and its
          income can both increase and decrease and you may not get back the full amount originally
          invested. The value of overseas investments will be influenced by the rate of exchange.
        </p>
      </div>
      <ContactContainer
        title='Find out more'
        infoText='For more information about our Investments & Wealth Advice service, please contact our expert team'
        items={[
          {
            linkTitle: '+44 (0)20 7292 9025',
            to: '+44 (0)20 7292 9025',
            telNumber: true,
          },
          // {
          //   linkTitle: 'Send Secure Message',
          //   to: '/secure-mail/new'
          // }
          {
            linkTitle: 'Send Secure Message',
            to: '/secure-mail/enquiry#investmentmanager',
          },
        ]}
      />
    </InnerFullWidthContainer>
  </div>
)

export default InvestmentManager
