export default (store) => ({
  path: 'services',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const ServicesView = require('../containers/Services').default
        cb(null, ServicesView)
      },
      'services'
    )
  },
})
