/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { ReactNode } from 'react'
import { Link } from 'react-router'
import s from './styles.css'
import Icon from '../Icon'
import ModalLink from '../../containers/ModalLink'
import SelectEntity from '../../containers/SelectEntity'
import Button from '../Button'
import Slidey from '../Slidey'

type NavItemProps = {
  children: ReactNode
  to: string
  icon?: string // todo - testing was renamed from glyph to icon
}

const NavItem = ({ icon, children, to }: NavItemProps) => (
  <li>
    <Link to={to} className={icon && s.mainLink} activeClassName={s.active}>
      {icon && <Icon icon={icon} />} {children}
    </Link>
  </li>
)

type SubNavItemProps = {
  children: ReactNode
  to: string
}

const SubNavItem = ({ children, to }: SubNavItemProps) => (
  <li>
    <Link to={to} activeClassName={s.active}>
      {children} <Icon icon='chevronRight' />
    </Link>
  </li>
)

type MobNavProps = {
  hasAuthorisations: boolean
  onSubMenuClick: () => void
  hasEntities?: boolean
  lastLogin?: string
  displayName?: string
  prefsIsOpen?: boolean
}

export const MobNav = ({
  hasEntities,
  lastLogin,
  displayName,
  prefsIsOpen,
  onSubMenuClick,
  hasAuthorisations,
}: MobNavProps) => {
  const prefsClass = prefsIsOpen ? s.prefsOpen : undefined
  return (
    <nav className={s.root}>
      <div className={s.inner}>
        <header className={s.header}>
          <h3>{displayName}</h3>
          {lastLogin && (
            <div>
              <h4>Last Login:</h4>
              <time>{lastLogin}</time>
            </div>
          )}
          {hasEntities && <SelectEntity />}
          <ModalLink
            className={s.logout}
            id='logOut'
            component={Button}
            text='Log Out'
            // eslint-disable-next-line react/style-prop-object
            style='secondary'
          />
        </header>
        <ul className={s.menu}>
          <NavItem to='/accounts' icon='home'>
            My Accounts
          </NavItem>
          <NavItem to='/payments' icon='payments'>
            Payments &amp; Transfers
          </NavItem>
          {/* <NavItem to='/services' glyph='services'>More Services</NavItem> */}
          <NavItem to='/secure-mail'>Secure mailbox</NavItem>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <li onClick={onSubMenuClick}>
            <a href='#' className={`${s.prefs} ${prefsClass}`}>
              Details &amp; Settings <Icon icon='chevronRight' />
            </a>
          </li>
          <li>
            <Slidey isOpen={prefsIsOpen}>
              <ul className={s.subMenu}>
                <SubNavItem to='/settings/alerts'>View &amp; set up alerts</SubNavItem>
                <SubNavItem to='/settings/manage-accounts'>
                  Reorder &amp; Rename Accounts
                </SubNavItem>
                {/* <SubNavItem to='/settings/paymentcompanies'>Your data sharing</SubNavItem> */}
                <SubNavItem to='/settings/manage-credentials/password'>Change password</SubNavItem>
                <SubNavItem to='/settings/manage-credentials/pin'>
                  Change Transaction PIN
                </SubNavItem>
                <SubNavItem to='/settings/activity-log'>Activity log</SubNavItem>
                {/* GDPR Modal */}
                <SubNavItem to='/settings/marketing-preferences'>Marketing preferences</SubNavItem>
                {hasAuthorisations && (
                  <SubNavItem to='/settings/authorisations'>Authorisations</SubNavItem>
                )}
              </ul>
            </Slidey>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default MobNav
