import React from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import SingleField from '../Forms/SingleField'
import Select from '../Forms/Select'

type FormProps = {
  accountSelectOptions?: any[]
}

type FieldProps = {
  account: string
}

const Form = ({ accountSelectOptions }: InjectedFormProps<FieldProps> & FormProps) => (
  <form onSubmit={(e) => e.preventDefault()}>
    <Field
      name='account'
      label='Account'
      component={SingleField}
      InputComponent={Select}
      inputComponentProps={{
        options: accountSelectOptions,
      }}
    />
  </form>
)

const ReduxForm = reduxForm<FieldProps, FormProps>({
  form: 'recurringPaymentsFilters',
})(Form)

export default ReduxForm
