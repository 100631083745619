export default (store) => ({
  path: 'payments',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const PaymentsView = require('../containers/Payments').default
        cb(null, PaymentsView)
      },
      'payments'
    )
  },
})
