import React, { ReactNode } from 'react'
import s from './styles.css'
import Button from '../Button'
import Slidey from '../Slidey'

type FilterPanelProps = {
  children?: ReactNode
  collapseForSmall?: boolean
  isOpen?: boolean
  onToggleClick?: () => void
}

export const FilterPanel = ({
  children,
  collapseForSmall = false,
  isOpen = true,
  onToggleClick,
}: FilterPanelProps) => (
  <div className={s.root}>
    <div className={s.inner}>
      {collapseForSmall ? (
        <div>
          <Button
            onClick={onToggleClick}
            text={isOpen ? 'Hide Filters' : 'Show Filters'}
            // eslint-disable-next-line react/style-prop-object
            style='ghost-light'
            size='expand'
            className={s.button}
          />
          <Slidey isOpen={isOpen}>
            <div className={s.children}>{children}</div>
          </Slidey>
        </div>
      ) : (
        children
      )}
    </div>
  </div>
)

export default FilterPanel
