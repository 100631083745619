import { getIsFiltered } from './selectors'

const tableFormat = {
  mapRowIdTo: 'id',
  cols: [
    {
      header: 'Date',
      headerHide: true,
      mapTextTo: 'date',
      showFor: 'medium',
      perc: 10,
    },
    {
      header: '',
      mapIconTo: 'transactionCode',
      iconMap: {
        DDX: 'directDebit',
      },
      showFor: 'medium',
      perc: 3,
    },
    {
      header: 'Description',
      headerHide: true,
      mapTextTo: 'title',
      mapDrawerTo: 'ref',
      wrap: true,
      perc: 45,
    },
    {
      header: 'Debit',
      mapTextTo: 'debit',
      showFor: 'large',
      perc: 12,
    },
    {
      header: 'Credit',
      mapTextTo: 'credit',
      showFor: 'large',
      perc: 12,
    },
    {
      header: 'Debit/Credit',
      mapTextTo: 'debitCredit',
      hideFor: 'large',
      perc: 15,
    },
    {
      header: 'Balance',
      mapTextTo: 'balance',
      showFor: 'medium',
      perc: 40,
    },
  ],
}

export default (state, accountId) => {
  const { group } = state.accounts.items[accountId]
  const { type } = state.accounts.items[accountId]
  const isFiltered = getIsFiltered(state)

  if (group !== 'lending' && type !== 'TD' && !isFiltered) {
    return tableFormat
  }
  return {
    ...tableFormat,
    cols: tableFormat.cols.filter((item) => item.mapTextTo !== 'balance'),
  }
}
