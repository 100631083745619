import React from 'react'
import Breadcrumbs from '../Breadcrumbs'
import BulletPoints from '../BulletPoints'
import ContactContainer from '../ContactContainer'
import InnerFullWidthContainer from '../InnerFullWidthContainer'
import ServicesHeader from '../ServicesHeader'
import s from '../ServicesView/styles.css'

const addPadding = true
const listStyle = true

export const ServicesInternational = (/* { mappingError } */) => (
  <div>
    <Breadcrumbs
      items={[
        {
          to: '/services',
          text: 'Additional services',
        },
        {
          to: '/services/International',
          text: 'International accounts',
        },
      ]}
    />
    <ServicesHeader
      title='Currency management without borders'
      secondaryTitle='Move your money safely around the world'
    />
    <InnerFullWidthContainer addPadding={addPadding}>
      <div className={`${s.textArea} ${s.extraTopMargin}`}>
        <p>
          Our foreign exchange services give you the power to move money around the world
          efficiently, cost-effectively and with minimal risk. We specialise in:
        </p>
      </div>
      <BulletPoints
        listStyle={listStyle}
        items={[
          {
            bulletPoint: 'foreign exchange trading',
          },
          {
            bulletPoint: 'hedging solutions',
          },
          {
            bulletPoint: 'onward global funds transmission services',
          },
        ]}
      />
      <ContactContainer
        title='Find out more'
        infoText='To find out more about our Foreign Exchange services, please contact the Weatherbys FX Team'
        items={[
          {
            linkTitle: '+44 (0)1933 304849',
            to: '++44 (0)1933 304849',
            telNumber: true,
          },
          // {
          //   linkTitle: 'Send Secure Message',
          //   to: '/secure-mail/new'
          // }
          {
            linkTitle: 'Send Secure Message',
            to: '/secure-mail/enquiry#international',
          },
        ]}
      />
    </InnerFullWidthContainer>
  </div>
)

export default ServicesInternational
