import React from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import SingleField from '../Forms/SingleField'
import { renameAccount } from '../../containers/ManageAccounts/actions'
import { FormProps } from './types'

type FormFieldsProps = {
  accountName: string
}

const Form = ({
  submitting,
  error,
  invalid,
  handleSubmit,
  save,
}: InjectedFormProps<FormFieldsProps> & FormProps) => (
  <form onSubmit={handleSubmit(save)}>
    <Field name='accountName' component={SingleField} type='text' />
  </form>
)

const ReduxForm = reduxForm<FormFieldsProps, FormProps>({
  form: 'account-edit-single',
  enableReinitialize: true,
  onSubmit: renameAccount,
})(Form)

export default ReduxForm
