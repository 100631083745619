import get from 'lodash/get'
import moment from 'moment'
import { decodeHTML } from '../../utils/decodeHTML'

/**
 * Tidies deeply nested and poorly named accounts data from API into something
 * easier to deal with!
 * @param  {Object} data - raw data from API
 * @return {Object} data - tidied data
 */
export interface TidyMailD {
  data: {
    mails: {
      messageId: {
        value: string
      }
      party: {
        value: string
      }
      messageUserMappings: {
        mapId: string
        status: string
        msgFlag: string
      }[]
      senderName: string
      subject: string
      subjectId: string
      creationDate: string
      messageBody: string
      interactionId: string
    }[]
  }
}
export default (data: TidyMailD) => {
  const tidyData = { items: {} }

  let mail = get(data, 'data.mails')
  if (mail) {
    if (!(mail instanceof Array)) {
      mail = [mail]
    }
    mail.reverse().forEach((item) => {
      tidyData.items[item.messageId.value] = {
        id: String(item.messageId.value),
        threadId: String(item.messageUserMappings[0].mapId),
        sender: item.senderName,
        subject: decodeHTML(item.subject),
        subjectId: item.subjectId,
        isRead: item.messageUserMappings[0].status === 'R',
        date: moment(item.creationDate, 'YYYY-MM-DD HH:mm:ss').format('dddd, Do MMMM YYYY - HH:mm'),
        folderName: item.messageUserMappings[0].msgFlag === 'F' ? 'outbox' : 'inbox',
        timeStamp: parseInt(moment(item.creationDate, 'YYYY-MM-DD HH:mm:ss').format('X')),
        interactionId: item.interactionId,
        body: decodeHTML(item.messageBody),
        party: item.party.value,
      }
    })
  }

  return tidyData
}
