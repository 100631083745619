import get from 'lodash/get'
import moment from 'moment'
import formatMoney from '../formatMoney'
import { decodeHTML } from '../decodeHTML'
// todo later - totalrejected records key causing problem
/*
data: {
  totalCount: number,
  recordDetails:
  {
    version: number,
    generatedPackageId: boolean,
    auditSequence: number,
    fileRefId: string,
    recRefId: string,
    totalrejectedrecords?: number,
    currencyAmount: {
      currency: string,
      amount: string | number,
    },
    valueDate: string,
    debitAccountId: string,
    creditAccountId: string | number,
    beneficiaryName: string,
    status: string,
    paymentType: string,
    sortCode: string
  }[]
  |
  {
    totalrejectedrecords?: number,
    totalrejectamount?: string | number
  } 
} */
export default (data, state) => {
  const tidy: any = {}

  const { realToMasked } = state.accounts

  const main = get(data, 'data.recordDetails', [])
  if (main.length === 0) return { items: { status: 'error' } }
  const tidyDetails = main.map((item) => ({
    name: decodeHTML(item.beneficiaryName),
    amount: formatMoney(item.currencyAmount.amount, item.currencyAmount.currency),
    amountPure: item.currencyAmount.amount,
    accountDetails: `${item.sortCode} - ${item.creditAccountId}`,
    date: moment(item.valueDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
    statusCode: item.status,
    status: item.status,
    id: item.recRefId,
  }))

  tidy.details = tidyDetails

  const summary = data.data.recordDetails
  const totalAmount = main.reduce((total, item) => total + item.currencyAmount.amount, 0)
  tidy.pureAmount = totalAmount
  tidy.amount = formatMoney(totalAmount, main[0].currencyAmount.currency)
  tidy.records = data.data.totalCount
  tidy.amountRejected = formatMoney(summary.totalrejectamount, main[0].currencyAmount.currency)
  tidy.recordsRejected = summary.totalrejectrecords ? summary.totalrejectedrecords : 0
  tidy.date = moment(main[0].valueDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
  if (main[0].debitAccountId.includes('@~')) {
    tidy.accountId = realToMasked[main[0].debitAccountId.split('@~')[1]]
  } else {
    tidy.accountId = realToMasked[main[0].debitAccountId]
  }
  return {
    items: tidy,
  }
}
