import React from 'react'
import { reduxForm, Field, InjectedFormProps } from 'redux-form'
import BottomError from '../Forms/BottomError'
import Modal from '../../containers/Modal'
import SubmitCancel from '../Forms/SubmitCancel'
import DatePicker from '../../containers/DatePicker'
import FileInput from '../Forms/FileInput'
import SingleField from '../Forms/SingleField'
import * as v from '../../utils/forms/validators'
import s from './styles.css'

type UploadModalProps = {
  handleSubmit?: () => void // todo - was made optional as PaymentBulkView/View.tsx is not passing it
  onFileVerify: () => void
  submitting?: boolean
  show3pmMessage?: boolean
  error?: string
  minDate: any
}

type FieldProps = {
  date: string
  file: string
}

const UploadModal = ({
  submitting,
  handleSubmit,
  onFileVerify,
  minDate,
  error,
  show3pmMessage,
}: InjectedFormProps<FieldProps> & UploadModalProps) => (
  <Modal id='bulkUploadFile' size='medium' title='Upload bulk file'>
    <form onSubmit={handleSubmit(onFileVerify)}>
      <Field
        name='date'
        label='Payment Date'
        component={SingleField}
        InputComponent={DatePicker}
        inputComponentProps={{
          minDate,
        }}
        validate={v.required}
        validationMessages={{
          required: 'On what date should the transaction happen?',
        }}
      />
      <p>
        <em>Payments will only be made on working days</em>
      </p>
      {show3pmMessage && (
        <p className={s.after3pmMessage}>
          The Deadline for the Submission of BACS payments is 3pm. Any payments received or
          authorised after this time will be submitted on the following working day
        </p>
      )}
      <div className={s.fileField}>
        <Field
          name='file'
          label='File'
          subLabel={
            <span>
              Remember to use the{' '}
              <a download href='bulk-upload-XLS.xls'>
                Excel
              </a>{' '}
              or&nbsp;
              <a download href='bulk-upload-CSV.txt'>
                CSV
              </a>{' '}
              template.
            </span>
          }
          component={SingleField}
          InputComponent={FileInput}
          inputComponentProps={{ accept: '.txt, .xls, .csv' }}
          validate={v.required}
          validationMessages={{
            required: 'On what date should the transaction happen?',
          }}
        />
      </div>
      <BottomError message={error} />
      <SubmitCancel
        submitText='Next'
        cancelText='Cancel'
        submitting={submitting}
        cancelClosesModal
      />
    </form>
  </Modal>
)

const UploadModalReduxForm = reduxForm<FieldProps, UploadModalProps>({
  form: 'bulkUploadFile',
})(UploadModal)

export default UploadModalReduxForm
