import React from 'react'
import VirtualList from 'react-virtual-list'
import TableRow from '../TableRow'

type ListProps = {
  itemHeight: number
  items: any[]
}

const List = ({ itemHeight, items, ...rest }: ListProps) => (
  <tbody>
    {items.map((row) => (
      <TableRow
        {...rest}
        key={row.id}
        id={row.id}
        cols={row.cols}
        currentRow={row}
        height={itemHeight}
      />
    ))}
  </tbody>
)

const Virtualized = VirtualList()(List)

export default Virtualized
