import { combineReducers } from 'redux'
import * as c from '../../constants'
import createListReducer, { ListState } from '../../reducerCreators/listReducer'

export interface IPayeeFiltersState {
  alphabet?: string
}

export const payeeFiltersReducer = (state: IPayeeFiltersState = {}, action): IPayeeFiltersState => {
  const p = action.payload
  switch (action.type) {
    case c.PAYEES_FILTER_ALPHABET: {
      return {
        ...state,
        alphabet: p.charSet,
      }
    }
    default:
      return state
  }
}

export interface IPayeesState {
  list: ListState
  filters: IPayeeFiltersState
}

export default combineReducers<IPayeesState, any>({
  list: createListReducer('payees'),
  filters: payeeFiltersReducer,
})
