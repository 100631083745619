import React from 'react'
import Breadcrumbs from '../Breadcrumbs'
import ActionHeader from '../ActionHeader'
import InnerContainer from '../InnerContainer'
import TypesTable from '../../containers/ActivityLogTypesList'

export const ActivityLog = () => (
  <div>
    <Breadcrumbs
      items={[
        {
          to: '/settings',
          text: 'Details & settings',
        },
        {
          to: '/settings/activity-log',
          text: 'Activity log',
        },
      ]}
    />

    <InnerContainer>
      <ActionHeader title='Activity log' />
      <TypesTable />
    </InnerContainer>
  </div>
)

export default ActivityLog
