/* eslint-disable */
import React from 'react'
import { Link } from 'react-router'
import Button from '../../Button'
import CloseModalLink from '../../../containers/CloseModalLink'
import s from './styles.css'
import LoadingSpinner from '../../LoadingSpinner'

type SubmitCancelProps = {
  submitText?: string
  showSubmit?: boolean
  cancelText?: string
  onCancelClick?: () => void
  cancelClosesModal?: boolean
  submitClosesModal?: boolean
  submitting?: boolean
  cancelTo?: string
  submitDisabled?: boolean
  onSubmitClick?: () => void
  cancelDisabled?: boolean
  cancelling?: boolean
  refreshWarning?: string
  cancelLoaderText?: string
}

const SubmitCancel = ({
  submitText = 'Submit',
  cancelText = 'Cancel',
  showSubmit = true,
  onSubmitClick,
  submitDisabled,
  submitClosesModal = false,
  onCancelClick,
  cancelTo,
  cancelClosesModal,
  submitting,
  cancelDisabled,
  cancelling,
  refreshWarning,
  cancelLoaderText,
}: SubmitCancelProps) => {
  const isSubmitButton = onSubmitClick === undefined

  const submitButton = (
    <Button
      className={s.button}
      submit={isSubmitButton}
      text={submitText}
      loading={submitting}
      disabled={submitDisabled}
      onClick={onSubmitClick}
    />
  )

  const cancelOptions = () => {
    if (cancelling && cancelLoaderText) {
      return <LoadingSpinner text={cancelLoaderText} />
    }

    return cancelClosesModal ? (
      <CloseModalLink>{cancelText}</CloseModalLink>
    ) : cancelTo ? (
      <Link to={cancelTo}>{cancelText}</Link>
    ) : (
      <a href='#' onClick={onCancelClick}>
        {cancelText}
      </a>
    )
  }

  return (
    <div>
      <div className={`${s.root} ${submitDisabled || cancelDisabled ? s.nonClickable : ''}`}>
        {showSubmit && submitClosesModal ? (
          <CloseModalLink>{submitButton}</CloseModalLink>
        ) : (
          submitButton
        )}
        {cancelOptions()}
      </div>
      {refreshWarning && (submitting || cancelling) && (
        <div className={s.refreshWarningText}>{refreshWarning}</div>
      )}
    </div>
  )
}

export default SubmitCancel
