import React from 'react'
import ReactDatePicker from 'react-datepicker'
import moment from 'moment'
import s from './styles.css'
import Icon from '../../Icon'

type DatePickerProps = {
  input: { value: string; onChange: () => void } // todo - testing on onChange
  startDate?: string | moment.Moment
  endDate?: string | moment.Moment
  selectsStart?: boolean
  selectsEnd?: boolean
  mobile?: boolean
  minDate?: moment.Moment
  maxDate?: moment.Moment
}

export const DatePicker = ({
  input,
  minDate,
  maxDate,
  startDate,
  endDate,
  selectsStart,
  selectsEnd,
  mobile = false,
}: DatePickerProps) => {
  const otherProps = mobile ? { popperPlacement: 'right' } : {}
  return (
    <div className={s.root}>
      <ReactDatePicker
        {...input}
        startDate={startDate ? moment(startDate, 'DD/MM/YY') : null}
        endDate={endDate ? moment(endDate, 'DD/MM/YY') : null}
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        placeholderText='DD/MM/YY'
        dateFormat='DD/MM/YY'
        locale='en-gb'
        minDate={minDate}
        maxDate={maxDate}
        selected={input.value ? moment(input.value, 'DD/MM/YY') : null}
        fixedHeight
        {...otherProps}
      />
      {!mobile && (
        <div className={s.icon}>
          <Icon icon='calendar' />
        </div>
      )}
    </div>
  )
}

export default DatePicker
