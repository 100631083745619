/* eslint-disable */
// @ts-nocheck
// todo - eslint and typescript
import React from 'react'
import s from './styles.css'
import AccountPermissionsCard from './AccountPermissionsCard'
import Button from '../Button'
import AccountTabs from './AccountTabs'
import AccountTabsMob from './AccountTabsMob'
import cloneDeep from 'lodash/cloneDeep'
import SelectedAccountName from './SelectedAccountName'
import { MakeRequest } from './MakeRequest'
import goOut from '../../utils/goOut'
import LoadingSpinner from '../LoadingSpinner'

type AccountPermissionsProps = {
  onSuccess: () => void
  changeCurrentView: () => void
  selectedAccessPointId: string
  selectedEntity: string
}

let Request = new MakeRequest()
class AccountPermissions extends React.Component<AccountPermissionsProps> {
  constructor(props: AccountPermissionsProps | Readonly<AccountPermissionsProps>) {
    super(props)
    this.state = {
      permissionData: {},
      accessButtonDisabled: true,
      defaultView: 'edit',
      defaultTabState: 'select',
      data: {},
      mode: 'POST',
      putData: {},
      loading: true,
      availabilityInfo: {},
    }
  }

  onCheckboxClick = (name, type, accounttNumber) => {
    let permissionDataNew = JSON.parse(JSON.stringify(this.state.permissionData))
    if (permissionDataNew[name] && accounttNumber) {
      permissionDataNew[name].accountNumber = accounttNumber
    }
    if (permissionDataNew[name] && permissionDataNew[name].hasOwnProperty(type)) {
      if (permissionDataNew[name][type]) {
        permissionDataNew[name][type] = false
      } else {
        permissionDataNew[name][type] = true
      }
    } else {
      if (permissionDataNew[name]) {
        permissionDataNew[name][type] = true
      } else {
        permissionDataNew[name] = {}
        permissionDataNew[name][type] = true
      }
    }
    this.setState({ permissionData: permissionDataNew }, () => {
      this.shouldButtonDisabled()
    })
  }

  shouldButtonDisabled = () => {
    let data = JSON.stringify(this.state.permissionData)
    this.setState({ accessButtonDisabled: data.indexOf(true) === -1 })
  }

  provideAccessAction = () => {
    this.setState({ defaultView: 'view' }, () => {
      this.props.changeCurrentView('view')
    })
  }
  selectAll = () => {
    let newPermissionData = cloneDeep(this.state.permissionData)
    for (let key in newPermissionData) {
      newPermissionData[key].paymentTransfers = true
      newPermissionData[key].accountEnquiries = true
    }
    this.setState({ permissionData: newPermissionData }, () => {
      this.setState({ defaultTabState: 'deselect' }, () => {
        this.shouldButtonDisabled()
      })
    })
  }
  deselectAll = () => {
    let newPermissionData = cloneDeep(this.state.permissionData)
    for (let key in newPermissionData) {
      newPermissionData[key].paymentTransfers = false
      newPermissionData[key].accountEnquiries = false
    }
    this.setState({ permissionData: newPermissionData }, () => {
      this.setState({ defaultTabState: 'select' }, () => {
        this.shouldButtonDisabled()
      })
    })
  }
  confirmAction = () => {
    let { data, putData, mode, permissionData } = this.state
    let { selectedAccessPointId, selectedEntity } = this.props
    let putDataCopy = cloneDeep(putData)
    if (mode === 'PUT') {
      let accountExclusionDTOs = []
      for (let key in permissionData) {
        let tempObj2 = {
          accountNumber: {
            displayValue: permissionData[key].accountNumber || '',
            value: key,
          },
          taskIds: [],
        }
        if (permissionData[key].paymentTransfers) {
          tempObj2.taskIds.push('PC_F_SELF')
          tempObj2.taskIds.push('PC_F_CGNDP')
        }
        if (permissionData[key].accountEnquiries) {
          tempObj2.taskIds.push('CH_I')
        }
        putDataCopy.accessPointAccountDTOs.forEach((item) => {
          if (item.accessPointId === selectedAccessPointId && item.accountType === 'CSA') {
            item.accountExclusionDTOs.forEach((inner) => {
              if (inner.accountNumber.value === key) {
                tempObj2.accountExclusionId = inner.accountExclusionId
              }
            })
          }
        })
        if (tempObj2.taskIds.length > 0) {
          accountExclusionDTOs.push(tempObj2)
        }
      }
      putDataCopy.accessPointAccountDTOs.forEach((item) => {
        if (item.accessPointId === selectedAccessPointId && item.accountType === 'CSA') {
          item.accountExclusionDTOs = accountExclusionDTOs
        }
      })

      let dataToSend = {
        accessPointAccountDTOs: putDataCopy.accessPointAccountDTOs,
      }

      delete dataToSend.accessPointAccountDTOs[0].party

      Request.post('/digx/v1/session/nonce', {}).then((res) => {
        let nonce = null
        let nonceAvailable = false
        for (let pair of res.headers.entries()) {
          if (pair[0] === 'x-nonce') {
            nonce = JSON.parse(pair[1]).nonce[0]
            nonceAvailable = true
            Request.put('/digx/v1/me/accessPointAccount', dataToSend, nonce, selectedEntity)
              .then((response) => response.json())
              .then((res) => {
                this.props.onSuccess()
              })
              .catch((error) => {
                console.log(error)
              })

            break
          }
        }
        if (!nonceAvailable) {
          goOut('https://www.weatherbys.bank/sessionexpired')
        }
      })
    } else {
      let postData = { accessPointAccountDTOs: [] }
      let tempObj = {
        accessPointId: selectedAccessPointId,
        accountType: 'CSA',
        accessLevel: 'ACCESSPOINT',
        accessStatus: false,
        accountExclusionDTOs: [],
      }
      data.accounts &&
        data.accounts[0] &&
        data.accounts[0].accountsList &&
        data.accounts[0].accountsList.forEach((item) => {
          if (item.accountType === 'CSA') {
            let tempObj1 = {}
            let tempTaskArray = []
            if (permissionData[item.accountNumber.value].paymentTransfers) {
              tempTaskArray.push('PC_F_SELF')
              tempTaskArray.push('PC_F_CGNDP')
            }
            if (permissionData[item.accountNumber.value].accountEnquiries) {
              tempTaskArray.push('CH_I')
            }
            tempObj1.accountNumber = {
              displayValue: item.accountNumber.displayValue,
              value: item.accountNumber.value,
            }
            tempObj1.taskIds = tempTaskArray
            if (tempTaskArray.length > 0) {
              tempObj.accountExclusionDTOs.push(tempObj1)
            }
          }
        })
      postData.accessPointAccountDTOs.push(tempObj)

      Request.post('/digx/v1/session/nonce', {}).then((res) => {
        let nonce = null
        let nonceAvailable = false
        for (let pair of res.headers.entries()) {
          if (pair[0] === 'x-nonce') {
            nonce = JSON.parse(pair[1]).nonce[0]
            nonceAvailable = true
            Request.post(
              '/digx/v1/me/accessPointAccount?locale=en',
              postData,
              nonce,
              selectedEntity
            )
              .then((response) => response.json())
              .then((res) => {
                this.props.onSuccess()
              })
              .catch((error) => {
                console.log(error)
              })

            break
          }
        }
        if (!nonceAvailable) {
          goOut('https://www.weatherbys.bank/sessionexpired')
        }
      })
    }
  }

  componentDidMount() {
    let { selectedAccessPointId, selectedEntity } = this.props
    let permissionDataCopy = {}
    let { availabilityInfo } = this.state
    let availabilityInfoNew = JSON.parse(JSON.stringify(availabilityInfo))
    Request.post('/digx/v1/session/nonce', {}).then((res) => {
      let nonce = null
      let nonceAvailable = false
      for (let pair of res.headers.entries()) {
        if (pair[0] === 'x-nonce') {
          nonce = JSON.parse(pair[1]).nonce[0]
          nonceAvailable = true
          Request.get(
            `/digx/v1/me/accessPointAccount?accessPointId=${selectedAccessPointId}&accountType=CSA`,
            nonce,
            selectedEntity
          )
            .then((response) => response.json())
            .then((response1) => {
              this.setState({ data: response1 }, () => {
                if (
                  response1 &&
                  response1.accounts &&
                  response1.accounts[0] &&
                  response1.accounts[0].setupInformation === 'SETUP_EXISTS' &&
                  response1.accounts[0].accessLevel === 'ACCESSPOINT'
                ) {
                  response1.accounts[0].accountsList.forEach((item, index) => {
                    permissionDataCopy[item.accountNumber.value] = {}
                    permissionDataCopy[item.accountNumber.value].paymentTransfers = false
                    permissionDataCopy[item.accountNumber.value].accountEnquiries = false
                    availabilityInfoNew[item.accountNumber.value] = {}
                    availabilityInfoNew[item.accountNumber.value].paymentTransfers = false
                    availabilityInfoNew[item.accountNumber.value].accountEnquiries = false

                    item.tasks.forEach((taskItem, index) => {
                      if (taskItem.name === 'Payments' || taskItem.name === 'Make A Payment') {
                        taskItem.childTasks.forEach((innerTaskItem, index) => {
                          if (innerTaskItem.isAllowed) {
                            permissionDataCopy[item.accountNumber.value].paymentTransfers = true
                          }
                          if (
                            innerTaskItem.childTask.id === 'PC_F_CGNDP' ||
                            innerTaskItem.childTask.id === 'PC_F_SELF'
                          ) {
                            availabilityInfoNew[item.accountNumber.value].paymentTransfers = true
                          }
                        })
                      } else {
                        taskItem.childTasks.forEach((innerTaskItem, index) => {
                          if (innerTaskItem.isAllowed) {
                            permissionDataCopy[item.accountNumber.value].accountEnquiries = true
                          }

                          if (innerTaskItem.childTask.id === 'CH_I') {
                            availabilityInfoNew[item.accountNumber.value].accountEnquiries = true
                          }
                        })
                      }
                    })
                  })

                  Request.post('/digx/v1/session/nonce', {}).then((res) => {
                    let nonce = null
                    let nonceAvailable = false
                    for (let pair of res.headers.entries()) {
                      if (pair[0] === 'x-nonce') {
                        nonce = JSON.parse(pair[1]).nonce[0]
                        nonceAvailable = true
                        Request.get(
                          `/digx/v1/me/accessPointAccount/${selectedAccessPointId}?locale=en`,
                          nonce,
                          selectedEntity
                        )
                          .then((response) => response.json())
                          .then((resp) => {
                            this.setState(
                              {
                                putData: resp,
                                permissionData: permissionDataCopy,
                                mode: 'PUT',
                                loading: false,
                                availabilityInfo: availabilityInfoNew,
                              },
                              () => {
                                this.shouldButtonDisabled()
                              }
                            )
                          })
                          .catch((error) => {
                            console.log(error)
                          })

                        break
                      }
                    }
                    if (!nonceAvailable) {
                      goOut('https://www.weatherbys.bank/sessionexpired')
                    }
                  })
                } else {
                  if (
                    response1 &&
                    response1.accounts &&
                    response1.accounts[0] &&
                    response1.accounts[0].setupInformation === 'SETUP_EXISTS' &&
                    response1.accounts[0].accessLevel === 'USER'
                  ) {
                    response1.accounts[0].accountsList.forEach((item, index) => {
                      permissionDataCopy[item.accountNumber.value] = {}
                      permissionDataCopy[item.accountNumber.value].paymentTransfers = false
                      permissionDataCopy[item.accountNumber.value].accountEnquiries = false
                      availabilityInfoNew[item.accountNumber.value] = {}
                      availabilityInfoNew[item.accountNumber.value].paymentTransfers = false
                      availabilityInfoNew[item.accountNumber.value].accountEnquiries = false

                      item.tasks.forEach((taskItem, index) => {
                        if (taskItem.name === 'Payments' || taskItem.name === 'Make A Payment') {
                          taskItem.childTasks.forEach((innerTaskItem, index) => {
                            if (
                              innerTaskItem.childTask.id === 'PC_F_CGNDP' ||
                              innerTaskItem.childTask.id === 'PC_F_SELF'
                            ) {
                              availabilityInfoNew[item.accountNumber.value].paymentTransfers = true
                            }
                          })
                        }
                        if (taskItem.name === 'All Inquiry Transactions') {
                          taskItem.childTasks.forEach((innerTaskItem, index) => {
                            if (innerTaskItem.childTask.id === 'CH_I') {
                              availabilityInfoNew[item.accountNumber.value].accountEnquiries = true
                            }
                          })
                        }
                      })
                    })
                  }

                  this.setState(
                    {
                      permissionData: permissionDataCopy,
                      mode: 'POST',
                      loading: false,
                      availabilityInfo: availabilityInfoNew,
                    },
                    () => {
                      this.shouldButtonDisabled()
                    }
                  )
                }
              })
            })
            .catch((error) => {
              console.log(error)
            })

          break
        }
      }
      if (!nonceAvailable) {
        goOut('https://www.weatherbys.bank/sessionexpired')
      }
    })
  }

  render() {
    const {
      permissionData,
      accessButtonDisabled,
      defaultView,
      defaultTabState,
      data,
      loading,
      availabilityInfo,
    } = this.state
    return (
      <span>
        {defaultView === 'edit' && (
          <AccountTabs
            deselectAll={this.deselectAll}
            selectAll={this.selectAll}
            defaultTabState={defaultTabState}
          />
        )}
        {defaultView === 'edit' && (
          <AccountTabsMob
            deselectAll={this.deselectAll}
            selectAll={this.selectAll}
            defaultTabState={defaultTabState}
          />
        )}
        {defaultView === 'view' && <SelectedAccountName accountName={'Current Account'} />}
        {loading && (
          <div className={s.psdSpinner}>
            <LoadingSpinner text={'Loading...'} />
          </div>
        )}
        {!loading && (
          <div className={s.AccountPermissionsDiv}>
            {data.accounts && data.accounts[0] && data.accounts[0].accountsList.length === 0 && (
              <div>No accounts available.</div>
            )}

            {(!data.accounts || !data.accounts[0] || !data.accounts[0].accountsList) && (
              <div>No accounts available.</div>
            )}

            {data.accounts &&
              data.accounts[0].setupInformation &&
              data.accounts[0].setupInformation !== 'SETUP_EXISTS' && (
                <div>Setup for selected TPP does not exist</div>
              )}
            {data.accounts &&
              data.accounts[0].setupInformation &&
              data.accounts[0].setupInformation === 'SETUP_EXISTS' &&
              data.accounts[0].accountsList.map((item, index) => {
                return (
                  <AccountPermissionsCard
                    key={item.accountNumber.displayValue}
                    onCheckboxClick={this.onCheckboxClick}
                    accountNumber={item.accountNumber.displayValue}
                    accountName={item.displayName}
                    defaultView={defaultView}
                    name={item.accountNumber.value}
                    permissionData={permissionData[item.accountNumber.value] || {}}
                    availabilityInfo={availabilityInfo[item.accountNumber.value] || {}}
                  />
                )
              })}
            <span className={s.psdProvideAccess}>
              {defaultView === 'edit' && (
                <Button
                  text={'Provide Access'}
                  disabled={
                    accessButtonDisabled ||
                    (data.accounts &&
                      data.accounts[0] &&
                      data.accounts[0].accountsList.length === 0)
                  }
                  onClick={this.provideAccessAction}
                />
              )}
              {defaultView === 'view' && (
                <Button
                  text={'Confirm'}
                  disabled={accessButtonDisabled}
                  onClick={this.confirmAction}
                />
              )}
              {defaultView === 'view' && (
                <span
                  className={s.psdAmend}
                  onClick={() => {
                    this.setState({ defaultView: 'edit' }, () => {
                      this.props.changeCurrentView('edit')
                    })
                  }}
                >
                  Amend
                </span>
              )}
            </span>
          </div>
        )}
      </span>
    )
  }
}

export default AccountPermissions
