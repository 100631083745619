import { createFormAction } from 'redux-form-saga'
import * as c from '../../constants'

export const send = createFormAction('ENQUIRY')

export function mailListFetchRequest(folderName: string) {
  return {
    type: c.MAIL_LIST_FETCH_REQUEST,
    payload: {
      folderName,
    },
  }
}

export function mailDetailFetchRequest(id: string | number, folderName: string) {
  return {
    type: c.MAIL_DETAIL_FETCH_REQUEST,
    payload: {
      id,
      folderName,
    },
  }
}

export function mailBulletinsFetch() {
  return {
    type: c.MAIL_BULLETINS_FETCH,
  }
}

export function mailListFetchFail() {
  return {
    type: c.MAIL_LIST_FETCH_FAIL,
  }
}
export interface ImailListUpdate {
  [key: string]: {
    text: string
  }
}
export function mailListUpdate(folderName: string, items: ImailListUpdate) {
  return {
    type: c.MAIL_LIST_UPDATE,
    payload: {
      folderName,
      items,
    },
  }
}
export interface ImailDetailUpdate {
  [key: string]: string
}
export function mailDetailUpdate(id: string, folderName: string, detail: ImailDetailUpdate) {
  return {
    type: c.MAIL_DETAIL_UPDATE,
    payload: {
      folderName,
      id,
      detail,
    },
  }
}
