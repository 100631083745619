import { connect } from 'react-redux'
import { reset } from 'redux-form'
import AccountServicesView from '../../components/AccountServicesView'
import { flowUpdateData, flowReset } from '../../store/flow'
import { modalShow } from '../../store/ui'
import { getAccountServicesVisibility } from './selectors'
import getIsAccountMappingKnown from '../../selectors/getIsAccountMappingKnown'

export const mapStateToProps = (state, ownProps) => {
  const visibility = getAccountServicesVisibility(state)

  return {
    viewIsLoading:
      !getIsAccountMappingKnown(state, 'chequeBooks') ||
      !getIsAccountMappingKnown(state, 'payInBooks') ||
      !getIsAccountMappingKnown(state, 'chequeStatus'),
    chequeBooksIsVisible: visibility.chequeBooks,
    payInBooksIsVisible: visibility.payInBooks,
    chequeStatusIsVisible: visibility.chequeStatus,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const handleClick = (serviceType) => {
    dispatch(reset('accServ'))
    dispatch(flowReset())
    dispatch(
      flowUpdateData({
        serviceType,
      })
    )
    dispatch(modalShow('accServ'))
  }

  return {
    onOrderPayInBookClick: () => {
      handleClick('payInBooks')
    },
    onOrderChequeBookClick: () => {
      handleClick('chequeBooks')
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountServicesView)
