import { connect } from 'react-redux'
import FlowConfirm from '../../components/FlowConfirm'
import { showPaymentCutoffModal, hidePaymenCutoffModal } from '../PaymentMake/actions'
import { DIGXErrorCodes } from '../../models/enum'
import { getExistingPayees, getToId } from '../PaymentMake/selectors'

const mapStateToProps = (state, ownProps) => {
  return {
    authIsVisible: state.twoFactorAuth.ref !== undefined,
    goBackLinkIsVisible: state.twoFactorAuth.stepTwo !== true,
    confirmIsVisible: state.twoFactorAuth.ref === undefined,
    success: ownProps.successShouldBePassed
      ? ownProps.success
      : ownProps.success || state.twoFactorAuth.success,
    isCutOffPayment:
      state.paymentMake.requestParams?.data?.status?.message?.code === DIGXErrorCodes.DIGX_WW_017,
    cutoffMessage: state.paymentMake.requestParams?.data?.status?.message?.detail || '',
    showPaymentCutoffModal: state.paymentMake.showPaymentCutoffModal || false,
    copStatus: state.cop.status,
    verificationReport: getExistingPayees(state)[getToId(state)]?.verificationReport,
  }
}

export const mapDispatchToProps = (dispatch) => ({
  showPaymentCutoffModalFunc: (e) => {
    e.preventDefault()
    dispatch(showPaymentCutoffModal())
  },
  hidePaymentCutoffModalFunc: (e) => {
    e.preventDefault()
    dispatch(hidePaymenCutoffModal())
  },
})
export default connect(mapStateToProps, mapDispatchToProps)(FlowConfirm)
