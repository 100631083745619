import React from 'react'
import { Field, reduxForm, Form, InjectedFormProps } from 'redux-form'
import InnerContainer from '../InnerContainer'
import SingleField from '../Forms/SingleField'
import * as v from '../../utils/forms/validators'
import MoneyFormHelper from '../formHelpers/MoneyFormHelper'
import Button from '../Button'
import BottomError from '../Forms/BottomError'
import PaymentDateOptions from '../../containers/PaymentDateOptions'
import ScrollTo from '../../containers/ScrollTo'
import Modal from '../../containers/Modal'
import SubmitCancel from '../Forms/SubmitCancel'
import s from './styles.css'
import COPSuccessIcon from '../../assets/icons/COPSuccessIcon'
import { ConfirmationOfPayeeStatus } from '../../containers/ConfirmationOfPayee/reducer'

type PaymentMakeFormProps = {
  currency: string
  handleSubmit: () => void
  paymentVerify: () => void
  error?: string
  invalid?: boolean
  submitting?: boolean
  lastReference?: string
  onClickLastReference?: (value: string) => void
  isSubmitDisabled?: boolean
  hasReference?: boolean
  onSubmitButtonClick?: () => void
  checkReference?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, v2: boolean) => void
  copStatus?: ConfirmationOfPayeeStatus
}

const PaymentMakeForm = ({
  currency,
  handleSubmit,
  paymentVerify,
  error,
  invalid,
  submitting,
  lastReference,
  onClickLastReference,
  isSubmitDisabled,
  hasReference,
  onSubmitButtonClick,
  checkReference,
  copStatus,
}: InjectedFormProps<any> & PaymentMakeFormProps) => (
  <InnerContainer>
    <ScrollTo>
      <h2>Payment details</h2>
    </ScrollTo>
    <Form onSubmit={handleSubmit(paymentVerify)}>
      {copStatus === ConfirmationOfPayeeStatus.SUCCESS && (
        <div className='narrow'>
          <div className={s.successDiv}>
            <div className={s.iconDiv}>
              <COPSuccessIcon className={s.successIcon} />
            </div>
            <div className={s.successTextDiv}>
              <div className={s.successHeader}>Account confirmed</div>
              <div className={s.successSubHeader}>All details match</div>
            </div>
          </div>
        </div>
      )}

      <fieldset className='narrow'>
        <MoneyFormHelper
          label={`Amount in ${currency}`}
          name='amount'
          validate={v.required}
          validationMessages={{
            required: 'How much would you like to pay?',
          }}
          autoFocus
        />

        <label htmlFor='reference'>Reference (optional)</label>
        {lastReference && (
          <span className={s.ref}>
            Use last reference:{' '}
            <a
              href='#'
              onClick={(e) => {
                e.preventDefault()
                onClickLastReference(lastReference)
              }}
            >
              {lastReference}
            </a>
          </span>
        )}
        <Field
          name='reference'
          component={SingleField}
          type='text'
          validate={[v.reference, v.maxLength35AN]}
        />
      </fieldset>
      <PaymentDateOptions formName='paymentMakeVerify' type='payment' />
      <fieldset className='narrow'>
        <BottomError message={error} />
        <Button
          onClick={(e) => checkReference(e, hasReference)}
          disabled={isSubmitDisabled}
          text='Continue & Review'
          loading={submitting}
        />
      </fieldset>
    </Form>
    <Modal id='paymentNoReference' size='small' title='Missing reference'>
      <p>
        You have not included a reference on this payment. Would you like to continue without a
        reference?
      </p>
      <SubmitCancel
        submitText='Continue'
        onSubmitClick={onSubmitButtonClick}
        submitClosesModal
        cancelText='Go back'
        cancelClosesModal
      />
    </Modal>
  </InnerContainer>
)

const PaymentMakeReduxForm = reduxForm<any, PaymentMakeFormProps>({
  form: 'paymentMakeVerify',
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true,
})(PaymentMakeForm)

export default PaymentMakeReduxForm
