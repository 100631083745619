/* eslint-disable no-underscore-dangle */
import { isTrue } from '../../utils/common'

const isProd = isTrue(process.env.__PROD__)

const mode = process.env.__REQUEST_CONFIG_MODE__ as RequestMode
const credentials = process.env.__REQUEST_CONFIG_CREDENTIALS__ as RequestCredentials

// eslint-disable-next-line import/prefer-default-export
export class MakeRequest {
  // eslint-disable-next-line class-methods-use-this
  async makeRequest(url: string, nonce: string, data: any, method: string, selectedEntity: any) {
    const config: RequestInit = {
      headers: {
        'Content-Type': 'application/json',
        'x-nonce': nonce,
        'X-Party-Id': selectedEntity || null,
      },
      method,
      mode,
      credentials,
      body: undefined,
    }
    if (Object.keys(data).length > 0) {
      config.body = JSON.stringify(data)
    }
    const response = await fetch(url, config)
    return response
  }

  async get(url: string, nonce: string, selectedEntity: any) {
    return this.makeRequest(url, nonce, {}, 'GET', selectedEntity)
  }

  async post(url: string, data: any, nonce: string, selectedEntity: any) {
    return this.makeRequest(url, nonce, data, 'POST', selectedEntity)
  }

  async put(url: string, data: any, nonce: string, selectedEntity: any) {
    return this.makeRequest(url, nonce, data, 'PUT', selectedEntity)
  }

  async delete(url: string, data: any, nonce: string, selectedEntity: any) {
    return this.makeRequest(url, nonce, data, 'DELETE', selectedEntity)
  }
}
