import React from 'react'
import Steps from '../Steps'
import WelcomeStep from './WelcomeStep'
import PassStep from './PassStep'
import PinStep from './PinStep'
import TermsStep from './TermsStep'
import SuccessStep from './SuccessStep'
import LoadingSpinner from '../LoadingSpinner'
import PasswordChanged from './PasswordChanged'
import s from './styles.css'
import { ChangeCredentialsSteps } from '../../models/enum'

type ChangeCredentialsViewProps = {
  currentStepNum?: number
  currentStep?: string
  steps: string[]
  acceptTermsSubmit?: () => void
  ready?: boolean // todo - was string.boo??
  forceChangePassSubmit: () => void
  forceChangePinSubmit: () => void
  goNextStep: () => void
  successMessage: string
  isFirstTime?: boolean
  isLoading?: boolean
  authError?: boolean
}

export const ChangeCredentialsView = ({
  currentStep,
  currentStepNum,
  steps,
  forceChangePassSubmit,
  acceptTermsSubmit,
  forceChangePinSubmit,
  goNextStep,
  successMessage,
  isFirstTime,
  ready,
  isLoading,
  authError,
}: ChangeCredentialsViewProps) => {
  let currentStepEl
  let showSteps = false

  switch (currentStep) {
    case ChangeCredentialsSteps.welcome:
      currentStepEl = <WelcomeStep onClickStart={goNextStep} steps={steps} />
      break
    case ChangeCredentialsSteps.terms:
      // todo - onClickAccept is not in use inside TermStep
      currentStepEl = <TermsStep onSubmit={acceptTermsSubmit} onClickAccept={goNextStep} />
      showSteps = steps.length !== 1
      break
    case ChangeCredentialsSteps.pass:
      currentStepEl = <PassStep onSubmit={forceChangePassSubmit} isFirstTime={isFirstTime} />
      showSteps = steps.length !== 1
      break
    case ChangeCredentialsSteps.passChanged:
      currentStepEl = <PasswordChanged successMessage='Password Changed Successfully' ready />
      break
    case ChangeCredentialsSteps.pin:
      currentStepEl = <PinStep onSubmit={forceChangePinSubmit} isFirstTime={isFirstTime} />
      showSteps = steps.length !== 1
      break
    case ChangeCredentialsSteps.success:
      currentStepEl = <SuccessStep successMessage={successMessage} ready={ready} />
      break

    default:
  }

  return (
    <div className={s.root}>
      {isLoading && <LoadingSpinner text='Please wait...' />}
      {currentStep !== ChangeCredentialsSteps.passChanged && authError && (
        <div className={s.row}>
          <div className={s.col}>
            <h2>Password link expired</h2>
            <p>
              For security reasons, password links expire after a short period of time. If you still
              need to reset your password, you can request this by contacting your Private Banking
              or Racing Bank Team on the following:
            </p>
            <p>
              Private Bank Team: <a href='tel:+44 (0) 1933 543 600'>+44 (0) 1933 543 600 </a>
            </p>
            <p>
              Racing Bank Team: <a href='tel:+44 (0) 1933 543 543 '>+44 (0) 1933 543 543 </a>{' '}
            </p>
            <p>
              Email: <a href='mailto:bank@weatherbys.bank'>bank@weatherbys.bank</a>{' '}
            </p>
          </div>
        </div>
      )}
      {!authError && (
        <span>
          {showSteps && (
            <div className={s.steps}>
              <Steps numItems={steps.length} activeItem={currentStepNum} />
            </div>
          )}
          {currentStepEl}
        </span>
      )}
    </div>
  )
}

export default ChangeCredentialsView
