export default (store) => ({
  path: '/payments/transfer-money(/:id)',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const TransferMoneyView = require('../containers/TransferMoney').default

        cb(null, TransferMoneyView)
      },
      'transfer-money'
    )
  },
})
