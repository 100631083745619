import React from 'react'
import s from './styles.css'

type WarningsProps = {
  items?: string[]
}

const Warnings = ({ items }: WarningsProps) => (
  <div>
    {items && items.length > 0 && (
      <ul className={s.root}>
        {items.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={index}>{item}</li>
        ))}
      </ul>
    )}
  </div>
)

export default Warnings
