import React from 'react'
import Modal from '../../containers/Modal'
import SubmitCancel from '../Forms/SubmitCancel'

type AuthorisationsDeclineModalProps = {
  id: string
  isSubmitting?: boolean
  onDeclineSubmit: () => void
}

const AuthorisationsDeclineModal = ({
  id,
  isSubmitting,
  onDeclineSubmit,
}: AuthorisationsDeclineModalProps) => (
  <Modal id={id} size='small' title='Decline Transaction' isNonClickable={isSubmitting}>
    <p>Are you sure you want to decline this transaction?</p>
    <SubmitCancel
      submitText='Confirm'
      cancelText='Go back'
      submitting={isSubmitting}
      onSubmitClick={onDeclineSubmit}
      cancelClosesModal
    />
  </Modal>
)

export default AuthorisationsDeclineModal
