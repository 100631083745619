export default (state, accountId) => {
  const account = state.accounts.items[accountId]
  const entityId = state.entities.activeEntityId
  let canTransfer

  if (account.transferOutDisabled) {
    canTransfer = false
  } else if (!account.transferIsMapped) {
    canTransfer = false
  } else if (entityId) {
    canTransfer = account.canTransferLocal
  } else {
    canTransfer = account.canTransferGlobal
  }
  return canTransfer
}
