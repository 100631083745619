/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { ReactNode } from 'react'
import MediaQuery from 'react-responsive'
import Header from '../Header'
import Footer from '../../containers/Footer'
import MobNav from '../../containers/MobNav'
import Wrapper from '../Wrapper'
import LogoutModal from '../../containers/LogoutModal'
import ErrorModal from '../../containers/ErrorModal'
import BrowserNotification from '../BrowserNotification'
import s from './styles.css'

type CoreLayoutProps = {
  children: ReactNode // todo - was element.
  onContentClick: () => void
  onOverlayClick: () => void
  onBurgerClick: () => void
  mobMenuShowing: boolean
  lastLogin?: string
  displayName?: string
  hasEntities?: boolean
  prefsIsOpen?: boolean
  hasAuthorisations: boolean
  onSubMenuClick: () => void
}

const CoreLayout = ({
  children,
  onBurgerClick,
  onContentClick,
  onOverlayClick,
  mobMenuShowing,
  hasEntities,
  lastLogin,
  displayName,
  onSubMenuClick,
  prefsIsOpen,
  hasAuthorisations,
}: CoreLayoutProps) => {
  const toggleClass = mobMenuShowing ? s.slideOver : ''
  return (
    <div className={`${s.root} ${toggleClass}`}>
      <MediaQuery query='(max-width: 959px)'>
        <div className={s.slideNav}>
          <MobNav
            hasEntities={hasEntities}
            lastLogin={lastLogin}
            displayName={displayName}
            onSubMenuClick={onSubMenuClick}
            prefsIsOpen={prefsIsOpen}
          />
        </div>
      </MediaQuery>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div className={s.content} onClick={onContentClick}>
        {mobMenuShowing && <div aria-hidden className={s.overlay} onClick={onOverlayClick} />}
        <div>
          <BrowserNotification />
          <Header
            onBurgerClick={onBurgerClick}
            hasEntities={hasEntities}
            lastLogin={lastLogin}
            displayName={displayName}
          />
          <Wrapper>
            {children}
            <Footer />
          </Wrapper>
        </div>
      </div>
      <LogoutModal />
      <ErrorModal />
    </div>
  )
}

export default CoreLayout
