import React, { ReactNode } from 'react'
import s from './styles.css'

type StripProps = {
  children?: ReactNode
  className?: string
  accType?: string
}

export const Strip = ({ children, className, accType }: StripProps) => (
  <div className={`${accType} ? ${s.lTDRoot} ${className}: ${s.root} ${className}`}>
    <div className={s.inner}>{children}</div>
  </div>
)

export default Strip
