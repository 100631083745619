import React from 'react'
import s from './styles.css'
import FormOTP from './FormOTP'
import FormPIN from './FormPIN'
import Steps from '../Steps'

type TwoFactorAuthProps = {
  stepNum: number
  description: string
  step: string
  title?: string
  showRecentMessage?: boolean
  handleSubmit: () => void // todo - was added as FormOTP.tsx requires it
  OTPSubmit: () => void // todo - was added as FormOTP.tsx requires it
  PINSubmit: () => void // todo - was added as FormPIN.tsx requires it
}

const TwoFactorAuth = (props: TwoFactorAuthProps) => {
  const { title = 'Authorise new payee' } = props
  return (
    <div className={s.root}>
      <h2>{title}</h2>
      <Steps activeItem={props.stepNum} numItems={2} />
      <p>{props.description}</p>

      {/* Tried to make this one form but resubmitting the same
      form was causing issues :( */}
      {props.step === 'OTP' ? <FormOTP {...props} /> : <FormPIN {...props} />}

      <div className={s.help}>
        <h2>Need help?</h2>
        <h3>Contact us</h3>
        <p>
          If you have not received a one time password (OTP) or are having trouble remembering your
          transaction PIN, please call our support team on +44&nbsp;(0)1933&nbsp;543543 or email us
          at <a href='mailto:bank@weatherbys.bank'>bank@weatherbys.bank</a>.
        </p>
      </div>
    </div>
  )
}

export default TwoFactorAuth
