import React from 'react'
import Slidey from '../../components/Slidey'
import s from './styles.css'
import { MaxHeightEnum } from '../../models/enum'

type ForgotPasswordProps = {
  onClick: () => void
  isShowing?: boolean
}

const ForgotPassword = ({ onClick, isShowing }: ForgotPasswordProps) => (
  <div className={s.root}>
    {!isShowing && (
      <a href='#' onClick={onClick}>
        I have forgotten my User ID or password
      </a>
    )}
    <Slidey isOpen={isShowing} maxHeight={MaxHeightEnum.medium}>
      <h3>I have forgotten my User ID or password</h3>
      <p>Please speak to your Private Bank Team or Racing Bank Team, or contact us on following:</p>
      <p>
        Private Bank Team: <a href='tel:+44 (0) 1933 543 600'>+44 (0) 1933 543 600</a>
      </p>
      <p>
        Racing Bank Team: <a href='tel:+44 (0) 1933 543 543 '>+44 (0) 1933 543 543</a>
      </p>
    </Slidey>
  </div>
)

export default ForgotPassword
