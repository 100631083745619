import React from 'react'
import BottomError from '../Forms/BottomError'
import Button from '../Button'
import s from './styles.css'
import Modal from '../../containers/Modal'

type CutoffProps = {
  onSubmit: () => void
  showPaymentCutoffModalFunc: () => void
  hidePaymentCutoffModalFunc: () => void
  disableCancel?: boolean
  error?: string
  submitText?: string
  cancelLink?: string
  cancelText?: string
  showPaymentCutoffModal?: boolean
  cutoffMessage?: string
  submitting?: boolean
}

const CutoffPayments = ({
  submitting,
  cutoffMessage,
  onSubmit,
  showPaymentCutoffModalFunc,
  hidePaymentCutoffModalFunc,
  disableCancel,
  error,
  submitText,
  cancelLink,
  cancelText,
  showPaymentCutoffModal,
}: CutoffProps) => (
  <form onSubmit={onSubmit}>
    <BottomError message={error} />
    <Button submit={false} text={submitText} onClick={showPaymentCutoffModalFunc} />
    <Button
      // eslint-disable-next-line react/style-prop-object
      style='ghost-dark'
      disabled={disableCancel}
      to={cancelLink}
      className={s.cancel}
      text={cancelText}
    />

    <Modal
      isOpen={showPaymentCutoffModal}
      onClose={hidePaymentCutoffModalFunc}
      size='medium'
      title='Payment date'
    >
      <p>{cutoffMessage}</p>
      <BottomError message={error} />

      <div className={s.modalButtonsAlignCenter}>
        <Button onClick={onSubmit} text={submitText} loading={submitting} />
        <Button
          // eslint-disable-next-line react/style-prop-object
          style='ghost-dark'
          disabled={disableCancel}
          to={cancelLink}
          className={s.cancel}
          text={cancelText}
        />
      </div>
    </Modal>
  </form>
)

export default CutoffPayments
