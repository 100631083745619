/* eslint-disable */
// @ts-nocheck
// todo - eslint and typescript
import get from 'lodash/get'
import messages from './messages'
import { decodeHTML } from '../../utils/decodeHTML'

/**
 * Takes in error object. If from API, returns message, if a browser error,
 * translates or returns default message
 * @param {obj} Error - Error object
 * @return {string} message - nice human readable message
 */

const LOGIN_ERROR_CODE = 'OBDXIDM-0'
const LOGIN_ERROR_TYPE = 'INVALID_CRED'

export default (error) => {
  const rgError = get(error, 'response.data.message.code')
  const rgErrorMessage = get(error, 'response.data.message.title')
  const validErr = get(error, 'response.data.message.validationError', [])
  const errHeader = get(error, 'response.headers["x-auth-failure-response"]')
  let msg = error.message
  let errMessage = ''
  if (error.response !== undefined && error.response.status === 403) {
    try {
      const errorMessage = JSON.parse(errHeader)?.errorMessage
      const errorCode = JSON.parse(errHeader)?.errorCode
      const errorType = JSON.parse(errHeader)?.type

      if (errorCode === LOGIN_ERROR_CODE && errorType === LOGIN_ERROR_TYPE) {
        return decodeHTML(errorMessage)
      }
    } catch (e) {
      return getErrorMessage(rgError, rgErrorMessage, messages, msg, errMessage, validErr)
    }

    if (rgError === 'DIGX_PROD_ACCESS_DENIED_0000') {
      return messages.access_denied
    }
    return messages.wrong_credentials
  }
  errMessage = getErrorMessage(rgError, rgErrorMessage, messages, msg, errMessage, validErr)
  // checking if we have got the error. if errMessage is not null or blank, return error message
  if (errMessage) {
    return errMessage
  }
  // errMessage is still null or blank,further checking
  if (validErr !== undefined && validErr[0]) {
    if (validErr[0].errorCode === 'DIGX_PY_0025') {
      msg = 'invalid_account_number'
      return messages[msg]
    }
    if (validErr[0].code === '3403') {
      msg = 'select_when'
      return messages[msg]
    }
    errMessage = getErrorMessage(
      validErr[0].errorCode,
      rgErrorMessage,
      messages,
      msg,
      errMessage,
      validErr
    )
    // again checking if we have got the error.if errMessage is not null or blank, return error message
    if (errMessage) {
      return errMessage
    }

    if (validErr[0].errorCode.length) {
      msg = 'default'
      errMessage = `${messages[msg]} Error Code: ${validErr[0].errorCode}`
      return errMessage
    }
  }
  if (error.name === 'API') {
    return msg
  }
  if (errMessage) {
    return errMessage
  }
  let message = messages.default
  if (errMessage) {
    message = errMessage
  }
  return messages[msg] ? messages[msg] : message
}
function getErrorMessage(errCode, rgErrorMessage, messages, msg, errMessage, validErr) {
  switch (errCode) {
    case 'DIGX_UM_041':
      msg = 'pswd_in_history'
      return messages[msg]
    case 'SM-MULMAN05':
      msg = 'transaction_limit'
      return messages[msg]
    case 'DIGX_FL_040':
      msg = 'daily_limit'
      return messages[msg]
    case 'DIGX_FL_035':
      msg = 'daily_limit'
      return messages[msg]
    case 'FT-VAC09':
      msg = 'credit_in_not_allowed'
      return messages[msg]
    case 'DIGX_AUTH_0007':
      msg = 'locked_transaction_pin'
      return messages[msg]
    case 'DIGX_FL_059':
      msg = 'daily_cumulative_limit'
      return messages[msg]
    case 'DIGX_FL_015':
      msg = 'no_limits_set'
      return messages[msg]
    case 'DIGX_CZ_TFA_0001':
      msg = 'wrong_pin'
      return messages[msg]
    case 'DIGX_PROD_IDM_0010':
      msg = 'wrong_current_password'
      return messages[msg]
    case 'DIGX_AP_0012':
      msg = 'payment_approval_rules'
      return messages[msg]
    case 'DIGX_AP_0013':
      msg = 'transaction_rules'
      return messages[msg]
    case 'DIGX_TB_0003':
      errMessage = decodeHTML(rgErrorMessage)
      return errMessage
    case 'DIGX_TFA_0004':
      msg = 'wrong_details'
      return messages[msg]
    case 'DIGX_AUTH_0004':
      msg = 'locked_transaction_pin'
      return messages[msg]
    case 'DIGX_AUTH_0012':
      msg = 'temp_locked_transaction_pin'
      return messages[msg]
    case 'DIGX_FU_003':
      msg = 'duplicate_file_name'
      return messages[msg]
    case 'DIGX_PY_1360':
      msg = 'duplicate_payee'
      return messages[msg]
    case 'DIGX_FU_048':
      msg = 'alpha_numeric_file'
      return messages[msg]
    case 'DIGX_PY_1338':
      msg = 'alpha_numeric_second_character_onwards'
      return messages[msg]
    case 'DIGX_PY_PAYEE_007':
      msg = 'alpha_numeric_first_character'
      return messages[msg]
    case 'DIGX_CZ_ANN_02':
      msg = 'alpha_numeric'
      return messages[msg]
    case 'DIGX_CZ_ANN_03':
      msg = 'nickname_character_limit'
      return messages[msg]
    case 'DIGX_PY_0138':
      msg = 'alpha_numeric_reference'
      return messages[msg]
    case 'DIGX_PY_0134':
      msg = 'not_enough_funds'
      return messages[msg]
    case 'DIGX_PY_REMARK_001':
      msg = 'character_limit'
      return messages[msg]
    case 'DIGX_PY_REMARK_002':
      msg = 'alpha_numeric_reference'
      return messages[msg]
    case 'DIGX_TPIN_EXISTS':
      msg = 'pin_in_history'
      return messages[msg]
    case 'DIGX_TPIN_MISMATCH':
      msg = 'wrong_pin'
      return messages[msg]
    case 'DIGX_PY_HOST_006':
      msg = 'account_marked_no_debits'
      errMessage = `${messages[msg]} and quote error code: ${errCode}. Account is marked for no debits`
      return errMessage
    case 'DIGX_CZ_AP_0001':
      msg = 'cut_off_time_passed'
      errMessage = `${messages[msg]} and quote error code: ${errCode}. This transaction cannot be approved as the cut off time has passed.`
      return errMessage
    case 'DIGX_AP_0048':
      msg = 'post_value_date'
      errMessage = `${messages[msg]} and quote error code: ${errCode}. Transaction cannot be approved post value date`
      return errMessage
    default:
      if (errCode && validErr.length === 0) {
        msg = 'default'
        errMessage = `${messages[msg]} and quote error code: ${errCode}`
        return errMessage
      }
      return null
  }
}
