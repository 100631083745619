/* eslint-disable */
// todo - eslint and typescript
import React from 'react'
import s from './styles.css'
import PendingTableRow from './PendingTableRow'
import PendingTableDD from './PendingTableDD'
import { MakeRequest } from './MakeRequest'
import goOut from '../../utils/goOut'

const Request = new MakeRequest()

interface PendingTransactionsTableProps {
  showPendingTransactions: boolean
  showPendingTransactionsFunc: () => void
  hidePendingTransactionsFunc: () => void
  accounts?: {
    items: {
      [key: string]: {
        customerId: string
        accountId: string
      }
    }
  }
  filters?: any // todo - was object
  rows?: []
}

interface PendingTransactionsTableState {
  czPendingTransactionItems: {
    description: string
    transactionDate?: string
    valueDate?: string
    creditDebitFlag?: string
    debitAmount?: {
      amount: string | number
      currency: string
    }
    creditAmount: {
      amount: string | number
      currency: string
    }
  }[]
  rows: string[]
  loading: boolean
}
class PendingTransactionsTable extends React.Component<
  PendingTransactionsTableProps,
  PendingTransactionsTableState
> {
  constructor(props: PendingTransactionsTableProps | Readonly<PendingTransactionsTableProps>) {
    super(props)
    this.state = {
      czPendingTransactionItems: [],
      rows: [],
      loading: true,
    }
    this.callPendingApi = this.callPendingApi.bind(this)
  }

  componentDidMount() {
    this.callPendingApi(this.props.rows)
  }

  /* eslint-disable-next-line */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { rows } = this.state
    const { filters } = nextProps
    if (
      Object.keys(filters).length === 0 &&
      rows &&
      nextProps.rows &&
      JSON.stringify(rows) !== JSON.stringify(nextProps.rows)
    ) {
      this.callPendingApi(nextProps.rows)
    }
  }

  callPendingApi(rows) {
    // this.props.hidePendingTransactionsFunc()
    this.setState({ rows, loading: true })
    const { accounts } = this.props
    const uniqueId = window.location.hash.split('/')[3]
    const partyId =
      (accounts && accounts.items[uniqueId] && accounts.items[uniqueId].customerId) || null
    const accountId =
      (accounts && accounts.items[uniqueId] && accounts.items[uniqueId].accountId) || null
    if (accountId && partyId) {
      Request.post('/digx/v1/session/nonce', {}, null, '').then((res) => {
        let nonce = null
        let nonceAvailable = false
        for (const pair of res.headers.entries()) {
          if (pair[0] === 'x-nonce') {
            nonce = JSON.parse(pair[1]).nonce[0]
            nonceAvailable = true
            Request.get(
              `/digx/cz/v1/accounts/demandDeposit/${accountId}/pendingTransactions`,
              nonce,
              partyId
            )
              .then((response) => response.json())
              .then((res) => {
                this.setState({
                  czPendingTransactionItems: res.czPendingTransactionItems || [],
                  loading: false,
                })
              })
              .catch((error) => {
                console.log(error)
              })

            break
          }
        }
        if (!nonceAvailable) {
          goOut('https://www.weatherbys.bank/sessionexpired')
        }
      })
    }
  }

  render() {
    const {
      showPendingTransactions,
      showPendingTransactionsFunc,
      hidePendingTransactionsFunc,
      filters,
    } = this.props
    const { czPendingTransactionItems, loading } = this.state
    if (Object.keys(filters).length > 0) {
      return null
    }
    if (czPendingTransactionItems.length === 0 || loading) {
      return null
    }
    return (
      <div className={s.pendingTransactions}>
        <PendingTableDD
          showPendingTransactions={showPendingTransactions}
          hidePendingTransactionsFunc={hidePendingTransactionsFunc}
          showPendingTransactionsFunc={showPendingTransactionsFunc}
        />
        {showPendingTransactions &&
          czPendingTransactionItems.map((item, index) => (
            <PendingTableRow data={item} key={item.description + index} />
          ))}
      </div>
    )
  }
}

export default PendingTransactionsTable
