import React from 'react'
import { Field } from 'redux-form'
import s from './styles.css'
import Select from '../Select'
import * as v from '../../../utils/forms/validators'
import MoneyFormHelper from '../../formHelpers/MoneyFormHelper'

type CurrencyAndAmountProps = {
  currencyOptions: any[]
  showRequired?: boolean
}

const CurrencyAndAmount = ({ currencyOptions, showRequired }: CurrencyAndAmountProps) => (
  <div>
    <label className={s.hideLabel} htmlFor='amount'>
      Currency to be sent *
    </label>
    <div className={s.root}>
      <div className={s.col1}>
        <label className={s.label1} htmlFor='amount'>
          Currency to be sent *
        </label>
        <div className={s.input1}>
          <Field name='currency' component={Select} options={currencyOptions} />
        </div>
      </div>
      <div className={s.col2}>
        <div className={s.input2}>
          <MoneyFormHelper
            name='amount'
            validate={v.required}
            placeholder=''
            validationMessages={{
              required: 'How much money are you paying?',
            }}
          />
        </div>
      </div>
    </div>
  </div>
)

export default CurrencyAndAmount
