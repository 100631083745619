import React from 'react'
import Breadcrumbs from '../Breadcrumbs'
import ActionHeader from '../ActionHeader'
import InnerContainer from '../InnerContainer'
// import AlertsUserForm from '../../containers/AlertsUserForm'
import Warnings from '../Warnings'
// import AlertsAccountForm from '../../containers/AlertsAccountForm'
// import Stripey from '../Stripey'
import s from './styles.css'

type AlertsViewProps = {
  contacts?: any // todo - was object
  showAlerts?: boolean
}

export const AlertsView = ({
  contacts: { email, sms } = {},
  showAlerts,
  ...props
}: AlertsViewProps) => (
  <div>
    <Breadcrumbs
      items={[
        {
          to: '/settings',
          text: 'Details & Settings',
        },
        {
          to: '/settings/alerts',
          text: 'View details',
          // text: 'View & Set up Alerts'
        },
      ]}
    />

    <InnerContainer>
      {/* // MOVE THIS BLOCK DOWN LATER
      <AlertsAccountForm />
      <Stripey className={s.stripeyAlerts} /> */}
      {showAlerts ? (
        <div>
          <ActionHeader title='View details' />
          {/* <ActionHeader title='View & Set up Alerts' /> */}
          <p>Are these details up to date? If not, please contact us at +44 (0)1933 543543.</p>
          <div className={s.userDetails}>
            <span className={s.userDetail}>Email: {email}</span>
            <span className={s.userDetail}>Mobile phone: {sms}</span>
          </div>
          {/* <AlertsUserForm /> */}
        </div>
      ) : (
        <Warnings
          items={[
            `Please call us with your email address and mobile number on +44 (0)1933 543543,
          so we can activate alerts for you`,
          ]}
        />
      )}
    </InnerContainer>
  </div>
)

export default AlertsView
