import { connect } from 'react-redux'
import Tooltip from '../../components/Tooltip'
import { tooltipShow, tooltipHide, tooltipToggle } from '../../store/ui'
import { isAndroidOriOS } from '../../utils/getPlatform'

const mapStateToProps = (state, ownProps) => ({
  active: state.ui.tooltip === ownProps.id,
  isAndroidOriOS,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: (e) => {
    e.stopPropagation()
    dispatch(tooltipToggle(ownProps.id))
  },
  onMouseOver: () => dispatch(tooltipShow(ownProps.id)),
  onMouseOut: () => dispatch(tooltipHide()), // todo-ts , param ownProps.id was removed, as redux action/reducer do not use it
})

export default connect(mapStateToProps, mapDispatchToProps)(Tooltip)
