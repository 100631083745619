import React from 'react'
import s from './styles.css'
import Icon from '../Icon'
import { ResultsInfoDateState } from '../../models/types'

type ResultsInfoProps = {
  state?: {
    state: ResultsInfoDateState
    message?: string
    errorCode?: any // todo - added as code is using it
  }
}

const ResultsInfo = ({ state }: ResultsInfoProps) => {
  const code = state.state
  const { message } = state
  const { errorCode } = state
  let mainText
  let extraText
  let iconClass
  let icon
  switch (code) {
    case 'loading':
    case 'loadingEmpty':
      mainText = 'Loading data...'
      icon = 'loading'
      iconClass = s.spin
      break
    case 'noResults':
      mainText = 'No results found'
      icon = 'search'
      break
    case 'noResultsFilter':
      mainText = 'No results found. Change the filters for more results.'
      icon = 'search'
      break
    case 'endOfResults':
      mainText = 'No more results for given filters'
      icon = 'search'
      break
    case 'completelyEmpty':
      icon = 'search'
      mainText = 'No data to show'
      break
    case 'endOfExisting':
    case 'endOfEverything':
      mainText = 'End of results'
      icon = 'hourglass'
      break
    case 'error':
      mainText = message || 'Error: Could not load results'
      extraText = `Error Code: ${errorCode}`
      icon = 'loadingError'
      iconClass = s.large
      break
    default:
      mainText = ''
  }
  return (
    <div className={s.root}>
      {icon && <Icon icon={icon} className={`${s.icon} ${iconClass}`} />}
      <div>{mainText}</div>
      {errorCode && <div>{extraText}</div>}
    </div>
  )
}

export default ResultsInfo
