import React from 'react'
import s from './styles.css'

type StripeyProps = {
  className?: string
}

const Stripey = ({ className }: StripeyProps) => <hr className={`${s.stripey} ${className}`} />

export default Stripey
