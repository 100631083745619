import { connect } from 'react-redux'
import LoginView from '../../components/LoginView'
import { tooltipShow, tooltipHide } from '../../store/ui'

const mapDispatchToProps = (dispatch, ownProps) => ({
  onKey: (e) => {
    if (e.getModifierState && e.getModifierState('CapsLock')) {
      dispatch(tooltipShow('CapsLock'))
    } else {
      dispatch(tooltipHide())
    }
  },
})

export default connect(null, mapDispatchToProps)(LoginView)
