export default (store) => ({
  path: 'new',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const MailNew = require('../components/MailNew').default
        cb(null, MailNew)
      },
      'mail-new'
    )
  },
})
