import { connect } from 'react-redux'
import sortBy from 'lodash/sortBy'
import ActivityLogTypesList from '../../components/ActivityLogTypesList'
import niceTableData from '../../utils/niceTableData'

const mapStateToProps = (state, ownProps) => {
  const tableFormat = {
    mapRowIdTo: 'id',

    cols: [
      {
        header: 'Type',
        mapTextTo: 'title',
        wrap: true,
        perc: 50,
      },
      {
        header: 'Status',
        mapTextTo: 'status',
        perc: 50,
      },
    ],
    card: {
      mapTitleTo: 'title',
      mapDetailsTo: 'status',
    },
  }

  const { items } = state.activityLog.types

  const itemValues = Object.values(items).map((row: any) => ({
    ...row,
    title: row.title === 'My Account Transfer' ? 'Internal Transfer' : row.title,
  }))

  const itemsWithoutPayments = itemValues.filter(
    (item) => item.title !== 'Weatherbys account payee'
  )

  const sortedItems = sortBy(itemsWithoutPayments, ['title', 'status'])

  const niceData = niceTableData(sortedItems, tableFormat)

  const rowLink = ({ type, statusCode }) =>
    `/settings/activity-log/type/${type}?status=${statusCode}`

  return {
    rows: niceData.body,
    headerCols: niceData.header,
    virtualized: true,
    cardForSmall: true,
    isFetching: state.activityLog.types.isFetching,
    failedToFetch: state.activityLog.types.errorMessage,
    errorCode: state.activityLog.types.errorCode,
    rowLink,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLogTypesList)
