import * as React from 'react'

function SvgMakePayment(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 7 9'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <use xlinkHref='#make-payment_svg__path0_fill' transform='translate(0 -2)' fill='#5E5E5E' />
      <defs>
        <path
          id='make-payment_svg__path0_fill'
          d='M2.63 7.355l.048 1.29c0 .601-.121 1.08-.364 1.435H6.61l-.005.92H.557v-.92h.45c.157-.035.284-.18.382-.434.097-.257.15-.574.158-.949V8.65L1.5 7.355H.533v-.92h.938l-.053-1.523c0-.777.238-1.398.715-1.863.48-.465 1.111-.697 1.892-.697.743 0 1.33.207 1.764.62.434.415.65.967.65 1.659H5.32c0-.418-.125-.748-.375-.99-.246-.243-.586-.364-1.02-.364-.41 0-.741.15-.995.452-.254.3-.381.695-.381 1.183l.053 1.524H4.47v.92H2.63z'
        />
      </defs>
    </svg>
  )
}

export default SvgMakePayment
