import get from 'lodash/get'

export default (data: {
  data: {
    limitUtilizationDTOs: {
      utilizationId: string
      amount: {
        amount: number
      }
      count: number
    }[]
  }
}) => {
  const limitLinkages = get(data, 'data.limitUtilizationDTOs', [])
  const tidyData = {}
  const targets = {
    FU_F_DFT: 'bulkPayments',
    PC_F_UK_FAS: 'makePayment',
    PC_F_SELF: 'internalTransfer',
  }

  // todo-ts: changed map to forEach, as no returned
  limitLinkages.forEach((item) => {
    tidyData[targets[item.utilizationId]] = {
      amount: item.amount.amount,
      count: item.count,
    }
  })

  return tidyData
}
