import values from 'lodash/values'
import getAccount from '../../selectors/getAccount'

const getAllPaymentsUpcomingClosed = (state) => {
  const entityId = state.entities.activeEntityId

  const processList = (arr, type) =>
    arr
      .map((item) => {
        const acc = getAccount(state, item.fromId)

        return {
          ...item,
          type,
          fromAccountTitle: acc.title,
        }
      })
      .filter((item) => item.statusCode === 'C' && (!entityId || entityId === item.customerId))

  const futurePayments = processList(values(state.paymentsFuture.list.items), 'Future payment')
  const SI = processList(values(state.paymentsRecurring.SI.items), 'Standing order')
  const DD = processList(values(state.paymentsRecurring.DD.items), 'Direct debit')

  const allItems = [...futurePayments, ...SI, ...DD]
  return allItems
}

export const getPaymentsUpcomingClosed = (state, limit = 30) => {
  const allItems = getAllPaymentsUpcomingClosed(state)

  const { panel } = state.ui
  const closedPayments = panel === 'showAllClosedPayments' ? allItems : allItems.slice(0, limit)
  return closedPayments
}

export const getIsAllClosedPaymentsShowing = (state, limit = 30) =>
  getAllPaymentsUpcomingClosed(state).length < limit || state.ui.panel === 'showAllClosedPayments'
