export const getIsFirstTime = (state) => state.changeCredentials.isFirstTime

export const getVisibleSteps = (state) =>
  state.changeCredentials.steps.filter((step) => step !== 'welcome' && step !== 'success')

export const getCurrentStep = (state) =>
  state.changeCredentials.steps[state.changeCredentials.currentStep]

export const getSuccessMessage = (state) => {
  const { steps } = state.changeCredentials

  if (state.changeCredentials.isFirstTime) {
    return 'Setup complete'
  }
  if (steps.indexOf('pass') > -1 && steps.indexOf('pin') > -1) {
    return 'Security details changed'
  }
  if (steps.indexOf('pass') > -1) {
    return 'Password changed'
  }
  if (steps.indexOf('pin') > -1) {
    return 'Transaction PIN changed'
  }
  return 'Success'
}
export const getUserReady = (state) => state.user.ready

export const getIsLoading = (state) => state.changeCredentials.isLoading

export const getAuthError = (state) => state.changeCredentials.authError
