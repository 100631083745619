import React from 'react'
import s from './TallButton.css'

type TallButtonProps = {
  onClick: () => void
  text: string
  mobileText: string
  image: string
  imageClassName?: string
}

const TallButton = ({ onClick, text, mobileText, image, imageClassName }: TallButtonProps) => (
  <button type='button' className={s.root} onClick={onClick}>
    <div className={s.imageWrapper}>
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img className={`${s.image} ${imageClassName || undefined}`} src={image} />
    </div>
    <span className={s.text}>{text}</span>
    <span className={s.mobileText}>{mobileText}</span>
  </button>
)

export default TallButton
