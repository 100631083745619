import React from 'react'
import s from './styles.css'

type FieldsetLegendProps = {
  title: string
  info?: string
  children?: any[]
}

const FieldsetLegend = ({ title, info, children }: FieldsetLegendProps) => (
  <div className={s.root}>
    <legend>{title}</legend>
    {info && <p>{info}</p>}
    {children}
  </div>
)

export default FieldsetLegend
