import React, { Component } from 'react'
import { Link } from 'react-router'
import responsiveCheck from '../../../utils/responsiveCheck'
import Icon from '../../Icon'
import history from '../../../history'
import Button from '../../Button'
import s from './styles.css'
import { decodeHTML } from '../../../utils/decodeHTML'

type TableRowProps = {
  id: string | number
  currentRow: any // todo - was object.
  cols: {
    text?: string | number
    showFor?: string
    hideFor?: string
    drawer?: string | number
    perc?: number
    wrap?: boolean //  - added as it is used in the code
    hasActions?: boolean //  - added as it is used in the code,
    extra?: any //  - added as it is used in the code,
    icon?: string //  - added as it is used in the code - was iconGlyph but renamed as icon
  }[]
  browser?: {
    greaterThan?: { small: boolean; medium: boolean }
    lessThan?: { small: boolean; medium: boolean }
    foo?: 'bar' // todo - putting this to  pass the test case
  }
  onRowClick?: (v: string | number) => void
  onActionClick?: (v1: string, id: string | number) => void
  rowLink?: (v: any) => string
  onDrawerClick?: (v1: string | false, v2: string | number) => void // onDrawerClick(currentPanel, id)}
  currentPanel?: string | false
  height?: number
  actions?: {
    text: string
    id: string
    to?: (v) => string // todo - was func
  }[]
}

class TableRow extends Component<TableRowProps> {
  constructor(props) {
    super(props)
    this.handleRowClick = this.handleRowClick.bind(this)
    this.handleActionClick = this.handleActionClick.bind(this)
  }

  // eslint-disable-next-line consistent-return
  handleRowClick() {
    const { onRowClick, rowLink, currentRow, id } = this.props
    if (onRowClick) return onRowClick(id)
    if (rowLink) history.push(rowLink(currentRow))
  }

  handleActionClick(actionId) {
    const { onActionClick, id } = this.props
    onActionClick(actionId, id)
  }

  render() {
    const {
      id,
      cols,
      browser,
      onRowClick,
      onDrawerClick,
      currentPanel,
      actions,
      rowLink,
      currentRow = { hasDrawer: false },
      height,
    } = this.props
    const rowClass = `${s.row} ${onRowClick || rowLink ? s.clickable : undefined}`

    const isDrawerOpen = currentPanel === id

    const styles: any = {} // todo - any
    if (height) styles.height = height

    return (
      <tr onClick={this.handleRowClick} className={rowClass} style={styles}>
        {cols.map((item, index) => {
          let innerEl

          const condition = item.showFor ? item.showFor : item.hideFor
          let show = responsiveCheck(condition, browser)
          // Reverse responsive check if hiding for instead of showing for
          if (item.hideFor) {
            show = !show
          }
          const width = item.perc ? `${item.perc}%` : 'auto'
          const colClass = item.wrap ? s.wrap : s.noWrap
          const style = {
            width,
          }
          if (item.hasActions && actions) {
            innerEl = (
              <ul className={s.actions}>
                {actions.map((action) => (
                  <li key={action.id}>
                    {
                      // Check if dispatching action or just a Link
                      // action.to is a function that will return a string,
                      // making use of the row ID if needed
                      action.to ? (
                        <Link to={action.to(id)}>{action.text}</Link>
                      ) : (
                        <a
                          href='#'
                          onClick={(e) => {
                            e.preventDefault()
                            return this.handleActionClick(action.id)
                          }}
                        >
                          {action.text}
                        </a>
                      )
                    }
                  </li>
                ))}
              </ul>
            )
          } else if (item.icon) {
            innerEl = (
              <div className={s.icon}>
                <Icon icon={item.icon} />
              </div>
            )
          } else {
            const textEl = <span className={s.ellipsis}>{item.text && decodeHTML(item.text)}</span>
            const extraEl = item.extra ? (
              <span className={s.extra}>{item.extra && decodeHTML(item.extra)}</span>
            ) : (
              false
            )
            innerEl = (
              <div>
                {textEl}
                {extraEl}
              </div>
            )
          }

          // Wrap element in a Link if there is a rowLink prop
          if (rowLink)
            innerEl = (
              <Link to={rowLink(currentRow)} className={s.colLink}>
                {innerEl}
              </Link>
            )

          const element = show ? (
            // eslint-disable-next-line react/no-array-index-key
            <td className={colClass} key={index} style={style}>
              {innerEl}
              {isDrawerOpen && <div className={s.drawerText}>{item.drawer}</div>}
            </td>
          ) : (
            false
          )
          return element
        })}
        {currentRow.hasDrawer && (
          <td className={s.drawerWrap}>
            <Button
              text={isDrawerOpen ? '–' : '+'}
              className={s.drawerButton}
              // eslint-disable-next-line react/style-prop-object
              style='ghost-dark'
              onClick={() => onDrawerClick(currentPanel, id)}
            />
          </td>
        )}
      </tr>
    )
  }
}

export default TableRow
