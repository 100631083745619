import * as c from '../../constants'

export interface ITransferMoneyFormState {
  values: any
  fields: any
}

export default function transferMoneyFormReducer(
  state: ITransferMoneyFormState,
  action
): ITransferMoneyFormState {
  const m = action.meta

  switch (action.type) {
    case c.REDUX_FORM_CHANGE:
      if (m && m.form === 'transferMoney' && m.field === 'from') {
        const fromVal = state.values && state.values.from && state.values.from.value

        const toVal = action.payload

        if (fromVal === toVal) {
          return {
            ...state,
            values: {
              ...state.values,
              to: undefined,
            },
            fields: {
              ...state.fields,
              to: undefined,
            },
          }
        }
        return state
      }
      return state

    default:
      return state
  }
}
