import React from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import Button from '../Button'
import SingleField from '../Forms/SingleField'
import BottomError from '../Forms/BottomError'
import * as v from '../../utils/forms/validators'
import Select from '../Forms/Select'

type MailFormProps = {
  handleSubmit: () => void
  send: () => void
  entityOptions: any[]
  subjectOptions: any[]
  error?: string
  invalid?: boolean
  submitting?: boolean
  entitySelectIsVisible?: boolean
  customSubjectIsVisible?: boolean
  replyingTo?: string
}

type FieldProps = {
  customerId: string
  subjectCode: string
  customSubject: string
  body: string
}

const MailForm = ({
  handleSubmit,
  error,
  submitting,
  send,
  invalid,
  entityOptions,
  entitySelectIsVisible,
  customSubjectIsVisible,
  subjectOptions,
  replyingTo,
}: InjectedFormProps<FieldProps> & MailFormProps) => (
  <form onSubmit={handleSubmit(send)}>
    {!replyingTo && (
      <div>
        {entitySelectIsVisible && (
          <Field
            name='customerId'
            label='From'
            component={SingleField}
            InputComponent={Select}
            inputComponentProps={{
              options: entityOptions,
            }}
            validate={v.required}
          />
        )}

        <Field
          name='subjectCode'
          label='Subject'
          component={SingleField}
          InputComponent={Select}
          inputComponentProps={{
            options: subjectOptions,
          }}
          validate={v.required}
        />
        {customSubjectIsVisible && (
          <Field
            name='customSubject'
            component={SingleField}
            type='text'
            validate={v.required}
            placeholder='Subject of your message...'
          />
        )}
      </div>
    )}

    <Field
      label='Message'
      name='body'
      type='textarea'
      component={SingleField}
      validate={v.required}
      validateType='onSubmit'
    />

    <BottomError message={error} />
    <Button disabled={invalid} submit text='Send' loading={submitting} />
  </form>
)

const MailReduxForm = reduxForm<FieldProps, MailFormProps>({
  form: 'mail',
  enableReinitialize: true,
})(MailForm)

export default MailReduxForm
