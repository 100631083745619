import React from 'react'
import { Field } from 'redux-form'
import SingleField from '../Forms/SingleField'
import MoneyField from '../Forms/MoneyField'
import * as n from '../../utils/forms/normalizers'
import { SupportedCurrenciesType } from '../../models/enum'

type MoneyFormHelperProps = {
  placeholder?: SupportedCurrenciesType | ''
  autoFocus?: boolean
  name?: string
  validate?: (value: string) => string | undefined
  validationMessages?: any
  label?: string // todo - was added as the child was passing them in
  layout?: string // todo - was added as the child was passing them in
  id?: any // todo - was added as the child was passing them in - AlertsView/Form
  className?: string // todo - was added as the child was passing them in - AlertsView/Form
}

const MoneyFormHelper = ({ placeholder = '£', autoFocus, ...props }: MoneyFormHelperProps) => (
  <Field
    type='text'
    component={SingleField}
    props={{ autoFocus }}
    InputComponent={MoneyField}
    inputComponentProps={{ placeholder }}
    normalize={n.onlyDecimal}
    {...props}
  />
)

export default MoneyFormHelper
