/*
 * Tidies deeply nested and poorly named accounts data from API into something
 * easier to deal with!
 * @param  {Object} data - raw data from API
 * @return {Object} data - tidied data
 */

export default (data: {
  data: {
    userLoginFlowDTOList: {
      configPackageId: string
    }[]
  }
}) => {
  const response = data.data.userLoginFlowDTOList
  const flags: any = {}
  flags.isFirstTime = response.length > 0
  response.forEach((item) => {
    if (item.configPackageId === 'terms-and-conditions') {
      flags.termsNotAccepted = true
    }
    if (item.configPackageId === 'FORCE_CHANGE_PASSWD') {
      flags.forcePasswordChange = true
    }
    if (item.configPackageId === 'force-change-pin') {
      flags.forcePinChange = true
    }
  })

  return flags
}
