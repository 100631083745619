import * as c from '../../constants'

export const getParam = (param) => {
  return new URLSearchParams(window.location.hash).get(param)
}

export const verifyAuthToken = (dispatch) => {
  dispatch({
    type: c.VERIFY_AUTH_TOKEN,
    payload: {
      token: getParam('token'),
    },
  })
}
