export default (store) => ({
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const ServicesRacingPlus = require('../containers/ServicesRacingPlus').default
        cb(null, ServicesRacingPlus)
      },
      'racingplus'
    )
  },
})
