import React from 'react'
import PaymentsBulkItemList from '../../containers/PaymentsBulkItemList'
import s from './MainSuccess.css'

type MainSuccessProps = {
  items: {
    stats: {
      processing: string
      rejected: string
      accepted: string
    }
  } // todofixed - was object
}

const MainSuccess = ({ items: { stats } }: MainSuccessProps) => (
  <div>
    <div className={s.statsBg}>
      <div className={s.stats}>
        <div className={s.stat}>
          <span className={s.statLabel}>Successful</span>
          <span className={s.statNumber}>{stats.accepted}</span>
        </div>
        <div className={s.stat}>
          <span className={s.statLabel}>Rejected</span>
          <span className={s.statNumber}>{stats.rejected}</span>
        </div>
        <div className={s.stat}>
          <span className={s.statLabel}>Under process</span>
          <span className={s.statNumber}>{stats.processing}</span>
        </div>
      </div>
    </div>
    <PaymentsBulkItemList />
  </div>
)

export default MainSuccess
