export default (store, state) => ({
  path: 'paymentcompanies',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const Alerts = require('../containers/PaymentCompanies').default

        cb(null, Alerts)
      },
      'paymentcompanies'
    )
  },
})
