/* eslint-disable */
// @ts-nocheck
// todo - eslint and typescript
import React from 'react'
import ReactDOM from 'react-dom'
import createStore from './store/createStore'
import AppContainer from './containers/AppContainer'
import { isTrue } from './utils/common'

// ========================================================
// Core styles
// ========================================================
import 'normalize.css'
import './styles/core.css'
import './styles/global/select.css'
import './styles/global/datepicker.css'
// ========================================================
// Render Setup
// ========================================================

let store
const MOUNT_NODE = document.getElementById('root')

const render = () => {
  const routes = require('./routes/index').default(store)
  ReactDOM.render(<AppContainer store={store} routes={routes} />, MOUNT_NODE)
}

async function waitRender() {
  store = await createStore()

  render()
}

// This code is excluded from production bundle
if (isTrue(process.env.__DEV__)) {
  if (module.hot) {
    module.hot.accept()
    // Development render functions
    const renderApp = render
    const renderError = (error) => {
      const RedBox = require('redbox-react').default

      ReactDOM.render(<RedBox error={error} />, MOUNT_NODE)
    }

    // Wrap render in try/catch
    const devrender = () => {
      try {
        renderApp()
      } catch (error) {
        console.error(error)
        renderError(error)
      }
    }

    // Setup hot module replacement
    module.hot.accept('./routes/index', () =>
      setImmediate(() => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE)
        devrender()
      })
    )
  }
}

// ========================================================
// Go!
// ========================================================
waitRender()
