import * as React from 'react'

function SvgDownload(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 19.2 17.5'
      width='1em'
      height='1em'
      {...props}
    >
      <defs>
        <style>{'.download_svg__cls-1{fill:#5e5e5e}'}</style>
      </defs>
      <g id='download_svg__Layer_2' data-name='Layer 2'>
        <g id='download_svg__Layer_1-2' data-name='Layer 1'>
          <path
            className='download_svg__cls-1'
            d='M18.88 12a1.08 1.08 0 00-.78-.32h-5.36l-1.57 1.55a2.25 2.25 0 01-3.14 0l-1.56-1.56H1.11a1.07 1.07 0 00-.79.33 1 1 0 00-.32.78v3.65a1 1 0 00.32.77 1.07 1.07 0 00.78.32h17a1.07 1.07 0 00.78-.32 1 1 0 00.32-.77v-3.67a1 1 0 00-.32-.76zm-4.33 3.84a.73.73 0 01-1 0 .71.71 0 010-1 .73.73 0 011 0 .71.71 0 010 1zm3 0a.73.73 0 01-1 0 .71.71 0 010-1 .73.73 0 011 0 .71.71 0 010 1z'
          />
          <path
            className='download_svg__cls-1'
            d='M9.08 12.18a.73.73 0 001 0l5.17-5.11a.65.65 0 00.16-.8.69.69 0 00-.68-.44h-3V.73a.7.7 0 00-.13-.51.71.71 0 00-.52-.22h-3a.71.71 0 00-.48.22.7.7 0 00-.22.51v5.1h-3a.69.69 0 00-.68.44.65.65 0 00.16.8z'
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgDownload
