import React from 'react'
import Breadcrumbs from '../Breadcrumbs'
import Form from '../../containers/TransferMoneyForm'
import InnerContainer from '../InnerContainer'

type TransferMoneyViewProps = {
  success?: boolean
  params?: { id: string } // todofixed - was object
}

const TransferMoneyView = ({ success, params }: TransferMoneyViewProps) => (
  <div>
    <Breadcrumbs
      items={[
        {
          to: '/payments',
          text: 'Payments',
        },
        {
          to: '/payments/transfer-money',
          text: 'Internal Transfer',
        },
      ]}
    />
    <InnerContainer>
      <h1>Internal Transfer</h1>
      <Form params={params} />
    </InnerContainer>
  </div>
)

export default TransferMoneyView
