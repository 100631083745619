import * as c from '../../constants'

export function listUpdateAll(name, items) {
  return {
    type: c.LIST_UPDATE_ALL,
    name,
    payload: {
      items,
    },
  }
}

export function listMergeItems(name, items) {
  return {
    type: c.LIST_MERGE_ITEMS,
    name,
    payload: {
      items,
    },
  }
}

export function listClearItems(name) {
  return {
    type: c.LIST_CLEAR_ITEMS,
    name,
  }
}

export function listMergeItemDetails(name, items) {
  return {
    type: c.LIST_MERGE_ITEM_DETAILS,
    name,
    payload: {
      items,
    },
  }
}

export function listItemDelete(name, id) {
  return {
    type: c.LIST_ITEM_DELETE,
    name,
    payload: {
      id,
    },
  }
}

export function listItemUpdate(name, id, details) {
  return {
    type: c.LIST_ITEM_UPDATE,
    name,
    payload: {
      id,
      details,
    },
  }
}

export function listFetchStop(name) {
  return {
    type: c.LIST_FETCH_STOP,
    name,
  }
}

export function listFetchFail(name, error) {
  return {
    type: c.LIST_FETCH_FAIL,
    name,
    payload: {
      error,
    },
  }
}

export function listFetchStart(name) {
  return {
    type: c.LIST_FETCH_START,
    name,
  }
}
