import React from 'react'
import s from './styles.css'

type StepsProps = {
  numItems: number
  activeItem: number
}

const Steps = ({ numItems, activeItem }: StepsProps) => {
  const items = []
  for (let i = 0; i < numItems; i += 1) {
    const activeClass = activeItem === i ? s.active : undefined
    items.push(
      <li key={i} className={`${s.item} ${activeClass}`}>
        <span className={s.circle}>{i + 1}</span>
      </li>
    )
  }
  return <ol className={s.root}>{items}</ol>
}

export default Steps
