import { connect } from 'react-redux'
import moment from 'moment'
import PaymentsBulkView from '../../components/PaymentsBulkView'
import getUserPermittedFunctions from '../../selectors/getUserPermittedFunctions'
import { show3pmMessage } from './selectors'
import * as a from './actions'

const mapStateToProps = (state) => ({
  mappingList: getUserPermittedFunctions(state),
  minDate: moment(),
  verifySuccess: state.flow.success,
  isViewShowing: true,
  show3pmMessage: show3pmMessage(),
  mappingError: state.accounts.knownMappingsErrors.bulk,
})

const mapDispatchToProps = (state) => ({
  bulkUploadVerify: a.bulkUploadVerify,
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsBulkView)
