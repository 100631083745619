/* eslint-disable */
// @ts-nocheck
// todo - eslint and typescript
import get from 'lodash/get'
import toArray from 'lodash/toArray'

const typeToCode = {
  internalTransfer: 'PC_F_SFT',
  externalTransfer: 'PC_F_DFT',
  internalStandingOrder: 'PC_F_SFTI',
  externalStandingOrder: 'PC_F_DFTI',
  createPayee: 'PC_N_DOP',
  editPayee: 'PC_N_UDOP',
  deletePayee: 'PC_N_DDP',
  payinBook: 'CH_N_PBR',
  chequeBook: 'CH_N_CBR',
  bulkPayment: 'FU_F_DFT',
  cancelledSO: 'PC_F_PIC_SI',
  cancelledFP: 'PC_F_PIC_FP',
  cancelledDD: 'PC_F_PIC_DD',
  adhocPayment: 'PC_F_GNDP',
}

const statusToCode = {
  pendingApproval: 'INITIATED',
  rejected: 'REJECT',
  approved: 'APPROVED',
  timeOut: 'SENT_TO_HOST',
}

const codeToType = {}
const codeToStatus = {}

for (const key in typeToCode) {
  codeToType[typeToCode[key]] = key
}

for (const key in statusToCode) {
  codeToStatus[statusToCode[key]] = key
}
/**
 * Tidies deeply nested and poorly named data from API into something
 * easier to deal with!
 * @param  {Object} data - raw data from API
 * @return {Object} data - tidied data
 */

export interface TidyActivityLogTypesD {
  countDTOList: {
    transactionType: string
    transactionList: {
      transactionName: string
      status: string
      count: number
    }
  }[]
}
export default (data: TidyActivityLogTypesD) => {
  const tidyData = {}

  let itemsList = get(data, 'data.countDTOList')

  if (!itemsList) return { items: {} }
  if (!(itemsList instanceof Array)) {
    itemsList = toArray(itemsList)
  }
  itemsList.forEach((items) => {
    if (items.transactionList) {
      items.transactionList.forEach((item) => {
        const titleName = codeToType[item.transactionName]
        const statusName = codeToStatus[item.status]
        let title
        let statusCode
        let status
        switch (titleName) {
          case 'internalTransfer':
            title = 'Internal Transfer'
            break
          case 'externalTransfer':
            title = 'Make payment'
            break
          case 'internalStandingOrder':
            title = 'Internal Future payment/Standing order'
            break
          case 'externalStandingOrder':
            title = 'External Future payment/Standing order'
            break
          case 'createPayee':
            title = 'Create payee'
            break
          case 'deletePayee':
            title = 'Delete payee'
            break
          case 'payinBook':
            title = 'Pay-in book request'
            break
          case 'chequeBook':
            title = 'Cheque book request'
            break
          case 'bulkPayment':
            title = 'Bulk Payment'
            break
          case 'editPayee':
            title = 'Edit Payee'
            break
          case 'cancelledSO':
            title = 'Cancelled Standing order'
            break
          case 'cancelledDD':
            title = 'Cancelled Direct Debit'
            break
          case 'cancelledFP':
            title = 'Cancelled Future payment'
            break
          case 'adhocPayment':
            title = 'Third Party Payment - Adhoc'
        }
        switch (statusName) {
          case 'pendingApproval':
            status = 'Pending approval'
            statusCode = 'PENDING_APPROVAL'
            break
          case 'rejected':
            status = 'Rejected'
            statusCode = 'REJECTED'
            break
          case 'approved':
            status = 'Approved'
            statusCode = 'APPROVED'
            break
          case 'timeOut':
            status = 'TimeOut Error'
            statusCode = 'SENT_TO_HOST'
        }

        tidyData[`${items.transactionType}-${item.transactionName}-${item.status}`] = {
          id: `${item.transactionName}-${item.status}`,
          type: item.transactionName,
          count: item.count,
          statusCode,
          discriminator: items.transactionType,
          title,
          status,
        }
      })
    }
  })
  return {
    items: tidyData,
  }
}
