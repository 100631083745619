import React from 'react'
import TextJumpoffs from '../TextJumpoffs'
import s from './links.css'

type SecondLinksProps = {
  racingAccount?: boolean
}

const SecondLinks = ({ racingAccount }: SecondLinksProps) => (
  <div className={racingAccount ? `${s.root} ${s.first}` : `${s.root}`}>
    <TextJumpoffs
      items={[
        {
          title: 'Asset Finance',
          bodyText: `Part of the Weatherbys family of companies, Arkle Finance are experts in financing
        for both business and personal use`,
          linkText: 'Find out more',
          to: '/services/assetfinance',
          buttonClass: 'button',
          border: true,
          button: false,
        },
        {
          title: 'Insurance',
          bodyText: `Weatherbys Hamilton provide comprehensive insurance for a range of assets from
         the straightforward to the exotic`,
          linkText: 'Find out more',
          to: '/services/insurance',
          button: false,
          border: true,
        },
        {
          title: 'International Accounts',
          bodyText: 'Moving your money safely and securely around the world with Weatherbys FX',
          linkText: 'Find out more',
          to: '/services/international',
          button: false,
          border: true,
        },
      ]}
    />
  </div>
)

export default SecondLinks
