import React from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import Select from '../Forms/Select'
import SingleField from '../Forms/SingleField'
import ResultsInfo from '../ResultsInfo'
import s from './styles.css'
import * as v from '../../utils/forms/validators'
import SubmitCancel from '../Forms/SubmitCancel'
import BottomError from '../Forms/BottomError'
import { IAddress } from '../../models/interfaces'

type IFormProps = {
  type: string
  handleSubmit?: () => void // todo - made optional as the child component (index) was not passing them
  submitOrder?: () => void // todo - made optional as the child component (index) was not passing them
  onAccountSelectChange?: () => void // todo - made optional as the child component (index) was not passing them
  address?: IAddress
  addressIsLoading?: boolean
  accountOptions?: any[] // todo
  accountOptionsAreLoading?: boolean
  addressIsShowing?: boolean
  submitting?: boolean
  error?: string
}

type FieldProps = {
  account: string
}

const Form = ({
  type,
  address = {},
  addressIsLoading,
  addressIsShowing,
  handleSubmit,
  submitOrder,
  accountOptions,
  accountOptionsAreLoading,
  onAccountSelectChange,
  submitting,
  error,
}: InjectedFormProps<FieldProps> & IFormProps) => {
  const { fullName, addressLine1, addressLine2, addressLine3, city, region, country, postcode } =
    address
  return (
    <form onSubmit={handleSubmit(submitOrder)}>
      <Field
        name='account'
        label='Account'
        component={SingleField}
        InputComponent={Select}
        inputComponentProps={{
          options: accountOptions,
          isLoading: accountOptionsAreLoading,
        }}
        onChange={onAccountSelectChange}
        validate={v.required}
      />
      {addressIsShowing &&
        (addressIsLoading ? (
          <ResultsInfo state={{ state: 'loading' }} />
        ) : (
          <div>
            <p>Your new {type} will be sent to:</p>
            <address className={s.address}>
              {fullName && <span>{fullName}</span>}
              {addressLine1 && <span>{addressLine1}</span>}
              {addressLine2 && <span>{addressLine2}</span>}
              {addressLine3 && <span>{addressLine3}</span>}
              {city && <span>{city}</span>}
              {region && <span>{region}</span>}
              {country && <span>{country}</span>}
              {postcode && <span>{postcode}</span>}
            </address>
            <div className={s.bottom}>
              <BottomError message={error} />
              <SubmitCancel
                submitText={`Order ${type}`}
                submitting={submitting}
                cancelClosesModal
              />
            </div>
            <p>To change your correspondance address please contact the bank.</p>
          </div>
        ))}
    </form>
  )
}

export default reduxForm<FieldProps, IFormProps>({
  form: 'accServ',
})(Form)
