import { takeLatest, put, call, select } from 'redux-saga/effects'
import * as c from '../../constants'
import { modalErrorShow } from '../../store/ui'
import humanError from '../../utils/humanError'
import request from '../../utils/request'
import getIsAccountMappingKnown from '../../selectors/getIsAccountMappingKnown'
import { accountsMappingAdd } from '../Accounts/actions'
import cancelUpcomingPayment from '../../sagas/cancelUpcomingPayment'
import { listMergeItems, listFetchStop } from '../../reducerCreators/listReducer/actions'
import { paymentsRecurringStopDD, paymentsRecurringStopSI } from './actions'

export function* loadRecurringPayments() {
  const nonce = yield call(request, {
    id: c.requestNonce,
    url: 'v1/session/nonce',
  })
  const standingOrders = yield call(request, {
    id: c.requestStandingOrders,
    url: '/cz/v1/payments/instructions?code=SI&type=REC&locale=en',
    nonce,
  })
  yield put(listMergeItems('paymentsRecurringSI', standingOrders.SI))
  const nonce2 = yield call(request, {
    id: c.requestNonce,
    url: 'v1/session/nonce',
  })
  const directDebits = yield call(request, {
    id: c.requestDirectDebit,
    url: '/cz/v1/payments/instructions?code=DD&type=REC&locale=en',
    nonce: nonce2,
  })
  yield put(listMergeItems('paymentsRecurringDD', directDebits.DD))
  yield put(listFetchStop('paymentsRecurringDD'))
  yield put(listFetchStop('paymentsRecurringSI'))
}

export function* handleInit() {
  try {
    const isMappingKnown = yield select(getIsAccountMappingKnown, 'paymentsRecurring')
    if (!isMappingKnown) {
      const response1 = yield call(request, {
        id: c.requestNonce,
        url: 'v1/session/nonce',
      })

      const ids = yield call(request, {
        id: c.requestPaymentsRecurringMapping,
        url: '/v1/accounts/demandDeposit?taskCode=PC_I_INSTRL&locale=en',
        nonce: response1,
      })

      yield put(accountsMappingAdd(ids, 'paymentsRecurring', 'canMakeRecurringPayment'))
    }

    yield call(loadRecurringPayments)
  } catch (error) {
    console.error(error)
    const message = yield call(humanError, error)
    yield put(modalErrorShow(message))
  }
}

export function* watchPaymentsRecurring() {
  yield takeLatest(c.PAYMENTS_RECURRING_INIT, handleInit)
  yield takeLatest(
    paymentsRecurringStopDD.REQUEST,
    cancelUpcomingPayment(
      'paymentsRecurringDD',
      c.requestDirectDebitStop,
      c.requestDirectDebitStopPatch,
      paymentsRecurringStopDD,
      'DD'
    )
  )
  yield takeLatest(
    paymentsRecurringStopSI.REQUEST,
    cancelUpcomingPayment(
      'paymentsRecurringSI',
      c.requestStandingOrderStop,
      c.requestStandingOrderStopPatch,
      paymentsRecurringStopSI,
      'SI'
    )
  )
}
