import { connect } from 'react-redux'
import PaymentMakePayees from '../../components/PaymentMakePayees'
import topOfPage from '../../utils/topOfPage'
import * as a from '../PaymentMake/actions'

const mapDispatchToProps = (dispatch) => ({
  onPayeeClick: (id) => {
    topOfPage()
    dispatch(a.paymentMakeToUpdate(id))
  },
  onNewPayeeClick: () => {
    dispatch(a.paymentMakeNewPayeeShow())
  },
})

export default connect(null, mapDispatchToProps)(PaymentMakePayees)
