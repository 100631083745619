import * as c from '../../constants'
import { IAccountUpdateType, IaccountsMappingAdd } from './interface'

export function accountsUpdate(items: IAccountUpdateType, itemsOrder: string[]) {
  return {
    type: c.ACCOUNTS_UPDATE,
    payload: {
      items,
      itemsOrder,
    },
  }
}

export function accountsRealToMaskedUpdate(realToMasked: string) {
  return {
    type: c.ACCOUNTS_REALTOMASKED_UPDATE,
    payload: {
      realToMasked,
    },
  }
}

export function accountsFetch() {
  return {
    type: c.ACCOUNTS_FETCH,
  }
}

export function accountsFetchFail(error: string) {
  return {
    type: c.ACCOUNTS_FETCH_FAIL,
    payload: {
      error,
    },
  }
}

export function accountInit(id: string) {
  return {
    type: c.ACCOUNT_INIT,
    payload: {
      id,
    },
  }
}

/*
export function accountsKnownMappingAdd (id) {
  return {
    type: c.ACCOUNTS_KNOWN_MAPPING_ADD,
    payload: {
      id
    }
  }
}
*/

export function accountsMappingAdd(
  mappings: IaccountsMappingAdd,
  mappingName: string,
  propertyName: string
) {
  return {
    type: c.ACCOUNTS_MAPPING_ADD,
    payload: {
      ids: mappings.accountIds,
      error: mappings.error,
      mappingName,
      propertyName,
    },
  }
}

export function accountDetailsUpdate(
  id: string,
  details: {
    [key: string]: string
  }
) {
  return {
    type: c.ACCOUNT_DETAILS_UPDATE,
    payload: {
      id,
      details,
    },
  }
}

export function accountDetailsFetch(id: string) {
  return {
    type: c.ACCOUNT_DETAILS_FETCH,
    payload: {
      id,
    },
  }
}

export function accountCustomerAddressUpdate(id: string) {
  return {
    type: c.ACCOUNT_CUSTOMER_ADDRESS_UPDATE,
    payload: {
      id,
    },
  }
}

export function accountDetailsFetchFail(error: string | boolean) {
  return {
    type: c.ACCOUNT_DETAILS_FETCH_FAIL,
    payload: {
      error,
    },
  }
}
