// Functions that a user can be mapped to
export default {
  DFT: 'payment',
  OAT: 'transfer',
  DP: 'payeesManage',
  UP: 'paymentsFuture',
  ACL: 'transactionLog',
  ACS: ['statements', 'loanStatements'],
  FUFT: 'bulkUpload',
  FUT: 'bulkView',
  ALT: 'alerts',
  PBR: 'payInBooks',
  CBR: 'chequeBooks',
  CSI: 'chequeStatus',
  AP: 'approver',
}
