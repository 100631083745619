/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import ActionHeader from '../ActionHeader'
import InnerContainer from '../InnerContainer'
import s from './TopDetails.css'

type PaymentsBulkItemViewProps = {
  items: {
    name: string
    reference: string
    amount: string | number
    date: string
    fromAccount: {
      accountNumber: string
      sortCode: string
      title: string
    }
    records: string | boolean | number
    status: string
    stats: {
      message: string
    }
  } // todofixed - was object.
  onDownloadClick: () => void
}

const PaymentsBulkItemView = ({
  items: { name, reference, stats, amount, records, date, fromAccount, status },
  onDownloadClick,
  ...rest
}: PaymentsBulkItemViewProps) => {
  const elem =
    status === 'error' ? (
      <div className={s.right}>
        <h5 className={s.status}>{stats.message}</h5>
      </div>
    ) : (
      <div className={s.right}>
        <h5 className={s.status}>{stats.message}</h5>
        <span className={s.detail}>From: {fromAccount.title}</span>
        <span className={s.mutedDetail}>
          {fromAccount.sortCode} {fromAccount.accountNumber}
        </span>
        <span className={s.detail}>
          {records} Payment{records === 1 ? null : 's'}
        </span>
        <span className={s.detail}>{amount}</span>
        <span className={s.detail}>{date}</span>
      </div>
    )
  return (
    <InnerContainer>
      <div className={s.root}>
        <div className={s.left}>
          <ActionHeader title={name} wrap />
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <a onClick={onDownloadClick}>DOWNLOAD LINK</a>
          <p className={s.reference}>File reference number: {reference}</p>
        </div>
        {elem}
      </div>
    </InnerContainer>
  )
}

export default PaymentsBulkItemView
