import React from 'react'
import { reduxForm, InjectedFormProps } from 'redux-form'
import BottomError from '../Forms/BottomError'
import SubmitCancel from '../Forms/SubmitCancel'
import Modal from '../../containers/Modal'

type LogoutModalProps = {
  handleSubmit: () => void
  logout: () => void
  submitting?: boolean
  error?: string
}

const LogoutModal = ({
  handleSubmit,
  logout,
  error,
  submitting,
}: InjectedFormProps<any> & LogoutModalProps) => (
  <Modal id='logOut' size='small' title='Log out'>
    <form onSubmit={handleSubmit(logout)}>
      <p>Are you sure you want to LOG OUT?</p>
      <BottomError message={error} />
      <SubmitCancel submitText='Log Out' submitting={submitting} cancelClosesModal />
    </form>
  </Modal>
)

const LogoutReduxModal = reduxForm<any, LogoutModalProps>({
  form: 'logout',
})(LogoutModal)

export default LogoutReduxModal
