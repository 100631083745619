import React from 'react'
import { reduxForm, InjectedFormProps } from 'redux-form'
import NewPasswordFormHelper from '../../formHelpers/NewPasswordFormHelper'
import FieldsetLegend from '../../Forms/FieldsetLegend'
import Button from '../../Button'
import BottomError from '../../Forms/BottomError'

type PassStepProps = {
  handleSubmit?: () => void // todo - was mandatory- but ChangeCredentialsViewn do not pass
  onSubmit: () => void
  invalid?: boolean
  submitting?: boolean
  error?: string
  isFirstTime?: boolean
}

const PassStep = ({
  handleSubmit,
  onSubmit,
  error,
  invalid,
  submitting,
  isFirstTime,
}: InjectedFormProps<any> & PassStepProps) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <fieldset className='narrow'>
      <FieldsetLegend
        title={isFirstTime ? 'Choose a password' : 'Change your password'}
        info={isFirstTime && 'You will use it to log into Online Banking.'}
      />

      <NewPasswordFormHelper />
    </fieldset>
    <fieldset className='narrow'>
      <BottomError message={error} />
      <Button disabled={invalid} submit text='Next' loading={submitting} />
    </fieldset>
  </form>
)

const PassStepReduxForm = reduxForm<any, PassStepProps>({
  form: 'forceChangePassword',
})(PassStep)

export default PassStepReduxForm
