import moment from 'moment'
import get from 'lodash/get'
import formatMoney from '../formatMoney'

/**
 * Tidies deeply nested and poorly named loan data from API into something
 * easier to deal with!
 * @param  {Object} data - raw data from API
 * @return {Object} data - tidied data
 */

const roloverCodeToTitle = {
  A: 'Close on Maturity (No Rollover)',
  I: 'Renew Principal and Interest',
  P: 'Renew Principal and Pay Out the Interest',
  S: 'Renew Special Amount and Pay Out the remaining amount',
  T: 'Renew Principal+(Interest-Tax)',
}
export interface TidyTermDepositDetailsD {
  data: {
    termDepositDetails: {
      valueDate: string
      maturityDate: string
      currencyCode: string
      balance: number | string
      principalAmount: {
        amount: number | string
      }
      maturityAmount: {
        amount: number | string
      }
      interestRate: string | number
      rollOverType: string
      termyears: number
      termmonths: number
      termdays: number
      productDTO: {
        name: string
      }
    }
  }
}
export default (inputData: TidyTermDepositDetailsD) => {
  const data = get(inputData, 'data.termDepositDetails')

  const currency = data.currencyCode
  const f1 = 'YYYY-MM-DD'
  const f2 = 'DD/MM/YYYY'
  const startDate = moment(data.valueDate, f1).startOf('day')
  const maturityDate = moment(data.maturityDate, f1).startOf('day')
  const duration = `${data.termyears} years, ${data.termmonths} months, ${data.termdays} days`

  return {
    duration,
    startDate: startDate.format(f2),
    maturityDate: maturityDate.format(f2),
    interestRate: `${data.interestRate}%`,
    maturityAmount: formatMoney(data.maturityAmount.amount, currency),
    originalAmount: formatMoney(data.principalAmount.amount, currency),
    payoutInstructions: roloverCodeToTitle[data.rollOverType].toString(),
    productName: data.productDTO.name,
  }
}
