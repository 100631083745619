/**
 * Groups accounts into groups
 * @param  {Object} items - items object
 * @return {Array} groupedData - Grouped data
 */
export default (items) => {
  // Types of account
  const groups = [
    {
      title: 'Private Bank Current Accounts',
      code: 'current',
    },
    {
      title: 'Racing Bank Accounts',
      code: 'racing',
    },
    {
      title: 'Deposit Accounts',
      code: 'deposit',
    },
    {
      title: 'Lending',
      code: 'lending',
    },
  ]
  // Map codes to array for easy lookup
  const codes = groups.map((item) => item.code)
  // Empty grouped data
  const groupedData = []
  // Building empty groups
  for (let i = 0; i < groups.length; i += 1) {
    groupedData.push({
      title: groups[i].title,
      id: groups[i].code,
      items: [],
    })
  }
  // Iterate through items
  Object.keys(items).forEach((key) => {
    // Get the index of the correct position in group array
    // based on accounttype property
    const groupIndex = codes.indexOf(items[key].group)
    // Add to array if accounttype exists
    if (groupIndex > -1) {
      groupedData[groupIndex].items.push(items[key])
    }
  })
  // Remove any groups that have no items
  for (let i = groupedData.length - 1; i > -1; i -= 1) {
    if (groupedData[i].items.length === 0) {
      groupedData.splice(i, 1)
    }
  }
  return groupedData
}
