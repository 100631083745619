export default () => ({
  path: 'manage-credentials/:type',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const ManageCredentials = require('../containers/ManageCredentials').default
        cb(null, ManageCredentials)
      },
      'manage-credentials'
    )
  },
})
