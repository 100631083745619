import React, { ReactNode } from 'react'
import { reduxForm, InjectedFormProps } from 'redux-form'
import Checkbox from '../Forms/Checkbox'
import Button from '../Button'
import * as v from '../../utils/forms/validators'
import BottomError from '../Forms/BottomError'
import LoadingSpinner from '../LoadingSpinner'
import s from './styles.css'

type FormProps = {
  children?: ReactNode
  handleSubmit: () => void
  uncheck: (value: string) => void
  tidyUp: () => void
  yesChecked: boolean
  noChecked: boolean
  submitting: boolean
  standalone?: boolean
  settingsSaved: boolean
  forModal: boolean
  marketingModalSubmit: () => void
  customErrors?: string
  anyYesOptionsChecked: boolean
  loading?: boolean
}

const Form = ({
  handleSubmit,
  uncheck,
  tidyUp,
  yesChecked,
  noChecked,
  marketingModalSubmit,
  customErrors,
  submitting,
  forModal = false,
  settingsSaved,
  anyYesOptionsChecked,
  children,
  standalone = false,
  loading = true,
}: InjectedFormProps<any> & FormProps) => (
  <div>
    <div className={s.formWrap}>
      <form onSubmit={handleSubmit(marketingModalSubmit)} className={`${s.form}`}>
        {children}
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div>
            <Checkbox
              boldLabel='YES'
              name='consent'
              validate={(yesChecked || !noChecked) && v.required}
              onChange={() => {
                uncheck('nonConsent')
                tidyUp()
              }}
              label='please, I would like to receive Weatherbys Bank marketing communications, by:'
            />
            <div className={s.boxes}>
              <Checkbox
                name='email'
                label='Email'
                disabled={!yesChecked}
                validate={yesChecked && !anyYesOptionsChecked && v.required}
              />
              <Checkbox
                name='telephone'
                label='Telephone'
                disabled={!yesChecked}
                validate={yesChecked && !anyYesOptionsChecked && v.required}
              />
              <Checkbox
                name='post'
                label='Post'
                disabled={!yesChecked}
                validate={yesChecked && !anyYesOptionsChecked && v.required}
              />
              <Checkbox
                name='text'
                label='Text (SMS)'
                disabled={!yesChecked}
                validate={yesChecked && !anyYesOptionsChecked && v.required}
              />
            </div>
            <Checkbox
              boldLabel='NO'
              name='nonConsent'
              validate={(!yesChecked || noChecked) && v.required}
              onChange={() => {
                uncheck('consent')
                tidyUp()
              }}
              label='thank you'
            />
          </div>
        )}

        <div className={s.buttonWrap}>
          <BottomError message={customErrors} />
          <Button
            submit
            text='Confirm'
            className={s.button}
            loading={submitting}
            disabled={loading}
          />
          {settingsSaved && <span className={s.saved}>Changes saved.</span>}
        </div>
        {standalone && (
          <b className={s.asteriskText}>
            Please note that your contact preferences will not affect our normal communications with
            you as a Weatherbys Bank client.
          </b>
        )}
      </form>
    </div>
  </div>
)

const ReduxForm = reduxForm<any, FormProps>({
  form: 'marketingConsent',
  enableReinitialize: true,
})(Form)

export default ReduxForm
