export default (store) => ({
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const ServicesAssets = require('../containers/ServicesAssets').default
        cb(null, ServicesAssets)
      },
      'assetfinance'
    )
  },
})
