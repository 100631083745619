import { connect } from 'react-redux'
import * as a from '../../store/ui'
import ModalLink from '../../components/ModalLink'

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: (e) => {
    e.preventDefault()
    dispatch(a.modalShow(ownProps.id))
  },
})

export default connect(null, mapDispatchToProps)(ModalLink)
