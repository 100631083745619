import get from 'lodash/get'

export const getAccountsWithAlerts = (state) =>
  Object.values(state.accounts.items).filter((account: any) => account.hasAlerts)

export const getUserContacts = (state) => get(state, 'alerts.user.contacts', {})

export const getMaskedContacts = (state) => {
  const { email, sms } = getUserContacts(state)

  if (!email && !sms) return { email: '', sms: '' }

  const phone = String(sms)
  const maskedSMS = `+${phone}`

  const masked = {
    email,
    sms: maskedSMS,
  }

  return masked
}

// Checks whether the user does not have a
// default email or sms and should have access to alerts
export const doesUserHaveDetails = (state) => {
  const { email, sms } = getUserContacts(state)
  return email !== 'bankit@weatherbys.bank' && String(sms) !== '447724677043'
}

export const getAlertsIds = (state, type) => {
  let alerts
  if (type === 'user') alerts = state.alerts.user
  if (type === 'account') alerts = state.alerts.account.alerts
  return Object.values(alerts.fields)
}

export const getAccountFilterOptions = (accounts) =>
  accounts.map((a) => ({
    label: a.title,
    value: `/settings/alerts?accountNumber=${a.accountNumber}`,
  }))

export const getActiveAccount = (state, accountNumber) => {
  const accounts = getAccountsWithAlerts(state)
  const activeNumber = accountNumber || state.alerts.account.number
  return accounts.find((a: any) => a.accountNumber === activeNumber) || accounts[0]
}
export const getSubId = (state) => state.alerts.user.subscriptionId

export const getPartyId = (state) => state.user.mainPartyId
