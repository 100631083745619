/* eslint-disable */
// @ts-nocheck
// todo - eslint and typescript
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getPaymentMakeDetails } from '../PaymentMake/selectors'
import getPayee from '../../selectors/getPayee'
import { formError } from '../../sagas/formErrors/actions'
import request from '../../utils/request'
import { twoFactorAuthSetInitialRequest, twoFactorAuthSetRef } from '../TwoFactorAuth/actions'
import { flowSuccess } from '../../store/flow'
import { modalHide } from '../../store/ui'
import getFlowData from '../../selectors/getFlowData'
import * as c from '../../constants'
import * as a from './actions'

import updateList from '../../sagas/updateList'
import listItemDelete from '../../sagas/listItemDelete'

export function* handleEditSubmit(action) {
  try {
    yield put(twoFactorAuthSetInitialRequest('payeeModify'))
    const formData = action.payload
    const payee = yield select(getPayee, formData.id)
    const pm = yield select(getPaymentMakeDetails)
    const nonce = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    const details = yield call(request, {
      id: c.requestPayeeCreateOrModify,
      nonce,
      partyId: payee.party.value,
      url: `/cz/v1/payments/payeeGroup/${payee.groupId}/payees/external/${payee.id}?locale=en`,
      payload: {
        stage: 0,
        ...payee,
        ...pm.newPayeeDetails,
        ...formData,
      },
    })
    const payeeDetails = {
      refNumber: details.ref,
      groupId: payee.groupId,
      payeeId: payee.id,
      partyId: payee.party.value,
    }
    yield put(twoFactorAuthSetRef(payeeDetails))
    yield put(flowSuccess(formData))
  } catch (error) {
    console.error(error)
    yield put(formError(a.payeeSubmit.FAILURE, error))
  }
}

export function* handleDeleteSubmit() {
  try {
    const id = yield select(getFlowData)
    const payees = yield select((state: any) => state.payees)
    const { groupId } = payees.list.items[id]
    yield call(listItemDelete('payees', c.requestPayeeDelete, id, groupId))
    yield put(modalHide())
    yield put(a.payeeDelete.success())
    const request1 = {
      id: c.requestPayees,
      url: 'cz/v1/payments/payeeGroup?expand=ALL',
    }
    yield call(updateList('payees', request1))
  } catch (error) {
    console.error(error)
    yield put(formError(a.payeeDelete.FAILURE, error))
  }
}

export function* watchPayees() {
  yield takeLatest(a.payeeSubmit.REQUEST, handleEditSubmit)
  yield takeLatest(a.payeeDelete.REQUEST, handleDeleteSubmit)
  const updateListRequest = {
    id: c.requestPayees,
    url: 'cz/v1/payments/payeeGroup?expand=ALL',
  }
  yield takeLatest(c.PAYEES_FETCH_REQUEST, updateList('payees', updateListRequest))
}
