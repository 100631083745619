import React from 'react'
import s from './styles.css'

interface PasswordFieldProps {
  input: any
  autoFocus?: boolean
}

interface PasswordFieldState {
  type: string
}

class PasswordField extends React.Component<PasswordFieldProps, PasswordFieldState> {
  constructor(props: Readonly<PasswordFieldProps>) {
    super(props)
    this.state = {
      type: 'password',
    }
    this.toggle = this.toggle.bind(this)
  }

  toggle(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault()
    this.setState((prevState) => ({
      type: prevState.type === 'password' ? 'text' : 'password',
    }))
  }

  render() {
    return (
      <div className={s.root}>
        <input
          className={s.input}
          {...this.props.input}
          type={this.state.type}
          autoFocus={this.props.autoFocus}
          autoComplete='new-password'
        />
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href='#' onClick={this.toggle} className={s.showHide} tabIndex={-1} aria-hidden>
          {this.state.type === 'password' ? 'Show' : 'Hide'}
        </a>
      </div>
    )
  }
}

export default PasswordField
