import moment from 'moment'
import formatMoney from '../formatMoney'

export interface TidyChequeStatusI {
  data: {
    issuedChequeDetails: {
      chequeNumber: string
      accountno: {
        accountnoinputdto: {
          nbraccount: string
        }
      }
      chequeStatus: string
      chequeAmount: {
        amount: string | number
        currency: string
      }
      paidOn: string
    }[]
  }
}
export default (
  data: TidyChequeStatusI,
  state: {
    accounts: {
      realToMasked: {
        [key: string]: string
      }
    }
  }
) => {
  const tidyData = {}
  const items = data.data.issuedChequeDetails

  if (items.length > 0) {
    items.forEach((item) => {
      const id = item.chequeNumber
      const accountNumber = item.accountno ? item.accountno.accountnoinputdto.nbraccount : ''
      const status = item.chequeStatus
      let statusTitle = ''
      const amount =
        item.chequeAmount.amount !== 0
          ? formatMoney(item.chequeAmount.amount, item.chequeAmount.currency)
          : undefined
      const date =
        item.paidOn && item.paidOn !== ''
          ? moment(item.paidOn, 'YYYY-MM-DD').format('DD/MM/YYYY')
          : undefined
      switch (status) {
        case 'C':
          statusTitle = 'Cancelled'
          break
        case 'N':
          statusTitle = 'Not Used'
          break
        case 'R':
          statusTitle = 'Rejected'
          break
        case 'S':
          statusTitle = 'Stopped'
          break
        case 'U':
          statusTitle = 'Used'
          break
        case 'H':
          statusTitle = 'Hold'
          break
        default:
      }
      tidyData[id] = {
        id,
        amount,
        accountId: state.accounts.realToMasked[accountNumber],
        statusCode: item.chequeStatus,
        status: statusTitle,
        date,
      }
    })
  }

  return {
    items: tidyData,
  }
}
