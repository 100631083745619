import React from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import SingleField from '../Forms/SingleField'
import Select from '../Forms/Select'
import s from './styles.css'

type FormProps = {
  entityOptions?: any[]
  alphabetOptions?: any[]
  entitySelectIsVisible?: boolean
}

type FieldProps = {
  search: string
  entity: string
  alphabet: string
}

const Form = ({
  entityOptions,
  alphabetOptions,
  entitySelectIsVisible,
}: InjectedFormProps<FieldProps> & FormProps) => (
  <form className={s.form} onSubmit={(e) => e.preventDefault()}>
    <div className={s.col}>
      <Field name='search' label='Search' component={SingleField} type='text' />
    </div>
    {entitySelectIsVisible && (
      <div className={s.col}>
        <Field
          name='entity'
          label='View payees from'
          component={SingleField}
          InputComponent={Select}
          inputComponentProps={{
            options: entityOptions,
          }}
        />
      </div>
    )}
    <div className={s.tinyCol}>
      <Field
        name='alphabet'
        label='Go to letter'
        component={SingleField}
        InputComponent={Select}
        inputComponentProps={{
          options: alphabetOptions,
        }}
      />
    </div>
  </form>
)

const FormRedux = reduxForm<FieldProps, FormProps>({
  form: 'payeeFilters',
})(Form)

export default FormRedux
