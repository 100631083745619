import moment from 'moment'
import formatMoney from '../formatMoney'

/**
 * Tidies deeply nested and poorly named data from API into something
 * easier to deal with!
 * @param  {Object} data - raw data from API
 * @return {Object} data - has SI and DD objects underneat
 */

// TODO -TS
export default (data, state) => {
  const obj = data.data.directDebitList
  const tidyData = {}
  // todo-ts: was map and converted forEach, as nothing is return
  obj.forEach((item) => {
    const { realToMasked } = state.accounts

    let extraDetails = {}
    let date = item.endDate
    let timeStamp

    const id = String(item.externalReferenceNumber)
    const statusCode = item.statusType && item.statusType.toLowerCase() === 'active' ? 'A' : 'C'

    if (date && date !== '') {
      date = moment(item.endDate, 'YYYY-MM-DD').format('DD/MM/YY')
      timeStamp = moment(date, 'DD/MM/YY').format('X')
    } else {
      date = undefined
      timeStamp = undefined
    }

    extraDetails = {
      lastPaymentDate: date,
    }
    tidyData[id] = {
      id,
      statusCode,
      fccRef: item.externalReferenceNumber,
      type: item.prodCode,
      fromId: realToMasked[item.debitAccountId.displayValue],
      customerId: item.partyId.value,
      numInstallments: item.instances,
      recipientName: item.payeeNickName,
      partyId: item.partyId,
      dealId: item.dealId,
      amount: formatMoney(item.amount.amount, item.amount.currency),
      timeStamp,
      ...extraDetails,
    }
  })
  return {
    DD: tidyData,
  }
}
