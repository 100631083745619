import React from 'react'
import Breadcrumbs from '../Breadcrumbs'
import ContactContainer from '../ContactContainer'
import InnerFullWidthContainer from '../InnerFullWidthContainer'
import ServicesHeader from '../ServicesHeader'
import s from '../ServicesView/styles.css'

const addPadding = true

export const ServicesInsurance = (/* { mappingError } */) => (
  <div>
    <Breadcrumbs
      items={[
        {
          to: '/services',
          text: 'Additional services',
        },
        {
          to: '/services/invoiceservices',
          text: 'Invoice services',
        },
      ]}
    />
    <ServicesHeader title='Invoice services' secondaryTitle='Pay you invoices the pain-free way' />
    <InnerFullWidthContainer addPadding={addPadding}>
      <div className={`${s.textArea} ${s.extraTopMargin}`}>
        <p>
          If you’re an owner or a trainer, our Invoice Payment Service can take away the pain of
          paying invoices for training, vets and stud fees. When you sign up, our expert Racing Bank
          team will pay your bills and take care of any queries on your behalf.
        </p>
      </div>
    </InnerFullWidthContainer>
    <InnerFullWidthContainer addPadding={addPadding}>
      <ContactContainer
        title='Find out more'
        infoText='To find out more'
        items={[
          {
            linkTitle: '+44 (0) 1933 304 777',
            to: '+44 (0) 1933 304 777',
            telNumber: true,
          },
          // {
          //   linkTitle: 'Send Secure Message',
          //   to: '/secure-mail/new'
          // }
          {
            linkTitle: 'Send Secure Message',
            to: '/secure-mail/enquiry#invoiceservices',
          },
        ]}
      />
    </InnerFullWidthContainer>
  </div>
)

export default ServicesInsurance
