import * as React from 'react'

function SvgDocument(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 512 512'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M432.953 57.856h-14.592v367.872a43.776 43.776 0 01-43.264 44.288h-266.24v13.824c-.1 15.394 12.255 27.977 27.648 28.16h296.448a27.802 27.802 0 0027.648-28.16V86.272c.143-15.454-12.196-28.136-27.648-28.416zm-206.08 396.288h148.224c15.452-.28 27.791-12.962 27.648-28.416V28.16c.1-15.394-12.254-27.977-27.648-28.16H177.465v9.472a9.6 9.6 0 01.256 2.816v77.568a39.22 39.22 0 01-38.912 39.68H51.001v296.192c-.143 15.454 12.196 28.136 27.648 28.416h148.224zm5.632-96.256h-96.768c-7.352 0-13.312-5.96-13.312-13.312 0-7.352 5.96-13.312 13.312-13.312h96.768c7.352 0 13.312 5.96 13.312 13.312 0 7.352-5.96 13.312-13.312 13.312zm96.512-68.096h-193.28c-7.422 0-13.44-6.017-13.44-13.44 0-7.423 6.018-13.44 13.44-13.44h193.28c7.423 0 13.44 6.017 13.44 13.44 0 7.423-6.017 13.44-13.44 13.44zm-193.28-99.328h193.28c7.423 0 13.44 6.017 13.44 13.44 0 7.423-6.017 13.44-13.44 13.44h-193.28c-7.422 0-13.44-6.017-13.44-13.44 0-7.423 6.018-13.44 13.44-13.44zm-72.704-76.8h76.032c12.719-.388 22.818-10.828 22.784-23.552V12.288A11.52 11.52 0 00150.33.512a11.213 11.213 0 00-8.192 3.328L54.841 93.184a11.98 11.98 0 008.192 20.48z'
        fill='#999'
        fillRule='evenodd'
      />
    </svg>
  )
}

export default SvgDocument
