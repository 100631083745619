import { connect } from 'react-redux'
import PayeeTable from '../../components/PayeeTable'
import niceTableData from '../../utils/niceTableData'
import getEntities from '../../selectors/getEntities'
import { getVisiblePayees } from '../Payees/selectors'
import { getIsFiltered } from './selectors'
import { copReset } from '../ConfirmationOfPayee/actions'

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
const alphabetOptions = [
  {
    label: 'All',
    value: undefined,
  },
  {
    label: '0-9',
    value: '0123456789',
  },
  ...alphabet.map((l) => ({
    label: l,
    value: l,
  })),
]

interface ITableFormat {
  mapRowIdTo: string
  cols: {
    header: string
    mapTextTo?: string
    perc?: number
    wrap?: boolean
    mapExtraTo?: string
    hasActions?: boolean
  }[]
  card: {
    mapTitleTo: string
    mapExtraTo: string
    mapTextTo: string
  }
}

const mapStateToProps = (state, ownProps) => {
  const tableFormat: ITableFormat = {
    mapRowIdTo: 'id',
    cols: [
      {
        header: 'Name',
        mapTextTo: 'title',
        perc: 50,
        wrap: true,
      },
      {
        header: 'Bank details',
        mapTextTo: 'accountNumber',
        mapExtraTo: 'sortCodeNice',
      },
      {
        header: 'Last reference',
        mapTextTo: 'lastReference',
        perc: 25,
      },
    ],
    card: {
      mapTitleTo: 'title',
      mapExtraTo: 'details', // We created this property below
      mapTextTo: 'lastReference',
    },
  }

  if (ownProps.actions) {
    tableFormat.cols.push({
      header: 'Actions',
      hasActions: true,
      perc: 10,
    })
  }

  const entityFilterMode = ownProps.entitySelectIsVisible && 'inner'

  const items = getVisiblePayees(state, entityFilterMode)
  const niceData = niceTableData(items, tableFormat)

  return {
    rows: niceData.body,
    headerCols: niceData.header,
    isFetching: state.payees.list.isFetching,
    hasFilters: getIsFiltered(state),
    failedToFetch: state.payees.list.errorMessage,
    errorCode: state.payees.list.errorCode,
    cardForSmall: true,
    entityOptions: [
      {
        label: 'All Accounts',
        value: false,
      },
      ...getEntities(state),
    ],
    alphabetOptions,
    initialValues: {
      entity: {
        value: state.entities.activeEntityId,
      },
    },
  }
}
const mapDispatchToProps = (dispatch) => ({
  copReset() {
    dispatch(copReset())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PayeeTable)
