export default (store) => ({
  path: 'new',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const PayeeNew = require('../containers/PayeeNew').default
        cb(null, PayeeNew)
      },
      'payee-new'
    )
  },
})
