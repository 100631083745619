import { connect } from 'react-redux'
import { panelHide } from '../../store/ui'
import EntitiesHelp from '../../components/EntitiesHelp'
import userIsFirstTime from '../../selectors/userIsFirstTime'
import userHasEntities from '../../selectors/userHasEntities'

export const mapStateToProps = (state) => ({
  isOpen: state.ui.panel === 'entitiesHelp',
  isInDom: userIsFirstTime(state) && userHasEntities(state),
})

const mapDispatchToProps = (dispatch) => ({
  onCloseClick: (e) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(panelHide()) // todo-ts: why no Id is passed?
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(EntitiesHelp)
