import React from 'react'
import Breadcrumbs from '../Breadcrumbs'
import InnerContainer from '../InnerContainer'
import ManageAccountItem from '../../containers/ManageAccountItem'
import Button from '../Button'
import s from './styles.css'

type ManageAccountsViewProps = {
  onSave: () => void
  showSecondButton: boolean
  isSaving: boolean
  groups?: any[]
  showSavedMessage?: boolean
}

const ManageAccountsView = ({
  groups,
  onSave,
  showSecondButton,
  isSaving,
  showSavedMessage,
  ...props
}: ManageAccountsViewProps) => (
  <div className={s.root}>
    <Breadcrumbs
      items={[
        {
          to: '/settings',
          text: 'Details & settings',
        },
        {
          to: '/settings/manage-accounts',
          text: 'Reorder & rename accounts',
        },
      ]}
    />
    <InnerContainer>
      <div className={s.heading}>
        <h1>Reorder &amp; rename accounts</h1>
        {showSavedMessage && (
          <span className={`${s.savedText} ${s.marginTop}`}>Your settings have been saved.</span>
        )}
        {showSecondButton && (
          <Button text='Save Settings' onClick={onSave} className={s.topButton} />
        )}
      </div>
      {groups.map((group) => (
        <div key={group.id} className={s.group}>
          <h2>{group.title}</h2>
          {group.items.map((item) => (
            <ManageAccountItem id={item.id} key={item.id} {...props} />
          ))}
        </div>
      ))}
      <Button text='Save Settings' onClick={onSave} loading={isSaving} />
      {showSavedMessage && <span className={s.savedText}>Your settings have been saved.</span>}
    </InnerContainer>
  </div>
)

export default ManageAccountsView
