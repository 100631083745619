import React from 'react'
import Breadcrumbs from '../Breadcrumbs'
import BulletPoints from '../BulletPoints'
import ContactContainer from '../ContactContainer'
import InnerFullWidthContainer from '../InnerFullWidthContainer'
import ServicesHeader from '../ServicesHeader'
import s from '../ServicesView/styles.css'

const addPadding = true
const listStyle = true

export const ServicesExpatriate = (/* { mappingError } */) => (
  <div>
    <Breadcrumbs
      items={[
        {
          to: '/services',
          text: 'Additional services',
        },
        {
          to: '/services/expatriate',
          text: 'Expatriate services',
        },
      ]}
    />
    <ServicesHeader
      title='Manage your money – wherever you are'
      secondaryTitle='Specially designed for UK domiciled individuals who live abroad'
    />

    <InnerFullWidthContainer addPadding={addPadding}>
      <div className={`${s.textArea} ${s.extraTopMargin}`}>
        <p>
          If you live and work abroad, securing a mortgage on a property in the UK can be a complex
          task. Our specialist Expatriate Services team provides bespoke lending solutions to help
          you seamlessly manage your financial affairs across borders, including:
        </p>
      </div>
      <BulletPoints
        listStyle={listStyle}
        items={[
          {
            bulletPoint:
              'foreign currency bank accounts and&nbsp;<a href="/#/services/mortgageloans">mortgages</a>',
          },
          {
            bulletPoint:
              'bespoke underwriting, with each applicant considered on their personal circumstances',
          },
          {
            bulletPoint: 'loan amounts between £500,000 and £5,000,000',
          },
          {
            bulletPoint:
              'loan-to-value ratio of up to 65%, with interest only option available for up to five years',
          },
        ]}
      />
    </InnerFullWidthContainer>
    <InnerFullWidthContainer addPadding={addPadding}>
      <ContactContainer
        title='Find out more'
        infoText={`For more information about our services for Expatriate clients, please contact
        Thomas Fitzgerald O'Connor`}
        items={[
          {
            linkTitle: '+44 (0) 20 7292 9029',
            to: '+44 (0) 20 7292 9029',
            telNumber: true,
          },
          // {
          //   linkTitle: 'Send Secure Message',
          //   to: '/secure-mail/new'
          // }
          {
            linkTitle: 'Send Secure Message',
            to: '/secure-mail/enquiry#expatriate',
          },
        ]}
      />
    </InnerFullWidthContainer>
  </div>
)

export default ServicesExpatriate
