import * as c from '../../constants'

export interface IPaymentMakeState {
  from?: string
  to?: string
  newPayeeDetails?: any
  ignoreReset?: boolean
  isNewPayee?: boolean
  success?: any
  showPaymentCutoffModal?: boolean
}

export default function paymentMakeReducer(
  state: IPaymentMakeState = {},
  action
): IPaymentMakeState {
  const p = action.payload
  switch (action.type) {
    case c.R_PAYMENTMAKE_FROM_UPDATE: {
      return {
        ...state,
        from: p.id,
      }
    }
    case c.PAYMENTMAKE_TO_UPDATE: {
      return {
        ...state,
        to: p.id,
      }
    }
    case c.PAYMENTMAKE_DETAILS_UPDATE: {
      return {
        ...state,
        ...p.details,
      }
    }
    case c.PAYMENTMAKE_NEWPAYEE_DETAILS_UPDATE: {
      return {
        ...state,
        newPayeeDetails: {
          ...state.newPayeeDetails,
          ...p.details,
        },
      }
    }
    case c.PAYMENTMAKE_NEWPAYEE_DETAILS_CLEAR: {
      return {
        ...state,
        newPayeeDetails: false,
      }
    }
    case c.PAYMENTMAKE_RESET: {
      if (state.ignoreReset) {
        return {
          ...state,
          ignoreReset: false,
        }
      }
      return {}
    }
    case c.PAYMENTMAKE_IGNORE_NEXT_RESET: {
      return {
        ...state,
        ignoreReset: true,
      }
    }
    case c.PAYMENTMAKE_NEWPAYEE_SHOW: {
      return {
        ...state,
        isNewPayee: true,
      }
    }
    case c.PAYMENTMAKE_NEWPAYEE_HIDE: {
      return {
        ...state,
        isNewPayee: false,
      }
    }
    case c.PAYMENTMAKE_RESET_SUCCESS: {
      return {
        ...state,
        success: undefined,
      }
    }
    case c.SHOW_PAYMENT_CUTOFF_MODAL: {
      return {
        ...state,
        showPaymentCutoffModal: true,
      }
    }
    case c.HIDE_PAYMENT_CUTOFF_MODAL: {
      return {
        ...state,
        showPaymentCutoffModal: false,
      }
    }
    default:
      return state
  }
}
